import React, { useState, useEffect, useRef } from "react";
import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";

import "./RestrictedCustomisationPopup.scss";
import CustomisationControls from "../../components/CustomisationControls/CustomisationControls";
import AddDisruptor from "../Campaigns/AddDisruptor";



const RestrictedCustomisationPopup = (props) => {
    let featureData = null;
    for (let i =  0; i < props.campaignData.features.length; i++) {
        if (props.campaignData.features[i]._id === props.featureId) {
            featureData = props.campaignData.features[i];
            break;
        }
    }

    console.log("RESTRICTED CUSTOMISATION POPUP", featureData, props.customisationVars, props.featureId, props.campaignData);
    const [editedCustomisationVars, setEditedCustomisationVars] = useState(props.customisationVars);
    useEffect(() => {
        setEditedCustomisationVars(JSON.parse(JSON.stringify(props.customisationVars)));
    }, [props.customisationVars]);

    // Adding disruptors
    const [showAddDistruptor, setShowAddDistruptor] = useState(false);
    const disruptorsUpdatedCallback = useRef(null);
    const existingDisruptorAddedCallback = useRef(null);

    const lastDisruptorAdded = useRef(null);
    const lastFeatureListUpdate = useRef(null);
    const [buildLeaderboard, setBuildLeaderboard] = useState(false);
    const [newDisruptorLabel, setNewDisruptorLabel] = useState(null);

    const addDisruptor = (completedCallback, addExistingCallback, isLeaderboard=false, disruptorLabel = null) => {
        disruptorsUpdatedCallback.current = completedCallback;
        existingDisruptorAddedCallback.current = addExistingCallback;

        lastDisruptorAdded.current = null;
        lastFeatureListUpdate.current = null;
        setBuildLeaderboard(isLeaderboard);
        setNewDisruptorLabel(disruptorLabel);
        setShowAddDistruptor(true);
    };
    const closeAddDisruptor = () => {setShowAddDistruptor(false)};

    return (
        <>
            <PopUpPanel closePanelFunc={props.closePanelFunc} className="restricted-customisation-popup">
                <h1>{props.title}</h1>
                <div className="restricted-customisation-popup__scroll-area">
                    <CustomisationControls
                        restrictedCustomisation = {{tab: props.tab, group: props.group, family: props.family}}
                        tabId={props.tab}
                        controlsData={featureData.feature.customisation}
                        customisationVars={editedCustomisationVars}
                        featureId={props.featureId}
                        featureData={featureData}
                        campaignData={props.campaignData}
                        advancedMode={props.advancedMode || true}
                        addDisruptor={addDisruptor}
                    />
                </div>
                <button className="standard-button save-button" 
                    onClick={
                        () => {
                            console.log("Save restricted customisations: ", props.customisationVars, editedCustomisationVars);
                            props.customisationVars.current[props.featureId] = editedCustomisationVars.current[props.featureId];
                            props.saveCustomisationVars(props.customisationVars, props.featureId);
                            props.closePanelFunc();
                        }
                    }
                >Save Changes</button>
            </PopUpPanel>
            {showAddDistruptor &&
                <AddDisruptor
                    closePanelFunc={closeAddDisruptor} 
                    campaignId={props.campaignId} 
                    addSelectedFeatureToListFunc={() => {}}
                    addExistingDisruptorToListFunc={existingDisruptorAddedCallback.current}
                    campaignTier={props.campaignData.campaignTier}
                    isIntro={false}
                    isOutro={false}
                    chainableOnly={true}
                    showSelectExistingOption={true}
                    campaignFeatures={props.campaignData.features}
                    buildLeaderboard={buildLeaderboard}
                    disruptorContentLabel={newDisruptorLabel}
                    isRestricted={true}
                />
            }
        </>
    )
}

export default RestrictedCustomisationPopup;
