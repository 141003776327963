import { useContext } from "react";
import { assets } from "../../assets/assets";
import PropTypes from 'prop-types';
import "./ProfilePic.scss";
import { getAcTypeData } from "../../helpers/AccountTypes";
import { AuthContext } from "../../contexts/AuthContext";
import { ProfilePicURL } from "../../helpers/ProfilePicURL";

export const PROFILE_PIC_SIZES = {
    small: 'small',
    medium: 'medium',
    large: 'large',
    extralarge: 'extralarge'
}

const ProfilePic = (props) => {
    const { size, className, onClick, user } = props;

    // Make sure we have user data, default to logged in user
    const [authContext, setAuthContext] = useContext(AuthContext);
    let userData = user;
    if (userData === null) {
        userData = authContext.userData;
    }

    // Get the css for the border colour
    let ppBorderColorCss = 'streamer-pp-border';
    if (userData) {
        const acTypeData = getAcTypeData(userData.acType || userData.accountType);
        if (acTypeData) {
            ppBorderColorCss = acTypeData.profilePicBorderCss;
        }
    }

    // Get the pic url
    const picUrl = ProfilePicURL(userData);
    // console.log(picUrl, userData)
    return(
        <div className={`profile-pic ${ppBorderColorCss} ${size} ${className}`} style={{ backgroundImage: `url(${picUrl})` }} onClick={onClick}></div>
    );
}

ProfilePic.propTypes = {
    size: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    user: PropTypes.object,
};

ProfilePic.defaultProps = {
    size: 'small',
    className: '',
    onClick: null,
    user: null,
}

export default ProfilePic;