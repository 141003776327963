import { useEffect, useRef, useState } from "react";
import FormDropDown from "../../../forms/DropDownList/FormDropDown";
import "./AnswerSelector.scss";
import ToggleCheck from "../../../forms/ToggleCheck/ToggleCheck";

const LivePollAnswerSelector = (props) => {
    const pollsData = props.customisationData?.setup?.pollData?.polls;
    const featureVars = props.featureVars;
    const selectedPollIndex = featureVars.pollControl.selectedPoll;
    const selectedPollData = pollsData?.[selectedPollIndex];
    const pollType = selectedPollData?.type;

    const [forceUpdate, setForceUpdate] = useState(0);
    const forceUpdateNow = () => {
        setForceUpdate((val) => val + 1);
    }

    const resetCorrectAnswers = () => {
        const pollStateData = findPollsStateData(selectedPollData);
        for (let i = 0; i < pollStateData.answerState.length; i++) {
            pollStateData.answerState[i].correct = false;
        }
    }

    const findPollsStateData = (pollData) => {
        if (!pollData) {
            return null;
        }
        let pollStateData = null;
        // console.log('--- ANSWER_SELECTOR, findPollsStateData: ', props.previewControls, props.passedInPreviewData);
        if (props.previewControls && props.passedInPreviewData.pollControl?.pollsState) {
            const pollTitle = pollData.title;
            for (let i = 0; i < props.passedInPreviewData.pollControl.pollsState.length; i++) {
                if (props.passedInPreviewData.pollControl.pollsState[i].pollTitle === pollTitle) {
                    pollStateData = props.passedInPreviewData.pollControl.pollsState[i];
                }
            }
        }
        if (pollStateData === null && featureVars.pollControl?.pollsState) {
            const pollTitle = pollData.title;
            for (let i = 0; i < featureVars.pollControl.pollsState.length; i++) {
                if (featureVars.pollControl.pollsState[i].pollTitle === pollTitle) {
                    pollStateData = featureVars.pollControl.pollsState[i];
                }
            }
        }
        if (pollStateData === null) {
            if (typeof featureVars.pollControl.pollsState === 'undefined') {
                featureVars.pollControl.pollsState = [];
            }
            pollStateData = {
                pollTitle: pollData.title,
                pollRunning: false,
                answerState: []
            };
            featureVars.pollControl.pollsState.push(pollStateData);
        }
        pollStateData.type = pollData.type;

        if (Array.isArray(pollStateData.answerState) === false) {
            pollStateData.answerState = [];
        }
        // Make sure we have state data for each answer
        // State data is in the following format:
        // {
        //     included: [boolean],
        //     correct: [boolean],
        //     votePercentage: [integer]
        // }
        // We can report the state back from the poll to keep the votePercentage up to date, although prob only at the of voting start and end
        for (let i = 0; i < 10; i++) {
            if (pollData['answer' + (i + 1)] && pollData['answer' + (i + 1)] !== '') {
                if (typeof pollStateData.answerState[i] === 'undefined') {
                    pollStateData.answerState.push({
                        included: true,
                        correct: false,
                        votePercentage: 0
                    });
                }
            } else {
                // A blank / non existent answer means we don't have any more answers
                break;
            }
        }

        return pollStateData;
    }

    const pollStateData = findPollsStateData(selectedPollData);
    const passedInPreviewData = props?.passedInPreviewData;
    const changeValFunc = props.changeValFunc;
    const reportCustomisationVarsChanged = props.reportCustomisationVarsChanged;

    useEffect(() => {
        const varsToUse = passedInPreviewData ? passedInPreviewData : featureVars;
        if (varsToUse?.pollControl?.pollsState) {
            for (let i = 0; i < varsToUse.pollControl.pollsState.length; i++) {
                const pollStateData_i = varsToUse.pollControl.pollsState[i];
                
                if (pollStateData_i && pollStateData_i.pollRunning === true) {
                    // Set a flag to show we have not reported our data yet, we only save the data at the end of the poll
                    // console.log('--- ANSWER_SELECTOR, useEffect (Reset pollRunDataSaved): ', pollStateData_i);
                    pollStateData_i.pollRunDataSaved = false;
                }

                if (pollStateData_i && pollStateData_i.pollRunning === false && pollStateData_i.pollHasVotes === true) {
                    // Set pollStateData.highlightRemovedAnswers to true ready for when we hit send / show the previous poll data
                    // console.log('--- ANSWER_SELECTOR, useEffect (Time to highlight removed answers): ', pollStateData_i);
                    pollStateData_i.highlightRemovedAnswers = true;

                    if (pollStateData_i.pollRunDataSaved === false) {
                        // Save the poll results to customisationData
                        if (pollStateData_i.type !== 'predictive' || pollStateData_i.correctAnswer !== -1) {
                            // console.log('--- ANSWER_SELECTOR, useEffect (Time to save data): ', pollStateData_i, props.customisationData);
                            pollStateData_i.pollRunDataSaved = true;

                            // Save the poll results to customisationData
                            if (!props.isPreview && reportCustomisationVarsChanged) {
                                // No saving of runs in preview mode!
                                const pollsData = props.customisationData?.setup?.pollData?.polls;
                                if (pollsData) {
                                    let completedPollData = null;
                                    for (let i = 0; i < pollsData.length; i++) {
                                        if (pollsData[i].title === pollStateData_i.pollTitle) {
                                            completedPollData = pollsData[i];
                                        }
                                    }
                                    if (completedPollData) {
                                        // console.log('--- ANSWER_SELECTOR, useEffect (Completed poll data): ', completedPollData);
                                        let runData = null;
                                        let addNewRun = true;
                                        for (let j = 0; j < props.customisationData.setup.runData.polls.length; j++) {
                                            if (props.customisationData.setup.runData.polls[j].title === pollStateData_i.pollTitle && props.customisationData.setup.runData.polls[j].timeAndDate === pollStateData_i.startTime) {
                                                runData = props.customisationData.setup.runData.polls[j];
                                                addNewRun = false;
                                            }
                                        }
                                        if (addNewRun) {
                                            let channelString = '';
                                            if (props.channels) {
                                                if (Array.isArray(props.channels)) {
                                                    for (let i = 0; i < props.channels.length; i++) {
                                                        if (channelString !== '') {
                                                            channelString += ', ';
                                                        }
                                                        channelString += props.channels[i].twitchHandle;
                                                    }
                                                }
                                            }
                                            if (channelString === '') {
                                                channelString = 'none';
                                            }
                                            runData = {
                                                title: pollStateData_i.pollTitle,
                                                timeAndDate: pollStateData_i.startTime,
                                                channel: channelString,
                                                type: pollStateData_i.type,
                                                removalType: completedPollData.removalType,
                                                question: completedPollData.question,
                                            };
                                            props.customisationData.setup.runData.polls.push(runData);

                                            // Record our run data!
                                            let totalVotes = 0;
                                            let mostVotedVotes = 0;
                                            let leastVotes = 1000000;
                                            for (let i = 1; i <= 10; i++) {
                                                runData['answer' + i] = completedPollData['answer' + i];
                                                runData['included' + i] = pollStateData_i.answerState?.[i - 1]?.includedInLastVote ?? false;
                                                runData['votes' + i] = pollStateData_i.rawAnswerVoteCounts?.[i - 1] ?? 0;
                                                runData['percentage' + i] = pollStateData_i.answerState?.[i - 1]?.votePercentage ?? 0;
                                                totalVotes += pollStateData_i.rawAnswerVoteCounts?.[i - 1] ?? 0;
                                                if (pollStateData_i.rawAnswerVoteCounts?.[i - 1] > mostVotedVotes) {
                                                    mostVotedVotes = pollStateData_i.rawAnswerVoteCounts?.[i - 1];
                                                }
                                                if (pollStateData_i.rawAnswerVoteCounts?.[i - 1] < leastVotes) {
                                                    leastVotes = pollStateData_i.rawAnswerVoteCounts?.[i - 1];
                                                }
                                                runData['winner' + i] = false;
                                                if (pollStateData_i.type === 'removal') {
                                                    if (pollStateData_i.answerState?.[i - 1]?.includedInLastVote) {
                                                        runData['winner' + i] = !pollStateData_i.answerState?.[i - 1]?.included ?? false;
                                                    } else {
                                                        runData['winner' + i] = false;
                                                    }
                                                } else
                                                if (pollStateData_i.type === 'predictive') {
                                                    runData['winner' + i] = pollStateData_i.answerState?.[i - 1]?.correct ?? false;
                                                }
                                            }
                                            runData.totalVotes = totalVotes;
                                            let mostVotedAnswer = '';
                                            for (let i = 1; i <= 10; i++) {
                                                if (pollStateData_i.rawAnswerVoteCounts?.[i - 1] === mostVotedVotes) {
                                                    if (mostVotedAnswer !== '') {
                                                        mostVotedAnswer += ', ';
                                                    }
                                                    mostVotedAnswer += completedPollData['answer' + i];
                                                    if (pollStateData_i.type === 'opinion') {
                                                        runData['winner' + i] = true;
                                                    }
                                                }
                                            }
                                            runData.winningAnswer = mostVotedAnswer;

                                            let cumulativeRunData = null;
                                            for (let j = 0; j < props.customisationData.setup.totalData.polls.length; j++) {
                                                if (props.customisationData.setup.totalData.polls[j].title === pollStateData_i.pollTitle && props.customisationData.setup.totalData.polls[j].type === pollStateData_i.type) {
                                                    cumulativeRunData = props.customisationData.setup.totalData.polls[j];
                                                }
                                            }
                                            if (!cumulativeRunData) {
                                                cumulativeRunData = {
                                                    title: pollStateData_i.pollTitle,
                                                    type: pollStateData_i.type,
                                                    removalType: completedPollData.removalType,
                                                    question: completedPollData.question,
                                                }
                                                props.customisationData.setup.totalData.polls.push(cumulativeRunData);
                                            }
                                            cumulativeRunData.totalVotes = (cumulativeRunData.totalVotes || 0) + totalVotes;
                                            cumulativeRunData.runCount = (cumulativeRunData.runCount || 0) + 1;
                                            cumulativeRunData.lastRun = runData.timeAndDate;
                                            // copy answers from runData, update total votes
                                            for (let i = 1; i <= 10; i++) {
                                                cumulativeRunData['answer' + i] = runData['answer' + i];
                                                cumulativeRunData['votes' + i] = (cumulativeRunData['votes' + i] || 0) + runData['votes' + i];
                                                cumulativeRunData['percentage' + i] = Math.round((cumulativeRunData['votes' + i] / cumulativeRunData.totalVotes) * 100);
                                            }
                                            // Find most popular answer
                                            let mostPopularAnswer = '';
                                            let mostPopularVotes = 0;
                                            for (let i = 1; i <= 10; i++) {
                                                if (cumulativeRunData['votes' + i] > mostPopularVotes) {
                                                    mostPopularVotes = cumulativeRunData['votes' + i];
                                                }
                                            }
                                            for (let i = 1; i <= 10; i++) {
                                                if (cumulativeRunData['votes' + i] === mostPopularVotes) {
                                                    if (mostPopularAnswer !== '') {
                                                        mostPopularAnswer += ', ';
                                                    }
                                                    mostPopularAnswer += cumulativeRunData['answer' + i];
                                                }
                                            }
                                            cumulativeRunData.winningAnswer = mostPopularAnswer;

                                            // console.log('--- ANSWER_SELECTOR, useEffect (Saving run data): ', runData, cumulativeRunData);

                                            reportCustomisationVarsChanged(props.customisationVars, props.featureId);
                                        }
                                    }
                                }
                            } /* else {
                                console.log('--- ANSWER_SELECTOR, useEffect (no saving run data in preview mode): ', pollStateData_i);
                            } */
                        }
                    }
                }

                if (pollStateData.pollTitle === pollStateData_i.pollTitle) {
                    let pollHasVotes = false;
                    let correctAnswer = -1;
                    for (let i = 0; i < pollStateData_i.answerState.length; i++) {
                        if (pollStateData_i.answerState[i].votePercentage > 0) {
                            pollHasVotes = true;
                        }
                        if (pollStateData_i.answerState[i].correct) {
                            correctAnswer = i;
                        }
                    }

                    // make sure top level, ie. varsToUse.pollControl.pollHasVotes is updated
                    if (typeof pollStateData.pollHasVotes !== 'undefined' && pollStateData.pollHasVotes !== pollHasVotes) {
                        // console.log('--- ANSWER_SELECTOR, Set pollHasVotes to: ', pollHasVotes);
                        setTimeout(() => changeValFunc('pollControl', 'pollHasVotes', pollHasVotes, false, false, pollStateData.correctAnswer !== correctAnswer ? false : true), 20);
                    }
                    // make sure top level, ie. varsToUse.pollControl.correctAnswer is updated
                    if (typeof pollStateData.correctAnswer !== 'undefined' && pollStateData.correctAnswer !== correctAnswer) {
                        // console.log('--- ANSWER_SELECTOR, Set correctAnswer to: ', correctAnswer);
                        setTimeout(() => changeValFunc('pollControl', 'correctAnswer', correctAnswer, false, false, true), 20);
                    }  
                }
            }
        }
    }, [pollStateData, featureVars, passedInPreviewData, changeValFunc]);

    return (
        <div className="fl-column live-poll-answers-selector">
            {!pollStateData &&
                <div className="form-field-content label">
                    Poll Status and Answers Not Available
                </div>
            }
            {pollStateData &&
                <>
                    <div className="form-field-content var-label">
                        Poll Status
                    </div>
                    <div className="fl-column no-gap">
                        {pollType === 'opinion' &&
                            <>
                                <div className="poll-type">Opinion Poll.</div>
                                <div>Results will appear below after the voting ends.</div>
                            </>
                        }
                        {pollType === 'predictive' &&
                            <>
                                <div className="poll-type">Predictive Poll.</div>
                                <div>You will be able to select the correct answer below when voting is not active.</div>
                            </>
                        }
                        {pollType === 'removal' &&
                            <>
                                <div className="poll-type">Removal Poll.</div>
                                <div>You will be able to select the answers to include / remove when voting is not active. Only these answers will be included the next time you run this poll.</div>
                            </>
                        }
                        {pollStateData.pollRunning &&
                            <div className="poll-running">Poll is currently running...</div>
                        }
                        {!pollStateData.pollRunning &&
                            <div className="poll-not-running">Poll is not currently running.</div>
                        }
                    </div>
                    <div>Question: <span className='question-text'>{selectedPollData.question}</span></div>
                    {pollStateData.answerState.map((answer, index) => {
                        let toggleEnabled = !pollStateData.pollRunning;
                        if (toggleEnabled && pollType === 'predictive') {
                            if (pollStateData.correctAnswer !== -1 || !pollStateData.pollHasVotes) {
                                toggleEnabled = false;
                            }
                        }
                        return (
                            <div className="fl-row compact" key={index}>
                                {(pollType === 'predictive' || pollType === 'removal') &&
                                    <div className="shrink">
                                        <ToggleCheck
                                            label={selectedPollData['answer' + (index + 1)]}
                                            showLabel={false}
                                            currentState={pollType === 'predictive' ? answer.correct : answer.included}
                                            toggleFunc={(e, id) => {
                                                if (pollType === 'predictive') {
                                                    const correct = !answer.correct;
                                                    resetCorrectAnswers();
                                                    answer.correct = correct;
                                                } else {
                                                    answer.included = !answer.included;
                                                }
                                                forceUpdateNow();
                                                // props.setFeatureVars(featureVars);
                                            }}
                                            enabled={toggleEnabled}
                                        />
                                    </div>
                                }
                                <div className="grow answer-text">
                                    {selectedPollData['answer' + (index + 1)]}
                                </div>
                                <div className="shrink vote-percentage">
                                    {answer.votePercentage}%
                                </div>
                            </div>
                        )
                    })}
                </>
            }
        </div>
    )
}

export default LivePollAnswerSelector;
