import BotsOverview from "../Campaigns/BotsOverview";
import { CampaignContext } from "../../contexts/CampaignContext";
import { useContext } from "react";

const TabBots = (props) => {
    const [campaignContext, setCampaignContext] = useContext(CampaignContext);
    return (
        <div className="split-content">
            <div className="column">
                <div className="underlined-panel-heading">
                    <h4>Stream Bots</h4>
                    <div className="grow"></div>
                    {!props._botsAvailable && <div className="error">Bots not currently available</div>}
                    {props._botsAvailable && !props._streamStarted && <div className="error">Automated bots not yet started</div>}
                </div>
                <div className="ms-full-scroll-area">
                    <BotsOverview 
                        bots={campaignContext.campaignData.chatbots} 
                        botsAvailable={props._botsAvailable}
                        streamStarted={props._streamStarted}
                        streamLiveTime={props._streamLiveTime} 
                        timedBots={props._timedBots} 
                        firedBots={props._analyticsData.botsFired} 
                        controlMode={true} 
                        triggerBotFunc={props._triggerBotPost} 
                        pauseBotFunc={props._pauseBot} 
                        unpauseBotFunc={props._unpauseBot} />
                </div>
            </div>

        </div>
    )
}

export default TabBots
