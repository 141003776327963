import React, { useState, useEffect, useContext } from "react";
import PropTypes from 'prop-types'; 
import validator from "validator";

import axios from "../../api/axios";

import DebugLogger from "../../helpers/DebugLogger";
import { toast } from "react-toastify";
import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import FormField from "../../components/forms/FormField/FormField";
import RadioButtonGroup from "../../components/forms/RadioButtonGroup/RadioButtonGroup";
import TextArea from "../../components/forms/TextArea/TextArea";
import { CampaignContext } from "../../contexts/CampaignContext";
import { updateCampaignBotRoute } from "../../api/routes";
import HMSTimeField from "../../components/forms/HMSTimeField/HMSTimeField";
import { BOT_TYPES, getBotTypesListData } from "../../helpers/Bots";
import { IconJsxer } from "../../helpers/IconHelper";

const EditStreamBot = (props) => {
    const [campaignContext, setCampaignContext] = useContext(CampaignContext);
    const [botName, setBotName] = useState(props.bot.name);
    const [botNameError, setBotNameError] = useState('');
    const [botType, setBotType] = useState(props.bot.botType);
    const [botCommand, setBotCommand] = useState(props.bot.botCommand);
    const [botCommandError, setBotCommandError] = useState('');
    const [responseTimeout, setResponseTimeout] = useState(props.bot.responseTimeout);
    const [responseTimeoutError, setResponseTimeoutError] = useState('');
    const [autoPostTime, setAutoPostTime] = useState(props.bot.autoPostTime);
    const [autoPostTimeError, setAutoPostTimeError] = useState('');
    const [botCopy, setBotCopy] = useState('');
    const [addedBotCopy, setAddedBotCopy] = useState(props.bot.botCopy);
    const [botCopyError, setBotCopyError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const botTypeSelected = (e, id, val) => {
        setBotType(val)
    }

    const addBotCopy = (e) => {
        e.preventDefault();
        if (botCopy !== '') {
            addedBotCopy.push(botCopy);
            setAddedBotCopy(addedBotCopy);
            setBotCopy('');
        }
    }

    const removeBotCopy = (index) => {
        let updatedBotCopyArr = [];
        for (let i = 0; i < addedBotCopy.length; i++) {
            if (i !== index) {
                updatedBotCopyArr.push(addedBotCopy[i]);
            }
        }
        setAddedBotCopy(updatedBotCopyArr);
    }

    const saveBot = (e) => {
        e.preventDefault();

        let formValid = true;
        if (botName === '') {
            setBotNameError('You need to enter a name for your bot');
            formValid = false;
        } else {
            setBotNameError('');
        }
        if (botCopy === '' && addedBotCopy.length === 0) {
            setBotCopyError('You need to enter some copy for your bot');
            formValid = false;
        } else {
            setBotCopyError('');
        }
        if (botType === BOT_TYPES.auto.dbString && autoPostTime < 0) {
            setAutoPostTimeError('Automatic bots need an auto post time of at least 0');
            formValid = false;
        } else {
            if (botType === BOT_TYPES.auto.dbString) {
                setAutoPostTimeError('');
            }
        }
        if (botType === BOT_TYPES.interval.dbString && autoPostTime < 30) {
            setAutoPostTimeError('Interval bots need an auto post interval of at least 30 seconds');
            formValid = false;
        } else {
            if (botType === BOT_TYPES.interval.dbString) {
                setAutoPostTimeError('');
            }
        }
        if (botType === BOT_TYPES.response.dbString && responseTimeout < 30) {
            setResponseTimeoutError('Response bots need an response timeout of at least 30 seconds');
            formValid = false;
        } else {
            setResponseTimeoutError('');
        }
        if (botType === BOT_TYPES.response.dbString && botCommand === '') {
            setBotCommandError('You need to enter a command to trigger your bot');
            formValid = false;
        } else {
            setBotCommandError('');
        }

        if (formValid) {
            // do axios post here

            // First let's just deal with out bot copy array.
            // For the ultimate ux experience, we can add a single copy / response without actually clicking the Add Copy button,
            // we can just leave it in the text box. 
            // Also if we forget to add our last piece of copy, we can deal with that here as well.
            let addedBotCopyToSend = addedBotCopy;
            if (addedBotCopyToSend.length === 0) {
                addedBotCopyToSend = [botCopy];
            }
            if (botCopy !== '' && addedBotCopyToSend[addedBotCopyToSend.length - 1] !== botCopy) {
                addedBotCopyToSend.push(botCopy);
            }

            setIsSubmitting(true);
            console.log(props.bot.botID)
            axios
            .post(updateCampaignBotRoute, { 
                campaignID:props.campaignId,
                botID:props.bot.id,
                botName:botName, 
                botType:botType, 
                autoPostTime:autoPostTime, 
                botCopy:addedBotCopyToSend,
                botCommand: botCommand,
                responseTimeout: responseTimeout,
                withCredentials:true
            })
            .then((response) =>{
                console.log(response)

                let oldCampaignData = campaignContext.campaignData
                oldCampaignData.chatbots = response.data.campaignData.chatbots;

                setCampaignContext((oldValues) => {
                    return { ...oldValues, initialising: false, campaignData: oldCampaignData }
                })

                toast.success("Bot ("+botName+") successfully edited");
                props.closePanelFunc();
            })
        }
    }

    return (
        <PopUpPanel closePanelFunc={props.closePanelFunc}>
            <div className="form-holder co-popup-content">
                <form id="addstreambot" className="form">
                    <h2>Edit Stream Bot</h2>
                    <FormField
                        type="text"
                        id="bot_name"
                        placeholder="Enter Bot Name"
                        setFunc={setBotName}
                        label="Bot Name"
                        error={botNameError}
                        value={botName}
                    />
                    <RadioButtonGroup
                        id="bot_type"
                        label="Bot Type"
                        selectFunc={botTypeSelected}
                        options={getBotTypesListData()}
                        selectedOption={botType}
                    />
                    {(botType === BOT_TYPES.auto.dbString || botType === BOT_TYPES.interval.dbString) &&
                        <div className="fl-row">
                            <HMSTimeField
                                id="auto_post"
                                setFunc={setAutoPostTime}
                                label={`${botType === BOT_TYPES.auto.dbString ? 'Auto ' : ''}Post Time${botType === BOT_TYPES.interval.dbString ? ' Interval' : ''}`}
                                labelNote={`Hours, minutes and seconds ${botType === BOT_TYPES.auto.dbString ? 'until the' : 'between'} bot posts.`}
                                error={autoPostTimeError}
                                value={autoPostTime}
                            />
                        </div>
                    }
                    {botType === BOT_TYPES.response.dbString &&
                        <>
                            <FormField
                                type="text"
                                id="bot_command"
                                placeholder="Enter Bot Command"
                                setFunc={setBotCommand}
                                label="Bot Command"
                                labelNote="The bot will respond when this command is typed into chat"
                                error={botCommandError}
                                value={botCommand}
                            />
                            <HMSTimeField
                                id="response_timeout"
                                showHours={false}
                                setFunc={setResponseTimeout}
                                label="Response Timeout"
                                labelNote="Minimum time between responses"
                                error={responseTimeoutError}
                                value={responseTimeout}
                            />
                        </>
                    }
                    <div className="stream-bot-add-copy">
                        <div className="grow">
                            <TextArea
                                id="bot_copy"
                                setFunc={setBotCopy}
                                label="Bot Copy"
                                labelNote="Add multiple messages and your bot choose from them at random"
                                rows={3}
                                error={botCopyError}
                                value={botCopy}
                            />
                        </div>
                        <button className="add-bot-copy-btn" onClick={addBotCopy}>Add Copy</button>
                    </div>
                    {addedBotCopy.length > 0 &&
                        <>
                            <div className="form-field-content label">
                                Added Bot Copy
                            </div>
                            <div className="added-bot-copy">
                            {addedBotCopy.map(
                                (val, i) => {
                                    return (
                                        <div className="fl-row" key={'bot_copy_' + i}>
                                            <p>{val}</p>
                                            <div className="grow"></div>
                                            <div className="remove-bot-copy" onClick={(e) => removeBotCopy(i)}>
                                                {IconJsxer.GetIcon(IconJsxer.ICONS.checkX, IconJsxer.ICON_STYLES.standard)}
                                            </div>
                                        </div>
                                    )
                                }
                            )}
                            </div>
                        </>
                    }
                    <button className={'submit-btn' + (isSubmitting ? ' button-inactive' : ' button-active')} onClick={isSubmitting ? null : saveBot}>
                        {isSubmitting ? "Saving Bot" : "Save Bot"}
                    </button>
                </form>
            </div>
        </PopUpPanel>
    )
}

export default EditStreamBot;
