import ProfilePic, { PROFILE_PIC_SIZES } from "../../components/ProfilePic/ProfilePic";
import ToggleCheck from "../../components/forms/ToggleCheck/ToggleCheck";
import { getAcTypeData } from "../../helpers/AccountTypes";

const ListedUser = (props) => {
    const { user, selected, toggleSelectedFunc, showCheck } = props;

    const toggleSelectedHandler = (e) => {
        e.preventDefault();
        if (toggleSelectedFunc) {
            toggleSelectedFunc(e, user);
        }
    }

    return (
        <div className="fl-row">
            <ProfilePic size={PROFILE_PIC_SIZES.medium} user={user} />
            <div className="user-name">{user.name}</div>
            <div className="grow" />
            {showCheck &&
                <div className="centered">
                    <ToggleCheck id={user._id} currentState={selected} toggleFunc={toggleSelectedHandler} uncheckedStyle={props.uncheckedStyle || "add"} />
                </div>
            }
        </div>
    );
}

export default ListedUser;
