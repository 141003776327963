export const REGIONS = {
    any: {
        displayName: 'Global',
        dbString: 'any',
    },
    us: {
        displayName: 'United States (US)',
        dbString: 'us',
    },
    uk: {
        displayName: 'United Kingdom (UK)',
        dbString: 'uk',
    },
    au: {
        displayName: 'Australia (AU)',
        dbString: 'au',
    },
    br: {
        displayName: 'Brazil (BR)',
        dbString: 'br',
    },
    ca: {
        displayName: 'Canada (CA)',
        dbString: 'ca',
    },
    cn: {
        displayName: 'China (CN)',
        dbString: 'cn',
    }
    ,
    de: {
        displayName: 'Germany (DE)',
        dbString: 'de',
    },
    es: {
        displayName: 'Spain (ES)',
        dbString: 'es',
    },
    fr: {
        displayName: 'France (FR)',
        dbString: 'fr',
    },
    hk: {
        displayName: 'Hong Kong (HK)',
        dbString: 'hk',
    },
    it: {
        displayName: 'Italy (IT)',
        dbString: 'it',
    },
    jp: {
        displayName: 'Japan (JP)',
        dbString: 'jp',
    },
    kr: {
        displayName: 'Korea (KR)',
        dbString: 'kr',
    },
    mx: {
        displayName: 'Mexico (MX)',
        dbString: 'mx',
    },
    nl: {
        displayName: 'Netherlands (NL)',
        dbString: 'nl',
    },
    se: {
        displayName: 'Sweden (SE)',
        dbString: 'se',
    },
    sg: {
        displayName: 'Singapore (SG)',
        dbString: 'sg',
    },
    tw: {
        displayName: 'Taiwan (TW)',
        dbString: 'tw',
    },
    none: {
        displayName: 'None (N/A)',
        dbString: 'none',
    },
    other: {
        displayName: 'Other (N/A)',
        dbString: 'other',
    }
}

export const REGIONS_ORDER = [
    REGIONS.any,
    REGIONS.us,
    REGIONS.uk,
    REGIONS.au,
    REGIONS.br,
    REGIONS.ca,
    REGIONS.de,
    REGIONS.es,
    REGIONS.fr,
    REGIONS.hk,
    REGIONS.it,
    REGIONS.jp,
    REGIONS.kr,
    REGIONS.mx,
    REGIONS.nl,
    REGIONS.se,
    REGIONS.sg,
    REGIONS.tw,
    REGIONS.none,
    REGIONS.other
]

export const getRegionDataFromDbString = (dbString) => {
    for (let i = 0; i < REGIONS_ORDER.length; i++) {
        if (REGIONS_ORDER[i].dbString === dbString) {
            return REGIONS_ORDER[i];
        }
    }
    return REGIONS.any;
}

export const getRegionsDropDownListData = (listArray = [], ignoreRegions = []) => {
    for (let i = 0; i < REGIONS_ORDER.length; i++) {
        if (ignoreRegions.indexOf(REGIONS_ORDER[i]) === -1 && ignoreRegions.indexOf(REGIONS_ORDER[i].displayName) === -1 && ignoreRegions.indexOf(REGIONS_ORDER[i].dbString) === -1) {
            listArray.push({id: REGIONS_ORDER[i].dbString, value: REGIONS_ORDER[i].dbString, label: REGIONS_ORDER[i].displayName});
        }
    }
    return listArray;
}
