import PropTypes from 'prop-types';
import DebugLogger from "../../helpers/DebugLogger";

import "./ConfirmationCodeInputSingle.scss";

const ConfirmationCodeInputSingle = (props) => {
  const { id, submitConfirm, error } = props;
  let codeLength = 6;
  let isPasting = false;

  if (id === '') {
    DebugLogger.warn('No id provided to ConfirmationCodeField, using a random id...');
  }

  function handlePaste(e) {
    isPasting = true;
  }

  const checkInputOnKeyUp = function(e) {
    e.preventDefault();
    const key = e.key;
    switch (key)  {
      case "Backspace":
        let el = e.target

        //either delete value or if there is no value, move to the previous and delete that
        if(el.value != "") {
          el.value = "";
        } else {
          //then focus on the previous one
          let splitId = el.id.split("_#")
          let newIdNum = parseInt(splitId[splitId.length-1]) - 1
          if(newIdNum < 0) {
            newIdNum = 0;
          }
          let newId = "";
          //rebuild split id, excluding the id that will be added at the end
          for(var i = 0; i<splitId.length-1; i++) {
            let idPart = splitId[i];  
            
            newId += idPart;
          }
          newId += "_#" + (newIdNum);
      
          let prevElement = document.getElementById(newId)
          if(prevElement) {
            prevElement.value = "";
            prevElement.focus()
          }
        }
      break;

      case "Delete":
        el.val = "";
      break;
    }
  }
  //function to control input, onchange
  const inputComplete = function(e, submitConfirm=props.submitConfirm) {

    const key = e.key;

    //first force it to uppercase
    let el = e.target
    let start = el.selectionStart
    let end = el.selectionEnd;
    el.value = el.value.replace(/[^\w\s]/gi, '').toUpperCase() //remove non alphanumeric values and force to uppercase

    if(isPasting) {

      let idParts = el.id.split("_#");
      let initialIdNum = parseInt(idParts[1]); 
      let currentIdNum = initialIdNum;
      let fullValue = el.value;
      for(let i = 0; i<fullValue.length && (i+initialIdNum)<codeLength; ++i) {
        el.value = fullValue.substring(i, i+1);
        if((i+initialIdNum)<(codeLength)) {
          let nextElement = document.getElementById(idParts[0] + "_#" + ++currentIdNum);
          if(nextElement) {
            el = nextElement;
          }
        }
      }

      if(submitConfirm) {
        isPasting = false;
        submitConfirm(e);
      } else {
        isPasting = false;
        el.focus();
        el.value=el.value;
      }

    } else {
      el.value = el.value.substring(el.value.length - 1) //force only 1 character
      
      el.setSelectionRange(start, end)
    
      //then focus on the next one if there is a value
      if(el.value && el.value != '') {
        let splitId = el.id.split("_#")
        let newIdNum = parseInt(splitId[splitId.length-1]) + 1
        let newId = "";
        //rebuild split id, excluding the id that will be added at the end
        for(var i = 0; i<splitId.length-1; i++) {
          let idPart = splitId[i];  
          
          newId += idPart;
        }
        newId += "_#" + (newIdNum);
    
        let nextElement = document.getElementById(newId)
        if(nextElement) {
          nextElement.focus()
        } else {
          submitConfirm(e);
        }
      }
    }
  
  };


  return (
    <div className="confirmation-code-input-single-holder">

      <div className="confirmation-code-input-single-content-holder">

        <div className="confirmation-code-input-single-content">
          <input
            type="text"
            id={id !== '' ? id : Math.floor(Math.random() * 100000).toString()}
            name={id !== '' ? id : Math.floor(Math.random() * 100000).toString()}
            onChange={
              (event) => { 
                inputComplete(event)
              }
            }
            onKeyUp={
              (event) => {
                checkInputOnKeyUp(event)
              }
            }
            onPaste={
              (event) => {
                handlePaste(event)
              }
            }
          />
        </div>
      </div>

    </div>
  );
};

ConfirmationCodeInputSingle.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
};

ConfirmationCodeInputSingle.defaultProps = {
  type: '',
  id: '',
  error: '',
  placeholder: '',
  pattern: '',
}

export default ConfirmationCodeInputSingle;
