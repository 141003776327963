import "./OneStreamOverview.scss";

import { useNavigate } from "react-router-dom";
import React, { useContext } from "react";
import { CampaignContext } from "../../contexts/CampaignContext";

import { toast } from "react-toastify";
import PropTypes from 'prop-types';
import { convertMSToHMS, getShortDateTimeString, getShortTimeString, getTzLongDateString } from "../../helpers/Dates";
import { IconJsxer } from "../../helpers/IconHelper";
import HeadedPanel from "../HeadedPanel/HeadedPanel"
import { TwitchIconSvg } from "../../assets/assets";
import { STREAM_STATUS_TYPES, getStreamTypeDataFromDbString, getTwitchStreamUrl } from "../../helpers/Streams";
import { TOP_LEVEL_NAV_ROUTES } from "../../config/NavRoutes";
import LeftColorBlockBox from "../LeftColorBlockBox/LeftColorBlockBox";
import LeftColorBlockHeadedPanel from "../LeftColorBlockBox/LeftColorBlockHeadedPanel";
import { AuthContext } from "../../contexts/AuthContext";
import { ACCOUNT_TYPES } from "../../helpers/AccountTypes";
import {CHANNEL_COLOURS } from "../../helpers/AnalyticsFunctions";

const OneStreamOverview = (props) => {
    const {stream, timezoneData} = props;
    const [campaignContext, setCampaignContext] = useContext(CampaignContext);

    const startTimeHms = convertMSToHMS(stream.startTime, false, timezoneData.offset);
    const endTimeHms = convertMSToHMS(stream.endTime, false, timezoneData.offset);
    const streamUrl = getTwitchStreamUrl(stream.twitchHandle);
    const statusData = getStreamTypeDataFromDbString(stream.status);
    const [authContext, setAuthContext] = useContext(AuthContext);
    const copyStreamLinkToClipboard = () => {
        navigator.clipboard.writeText(streamUrl);
        toast.success("Stream Url copied to clipboard!");
    }

    const openTwitchChannel = (twitchHandle = null) => {
        let urlToOpen = streamUrl;
        if (twitchHandle) {
            urlToOpen = getTwitchStreamUrl(twitchHandle);
        }
        window.open(urlToOpen, '_blank');
    }

const openAddStreamChannel = (e) => {
   
    props.addStreamChannelFunc(e, props.stream._id)
}


    const navigate = useNavigate();
    const openManageStream = (e, campaignId = null, streamId = null) => {
        e.preventDefault();
        
        campaignId = campaignId || campaignContext.campaignData._id;
        streamId = streamId || props.stream._id;

        navigate(TOP_LEVEL_NAV_ROUTES.MANAGE_CAMPAIGN_STREAM.replace(':campaignId', campaignId).replace(':streamId', streamId));
    }

    const streamStatus = stream.archived ? "archived" : statusData.dbString;
    return (
        <div className='no-panel-shadow'>
           
                
            <LeftColorBlockHeadedPanel  style={'campaign_' + streamStatus}
                
                headingContent={
                    <div className='fl-row list-view-content'>
                        <div className="fl-row shrink">
                            <div className='fl-row stream-list-view-data-block'>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.scheduleNav, IconJsxer.ICON_STYLES.listIcon)}
                                {getTzLongDateString(stream.startTime, timezoneData.offset)}
                            </div>
                        
                            {!props.condensed &&
                                <>
                                    <div className='fl-row stream-list-view-data-block'>
                                        {IconJsxer.GetIcon(IconJsxer.ICONS.clock, IconJsxer.ICON_STYLES.listIcon)}
                                        {`${startTimeHms.h_str}:${startTimeHms.m_str} - ${endTimeHms.h_str}:${endTimeHms.m_str} (${timezoneData.label.replace('(', '').replace(')', '')})`}
                                    </div>
    
                                    <div className='fl-row stream-list-view-data-block light-text sl-local-time'>
                                        {IconJsxer.GetIcon(IconJsxer.ICONS.clock, IconJsxer.ICON_STYLES.listIcon)}
                                        {getShortDateTimeString(stream.startTime, false, ', ')} - {getShortTimeString(stream.endTime, false)} (Local Time)
                                    </div>
                                </>
                            }
                        </div>
    
                        {/* <div className="grow"></div> */}
                        <div className="grow"></div>
                        {!props.condensed && !props.useCustomContentChildren &&
                            <>
                                {/* <button className='standard-button' onClick={openTwitchChannel}>
                                    <div className="lcb-button-icon">
                                        {TwitchIconSvg}
                                    </div>
                                </button> */}
                                {!props.isStreamerOverview && authContext.userData.userLevel >= ACCOUNT_TYPES.creative.level &&
                                    <button className='standard-button' onClick={openManageStream}>
                                        {IconJsxer.GetIcon(IconJsxer.ICONS.cog, IconJsxer.ICON_STYLES.listIcon)}
                                    </button>
                                }
                            </>
                        }
    
                        {props.editMode && !props.condensed && !props.useCustomContentChildren && authContext.userData.userLevel >= ACCOUNT_TYPES.producer.level &&
                            <>
                                {!stream.archived &&
                                    <>
                                        <div className={`edit-icon${` ${statusData.headedPanelColorStr}-color-heading`}`} onClick={props.editFunc ? (e) => props.editFunc(stream) : null}>
                                            {IconJsxer.GetIcon(
                                                IconJsxer.ICONS.edit,
                                                IconJsxer.ICON_STYLES.campaignPanelTop,
                                            )}
                                        </div>
                                        <div className={`edit-icon${` ${statusData.headedPanelColorStr}-color-heading`}`} onClick={props.removeFunc ? (e) => props.removeFunc(stream) : null}>
                                            {IconJsxer.GetIcon(
                                                IconJsxer.ICONS.trash,
                                                IconJsxer.ICON_STYLES.campaignPanelTop,
                                            )}
                                        </div>
                                    </>
                                }
                                {stream.archived &&
                                    <>
                                        <div className="edit-icon-gr" onClick={props.removeFunc ? (e) => props.removeFunc(stream) : null}>
                                            {IconJsxer.GetIcon(
                                                IconJsxer.ICONS.undelete,
                                                IconJsxer.ICON_STYLES.campaignPanelTop,
                                            )}
                                        </div>
                                    </>
                                }
                            </>
                        }
                    </div>
                }>
              
                {!props.useCustomContentChildren &&
                    <>
                        <div className="fl-row">
                            <h4>Connected Twitch Channels</h4>


                            <div className="grow"></div>
                            {authContext.userData.userLevel >= ACCOUNT_TYPES.producer.level &&

                                <div className={`add-btn`} onClick={openAddStreamChannel}>
                                    {IconJsxer.GetIcon(IconJsxer.ICONS.plus, IconJsxer.ICON_STYLES.roundPanelButtonSmall)}

                                </div>
                            }
                        </div>

                        <hr />

                        <div className="fl-column compact">
                            {props.stream.channels.map(
                                (val, i) => {
                                    return (
                                        <LeftColorBlockBox style={{ customColor: CHANNEL_COLOURS[i % 8] }} whiteIcon={true} useImageIcon={true} imageIcon={val.profilePic} key={'chan_' + i}>
                                            <div className='list-view-content channel fl-row'>


                                                <div className="list-view-label">
                                                    {val.twitchHandle}
                                                </div>

                                                <div className="edit-icon" onClick={copyStreamLinkToClipboard}>{IconJsxer.GetIcon(IconJsxer.ICONS.copy, IconJsxer.ICON_STYLES.standard)}</div>
                                                <div className="grow"></div>
                                                <div className="shrink">
                                                    <button className='standard-button' onClick={() => openTwitchChannel(val.twitchHandle)}>
                                                        <div className="lcb-button-icon">
                                                            {TwitchIconSvg}
                                                        </div>
                                                    </button>
                                                </div>
                                                {props.editMode && authContext.userData.userLevel >= ACCOUNT_TYPES.producer.level &&
                                                    <div className={`edit-icon${` ${statusData.headedPanelColorStr}-color-heading`}`} onClick={props.removeStreamChannelFunc ? (e) => props.removeStreamChannelFunc(props.stream._id, val.twitchHandle) : null}>
                                                        {IconJsxer.GetIcon(
                                                            IconJsxer.ICONS.trash,
                                                            IconJsxer.ICON_STYLES.campaignPanelTop,
                                                        )}
                                                    </div>
                                                }
                                            </div>
                                        </LeftColorBlockBox>
                                    )
                                }
                            )}
                        </div>
                    </>
                }
                {props.useCustomContentChildren &&
                    props.children
                }
            </LeftColorBlockHeadedPanel>
        </div>
    )
}

OneStreamOverview.propTypes = {
    stream: PropTypes.object,
    editMode: PropTypes.bool,
    editFunc: PropTypes.func,
    removeFunc: PropTypes.func,
    duplicateFunc: PropTypes.func,
};

OneStreamOverview.defaultProps = {
    stream: {},
    editMode: false,
    editFunc: null,
    removeFunc: null,
    duplicateFunc: null,
}

export default OneStreamOverview;