import { useState } from "react";
import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import FormField from "../../components/forms/FormField/FormField";
import { IconJsxer } from "../../helpers/IconHelper";

const ForceStopPopup = (props) => {
    const [forceStop, setForceStop] = useState('');
    const [forceStopError, setForceStopError] = useState('');
    const forceStopNow = () => {
        if (forceStop === 'RESET') {
            setForceStopError('');
            props.forceStopNow();
            props.closePanelFunc();
        } else {
            setForceStopError('Type RESET above to confirm.');
        }
    }

    return (
        <PopUpPanel closePanelFunc={props.closePanelFunc}>
            <div className="fl-column red-force-stop-text form-holder">
                <h2>FORCE STOP!</h2>
                <div className="fl-row">
                    <div className="grow"></div>
                    <div>{IconJsxer.GetIcon(IconJsxer.ICONS.error, IconJsxer.ICON_STYLES.forceStopPopIcon)}</div>
                    <div className="grow"></div>
                </div>
                <div></div>
                <div>You should only do this if you are encountering<br />problems during a live stream.</div>
                <div>Your analytics session will be ended and<br />the extension page will be silently reloaded.</div>
                <div></div>
                <div></div>
                <div className="warning-text">Type "RESET" in the box below and<br />click the FORCE STOP button to continue.</div>
                <div></div>
                <FormField
                    type="text"
                    id="str_handle"
                    placeholder=""
                    setFunc={setForceStop}
                    label="Force Stop?"
                    value={forceStop}
                    showLabel={false}
                    error={forceStopError}
                />
                <div></div>
                <div className="fl-row grow">
                    <div className="grow"></div>
                    <button className="standard-button" onClick={forceStopNow}>FORCE STOP!</button>
                    <div className="grow"></div>
                </div>
            </div>
        </PopUpPanel>
    )
}

export default ForceStopPopup;
