import "./LeftColorBlockBox.scss";
import PropTypes from 'prop-types';
import { IconJsxer } from "../../helpers/IconHelper";
import { LEFT_COLOR_BLOCK_STYLES } from "./LeftColorBlockBox";

const LeftColorBlockHeadedPanel = (props) => {
    const {style, fullWidth, headingContent, children, overrideColor, overrideBgColor, className} = props;
    let styleData = style;

    if (typeof styleData === 'string') {
        if (styleData === 'feature') styleData = 'feature_default';
        if (styleData === 'disruptor') styleData = 'disruptor_default';
        styleData = LEFT_COLOR_BLOCK_STYLES[styleData];
    }
    if (typeof styleData === 'undefined') {
        styleData = LEFT_COLOR_BLOCK_STYLES.default;
    }

    let overrideStyleData = overrideColor;
    if (overrideColor) {
        if (typeof overrideStyleData === 'string') {
            if (overrideStyleData === 'feature') overrideStyleData = 'feature_default';
            if (overrideStyleData === 'disruptor') overrideStyleData = 'disruptor_default';
            overrideStyleData = LEFT_COLOR_BLOCK_STYLES[overrideStyleData];
        }
        if (typeof overrideStyleData === 'undefined') {
            overrideStyleData = LEFT_COLOR_BLOCK_STYLES.default;
        }
    }

    const extraStyle = {};
    if (overrideBgColor) {
        extraStyle.backgroundColor = overrideBgColor;
    }

    return (
        <div className={`lcb-headed-panel-holder ${fullWidth ? 'lcb-full-width' : ''}${className ? ` ${className}` : ' grow'}`}>
            <div className={`left-color-block-box grow lcb-heading`}>
                <div className={`content-block left ${'lcb-' + (overrideStyleData ? overrideStyleData.color : styleData.color) + '-left'}${props.whiteIcon ? ' white-icon' : ''} lcb-heading`}>
                    {IconJsxer.GetIcon(styleData.icon, IconJsxer.ICON_STYLES.analyticsColourBlockIcon)}
                </div>
                <div className={`content-block right ${'lcb-' + (overrideStyleData ? overrideStyleData.color : styleData.color) + '-right'} lcb-heading`} style={extraStyle}>
                    {headingContent}
                </div>
            </div>
            <div className="lcb-headed-content" style={extraStyle}>
                {children}
            </div>
        </div>
    )
}

LeftColorBlockHeadedPanel.propTypes = {
    style: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    className: PropTypes.string,
    overrideColor: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    overrideBgColor: PropTypes.string,
    fullWidth: PropTypes.bool,
    headingContent: PropTypes.element,
};

LeftColorBlockHeadedPanel.defaultProps = {
    style: 'default',
    fullWidth: true,
}

export default LeftColorBlockHeadedPanel;
