import { useState } from "react";
import "./HeadedPanel.scss";
import PropTypes from 'prop-types';
import { IconJsxer } from "../../helpers/IconHelper";

const HeadedPanel = (props) => {
    const { color, className, children, headingContent } = props;
    const [expanded, setExpanded] = useState(props.expandable ? (props.startCollapsed ? false : true) : true)
    const toggleExpanded = () => {
        setExpanded(!expanded);
    }

    return (
        <div className={`headed-panel${className ? ` ${className}` : ''}`}>
            <div className={`heading-pane label ${`${color}-color-heading`}`}>
                {headingContent}
                {props.expandable && 
                    <>
                        <div className="grow"></div>
                        <div onClick={toggleExpanded}>
                            {IconJsxer.GetIcon(expanded ? IconJsxer.ICONS.chevronUp : IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.headedPanelCollapse)}
                        </div>
                    </>
                }
            </div>
            <div className={`content-pane ${`${color}-color-content`}${expanded ? '' : ' content-collapsed'}`}>
                <div className={expanded ? 'content-holder' : 'content-holder content-collapsed'}>
                    {children}
                </div>
            </div>
        </div>
    )
}

HeadedPanel.propTypes = {
    color: PropTypes.string.isRequired,
    headingContent: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    className: PropTypes.string,
    expandable: PropTypes.bool,
    startCollapsed: PropTypes.bool,
};

HeadedPanel.defaultProps = {
    color: 'default',
    headingContent: 'Heading',
    expandable: false,
    startCollapsed: false,
}

export default HeadedPanel;
