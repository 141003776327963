import React, { useState, useEffect, useContext } from "react";
import PropTypes from 'prop-types'; 
import validator from "validator";

import axios from "../../api/axios";

import DebugLogger from "../../helpers/DebugLogger";

import "./InviteUser.scss";
import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import FormField from "../forms/FormField/FormField";
import DropDownList from "../forms/DropDownList/DropDownList";
import { ACCOUNT_TYPES, getAcDropListData, getAcTypeData } from "../../helpers/AccountTypes";
import { createUserInvitesRoute } from "../../api/routes";
import { toast } from "react-toastify";
import { AuthContext } from "../../contexts/AuthContext";
import FormDropDown from "../forms/DropDownList/FormDropDown";


const InviteUser = (props) => {
    const [authContext, setAuthContext] = useContext(AuthContext);
    const acSettings = getAcTypeData(authContext.userData.accountType);
    const maxInviteLevel = acSettings.adminSettings.maxInviteLevel;

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    // set our default type
    const listData = getAcDropListData(
        [], 
        maxInviteLevel, 
        [ACCOUNT_TYPES.notset],
        false
    )
    let defaultAcType = null;
    if (props.defaultType) {
        for (let i = 0; i < listData.length; i++) {
            if (listData[i].id === props.defaultType || listData[i].id === props.defaultType.dbString ) {
                listData[i].index = i;
                defaultAcType = listData[i];
                break;
            }
        }
    }
    const [selectedType, setSelectedType] = useState(defaultAcType || ACCOUNT_TYPES.streamer);
    const selectTypeHandler = (e, i, item) => {
        setSelectedType(item);
    }

    const sendInvite = (e) => {
        e.preventDefault();

        let formIsValid = true;
        const emails = email.replace(' ', '').split(',');

        for (let i = 0; i < emails.length; i++) {
            if (!validator.isEmail(emails[i])) {
                formIsValid = false;
                setEmailError("You have entered an invalid email");
                break;
            } else {
                setEmailError("");
            }
        }

        if (formIsValid) {
            let accountType = props.acType || selectedType.id || ACCOUNT_TYPES.notset.dbString;
            setIsSubmitting(true);

            axios
            .post(createUserInvitesRoute, {
                emails: emails,
                acType: accountType,
                campaignIds: [props.campaignId],
            })
            .then(function (response) {
                setIsSubmitting(false);
                props.closePanelFunc();

                if(response.data.success) {
                  toast.success(response.data.message);
                } else {
                  toast.error(response.data.message);
                }
            })
            .catch(function (error) {
                console.log("error = ",error);
                setIsSubmitting(false);
                toast.error('Error - An unexpected error occured');
            });
        }
    }

    return (
        <PopUpPanel closePanelFunc={props.closePanelFunc}>
            <div className="form-holder invite-user-content">
                <form id="inviteform" className="form">
                    <h2>{props.title}</h2>
                    {props.subTitle &&
                        <p className="light-text">{props.subTitle}</p>
                    }
                    <FormField
                        type="email"
                        id="email"
                        placeholder="Enter Email"
                        setFunc={setEmail}
                        label="Email"
                        labelNote="Note: seperate multiple email addresses using a comma"
                        error={emailError}
                    />
                    {props.showAcTypeSelector &&
                        <FormDropDown 
                                id="usertype"
                                selectFunc={selectTypeHandler}
                                currentIndex={selectedType ? selectedType.index : 0}
                                items={listData}
                                label="User Type"
                            />
                    }
                    {!props.showAcTypeSelector && props.acType !== null &&
                        <>
                            <div className="label">
                                User Type
                            </div>
                            <p className="light-text indented">{props.acType ? props.acType.displayName : 'Not Set'}</p>
                        </>
                    }
                    <button className={'submit-btn' + (isSubmitting ? ' button-inactive' : ' button-active')} onClick={isSubmitting ? null : sendInvite}>
                        {isSubmitting ? "Sending Invites" : "Send Invite"}
                    </button>
                </form>
            </div>
        </PopUpPanel>
    )
}

InviteUser.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    closePanelFunc: PropTypes.func.isRequired,
    campaignId: PropTypes.string,
    showAcTypeSelector: PropTypes.bool,
    acType: PropTypes.object,
    defaultType: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

InviteUser.defaultProps = {
    title: 'Invite User',
    subTitle: '',
    closePanelFunc: () => DebugLogger.error('You need to provide a closePanelFunc to InviteUser component.'),
    campaignId: '',
    showAcTypeSelector: true,
    acType: null,
    defaultType: null,
}

export default InviteUser;