import React, { useState, useEffect, useContext } from "react";
//import PropTypes from 'prop-types'; 
import validator from "validator";

import axios from "../../api/axios";

import DebugLogger from "../../helpers/DebugLogger";
import { toast } from "react-toastify";
import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import FormField from "../../components/forms/FormField/FormField";
//import RadioButtonGroup from "../../components/forms/RadioButtonGroup/RadioButtonGroup";
import DropDownList from "../../components/forms/DropDownList/DropDownList";
import Toggle from "../../components/forms/Toggle/Toggle";
import TextArea from "../../components/forms/TextArea/TextArea";
import HMSTimeField from "../../components/forms/HMSTimeField/HMSTimeField";
import { IconJsxer } from "../../helpers/IconHelper";
import ImageUploader2 from "../../components/forms/ImageUpload/ImageUploader2";

import "./EditQuiz.scss";
 import { uploadCustomAssetRoute } from "../../api/routes";
import { getFeatureAssetBaseUrl } from "../../helpers/FeatureAssets";
import FormDropDown from "../../components/forms/DropDownList/FormDropDown";
import { DEFAULT_HASHTAGS } from "./EditQuiz";
const EditQuizQuestion = (props) => {
  
  let question = props.quiz.rounds[props.roundIndex].questions[props.questionIndex];
  let roundId = "round" + props.roundIndex;
  let questionId = roundId + "question" + props.questionIndex;
  let questionTextId = "_questionText";
  let questionImageId = "_questionImage";
  let questionTypeId = "_type";

  console.log('Edit Quiz Question', props);

    const [questionCopy, setQuestionCopy] = useState('');
    const [questionImage, setQuestionImage] = useState('');
    const [questionType, setQuestionType] = useState('');
    const [questionTypeID, setQuestionTypeID] = useState(0);

    const [answerHashtag, setAnswerHashtag] = useState(DEFAULT_HASHTAGS[0]);
    const [answerCopy, setAnswerCopy] = useState('');
    const [answerImage, setAnswerImage] = useState('');
    const [answerID, setAnswerID] = useState(0)
    const [addAnswerMode, setAddAnswerMode] = useState(false);
    const [editAnswerMode, setEditAnswerMode] = useState(false);
    const [isCorrectAnswer, setIsCorrectAnswer] = useState(false);
    const [usePreviousWinner, setUsePreviousWinner] = useState(false);
    const [answerExplanation, setAnswerExplanation] = useState('');
    const [editAnswerIndex, setEditAnswerIndex] = useState(0);

    const [questionValid, setQuestionValid] = useState(false);

    useEffect(() => {
        setQuestionCopy(question.questionText);
        setQuestionImage(
            {filename:  question.questionImage,     
                baseURL:  getBaseURL()})
        setAnswerImage(null)
        setAddAnswerMode(question.answers.length == 0)

        const availableQuestionTypes = props.quizTypes[props.quizTypeIndex].availableQuestionTypes;
        let validType = false;
        for (let i = 0; i < availableQuestionTypes.length; i++) {
            if (availableQuestionTypes[i].value == question.type) {
                validType = true;
                break;
            }
        }
        if (!validType) {
            question.type = availableQuestionTypes[0].value;
            question.typeID = availableQuestionTypes[0].index;
        }
        setQuestionType(question.type)
        setQuestionTypeID(question.typeID ? question.typeID : 0)

        console.log('TYPE ID:', question, availableQuestionTypes[0] )

        if (question.answers && question.answers.length > 0) {
            setTimeout(validateQuestion, 100);
        }
    }, [])

    const getBaseURL = () => {
        return getFeatureAssetBaseUrl()+'campaigns/'+ props.campaignId+'/quizzes/'+ props.quiz._id+'/image/'
    }

    const closeAddEdit = () => {
        setAddAnswerMode(false);
        setEditAnswerMode(false);
    }

    const changeQuestionImage = (imgData, isQuestionImage) =>{

        console.log(imgData)
     
        if(!imgData.file)
        {
            setAnswerImage(null);
            return;
        }


        console.log(imgData.file ? URL.createObjectURL(imgData.file) : null)

        const filename = imgData.file.name.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "").toLowerCase();
        console.log(filename)
        const formData = new FormData();
         
        formData.append('filename', filename);
        formData.append('campaignID', props.campaignId);
        formData.append('featureID', 'quizzes/'+ props.quiz._id);

        formData.append('type', 'image');
        formData.append('file', imgData.file);
        axios.post(uploadCustomAssetRoute, formData, { withCredentials: true })
            .then(res => {
                if(isQuestionImage)
                {
                    setQuestionImage({
                        filename: res.data.uploadedFilename,     
                        baseURL:  getBaseURL()
                    })
                    props.updateQuizState(res.data.uploadedFilename, `rounds ${props.roundIndex} questions ${props.questionIndex} questionImage`)
                }
                else
                {
                    setAnswerImage(res.data.uploadedFilename)
                }
                
                DebugLogger.log('Upload result: ', res);
                
              
            })
            .catch(err => {
                DebugLogger.log('Upload error: ', err);
                toast.error('Error Uploading File - ' + err);
           
            });

    }

    const addNewAnswer = () =>{
        setAnswerHashtag(DEFAULT_HASHTAGS[question.answers.length]);
        setIsCorrectAnswer(false);
        setUsePreviousWinner(false);
        setAnswerCopy('');
        setAddAnswerMode(true);
        setEditAnswerMode(false);
        setAnswerExplanation('');
        setAnswerImage(null)
    }

    const saveNewAnswer = (e) => {
        console.log( answerHashtag, answerCopy, answerImage)
        props.addAnswer(e, props.roundIndex, props.questionIndex, answerHashtag, answerCopy, answerImage, answerExplanation, isCorrectAnswer, usePreviousWinner)
        setAddAnswerMode(false);
        validateQuestion();
        // toast.success('Answer added to question')
    }
    const updateAnswer = () =>{
        console.log('image', )
        props.updateQuizState(answerHashtag, `rounds ${props.roundIndex} questions ${props.questionIndex} answers ${editAnswerIndex} hashtag`)
        props.updateQuizState(answerImage, `rounds ${props.roundIndex} questions ${props.questionIndex} answers ${editAnswerIndex} answerImage`)
        props.updateQuizState(answerExplanation, `rounds ${props.roundIndex} questions ${props.questionIndex} answers ${editAnswerIndex} answerExplanation`)
        props.updateQuizState(answerCopy, `rounds ${props.roundIndex} questions ${props.questionIndex} answers ${editAnswerIndex} answerText`)
        props.updateQuizState(isCorrectAnswer, `rounds ${props.roundIndex} questions ${props.questionIndex} answers ${editAnswerIndex} correctAnswer`)
        props.updateQuizState(usePreviousWinner, `rounds ${props.roundIndex} questions ${props.questionIndex} answers ${editAnswerIndex} usePreviousWinner`)
        setEditAnswerMode(false);
        validateQuestion();
        // toast.success('Answer updated')
    }

    const [questionCopyError, setQuestionCopyError] = useState('');
    const [answersError, setAnswersError] = useState('');
    const validateQuestion = () => {
        let isValid = true;
        const copy = questionCopy || question.questionText;
        const image = questionImage || question.questionImage;
        console.log('Validate copy: ', copy, image);
        if ((!copy || copy.length === 0) && (!image || !image.filename || image.filename.length === 0)) {
            setQuestionCopyError("Copy or Image is required");
            isValid = false;
        } else {
            setQuestionCopyError("");
        }

        let newAnswerError = "";
        if (question.answers.length < 2) {
            newAnswerError = "At least two answers are required";
            isValid = false;
        } else {
            let validAnswers = true;
            let correctAnswers = 0;
            let answerHashtags = [];
            let validHashtags = true;
            for (let i = 0; i < question.answers.length; i++) {
                if (props.quiz.type !== "Tug of War" || !question.answers[i].usePreviousWinner) {
                    if ((!question.answers[i].answerText || question.answers[i].answerText.length === 0) && (!question.answers[i].answerImage || question.answers[i].answerImage.length === 0)) {
                        validAnswers = false;
                    }
                    if (question.answers[i].correctAnswer) {
                        correctAnswers++;
                    }
                    if (!question.answers[i].hashtag || question.answers[i].hashtag.length === 0 || answerHashtags.indexOf(question.answers[i].hashtag) >= 0) {
                        validHashtags = false;
                    }
                    answerHashtags.push(question.answers[i].hashtag);
                    console.log('Valid: ', validAnswers, correctAnswers, validHashtags);
                }
            }

            // Polls don't have right or wrong answers so we'll just fake this piece of validation
            if (props.quiz.type === 'Poll' || props.quiz.type == "Tug of War") {
                correctAnswers = 1;
            }

            /* if (!validAnswers || correctAnswers !== 1 || !validHashtags) {
                newAnswerError = `All answers must have text or an image${props.quiz.type !== 'Poll' ? ' and' : ','} include a unique hashtag${props.quiz.type !== 'Poll' ? ' and there must be exactly one correct answer' : ''}`;
                isValid = false;
            } */
            if (correctAnswers !== 1) {
                if (correctAnswers === 0) {
                    newAnswerError += `Please include one correct answer. `;
                } else {
                    newAnswerError += `Please include only one correct answer. `;
                }
                isValid = false;
            }
            // The following messages may never get shown as there is validation when editing answers as well
            if (!validAnswers) {
                newAnswerError += `All answers must have text or an image. `;
                isValid = false;
            }
            if (!validHashtags) {
                newAnswerError += `All answers must have a unique hashtag. `;
                isValid = false;
            }
        }
        setAnswersError(newAnswerError);

        setQuestionValid(isValid);
        return isValid;
    }
    useEffect(() => {
        validateQuestion();
    }, [questionCopy, questionImage, question.answers]);

    const saveQuestion = (e) => {
        if (!validateQuestion()) {
            return;
        }

        props.updateQuizState(questionCopy, `rounds ${props.roundIndex} questions ${props.questionIndex} questionText`);
        props.updateQuizState( questionType,  `rounds ${props.roundIndex} questions ${props.questionIndex} type`);
        props.updateQuizState(questionTypeID,  `rounds ${props.roundIndex} questions ${props.questionIndex} typeID`);
        props.closePanelFunc();
        // toast.success('Question saved')
    }

    const editAnswer = (e, hashtag, copy, image, explanation, isCorrect, index, usePreviousWinner = false ) => {
        console.log( answerHashtag, answerCopy, answerImage, isCorrect)
        setEditAnswerMode(true);
        setAddAnswerMode(false);
        setAnswerHashtag(hashtag);
        setIsCorrectAnswer(isCorrect);
        setUsePreviousWinner(usePreviousWinner);
        setAnswerCopy(copy);
        setAnswerImage(image)
        setAnswerExplanation(explanation)
        setEditAnswerIndex(index)
    }

    console.log('Edit question: ', questionType);
    const maxAnswers = (questionType === 'True or False' || questionType === 'Poll - 50 / 50' || questionType === 'Poll -  Tug of War' || props.quiz.type == "Tug of War") ? 2 : 6;
    let gotOneSetToUsePreviousWinner = false;
    for (let i = 0; i < question.answers.length; i++) {
        if (question.answers[i].usePreviousWinner) {
            gotOneSetToUsePreviousWinner = true;
            break;
        }
    }
 
  return (
    <PopUpPanel wide={true} closePanelFunc={props.closePanelFunc}>
        <div>
            <div className="question_holder form-holder flex" key={questionId}>
            <div>
           
                <div className="s-row">
                <h2>Question</h2>  
                    {questionType != "Image Question with Text Answers" &&
                        <div className="column-cell">
                            <TextArea id="q_copy" label="Question Copy" value={questionCopy} showError={true} error={questionCopyError} placeholder={"Enter question"} setFunc={(val) => { setQuestionCopy(val)}}></TextArea>
                        </div>
                    }
                </div>
                <div className="s-row">
                    <div className="column-cell">
                        {questionType == "Image Question with Text Answers" &&
                            <ImageUploader2
                                leftJustified={false}
                                id={questionId + questionImageId}
                                currentFile={questionImage.filename ?  getBaseURL()+questionImage.filename : null}
                                fileSelectedCallback={(val) => changeQuestionImage(val, true)}
                                removedCallback={() => setQuestionImage('')}
                                showZoomOption={false}
                                zoomFunction={props.zoomFunction}
                                showRemoveOption={true}
                                showLabel={true}
                                label={'Question Image'}
                                labelNote={'Upload an image to support the question, max 600x250 pixels'}
                            />
                        }
                        <div className="s-row">
                            <div className="column-cell">
                            {/* <div className="label">Question type</div> */}
                            { props.questionTypes && props.questionTypes.length>0 && typeof props.quizTypeIndex === 'number' && props.quizTypes[props.quizTypeIndex] &&
                                <FormDropDown
                                    id={questionId + questionTypeId}
                                    items={/* [props.questionTypes[0]].concat */(props.quizTypes[props.quizTypeIndex].availableQuestionTypes)}
                                    selectFunc={(e, id, item) => {console.log(item); setQuestionTypeID(item.index); setQuestionType(item.value)} }
                                    currentIndex={ questionTypeID && questionTypeID < props.quizTypes[props.quizTypeIndex].availableQuestionTypes.length ? questionTypeID : 0 }
                                    icon="list"
                                    label="Question type"
                                    enabled={(props.quizTypes[props.quizTypeIndex].availableQuestionTypes.length == 1) ? false : true}
                                />
                            }
                                 <button  className={'standard-button' + (questionValid ? '' : ' button-inactive')} onClick={ questionValid ? e => { saveQuestion(e) } : null }>Update & Close</button>
                                      
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                    <div className="s-row answer-box">
                      <div className="column-cell answer-title flex">
                          <div><h2>Answers</h2></div>
                            {question.answers.length < maxAnswers && !addAnswerMode && !editAnswerMode &&
                              <div className={`round-button small-add-button`} onClick={(e) => { addNewAnswer() }}>
                                  {IconJsxer.GetIcon(IconJsxer.ICONS.plus, IconJsxer.ICON_STYLES.roundPanelButtonSmall)}
                              </div>
                            }
                          {/*                  </div>
                            { (question.type != "True or False" || question.answers.length < 2) &&
                                <button  className={'standard-button'} onClick={ }}>Add Answer</button>
                            }
                            { (question.type == "True or False" && question.answers.length == 0) &&
                                <p>True or False questions can only contain 2 answers.</p>
                            }*/}
                      </div>

                      {answersError && <div className="error">{answersError}</div>}

                      <div className="scroll-edit-answers">
                        {(addAnswerMode || editAnswerMode) &&
                        
                            <div className="answer_holder">
                                <div className="answer-title flex">
                                    <div>
                                        <h5>{addAnswerMode ? 'Add New Answer' : ' Edit Answer '+(editAnswerIndex+1)}</h5>
                                    </div>
                                    <div className="grow"></div>
                                    <div className={`round-button small-add-button close-add`} onClick={(e) => { closeAddEdit()}}>
                                        {IconJsxer.GetIcon(IconJsxer.ICONS.closeX, IconJsxer.ICON_STYLES.roundPanelButtonSmall)}
                                    </div>  
                                </div>

                                {questionType === 'Poll -  Tug of War' && props.questionIndex > 0 &&
                                    <div className="flex">
                                        <div className="label">Use Previous Winner</div>
                                        <Toggle
                                            id={'usePreviousWinner'}
                                            currentState={usePreviousWinner}
                                            label="Use Previous Winner"
                                            toggleFunc={(e, id) => { console.log(id); setUsePreviousWinner(!usePreviousWinner) }}
                                            enabled={usePreviousWinner || !gotOneSetToUsePreviousWinner || (!addAnswerMode && gotOneSetToUsePreviousWinner && question.answers[editAnswerIndex].usePreviousWinner)}
                                        />  
                                    </div>
                                }
                               
                                {!usePreviousWinner &&
                                <>
                                    <div className='hashtag-container'>
                                        

                                        {questionType != "Text Question with Image Answers" &&
                                            <div className="answer-copy">
                                                <FormField  label="Answer Copy"  id="answer_copy" type="text"  placeholder={"Enter answer copy"} name={'answerCopy'} value={answerCopy} setFunc={(val) => {setAnswerCopy(val)}}></FormField>
                                            </div>
                                        }
                                        
                                       
                                    </div>

                                    <div className="flex">
                                        {/* question.type == "Text Question with Image Answers" && 
                                            <div className="answer-image">
                                                <div className="label">Answer image</div>
                                                <FormField name={'answerImage'} value={answerImage} setFunc={(val) => { setAnswerImage(val)}}></FormField>
                                            </div> 
                                    */}
                                    {questionType == "Text Question with Image Answers" && 
                                          <div> 
                                            <ImageUploader2
                                                leftJustified={false}
                                                id={questionId + questionImageId}
                                                currentFile={answerImage ? getBaseURL()+answerImage : null}
                                                fileSelectedCallback={(val) => changeQuestionImage(val, false)}
                                                removedCallback={() => setAnswerImage(null)}
                                                showZoomOption={false}
                                                zoomFunction={props.zoomFunction}
                                                showRemoveOption={true}
                                                showLabel={true}
                                                label={'Answer Image'}
                                                labelNote={'Upload an image to support the answer, max 450x250 pixels'}
                                            />
                                        </div>
                                    }
                                        <div className='fulltextArea'>
                                            <TextArea label="Answer Explanation" id="answer_explanation" placeholder={"Enter answer explanation"} name={'anserExp'} value={answerExplanation} error=" " showError={true} setFunc={(val) => {setAnswerExplanation(val)}}></TextArea>
                                        </div>
                                    </div>
                                </>
                                }


                                    <div className="flex">
                                    {!usePreviousWinner &&
                                    <>
                                        <div className="hashtag">
                                            <FormField  label="Hashtag" id="hashtag" type="text" placeholder={"Enter hashtag"}  name={'answerHashtag'} value={answerHashtag} setFunc={(val) => { setAnswerHashtag(val)}}></FormField>
                                        </div>
                                        {props.quiz.type !== 'Poll' && props.quiz.type !== "Tug of War" &&
                                            <div>
                                                <div className="label">Correct Answer</div>
                                                <Toggle
                                                    id={'isCorrect'}
                                                    currentState={isCorrectAnswer}
                                                    label="Correct Answer"
                                                    toggleFunc={(e, id) => { console.log(id); setIsCorrectAnswer(!isCorrectAnswer) }}
                                                    enabled={true}
                                                />  
                                            </div>
                                        }
                                    </>
                                    }

                                        <div className='grow'>
                                            {addAnswerMode && 
                                                <button  disabled={!usePreviousWinner && (((!answerImage || answerImage.filename == '') && answerCopy == '') || answerHashtag == '') ? 'disabled' : ''} className={'standard-button'} onClick={ e => { saveNewAnswer(e) } }>Add Answer</button>
                                            }
                                             {editAnswerMode && 
                                                <button  disabled={!usePreviousWinner && (((!answerImage || answerImage.filename == '') && answerCopy == '') || answerHashtag == '') ? 'disabled' : ''} className={'standard-button'} onClick={ e => { updateAnswer(e) } }>Update</button>
                                            }
                                        </div>
                                       
                                    </div>

                                       
                                </div>
                            
                        }




                        {   (question.answers && question.answers.length>0) &&
                            
                            question.answers.map((answer, answerIndex) => {


                        
                                console.log(answer)

                                let answerId = questionId + "_answer" + answerIndex;
                                let answerHashtagId = "_hashtag";
                                let answerTextId = "_answerText";
                                let answerImageId = "_answerImage";

                                if (answerIndex < maxAnswers) {
                                    return(
                                        <div className="answer_holder " key={answerId}>

                                            <div className={`flex single-answer small-gap`}>
                                                <div className={`bold-text ${answer.correctAnswer == true || props.quiz.type == 'Poll' || props.quiz.type == "Tug of War" ? 'correct-answer-box' : 'incorrect-answer-box'}`}> {answerIndex+1}.</div>
                                                {!answer.usePreviousWinner &&
                                                <div>
                                                    <div  className={`hashtag-pill  ${answer.correctAnswer == true || props.quiz.type == 'Poll' || props.quiz.type == "Tug of War" ? 'correct' : 'incorrect'}`}>  <span className="small-text">Hashtag:</span> <span className="bold-text">{ answer.hashtag}</span></div>
                                                    <div className="flex answers">
                                                        {answer.answerImage &&
                                                        
                                                            <div className="img-preview">
                                                            <img src={getBaseURL()+answer.answerImage} />
                                                        </div>
                                                        }
                                                    
                                                        <div>
                                                        {answer.answerText && <p className='tight'><span className="small-text">Answer:</span><br /> <span className="bold-text">{ answer.answerText}</span></p> }
                                                        {answer.answerExplanation && <p><span className="small-text">Answer Explanation:</span><br />{ answer.answerExplanation}</p>}
                                                        </div>
                                                    </div>
                                            
                                                </div>
                                                }
                                                {answer.usePreviousWinner &&
                                                    <div>
                                                        <div className={`hashtag-pill correct`}><span className="bold-text">Using Previous Winner</span></div>
                                                    </div>
                                                }
                                                <div className="grow"></div>
                                        
                                                <div className="edit-icon" onClick={(e) => { editAnswer(e, answer.hashtag, answer.answerText, answer.answerImage, answer.answerExplanation, answer.correctAnswer, answerIndex, answer.usePreviousWinner) }}> 
                                                    {IconJsxer.GetIcon(IconJsxer.ICONS.edit,IconJsxer.ICON_STYLES.campaignPanelTop)}
                                                </div>
                                                <div className="edit-icon" onClick={(e) => { props.removeAnswer(e, props.roundIndex, props.questionIndex, answerIndex); validateQuestion() }}> 
                                                    {IconJsxer.GetIcon( IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.campaignPanelTop)}
                                                </div>
                                            </div>
                                        </div>       
                                    );
                                } else {
                                    return null;
                                }
                            })
                        }
                      </div>
                    </div>
            </div>
        </div>
    </PopUpPanel>
  );
}

export default EditQuizQuestion;