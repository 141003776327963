import React, { useContext } from "react";
import { CampaignContext } from "../../../contexts/CampaignContext";
import StreamsOverview from "../StreamsOverview";
import { IconJsxer } from "../../../helpers/IconHelper";

import { ACCOUNT_TYPES } from "../../../helpers/AccountTypes";
import { AuthContext } from "../../../contexts/AuthContext";

const CampaignStreamsTab = (props) => {
    const [campaignContext, setCampaignContext] = useContext(CampaignContext);
    const [authContext, setAuthContext] = useContext(AuthContext);

    return (
        <div className="co-tab-content">
            <div className={`co-tab-content-area fl-equal`}>
                <div className="underlined-panel-heading">
                    <h4>Campaign Streams</h4>
                    <div className="grow"></div>
                    {authContext.userData.userLevel >= ACCOUNT_TYPES.producer.level &&
                        <div className="round-button small-add-button" onClick={props._addStream}>
                            {IconJsxer.GetIcon(IconJsxer.ICONS.plus, IconJsxer.ICON_STYLES.roundPanelButtonSmall)}
                        </div>
                    }
                </div>
                <div className="co-fullcol-scroll-area">
                    <div className="fl-column compact">
                        <StreamsOverview
                            auth={authContext}
                            streams={campaignContext.campaignData.streams}
                            editMode={props._editMode}
                            editFunc={props._editStream}
                            removeFunc={props._removeStream}
                            addChannelFunc={props._addStreamChannelFunc}
                            removeChannelFunc={props._removeStreamChannelFunc}
                            getProfilePicFunc={props._getProfilePicFunc}
                        />
                    </div>
                </div>
            </div>  
        </div>
               
    )
}

export default CampaignStreamsTab;
