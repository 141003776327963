import React, { useState, setState } from "react";

const AuthContext = React.createContext([[], () => {}])

let initialState = {initialising: true, isLoggedIn:false, isConfirmed:false, userData:{}}

const AuthProvider = (props) => {
  const [state, setState] = useState(initialState)

  return (
    <AuthContext.Provider value={[state, setState]}>
      {props.children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }