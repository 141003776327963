import React, { useState, useEffect } from "react";
import validator from "validator";

import { toast } from "react-toastify";
import axios from "../../api/axios";
import { useNavigate, useLocation } from "react-router-dom";

import { resetPasswordRoute, checkResetTokenRoute } from "../../api/routes";
import FormField from "../../components/forms/FormField/FormField";
import "./ResetPassword.scss";

import ClosePanelBtn from "../../components/ClosePanelBtn/ClosePanelBtn";
import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import { NOT_STRONG_PASSWORD_ERROR, STRONG_PASSWORD_SETTINGS } from "../../helpers/AuthFunctions";

const ResetPassword = (props) => {
    console.log("props = ",props);
    const [resetTokenValid, setResetTokenValid] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    const [formValid, setFormValid] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [wasSentSuccessfully, setWasSentSuccessfully] = useState(false);

    const navigate = useNavigate();


    const completeAndClose = (event) => {
        props.closePanelFunc();
        navigate("/");
    };

    const checkResetToken = async (event) => {
        console.log("props.resetToken = ",props.resetToken)
        if(!props.resetToken) {
            toast.error("No reset code provided.")
            return false;
        }
        
        console.log("pre checkResetTokenRoute");
        console.log("email = " + props.resetEmail);
        console.log("token = " + props.resetToken)
        await axios
            .post(checkResetTokenRoute, {
               resetEmail: props.resetEmail,
               resetToken: props.resetToken 
            })
            .then((response) => {
                console.log("response = ");
                console.table(response);
                if(response.data.success) {
                    console.log("id matches")
                    toast.success(response.data.message);
                    setResetTokenValid(true);
                }
            })
            .catch(err => {
                console.log("frontend checkresettoken catch")
                console.table(err);
                if(err && err.response && err.response.data && err.response.data.success == false) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error("An unexpected error occured while checking the reset token");
                }
            })

        return resetTokenValid;

    }

    const handleValidation = (event) => {
        let formIsValid = true;

        if (
            !validator.isStrongPassword(password, STRONG_PASSWORD_SETTINGS)
        ) {
            formIsValid = false;
            setPasswordError(
                NOT_STRONG_PASSWORD_ERROR
            );
        } else {
            setPasswordError("");
        }

        if (confirmPassword !== password) {
            formIsValid = false;
            setConfirmPasswordError(
                "Password and Confirm Password must match"
            );
        } else {
            setConfirmPasswordError("");
        }

        setFormValid(formIsValid);
        return formIsValid;
    };

    const resetPasswordFormSubmitHandler = (e) => {
        e.preventDefault();
        const formValid = handleValidation();

        if (formValid) {
            console.log("formValid");
            setIsSubmitting(true);

            console.log("pre resetPassword");
            axios
                .post(resetPasswordRoute, {
                    email: props.resetEmail,
                    resetToken: props.resetToken,
                    password: password,
                    confirmPassword: confirmPassword
                })
                .then(function (response) {
                    setIsSubmitting(false);
                    
                    console.log("reset password success");
                    console.table(response);
                    toast.success("Password Reset Successfully!");
                    setWasSentSuccessfully(true);
                })
                .catch(function (error) {
                    console.log("frontend reset password catch")
                    console.table(error)
                    setIsSubmitting(false);
                    toast.error('Error - ' + error.response.data.message);
                });
        }
    };


    useEffect(() => {
        checkResetToken()
        //props.clearToken()
    }, [props.resetToken, props.clearToken])

    return (
        <PopUpPanel showCloseBtn={false}>

            { !wasSentSuccessfully && 
            
                <div className="form-holder">
                    <ClosePanelBtn clickFunc={completeAndClose} />
                    <form id="resetpasswordform" className="form">
                        <h2>Reset Password</h2>
                        <p className="p-grey">Enter a new password.</p>
                        <p className="p-grey">Passwords should contain a combination of letters numbers and symbols.</p>
                        <div className="spacer-small"></div>

                        <FormField
                            type="password"
                            id="password"
                            placeholder="Enter Password"
                            setFunc={setPassword}
                            label="Password"
                            error={passwordError}
                        />
                        <div className="spacer-small"></div>
                        <FormField
                            type="password"
                            id="confirmPassword"
                            placeholder="Confirm Password"
                            setFunc={setConfirmPassword}
                            label="Confirm Password"
                            error={confirmPasswordError}
                        />

                        <div className="spacer-small"></div>
                        <div className="submit-btn-holder">
                            <button className={'submit-btn' + ((isSubmitting || !resetTokenValid) ? ' button-inactive' : ' button-active')} onClick={(isSubmitting || !resetTokenValid) ? null : resetPasswordFormSubmitHandler}>
                                {isSubmitting ? "Resetting password" : "Reset password"}
                            </button>
                        </div>
                    </form>
                
                </div>
            }
            { wasSentSuccessfully &&
                <div className="form-holder">
                    <div className="contentholder">
                        <h2>Password Saved</h2>
                        <p className="p-grey">Your password has been reset.</p>
                        <p className="p-grey">Please close this dialogue and log in!</p>
                    </div>

                    <div className="spacer-large"></div>
                    
                    <form className="form">
                        <div className="submit-btn-holder">
                            <button className={'submit-btn button-active'} onClick={completeAndClose}>
                                {"Close"}
                            </button>
                        </div>
                    </form>
                </div>
            }
        </PopUpPanel>
    )
}

export default ResetPassword;
