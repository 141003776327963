import { useEffect, useRef, useState } from "react";
import { IconJsxer } from "../../helpers/IconHelper";
import "./ChatTranscript.scss";
import { toast } from "react-toastify";
import {CHANNEL_COLOURS } from "../../helpers/AnalyticsFunctions";
import DropDownList from "../../components/forms/DropDownList/DropDownList";

const ChatTranscript = (props) => {
    const [chatOpen, setChatOpen] = useState(false);
    const toggleChat = (e) => {
        e.preventDefault();
        setChatOpen(!chatOpen);
    }

    const [lockToBottom, setLockToBottom] = useState(true);
    const scrollAreaRef = useRef(null);
    const scrollHandler = (e) => {
        if (scrollAreaRef.current) {
            // if (scrollAreaRef.current.scrollTop + 10 < scrollAreaRef.current.scrollHeight - scrollAreaRef.current.offsetHeight) {
            if (scrollAreaRef.current.scrollHeight - scrollAreaRef.current.scrollTop - scrollAreaRef.current.clientHeight < 1) {
                setLockToBottom(true);
            } else {
                setLockToBottom(false);
            }
        }
    }
    const copyAMA = (amaQuestion) => {
        let lowerCaseQuestion = amaQuestion.toLowerCase();
        let index = lowerCaseQuestion.match(/ask/).index +3;
        let copiedAMA = amaQuestion.substring(index);
        if(copiedAMA[0] == ' ') copiedAMA = copiedAMA.substring(1);
        navigator.clipboard.writeText(copiedAMA);
        toast.success("Question copied to clipboard: "+copiedAMA);

    }
    const [channelList, setChannelList] = useState([])
    useEffect(
        () => {
            if (lockToBottom) {
                if (scrollAreaRef.current) {
                    scrollAreaRef.current.scrollTo(0, scrollAreaRef.current.scrollHeight);
                }
            }

    

        }
    )

    useEffect( () =>{
        if(props.channels)
            {
                let sortedChannels = props.channels.toSorted(function(a, b) {
                    var textA = a.twitchHandle.toUpperCase();
                    var textB = b.twitchHandle.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
                console.log('SORT COLOURS')
                setChannelList(sortedChannels)
            }
    }, [props.channels])
    const getChannelColour = (channel) => {

        for(let i=0; i<channelList.length; i++)
        {
            if(channelList[i].twitchHandle.toLowerCase() == channel.toLowerCase() ) return CHANNEL_COLOURS[i%8];
        }
    }
    const getProfilePic = (channel) => {

        for(let i=0; i<channelList.length; i++)
        {
            if(channelList[i].twitchHandle.toLowerCase() == channel.toLowerCase() ) return channelList[i].profilePic;
        }
    }

    const [availableChannels, setAvailableChannels] = useState([])


    const checkChannels = (channel) => {
        let newChannel = true;
        for(let i=0; i<availableChannels.length; i++)
        {

            if(availableChannels[i] == channel) newChannel = false;
        }
        if(newChannel) 
        {
            let channelArr = availableChannels;
            channelArr.push(channel)
            setAvailableChannels(channelArr);
        }
    }

    const updateChannelFilterHandler = async (e, id, item) => {

        console.log(item, id)
        console.log('SET', item)
        setChannelFilter(item);
    }
    const [channelFilter, setChannelFilter] = useState(null);
    const GetSessionDropdown = () => {
    
        let dropDownItems = 
            [
                {id:'all', label:'All'}
            ];
    
        if(availableChannels &&  availableChannels.length >= 1)
        {
            for(let i=0; i<availableChannels.length; i++)
            {
                let channelObj = availableChannels[i];
                dropDownItems.push({id:channelObj, label:channelObj.toUpperCase() })
            }
        }
    
        return(
            <DropDownList 
            id="assignedTo" 
            selectFunc={updateChannelFilterHandler}
            currentIndex={channelFilter ? channelFilter.index : 0}
            icon="oneUser"
            items={
                dropDownItems
            }
            className="filter"
        >
    
        </DropDownList>
        )
    
    
    }

        

    return (
        <>
            <div className={`chat-transcript${chatOpen ? ' ct-open' : ''}`}>
                <div className="chatHistory fl-row">
                <h4>Chat History</h4>
                {GetSessionDropdown(props)}
                </div>
                <div className="titles chat fl-row">
                <p className='channel-title'>Channel:</p>
                <p> Chat Message</p>
                 </div>
                <div className="scroll-area" ref={scrollAreaRef} onScroll={scrollHandler}>
                {props.chatHistory.map(
                    (val, i) => {
                        if(!channelFilter || channelFilter.id == val.channel || channelFilter.id == 'all' )
                        {
                        
                        checkChannels(val.channel);
                        const message = val.hashtag ? val.message.replace(val.hashtag, `<span class="hash-hilight">${val.hashtag}</span>`) : val.message;
                        return (
                            <>

                            <div className="chat fl-row">
                            <div className='channel'  style={{color: getChannelColour(val.channel), border:"2px solid "+getChannelColour(val.channel)}}> <img className='user-profile-pic-small' src={getProfilePic(val.channel)}></img>{val.channel.toUpperCase()} </div>
                            {val.isAMA && 
                                  <div onClick={() => {copyAMA(val.message)}} key={'chat_' + i}><span className="name" style={{color: val.color || '#0000FF'}}>{val.name}:</span> <span className='ama'  dangerouslySetInnerHTML={{__html: message}}></span></div>
                            }
                                 {!val.isAMA && 
                                 <div  key={'chat_' + i}>
                                  <p><span className="name" style={{color: val.color || '#0000FF'}}>{val.name}:</span> <span dangerouslySetInnerHTML={{__html: message}}></span></p>
                                </div>
                            }
                            </div>
                            </>
                            
                                    )
                        
                            }
                    }
                    )
                }
                </div>
            </div>

            <div className="chat-toggle-button" onClick={toggleChat}>
                {IconJsxer.GetIcon(IconJsxer.ICONS.chatHistory, IconJsxer.ICON_STYLES.chatToggleControlButton)}
            </div>
            </>
       
        
    )
}

export default ChatTranscript;
