import "./Campaigns.scss";
import React, { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import axios from "../../api/axios";
import { AuthContext } from "../../contexts/AuthContext";

import ReactPaginate from 'react-paginate';
import { useNavigate, } from "react-router-dom";
import Toggle from "../../components/forms/Toggle/Toggle";
import { ProfilePicURL } from "../../helpers/ProfilePicURL";
import ContentTopProfile from "../../components/ContentTopProfile/ContentTopProfile";
import HeadedPanel from "../../components/HeadedPanel/HeadedPanel";
import CampaignSummaryPanel from "../../components/Campaigns/CampaignSummaryPanel";
import { IconJsxer } from "../../helpers/IconHelper";
import DropDownList from "../../components/forms/DropDownList/DropDownList";
import UserLevelRestrict from "../../components/UserLevelRestrict/UserLevelRestrict";
import { TOP_LEVEL_NAV_ROUTES } from "../../config/NavRoutes";
import { getAllCampaignsRoute, getUserCampaigns, appAccessTokenRoute } from "../../api/routes";
import { getRegionsDropDownListData } from "../../helpers/Regions";
import { alphaTypePrimer, filterList, searchList, toStringPrimer } from "../../helpers/ListFunctions";
import { orderCampaignsAccordingToStatus } from "../../helpers/CampaignsHelper";
import { ACCOUNT_TYPES } from "../../helpers/AccountTypes";
// import setComponentSearchTerm from "../../components/forms/SearchBox/SearchBox";
import LeftColorBlockBox from "../../components/LeftColorBlockBox/LeftColorBlockBox";
import { updateComponentSearchTerm } from "../../components/forms/SearchBox/SearchBox";


const Campaigns = () => {
    const navigate = useNavigate();

    const createCampaign = (e) => {
        navigate(TOP_LEVEL_NAV_ROUTES.CREATE_CAMPAIGN);
    }
    const [campaignsData, setCampaignsData] = useState([]);
    const [displayCampaignsData, setDisplayCampaignsData] =  useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [pageList, setPageList] = useState([]);
    const [showAllCampaigns, setShowAllCampaigns] = useState(false);

    const [regionFilter, setRegionFilter] = useState(null);
    const [statusFilter, setStatusFilter] = useState(null);
    const [campaignStatusFilter, setCampaignStatusFilter] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    // const [clientOnly, setClientOnly] = useState(false);
    const clientOnly = useRef(false);
    const [listView, setListView] = useState(true);

    const updateFilterHandler = (e, dropDownId, item) => {
        console.log("updateFilterHandler(e,dropDownId, item)",e,dropDownId,item);
        switch (dropDownId) {
            case 'region':
                console.log("dropDownId == region");
                setRegionFilter(item);
                break;
            case 'status':
                setStatusFilter(item);
                break;
            case 'campaignStatus':
                setCampaignStatusFilter(item);
                break;
            default:
                break;
        }
    }

    const filterDisplayList = () =>{
        let tempFilterArr = campaignsData.concat();
        if(regionFilter && regionFilter.id !== 'all')
        {
            tempFilterArr = filterList(tempFilterArr, ['region'], regionFilter.id);
        }
        if(statusFilter && statusFilter.id !== 'all')
        {
            tempFilterArr = filterList(tempFilterArr, ['status'], statusFilter.id);
        }
        setDisplayCampaignsData(tempFilterArr);
        setSearchTerm('');
        return tempFilterArr;
    }
    useEffect(() => {
        axios.get(appAccessTokenRoute)
            .catch((error) => {
                console.warn('Error getting app access token: ', error);
            });
    }, [])


   /* useEffect(() => {
        if (searchTerm !== '') {
            searchCampaignsList(null, searchTerm);
        } else {
            filterDisplayList();
        }
    }, [regionFilter, statusFilter])*/

    const searchCampaignsList = (e, searchTerm) => {
        // let filteredList = filterDisplayList();
        (searchTerm.indexOf('Client:') != -1 || searchTerm.indexOf('client:') != -1) ? clientOnly.current = true : clientOnly.current = false;
        setSearchTerm(searchTerm);
        /*
        if (searchTerm !== '') {
            // Currently we can't search all users involved in the campaign because that data doesn't exist in the dataset (we need to add it!)
            let searchedList = searchList(filteredList, ['campaignName', 'campaignOwner', '_id', 'client', 'producerAccounts', 'clientAccounts', 'streams', 'name', 'email', 'twitchHandle', 'timeZone', 'discord'], searchTerm, alphaTypePrimer);
            setDisplayCampaignsData(searchedList);
        }*/
    }

    const searchClientList = (searchTerm) => {
        clientOnly.current = true;
        setSearchTerm('Client: ' + searchTerm);
        // setComponentSearchTerm('Client: ' + searchTerm);
        updateComponentSearchTerm('Client: ' + searchTerm);
        // const searchBox = document.getElementById('search');
        // searchBox.value = 'Client: ' + searchTerm;
    }

    const fetchCampaignList = (newPagination = false) => {

        let campaignRoute = showAllCampaigns ? getAllCampaignsRoute : getUserCampaigns;

        // Moved this to here because I found if I searched for "client: test" and then "test" immediately after each other, no new search would be performed.
        // This was because the searchTerm state var wasn't changing!
        // This is also why I changed clientOnly to a ref as I have had trouble accessing state vars outside off useEffect and the render before, 
        // but it turned out the problem was something else...
        // - Carl
        let actualSearchTerm = searchTerm;
        actualSearchTerm = actualSearchTerm.replace('Client: ', '');
        actualSearchTerm = actualSearchTerm.replace('client: ', '');
        actualSearchTerm = actualSearchTerm.replace('Client:', '');
        actualSearchTerm = actualSearchTerm.replace('client:', '');

        axios
            .get(campaignRoute, { params:{page:currentPage, searchTerm:actualSearchTerm, regionFilter:regionFilter, statusFilter:statusFilter, campaignStatusFilter: campaignStatusFilter, isClientOnly:clientOnly.current}, withCredentials: true })
            .then((response) => {
                console.log(response.data.campaigns)
                //campaigns = response.data.campaigns;

                // Order our campaigns, live is most important, then scheduled, then ended
                const campaigns = response.data.campaigns;
                // This returns the campaigns back, but we don't need it because arrays are sorted in place
               // orderCampaignsAccordingToStatus(campaigns);

                setCampaignsData(response.data.campaigns);
                setDisplayCampaignsData(response.data.campaigns);
                setNumberOfPages(response.data.numberOfPages);
                console.log('NUMBER OF PAGES', response.data.numberOfPages)
                //if(newPagination) createPageList();
            })
            .catch((error) => {
                //
                //toast.error(error.response.data.msg);
            });
    }
    const toggleShowAllCampaignsHandler = (event, id) => {
        //console.log(id, event)
        event.preventDefault()
        setShowAllCampaigns(!showAllCampaigns);
    }

    const createPageList = () => {
        let pageData = []
        for(let i=0; i<numberOfPages; i++)
        {
            pageData.push({pageNumber:i +1})
        }
        console.log('PAGE LENGTH', pageData.length)
        setPageList(pageData)
    } 
    const handlePageClick = (event) => {
        setCurrentPage(event.selected)
      };
    const setPage = (page) =>{
        setCurrentPage(page)
    }
    const pageDown = () =>{
        setCurrentPage(currentPage - 1)
    }
    const pageUp = () =>{
        setCurrentPage(currentPage + 1)
    }

    useEffect(() => {
        console.log('USE EFFECT')

        fetchCampaignList(true);
    }, [showAllCampaigns, searchTerm, regionFilter, statusFilter, campaignStatusFilter, currentPage])
    useEffect(() => {
        console.log('PAGE DATA UPDATED')
        setCurrentPage(0);
        createPageList();
    }, [numberOfPages])

    const [authContext, setAuthContext] = useContext(AuthContext);

    return (
        <div className="content-page-container">
            <div className="content-page-top">
                <div className="fl-column grow">
                <ContentTopProfile showSearchBox={true} searchFunc={searchCampaignsList} />
                <div className="filters-container form-holder">
                        <DropDownList
                            id="campaignStatus"
                            selectFunc={updateFilterHandler}
                            currentIndex={campaignStatusFilter ? campaignStatusFilter.index : 0}
                            deselectedLabel="Campaign Status"
                            icon="status"
                            items={
                                [
                                    {
                                        id: 'normal',
                                        label: 'Active Campaigns',
                                        // deselect: true,
                                    },
                                    {
                                        id: 'draft',
                                        label: 'Drafts',
                                    },
                                    {
                                        id: 'archived',
                                        label: 'Archived',
                                    },
                                ]
                            }
                            className="filter"
                        />
                        <DropDownList
                            id="region"
                            selectFunc={updateFilterHandler}
                            currentIndex={regionFilter ? regionFilter.index : 0}
                            deselectedLabel="Region"
                            icon="region"
                            items={
                                getRegionsDropDownListData([
                                    {
                                        id: 'all',
                                        label: 'See All',
                                        deselect: true,
                                    },
                                ])
                            }
                            className="filter"
                        />
                        <DropDownList
                            id="status"
                            selectFunc={updateFilterHandler}
                            currentIndex={statusFilter ? statusFilter.index : 0}
                            deselectedLabel="Stream Status"
                            icon="liveStream"
                            items={
                                [
                                    {
                                        id: 'all',
                                        label: 'See All',
                                        deselect: true,
                                    },
                                    {
                                        id: 'live',
                                        label: 'Live',
                                    },
                                    {
                                        id: 'scheduled',
                                        label: 'Scheduled',
                                    },
                                    {
                                        id: 'ended',
                                        label: 'Ended',
                                    },
                                    {
                                        id: 'notsetup',
                                        label: 'Not Setup',
                                    },
                                    /* {
                                        id: 'archived',
                                        label: 'Archived',
                                    }, */
                                ]
                            }
                            className="filter"
                        />

                        {searchTerm !== '' && <div className="light-text">Searched for: {searchTerm} ({displayCampaignsData.length} result{displayCampaignsData.length > 1 ? 's' : ''}) <span className="small-text">[<a href="#" onClick={(e) => {e.preventDefault(); searchCampaignsList(e, '');}}>Clear Search</a>]</span></div>}

                        <div className="grow"></div>

                        {authContext.userData.userLevel >= ACCOUNT_TYPES.producer.level &&
                        <>
                        <div className="fl-row">
                            Show Campaigns for All Users
                            <Toggle currentState={showAllCampaigns} toggleFunc={toggleShowAllCampaignsHandler} />
                        </div>


                            <div className={`switch-view-btn${listView ? ' view-selected' : ''}`} onClick={() => setListView(true)}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.listView, IconJsxer.ICON_STYLES.settingsEdit)}
                            </div>
                            <div className={`switch-view-btn${listView ? '' : ' view-selected'}`} onClick={() => setListView(false)}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.gridView, IconJsxer.ICON_STYLES.settingsEdit)}
                            </div>
                            </>
                        }
                        </div>
                    </div>
            </div>
            <div className={`content-page-content-container`}>
                <div className="content-page-main-content campaign-main-content">
                    
                <div className="users-panel">
                        <div className={`campaigns-container${listView ? ' compact' : ''}`}>
                            {listView &&
                                <UserLevelRestrict accountType={ACCOUNT_TYPES.producer} element={
                                        <div className={`create-campaign-button button-active${listView ? ' wide-btn' : ''}`} onClick={createCampaign}>
                                            <div className={listView ? 'fl-row' : ''}>
                                                {IconJsxer.GetIcon(IconJsxer.ICONS.plus, listView ? IconJsxer.ICON_STYLES.campaignWideBtn : IconJsxer.ICON_STYLES.campaignLargeBtn)}
                                                <h3>Create{!listView && <br />} Campaign</h3>
                                            </div>
                                        </div>
                                    }
                                />
                            }
                            {listView &&
                                <>
                                    <div></div>
                                    

                                    <div className="clist-headings-container grow">
                                        <div className='fl-row list-view-content'>

                                            <div className="left-pad"></div>
                                            
                                            <div className='grow'>Campaign Name</div>
                                            <div className='fl-row list-view-data-block'>
                                                Client
                                            </div>
                                            <div className='fl-row list-view-data-block'>
                                                Region
                                            </div>
                                            <div className='fl-row list-view-data-block wide'>
                                                Owner
                                            </div>
                                            <div className='fl-row list-view-data-block narrow'>
                                                Salesforce No.
                                            </div>

                                            <div className='fl-row list-view-data-block narrow'>
                                                Created
                                            </div>

                                            <div className="right-pad"></div>
                                            
                                        </div>
                                    </div>

                                </>
                            }
                            <div className={`scroll-container ${listView ? 'scroll-list' : 'scroll-panels'}`}>
                                {!listView &&
                                    <UserLevelRestrict accountType={ACCOUNT_TYPES.producer} element={
                                            <div className={`create-campaign-button button-active${listView ? ' wide-btn' : ''}`} onClick={createCampaign}>
                                                <div className={listView ? 'fl-row' : ''}>
                                                    {IconJsxer.GetIcon(IconJsxer.ICONS.plus, listView ? IconJsxer.ICON_STYLES.campaignWideBtn : IconJsxer.ICON_STYLES.campaignLargeBtn)}
                                                    <h3>Create{!listView && <br />} Campaign</h3>
                                                </div>
                                            </div>
                                        }
                                    />
                                }
                            {
                                displayCampaignsData.map((val, i) => {
                                    return (<CampaignSummaryPanel campaign={val} key={val._id} listView={listView} searchFunc={searchClientList} />);
                                })
                            
                            }
                        
                        </div>
                    </div>
                </div>

                    <ReactPaginate
                        activeClassName={'pagination-item item-active '}
                        breakClassName={'pagination-item break-me '}
                        breakLabel={'...'}
                        containerClassName={'pagination'}
                        disabledClassName={'disabled-page'}
                        marginPagesDisplayed={2}
                        nextClassName={"pagination-item  "}
                        nextLabel={IconJsxer.GetIcon(IconJsxer.ICONS.chevronRight, IconJsxer.ICON_STYLES.settingsEdit)}
                        onPageChange={handlePageClick}
                        pageCount={numberOfPages}
                        forcePage={currentPage}
                        pageClassName={'pagination-item pagination-page '}
                        pageRangeDisplayed={2}
                        previousClassName={"pagination-item  "}
                        previousLabel={IconJsxer.GetIcon(IconJsxer.ICONS.chevronLeft, IconJsxer.ICON_STYLES.settingsEdit)}
                        renderOnZeroPageCount={false}
                    />
                </div>
            </div>
        </div>
    );
}

export default Campaigns;