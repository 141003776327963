import { assets } from "../../assets/assets";
import "./Feature.scss";
import React from "react";

function ExpiredFeature() {
  return (
    <div className="feature-expired">
        <div className="content">
            <img alt="ICAST Logo" src={assets.icastTopLogo} />
            <h1>This page has expired</h1>
        </div>
    </div>
  );
}

export default ExpiredFeature;
