export const longDateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
};

export const shortDateOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
};

export const shortDateNumberOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
};

export const timeOptionsNoSeconds = {
    hour: '2-digit', 
    minute: '2-digit'
}

export const getLocaleData = () => Intl.DateTimeFormat().resolvedOptions();
export const getDefaultTimezone = () => getLocaleData().timeZone;

export const getDefaultTimeZoneListData = (options, timezone = null) => {
    const defaultTz = timezone || getDefaultTimezone();
    const tzList = options;
    for (let i = tzList.length - 1; i >= 0; i--) {
        if (defaultTz === tzList[i].value) {
            // Since our drop downs work by saving the whole data object, but with an index property added,
            // we're adding the index property to our list item here to keep things consistent.
            tzList[i].index = i;
            return tzList[i];
        }
    }

    // Default to gmt of the list...
    for (let i = 0; i < tzList.length; i++) {
        if (tzList[i].value === 'Etc/GMT') {
            return tzList[i];
        }
    }

    // Fall back to the start of the list...
    return tzList[0];
}

export const getRelativeHoursDropDownListData = (appendStr = '') => {
    const hoursDropData = [];
    for (let i = 0; i < 100; i++) {
        hoursDropData.push(
            {
                index: i,
                value: i,
                label: (i < 10 ? '0' + i : i) + appendStr,
            }
        )
    }
    return hoursDropData;
}

export const getHoursDropDownListData = (appendStr = '') => {
    const hoursDropData = [];
    for (let i = 0; i < 24; i++) {
        hoursDropData.push(
            {
                index: i,
                value: i,
                label: (i < 10 ? '0' + i : i) + appendStr,
            }
        )
    }
    return hoursDropData;
}

export const getMinsSecsDropDownListData = (appendStr = '') => {
    const minsSecsDropData = [];
    for (let i = 0; i < 60; i++) {
        minsSecsDropData.push(
            {
                index: i,
                value: i,
                label: (i < 10 ? '0' + i : i) + appendStr,
            }
        )
    }
    return minsSecsDropData;
}

export const getLongDateString = (unixTime) => new Date(unixTime).toLocaleDateString(getLocaleData().locale, longDateOptions);
export const getShortDateString = (unixTime) => new Date(unixTime).toLocaleDateString(getLocaleData().locale, shortDateOptions);
export const getShortDateNumericString = (unixTime) => new Date(unixTime).toLocaleDateString(getLocaleData().locale, shortDateNumberOptions);
export const getShortDateNumericStringForFilenames = (unixTime) => new Date(unixTime).toLocaleDateString(getLocaleData().locale, shortDateNumberOptions).replace(/\//g, '-');
// export const getShortDateTimeString = (unixTime) => getShortDateString(unixTime) + ' - ' + new Date(unixTime).toLocaleTimeString();
export const getShortDateTimeString = (unixTime, seconds = false, seperator = ' - ') => getShortDateString(unixTime) + seperator + new Date(unixTime).toLocaleTimeString([], seconds ? null : timeOptionsNoSeconds);
export const getShortTimeString = (unixTime, seconds = false) => new Date(unixTime).toLocaleTimeString([], seconds ? null : timeOptionsNoSeconds);

// The problem with dates in js is that when we create a date object with a unix timestamp, 
// we end up with local dates, not the original date! 
// So if for example we choose a timezone that is 5 hours behind ours, then the date will potentially show as one day before... 
// The solution is to adjust our unix timestamp by our current timezone offset AND the timezone offset of the selected date before we create the Date object.
// Meaning we bring it inline with our own timezone.
export const getTzAdjustedDateObject = (unixTime, timeZoneAdjust) => {
    unixTime += timeZoneAdjust * 3600 * 1000;
    const now = new Date();
    unixTime += now.getTimezoneOffset() * 60 * 1000;
    return new Date(unixTime);
}
export const getTzLongDateString = (unixTime, timeZoneAdjust) => {
    return getTzAdjustedDateObject(unixTime, timeZoneAdjust).toLocaleDateString(getLocaleData().locale, longDateOptions);
}
export const getTzShortDateString = (unixTime, timeZoneAdjust) => {
    return getTzAdjustedDateObject(unixTime, timeZoneAdjust).toLocaleDateString(getLocaleData().locale, shortDateOptions);
}

/**
 * Convert UTC seconds or relative seconds to Hours, Mins, Seconds. Hours are clamped to 0-24 for non-relative times.
 * Resulting array has 2 variations, {h, m, s} and {h_str, m_str, s_str}, the latter (_str) has a leading zero
 * @param {string} timeInSecs The UTC time in seconds
 * @param {boolean} relativeTime For non-relative times we should clamp hours to 0-24
 * @param {array} timeZoneAdjust The +/-hours to adjust for timezone. Can be half hours (eg. -4.5), as specified in timezone drop downlist data.
 * @returns Object {h, m, s, h_str, m_str, s_str}
 */
export const convertSecondsToHMS = (timeInSecs, relativeTime = false, timeZoneAdjust = 0) => {
    // Adjust time in secs for timezone... timeInSecs is always utc or relative...
    if (!relativeTime) {
        const timeZoneAdjustSecs = timeZoneAdjust * 3600;
        timeInSecs += timeZoneAdjustSecs;
    }

    let h = 0, m = 0, s = 0;
    if (timeInSecs >= 0) {
        h = Math.floor(timeInSecs / 3600);
        m = Math.floor((timeInSecs - h * 3600) / 60);
        s =  Math.floor((timeInSecs - h * 3600 - m * 60));
    }

    // For relative times, don't clamp the hours!
    if (!relativeTime) {
        while (h < 0) h += 24;
        while (h > 23) h -= 24;
    }

    return {
        h: h, h_str: h >= 10 ? h : '0' + h, 
        m: m, m_str: m >= 10 ? m : '0' + m, 
        s: s, s_str: s >= 10 ? s : '0' + s,
    }
}

/**
 * Convert UTC milliseconds or relative milliseconds to Hours, Mins, Seconds. Hours are clamped to 0-24 for non-relative times.
 * Resulting array has 2 variations, {h, m, s} and {h_str, m_str, s_str}, the latter (_str) has a leading zero
 * @param {string} timeInMS The UTC time in milliseconds
 * @param {boolean} relativeTime For non-relative times we should clamp hours to 0-24
 * @param {array} timeZoneAdjust The +/-hours to adjust for timezone. Can be half hours (eg. -4.5), as specified in timezone drop downlist data.
 * @returns Object {h, m, s, h_str, m_str, s_str}
 */
export const convertMSToHMS = (timeInMS, relativeTime = false, timeZoneAdjust = 0) => {
    // Adjust time in secs for timezone... timeInSecs is always utc or relative...
    if (!relativeTime) {
        const timeZoneAdjustMs = timeZoneAdjust * 3600 * 1000;
        timeInMS += timeZoneAdjustMs;
    }

    let h = 0, m = 0, s = 0;
    if (timeInMS >= 0) {
        h = Math.floor(timeInMS / 1000 / 3600);
        m = Math.floor((timeInMS / 1000 - h * 3600) / 60);
        s = (timeInMS / 1000 - h * 3600 - m * 60);
    }

    // For relative times, don't clamp the hours!
    if (!relativeTime) {
        while (h < 0) h += 24;
        while (h > 23) h -= 24;
    }

    return {
        h: h, h_str: h >= 10 ? Math.floor(h) : '0' + Math.floor(h), 
        m: m, m_str: m >= 10 ? Math.floor(m) : '0' + Math.floor(m), 
        s: s, s_str: s >= 10 ? Math.floor(s) : '0' + Math.floor(s)}
}

/**
 * Convert inputted time, date and timezone into a unix timestamp.
 * These style inputs are used in a few places, so here is a helper to do it.
 * @param {Date} date The date as it gets returned by the DateSelect component
 * @param {number} time The time as it gets returned from a HMSTimeField (it's in seconds)
 * @param {object} timezone The timezone data object that is used by our drop down list, contains an offset property
 * @returns Unix timestamp, UTC Milliseconds since Jan 1st 1970
 */
export const convertFormDateTimeTimezoneToUnixTimestamp = (date, time, timezone) => {
    // console.log('--- CONVERTING DATE TIME TIMEZONE TO UNIX TIMESTAMP ---', date, time, timezone);
    // Time comes out of HMSTimeField in seconds
    let utcSecs_time = typeof time === 'number' ? time : 0;
    // Timezone from FormDropDownList data contains offset in hours (some timezones are not full hours, eg 4.5)
    // We convert it to seconds to adjust the time entered for selected timezone
    const timeZoneAdjustSecs = timezone.offset * 3600;
    // Subtract the selected timezone's offset to adjust the time to utc
    utcSecs_time -= timeZoneAdjustSecs;
    // The date from DateSelect is set to midnight in your local timezone, so we need to adjust this to utc
    // Plus getTime returns milliseconds, so need to convert to seconds. getTimezoneOffset returns the offset in minutes here
    // console.log('convertFormDateTimeTimezoneToUnixTimestamp, subbed date timezone offset: ', date.getTimezoneOffset(), date);
    // This does not seem right, getTimezone returns -60 for bst, so it seems like we should add that to the date to end up at midnight utc...?
    utcSecs_time += date.getTime() / 1000 - date.getTimezoneOffset() * 60;   
    // Convert our seconds to milliseconds so it's a proper unix timestamp for storage in the db
    return utcSecs_time * 1000;
}
