import React, { useState, setState } from "react";

const CampaignContext = React.createContext([[], () => {}])

let initialState = {initialising: true, campaignData:{}}

const CampaignProvider = (props) => {
  const [state, setState] = useState(initialState)

  return (
    <CampaignContext.Provider value={[state, setState]}>
      {props.children}
    </CampaignContext.Provider>
  )
}

export { CampaignContext, CampaignProvider }