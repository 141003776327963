import PropTypes from 'prop-types';
import OneBotOverview from '../../components/Bots/OneBotOverview';

const BotsOverview = (props) => {


const getBotFiredCount = (botID) => {
    if(props.firedBots)
    {
        for(let i=0; i<props.firedBots.length; i++)
        {
            if(props.firedBots[i].botID == botID) 
            {
              return props.firedBots[i].amount
            }
        }
    }
    return 0;
}

    return (
        <div className='fl-column compact'>
        {props.bots.map(
            (val, i) => {
                const firedCount = getBotFiredCount(val.id);
                return (
                    <div key={'bo_' + i}>
                        {(!val.archived || props.editMode) &&
                            <OneBotOverview 
                                bot={val}
                                botsAvailable={props.botsAvailable}
                                streamStarted={props.streamStarted}
                                streamLiveTime = {props.streamLiveTime}
                                timedBots = {props.timedBots}
                                firedAmount = {firedCount}
                                controlMode={props.controlMode} 
                                editMode={props.editMode} 
                                _gotLiveStream={props._gotLiveStream}
                                editFunc={props.editFunc} 
                                removeFunc={props.removeFunc} 
                                duplicateFunc={props.duplicateFunc} 
                                archived={props.archived}
                                triggerBotFunc={props.triggerBotFunc}
                                pauseBotFunc={props.pauseBotFunc}
                                unpauseBotFunc={props.unpauseBotFunc}
                            />
                        }
                    </div>
                )
            }
        )}
        </div>
    )
}

BotsOverview.propTypes = {
    bots: PropTypes.array,
    controlMode: PropTypes.bool,
    editMode: PropTypes.bool,
    editFunc: PropTypes.func,
    removeFunc: PropTypes.func,
    duplicateFunc: PropTypes.func,
    archived: PropTypes.bool,
    triggerBotFunc: PropTypes.func,
    pauseBotFunc: PropTypes.func,
    unpauseBotFunc: PropTypes.func,
};

BotsOverview.defaultProps = {
    bots: [],
    controlMode: false,
    editMode: false,
    editFunc: null,
    removeFunc: null,
    duplicateFunc: null,
    archived: false,
    triggerBotFunc: null,
    pauseBotFunc: null,
    unpauseBotFunc: null,
}

export default BotsOverview;
