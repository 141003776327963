import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import "./ArcadeDataPopup.scss";
import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import { CampaignContext } from "../../contexts/CampaignContext";
import OneStreamOverview from "../Streams/OneStreamOverview";
import { getTimezoneOptions, parseTimezone } from "../../helpers/TimeZones";
import axios from "../../api/axios";
import { getArcadeEntriesRoute } from "../../api/routes";
import CollapsibleHeadedSection from "../CollapsibleHeadedSection/CollapsibleHeadedSection";
import { convertMSToHMS, convertSecondsToHMS, getDefaultTimezone, getShortDateNumericStringForFilenames, getShortDateTimeString, getShortTimeString, getTzLongDateString } from "../../helpers/Dates";
import { IconJsxer } from "../../helpers/IconHelper";
import XLSX from 'xlsx';
import { alphaTypePrimer, numberTypePrimer, sortListOn } from "../../helpers/ListFunctions";

const ArcadeDataPopup = (props) => {
    const [campaignContext, setCampaignContext] = useContext(CampaignContext);
    const streams = campaignContext.campaignData.streams;

    const [arcadeDataFetched, setArcadeDataFetched] = useState({});
    const arcadeData_ref = useRef({});
    const [arcadeData, setArcadeData] = useState({});

    // Cycle through each stream and get data using axios and getArcadeEntriesRoute
    const getArcadeDataForStreamID = (streamID) => {
        if (!arcadeDataFetched[streamID]) {
            axios.get(getArcadeEntriesRoute, {
                params: {
                    streamID: streamID
                }
            }).then(response => {
                arcadeData_ref.current[streamID] = response.data;
                setArcadeDataFetched((oldVals) => {
                    return ({
                        ...oldVals,
                        [streamID]: true
                    });
                });
                setArcadeData({
                    ...arcadeData_ref.current
                });
                console.log('Got arcade data:', response.data);
            }).catch(err => {
                console.log(err);
                setArcadeDataFetched((oldVals) => {
                    return ({
                        ...oldVals,
                        [streamID]: true
                    });
                });
            });
        }
    }
    useEffect(() => {
        streams.forEach(stream => {
            getArcadeDataForStreamID(stream._id);
        });
        getArcadeDataForStreamID('preview_test');
    }, [streams]);

    const capitalizeFirstLetter = (string) => {
        if (typeof string !== 'string') {
            return string;
        }
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const createXlsxData = (entry, timezoneData) => {
        const workBook = XLSX.utils.book_new();

        const campaignSheetData = [];
        campaignSheetData.push(['Campaign Name', campaignContext.campaignData.campaignName]);
        campaignSheetData.push(['Client', campaignContext.campaignData.client]);
        campaignSheetData.push(['Region', capitalizeFirstLetter(campaignContext.campaignData.region)]);
        campaignSheetData.push(['Owner', campaignContext.campaignData.campaignOwnerName]);
        campaignSheetData.push(['Campaign Created', getShortDateTimeString(campaignContext.campaignData.dateCreated)]);
        campaignSheetData.push(['Salesforce No.', campaignContext.campaignData.sfNumber]);
        campaignSheetData.push(['Data Exported', getShortDateTimeString(Date.now())]);
        const campaignSheet = XLSX.utils.aoa_to_sheet(campaignSheetData);
        const max_widths_1 = [campaignSheetData.reduce((w, r) => Math.max(w, String(r[0]).length), 10), campaignSheetData.reduce((w, r) => Math.max(w, String(r[1]).length), 10)];
        campaignSheet['!cols'] = [{ wch: max_widths_1[0] }, { wch: max_widths_1[1] }];
        console.log('Campaign Sheet:', campaignSheet);

        workBook.SheetNames.push('Campaign Data');
        workBook.Sheets['Campaign Data'] = campaignSheet;

        const activationSheetData = [];
        activationSheetData.push(['Activation Time', getTzLongDateString(entry.activationTime, timezoneData.offset)]);
        activationSheetData.push(['(local time)', getShortDateTimeString(entry.activationTime, false)]);
        activationSheetData.push(['Deactivation Time', getTzLongDateString(entry.deActivationTime, timezoneData.offset)]);
        activationSheetData.push(['(local time)', getShortDateTimeString(entry.deActivationTime, false)]);
        activationSheetData.push(['Timezone', timezoneData.label]);
        activationSheetData.push(['', '']);
        activationSheetData.push(['Prize Attempts', entry.attempts]);
        activationSheetData.push(['Prizes Won', entry.prizesWon]);
        activationSheetData.push(['', '']);
        activationSheetData.push(['Game Settings', '']);
        Object.keys(entry.gameSettings).forEach(key => {
            activationSheetData.push([entry.gameSettings[key].label, capitalizeFirstLetter(entry.gameSettings[key].value)]);
        });
        const activationSheet = XLSX.utils.aoa_to_sheet(activationSheetData);
        const max_widths_2 = [activationSheetData.reduce((w, r) => Math.max(w, String(r[0]).length), 10), activationSheetData.reduce((w, r) => Math.max(w, String(r[1]).length), 10)];
        activationSheet['!cols'] = [{ wch: max_widths_2[0] }, { wch: max_widths_2[1] }];
        console.log('Activation Sheet:', activationSheet);

        workBook.SheetNames.push('Activation Data');
        workBook.Sheets['Activation Data'] = activationSheet;

        const prizesSheetData = [];
        prizesSheetData.push(['Name', 'Description', 'Target', 'Quantity']);
        entry.prizes.forEach(prize => {
            prizesSheetData.push([prize.name, prize.description, prize.targetDescription, prize.quantity]);
        });
        const prizesSheet = XLSX.utils.aoa_to_sheet(prizesSheetData);
        const max_widths_3 = [prizesSheetData.reduce((w, r) => Math.max(w, String(r[0]).length), 10), prizesSheetData.reduce((w, r) => Math.max(w, String(r[1]).length), 10), prizesSheetData.reduce((w, r) => Math.max(w, String(r[2]).length), 10), prizesSheetData.reduce((w, r) => Math.max(w, String(r[3]).length), 10)];
        prizesSheet['!cols'] = [{ wch: max_widths_3[0] }, { wch: max_widths_3[1] }, { wch: max_widths_3[2] }, { wch: max_widths_3[3] }];
        console.log('Prizes Sheet:', prizesSheet);

        workBook.SheetNames.push('Prizes');
        workBook.Sheets['Prizes'] = prizesSheet;

        const entriesSheetData = [];
        entriesSheetData.push(['Twitch Handle', 'Channel', 'Time', 'Entry Count']);
        entry.entries.forEach(entry => {
            entriesSheetData.push([entry.twitchHandle, entry.channel, getShortDateTimeString(entry.entryTime, false), entry.entryCount]);
        });
        const entriesSheet = XLSX.utils.aoa_to_sheet(entriesSheetData);
        const max_widths_4 = [entriesSheetData.reduce((w, r) => Math.max(w, String(r[0]).length), 10), entriesSheetData.reduce((w, r) => Math.max(w, String(r[1]).length), 10), entriesSheetData.reduce((w, r) => Math.max(w, String(r[2]).length), 10), entriesSheetData.reduce((w, r) => Math.max(w, String(r[3]).length), 10)];
        entriesSheet['!cols'] = [{ wch: max_widths_4[0] }, { wch: max_widths_4[1] }, { wch: max_widths_4[2] }, { wch: max_widths_4[3] }];
        console.log('Entries Sheet:', entriesSheet);

        workBook.SheetNames.push('Opt Ins');
        workBook.Sheets['Opt Ins'] = entriesSheet;

        const winnersSheetData = [];
        winnersSheetData.push(['Twitch Handle', 'Channel', 'Time', 'Attempts']);
        entry.winners.forEach(entry => {
            winnersSheetData.push([entry.twitchHandle, entry.channel, getShortDateTimeString(entry.time, false), entry.attempts]);
        });
        const winnersSheet = XLSX.utils.aoa_to_sheet(winnersSheetData);
        const max_widths_5 = [winnersSheetData.reduce((w, r) => Math.max(w, String(r[0]).length), 10), winnersSheetData.reduce((w, r) => Math.max(w, String(r[1]).length), 10), winnersSheetData.reduce((w, r) => Math.max(w, String(r[2]).length), 10), winnersSheetData.reduce((w, r) => Math.max(w, String(r[3]).length), 10)];
        winnersSheet['!cols'] = [{ wch: max_widths_5[0] }, { wch: max_widths_5[1] }, { wch: max_widths_5[2] }, { wch: max_widths_5[3] }];
        console.log('Winners Sheet:', winnersSheet);

        workBook.SheetNames.push('Winners');
        workBook.Sheets['Winners'] = winnersSheet;

        const streamMarkersSheetData = [];
        streamMarkersSheetData.push(['Twitch Handle', 'Channel', 'Time', 'Description']);
        entry.streamMarkers.forEach(entry => {
            const tData = convertSecondsToHMS(entry.time, true);
            streamMarkersSheetData.push([entry.twitchHandle, entry.channel, `${tData.h} hrs ${tData.m} mins ${tData.s} secs (${entry.time} seconds)`, entry.description]);
        });
        const streamMarkersSheet = XLSX.utils.aoa_to_sheet(streamMarkersSheetData);
        const max_widths_7 = [streamMarkersSheetData.reduce((w, r) => Math.max(w, String(r[0]).length), 10), streamMarkersSheetData.reduce((w, r) => Math.max(w, String(r[1]).length), 10), streamMarkersSheetData.reduce((w, r) => Math.max(w, String(r[2]).length), 10), streamMarkersSheetData.reduce((w, r) => Math.max(w, String(r[3]).length), 10)];
        streamMarkersSheet['!cols'] = [{ wch: max_widths_7[0] }, { wch: max_widths_7[1] }, { wch: max_widths_7[2] }, { wch: max_widths_7[3] }];
        console.log('Stream Markers Sheet:', streamMarkersSheet);

        workBook.SheetNames.push('Stream Markers');
        workBook.Sheets['Stream Markers'] = streamMarkersSheet;

        const losersSheetData = [];
        losersSheetData.push(['Twitch Handle', 'Channel', 'Time']);
        entry.losers.forEach(entry => {
            losersSheetData.push([entry.twitchHandle, entry.channel, getShortDateTimeString(entry.time, false)]);
        });
        const losersSheet = XLSX.utils.aoa_to_sheet(losersSheetData);
        const max_widths_6 = [losersSheetData.reduce((w, r) => Math.max(w, String(r[0]).length), 10), losersSheetData.reduce((w, r) => Math.max(w, String(r[1]).length), 10), losersSheetData.reduce((w, r) => Math.max(w, String(r[2]).length), 10)];
        losersSheet['!cols'] = [{ wch: max_widths_6[0] }, { wch: max_widths_6[1] }, { wch: max_widths_6[2] }];
        console.log('Losers Sheet:', losersSheet);

        workBook.SheetNames.push('Losers');
        workBook.Sheets['Losers'] = losersSheet;

        return workBook;
    }

    const downloadXlsxData = (entry, timezoneData, index) => {
        const workBook = createXlsxData(entry, timezoneData);
        XLSX.writeFile(workBook, 'ArcadeData_' + (campaignContext.campaignData.campaignName.replace(/[ ]/g, '-')) + '_' + getShortDateNumericStringForFilenames(entry.activationTime) + '_' + (index + 1) + '.xlsx');
    }

    const SORT_TYPES = {
        HANDLE: 'twitchHandle',
        CHANNEL: 'channel',
        ENTRY_TIME: 'entryTime',
        ENTRY_COUNT: 'entryCount'
    }

    const getUserEntriesJsxBlock = (entries, timezoneData, extraHeading = "", extraProp = null, sortFunction = null, currentSort = null, sortListReverse = null) => {
        return (
            <div className="fl-column no-gap tight">
                <div className="grid sticky">
                    <div className="header label" onClick={sortFunction ? (event) => {sortFunction(event, SORT_TYPES.HANDLE)} : null}>Twitch Handle {currentSort === SORT_TYPES.HANDLE ? (IconJsxer.GetIcon(sortListReverse ? IconJsxer.ICONS.chevronUp : IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.roundFormControl)) : ''}</div>
                    <div className="header label" onClick={sortFunction ? (event) => {sortFunction(event, SORT_TYPES.CHANNEL)} : null}>Channel {currentSort === SORT_TYPES.CHANNEL ? (IconJsxer.GetIcon(sortListReverse ? IconJsxer.ICONS.chevronUp : IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.roundFormControl)) : ''}</div>
                    <div className="header label" onClick={sortFunction ? (event) => {sortFunction(event, SORT_TYPES.ENTRY_TIME)} : null}>Time {currentSort === SORT_TYPES.ENTRY_TIME ? (IconJsxer.GetIcon(sortListReverse ? IconJsxer.ICONS.chevronUp : IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.roundFormControl)) : ''}</div>
                    <div className="header label centered" onClick={sortFunction ? (event) => {sortFunction(event, SORT_TYPES.ENTRY_COUNT)} : null}>{extraHeading && extraProp ? extraHeading : ''} {currentSort === SORT_TYPES.ENTRY_COUNT ? (IconJsxer.GetIcon(sortListReverse ? IconJsxer.ICONS.chevronUp : IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.roundFormControl)) : ''}</div>
                </div>
                    {entries.map((entry, i) => {
                        const startTimeHms = timezoneData ? convertMSToHMS(entry.entryTime || entry.time, false, timezoneData.offset) : null;
                        const altBgClass = i % 2 === 0 ? ' alt-bg' : '';
                        return (
                            <div className={"grid" + altBgClass} key={'entry_' + i}>
                                <div className={"data"}><a href={'https://www.twitch.tv/' + entry.twitchHandle} target="_blank">{entry.twitchHandle}</a></div>
                                <div className={"data"}><a href={'https://www.twitch.tv/' + entry.channel} target="_blank">{entry.channel}</a></div>
                                <div className={"data fl-row"}>
                                    {timezoneData &&
                                        <>
                                            <div className='fl-row shrink'>
                                                {`${startTimeHms.h_str}:${startTimeHms.m_str}`}
                                            </div>
                                        </>
                                    }

                                    <div className='fl-row shrink light-text local-time-list'>
                                        {getShortTimeString(entry.entryTime || entry.time, false)} (Local Time)
                                    </div>
                                </div>
                                <div className={"data centered"}>{extraHeading && extraProp ? entry[extraProp] : ''}</div>

                            </div>
                        );
                    })}
                {entries.length === 0 &&
                    <div className="grid">
                        <div className="data" colSpan="5">No Data Recorded</div>
                    </div>
                }
            </div>
        );
    }

    const OptInsList = (props) => {
        const listSliceLength = 25;
        const [sortedOptInList, setSortedOptInList] = useState(props.entries);
        const [optInList, setOptInList] = useState(props.entries.slice(0, listSliceLength));

        const [sortListReverse, setSortListReverse] = useState(false);
        const [currentSort, setCurrentSort] = useState(SORT_TYPES.ENTRY_TIME);

        const sortList = (event, type) => {
            event.preventDefault();
    
            let reverse = !sortListReverse;
            setSortListReverse(reverse);
    
            let sortedList = sortedOptInList.concat();
    
            switch (type){
                case SORT_TYPES.HANDLE:
                    sortListOn(sortedList, 'twitchHandle', reverse, alphaTypePrimer);
                    break;
                case SORT_TYPES.CHANNEL:
                    sortListOn(sortedList, 'channel', reverse, alphaTypePrimer);
                    break;
                case SORT_TYPES.ENTRY_TIME:
                    sortListOn(sortedList, 'entryTime', reverse, numberTypePrimer);
                    break;
                case SORT_TYPES.ENTRY_COUNT:
                    sortListOn(sortedList, 'entryCount', reverse, numberTypePrimer);
                    break;
                default:
                    break;
            }
    
            setCurrentSort(type)
            setSortedOptInList(sortedList);
            setOptInList(sortedList.slice(0, optInList.length));
        }

        const showMore = () => {
            const currentLength = optInList.length;
            const newLength = currentLength + listSliceLength;
            setOptInList(sortedOptInList.slice(0, newLength));
        }

        return (
            <div className="opt-in-scroll-container">
                <div className="fl-column">
                    {getUserEntriesJsxBlock(optInList, props.timezoneData, "Entries", "entryCount", sortList, currentSort, sortListReverse)}
                    {optInList.length < props.entries.length &&
                        <div className="fl-column no-gap">
                            <div className="bold-text light-text under-list">Showing {optInList.length} of {props.entries.length} entries</div>
                            <div className="fl-row">
                                <div className="shrink">
                                    <button className="standard-button tight" onClick={showMore}>Show More</button>
                                </div>
                                <div className="grow"></div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }

    const getArcadeDataEntryJsxBlock = (entry, i, timezoneData = null) => {
        const startTimeHms = timezoneData ? convertMSToHMS(entry.activationTime, false, timezoneData.offset) : null;
        const endTimeHms = timezoneData ? convertMSToHMS(entry.deActivationTime, false, timezoneData.offset) : null;
        return (
            <div className="arcade-data-box" key={'arcade_data_' + i}>
                <div className='fl-row list-view-content'>
                    <div className="fl-row grow">
                        <div className="light-text bold-text">Activation #{i + 1}</div>
                        {timezoneData &&
                            <>
                                <div className='fl-row shrink arcade-data-heading-row-block'>
                                    {IconJsxer.GetIcon(IconJsxer.ICONS.scheduleNav, IconJsxer.ICON_STYLES.listIcon)}
                                    {getTzLongDateString(entry.activationTime, timezoneData.offset)}
                                </div>

                                <div className='fl-row shrink arcade-data-heading-row-block'>
                                    {IconJsxer.GetIcon(IconJsxer.ICONS.clock, IconJsxer.ICON_STYLES.listIcon)}
                                    {`${startTimeHms.h_str}:${startTimeHms.m_str} - ${endTimeHms.h_str}:${endTimeHms.m_str} (${timezoneData.label.replace('(', '').replace(')', '')})`}
                                </div>
                            </>
                        }

                        <div className='fl-row shrink arcade-data-heading-row-block light-text sl-local-time'>
                            {IconJsxer.GetIcon(IconJsxer.ICONS.clock, IconJsxer.ICON_STYLES.listIcon)}
                            {getShortDateTimeString(entry.activationTime, false, ', ')} - {getShortTimeString(entry.deActivationTime, false)} (Local Time)
                        </div>

                        <div className="grow"></div>

                        <div className='fl-row shrink arcade-data-heading-row-block bold-text'>
                            {IconJsxer.GetIcon(IconJsxer.ICONS.arcadeAttemps, IconJsxer.ICON_STYLES.listIcon)}
                            {entry.attempts}
                        </div>

                        <div className='fl-row shrink arcade-data-heading-row-block bold-text'>
                            {IconJsxer.GetIcon(IconJsxer.ICONS.arcadePrizes, IconJsxer.ICON_STYLES.listIcon)}
                            {entry.prizesWon}
                        </div>
                        <div className="fl-row shrink edit-icon" onClick={(e) => {e.preventDefault(); e.stopPropagation(); downloadXlsxData(entry, timezoneData, i)}}>
                            {IconJsxer.GetIcon(IconJsxer.ICONS.fileDownload, IconJsxer.ICON_STYLES.listIcon)}
                        </div>

                    </div>
                </div>
                <CollapsibleHeadedSection
                    title="Game Setup"
                >
                    <div className="fl-column no-gap tight">
                        <div className="grid game-setup">
                            {Object.keys(entry.gameSettings).map((key, i) => {
                                return (
                                    <Fragment key={'d_' + i}>
                                        <div className="data light-text">{entry.gameSettings[key].label}</div>
                                        <div className="data">{String(entry.gameSettings[key].value)}</div>
                                    </Fragment>
                                );
                            })}
                        </div>
                    </div>
                </CollapsibleHeadedSection>
                <CollapsibleHeadedSection
                    title={"Prizes (" + entry.prizes.length + ")"}
                >
                    <div className="fl-column no-gap tight">
                        <div className="grid prizes">
                            <div className="header label">Name</div>
                            <div className="header label">Description</div>
                            <div className="header label">Target</div>
                            <div className="header label centered">Quantity</div>
                        </div>
                        {entry.prizes.map((prize, i) => {
                            const altBgClass = i % 2 === 0 ? ' alt-bg' : '';
                            return (
                                <div className={"grid prizes" + altBgClass} key={'p_' + i}>
                                    <div className="data">{prize.name}</div>
                                    <div className="data">{prize.description}</div>
                                    <div className="data">{prize.targetDescription}</div>
                                    <div className="data centered">{prize.quantity}</div>
                                </div>
                            );
                        })}
                        {entry.prizes.length === 0 &&
                            <div className="grid prizes">
                                <div className="data" colSpan="4">No Prizes</div>
                            </div>
                        }
                    </div>
                </CollapsibleHeadedSection>
                <CollapsibleHeadedSection
                    title={"Opt Ins (" + entry.entries.length + ")"}
                >
                    {/*getUserEntriesJsxBlock(entry.entries, timezoneData, "Entries", "entryCount")*/}
                    <OptInsList entries={entry.entries} timezoneData={timezoneData} />
                </CollapsibleHeadedSection>
                <CollapsibleHeadedSection
                    title={"Winners (" + entry.winners.length + ")"}
                >
                    {getUserEntriesJsxBlock(entry.winners, timezoneData, "Attempts", "attempts")}
                </CollapsibleHeadedSection>
                <CollapsibleHeadedSection
                    title={"Stream Markers (" + entry.streamMarkers.length + ")"}
                >
                    <div className="fl-column no-gap tight">
                        <div className="grid stream-markers">
                            <div className="header label">Twitch Handle</div>
                            <div className="header label">Channel</div>
                            <div className="header label">Time</div>
                            <div className="header label">Description</div>
                        </div>
                        {entry.streamMarkers.map((marker, i) => {
                            const altBgClass = i % 2 === 0 ? ' alt-bg' : '';
                            const tData = convertSecondsToHMS(marker.time, true);
                            return (
                                <div className={"grid stream-markers" + altBgClass} key={'m_' + i}>
                                    <div className="data"><a href={'https://www.twitch.tv/' + marker.twitchHandle} target="_blank">{marker.twitchHandle}</a></div>
                                    <div className="data"><a href={'https://www.twitch.tv/' + marker.channel} target="_blank">{marker.channel}</a></div>
                                    <div className="data">{`${tData.h} hrs ${tData.m} mins ${tData.s} secs (${marker.time} seconds)`}</div>
                                    <div className="data">{marker.description}</div>
                                </div>
                            );
                        })}
                        {entry.streamMarkers.length === 0 &&
                            <div className="grid stream-markers">
                                <div className="data" colSpan="4">No Stream Markers Set</div>
                            </div>
                        }
                    </div>
                </CollapsibleHeadedSection>
                <CollapsibleHeadedSection
                    title={"Losers (" + entry.losers.length + ")"}
                >
                    {getUserEntriesJsxBlock(entry.losers, timezoneData)}
                </CollapsibleHeadedSection>
            </div>
        );
    }

    const localTimezoneData = parseTimezone(getDefaultTimezone());
    return (
        <PopUpPanel closePanelFunc={props.closePanelFunc} className="arcade-data-popup">
            <div className="form-holder co-popup-content">
                <h2>Arcade Data for {campaignContext.campaignData.campaignName}</h2>
                <div className="arcade-data-scrolling-panel fl-column">
                    {streams.map((stream, i) => {
                        const timezoneData = parseTimezone(stream.timeZone);
                        return (
                            <OneStreamOverview 
                                stream={stream} 
                                timezoneData={timezoneData}
                                useCustomContentChildren={true}
                                key={'astream_' + i}
                            >
                                <>
                                    {!arcadeDataFetched[stream._id] &&
                                        <>
                                            <div className="underlined-panel-heading">                                 
                                                <h4>Fetching Data...</h4>
                                            </div>
                                        </>
                                    }
                                    {arcadeDataFetched[stream._id] && (!arcadeData[stream._id] || arcadeData[stream._id].length === 0) &&
                                        <div className="underlined-panel-heading">
                                            <h4>No Data To Display</h4>
                                        </div>
                                    }
                                    {arcadeDataFetched[stream._id] && arcadeData[stream._id] && arcadeData[stream._id].length > 0 &&
                                        <>
                                            <div className="underlined-panel-heading">
                                                <h4>Data From Stream</h4>
                                            </div>
                                            <div className="fl-column">
                                                {arcadeData[stream._id].map((entry, i) => {
                                                    return getArcadeDataEntryJsxBlock(entry, i, timezoneData);
                                                })}
                                            </div>
                                        </>
                                    } 
                                </>
                            </OneStreamOverview>
                        );
                    })}
                    {arcadeDataFetched['preview_test'] &&
                        <div className="arcade-data-box no-gap">
                            <div className="underlined-panel-heading">
                                <h4>Test Data From Previews</h4>
                            </div>
                            <div className="fl-column">
                                {arcadeData['preview_test'].map((entry, i) => {
                                    return getArcadeDataEntryJsxBlock(entry, i, localTimezoneData);
                                })}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </PopUpPanel>
    )
}

export default ArcadeDataPopup;
