import React, { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { logoutRoute  } from "../api/routes";
import axios from "../api/axios";
import { toast } from "react-toastify";

export const STRONG_PASSWORD_SETTINGS = {
    minLength: 8,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 1,
    minSymbols: 1,
}

export const NOT_STRONG_PASSWORD_ERROR = "Must contain 8 characters, one uppercase letter, one lowercase letter, one number, and one special symbol";

export const LogoutHandler = (setAuthContext) => {  
 
    axios
    .get(logoutRoute, {withCredentials:true})
    .then(function (response) {
        toast.success("Logged Out Successfully!");
        
        setAuthContext(oldValues => {
            return { ...oldValues, token: null, isLoggedIn:false, isConfirmed:false, userData:{} }
        })
        window.localStorage.setItem("logout", Date.now())
    })
    .catch(function (error) {
         toast.error(error.response.data);
    });
}
