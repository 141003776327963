import PopUpPanel from "../PopUpPanel/PopUpPanel";
import { useContext, useEffect, useState, useReducer } from "react";
import axios from "../../api/axios";
import { userListAllUsersRoute } from "../../api/routes";
import { PRIMERS, filterList, searchList } from "../../helpers/ListFunctions";
import { DUMMY_USER } from "../../helpers/DummyData";
import { ACCOUNT_TYPES } from "../../helpers/AccountTypes";
import SearchBox from "../forms/SearchBox/SearchBox";
import ListedUser from "./ListedUser";
import ReactPaginate from 'react-paginate';
import { IconJsxer } from "../../helpers/IconHelper";
import "./SelectUser.scss";

const SelectUser = (props) => {

    /*********************************************************************
    * User List & Pagination
    * ******************************************************************/
    const [pageUpdate, forcePageUpdate] = useReducer(x => x + 1, 0);
    const [fetchUpdate, forceFetchUpdate] = useReducer(x => x + 1, 0);
    const [searchTerm, setSearchTerm] = useState('');
    const [availableUsers, setAvailableUsers] = useState([]);
    const [selectedClientsUserData, setSelectedClientsUserData] = useState([]);
    const [userFilter, setUserFilter] = useState({id:null});
    const [selectedProducersUserData, setSelectedProducersUserData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(0);

    const handlePageClick = (event) => {
        setCurrentPage(event.selected)
      };

    const searchUserList = (e, searchTerm, filteredList = null) => {
        setSearchTerm(searchTerm);
    }

    const fetchUserList = () => {
        axios
            .get(userListAllUsersRoute, { params:{
                page:currentPage, 
                searchTerm:searchTerm, 
                userType:null, 
                authType:null,
                isTwitchOnly:true, 
                sort:null,
                reverse:null
            }, withCredentials: true })
            .then((response) => {
                const userData = response.data.allusers;
                console.log(response.data.allusers)
                setAvailableUsers(userData)
                setNumberOfPages(response.data.numberOfPages);
            })
            .catch((error) => {
                //toast.error(error.response.data.msg);
            });
    }
    
    useEffect(() => {
       forcePageUpdate();
    }, [userFilter, searchTerm])

    useEffect(() => {
        console.log('GET PAGE CP')
       fetchUserList();
    }, [ currentPage, fetchUpdate])

    useEffect(() => {
        console.log('PAGE DATA UPDATED')
        currentPage == 0 ? forceFetchUpdate() : setCurrentPage(0);
       
    }, [numberOfPages, pageUpdate])
    

 

    const selectUser = (e, item) => {
        if (props.closePanelFunc) {
            props.closePanelFunc(null);
        }
        if (props.selectUserFunc) {
            props.selectUserFunc(item);
        }
    }

    return (
        <PopUpPanel closePanelFunc={props.closePanelFunc}>
            <div className="form-holder">
                <h2>Select {props.twitchOnly ? 'Twitch ' : ''}User</h2>
                <SearchBox searchFunc={searchUserList} initialSearchTerm={searchTerm} />
                <div className="select-user-pop">
                    <div className="scroll-panel">
                        <p className="label light-text">{props.twitchOnly ? 'Available Twitch Accounts' : 'Available Users'}</p>
                        <div className="scroll-container">
                            {availableUsers.map(
                                (val, i) => {
                                   
                                        return (
                                            <div className="one-user" key={'sel_user_' + i} onClick={(e) => selectUser(e, val)}>
                                                <ListedUser user={val} showCheck={false} />
                                            </div>
                                        )
                                    
                                }
                            )}
                        </div>
                        <ReactPaginate
                                                activeClassName={'pagination-item item-active '}
                                                breakClassName={'pagination-item break-me '}
                                                breakLabel={'...'}
                                                containerClassName={'pagination'}
                                                disabledClassName={'disabled-page'}
                                                marginPagesDisplayed={2}
                                                nextClassName={"pagination-item  "}
                                                nextLabel={IconJsxer.GetIcon(IconJsxer.ICONS.chevronRight, IconJsxer.ICON_STYLES.settingsEdit)}
                                                onPageChange={handlePageClick}
                                                pageCount={numberOfPages}
                                                forcePage={currentPage}
                                                pageClassName={'pagination-item pagination-page '}
                                                pageRangeDisplayed={2}
                                                previousClassName={"pagination-item  "}
                                                previousLabel={IconJsxer.GetIcon(IconJsxer.ICONS.chevronLeft, IconJsxer.ICON_STYLES.settingsEdit)}
                                                renderOnZeroPageCount={false}
                                            />
                    </div>
                </div>
            </div>
        </PopUpPanel>
    );
}

export default SelectUser;
