import PropTypes from 'prop-types';
import DebugLogger from "../../../helpers/DebugLogger";

import "./FormDropDown.scss";
import { useEffect, useState } from 'react';

const FormDropDown = (props) => {
    const { id, showLabel, label, labelNote, items, currentIndex, selectFunc, error, showErrors, className, enabled } = props;
    const [_currentIndex, setCurrentIndex] = useState(currentIndex);
    useEffect(
        () => {
            if (currentIndex < items.length) {
                setCurrentIndex(currentIndex)
            } else {
                setCurrentIndex(0);
                if (selectFunc && items.length > 0) {
                    selectFunc(null, id, items[0]);
                }
            }
        },
        [props.currentIndex]
    )
    
    const selectItem = (e) => {
        const i = e.target.selectedIndex;
        if (enabled && selectFunc) {
            items[i].index = i;
            // Just make these nice and robust!
            if (typeof items[i].value === 'undefined' && items[i].val !== undefined) {
                items[i].value = items[i].val;
            }
            selectFunc(e, id, items[i]);
        }
        if (selectFunc === null) {
            DebugLogger.warn('You should provide a prop called selectFunc(e, id, item) to FormDropDown. The component should be controlled by passing in the currentIndex as a prop, the index can be found as part of the item data passed back to your selectFunc. DropDownList does NOT track it\'s own state!');
        }
    }

    // console.log('Form dropdown items', items);

    return (
        <div className={className}>
            {showLabel && label != null &&
                <div className="form-field-content label">
                    {label}
                </div>
            }
            {labelNote != null && labelNote !== '' &&
                <div className="form-field-content label-note" dangerouslySetInnerHTML={{__html: labelNote}}>
                    {/*labelNote*/}
                </div>
            }
            <div className="select-holder select">
                {/* We are not using value as our first choice here as that messes up our Time Zone selector (some values are identical) */}
                <select onChange={selectItem} value={(items?.[_currentIndex]?.val || items?.[_currentIndex]?.value || items?.[_currentIndex]?.text || items?.[_currentIndex]?.label) ?? ''} id={id} disabled={!enabled}>
                    {items.map(
                        (val, i) => {
                            return (
                                <option key={id + '_' + i} value={val.val || val.value || val.text || val.label}>{val.label || val.text || val.value || val.val}</option>
                            )
                        }
                    )}
                </select>
                {enabled &&
                    <div className="select_arrow"></div>
                }
            </div>
            {showErrors &&
                <div className="error">
                    {error}&nbsp;
                </div>
            }
        </div>
    )
}

FormDropDown.propTypes = {
    id: PropTypes.string.isRequired,
    showLabel: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    labelNote: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentIndex: PropTypes.number.isRequired,
    selectFunc: PropTypes.func,
    error: PropTypes.string.isRequired,
    showErrors: PropTypes.bool.isRequired,
    enabled: PropTypes.bool,
    className: PropTypes.string,
};

FormDropDown.defaultProps = {
    showLabel: true,
    label: 'Choose',
    labelNote: '',
    id: 'drop_list',
    items: [
        {
            id: 'none',
            label: 'None',
            deselect: true,
        },
        {
            id: 'item1',
            label: 'Item 1',
        },
        {
            id: 'item2',
            label: 'Item 2',
        },
        {
            id: 'item3',
            label: 'Item 4',
        },
    ],
    currentIndex: 0,
    selectFunc: null,
    error: '',
    showErrors: true,
    enabled: true,
    className: '',
}

export default FormDropDown;
