import React, { useState, useEffect, useContext } from "react";

import axios from "../../api/axios";

import { toast } from "react-toastify";
import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import FormField from "../../components/forms/FormField/FormField";
import { CampaignContext } from "../../contexts/CampaignContext";
import { getDefaultTimeZoneListData, convertFormDateTimeTimezoneToUnixTimestamp } from "../../helpers/Dates";
import SelectUser from "../../components/SelectUser/SelectUser";
import { addStreamChannelRoute } from "../../api/routes";


const AddStreamChannel = (props) => {
    const [campaignContext, setCampaignContext] = useContext(CampaignContext);

    const [showSelectUser, setShowSelectUser] = useState(false);
    const toggleShowSelectUser = (e) => {
        if (e) {
            e.preventDefault();
        }
        setShowSelectUser(!showSelectUser);
    }
    const userSelected = (user) => {
        console.log(user);
        setStreamerHandle(user.name);
    }

    const [streamerHandle, setStreamerHandle] = useState('');
    const [streamerHandleError, setStreamerHandleError] = useState('');

    const [isSubmitting, setIsSubmitting] = useState(false);

    const saveStreamChannel = (e) => {
        e.preventDefault();

        if(!isSubmitting)
        {
            let formValid = true;
           if (streamerHandle === '') {
                setStreamerHandleError('You need to enter your streamer\'s Twitch Handle');
                formValid = false;
            } else {
                setStreamerHandleError('');
            }

            if (formValid) {
                if(!isSubmitting)
                {
                    // do axios post here
                    setIsSubmitting(true);
                    axios
                    .post(addStreamChannelRoute, { 
                        campaignID:props.campaignId,
                        streamID:props.streamID,
                        twitchHandle:streamerHandle
                    })
                    .then((response) =>{
                        console.log(response)
                        let oldCampaignData = campaignContext.campaignData
                        oldCampaignData.streams = response.data.campaignData.streams;
                        setIsSubmitting(false);
                        setCampaignContext((oldValues) => {
                            return { ...oldValues, initialising: false, campaignData: oldCampaignData }
                        })
                        toast.success("Channel ("+streamerHandle+") successfully added to stream");
                        props.closePanelFunc();
                    })
                }
                
            }
        }
    }

    return (
        <>
        {!showSelectUser &&
        <PopUpPanel closePanelFunc={props.closePanelFunc}>
            <div className="form-holder co-popup-content">
                <form id="addstream" className="form">
                    <h2>Add Channel To Stream</h2>
                    <div className="fl-row">
                        <div className="grow">
                        <FormField
                            type="text"
                            id="str_handle"
                            placeholder="Enter Twitch Handle"
                            setFunc={setStreamerHandle}
                            label="Streamer Twitch Handle"
                            error={streamerHandleError}
                            value={streamerHandle}
                        />
                        </div>
                        <div>
                        <button className="submit-btn" onClick={toggleShowSelectUser}>Select</button>
                        </div>
                    </div>
                    <button className={'submit-btn' + (isSubmitting ? ' button-inactive' : ' button-active')} onClick={isSubmitting ? null : saveStreamChannel}>
                        {isSubmitting ? "Adding Channel" : "Add Channel"}
                    </button>
                </form>
            </div>
        </PopUpPanel>
        }
        {showSelectUser &&
            <SelectUser closePanelFunc={toggleShowSelectUser} selectUserFunc={userSelected} twitchOnly={true} />
        }
        </>
    )
}

export default AddStreamChannel;
