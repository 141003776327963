import { useState } from "react";
import FormDropDown from "../../../forms/DropDownList/FormDropDown";
import { Player } from 'media-stream-player';
import "./ClawCameraFeeds.scss";

const clawCameraFeeds = [
    {
        id: 'machine1cam1',
        label: 'Left, Cam 1',
        value: 'clawcam1.twitchcampaignstudio.com'
    },
    {
        id: 'machine1cam2',
        label: 'Left, Cam 2',
          value:'clawcam2.twitchcampaignstudio.com'
    },
    {
        id: 'machine2cam1',
        label: 'Right, Cam 1',
          value:'clawcam3.twitchcampaignstudio.com'
    },
    {
        id: 'machine2cam2',
        label: 'Right, Cam 2',
        value:'clawcam4.twitchcampaignstudio.com'
    }
]




const ClawCameraFeeds = (props) => {
    const [currentFeed, setCurrentFeed] = useState(clawCameraFeeds[0].value);
    const [currentFeedIndex, setCurrentFeedIndex] = useState(0);
    const [switchingFeed, setSwitchingFeed] = useState(false);

    const switchFeed = (index) => {
        setCurrentFeed(clawCameraFeeds[index].value);
        setCurrentFeedIndex(index);
        setSwitchingFeed(true);
        setTimeout(() => {
            setSwitchingFeed(false);
        }, 100);
    }

    return (
        <div className="fl-column">
            <FormDropDown
                label='Select Camera Feed'
                items={clawCameraFeeds}
                value={currentFeed}
                selectFunc={(e, id, item) => {switchFeed(item.index)}}
                currentIndex={currentFeedIndex}
            />
            <div className="claw-feed-holder">
                {!switchingFeed && 
                    <Player
                        hostname={currentFeed}
                        initialFormat="RTP_H264"
                        secure
                        autoPlay
                        autoRetry
                    />
                }
            </div>
        </div>
    )
}

export default ClawCameraFeeds;
