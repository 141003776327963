import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DebugLogger from "../../../helpers/DebugLogger";
import axios from "../../../api/axios";

// import DefaultPlaceholderImg from "./placeholder.png";
import "./AudioUploader.scss";
import { IconJsxer } from '../../../helpers/IconHelper';

export const UPLOADER_PREVIEW_SIDE = {left: 'left', top: 'top'};

/**
 * Audio uploader component.
 * Select an audio to upload and see an immediate preview.
 * Optionally upload the audio immediately or return the selected file to a callback so it can be uploaded as part of a larger form.
 * 
 * PROPS (mostly self explnatory, but some may need a little extra explanation, all are optional and have defaults / warnings if recommended):
 * 
 * label = A label for your uploader
 * uploadImmediately = true / false. If true then the uploader will attempt to upload your audio to a provided endPoint as soon as it is selected.
 * endPoint = This is your endpoint for immediate uploads, for profile pics this is uploadProfilePicRoute from api/routes.js
 * removeEndPoint = Only really needed for immediate uploads, an endpoint to call to remove audio files from the server / db, see removeProfilePicRoute in api/routes.js
 * id = An id for your audio, when set to uploadImmediately the id will be passed to the endPoint otherwise it is passed to the callbacks detailed below
 * extraData = Optional extra data passed to your endpoint. If this is an object then each property is extracted and passed up alongside your id
 * 
 * -- Only used when uploadImmediately=false --
 * fileSelectedCallback = A callback that receives and object in this format: {id: id, file: file, originalEvent: e}, 
 *                        this is a different signature to the other callbacks simply because you will most likely need all the data that gets passed back
 *                        (you will probably need to store the selected audio data somewhere ready for submitting to your endpoint),
 *                        it fires when a file is selected just like it would if you used <input type="file"> directly instead of this component but with
 *                        a little bit more data included.
 *                        When removing the audio you will receive: {id: id, file: null, originalEvent: 'removed'}, allowing you to remove the audio from your stored form data.
 * 
 * -- Only used when uploadImmediately=true --
 * uploadedCallback = A callback that receives (res, file, id), you can probably ignore most params, but When uploading profile pics, I use it to set the profile pic
 *                      in authcontext userData.profilePic = res.data.uploadedFilename; so we can immediately start showing the new profile pic without asking the api for it.
 * 
 * -- Only used when uploadImmediately=true --
 * uploadErrorCallback = A callback you can use to deal with upload errors, but you probably won't need it...
 * 
 * -- Only used when uploadImmediately=true, if false you should use a fileSelected callback instead --
 * removedCallback = A callback you can use to detect when an audio is removed.
 *                      I use it when a user removes their profile audio to set the profile audio data on authcontext as with uploadedCallback
 * 
 * Hopefully that's all clear! :) - Carl
 * 
 * @param {*} props 
 * @returns The component
 */
const AudioUploader = (props) => {
  const { label, labelNote, showRemoveOption, uploadImmediately, endPoint, removeEndPoint, id, extraData, fileSelectedCallback, uploadedCallback, uploadErrorCallback, removedCallback } = props;
  let currentFile = props.currentFile;
  let currentFileName = props.currentFileName;

  // Warn about missing props...
  if (uploadImmediately && endPoint === '') {
    DebugLogger.error('No endPoint provided to Audio Uploader, you should provide one if uploadImmediately=true.');
  }
  if (!uploadImmediately && fileSelectedCallback === null) {
    DebugLogger.warn('No fileSelectedCallback provided to Audio Uploader, you should provide one if uploadImmediately=false.');
  }
  if (showRemoveOption && uploadImmediately && removeEndPoint === '') {
    DebugLogger.error('No removeEndPoint provided to Audio Uploader, you should provide one if uploadImmediately=true and showRemoveOption=true.');
  }
  if (showRemoveOption && uploadImmediately && removedCallback === null) {
    DebugLogger.warn('You should provide a removedCallback when using uploadImmediately=true and showRemoveOption=true.');
  }

  const [busy, setBusy] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [previewSrc, setPreviewSrc] = useState(currentFile || null);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(currentFileName || null);
  const fileInputRef = useRef(null);
  const audioPlayerRef = useRef(null);

  useEffect(
    () => {
      setFile(null);
      setPreviewSrc(null);
      setTimeout(
        () => setPreviewSrc(props.currentFile),
        100
      );

      setFileName(props.currentFileName);
    },
    [props.currentFile, props.currentFileName]
  )

  const chooseFile = () => {if (!busy) fileInputRef.current.click();};
  
  const handleFile = (e) => {
    e.preventDefault();
    if (!busy) {
      if (e.target.files.length === 0) {
        return;
      }
      const file = e.target.files[0];
      console.log('File: ', file);
      setPreviewSrc(null);
      setFile(file);
      setFileName(file.name);
      setTimeout(
        () => setPreviewSrc(URL.createObjectURL(file)),
        100
      );
      if (uploadImmediately) {
        DebugLogger.log('Attempting to upload audio: ', file);
        if (endPoint) {
          const formData = new FormData();
          formData.append('audio', file);
          if (id) {
            formData.append('id', id);
          } else {
            DebugLogger.warn('No id provided for audio upload.')
          }
          if (extraData) {
            if (typeof extraData === 'string' || typeof extraData === 'number' || typeof extraData === 'boolean') {
              formData.append('extraData', extraData);
            } else {
              for (let prop in extraData) {
                formData.append(prop, extraData[prop]);
              }
            }
          }
          setBusy(true);

          axios.post(endPoint, formData, { withCredentials: true })
            .then(res => {
              DebugLogger.log('Upload result: ', res);
              setBusy(false);
              if (uploadedCallback) {
                uploadedCallback(res, file, id);
              }
            })
            .catch(err => {
              DebugLogger.log('Upload error: ', err);
              setBusy(false);
              if (uploadErrorCallback) {
                uploadErrorCallback(err, id);
              }
            });
        } else {
          DebugLogger.error('Could not upload audio, no endPoint provided.');
        }
      } else {
        if (fileSelectedCallback) {
          fileSelectedCallback({id: id, file: file, originalEvent: e});
        }
      }
    }
  }

  const handleRemove = (e) => {
    e.preventDefault();
    if (!busy) {
      stopAudio();
      if (uploadImmediately) {
        DebugLogger.log('Attempting to remove audio...');
        if (removeEndPoint) {
          const formData = new FormData();
          if (id) {
            formData.append('id', id);
          } else {
            DebugLogger.warn('No id provided for audio removal.')
          }
          if (extraData) {
            if (typeof extraData === 'string' || typeof extraData === 'number' || typeof extraData === 'boolean') {
              formData.append('extraData', extraData);
            } else {
              for (let prop in extraData) {
                formData.append(prop, extraData[prop]);
              }
            }
          }
          setBusy(true);

          axios.post(removeEndPoint, formData)
            .then(res => {
              DebugLogger.log('Remove audio result: ', res);
              setBusy(false);
              fileInputRef.current.value = '';
              setPreviewSrc(null);
              currentFile = null;
              if (removedCallback) {
                removedCallback(id);
              }
            })
            .catch(err => {
              DebugLogger.log('Remove audio error: ', err);
              setBusy(false);
            });
        } else {
          DebugLogger.error('Could not remove audio, no removeEndPoint provided.');
        }
      } else {
        fileInputRef.current.value = '';
        setPreviewSrc(null);
        currentFile = null;
        if (fileSelectedCallback) {
          fileSelectedCallback({id: id, file: null, originalEvent: 'removed'});
        }
        if (removedCallback) {
          removedCallback(id);
        }
      }
    }
  }

  const playAudio = () => {
    audioPlayerRef.current.currentTime = 0;
    audioPlayerRef.current.play();
    setPlaying(true);
  }

  const stopAudio = () => {
    audioPlayerRef.current.pause();
    setPlaying(false);
  }

  return (
    <div className="audio-upload-holder">

        <div className='fl-row'>

          <div className={`play-button${previewSrc === null ? ' btn-off' : ''}`} onClick={previewSrc ? (playing ? stopAudio : playAudio) : null}>
            {IconJsxer.GetIcon(playing ? IconJsxer.ICONS.stop : IconJsxer.ICONS.play, IconJsxer.ICON_STYLES.roundFormControl)}
          </div>

          <div className='fl-column compact grow'>
            <div className="label">
              {label}<br /><span className='light-text small-text'>(.ogg format only)</span>
            </div>
            {/*showLabel && labelNote != null && labelNote !== '' &&
              <div className="form-field-content label-note">
                {labelNote}
              </div>
            */}
            <div className='fl-row grow'>
              {previewSrc &&
                <>
                  <div className={`upload-btn${busy ? ' btn-off' : ''}`} onClick={busy ? null : chooseFile}>{busy ? 'Uploading' : (fileName ? fileName.split('.')[0].split('__')[0] + '.ogg' : 'Select File')}</div>
                  <div className='grow'></div>
                  {showRemoveOption &&
                    <div className='remove-btn' onClick={busy ? null : handleRemove}>{IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                  }
                </>
              }
              {previewSrc === null &&
                <>
                  <div className={`upload-btn${busy ? ' btn-off' : ''}`} onClick={busy ? null : chooseFile}>{busy ? 'Uploading' : 'Upload file'}</div>
                  <div className='grow'></div>
                  {showRemoveOption &&
                    <div className='remove-btn' onClick={busy ? null : handleRemove}>{IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.roundPanelButton)}</div>
                  }
                </>
              }
            </div>
          </div>

        </div>

            <input
              type="file"
              accept=".ogg"
              name="audio"
              onChange={handleFile}
              hidden
              ref={fileInputRef}
              id={id}
            />

            {previewSrc &&
              <audio ref={audioPlayerRef} crossOrigin="anonymous" onEnded={() => setPlaying(false)}>
                <source src={previewSrc} />
              </audio>
            }

    </div>
  );
}

AudioUploader.propTypes = {
  label: PropTypes.string,
  currentFile: PropTypes.any,
  currentFileName: PropTypes.string,
  endPoint: PropTypes.string,
  removeEndPoint: PropTypes.string,
  showRemoveOption: PropTypes.bool,
  id: PropTypes.string,
  extraData: PropTypes.any,
  uploadImmediately: PropTypes.bool,
  fileSelectedCallback: PropTypes.func,
  uploadedCallback: PropTypes.func,
  uploadErrorCallback: PropTypes.func,
  removedCallback: PropTypes.func,
};

AudioUploader.defaultProps = {
  label: 'Custom Audio',
  currentFile: '',
  currentFileName: 'Current File',
  endPoint: '',
  removeEndPoint: '',
  showRemoveOption: true,
  id: '',
  extraData: null,
  uploadImmediately: false,
  fileSelectedCallback: null,
  uploadedCallback: null,
  uploadErrorCallback: null,
  removedCallback: null,
}

export default AudioUploader; 
