import PropTypes from 'prop-types';
import "./CirclePerc.scss";

/*
    If you want to restyle this, override the css!
*/

const CirclePerc = (props) => {
    return (
        <div className="circle-perc">
            <div className="back-circle">
                <svg viewBox="0 0 36 36">
                    <path
                        d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
                        fill="none"
                    />
                </svg>
            </div>
            <div className="perc-circle" style={{strokeDasharray: props.perc + ", 100"}}>
                <svg viewBox="0 0 36 36">
                    <path
                        d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
                        fill="none"
                    />
                </svg>
            </div>
            {props.children}
        </div>
    )
}

CirclePerc.propTypes = {
    perc: PropTypes.number,
}

CirclePerc.defaultProps = {
    perc: 75,
}

export default CirclePerc;
