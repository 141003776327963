import React, { useState, useEffect, useContext } from "react";

import axios from "../../api/axios";
import { CampaignContext } from "../../contexts/CampaignContext";
import { toast } from "react-toastify";
import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import FormDropDown from "../../components/forms/DropDownList/FormDropDown";
import { getDisruptorListRoute, getGenericQuizzesRoute, getQuizzesByCampaignRoute } from "../../api/routes";
import FormField from "../../components/forms/FormField/FormField";
import RadioButtonGroup from "../../components/forms/RadioButtonGroup/RadioButtonGroup";
import { getQuizRoute, createQuizRoute, quizUpdateRoute, getCampaignByIdRoute, getExtraQuizDataRoute, getAllCampaignsRoute } from "../../api/routes";

const AddQuiz = (props) => {
    const [campaignContext, setCampaignContext] = useContext(CampaignContext);

    /* const [disruptorName, setDisruptorName] = useState('');
    const [disruptorNameError, setDisruptorNameError] = useState('');

    const [chainPosition, setChainPosition] = useState('start');

    const [rawDisruptorList, setRawDisruptorList] = useState([]);
    const [selectedDisruptor, setSelectedDisruptor] = useState(null);
    const updateDisruptorHandler = (e, dropDownId, item) => {
        console.log('Disruptor selected: ', item);
        setSelectedDisruptor(item);
    }
    const [selectedDisruptorError, setSelectedDisruptorError] = useState('');

    const [selectedExistingDisruptor, setSelectedExistingDisruptor] = useState(null);
    const updateExistingDisruptorHandler = (e, dropDownId, item) => {
        console.log('Existing disruptor selected: ', item);
        setSelectedExistingDisruptor(item);
    }
    const [selectedExistingDisruptorError, setSelectedExistingDisruptorError] = useState(''); */

    const [isSubmitting, setIsSubmitting] = useState(false);

    /* const [campaignQuizzes, setCampaignQuizzes] = useState([]);
    const [genericQuizzes, setGenericQuizzes] = useState([]); */

    const createNewQuiz = () =>{
       console.log('Create New Quiz')
    }

    const [selectedExistingQuiz, setSelectedExistingQuiz] = useState(null);
    const existingQuizSelected = (e, id, item) => {
        console.log('Existing quiz selected: ', item);
        setSelectedExistingQuiz(item);
    }
    const addExistingQuiz = () =>{
        props.addExistingQuizToList(selectedExistingQuiz.quiz);
        props.closePanelFunc();
    }

    useEffect(() => {
        //fetchDisruptorList();
        if(quizStatuses.length == 0) {
            getQuizOptionData();
        }
    }, [])

    const [addNew, setAddNew] = useState(true);
    const selectRadioOption = (e, id, val) => {
        setAddNew(val === 'new');
    }

    const [quizNameError, setQuizNameError] = useState('');

    const getQuizOptionData = () => {
        axios
            .get(getExtraQuizDataRoute, {withCredentials: true})
            .then((res) => {
                setQuizStatuses(res.data.extraQuizData.statuses);
                setQuizTypes(res.data.extraQuizData.quizTypes);
               // setQuestionTypes(res.data.extraQuizData.questionTypes);


               
                setQuizGameMode(res.data.extraQuizData.gradingSystems[0].value)
                setGradingSystems(res.data.extraQuizData.gradingSystems);
            })
            .catch(err => {
                toast.error("Failed to get question Types and statuses")
            })
    };

    const createNewCampaignQuiz = () => {
        if(!isSubmitting)
        {
            if (quizName === '') {
                setQuizNameError("Please enter a quiz name");
                return;
            }

            console.log(quizTypes[0])
            setIsSubmitting(true);
            setQuizNameError('');
            axios
            .post(createQuizRoute,
                {
                    
                    name: quizName,
                    campaignID: props.campaignId, 
                    streamID: null, 
                    type:quizTypes[quizTypeIndex].id,
                    status:'Live',
                    gameMode:quizGameMode,
                    /*
                    disruptors: {
                        introDisruptor: quiz.introDisruptor,
                        correctDisruptor: quiz.correctDisruptor,
                        incorrectDisruptor: quiz.incorrectDisruptor
                    }, 
                    rounds: quiz.rounds, 
                    status: quiz.status,*/
                    withCredentials: true
                }
            )
            .then(response => {
                const createdQuiz = response.data.createdQuiz
                if(createdQuiz) {
                    toast.success("Quiz created successfully");


                    const newQuizData = {quiz: {_id: createdQuiz._id, name: createdQuiz.name, type:createdQuiz.type}};
                   console.log('NEW QUIZ DATA', props.addExistingQuizToList)
                    props.addExistingQuizToList(newQuizData.quiz);

                    props.closePanelFunc();
                    //setTimeout(() => { navigate(getNavigationRoute()) }, 600);
                } else {
                    toast.error(response.data.error);
                    setQuizNameError("Please enter a unique quiz name");
                    setIsSubmitting(false);
                }
            })
            .catch(err =>{
                console.log(err)
                toast.error(err);
            })
        }
    }

    const [quizStatuses, setQuizStatuses] = useState([]);
    const [quizTypes, setQuizTypes] = useState([]);
    const [quizTypeIndex, setQuizTypeIndex] = useState(0);
    const [gradingSystems, setGradingSystems] = useState([]);
    const [gradingSystemIndex, setGradingSystemIndex] = useState(0);
    const [quizName, setQuizName] = useState('');
    const [quizGameMode, setQuizGameMode] = useState('')
    const gameModeSelected = (e, id, val) => {
        console.log('VAL =',val)
        setQuizGameMode(val)
    }

    const changeQuizType = (e, id, item) => {
        e.preventDefault();
        console.log('QT', quizTypes)
        setQuizTypeIndex(item.index)
    }

    const getExistingQuizList = () => {
        console.log('Generate quiz list: ', props.genericQuizzes, props.campaignQuizzes);
        const quizList = [];
        if (props.genericQuizzes && props.genericQuizzes.length > 0) {
            props.genericQuizzes.map(
                (quiz) => {
                    quizList.push({
                        id: quiz._id,
                        label: quiz.name,
                        quiz: quiz,
                    });
                    return null;
                }
            )
        }
        if (props.campaignQuizzes && props.campaignQuizzes.length > 0) {
            props.campaignQuizzes.map(
                (quiz) => {
                    quizList.push({
                        id: quiz._id,
                        label: quiz.name,
                        quiz: quiz,
                    });
                    return null;
                }
            )
        }
        console.log('Quiz list: ', quizList);
        if (selectedExistingQuiz == null) {
            setSelectedExistingQuiz(quizList[0]);
        }
        return quizList;
    }

    return (
        <>
        <PopUpPanel closePanelFunc={props.closePanelFunc}>
            <div className="form-holder co-popup-content">
               
            <h2>{addNew ? 'Create New Quiz': 'Add Existing Quiz'}</h2> 
                    {props.showSelectExistingOption &&
                    <div className="fl-row grow">
                        <RadioButtonGroup
                            options={
                                [
                                    {label:"Add New", value: 'new'},
                                    {label:"Select Existing", value: 'existing'},
                                ]
                            }
                            selectedOption={addNew ? 'new' : 'existing'}
                            selectFunc={selectRadioOption}
                        />
                        <div className="grow"></div>
                    </div>
                    }
                    {addNew &&
                    <>
                    <form className="form" style={{maxWidth: "450px"}} >
                        <div className="s-row">
                            <div className="column-cell">
                        
                                { quizTypes && quizTypes.length>0 &&
                                    <FormDropDown
                                        label="Choose Quiz Type" 
                                        id={"quiz_type"}
                                        items={quizTypes}
                                        selectFunc={(e, id, item) => changeQuizType(e, id, item) }
                                        currentIndex={quizTypeIndex}
                                        icon="list"
                                    />
                                }
                            </div>
                        </div>
                        <div className="s-row">
                            <div className="column-cell">
                                {/* <div className="label">Name</div> */}

                            

                                <FormField 
                                    type="text"
                                    id="name" 
                                    showLabel={true} 
                                    label="Name" 
                                    placeholder={"Enter a quiz name"} 
                                    value={quizName} 
                                    setFunc={setQuizName} 
                                    error={quizNameError}
                                />
                            </div>
                        </div>
                        {/* <div className="s-row">
                            <div className="column-cell">
                                { gradingSystems && 
                                    <>
                                        <RadioButtonGroup 
                                            id="gradingSystem"
                                            label="Quiz Game Mode" 
                                            options={gradingSystems} 
                                            selectedOption={quizGameMode ? quizGameMode : ''} 
                                            selectFunc={gameModeSelected} 
                                        />
                                        <p><strong>Team Mode</strong> - Chat are working together to answer the questions<br />
                                        <strong>Vs Mode</strong> - Chat are playing against one another</p>
                                    </>
                                }
                            </div>
                        </div> */}
                    </form>
                    <button className={'submit-btn' + (isSubmitting ? ' button-inactive' : ' button-active')} onClick={isSubmitting ? null : createNewCampaignQuiz}>
                    {isSubmitting ? `Adding Quiz` : `Create New Campaign Quiz`}
                    </button>
                    </>
                    }





















                {!addNew &&
                    <>
                        <FormDropDown
                            label="Select Existing Quiz" 
                            id={"existing_quiz"}
                            items={getExistingQuizList()}
                            selectFunc={(e, id, item) => existingQuizSelected(e, id, item) }
                            currentIndex={selectedExistingQuiz ? selectedExistingQuiz.index : 0}
                        />

                        <button className={'submit-btn' + (isSubmitting ? ' button-inactive' : ' button-active')} onClick={isSubmitting ? null : addExistingQuiz}>
                            {isSubmitting ? `Adding Quiz` : `Add Exisitng Quiz`}
                        </button>
                    </>
                }
              
            </div>
        </PopUpPanel>
        </>
    )
}

export default AddQuiz;
