import BotsOverview from "../Campaigns/BotsOverview";
import { CampaignContext } from "../../contexts/CampaignContext";
import { useContext, useRef, useState } from "react";
import FeatureControls from "../../components/FeatureControls/FeatureControls";
import { IconJsxer } from "../../helpers/IconHelper";
import { assets } from "../../assets/assets";
import HeadedPanel from "../../components/HeadedPanel/HeadedPanel";
import Toggle from "../../components/forms/Toggle/Toggle";
import { FindParamArrayFromCustomisations } from "../../helpers/FeatureControlsHelper";
import { findFeatureChatCommands, findLinkedFeatureTriggers } from "../../helpers/CampaignsHelper";
import LeftColorBlockBox, { LEFT_COLOR_BLOCK_STYLES } from "../../components/LeftColorBlockBox/LeftColorBlockBox";
import LeftColorBlockHeadedPanel from "../../components/LeftColorBlockBox/LeftColorBlockHeadedPanel";
import { OneListedCommand } from "../Campaigns/FeaturePanel/FeaturePanel";

const TabDisruptors = (props) => {
    const [campaignContext, setCampaignContext] = useContext(CampaignContext);
    // const [currentDisruptor, setCurrentDisruptor] = useState(null);
    // const [previewMode, setPreviewMode] = useState(false);

    const selectDisruptor = (d) => {
        console.log('Select distruptor: ', d);
        props._setPreviewMode(false);
        props._setCurrentDisruptor(d);
    }

    const [expandedDisruptorTriggers, setExpandedDisruptorTriggers] = useState({});
    const setExpandedTriggerList = (id, expanded) => {
        setExpandedDisruptorTriggers((oldVal) => {
            const newVal = {...oldVal};
            newVal[id] = expanded;
            return (newVal)
        });
    }

    const [expandedChatCommands, setExpandedChatCommands] = useState({});
    const setExpandedChatCommandList = (id, expanded) => {
        setExpandedChatCommands((oldVal) => {
            const newVal = {...oldVal};
            newVal[id] = expanded;
            return (newVal)
        });
    }

    const [highlightedButtons, setHighlightedButtons] = useState([]);
    const highlightedButtons_ref = useRef([]);
    const highlightButton = (fId, command, buttonIndex) => {
        console.log('Hilight button: ', fId, command, buttonIndex);
        
        const newHighlightedButton = {group: fId, command: command, buttonIndex: buttonIndex};

        for (let i = 0; i < highlightedButtons_ref.current.length; i++) {
            if (highlightedButtons_ref.current[i].group === newHighlightedButton.group && highlightedButtons_ref.current[i].command === newHighlightedButton.command && highlightedButtons_ref.current[i].buttonIndex === newHighlightedButton.buttonIndex) {
                return;
            }
        }
        // We only get here if we didn't find a match
        const newHighlightedButtonsData = [...highlightedButtons_ref.current];
        newHighlightedButtonsData.push(newHighlightedButton);
        setHighlightedButtons(newHighlightedButtonsData);
        highlightedButtons_ref.current = newHighlightedButtonsData;
        setTimeout(() => {
            removeButtonHighlight(fId, command, buttonIndex);
        }, 300);
    }
    const removeButtonHighlight = (fId, command, buttonIndex) => {
        if (highlightedButtons_ref.current && highlightedButtons_ref.current.length > 0) {
            const newHighlightedButtonsData = highlightedButtons_ref.current.filter(
                (val, i, arr) => {
                    return !(val.group === fId && val.command === command && val.buttonIndex === buttonIndex);
                }
            )
            setHighlightedButtons(newHighlightedButtonsData);
            highlightedButtons_ref.current = newHighlightedButtonsData;
        }
    }
    const shouldHighlightButton = (fId, command, buttonIndex) => {
        for (let i = 0; i < highlightedButtons.length; i++) {
            if (highlightedButtons[i].group === fId && highlightedButtons[i].command === command && highlightedButtons[i].buttonIndex === buttonIndex) {
                return true;
            }
        }
        return false;
    }

    const getDisruptorPanel = (val, i, triggered = false, triggerDepth = 0) => {
        // console.log('Get disruptor panel for: ', val);
        let foundDynamicButtons = false;
        val.feature.defaultVars.forEach(varVal => {
            if (varVal.commandButtons && varVal.commandButtons.length) {
                varVal.commandButtons.forEach(cVal => {
                    if (cVal.mainControl) {
                        const controlsData = val.feature;
                        let buttonCommand = cVal.command;
                        let paramArray = null;
                        if (typeof buttonCommand === 'number') {
                            // We have a number as a command, so we need to look this one up!
                            if (buttonCommand >= 0 && controlsData.commands.length > buttonCommand) {
                                const command = controlsData.commands[buttonCommand]
                                buttonCommand = command.command;
                                if (typeof command.param === 'string' && Array.isArray(controlsData[command.param])) {
                                    // Kind of a legacy type so we can keep the multiple param buttons working while getting customisation up and running.
                                    // We have a param that references an array property in our feature, our label should show something relevant to the param
                                    paramArray = controlsData[command.param];
                                } else {
                                    if (typeof command.param === 'string') {
                                        // console.log('Finding param array for: ', command.param, props._customisationVars, val._id);
                                        paramArray = FindParamArrayFromCustomisations(props._customisationVars, val._id, command.param);
                                    }
                                }
                            }
                        }
                        if (paramArray && paramArray.length > 1) {
                            foundDynamicButtons = true;
                        }
                    }
                });
            }
        });

        let boxStyle = val.feature.featureType;
        if (LEFT_COLOR_BLOCK_STYLES[val.feature.featureType + '_' + val.feature.varKey]) {
            boxStyle = val.feature.featureType + '_' + val.feature.varKey;
        }
        if (LEFT_COLOR_BLOCK_STYLES['disruptor_' + val.feature.colourCategory]) {
            boxStyle = 'disruptor_' + val.feature.colourCategory;
        }
        if (LEFT_COLOR_BLOCK_STYLES['category_' + val.feature.colourCategory]) {
            boxStyle = 'category_' + val.feature.colourCategory;
        }
        const triggeredDisruptors = findLinkedFeatureTriggers(val._id, campaignContext.campaignData, props._customisationVars.current);
        const chatCommands = findFeatureChatCommands(val._id, campaignContext.campaignData);
        let hasChatCommands = false;
        if (chatCommands && chatCommands.length > 0) {
            for (let i = 0; i < chatCommands.length; i++) {
                if (chatCommands[i].hashtags && chatCommands[i].hashtags.length > 0) {
                    hasChatCommands = true;
                    break;
                }
            }
        }

        if ((!foundDynamicButtons && triggeredDisruptors.length === 0 && !hasChatCommands) || triggered) {
            return (
                <div className="fl-column compact" key={'disruptor_' + i}>
                    <div className="fl-row grow">
                        {triggerDepth > 0 &&
                            <div className="light-text triggered-feature-indent">
                                {/*IconJsxer.GetIcon(IconJsxer.ICONS.arrowCircleRight, IconJsxer.ICON_STYLES.analyticsColourBlockIcon)*/}
                            </div>
                        }
                        <LeftColorBlockBox style={boxStyle} overrideBgColor={props._currentDisruptor === val ? '#DDDDFF' : ''}>
                            <div className="fl-row list-view-content">

                                <div>
                                    {val.contentLabel || `Disruptor Name #${i + 1}`}

                                    <span className={`bot-time-dark`}>
                                        &nbsp;({val.feature.featureName})
                                    </span>
                                </div>

                                <div className="grow"></div>

                                {!triggered && val.feature.defaultVars.map(
                                    (varVal, varI, varArr) => {
                                        if (varVal.commandButtons && varVal.commandButtons.length) {
                                            return varVal.commandButtons.map(
                                                (cVal, cI, cArr) => {
                                                    if (cVal.mainControl) {
                                                        const controlsData = val.feature;
                                                        let buttonCommand = cVal.command;
                                                        let param = null;
                                                        let paramArray = null;
                                                        if (typeof buttonCommand === 'number') {
                                                            // We have a number as a command, so we need to look this one up!
                                                            if (buttonCommand >= 0 && controlsData.commands.length > buttonCommand) {
                                                                const command = controlsData.commands[buttonCommand]
                                                                buttonCommand = command.command;
                                                                if (typeof command.param === 'string' && Array.isArray(controlsData[command.param])) {
                                                                    // We have a param that references an array property in our feature, our label should show something relevant to the param
                                                                    paramArray = controlsData[command.param];
                                                                    param = 0;
                                                                } else {
                                                                    if (typeof command.param === 'string') {
                                                                        // console.log('Finding param array for: ', command.param, props._customisationVars, val._id);
                                                                        paramArray = FindParamArrayFromCustomisations(props._customisationVars, val._id, command.param);
                                                                    }
                                                                    if (paramArray === null) {
                                                                        param = command.param;
                                                                    } else {
                                                                        if (paramArray.length <= 1) {
                                                                            param = 0;
                                                                            paramArray = null;
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }

                                                        let buttonColour = '';
                                                        if (cVal.colour) {
                                                            buttonColour = cVal.colour;
                                                        }
                                                        if (buttonColour === '') {
                                                            // Based on the command, we can set a default colour. isPrimaryTrigger = green and isEndTrigger = red
                                                            for (let i = 0; i < controlsData.commands.length; i++) {
                                                                if (controlsData.commands[i].command === buttonCommand) {
                                                                    if (controlsData.commands[i].isPrimaryTrigger) {
                                                                        buttonColour = 'green';
                                                                    }
                                                                    if (controlsData.commands[i].isEndTrigger) {
                                                                        buttonColour = 'red';
                                                                    }
                                                                }
                                                                if (buttonColour !== '') {
                                                                    break;
                                                                }
                                                            }
                                                        }

                                                        if (paramArray === null || paramArray.length === 0) {
                                                            return (
                                                                <div className={`edit-icon live-qbut-${buttonColour}${shouldHighlightButton(val._id, buttonCommand, cI) ? '-light' : ''}`} onClick={(e) => {highlightButton(val._id, buttonCommand, cI); props._sendFeatureCommandFunc(e, buttonCommand, val._id, param)}} key={'control_' + cI}>
                                                                    {IconJsxer.ICONS[cVal.icon] &&
                                                                        IconJsxer.GetIcon(IconJsxer.ICONS[cVal.icon], IconJsxer.ICON_STYLES.campaignPanelTop)
                                                                    }
                                                                    {!IconJsxer.ICONS[cVal.icon] && assets[cVal.icon] &&
                                                                        <img src={assets[cVal.icon]} style={IconJsxer.ICON_STYLES.campaignPanelTop} alt="" />
                                                                    }
                                                                </div>
                                                            )
                                                        }

                                                        return null;
                                                    } else {
                                                        return null;
                                                    }
                                                }
                                            )
                                        }
                                        return null;
                                    }
                                )}

                                <div className="edit-icon" onClick={(e) => selectDisruptor(val)}>
                                    {IconJsxer.GetIcon(IconJsxer.ICONS.controls, IconJsxer.ICON_STYLES.campaignPanelTop)}
                                </div>
                            </div>
                        </LeftColorBlockBox>
                    </div>

                    {triggeredDisruptors.length > 0 && triggerDepth === 0 &&
                        <>
                            <div className="fl-column compact">
                                {triggeredDisruptors.map(
                                    (val_tf, i_tf, arr_tf) => getDisruptorPanel(val_tf, i_tf, true, 1)
                                )}
                            </div>
                        </>
                    }

                </div>
            )
        }

        return (
            <LeftColorBlockHeadedPanel style={boxStyle} overrideBgColor={props._currentDisruptor === val ? '#DDDDEE' : ''} key={'disruptor_' + i}
                headingContent={
                    <div className="fl-row list-view-content">

                        <div>
                            {val.contentLabel || `Disruptor Name #${i + 1}`}

                            <span className={`bot-time-dark`}>
                                &nbsp;({val.feature.featureType !== 'feature' ? val.feature.featureName : 'Main Feature'})
                            </span>
                        </div>

                        <div className="grow"></div>

                        {val.feature.featureType !== 'feature' && val.feature.defaultVars.map(
                            (varVal, varI, varArr) => {
                                if (varVal.commandButtons && varVal.commandButtons.length) {
                                    return varVal.commandButtons.map(
                                        (cVal, cI, cArr) => {
                                            if (cVal.mainControl) {
                                                const controlsData = val.feature;
                                                let buttonCommand = cVal.command;
                                                let param = null;
                                                let paramArray = null;
                                                if (typeof buttonCommand === 'number') {
                                                    // We have a number as a command, so we need to look this one up!
                                                    if (buttonCommand >= 0 && controlsData.commands.length > buttonCommand) {
                                                        const command = controlsData.commands[buttonCommand]
                                                        buttonCommand = command.command;
                                                        if (typeof command.param === 'string' && Array.isArray(controlsData[command.param])) {
                                                            // We have a param that references an array property in our feature, our label should show something relevant to the param
                                                            paramArray = controlsData[command.param];
                                                            param = 0;
                                                        } else {
                                                            if (typeof command.param === 'string') {
                                                                // console.log('Finding param array for: ', command.param, props._customisationVars, val._id);
                                                                paramArray = FindParamArrayFromCustomisations(props._customisationVars, val._id, command.param);
                                                            }
                                                            if (paramArray === null) {
                                                                param = command.param;
                                                            } else {
                                                                if (paramArray.length <= 1) {
                                                                    param = 0;
                                                                    paramArray = null;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }

                                                let buttonColour = '';
                                                if (cVal.colour) {
                                                    buttonColour = cVal.colour;
                                                }
                                                if (buttonColour === '') {
                                                    // Based on the command, we can set a default colour. isPrimaryTrigger = green and isEndTrigger = red
                                                    for (let i = 0; i < controlsData.commands.length; i++) {
                                                        if (controlsData.commands[i].command === buttonCommand) {
                                                            if (controlsData.commands[i].isPrimaryTrigger) {
                                                                buttonColour = 'green';
                                                            }
                                                            if (controlsData.commands[i].isEndTrigger) {
                                                                buttonColour = 'red';
                                                            }
                                                        }
                                                        if (buttonColour !== '') {
                                                            break;
                                                        }
                                                    }
                                                }

                                                if (paramArray === null || paramArray.length === 0) {
                                                    return (
                                                        <div className={`edit-icon live-qbut-${buttonColour}${shouldHighlightButton(val._id, buttonCommand, cI) ? '-light' : ''}`} onClick={(e) => {highlightButton(val._id, buttonCommand, cI); props._sendFeatureCommandFunc(e, buttonCommand, val._id, param)}} key={'control_' + cI}>
                                                            {IconJsxer.ICONS[cVal.icon] &&
                                                                IconJsxer.GetIcon(IconJsxer.ICONS[cVal.icon], IconJsxer.ICON_STYLES.campaignPanelTop)
                                                            }
                                                            {!IconJsxer.ICONS[cVal.icon] && assets[cVal.icon] &&
                                                                <img src={assets[cVal.icon]} style={IconJsxer.ICON_STYLES.campaignPanelTop} alt="" />
                                                            }
                                                        </div>
                                                    )
                                                }

                                                foundDynamicButtons = true;
                                                return null;
                                            } else {
                                                return null;
                                            }
                                        }
                                    )
                                }
                                return null;
                            }
                        )}

                        {val.feature.featureType !== 'feature' &&
                            <div className="edit-icon" onClick={(e) => selectDisruptor(val)}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.controls, IconJsxer.ICON_STYLES.campaignPanelTop)}
                            </div>
                        }
                    </div>
                }>

                    {foundDynamicButtons &&
                        <>

                            {val.feature.defaultVars.map(
                                (varVal, varI, varArr) => {
                                    // console.log('Sorting out buttons: ', val);
                                    if (varVal.commandButtons && varVal.commandButtons.length) {
                                        return varVal.commandButtons.map(
                                            (cVal, cI, cArr) => {
                                                if (cVal.mainControl) {
                                                    const controlsData = val.feature;
                                                    let buttonLabel = cVal.label;
                                                    if (IconJsxer.ICONS[cVal.icon]) {
                                                        buttonLabel = IconJsxer.GetIcon(IconJsxer.ICONS[cVal.icon], IconJsxer.ICON_STYLES.campaignPanelTop)
                                                    } else
                                                        if (assets[cVal.icon]) {
                                                            buttonLabel = <img src={assets[cVal.icon]} style={IconJsxer.ICON_STYLES.campaignPanelTop} alt="" />
                                                        }
                                                    let buttonCommand = cVal.command;
                                                    let param = null;
                                                    let paramArray = null;
                                                    if (typeof buttonCommand === 'number') {
                                                        // We have a number as a command, so we need to look this one up!
                                                        if (buttonCommand >= 0 && controlsData.commands.length > buttonCommand) {
                                                            const command = controlsData.commands[buttonCommand]
                                                            buttonCommand = command.command;
                                                            if (typeof command.param === 'string' && Array.isArray(controlsData[command.param])) {
                                                                // Kind of a legacy type so we can keep the multiple param buttons working while getting customisation up and running.
                                                                // We have a param that references an array property in our feature, our label should show something relevant to the param
                                                                paramArray = controlsData[command.param];
                                                                param = 0;
                                                            } else {
                                                                if (typeof command.param === 'string') {
                                                                    // console.log('Finding param array for: ', command.param, props._customisationVars, val._id);
                                                                    paramArray = FindParamArrayFromCustomisations(props._customisationVars, val._id, command.param);
                                                                }
                                                                if (paramArray === null) {
                                                                    param = command.param;
                                                                }
                                                            }
                                                        }
                                                    }

                                                    let buttonColour = '';
                                                    if (cVal.colour) {
                                                        buttonColour = cVal.colour;
                                                    }
                                                    if (buttonColour === '') {
                                                        // Based on the command, we can set a default colour. isPrimaryTrigger = green and isEndTrigger = red
                                                        for (let i = 0; i < controlsData.commands.length; i++) {
                                                            if (controlsData.commands[i].command === buttonCommand) {
                                                                if (controlsData.commands[i].isPrimaryTrigger) {
                                                                    buttonColour = 'green';
                                                                }
                                                                if (controlsData.commands[i].isEndTrigger) {
                                                                    buttonColour = 'red';
                                                                }
                                                            }
                                                            if (buttonColour !== '') {
                                                                break;
                                                            }
                                                        }
                                                    }

                                                    if (paramArray && paramArray.length > 0) {
                                                        return (
                                                            <div className="feature-controls-holder" style={{ marginBottom: 0, paddingBottom: 0 }} key={'bgrp_' + cI}>
                                                                <div className="button-grid" style={{ marginBottom: 0 }}>
                                                                    {paramArray.map(
                                                                        (pVal, pI, pArr) => {
                                                                            return (
                                                                                <button key={'btn_' + pI} className={`standard-button live-but-${buttonColour}`} onClick={(e) => {highlightButton(val._id, buttonCommand, pI); props._sendFeatureCommandFunc(e, buttonCommand, val._id, pI)}}>
                                                                                    {typeof buttonLabel !== 'object' &&
                                                                                        <>
                                                                                            {buttonLabel} {(pVal.label || pVal.name || pVal.fileName || pVal.filename)}
                                                                                        </>
                                                                                    }
                                                                                    {typeof buttonLabel === 'object' &&
                                                                                        <div className="fl-row">
                                                                                            {buttonLabel}
                                                                                            <div>
                                                                                                {(pVal.label || pVal.name || pVal.fileName || pVal.filename)}
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                    {shouldHighlightButton(val._id, buttonCommand, pI) &&
                                                                                        <>
                                                                                            <div className="live-but-highlighted-overlay"></div>
                                                                                            <img className="live-but-spinner" src={assets.ButtonSpinner} alt="" />
                                                                                        </>
                                                                                    }
                                                                                </button>
                                                                            )
                                                                        }
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )
                                                    }

                                                    foundDynamicButtons = true;
                                                    return null;
                                                } else {
                                                    return null;
                                                }
                                            }
                                        )
                                    }
                                    return null;
                                }
                            )}

                        </>
                    }

                    {triggeredDisruptors.length > 0 &&
                        <>
                            <div className="fl-row">
                                <h5 className="tight">Triggered Disruptors</h5>
                                <div className="grow"></div>
                                <div className="fl-row compact cursor-pointer" onClick={(e) => setExpandedTriggerList(val._id, expandedDisruptorTriggers[val._id] ? false : true)}>
                                    <div>{expandedDisruptorTriggers[val._id] ? 'Hide' : 'Show'}</div>
                                    {
                                        IconJsxer.GetIcon(expandedDisruptorTriggers[val._id] ? IconJsxer.ICONS.chevronUp : IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.standard)
                                    }
                                </div>
                            </div>
                            <hr className="tight-hr"></hr>
                            <div className={`fl-column compact disruptor-triggers-list${expandedDisruptorTriggers[val._id] ? ' dtl-expanded' : ''}`}>
                                {triggeredDisruptors.map(
                                    (val_tf, i_tf, arr_tf) => getDisruptorPanel(val_tf, i_tf, true, 0)
                                )}
                            </div>
                        </>
                    }

                    {hasChatCommands &&
                        <>
                            {(triggeredDisruptors.length > 0 || foundDynamicButtons) &&
                                <div className="spacer"></div>
                            }
                            <div className="fl-row">
                                <h5 className="tight">Chat Commands</h5>
                                <div className="grow"></div>
                                <div className="shrink">
                                    <Toggle id={'chatCommands_' + val._id} currentState={props._liveStreamServerState?.disabledChatCommandFeatureIds?.indexOf(val._id) === -1 ?? false} toggleFunc={(e) => {props._toggleChatCommandEnabled(val._id)}} enabled={props._chatConnected}></Toggle>
                                </div>
                                <div className="fl-row compact cursor-pointer" onClick={(e) => setExpandedChatCommandList(val._id, expandedChatCommands[val._id] ? false : true)}>
                                    <div>{expandedChatCommands[val._id] ? 'Hide' : 'Show'}</div>
                                    {
                                        IconJsxer.GetIcon(expandedChatCommands[val._id] ? IconJsxer.ICONS.chevronUp : IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.standard)
                                    }
                                </div>
                            </div>
                            <hr className="tight-hr"></hr>
                            <div className={`fl-column compact disruptor-triggers-list${expandedChatCommands[val._id] ? ' dtl-expanded' : ''}`}>
                                    <div className="feature-panel">
                                        <div className="inner-panel">
                                            <div className="scroll-container add-margin">
                                                <div className="grid less-detail">
                                                    <div className="header label fl-row">Control</div>
                                                    <div className="header label fl-row">Chat</div>
                                                    <div className="header label fl-row">Cooldown</div>
                                                    {/* <hr></hr> */}
                                                    {chatCommands.map(
                                                        (val_c, i_c, arr_c) => {
                                                            // console.log('Listing commands: ', val_c);
                                                            return (
                                                                <OneListedCommand
                                                                    key={'olc_' + i_c}
                                                                    _val={val_c}
                                                                    _featureData={val}
                                                                    _editMode={false}
                                                                    _removeHashtag={null}
                                                                    _campaignId={props._campaignId}
                                                                    _customisationData={props._customisationVarsRef}
                                                                    _gotLiveStream={true}
                                                                    _reducedDetail={true}
                                                                />
                                                            )
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </>
                    }

            </LeftColorBlockHeadedPanel>
        )
            
    }

    let themeable = false;
    if (props._currentDisruptor) {
        // Check tabs in featureData for any themeable tabs
        const featureData = props._currentDisruptor;
        for (let i = 0; i < featureData.feature.customisation.length; i++) {
            if (featureData.feature.customisation[i].themeable) {
                themeable = true;
                break;
            }
        }
    }

    let gotFeature = false;
    let gotChainedDisruptors = false;

    return (
        <div className="split-content">
            <div className="shrink-column">
            
                <div className="underlined-panel-heading">
                    <h4>Stream Disruptors</h4>
                    <div className="grow"></div>
                </div>

                <LeftColorBlockBox style={props._previewMode ? 'preview_mode' : props._currentDisruptor ? 'live_mode' : 'disabled_mode'}>
                    <div className="fl-row grow">
                        <div className="live-preview-toggle-content">
                            <h5 className={props._previewMode ? 'preview' : props._currentDisruptor ? 'live' : 'disabled'}>{props._previewMode ? 'Preview Mode' : props._currentDisruptor ? 'Live Mode' : 'Nothing Selected'}</h5>
                            <div className="grow">
                                {!props._currentDisruptor &&
                                    <>Please select a Feature or Disruptor to view its controls.</>
                                }
                                {!props._previewMode && props._currentDisruptor &&
                                    <>You are running in live mode. Commands will be sent to all active extension pages.</>
                                }
                                {props._previewMode &&
                                    <>Commands will be sent to the preview window. Note: settings changes will still be sent to active extension pages.</>
                                }
                            </div>
                        </div>
                        <div>
                            {props._currentDisruptor &&
                                <Toggle id="previewMode" currentState={!props._previewMode} toggleFunc={() => props._setPreviewMode(!props._previewMode, props._currentDisruptor, '(Disruptor)')} enabled={props._currentDisruptor !== null} overrideBg={{ on: '#FFF' }}></Toggle>
                            }
                        </div>
                    </div>
                </LeftColorBlockBox>

                <div className="scroll-area _42">
                    <div className="fl-column compact">
                        {props._features.map(
                            (val, i, arr) => {
                                if (val.feature.featureType === 'feature') {
                                    gotFeature = true;
                                }
                                if (val.isChained) {
                                    gotChainedDisruptors = true;
                                }
                                if (val.feature.featureType === 'disruptor' && !val.isChained) {
                                    return (
                                        <div key={'d_' + i}>
                                            {getDisruptorPanel(val, i)}
                                        </div>
                                    )
                                } else {
                                    return null;
                                }
                            }
                        )}
                        {gotFeature &&
                            <>
                                <div className="spacer"></div>
                                <h5 className="light-text">Main Feature</h5>
                            </>
                        }
                        { // Now display any features with triggered disruptors (switch to just show them anyway so we can have chat commands displayed)
                        props._features.map(
                            (val, i, arr) => {
                                // const triggeredDisruptors = findLinkedFeatureTriggers(val._id, campaignContext.campaignData, props._customisationVars.current);
                                if (val.feature.featureType === 'feature' /* && triggeredDisruptors.length */) {
                                    return (
                                        <div key={'f_' + i}>
                                            {getDisruptorPanel(val, i)}
                                        </div>
                                    )
                                } else {
                                    return null;
                                }
                            }
                        )}
                        {gotChainedDisruptors &&
                            <>
                                <div className="spacer"></div>
                                <h5 className="light-text">Chained Disruptors</h5>
                            </>
                        }
                        {props._features.map(
                            (val, i, arr) => {
                                if (val.feature.featureType === 'disruptor' && val.isChained) {
                                    return (
                                        <div key={'d_' + i}>
                                            {getDisruptorPanel(val, i)}
                                        </div>
                                    )
                                } else {
                                    return null;
                                }
                            }
                        )}
                    </div>
                </div>
            </div>
            <div className="column no-panel-shadow">
                <div className="underlined-panel-heading">
                    <h4>Disruptor Control</h4>
                    <div className="grow"></div>
                </div>
                <div className="ms-full-scroll-area">
                    {props._currentDisruptor &&
                        <div className="fl-column">
                            <FeatureControls
                                feature={props._currentDisruptor}
                                streamVars={props._featureVars.current}
                                featureId={props._currentDisruptor._id}
                                campaignId={props._campaignId}
                                streamId={props._streamId}
                                customisationVars={props._customisationVars}
                                reportFeatureVarsChangedFunc={props._reportFeatureVarsChanged}
                                reportCustomisationVarsChanged={props._reportCustomisationVarsChanged}
                                saveCustomisationVarsFunc={props._saveCustomisationVarsFunc}
                                sendFeatureCommandFunc={props._sendFeatureCommandFunc}
                                showTooltipFunc={props._showTooltip}
                                hideTooltipFunc={props._hideTooltip}
                                label={props._currentDisruptor.contentLabel}
                                quizData={props._allQuizData ? (props._allQuizData[props._currentDisruptor._id] || null) : null}
                                startCollapsed={false}
                                forceCollapsed={false}
                                chatConnected={props._chatConnected}
                                featureReady={props._featureReady}
                                isThemeable={themeable}
                                themeData={props._themeData}
                                applyThemeFunc={props._applyThemeFunc}
                                openRestrictedCustomisation={props.openRestrictedCustomisation}
                                channels={props.channels}
                            />
                        </div>
                    }
                </div>
            </div>

        </div>
    )
}

export default TabDisruptors
