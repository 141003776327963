import React, { useState, useEffect, useContext } from "react";

import axios from "../../api/axios";

import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import { CampaignContext } from "../../contexts/CampaignContext";
import { userListAllUsersRoute } from "../../api/routes";
import ListedCampaignUser from "../CreateCampaign/ListedCampaignUser";
import { PRIMERS, filterList } from "../../helpers/ListFunctions";

const EditCampaignOwner = (props) => {
    const [campaignContext, setCampaignContext] = useContext(CampaignContext);
    const [newCampaignOwner, setNewCampaignOwner] = useState(props.owner)

    const [allUserData, setAllUserData] = useState([]);
    const [filteredUserData, setFilteredUserData] = useState([]);
    // Filter our user list to only allow us to choose from certain types - the actual filter is passed in as a prop
    const createFilteredLists = (allUsersList) => {
        const userList = filterList(allUsersList, 'acType', props.acTypeFilter, PRIMERS.alphaTypePrimer);
        setFilteredUserData(userList);
    }

    const fetchUserList = () => {
        axios
            .get(userListAllUsersRoute, { withCredentials: true })
            .then((response) => {
                const userData = response.data.allusers;

                for (let i = 0; i < userData.length; i++) {
                    // Auto select the current or new owner in our list
                    if (userData[i]._id === props.newOwner._id) {
                        userData[i].selected = true;
                    } else {
                        userData[i].selected = false;
                    }

                }

                setAllUserData(userData);
                createFilteredLists(userData);
            })
            .catch((error) => {
                //
                //toast.error(error.response.data.msg);
            });
    }

    const toggleUserSelected = (e, user) => {
        // When we select a new owner, we need to make sure it is the only one in the list that is selected
        for (let i = 0; i < allUserData.length; i++) {
            if (allUserData[i]._id === user._id) {
                allUserData[i].selected = true;
            } else {
                allUserData[i].selected = false;
            }

            setNewCampaignOwner(user);
            createFilteredLists(allUserData);
        }
    }

    const changeOwner = (e) => {
        e.preventDefault();

        if (props.changeCampaignOwnerFunc) {
            props.changeCampaignOwnerFunc(e, newCampaignOwner);
        }
        if (props.closePanelFunc) {
            props.closePanelFunc();
        }

        // No axios needed as the new owner won't actually change until the hit Save (unlike bots)
    }

    useEffect(() => {
        fetchUserList();
    }, []);

    return (
        <PopUpPanel closePanelFunc={props.closePanelFunc}>
            <div className="form-holder co-popup-content">
                <form id="addstreambot" className="form">
                    <h2>Change Campaign Owner</h2>

                    <div className="form-field-content label">
                        Current Owner
                    </div>

                    <div className="drop-gap">
                        <ListedCampaignUser user={campaignContext.campaignData.campaignOwnerAccount} showCheck={false} />
                    </div>

                    <div className="form-field-content label">
                        Select New Owner
                    </div>

                    <div className="scroll-container drop-gap">
                        {filteredUserData.map(
                            (val, i) => {
                                return (
                                    <ListedCampaignUser user={val} selected={val.selected} toggleSelectedFunc={toggleUserSelected} showCheck={true} uncheckedStyle="x" key={'co_' + i} />
                                )
                            }
                        )}
                    </div>

                    <button className='submit-btn button-active' onClick={changeOwner}>
                        Change Owner
                    </button>
                </form>
            </div>
        </PopUpPanel>
    )
}

export default EditCampaignOwner;
