import React, { useState, useContext } from "react";

import { toast } from "react-toastify";
import axios from "../../api/axios";

import { AuthContext } from "../../contexts/AuthContext";

import { changeBotStatusRoute } from "../../api/routes";
import "./EditDisplayName.scss";

import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";

const ChangeBotStatusPopup = (props) => {
    const [authContext, setAuthContext] = useContext(AuthContext);

    const [botsButtonActive, setBotsButtonActive] = useState(true);
      const toggleBotAuthorisation = (e) => {
        e.preventDefault();
        setBotsButtonActive(false);
        axios
        .post(changeBotStatusRoute, {withCredentials: true, userID: authContext.userData._id})
        .then((response) => {
            setBotsButtonActive(true);
            toast.success('Bot authorisation changed');
            setAuthContext(
                (oldVals) => {
                    const oldBotVal = oldVals.userData.isBotAuthorised;
                    const userData = {...oldVals.userData};
                    userData.isBotAuthorised = !oldBotVal;
                    const updatedContextData = {...oldVals};
                    updatedContextData.userData = userData;
                    return updatedContextData;
                }
            );
            props.closePanelFunc();
        })
        .catch((error) => {
            setBotsButtonActive(true);
            toast.error(error.response.data.msg);
        });
    }

    return (
        <PopUpPanel closePanelFunc={props.closePanelFunc}>
            <div className="form-holder">
                <h2>Bots Currently Not Authorised</h2>
                <form id="changeDisplayName" className="form-holder">
                    <p>Bots are currently blocked from posting on your channel!</p>
                    <p>Your Twitch contact may use bots to post useful information to your channel during your sponsored stream. This could include instructions for your viewers and maybe even gifts, prize draws and more.</p>
                    <p>Would you like to Authroise Bot Posts now?</p>
                    <p>This setting can be changed at any time from your Profile page.</p>
                    <button className={`standard-button${botsButtonActive ? '' : ' button-inactive'}`} onClick={botsButtonActive ? toggleBotAuthorisation : null}>
                        Authorise Now
                    </button>
                </form>
            </div>
        </PopUpPanel>
    )
}

export default ChangeBotStatusPopup;
