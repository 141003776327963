import { assets } from "../../assets/assets";
import ProfilePic, { PROFILE_PIC_SIZES } from "../../components/ProfilePic/ProfilePic";
import Toggle from "../../components/forms/Toggle/Toggle";
import ToggleCheck from "../../components/forms/ToggleCheck/ToggleCheck";
import { ACCOUNT_TYPES, getAcTypeData } from "../../helpers/AccountTypes";
import { getShortDateString } from "../../helpers/Dates";
import { IconJsxer } from "../../helpers/IconHelper";
import { ProfilePicURL } from "../../helpers/ProfilePicURL";

const ListedUser = (props) => {
    const { user, maxChangeLevel, maxDeauthLevel, toggleAuthorisedFunc, editUserFunc, openUserCampaignsFunc, changeUserTypeFunc } = props;
    const acTypeData = getAcTypeData(user.acType);

    const openUserCampaigns = (e) => {
        e.preventDefault();
        openUserCampaignsFunc(e, user._id, user);
    }

    const changeUserType = (e) => {
        e.preventDefault();
        if (changeUserTypeFunc) {
            changeUserTypeFunc(e, user._id, user);
        }
    }

    return (
        <>
            <ProfilePic size={PROFILE_PIC_SIZES.medium} className="pp-style" user={user} />
            <div>
                <span className="email">{user.email}</span><br />
                <span className="small-text light-text">{user._id}</span>
            </div>
            <div>{user.firstName} {user.lastName} {user.isTwitch && <>(<a href={'https://www.twitch.tv/'+user.name} target='_blank'>{user.name}</a>)</>}</div>
            <div className="centered">
                <div className={`ac-pill ${acTypeData.colorCss}${changeUserTypeFunc ? ' button-active' : ''}`} onClick={changeUserTypeFunc ? changeUserType : null}>
                    <p>{acTypeData.shortName}</p>
                    {acTypeData.icon &&
                        <>
                            {IconJsxer.GetIcon(acTypeData.icon, IconJsxer.ICON_STYLES.acPillIcon, 'icon')}
                        </>
                    }
                    {!acTypeData.icon && acTypeData.useTwitchIcon &&
                        <img src={assets.TwitchLogoSmallWhite} className="tw-icon" alt="" />
                    }
                </div>
            </div>
            <div className="campaigns centered">
                <a href="" onClick={openUserCampaigns}>
                    {user.campaigns}
                </a>
            </div>
            <div className="centered">
                <Toggle id={user._id} currentState={user.authorised} toggleFunc={toggleAuthorisedFunc} enabled={maxDeauthLevel >= acTypeData.level} />
            </div>
            <div className="centered">
                {getShortDateString(user.dateCreated)}
            </div>
            <div className="centered">
                {IconJsxer.GetIcon(IconJsxer.ICONS.moreInfo, IconJsxer.ICON_STYLES.listIcon, 'icons', (event) => editUserFunc(event, user._id, user))}
            </div>
        </>
    );
}

export default ListedUser;
