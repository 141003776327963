import { useState } from "react";
import PropTypes from 'prop-types';
import DebugLogger from "../../../helpers/DebugLogger";

import "./CheckBox.scss";

import { IconJsxer } from "../../../helpers/IconHelper";

const CheckBox = (props) => {
    const { label, id, initialState, setFunc, error, unClickable } = props;

    if (label === DEFAULT_LABEL) {
        DebugLogger.warn('No label provided to Check Box, using default...');
    }
    if (setFunc === null) {
        DebugLogger.warn('You should provide a function called `setFunc(fieldVal[, id])` to CheckBox.');
    }
console.log('LOAD CHECK', initialState)
    const [checked, setChecked] = useState(initialState === 'checked' || initialState == true);
    console.log('LOAD CHECK1', checked)
    if(initialState != checked) setChecked(initialState)
    const toggleCheck = (e) => {
        let newValue = !checked;
        setChecked(!checked);
        if (setFunc) {
            setFunc(newValue, id);
        }
    }

    return (
        <div className="check-box-content">
            <div onClick={unClickable ? null : toggleCheck} className={`check-box-holder${unClickable ? ' unclickable' : ''}`}>
                <div className="check-box-outer">
                    <div className={`check-box ${checked ? 'checked' : 'unchecked'}`}>
                        {IconJsxer.GetIcon(IconJsxer.ICONS.check, IconJsxer.ICON_STYLES.roundFormControl)}
                    </div>
                </div>
                <div className="label">
                    {label}
                </div>
            </div>

            {error !== '' &&
                <div className="error">
                    {error}
                </div>
            }
        </div>
    )
}

const DEFAULT_LABEL = 'Check Box';

CheckBox.propTypes = {
    label: PropTypes.string.isRequired,
    id: PropTypes.string,
    initialState: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    error: PropTypes.string,
    setFunc: PropTypes.func,
    unClickable: PropTypes.bool,
};

CheckBox.defaultProps = {
    label: DEFAULT_LABEL,
    id: '',
    initialState: false,
    error: '',
    setFunc: null,
    unClickable: false,
}

export default CheckBox;
