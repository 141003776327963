import React from "react";
import "./StreamfogTest.scss";

const StreamfogTest = () => {
    return (
        <div className="content-page-container">
            <div className="content-page-top">
                <div className="fl-column grow">
                    <div className="fl-row grow">
                        <div>
                            <h1>Streamfog Test</h1>
                        </div>
                    </div>
                </div>
            </div>


            <div className="content-page-content-container">
                <div className="content-page-main-content">

                    <div className="streamfog-test-page-panel">

                        <iframe src="https://www.app.streamfog.com/ar-studio" title="Streamfog Test"></iframe>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default StreamfogTest;
