import { useState, useRef, useEffect } from "react";
import PopUpPanel from "../../../components/PopUpPanel/PopUpPanel";
import "./Themes.scss";
import Toggle from "../../../components/forms/Toggle/Toggle";
import TabbedPanel from "../../../components/TabbedPanel/TabbedPanel";
import CustomisationControls from "../../../components/CustomisationControls/CustomisationControls";
import { getFeatureAssetBaseUrl } from "../../../helpers/FeatureAssets";
import { CUSTOMISED_URL_MAP } from "../../../config/FeatureDashboardVarMap";
import { IconJsxer } from "../../../helpers/IconHelper";

const EditThemePopup = (props) => {
    const [advancedMode, setAdvancedMode] = useState(false);
    const [currentTab, setCurrentTab] = useState();

    const [themeUpdateCount, setThemeUpdateCount] = useState(0);
    const addValueToTheme = (tabKey, familyKey, varKey, varIndex, schemaKey, value, varSchema) => {
        console.log('Adding value to theme: ', tabKey, familyKey, varKey, varIndex, schemaKey, value, varSchema, props.themeData);

        if (schemaKey === 'ADD_DEFAULTS') {
            if (varSchema) {
                for (let prop in varSchema) {
                    addValueToTheme(tabKey, familyKey, varKey, varIndex, prop, varSchema[prop].default, varSchema[prop]);
                }
            }
            return;
        }

        if (!props.themeData?.themeData) {
            props.themeData.themeData = {};
        }
        const newThemeData = props.themeData.themeData;
        if (!newThemeData[tabKey]) {
            newThemeData[tabKey] = {};
        }
        if (!newThemeData[tabKey][familyKey]) {
            newThemeData[tabKey][familyKey] = {};
        }
        if (!newThemeData[tabKey][familyKey][varKey]) {
            newThemeData[tabKey][familyKey][varKey] = [];
        }
        while (newThemeData[tabKey][familyKey][varKey].length <= varIndex) {
            newThemeData[tabKey][familyKey][varKey].push({});
        }
        if (!newThemeData[tabKey][familyKey][varKey][varIndex]) {
            newThemeData[tabKey][familyKey][varKey][varIndex] = {};
        }
        if (schemaKey && value !== null && value !== undefined) {
            if (varSchema) {
                // Handle files!
                switch (varSchema.type.toLowerCase()) {
                    case 'video':
                    case 'image':
                    case 'audio':
                        // File upload types!
                        if (typeof newThemeData[tabKey][familyKey][varKey][varIndex][schemaKey] === 'undefined') {
                            newThemeData[tabKey][familyKey][varKey][varIndex][schemaKey] = {};
                        }
                        if (typeof value === 'string') {
                            // A string for a file is treated as a default file
                            newThemeData[tabKey][familyKey][varKey][varIndex][schemaKey] = {filename: value};
                            newThemeData[tabKey][familyKey][varKey][varIndex][schemaKey].srcUrl = getFeatureAssetBaseUrl(true) + props.featureData.feature.defaultAssetFolder + '/' + CUSTOMISED_URL_MAP[varSchema.type.toLowerCase()].dir + '/' + value;
                            newThemeData[tabKey][familyKey][varKey][varIndex][schemaKey].isDefault = true;
                            newThemeData[tabKey][familyKey][varKey][varIndex][schemaKey].updated = false;
                            newThemeData[tabKey][familyKey][varKey][varIndex][schemaKey].type = varSchema.type.toLowerCase();
                        } else {
                            const fileNameSplit = value.name.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, "").split('.');
                            fileNameSplit[0] += '__' + Date.now();
                            newThemeData[tabKey][familyKey][varKey][varIndex][schemaKey].filename = fileNameSplit.join('.');
                            value.uniqueFilename = fileNameSplit.join('.');
                            newThemeData[tabKey][familyKey][varKey][varIndex][schemaKey].newFile = value;
                            newThemeData[tabKey][familyKey][varKey][varIndex][schemaKey].srcUrl = value ? URL.createObjectURL(value) : null;
                            newThemeData[tabKey][familyKey][varKey][varIndex][schemaKey].isDefault = false;
                            newThemeData[tabKey][familyKey][varKey][varIndex][schemaKey].updated = true;
                            newThemeData[tabKey][familyKey][varKey][varIndex][schemaKey].type = varSchema.type.toLowerCase();
                        }
                        break;
                    default:
                        newThemeData[tabKey][familyKey][varKey][varIndex][schemaKey] = value;
                        break;
                }
            } else {
                newThemeData[tabKey][familyKey][varKey][varIndex][schemaKey] = JSON.parse(JSON.stringify(value));
                if (newThemeData[tabKey][familyKey][varKey][varIndex][schemaKey].originalFile) {
                    newThemeData[tabKey][familyKey][varKey][varIndex][schemaKey].originalFile = null;
                    delete newThemeData[tabKey][familyKey][varKey][varIndex][schemaKey].originalFile;
                }
            }
        }
        setThemeUpdateCount((oldVal) => oldVal + 1);

        console.log('Theme data updated (val added): ', props.themeData);
    }
    const removeThemeValue = (tabKey, familyKey, varKey, varIndex, schemaKey) => {
        if (!props.themeData?.themeData) {
            props.themeData.themeData = {};
        }
        const newThemeData = props.themeData.themeData;
        if (newThemeData && newThemeData[tabKey] && newThemeData[tabKey][familyKey] && newThemeData[tabKey][familyKey][varKey] && newThemeData[tabKey][familyKey][varKey][varIndex]) {
            delete newThemeData[tabKey][familyKey][varKey][varIndex][schemaKey];
            setThemeUpdateCount((oldVal) => oldVal + 1);

            console.log('Theme data updated (val removed): ', newThemeData);
        }
    }

    const tabData = useRef(null);
    const buildTabs = () => {
        console.log('Edit theme, build tabs: ', props);
            const newTabData = [];
            for (let i = 0; i < props.featureData.feature.customisation.length; i++) {
                if (props.featureData.feature.customisation[i].themeable) {
                    newTabData.push(
                        {
                            label: props.featureData.feature.customisation[i].tabTitle,
                            id: props.featureData.feature.customisation[i].tabKey,
                        }
                    )
                }
            }
            tabData.current = newTabData;
            setCurrentTab(newTabData[0].id);

            console.log('Tab data built: ', props, tabData.current);
            console.log('Props: ', props);
    }
    useEffect(() => {
        buildTabs();
    }, []);

    return (
        <PopUpPanel closePanelFunc={props.closePanelFunc} className="edit-theme-holder">
            <div className="fl-column edit-theme form-holder">
                <div className="fl-row theme-top">
                    <div className="grow">
                        <h2>Editing Theme: {props.themeData.themeName}</h2>
                    </div>
                    <div className="main-top-options" onClick={() => props.openCommonFontPopup(addValueToTheme)}>Set Theme Font {IconJsxer.GetIcon(IconJsxer.ICONS.font, IconJsxer.ICON_STYLES.customiseTopOptions)}</div>
                    <div className="fl-row shrink">
                        <div className={`bold-text${advancedMode ? '' : ' light-text'}`}>Advanced Mode</div>
                        <Toggle id="advancedMode" currentState={advancedMode} toggleFunc={(e, id) => setAdvancedMode((oldVal) => !oldVal)} />
                    </div>
                </div>
                <div className="fl-row">
                    <div className="content-page-content-container">
                        <div className="content-page-main-content">
                            {tabData.current && tabData.current.length >= 1 &&
                                <TabbedPanel
                                    tabs={
                                        /* POTENTIAL_TABS */
                                        tabData.current
                                    }
                                    switchTabCallback={(tabId, tabData) => setCurrentTab(tabData.id)}
                                    initialTab={0}
                                    tabWidth={tabData.current.length < 5 ? '20%' : 100}
                                >

                                    <div className="tab-content">
                                        <div className="co-tab-content no-panel-shadow">

                                            {props.customisationVars.current &&
                                                <CustomisationControls
                                                    tabId={currentTab}
                                                    controlsData={props.featureData.feature.customisation}
                                                    customisationVars={props.customisationVars}

                                                    editingTheme={true}
                                                    themeData={props.themeData.themeData}
                                                    addValueToTheme={addValueToTheme}
                                                    removeThemeValue={removeThemeValue}

                                                    featureId={props.featureId}
                                                    featureData={props.featureData}
                                                    campaignData={props.campaignData}
                                                    handleRemovedFile={props.handleRemovedFile}
                                                    zoomFunction={props.zoomFunction}
                                                    showTooltipFunc={props.showTooltipFunc}
                                                    hideTooltipFunc={props.hideTooltipFunc}
                                                    openResetPresetsPanel={props.openResetPresetsPanel}
                                                    addDisruptor={props.addDisruptor}
                                                    addQuiz={props.addQuiz}
                                                    customiseQuiz={props.customiseQuiz}
                                                    logAddedDisruptor={props.logAddedDisruptor}
                                                    logRemovedDisruptor={props.logRemovedDisruptor}
                                                    showFeaturePreview={props.showFeaturePreview}
                                                    customiseFeature={props.customiseFeature}
                                                    refreshPreview={props.refreshPreview}
                                                    openFontSelectorFunc={props.openFontSelectorFunc}
                                                    advancedMode={advancedMode}
                                                />
                                            }

                                        </div>
                                    </div>

                                </TabbedPanel>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </PopUpPanel>
    );
}

export default EditThemePopup;
