import { useEffect, useState } from "react";
import FormDropDown from "../../components/forms/DropDownList/FormDropDown";
import FormField from "../../components/forms/FormField/FormField";
import axios from "../../api/axios";
import { createQuizRoute, getExtraQuizDataRoute } from "../../api/routes";
import DEBUG_LOGGER from "../../helpers/DebugLogger";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { TOP_LEVEL_NAV_ROUTES } from "../../config/NavRoutes";

const { default: PopUpPanel } = require("../../components/PopUpPanel/PopUpPanel")

const NewQuizPopup = (props) => {
    const navigate = useNavigate();

    const [quizTypes, setQuizTypes] = useState([]);

    const [name, setName] = useState("");
    const [quizNameError, setQuizNameError] = useState("");
    const [quizTypeIndex, setQuizTypeIndex] = useState(0);
    const [quizType, setQuizType] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const changeQuizType = (e, id, item) => {
        //Changes the QuizType on the quiz level
        //The possible QuestionTypes will all be automatically set to the selected type
        setQuizTypeIndex(item.index);
        setQuizType(item.value);
    }

    const createQuiz = (e) => {
        e.preventDefault();
        console.log('Creating quiz');
        if (name === "") {
            setQuizNameError("Please enter a name for your quiz");
            return;
        } else {
            setQuizNameError("");
        }

        setIsSubmitting(true);

        axios
            .post(createQuizRoute,
                {
                    name: name,
                    campaignID: null, // (quiz && quiz.campaign && quiz.campaign._id) ? quiz.campaign._id : (campaign && campaign._id) ? campaign._id : campaignId,
                    streamID: null,
                    /* disruptors: {
                        introDisruptor: quiz.introDisruptor,
                        correctDisruptor: quiz.correctDisruptor,
                        incorrectDisruptor: quiz.incorrectDisruptor
                    }, */
                    // rounds: quiz.rounds,
                    // status: quiz.status,
                    type: quizType.value,
                    // gradingSystem: quiz.gradingSystem,
                    withCredentials: true
                }
            )
            .then(response => {
                if (response.data.success) {
                    toast.success("Quiz created successfully");
                    // setTimeout(() => { navigate(getNavigationRoute()) }, 600);
                    // setHasUnsavedChanges(false);
                    console.log('Return created quiz:', response.data);
                    navigate(TOP_LEVEL_NAV_ROUTES.EDIT_QUIZ.replace(':id', response.data.createdQuiz._id));
                } else {
                    toast.error(response.data.error);
                    setQuizNameError("Please enter a unique quiz name");
                    setIsSubmitting(false);
                }
            })
            .catch(function (error) {
                toast.error('Error - ' + error);
                setIsSubmitting(false);
            });
    }

    const cancelPanel = (e) => {
        e.preventDefault();
        props.closePanelFunc();
    }

    const getExtraQuizData = () => {
        axios
            .get(getExtraQuizDataRoute, { withCredentials: true })
            .then((res) => {
                console.log('getExtraQuizData', res.data.extraQuizData);
                setQuizTypes(res.data.extraQuizData.quizTypes);
                setQuizType(res.data.extraQuizData.quizTypes[0]);
                setQuizTypeIndex(0);
            })
            .catch(err => {
                DEBUG_LOGGER.log("getQuestionTypesAndStatuses error ", err);
                toast.error("Failed to get question Types and statuses")
            })
    };

    useEffect(() => {
        if (quizTypes.length === 0) {
            getExtraQuizData();
        }
    }, []);

    return (
        <PopUpPanel closePanelFunc={props.closePanelFunc} showCloseBtn={false}>
            <div className="form-holder co-popup-content">
                <form id="addstream" className="form">
                    <h2>Create New Quiz</h2>
                    <FormField 
                        id="name" type="text" showLabel={true} label="Quiz Name" placeholder={"Enter a name"} error={quizNameError} value={name} 
                        setFunc={(fieldVal) => { setName(fieldVal) }} 
                        enabled={isSubmitting ? false : true}
                    />

                    {quizTypes && quizTypes.length > 0 &&
                        <FormDropDown
                            id={"quiz_type"}
                            label="Quiz Type"
                            items={quizTypes}
                            selectFunc={(e, id, item) => changeQuizType(e, id, item)}
                            currentIndex={quizTypeIndex || 0}
                            enabled={isSubmitting ? false : true}
                            icon="list"
                        />
                    }

                    <div className="fl-row">
                        <button className={'submit-btn' + (isSubmitting ? ' button-inactive' : ' button-active')} onClick={isSubmitting ? null : createQuiz}>
                            {isSubmitting ? "Saving..." : "Save"}
                        </button>
                        <button className={'submit-btn' + (isSubmitting ? ' button-inactive' : ' button-active')} onClick={isSubmitting ? null : cancelPanel}>
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </PopUpPanel>
    )
}

export default NewQuizPopup;
