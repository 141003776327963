import { assets, TwitchIconSvg, TwitchIconSvgPurple, TwitchIconSvgPurpleSmall } from "../../assets/assets";
import ProfilePic, { PROFILE_PIC_SIZES } from "../../components/ProfilePic/ProfilePic";
import ToggleCheck from "../../components/forms/ToggleCheck/ToggleCheck";
import { getAcTypeData } from "../../helpers/AccountTypes";
import { IconJsxer } from "../../helpers/IconHelper";

const ListedCampaignUser = (props) => {
    const { user, selected, toggleSelectedFunc, showCheck } = props;
    const acTypeData = user ? getAcTypeData(user.acType || user.accountType) : null;


    const toggleSelectedHandler = (e) => {
        e.preventDefault();
        if (toggleSelectedFunc) {
            toggleSelectedFunc(e, user);
        }
    }

        // console.log('AC TYPE', user.acType)
    return (
        <>
        {user &&
        <div className="fl-row">
            <ProfilePic size={PROFILE_PIC_SIZES.medium} user={user} />
            <div className="fl-column shrink no-gap">
                <div className="user-name">{!user.firstName && !user.lastName && <>{user.name ? user.name : user.email}</>}{user.firstName} {user.lastName} {(user.isTwitch || user.authStrategy =='twitch') && <><span className="twitch-link-icon">{TwitchIconSvgPurpleSmall}</span><a href={'https://www.twitch.tv/'+user.name} target='_blank'>{user.name}</a></>}</div>
                <div className="small-text tight light-text">{acTypeData.displayName}</div>
            </div>
            {/* <div className={`ac-pill ${acTypeData.colorCss}`}>
                <p>{acTypeData.shortName}</p>
                {acTypeData.icon &&
                    <>
                        {IconJsxer.GetIcon(acTypeData.icon, IconJsxer.ICON_STYLES.acPillIcon, 'icon')}
                    </>
                }
                {!acTypeData.icon && acTypeData.useTwitchIcon &&
                    <img src={assets.TwitchLogoSmall} className="tw-icon" alt="" />
                }
            </div> */}
            <div className="grow" />
            {showCheck &&
                <div className="centered">
                    <ToggleCheck id={user._id} currentState={selected} toggleFunc={toggleSelectedHandler} uncheckedStyle={props.uncheckedStyle || "add"} />
                </div>
            }
        </div>
        }
        </>
    );
}

export default ListedCampaignUser;
