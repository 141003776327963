import ClosePanelBtn from "../ClosePanelBtn/ClosePanelBtn";
import PropTypes from 'prop-types'; 

import "./PopUpPanel.scss";

const PopUpPanel = (props) => {
    return (
        <div className={`blackout popup-panel-holder`}>
            <div className={`popup-panel ${props.className} ${props.wide ? 'wide-popup' : ''}`}>
                {props.showCloseBtn && <ClosePanelBtn clickFunc={props.closePanelFunc} />}
                {props.children}
            </div>
        </div>
    );
}

PopUpPanel.propTypes = {
    showCloseBtn: PropTypes.bool.isRequired,
    closePanelFunc: PropTypes.func,
    className: PropTypes.string,
};

PopUpPanel.defaultProps = {
    showCloseBtn: true,
    closePanelFunc: null,
    className: '',
}

export default PopUpPanel;
