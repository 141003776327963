import React from "react";
import PopUpPanel from "../../../components/PopUpPanel/PopUpPanel";
import { IconJsxer } from "../../../helpers/IconHelper";

const FunctionUnavailableInPreviewPopup = (props) => {
    return (
        <PopUpPanel closePanelFunc={props.closePanelFunc}>
            <div className="fl-column red-force-stop-text">
                <h2>Function Unavailable</h2>
                <div className="fl-row">
                    <div className="grow"></div>
                    <div>{IconJsxer.GetIcon(IconJsxer.ICONS.error, IconJsxer.ICON_STYLES.forceStopPopIcon)}</div>
                    <div className="grow"></div>
                </div>
                <h4>This function is only available during a live stream.</h4>
            </div>
        </PopUpPanel>
    )
}

export default FunctionUnavailableInPreviewPopup;
