import React, { useState } from "react";

import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";

const ConfirmRemoveQuizQuestion = (props) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const removeNow = (e) => {
        e.preventDefault();
        props.removeQuestion(props.roundIndex, props.questionIndex, removeCallback);
    }

    const removeCallback = (success) => {
        if (success) {
            props.closePanelFunc();
        } else {
            setIsSubmitting(false);
        }
    }

    let question = props.quiz.rounds[props.roundIndex].questions[props.questionIndex];
    return (
        <PopUpPanel closePanelFunc={props.closePanelFunc}>
            <div className="form-holder co-popup-content">
                <form id="confirmRemoveFeature" className="form">
                    <h2>Remove This Question?</h2>

                    <div className="drop-gap">
                        { question && question.questionText &&
                          <h4>{props.quiz.rounds[props.roundIndex].questions[props.questionIndex].questionText}</h4>
                        }
                        { question && question.questionImage &&
                          <img src={question.questionImage}></img>
                        }
                    </div>

                    <button className={'submit-btn' + (isSubmitting ? ' button-inactive' : ' button-active')} onClick={isSubmitting ? null : removeNow}>
                        {isSubmitting ? "Removing" : "Remove"}
                    </button>
                </form>
            </div>
        </PopUpPanel>
    )
}

export default ConfirmRemoveQuizQuestion;
