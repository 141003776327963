import React, { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";

import axios from "../../api/axios";
import { Link } from "react-router-dom";
import { confirmLoginRoute, logoutRoute, sendConfirmationRoute } from "../../api/routes";

import ConfirmationCodeInput from "../../components/ConfirmationCodeInput/ConfirmationCodeInput";
import ClosePanelBtn from "../../components/ClosePanelBtn/ClosePanelBtn";
import { assets } from "../../assets/assets"

import "./ConfirmLogin.scss";
import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import { LogoutHandler } from "../../helpers/AuthFunctions";


const ConfirmLogin = (props) => {
    const navigate = useNavigate()
    // const search = useLocation().search
    // const searchParams =  new URLSearchParams(search)
    // const email = searchParams.get('email')
    
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isAttempting, setIsAttempting] = useState(false)
    const [allowResend, setAllowResend] = useState(false);
    const [resendTimer, setResendTimer] = useState(30);
    const [authContext, setAuthContext] = useContext(AuthContext)
    
    useEffect(() => {
        if(authContext.isConfirmed) navigate('/');
        //setResendTimer(10);
        setAllowResend(false);
        startResetTimer(null);}, 
     [authContext]);


     useEffect(() => {
        if(!allowResend)
        {
            setTimeout( () => {
                startResetTimer(null);
        
            }, 1000); 
        }
     }, [resendTimer])

     useEffect(() => {
        if(!allowResend)
        {
            setResendTimer(30);
        }
     }, [allowResend])
   

    const startResetTimer = (e) => {
        
        if (e) e.preventDefault();

        if(resendTimer == 0)
        {
            setAllowResend(true)  
        }
        else
        {
            console.log(resendTimer)
            const newTime = resendTimer - 1
            console.log(newTime)
            setResendTimer(newTime)  
           
        }
    }

    const resendConfirmationCode = (e) => { 
        axios.post(sendConfirmationRoute, {withCredentials:true})
        setAllowResend(false);
    }


    const submitConfirmationCode = (e) => { 

        e.preventDefault();
        
        if(e.target.form) {
            let confirmationCode = "";
            for(var i = 0; i < e.target.form.length-1; i++) {
                var formElement = e.target.form[i];
                if(formElement.value != undefined && formElement.value != "") {
                    confirmationCode += formElement.value;
                } else {
                    confirmationCode = null;
                    break;
                }
            }

            if(confirmationCode) {
                
                if(isSubmitting) {
                    return;
                }
                setIsSubmitting(true);
                setIsAttempting(true)

                axios
                .post(confirmLoginRoute, {
                    confirmationCode: confirmationCode,
                    withCredentials:true
                })
                .then((response) => {
                    if(response.data.success) {
                        setIsSubmitting(false)
                        const data = response.data
                        toast.success("Two Step Authentication Successful!")
                        axios.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;
                        setAuthContext((oldValues) => {
                            return { ...oldValues, token:data.token, isLoggedIn:true, isConfirmed:true, userData:data.globalUserData}
                        })
                        navigate("/")
                    } else {
                        setIsSubmitting(false)
                        toast.error("Verification failed, please check the code you entered was correct");
                    }
                })
                .catch(function (error) {
                    setIsSubmitting(false)
                    toast.error('Error - '+error.message)
                });
            }
            else {
                e.preventDefault();
                toast.error("You must fill out the full confirmation code");
                return false;
            }
        }
    }


    return(
        <PopUpPanel closePanelFunc={() => { LogoutHandler(setAuthContext); }}>
                <div className="confirm-login-header-holder">
                    <div className="confirm-login-logo-holder">
                        <img src={assets.Lock} />
                    </div>
                    <h2 className="confirm-login-header">Two-factor<br />Authentication</h2>

                </div>
                <main>
                    <p className="confirm-login-text">
                    Please check your email for a confirmation code to complete 2 step verification of your account.
                    </p>
                    
                </main>
                <div className="form-holder">
                    <form onSubmit={(e) => e.preventDefault()} autoComplete="off">
                        <ConfirmationCodeInput id="confirmation-code-input" label="Verification code:" submitConfirm={submitConfirmationCode} />
                        
                        <div className="submit-btn-holder">
                            <button className={'submit-btn' + (isSubmitting ? ' button-inactive' : ' button-active')} onClick={isSubmitting ? null : submitConfirmationCode }>
                                {isSubmitting ? "Verifying" : "Verify"}
                            </button>
                        </div>
                        <div className="footer">
                            <p>By creating an account you agree to the Terms of Use</p>
                            {allowResend && 
                                <>
                                        <a href='#' onClick={resendConfirmationCode}> Resend Email </a>
                                
                                </>
                            }
                            {
                               !allowResend &&
                               <>
                                <p>Resend Email: {resendTimer}</p>
                               
                               </>  
                            }
                        </div>
                    </form>
                </div>
        </PopUpPanel>
    );
}

export default ConfirmLogin;