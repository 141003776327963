import PropTypes from 'prop-types';
import DebugLogger from "../../helpers/DebugLogger";
import { useNavigate, } from "react-router-dom";
import { TOP_LEVEL_NAV_ROUTES } from "../../config/NavRoutes";
import React, { useContext } from "react";
import { CampaignContext } from "../../contexts/CampaignContext";
import "./CampaignSummaryPanel.scss";

import { IconJsxer } from '../../helpers/IconHelper';
import HeadedPanel from "../HeadedPanel/HeadedPanel";
import { countStreamStatusTypesForCampaign, getStatusForCampaign, getStreamTypeDataFromDbString, getTwitchStreamUrl } from '../../helpers/Streams';
import { getRegionDataFromDbString } from '../../helpers/Regions';
import { TwitchIconSvg } from '../../assets/assets';
import { getLongDateString, getShortDateString, getShortTimeString } from '../../helpers/Dates';
import { CAMPAIGN_TIERS, getCampaignTierDataFromDbString } from '../../helpers/CampaignsHelper';
import LeftColorBlockBox from '../LeftColorBlockBox/LeftColorBlockBox';
import LeftColorBlockHeadedPanel from '../LeftColorBlockBox/LeftColorBlockHeadedPanel';

const CampaignSummaryPanel = (props) => {
    const navigate = useNavigate();

    const [campaignContext, setCampaignContext] = useContext(CampaignContext)
    const openCampaignOverview = (e, campaignID) => {
        navigate(TOP_LEVEL_NAV_ROUTES.CAMPAIGN_OVERVIEW.replace(':id', campaignID));
    }

    const streamUrl = props.showStream ? getTwitchStreamUrl(props.showStream.twitchHandle) : '';
    const openTwitchChannel = () => {
        window.open(streamUrl, '_blank');
    }

    const status = props.showStream ? getStreamTypeDataFromDbString(props.showStream.status) : (props.campaign.statusData || getStatusForCampaign(props.campaign.streams, props.campaign.archived));
    const statusCounts = props.campaign.statusCounts || countStreamStatusTypesForCampaign(props.campaign.streams);
    // Just insert that data so we can use it for filters!
    props.campaign.statusData = status;
    props.campaign.status = status.mapToForCount;
    props.campaign.statusCounts = statusCounts;
    // console.log('Status data: ', status);

    return (
        <>
        {props.listView &&
            <LeftColorBlockBox style={'campaign_' + status.dbString}>
                <div className='fl-row list-view-content'>
                    <div className='tier-badge'>
                        {/*console.log(getCampaignTierDataFromDbString(props.campaign.campaignTier))*/}
                        <img src={'/assets/tier-badges/badge-fill-' + getCampaignTierDataFromDbString(props.campaign.campaignTier).imgString + '.svg'} alt="" />
                    </div>
                    <div className='list-view-label grow'>{props.campaign.campaignName}</div>
                    <div className='fl-row list-view-data-block'>
                        {IconJsxer.GetIcon(IconJsxer.ICONS.handshake, IconJsxer.ICON_STYLES.listIcon)}
                        <a href='#' onClick={(e) => { props.searchFunc(props.campaign.client)}}>
                            {props.campaign.client}
                        </a>
                    </div>
                    <div className='fl-row list-view-data-block'>
                        {IconJsxer.GetIcon(IconJsxer.ICONS.region, IconJsxer.ICON_STYLES.listIcon)}
                        {getRegionDataFromDbString(props.campaign.region).displayName}
                    </div>
                    
                    <div className='fl-row list-view-data-block wide'>
                        {IconJsxer.GetIcon(IconJsxer.ICONS.oneUser, IconJsxer.ICON_STYLES.listIcon)}
                        {props.campaign.campaignOwner}
                    </div>
                    <div className='fl-row list-view-data-block narrow'>
                        {IconJsxer.GetIcon(IconJsxer.ICONS.salesforce, IconJsxer.ICON_STYLES.listIcon)}
                        {props.campaign.sfNumber !== 0 &&
                            <span><a href={props.campaign.sfLink} target='_blank'>{props.campaign.sfNumber}</a></span>
                        }
                        {props.campaign.sfNumber === 0 &&
                            <span className='light-text'>{props.campaign.sfNumber}</span>
                        }
                    </div>

                    <div className='fl-row list-view-data-block narrow'>
                        {IconJsxer.GetIcon(IconJsxer.ICONS.folderPlus, IconJsxer.ICON_STYLES.listIcon)}
                        {getShortDateString(props.campaign.dateCreated)}
                    </div>

                    <div className='stream-count light-text'>
                        <div className='fl-row compact grow'>
                            {IconJsxer.GetIcon(IconJsxer.ICONS.liveStream, IconJsxer.ICON_STYLES.standard)}
                            <div className='grow'>
                                {statusCounts.live + statusCounts.scheduled + statusCounts.ended}
                            </div>
                        </div>
                    </div>

                    <button className='standard-button' onClick={(e) => { openCampaignOverview(e, props.campaign._id); }}>
                        {IconJsxer.GetIcon(IconJsxer.ICONS.cog, IconJsxer.ICON_STYLES.listIcon)}
                    </button>
                </div>
            </LeftColorBlockBox>
        }
        {!props.listView &&
            <LeftColorBlockHeadedPanel
                style={'campaign_' + status.dbString}
                className={`campaign-panel`}
                fullWidth={false}
            // <HeadedPanel className={`campaign-panel${props.listView ? ' list-view' : ''}`} color={status.headedPanelColorStr}
                headingContent={
                    <>
                    {props.listView &&
                        <>
                            <div className="grow"></div>
                            <div className="icon">{IconJsxer.GetIcon(status.icon, IconJsxer.ICON_STYLES.campaignPanelTopList)}</div>
                            <div className='list-view-status'>Stream {status.displayName}</div>
                            <div className="grow"></div>
                        </>
                    }
                    {!props.listView &&
                        <div className='fl-row list-view-content'>
                            <div className="list-view-label grow">{props.campaign.campaignName}</div>
                            <div className='tier-badge'>
                                <img src={'/assets/tier-badges/badge-fill-' + getCampaignTierDataFromDbString(props.campaign.campaignTier).imgString + '.svg'} alt="" />
                            </div>
                        </div>
                    }
                    </>
                }
            >
                {props.listView &&
                    <div className='fl-column'>
                        <div className='fl-row'>
                            <div className='tier-badge'>
                                {console.log(getCampaignTierDataFromDbString(props.campaign.campaignTier))}
                                <img src={'/assets/tier-badges/badge-fill-' + getCampaignTierDataFromDbString(props.campaign.campaignTier).imgString + '.svg'} alt="" />
                            </div>
                            <h3>{props.campaign.campaignName}</h3>
                            <div className='grow'></div>
                            <div className="info-pill active">
                                {IconJsxer.GetIcon(IconJsxer.ICONS.liveStream, IconJsxer.ICON_STYLES.campaignPanelInfoPill)}
                                <div className="grow"></div>
                                <div>{statusCounts.live}</div>
                                <div className="grow"></div>
                                <div>Active</div>
                            </div>
                            <div className="info-pill scheduled">
                                {IconJsxer.GetIcon(IconJsxer.ICONS.scheduleNav, IconJsxer.ICON_STYLES.campaignPanelInfoPill)}
                                <div className="grow"></div>
                                <div>{statusCounts.scheduled}</div>
                                <div className="grow"></div>
                                <div>Scheduled</div>
                            </div>
                            <div className="info-pill archived">
                                {IconJsxer.GetIcon(IconJsxer.ICONS.archived, IconJsxer.ICON_STYLES.campaignPanelInfoPill)}
                                <div className="grow"></div>
                                <div>{statusCounts.ended}</div>
                                <div className="grow"></div>
                                <div>Ended</div>
                            </div>
                        </div>
                        <div className='fl-row'>
                            <p><span className="detail-label">Client: </span><a href='#' onClick={(e) => { props.searchFunc(props.campaign.client)}}><span className="detail-info-1">{props.campaign.client}</span></a></p>
                            <p><span className="detail-label">Region: </span><span className="detail-info-1">{getRegionDataFromDbString(props.campaign.region).displayName}</span></p>
                            <p><span className="detail-label">Campaign Owner: </span><span className="detail-info-1">{props.campaign.campaignOwner}</span></p>
                            <p><span className="detail-label">Salesforce#: </span><span className="detail-info-1"><a href={props.campaign.sfLink} target='_blank'>{props.campaign.sfNumber}</a></span></p>
                            <p><span className="detail-label">Created: </span><span className="detail-info-1">{getShortDateString(props.campaign.dateCreated)}</span></p>
                            <div className='grow'></div>
                            <div className="fl-row">
                                <button className="manage-btn fl-equal" onClick={(e) => { openCampaignOverview(e, props.campaign._id); }}>Manage</button>
                                <button disabled className="watch-btn fl-equal">Watch</button>
                            </div>
                        </div>
                    </div>
                }
                {!props.listView &&
                    <div className='fl-column grow'>

                        <div>
                            <p><span className="detail-label">Client: </span>
                                {props.searchFunc &&
                                    <a href='#' onClick={(e) => { props.searchFunc(props.campaign.client)}}><span className="detail-info-1">{props.campaign.client}</span></a>
                                }
                                {!props.searchFunc &&
                                    <span className="detail-info-1">{props.campaign.client}</span>
                                }
                            </p>


                            <p><span className="detail-label">Region: </span><span className="detail-info-1">{getRegionDataFromDbString(props.campaign.region).displayName}</span></p>
                        
                            {!props.showStream &&
                                <>
                                    <p><span className="detail-label">Campaign Owner: </span><span className="detail-info-1">{props.campaign.campaignOwner}</span></p>
                                    <p><span className="detail-label">Salesforce#: </span><span className="detail-info-1"><a href={props.campaign.sfLink} target='_blank'>{props.campaign.sfNumber}</a></span></p>
                                </>
                            }

                            <p><span className="detail-label">Created: </span><span className="detail-info-1">{getShortDateString(props.campaign.dateCreated)}</span></p>
                        </div>

                        {!props.showStream &&
                            <>
                                <div className="fl-row grow">
                                    <div className="info-pill active grow">
                                        {IconJsxer.GetIcon(IconJsxer.ICONS.liveStream, IconJsxer.ICON_STYLES.campaignPanelInfoPill)}
                                        <div className="grow"></div>
                                        <div>{statusCounts.live}</div>
                                        <div className="grow"></div>
                                        <div>Active</div>
                                    </div>
                                    <div className="info-pill scheduled grow">
                                        {IconJsxer.GetIcon(IconJsxer.ICONS.scheduleNav, IconJsxer.ICON_STYLES.campaignPanelInfoPill)}
                                        <div className="grow"></div>
                                        <div>{statusCounts.scheduled}</div>
                                        <div className="grow"></div>
                                        <div>Scheduled</div>
                                    </div>
                                    <div className="info-pill archived grow">
                                        {IconJsxer.GetIcon(IconJsxer.ICONS.archived, IconJsxer.ICON_STYLES.campaignPanelInfoPill)}
                                        <div className="grow"></div>
                                        <div>{statusCounts.ended}</div>
                                        <div className="grow"></div>
                                        <div>Ended</div>
                                    </div>
                                </div>
                            </>
                        }

                        {props.showStream && props.showStream.twitchHandle &&
                        <>
                            <div className='stream-data'>
                                <div className="fl-row"><div>{TwitchIconSvg}</div><div>{props.showStream.twitchHandle}</div></div>
                                <div className="fl-row"><div>{IconJsxer.GetIcon(IconJsxer.ICONS.scheduleNav, IconJsxer.ICON_STYLES.streamData)}</div><div>{getLongDateString(props.showStream.startTime)}</div></div>
                                <div className="fl-row"><div>{IconJsxer.GetIcon(IconJsxer.ICONS.clock, IconJsxer.ICON_STYLES.streamData)}</div><div>{getShortTimeString(props.showStream.startTime)}</div></div>
                            </div>
                        </>
                        }

                        <div className="fl-row">
                            <button className="standard-button tight" onClick={(e) => { openCampaignOverview(e, props.campaign._id); }}>{IconJsxer.GetIcon(IconJsxer.ICONS.cog, IconJsxer.ICON_STYLES.listIcon)}</button>
                            {props.showStream && 
                                <button className="standard-button tight" onClick={(e) => {openTwitchChannel()}}>
                                    <div className="lcb-button-icon">
                                        {TwitchIconSvg}
                                    </div>
                                </button>
                            }
                        </div>
                    </div>
                }
            </LeftColorBlockHeadedPanel>
        }
        </>
    );
}

export default CampaignSummaryPanel;
