import React, { useState, useEffect } from "react";

import axios from "../../api/axios";

import { toast } from "react-toastify";
import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import FormDropDown from "../../components/forms/DropDownList/FormDropDown";
import { getDisruptorListRoute, getFeatureListRoute } from "../../api/routes";
import FormField from "../../components/forms/FormField/FormField";

const AddFeature = (props) => {
    const [featureName, setFeatureName] = useState('');
    const [featureNameError, setFeatureNameError] = useState('');

    const [rawFeatureList, setRawFeatureList] = useState([]);
    const [selectedFeature, setSelectedFeature] = useState(null);
    const updateFeatureHandler = (e, dropDownId, item) => {
        console.log('Feature selected: ', item);
        setSelectedFeature(item);
        setFeatureName(item.label);
    }
    const [selectedFeatureError, setSelectedFeatureError] = useState('');

    const [isSubmitting, setIsSubmitting] = useState(false);

    const saveFeature = (e) => {
        e.preventDefault();

        if(!isSubmitting)
        {
            let formValid = true;
            if (selectedFeature === null || selectedFeature.index === 0) {
                setSelectedFeatureError('Please select a feature to add');
                formValid = false;
            } else {
                setSelectedFeatureError('');
            }
            if (featureName === '') {
                setFeatureNameError('You need to name your feature');
                formValid = false;
            } else {
                setFeatureNameError('');
            }

            if (formValid) {
                const newFeatureData = {feature: {_id: selectedFeature.id, featureName: selectedFeature.label, featureType: "feature"}, contentLabel: featureName, varKey: selectedFeature.value};
                setIsSubmitting(true);
                props.addSelectedFeatureToListFunc(newFeatureData, featureSaved);
            }
        }
    }

    const featureSaved = (success) => {
        setIsSubmitting(false);
        if (success) {
            props.closePanelFunc();
        }
    }

    const fetchFeatureList = () => {
        axios
            .get(getFeatureListRoute, { withCredentials: true })
            .then((response) => {
                const featureListData = response.data.featureList;
                console.log('Feature data: ', featureListData);
                setRawFeatureList(featureListData);
            })
            .catch((error) => {
                //
                //toast.error(error.response.data.msg);
            });
    }

    const getFeatureListDropDownData = () => {
        const featureDropDownData = [];
        featureDropDownData.push({ id: 0, value: '', label: 'None' });
        for (let i = 0; i < rawFeatureList.length; i++) {
            featureDropDownData.push({ id: rawFeatureList[i]._id, value: rawFeatureList[i].varKey, label: rawFeatureList[i].featureName });
        }
        return featureDropDownData;
    }

    useEffect(() => {
        fetchFeatureList();
    }, [])

    return (
        <>
        <PopUpPanel closePanelFunc={props.closePanelFunc}>
            <div className="form-holder co-popup-content">
                <form id="addstream" className="form">
                    <h2>Add Feature</h2>
                    {/* <FormField
                        type="text"
                        id="disruptor_name"
                        placeholder="Enter Disruptor Name"
                        setFunc={setFeatureName}
                        label="Disruptor Name"
                        error={featureNameError}
                        value={featureName}
                    /> */}
                    <FormDropDown
                                label="Select Feature"
                                id="select_feature"
                                selectFunc={updateFeatureHandler}
                                currentIndex={selectedFeature ? selectedFeature.index : 0}
                                items={
                                    getFeatureListDropDownData()
                                }
                                error={selectedFeatureError}
                            />

                    <button className={'submit-btn' + (isSubmitting ? ' button-inactive' : ' button-active')} onClick={isSubmitting ? null : saveFeature}>
                        {isSubmitting ? "Adding Feature" : "Add Feature"}
                    </button>
                </form>
            </div>
        </PopUpPanel>
        </>
    )
}

export default AddFeature;
