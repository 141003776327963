import { getFeatureAssetBaseUrl } from "./FeatureAssets";

export const injectQuizImageSrcUrls = (quiz) => {
    quiz.rounds.forEach((round) => {
        round.questions.forEach((question) => {
            if (question.questionImage) {
               let srcUrl = getFeatureAssetBaseUrl(false);
               if (quiz.campaign) {
                    srcUrl += 'campaigns/' + quiz.campaign._id + '/';
               } else {
                    srcUrl += 'campaigns/generic/';
               }
               srcUrl += 'quizzes/' + quiz._id + '/image/' + question.questionImage;
               // console.log('Question image srcUrl: ' + srcUrl, question.questionImage);
               question.questionImageSrcUrl = srcUrl;
            }
            question.answers.forEach((answer) => {
                if (answer.answerImage) {
                    let srcUrl = getFeatureAssetBaseUrl(false);
                    if (quiz.campaign) {
                        srcUrl += 'campaigns/' + quiz.campaign._id + '/';
                    } else {
                        srcUrl += 'campaigns/generic/';
                    }
                    srcUrl += 'quizzes/' + quiz._id + '/image/' + answer.answerImage;
                    // console.log('Answer image srcUrl: ' + srcUrl, answer.answerImage);
                    answer.answerImageSrcUrl = srcUrl;
                }
            });
        });
    });
    return quiz;
}

const QUIZ_TYPES = {
    quiz: {id: 'quiz', displayName: 'Quiz', shortName: 'Quiz', dbString: 'Quiz', colorCss: 'quiz', icon: 'quizPillIcon'},
    poll: {id: 'poll', displayName: 'Poll', shortName: 'Poll', dbString: 'Poll', colorCss: 'poll', icon: 'pollPillIcon'},
    tugOFWar: {id: 'tugOFWar', displayName: 'Tug of War', shortName: 'Tug of War', dbString: 'Tug of War', colorCss: 'tugofwar', icon: 'tugOfWarPillIcon'},
}

export const getQuizTypeDataFromDbString = (dbString) => {
    dbString = dbString.toLowerCase();
    for (let p in QUIZ_TYPES) {
        if (QUIZ_TYPES[p].dbString.toLowerCase() === dbString) {
            return QUIZ_TYPES[p];
        }
    }
    return null;
}
