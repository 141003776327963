import React, { useState } from "react";

import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import TabAnalytics from "../Streams/TabAnalytics";
import { convertMSToHMS, getLongDateString } from "../../helpers/Dates";
import DropDownList from "../../components/forms/DropDownList/DropDownList";




const SessionAnalytics = (props) => {



    console.log('Analytics sesh1:', props._analyticsData);
    return (
        <PopUpPanel closePanelFunc={props.closePanelFunc} className="session-analytics-popup">
            <h2>{props._sessionTwitchHandle} - {getLongDateString( props._sessionData.featureStartTime) } {'('+convertMSToHMS(props._sessionData.featureRunTime).h+':'+convertMSToHMS(props._sessionData.featureRunTime).m_str+':'+convertMSToHMS(props._sessionData.featureRunTime).s_str+')'}</h2>          
            <div className="session-analytics-scroll-panel">
                <TabAnalytics
                _analyticsObj={props._analyticsObj}
                    _analyticsData={props._analyticsData}
                    _updateAnalyticsFunc = {props._updateAnalyticsFunc}
                    _sessionID={props._sessionData.analyticDetails}
                
                    _streamData={{}}

                    _features={props._campaignData.features}

                    _showTooltip={props._showTooltip}
                    _hideTooltip={props._hideTooltip}
                />
            </div>
        </PopUpPanel>
    )
}

export default SessionAnalytics;
