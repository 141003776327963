import React, { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";

import "./ContentTopProfile.scss";
import ProfilePic, { PROFILE_PIC_SIZES } from "../ProfilePic/ProfilePic";
import SearchBox from "../forms/SearchBox/SearchBox";

const ContentTopProfile = (props) => {
    const [authContext, setAuthContext] = useContext(AuthContext);
    return (
        <div className="fl-row grow">
            <div className="top-bar-profile">

                <ProfilePic size={PROFILE_PIC_SIZES.large} className="pp-margin" />

                <div className="text">
                    <h1>Hey, {authContext.userData.firstName} {authContext.userData.lastName}!</h1>
                </div>
            </div>
            {props.showSearchBox &&
                    <>
                        <div className="grow"></div>
                        <div className="top-search">
                            <SearchBox searchFunc={props.searchFunc} />
                        </div>
                    </>
                }
        </div>
    )
}

export default ContentTopProfile;
