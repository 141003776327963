//qa5@5)ds>^#?
import React, { useContext, useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import axios from "../../api/axios";
import { loginRoute, sendConfirmationRoute, twitchAuthRoute } from "../../api/routes";
import FormField from "../../components/forms/FormField/FormField";
import { AuthContext } from "../../contexts/AuthContext";
import "./Login.scss";
import TabbedPanel from "../../components/TabbedPanel/TabbedPanel";
import DebugLogger from "../../helpers/DebugLogger";
import { assets } from "../../assets/assets";
import Register from "./Register";
import ConfirmLogin from "./ConfirmLogin";
import ForgottenPassword from "./ForgottenPassword";
import ResetPassword from "./ResetPassword";
import { pullInAccountSettings } from "../../helpers/AccountTypes";

const Login = (props) => {



  const [voices, setVoices] = useState(speechSynthesis.getVoices());
  useEffect(() => {
      setVoices(speechSynthesis.getVoices());
      speechSynthesis.onvoiceschanged = () => {
          setVoices(speechSynthesis.getVoices());
      };
  }, []);
  console.log(speechSynthesis.getVoices());


  const location = useLocation();
  const useQuery = new URLSearchParams(useLocation().search);

  const resetToken = useQuery.get("resettoken");
  const email = useQuery.get("email");
  let resetEmail = (resetToken) ? email : undefined;
  let registerEmail = (!resetToken) ? email: undefined;

  useEffect(() => {
    if(resetToken || email) {
      //resetEmail = email;
      window.history.replaceState(null, document.title, "/");
    }
  }, [resetToken, email])

  useEffect(() => {
    document.getElementById('twitchDemoVid').play();
  }, [])

  const [showRegisterForm, setShowRegisterForm] = useState((registerEmail) ? true : false);
  const [showForgottenPasswordForm, setShowForgottenPasswordForm] = useState(false);
  const [showResetPasswordForm, setShowResetPasswordForm] = useState((resetToken) ? true : false);
  const [showConfirmLoginPopup, setShowConfirmLoginPopup] = useState(props.showConfirmLoginPopup || false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
 // const [formValid, setFormValid, isValidForm] = useContext(AuthContext);
  const [authContext, setAuthContext] = useContext(AuthContext)
  const [currentTab, setCurrentTab] = useState(0);

  
  const handleValidation = (event) => {
    let formIsValid = true;
 
    if(username === '') 
    {
      setUsernameError("Please enter a username"); 
      formIsValid = false;
    } else {
      setUsernameError(""); 
    }
    if(password === ''){
      setPasswordError("Please enter a password"); 
      formIsValid = false;
    }  else {
      setPasswordError("");
    }
    return formIsValid;
  };

  const loginFormSubmitHandler = (e) => {
    e.preventDefault();

    // Double check our account settings have been pulled in...
    pullInAccountSettings(1000, true);

    setIsSubmitting(true);
    let formValidationPassed = handleValidation();
    if (formValidationPassed) {
      DebugLogger.log('Attempting login ...')
      axios
        .post(loginRoute, {username:username, password:password, withCredentials:true})
        .then(function (response) {
          setIsSubmitting(false);
          toast.success("Logged In Successfully!");

          const data = response.data
          console.log(data)
          let skipConfirm = data.globalUserData.ignoreTwoFactor
          setAuthContext((oldValues) => {
            axios.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;
            return { ...oldValues, token:data.token, isLoggedIn:true, userData:data.globalUserData, isConfirmed:skipConfirm}
          })
          if(!response.data.ignore2FA)
          {
            setShowConfirmLoginPopup(true); 
            return axios.post(sendConfirmationRoute, {withCredentials:true})
          }
          else return({data:{success:true}})

        })
        .then(confirmationSent => {
          if(confirmationSent.data.success) {
            toast.success(confirmationSent.data.message)
          } else {
            toast.error(new Error("Send Email Failed - " + confirmationSent.data.message))
          }
        })
        .catch(function (error) {
          setIsSubmitting(false);
          toast.error('Login Failed - Invalid username or password');
      });
    }
    else
    {
      setIsSubmitting(false);
    }
  };

  const toggleRegisterForm = (e) => {
    setShowRegisterForm(!showRegisterForm);
  }
  const toggleForgottenPasswordForm = (e) => {
    setShowForgottenPasswordForm(!showForgottenPasswordForm);
  }
  const toggleResetPasswordForm = (e) => {
    setShowResetPasswordForm(!showResetPasswordForm);
  }
  const toggleConfirmLoginPopup = (e) => {
    setShowConfirmLoginPopup(!showConfirmLoginPopup);
  }

  return (
    <>
    <div className="container fill-vh">
        <video className="login-vid" autoplay muted loop id="twitchDemoVid">
          <source src="./assets/campaign-studio-video.webm" type="video/webm"/>
        </video>
        
      <div className="row fill-height">

        <div className="login-right">
          {/* <img className="login-twitch-logo" src='./assets/campaign-studio-logo.svg' alt="Twitch Logo" /> */}
          <img className="login-icast-logo" src={assets.icastFrontPageLogo} alt="ICAST Logo" />

          <div className="login-form-holder form-holder">
            <TabbedPanel tabs={[{label: 'Twitch Login'}, {label: 'Email Login'}]} switchTabCallback={(tabId, tabData) => setCurrentTab(tabId)} extraPanelClass="login-panel-min-height">
              <>

                {currentTab === 1 &&
                  <form id="loginform" className="form">
                    <p className="title-text">Login and start creating campaigns.</p>
                    <div>
                      <FormField
                        type="text"
                        id="username"
                        placeholder="Enter Username"
                        setFunc={setUsername}
                        label="Email"
                        error={usernameError}
                      />
                    </div>
                    <div>
                      <FormField
                        type="password"
                        id="password"
                        placeholder="Enter Password"
                        setFunc={setPassword}
                        label="Password"
                        error={passwordError}
                      />
                    </div>
                    <button className={'submit-btn' + (isSubmitting ? ' button-inactive' : ' button-active')} onClick={isSubmitting ? null : loginFormSubmitHandler}>
                      {isSubmitting ? "Logging In" : "Login"}
                    </button>

                    <div className="footer">
                      <p className="heading"><span className="forgotten-password-link" onClick={toggleForgottenPasswordForm}>Trouble logging in?</span></p>
                      <p>By logging in you agree to the Terms of Use and the use of Cookies</p>
                      <p><span className="register-link" onClick={toggleRegisterForm}>Register Account</span></p>
                    </div>
                  </form>
                }

                {currentTab === 0 &&
                  <>
                    <p className="title-text">Click the button below to login with your Twitch Account.</p>
                    <a href={process.env.REACT_APP_API_BASE_URL+'/twitchapi/auth'} className="a-btn">
                      <button className="submit-btn button-active">
                        Login With Twitch
                      </button>
                    </a>

                           
                   


                  </>
                }

              </>
            </TabbedPanel>
          </div>
        </div>

      </div>
    </div>
    {showRegisterForm && <Register email={registerEmail} closePanelFunc={toggleRegisterForm} />}
    {showForgottenPasswordForm && <ForgottenPassword closePanelFunc={toggleForgottenPasswordForm} />}
    {showResetPasswordForm && <ResetPassword resetToken={resetToken} resetEmail={resetEmail} closePanelFunc={toggleResetPasswordForm} />}
    {authContext.isLoggedIn && !authContext.isConfirmed && <ConfirmLogin closePanelFunc={toggleConfirmLoginPopup} /> } 
    </>
  );
};

export default Login;
