import { useEffect, useRef, useState } from "react";
import { convertSecondsToHMS } from "../../helpers/Dates";
import { STREAM_TRANSCRIPT_SETTINGS } from "../../helpers/Streams";
import "./StreamTranscriptItem.scss";

const StreamTranscriptItem = (props) => {
    const {itemData} = props;
    const startTime = convertSecondsToHMS(itemData.startTime, true);
    const endTime = convertSecondsToHMS(itemData.endTime, true);

    const tooltipText = itemData.longDescription ? itemData.longDescription.split('||').join('<br/>') : '';
    /* const tooltipData = {
        id: itemData.type + itemData.startTime,
        tooltipText: tooltipText,
    } */

    // console.log('Tr Item: ', itemData);
    /* const addTooltipFunc = props._addTooltip;
    useEffect(
        () => {
            addTooltipFunc(tooltipData);
        }, []
    ); */

    let matchedSettings = STREAM_TRANSCRIPT_SETTINGS.default;
    Object.keys(STREAM_TRANSCRIPT_SETTINGS).forEach(element => {
        if(STREAM_TRANSCRIPT_SETTINGS[element].dbString == itemData.type) matchedSettings = STREAM_TRANSCRIPT_SETTINGS[element];
    });

    return (
        <div className="tr-item" onMouseEnter={(e) => {e.preventDefault(); props._showTooltip(tooltipText, e)}} onMouseLeave={props._hideTooltip}>
            {/* <a data-tooltip-id={tooltipData.id} data-tooltip-content={tooltipData.tooltipText}> */}
                <div className={"tr-pill " + (matchedSettings.colorCss)}>
                    {matchedSettings.label}
                </div>
            {/* </a> */}
            <div className="tr-time">{`${startTime.h_str}:${startTime.m_str}:${startTime.s_str}`} {itemData.endTime !=0 && <>- {`${endTime.h_str}:${endTime.m_str}:${endTime.s_str}`}</>}</div>
            <div className="tr-output">{itemData.shortDescription}</div>
        </div>
    )
}

export default StreamTranscriptItem;
