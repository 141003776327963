import { useContext, useEffect, useState, useReducer } from "react";
import ClosePanelBtn from "../../components/ClosePanelBtn/ClosePanelBtn";
import { CreateCampaignProgressBar } from "../../components/CreateCampaignProgressBar/CreateCampaignProgressBar";
import { TOP_LEVEL_NAV_ROUTES } from "../../config/NavRoutes";
import "./CreateCampaign.scss";

import { useNavigate, } from "react-router-dom";
import FormField from "../../components/forms/FormField/FormField";

import { toast } from "react-toastify";
import axios from "../../api/axios";
import { createCampaignRoute, getFeatureListRoute, getProfilePicRoute, userListAllUsersRoute } from "../../api/routes";
import { PRIMERS, filterList, searchList } from "../../helpers/ListFunctions";
import { ACCOUNT_TYPES } from "../../helpers/AccountTypes";
import { IconJsxer } from "../../helpers/IconHelper";
import ListedCampaignUser from "./ListedCampaignUser";
import { AuthContext } from "../../contexts/AuthContext";
import ReactPaginate from 'react-paginate';
import { getRegionsDropDownListData } from "../../helpers/Regions";
import SearchBox from "../../components/forms/SearchBox/SearchBox";
import FormDropDown from "../../components/forms/DropDownList/FormDropDown";
import RadioButtonGroup from "../../components/forms/RadioButtonGroup/RadioButtonGroup";
import { CAMPAIGN_TIERS, getCampaignTierFormData } from "../../helpers/CampaignsHelper";
import { STREAM_STATUS_TYPES } from "../../helpers/Streams";

import DateSelect from "../../components/forms/DateSelect/DateSelect";
import HMSTimeField from "../../components/forms/HMSTimeField/HMSTimeField";
import { convertFormDateTimeTimezoneToUnixTimestamp, getDefaultTimeZoneListData } from "../../helpers/Dates";
import SelectUser from "../../components/SelectUser/SelectUser";
import ListedAddedStream from "../../components/Streams/ListedAddedStream";
import { getTimezoneOptions, parseTimezone } from "../../helpers/TimeZones";
import Toggle from "../../components/forms/Toggle/Toggle";


const CreateCampaign = () => {
    const [authContext, setAuthContext] = useContext(AuthContext);
    const navigate = useNavigate();
    const [stage, setStage] = useState(1);

    const cancelCreateCampaign = (e) => {
        navigate(TOP_LEVEL_NAV_ROUTES.CAMPAIGNS);
    }


    /*********************************************************************
    * User List & Pagination
    * ******************************************************************/
    const [pageUpdate, forcePageUpdate] = useReducer(x => x + 1, 0);
    const [fetchUpdate, forceFetchUpdate] = useReducer(x => x + 1, 0);
    const [clientSearchTerm, setClientSearchTerm] = useState('');
    const [availableUsers, setAvailableUsers] = useState([]);
    const [selectedClientsUserData, setSelectedClientsUserData] = useState([]);
    const [userFilter, setUserFilter] = useState({id:null});
    const [selectedProducersUserData, setSelectedProducersUserData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(0);

    const handlePageClick = (event) => {
        setCurrentPage(event.selected)
      };

    const searchClientUserList = (e, searchTerm, filteredList = null) => {
        setClientSearchTerm(searchTerm);
    }

    const fetchUserList = () => {
        axios
            .get(userListAllUsersRoute, { params:{
                page:currentPage, 
                searchTerm:clientSearchTerm, 
                userType:userFilter, 
                authType:null, 
                sort:null,
                reverse:null
            }, withCredentials: true })
            .then((response) => {
                const userData = response.data.allusers;

                for (let i = 0; i < userData.length; i++) {
                    userData[i].selected = userData[i]._id == authContext.userData._id;
                    if(userFilter.id == 'all_external') {
                        for(let j=0; j<selectedClientsUserData.length; j++) {
                            if(userData[i]._id == selectedClientsUserData[j]._id)  userData[i].selected = true;
                        }
                        
                    }
                    else if(userFilter.id == 'all_producers') {
                        for(let j=0; j<selectedProducersUserData.length; j++) {
                            if(userData[i]._id == selectedProducersUserData[j]._id)  userData[i].selected = true;
                        }
                    }
                   
                }
                setAvailableUsers(userData)
                setNumberOfPages(response.data.numberOfPages);
            })
            .catch((error) => {
                //toast.error(error.response.data.msg);
            });
    }

    useEffect(() => {
        let simIn = Symbol('in')
        switch(stage)
        {
            case 2:
                setUserFilter(
                    {id:
                       "all_external"
                    })
            break;
            case 3:
                setUserFilter(
                    {id:
                       "all_producers"
                    })
            break;
        }
       
    }, [stage])
    
    useEffect(() => {
       forcePageUpdate();
    }, [userFilter, clientSearchTerm])

    useEffect(() => {
        console.log('GET PAGE CP')
       fetchUserList();
    }, [ currentPage, fetchUpdate])

    useEffect(() => {
        console.log('PAGE DATA UPDATED')
        currentPage == 0 ? forceFetchUpdate() : setCurrentPage(0);
       
    }, [numberOfPages, pageUpdate])
    
    //SELECTION DESELECTION
    const toggleUserSelected = (e, user) => {
       if(!user.selected) stage == 2 ? setSelectedClientsUserData(previous => [...previous, user]) : setSelectedProducersUserData(previous => [...previous, user])
        user.selected = true;
    }
    const toggleUserDeselected = (e, user, isExternal) => {
       user.selected = false;
       let index = 0
       let newClients = []
       switch (stage) {   
            case 2:
                index = selectedClientsUserData.findIndex((x) => x._id === user._id);
                newClients = [...selectedClientsUserData.splice(0, index), ...selectedClientsUserData.splice(index + 1)]
                setSelectedClientsUserData(newClients);
            break;
            case 3:
                index = selectedClientsUserData.findIndex((x) => x._id === user._id);
                newClients = [...selectedClientsUserData.splice(0, index), ...selectedClientsUserData.splice(index + 1)]
                setSelectedClientsUserData(newClients);
            break
        }
     }

    const clearAllSelectedClients = (e) => {
        e.preventDefault();
        for (let i = 0; i < availableUsers.length; i++) {
            availableUsers[i].selected = false;
        }
        setSelectedClientsUserData([])
    }

    const clearAllSelectedProducers = (e) => {
        e.preventDefault();
        for (let i = 0; i < availableUsers.length; i++) {
            // Don't remove the owner!
            if (availableUsers[i]._id !== authContext.userData._id) {
                availableUsers[i].selected = false;
            }
        }
        setSelectedProducersUserData([])
    }

    //Feature list
    const [rawFeatureList, setRawFeatureList] = useState([]);
    const fetchFeatureList = () => {
        axios
            .get(getFeatureListRoute, { withCredentials: true })
            .then((response) => {
                const featureListData = response.data.featureList;
                console.log(featureListData);
                setRawFeatureList(featureListData);
            })
            .catch((error) => {
                //
                //toast.error(error.response.data.msg);
            });
    }

    const getFeatureListDropDownData = () => {
        const featureDropDownData = [];
        featureDropDownData.push({ id: 0, value: '', label: 'None' });
        for (let i = 0; i < rawFeatureList.length; i++) {
            featureDropDownData.push({ id: rawFeatureList[i]._id, value: rawFeatureList[i].varKey, label: rawFeatureList[i].featureName });
        }
        return featureDropDownData;
    }

    useEffect(() => {
        fetchUserList();
        fetchFeatureList();
    }, [])

  
    // -----> End of list manipulation stuff





    const [startedStages, setStartedStages] = useState([1]);

    const [campaignName, setCampaignName] = useState('');
    const [campaignNameError, setCampaignNameError] = useState('');
    const [sfLink, setSFLink] = useState('');
    const [sfLinkError, setSFLinkError] = useState('');
    const [sfNumber, setSFNumber] = useState(0);
    const [sfNumberError, setSFNumberError] = useState('');
    const [selectedRegion, setSelectedRegion] = useState(null);
    const updateRegionHandler = (e, dropDownId, item) => {
        setSelectedRegion(item);
    }
    const [selectedFeature, setSelectedFeature] = useState(null);
    const updateFeatureHandler = (e, dropDownId, item) => {
        console.log('Feature selected: ', item);
        setSelectedFeature(item);
    }
    const campaignTierData = getCampaignTierFormData();
    const [campaignLevel, setCampaignLevel] = useState(campaignTierData[0].value);
    const updateLevelHandler = (e, id, val) => {
        setCampaignLevel(val);
    }

    const [draftCampaign, setDraftCampaign] = useState(false);
    const toggleDraftCampaign = (e) => {
        e.preventDefault();
        setDraftCampaign((val) => !val);
    }

    const [clientName, setClientName] = useState('');
    const [clientNameError, setClientNameError] = useState('');

    const [streamerHandle, setStreamerHandle] = useState('');
    const [streamerHandleError, setStreamerHandleError] = useState('');
    const [streamDiscord, setStreamDiscord] = useState('');
    const [streamDiscordError, setStreamDiscordError] = useState('');

    const [streamDate, setStreamDate] = useState(null);
    if (streamDate === null) {
        // We need to set a default date that is 00:00 our time...
        let defaultDate = new Date();
        defaultDate.setHours(0, 0, 0, 0);
        setStreamDate(defaultDate);
    }

    const dateSelected = (id, val) => setStreamDate(val);

    const [startTime, setStartTime] = useState(0);
    const [endTime, setEndTime] = useState(0);

    const [tzOptions, setTzOptions] = useState(getTimezoneOptions());


    const reevaluateTimezoneOptions = (selectedTimeZone = null) => {
        const utcUnix_startTime = convertFormDateTimeTimezoneToUnixTimestamp(streamDate, startTime, selectedTimeZone || timezone);
        const evaluatedOptions = getTimezoneOptions(utcUnix_startTime);
        if (tzOptions !== evaluatedOptions) {
            const currentTimezone = selectedTimeZone || timezone;
            setTzOptions(evaluatedOptions);
            // Settings TzOptions can re-order the list!
            setTimezone(parseTimezone(currentTimezone.value));
        }
    }

    const [timezone, setTimezone] = useState(getDefaultTimeZoneListData(tzOptions));
    const selectTimeZone = (e, id, item) => {
        reevaluateTimezoneOptions(item);
    }

    useEffect(() => {
        reevaluateTimezoneOptions();
    }, [streamDate, startTime]);

    const [addedStreams, setAddedStreams] = useState([]);
    const clearStreams = () => {
        setAddedStreams([]);
    }
    const clearStreamForm = () => {
        setStreamerHandle('');
        setStreamDiscord('');
    }
    const [showSelectUser, setShowSelectUser] = useState(false);
    const toggleShowSelectUser = (e) => {
        if (e) {
            e.preventDefault();
        }
        setShowSelectUser(!showSelectUser);
    }
    const userSelected = (user) => {
        console.log(user);
        setStreamerHandle(user.name);
        if (user.discord) {
            setStreamDiscord(user.discord);
        } else {
            setStreamDiscord('');
        }
    }
    const addStream = (e) => {
        e.preventDefault();

        let formValid = true;
        if (streamerHandle === '') {
            setStreamerHandleError('You need to enter your streamer\'s Twitch Handle');
            formValid = false;
        } else {
            setStreamerHandleError('');
        }

        if (formValid) {
            let utcUnix_startTime = convertFormDateTimeTimezoneToUnixTimestamp(streamDate, startTime, timezone);
            let utcUnix_endTime = convertFormDateTimeTimezoneToUnixTimestamp(streamDate, endTime, timezone);

            // Not sure exactly what we want to store on the server, 
            // but for ease of displaying the list list / editing I have the dates / times in various formats
            const streamData = {
                streamerHandle: streamerHandle,
                // Default profile pic - we are going to pull in the proper one in the background, but just in case that fails, we at least have something
                streamerProfilePic: 'https://static-cdn.jtvnw.net/user-default-pictures-uv/de130ab0-def7-11e9-b668-784f43822e80-profile_image-300x300.png',
                streamDiscord: streamDiscord,
                streamDate: streamDate,
                startTime: startTime,
                endTime: endTime,
                timezone: timezone,
                utcUnixTimeStampStart: utcUnix_startTime,
                utcUnixTimeStampEnd: utcUnix_endTime,
                dateObjStart: new Date(utcUnix_startTime),
                dateObjEnd: new Date(utcUnix_endTime),
                timeZoneDiff: timezone.offset,
            }

            addedStreams.push(streamData);
            setAddedStreams(addedStreams);
            clearStreamForm();

            // Get out profile pix for this user
            axios
                .get(getProfilePicRoute, { params: { twitchHandle: streamerHandle } })
                .then((response) => {
                    console.log('Profile pic: ', response);
                    if (response.data.profilePic) {
                        streamData.streamerProfilePic = response.data.profilePic;
                    }
                });

            console.log('Added stream: ', addedStreams);
        }
    }
    const removeStream = (stream) => {
        const updatedStreamList = [];
        for (let i = 0; i < addedStreams.length; i++) {
            if (addedStreams[i] !== stream) {
                updatedStreamList.push(addedStreams[i]);
            }
        }
        setAddedStreams(updatedStreamList);
    }

    const continueBtnHandler = (e) => {
        e.preventDefault();

        // a bit of validation...
        let validationPassed = true;
        switch (stage) {
            case 1:
                if (campaignName === '') {
                    setCampaignNameError('Please enter a name for your campaign');
                    validationPassed = false;
                } else {
                    setCampaignNameError('');
                }
                if (clientName === '') {
                    setClientNameError('Please enter a name for your client');
                    validationPassed = false;
                } else {
                    setClientNameError('');
                }
                if (sfLink === '') {
                    setSFLinkError('Please enter a Salesforce link');
                    validationPassed = false;
                } else {
                    setSFLinkError('');
                }
                if (sfNumber <= 0) {
                    setSFNumberError('Please enter a Salesforce number');
                    validationPassed = false;
                } else {
                    setSFNumberError('');
                }
                break;
            default:
                break;
        }

        if (validationPassed) {
            const updatedStartedStages = startedStages;
            updatedStartedStages.push(stage + 1);
            setStartedStages(updatedStartedStages);
            setStage(stage + 1);
        }
    }

    const submitNewCampaignHandle = (e) => {
        e.preventDefault();

        const clientIds = [];
        for (let i = 0; i < selectedClientsUserData.length; i++) {
            clientIds.push(selectedClientsUserData[i]._id);
            
        }
        const producerIds = [];
        for (let i = 0; i < selectedProducersUserData.length; i++) {
            producerIds.push(selectedProducersUserData[i]._id);
            
        }

        //Add stream data to the new campaign
        const streams = []
        for (let i = 0; i < addedStreams.length; i++) {
            let addedStream = addedStreams[i];
            let streamData = {
                status: STREAM_STATUS_TYPES.scheduled.dbString,
                demoMode: true,
                twitchHandle: addedStream.streamerHandle,
                channels: [{ twitchHandle: addedStream.streamerHandle, profilePic: addedStream.streamerProfilePic }],
                discord: addedStream.streamDiscord,
                startTime: addedStream.utcUnixTimeStampStart,
                endTime: addedStream.utcUnixTimeStampEnd,
                timeZone: addedStream.timezone.value,
                livePIN: Math.random().toString(36).substring(2, 8),
                archived: false
            }
            streams.push(streamData);
        }

        axios
            .post(createCampaignRoute, {
                campaignName: campaignName,
                campaignLevel: campaignLevel,
                draftCampaign: draftCampaign,
                selectedRegion: selectedRegion ? selectedRegion.id : 'any',
                clientName: clientName,
                clients: clientIds,
                campaignOwner: authContext.userData._id,
                campaignOwnerName:authContext.userData.firstName + ' ' + authContext.userData.lastName + (authContext.userData.authStrategy == 'twitch' ? '('+ authContext.userData.name +')' : ''),
                producers: producerIds,
                sfLink: sfLink,
                sfNumber: sfNumber,
                streams: streams,
                features: selectedFeature ? [{ feature: selectedFeature.id, varKey: selectedFeature.value, countIn: false, countInTime: 10 }] : [],
                chains: [[]],
            })
            .then(function (response) {
                console.log('Campaign created: ', response);
                toast.success("Campaign Created!");
                // Should this navigate to the new campaign? If so then the reponse needs to return the new campaign id
                navigate("/");
            })
            .catch(function (error) {
                // setIsSubmitting(false);
                toast.error('Error - ' + error.response.data.message);
                // console.log(error.response)
            });
        //}
    };

    return (
        <>
            <div className="create-campaign-content">
                <div className="content-panel form-holder">
                    <ClosePanelBtn clickFunc={cancelCreateCampaign} />
                    <div className="fl-row">
                        <h1>Create Campaign</h1>
                        {startedStages.length > 1 &&
                            <div className='tier-badge'>
                                <img src={'/assets/tier-badges/badge-fill-' + CAMPAIGN_TIERS[campaignLevel].imgString + '.svg'} alt="" />
                            </div>
                        }
                        {/* <div className={`tier-pill ${campaignLevel}`}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.star, IconJsxer.ICON_STYLES.standard)}
                                {(campaignLevel === 'gold' || campaignLevel === 'platinum') &&
                                    IconJsxer.GetIcon(IconJsxer.ICONS.star, IconJsxer.ICON_STYLES.standard)
                                }
                                {campaignLevel === 'platinum' &&
                                    IconJsxer.GetIcon(IconJsxer.ICONS.star, IconJsxer.ICON_STYLES.standard)
                                }
                            </div> */}

                    </div>
                    <CreateCampaignProgressBar stage={stage} setStageFunc={setStage} startedStages={startedStages} />
                    {stage === 1 &&
                        <>
                            <FormField id="campaign_name" type="text" label="Campaign Name" error={campaignNameError} placeholder="Campaign Name" setFunc={setCampaignName} value={campaignName} className="restrict-width" />
                            <FormField id="client_name" type="text" label="Client Name" error={clientNameError} placeholder="Client Name" setFunc={setClientName} value={clientName} className="restrict-width" />
                            <FormDropDown
                                label="Region"
                                id="region"
                                selectFunc={updateRegionHandler}
                                currentIndex={selectedRegion ? selectedRegion.index : 0}
                                items={
                                    getRegionsDropDownListData()
                                }
                                className="restrict-width"
                            />

                            <RadioButtonGroup label="Campaign Level" selectFunc={updateLevelHandler} className="restrict-width" horizontal={true} options={campaignTierData} selectedOption={campaignLevel} />
                            <p className="label">Draft Campaign?</p>
                            <div className="fl-column">
                                <div className="fl-row">
                                    <div className="shrink"><Toggle id="draftcampaign" currentState={draftCampaign} toggleFunc={toggleDraftCampaign} /></div>
                                    <div className="light-text small-text tight grow">Draft campaigns can easily be hidden from the main campaign list.<br />You should use this setting for test campaigns and experiments, you can change this later.</div>
                                </div>
                                <div></div>
                            </div>
                            <FormField id="salesfoce_number" type="number" label="Salesforce Number" placeholder="000000" setFunc={setSFNumber} value={sfNumber} error={sfNumberError} className="restrict-width" />
                            <FormField id="salesforce_link" type="text" label="Salesforce Link" placeholder="Salesforce Link" setFunc={setSFLink} value={sfLink} error={sfLinkError} className="restrict-width" />

                        </>
                    }


                    {stage === 2 &&
                        <>
                            <div className="fl-row">
                                <div className="grow">
                                    <p className="label">Add Existing External Contacts, including Streamers and Clientside Contacts</p>
                                    <p className="small-text light-text">Note: You can invite new contacts, plus add or remove contacts, once your campaign is created</p>
                                </div>
                                <SearchBox searchFunc={searchClientUserList} initialSearchTerm={clientSearchTerm} />
                            </div>

                            <div className="split-content">

                                <div className="pane">

                                    <div className="scroll-panel">
                                        <p className="label light-text">Available Users</p>
                                        <div className="scroll-container">
                                            {availableUsers.map(
                                                (val, i) => {
                                                   
                                                        return (
                                                            <ListedCampaignUser user={val} selected={val.selected} toggleSelectedFunc={toggleUserSelected} showCheck={true} key={'lcu_client_' + i} />
                                                        )
                                                   
                                                }
                                            )}
                                            {availableUsers.length == 0 && <p>No Users Found!</p>}
                                            
                                        </div>
                                        <ReactPaginate
                                                activeClassName={'pagination-item item-active '}
                                                breakClassName={'pagination-item break-me '}
                                                breakLabel={'...'}
                                                containerClassName={'pagination'}
                                                disabledClassName={'disabled-page'}
                                                marginPagesDisplayed={2}
                                                nextClassName={"pagination-item  "}
                                                nextLabel={IconJsxer.GetIcon(IconJsxer.ICONS.chevronRight, IconJsxer.ICON_STYLES.settingsEdit)}
                                                onPageChange={handlePageClick}
                                                pageCount={numberOfPages}
                                                forcePage={currentPage}
                                                pageClassName={'pagination-item pagination-page '}
                                                pageRangeDisplayed={2}
                                                previousClassName={"pagination-item  "}
                                                previousLabel={IconJsxer.GetIcon(IconJsxer.ICONS.chevronLeft, IconJsxer.ICON_STYLES.settingsEdit)}
                                                renderOnZeroPageCount={false}
                                            />
                                    </div>
                                    
                                </div>

                                <div className="pane">
                                    <div className="scroll-panel">
                                        <div className="fl-row">
                                            <p className="label light-text">Selected Users</p>
                                            <div className="grow"></div>
                                            <div className="fl-row remove-option" onClick={clearAllSelectedClients}>
                                                <p>Clear all</p>
                                                {IconJsxer.GetIcon(IconJsxer.ICONS.closeX, IconJsxer.ICON_STYLES.acPillIcon)}
                                            </div>
                                        </div>
                                        <div className="scroll-container">
                                            {selectedClientsUserData.map(
                                                (val, i) => {
                                                    /* Feels weird having this side searchable...
                                                    if (searchedClientUserData.indexOf(val) >= 0) { */
                                                    return (
                                                        <div className="fl-row" key={'lcs_client_' + i}>
                                                            <ListedCampaignUser user={val} selected={val.selected} toggleSelectedFunc={toggleUserDeselected} showCheck={false} />
                                                            <div className="grow"></div>
                                                            <div className="remove-option" onClick={(e) => toggleUserDeselected(e, val, i)}>
                                                                {IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.roundPanelButtonSmall)}
                                                            </div>
                                                        </div>
                                                    )
                                                    /* } else {
                                                        return null;
                                                    } */
                                                }
                                            )}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </>
                    }

                    {stage === 3 &&
                        <>
                            <div className="fl-row">
                                <div className="grow">
                                    <p className="label">Add Existing Producers</p>
                                    <p className="small-text light-text">Note: You can invite new producers, plus add or remove producers, once your campaign is created</p>
                                </div>
                                <SearchBox searchFunc={searchClientUserList} initialSearchTerm={clientSearchTerm} />
                            </div>

                            <div className="split-content">

                                <div className="pane">

                                    <div className="scroll-panel">
                                        <p className="label light-text">Available Users</p>
                                        <div className="scroll-container">
                                            {availableUsers.map(
                                                (val, i) => {
                                                 
                                                        return (
                                                            <ListedCampaignUser user={val} selected={val.selected} toggleSelectedFunc={toggleUserSelected} showCheck={true} key={'lpu_client_' + i} />
                                                        )
                                                    } 
                                            )}
                                        </div>
                                        <ReactPaginate
                                                activeClassName={'pagination-item item-active '}
                                                breakClassName={'pagination-item break-me '}
                                                breakLabel={'...'}
                                                containerClassName={'pagination'}
                                                disabledClassName={'disabled-page'}
                                                marginPagesDisplayed={2}
                                                nextClassName={"pagination-item  "}
                                                nextLabel={IconJsxer.GetIcon(IconJsxer.ICONS.chevronRight, IconJsxer.ICON_STYLES.settingsEdit)}
                                                onPageChange={handlePageClick}
                                                pageCount={numberOfPages}
                                                forcePage={currentPage}
                                                pageClassName={'pagination-item pagination-page '}
                                                pageRangeDisplayed={2}
                                                previousClassName={"pagination-item  "}
                                                previousLabel={IconJsxer.GetIcon(IconJsxer.ICONS.chevronLeft, IconJsxer.ICON_STYLES.settingsEdit)}
                                                renderOnZeroPageCount={false}
                                            />
                                    </div>
                                </div>

                                <div className="pane">
                                    <div className="scroll-panel">
                                        <div className="fl-row">
                                            <p className="label light-text">Selected Users</p>
                                            <div className="grow"></div>
                                            <div className="fl-row remove-option" onClick={clearAllSelectedProducers}>
                                                <p>Clear all</p>
                                                {IconJsxer.GetIcon(IconJsxer.ICONS.closeX, IconJsxer.ICON_STYLES.acPillIcon)}
                                            </div>
                                        </div>
                                        <div className="scroll-container">
                                            {selectedProducersUserData.map(
                                                (val, i) => {
                                                    /* Feels weird having this side searchable...
                                                    if (searchedProducersUserData.indexOf(val) >= 0) { */
                                                    return (
                                                        <div className="fl-row" key={'lcs_client_' + i}>
                                                            <ListedCampaignUser user={val} selected={val.selected} toggleSelectedFunc={toggleUserSelected} showCheck={false} />
                                                            <div className="grow"></div>
                                                            {/* Don't allow the owner to be removed! */}
                                                            {val._id !== authContext.userData._id &&
                                                                <div className="remove-option" onClick={(e) => toggleUserSelected(e, val, i)}>
                                                                    {IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.roundPanelButtonSmall)}
                                                                </div>
                                                            }
                                                            {val._id === authContext.userData._id &&
                                                                <p>Owner (You)</p>
                                                            }
                                                        </div>
                                                    )
                                                    /* } else {
                                                        return null;
                                                    } */
                                                }
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                    {stage === 4 &&
                        <>
                            <div className="fl-row">
                                <div className="grow">
                                    <p className="label">Add Streams</p>
                                    <p className="small-text light-text">Note: You can add more streams and edit streams once your campaign is created</p>
                                </div>
                            </div>

                            <div className="split-content">

                                <div className="pane">
                                    <div className="form-holder co-popup-content">
                                        <div className="fl-row">
                                            <div className="grow">
                                                <FormField
                                                    type="text"
                                                    id="str_handle"
                                                    placeholder="Enter Twitch Handle"
                                                    setFunc={setStreamerHandle}
                                                    label="Streamer Twitch Handle"
                                                    error={streamerHandleError}
                                                    value={streamerHandle}
                                                />
                                            </div>
                                            <div>
                                                <button className="submit-btn" onClick={toggleShowSelectUser}>Select</button>
                                            </div>
                                        </div>
                                        <FormField
                                            type="text"
                                            id="str_discord"
                                            placeholder="Enter Discord"
                                            setFunc={setStreamDiscord}
                                            label="Stream Discord"
                                            error={streamDiscordError}
                                            value={streamDiscord}
                                        />

                                        <div className="label">Schedule Stream</div>
                                        <div className="fl-row drop-gap grow">
                                            <DateSelect
                                                id="stream_date"
                                                allowPast={false}
                                                initialDate={streamDate}
                                                selectFunc={dateSelected}
                                            />
                                        </div>

                                        <div className="fl-row drop-gap shift-up-1">
                                            <HMSTimeField
                                                label="Start"
                                                inlineLabel={true}
                                                showLabel={false}
                                                id="stream_start"
                                                value={startTime}
                                                setFunc={setStartTime}
                                                maxHours={23}
                                                showSeconds={false}
                                                relativeTime={false}
                                                narrow={true}
                                                centered={false}
                                                showError={false}
                                            />
                                            <div className="grow-100 j-center">until</div>
                                            <HMSTimeField
                                                label="Until"
                                                inlineLabel={true}
                                                showLabel={false}
                                                id="stream_end"
                                                value={endTime}
                                                setFunc={setEndTime}
                                                maxHours={23}
                                                showSeconds={false}
                                                relativeTime={false}
                                                narrow={true}
                                                centered={false}
                                                showError={false}
                                            />
                                        </div>
                                        <FormDropDown
                                            id="timezone"
                                            label="Timezone"
                                            showLabel={false}
                                            items={tzOptions}
                                            currentIndex={timezone.index}
                                            selectFunc={selectTimeZone}
                                            className="shift-up-1"
                                        />

                                        <button className='submit-btn button-active' onClick={addStream}>
                                            Add Stream
                                        </button>
                                    </div>
                                </div>

                                <div className="pane">
                                    <div className="scroll-panel">
                                        <div className="fl-row">
                                            <p className="label light-text">Added Streams</p>
                                            <div className="grow"></div>
                                            <div className="fl-row remove-option" onClick={clearStreams}>
                                                <p>Clear all</p>
                                                {IconJsxer.GetIcon(IconJsxer.ICONS.closeX, IconJsxer.ICON_STYLES.acPillIcon)}
                                            </div>
                                        </div>
                                        <div className="scroll-container">
                                            {addedStreams.map(
                                                (val, i) => {
                                                    return (
                                                        <ListedAddedStream stream={val} removeFunc={removeStream} key={'str_' + i} />
                                                    )
                                                }
                                            )}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </>
                    }

                    {stage === 5 &&
                        <>

                        {campaignLevel != 'basic' ?
                            <FormDropDown
                                label="Select Feature"
                                id="select_feature"
                                selectFunc={updateFeatureHandler}
                                currentIndex={selectedFeature ? selectedFeature.index : 0}
                                items={
                                    getFeatureListDropDownData()
                                }
                                className="restrict-width"
                            />
                            : <p> Features cannot be added to a T3 campaign</p>
                            }

                            <button className='submit-btn button-active continue-btn' onClick={submitNewCampaignHandle}>
                                Save Campaign
                            </button>
                        </>
                    }

                    {stage !== 5 &&
                        <button className='submit-btn button-active continue-btn' onClick={continueBtnHandler}>
                            Continue
                        </button>
                    }
                </div>
            </div>
            {showSelectUser &&
                <SelectUser closePanelFunc={toggleShowSelectUser} selectUserFunc={userSelected} twitchOnly={true} />
            }
        </>
    )
}

export default CreateCampaign
