import React, { useState, useEffect, useContext, useReducer } from "react";
import PropTypes from 'prop-types';
import validator from "validator";

import axios from "../../api/axios";

import DebugLogger from "../../helpers/DebugLogger";

import "./InviteUser.scss";
import "./TabbedInviteUser.scss";

import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import FormField from "../forms/FormField/FormField";
import DropDownList from "../forms/DropDownList/DropDownList";
import { ACCOUNT_TYPES, getAcDropListData, getAcTypeData } from "../../helpers/AccountTypes";
import { createUserInvitesRoute, userListAllUsersRoute, addCampaignUsersRoute, addUsersByEmailRoute } from "../../api/routes";
import { toast } from "react-toastify";
import { AuthContext } from "../../contexts/AuthContext";
import { CampaignContext } from "../../contexts/CampaignContext";
import TabbedPanel from "../TabbedPanel/TabbedPanel";
import ClosePanelBtn from "../ClosePanelBtn/ClosePanelBtn";
import SearchBox from "../forms/SearchBox/SearchBox";
import { PRIMERS, alphaTypePrimer, filterList, searchList } from "../../helpers/ListFunctions";
import ListedCampaignUser from "../../containers/CreateCampaign/ListedCampaignUser";
import FormDropDown from "../forms/DropDownList/FormDropDown";
import { DUMMY_USER } from "../../helpers/DummyData";

import ReactPaginate from 'react-paginate';
import { IconJsxer } from "../../helpers/IconHelper";

const TabbedInviteUser = (props) => {
    const [authContext, setAuthContext] = useContext(AuthContext);
    const [campaignContext, setCampaignContext] = useContext(CampaignContext);

    const acSettings = getAcTypeData(authContext.userData.accountType);
    const maxInviteLevel = acSettings.adminSettings.maxInviteLevel;

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    // set our default type
    const listData = getAcDropListData(
        [],
        maxInviteLevel,
        [ACCOUNT_TYPES.notset],
        false
    )
    let defaultAcType = null;
    if (props.defaultType) {
        for (let i = 0; i < listData.length; i++) {
            if (listData[i].id === props.defaultType || listData[i].id === props.defaultType.dbString) {
                listData[i].index = i;
                defaultAcType = listData[i];
                break;
            }
        }
    }
    const [selectedType, setSelectedType] = useState(defaultAcType);
    const selectTypeHandler = (e, i, item) => {
        setSelectedType(item);
    }

    const [currentTab, setCurrentTab] = useState(props.defaultTab !== undefined && props.defaultTab !== null ? props.defaultTab : 1);

    const sendInvite = (e) => {
        e.preventDefault();

        let formIsValid = true;
        const emails = email.replace(' ', '').split(',');

        for (let i = 0; i < emails.length; i++) {
            if (!validator.isEmail(emails[i])) {
                formIsValid = false;
                setEmailError("You have entered an invalid email");
                break;
            } else {
                setEmailError("");
            }
        }

        if (formIsValid) {

            console.log('AC TYPE:', props)
            let accountType = selectedType.id || props.acType.dbString  || ACCOUNT_TYPES.notset.dbString;
            setIsSubmitting(true);


            axios
            .post(addUsersByEmailRoute, {campaignID:props.campaignId, emails:emails, withCredentials:true})
            .then((response) =>{
                setCampaignContext((oldValues) => {
                    return { ...oldValues, initialising: false, campaignData: response.data.campaignData }
                })
                if(response.data.inviteEmailList.length > 0)
                {
                    axios
                    .post(createUserInvitesRoute, {
                        emails: response.data.inviteEmailList,
                        acType: accountType,
                        campaignIds: [props.campaignId],
                    })
                    .then(function (response) {
                        setIsSubmitting(false);
                        props.closePanelFunc();
        
                        if(response.data.success) {
                          toast.success(response.data.message);
                        } else {
                          toast.error(response.data.message);
                        }
                    })
                    .catch(function (error) {
                        setIsSubmitting(false);
                        toast.error('Error - An unexpected error occured');
                    });
                }
                else if(emails.length > 0)
                {
                    //TODO - gets here even if you add someone already in the campaign
                    setIsSubmitting(false);
                    toast.success("Users added to campaign!");
                }
            })
        }
    }

      /*********************************************************************
    * User List & Pagination
    * ******************************************************************/
      const [pageUpdate, forcePageUpdate] = useReducer(x => x + 1, 0);
      const [fetchUpdate, forceFetchUpdate] = useReducer(x => x + 1, 0);
      const [searchTerm, setSearchTerm] = useState('');
      const [availableUsers, setAvailableUsers] = useState([]);
      const [selectedClientsUserData, setSelectedClientsUserData] = useState(campaignContext.campaignData.clientAccounts);
      const [userFilter, setUserFilter] = useState({id:null});
      const [selectedProducersUserData, setSelectedProducersUserData] = useState(campaignContext.campaignData.producerAccounts);
      const [currentPage, setCurrentPage] = useState(0);
      const [numberOfPages, setNumberOfPages] = useState(0);
    const [isFetching, setIsFetching] = useState(false)
      const handlePageClick = (event) => {
          setCurrentPage(event.selected)
        };
  
      const searchUserList = (e, searchTerm, filteredList = null) => {
          setSearchTerm(searchTerm);
      }
  
      const fetchUserList = () => {
        console.log('IP:', props.isProducer)
          axios
              .get(userListAllUsersRoute, { params:{
                  page:currentPage, 
                  searchTerm:searchTerm, 
                  userType:props.isProducer ? {id:"all_producers"} : {id:"all_external"}, 
                  authType:null,
                  isTwitchOnly:false, 
                  sort:null,
                  reverse:null
              }, withCredentials: true })
              .then((response) => {
                  const userData = response.data.allusers;
                  console.log(response.data.allusers)
                  for (let i = 0; i < userData.length; i++) {
                    userData[i].selected = userData[i]._id == authContext.userData._id;
                    console.log('SELECTED LIUST', selectedClientsUserData)

                    for(let j=0; j<selectedClientsUserData.length; j++) {
                        if(userData[i]._id == selectedClientsUserData[j]._id)  userData[i].selected = true;
                        console.log(' IS SELECTED:', userData[i]._id, userData[i].selected)
                    }
                    for(let j=0; j<selectedProducersUserData.length; j++) {
                        if(userData[i]._id == selectedProducersUserData[j]._id)  userData[i].selected = true;
                        console.log(' IS SELECTED:', userData[i]._id, userData[i].selected)
                    }
                    
                }
                console.log('USER DATA', userData)
                  setAvailableUsers(userData)
                  setNumberOfPages(response.data.numberOfPages);
                  setIsFetching(false)
              })
              .catch((error) => {
                setIsFetching(false);
                  //toast.error(error.response.data.msg);
              });
      }
      
      useEffect(() => {
         forcePageUpdate();
      }, [userFilter, searchTerm])
  
      useEffect(() => {
          console.log('GET PAGE CP')
         if(!isFetching) fetchUserList();
         setIsFetching(true)
      }, [ currentPage, fetchUpdate])
  
      useEffect(() => {
          console.log('PAGE DATA UPDATED')
          currentPage == 0 ? forceFetchUpdate() : setCurrentPage(0);
         
      }, [pageUpdate])


      const toggleUserSelected = (e, user) => {
       
        user.selected = !user.selected;
        if(!user.selected)
        {
            let index = selectedClientsUserData.findIndex((x) => x._id === user._id);
            let newUsers = [...selectedClientsUserData.splice(0, index), ...selectedClientsUserData.splice(index + 1)]
            setSelectedClientsUserData(newUsers);
        }   
        else
        {
            //'ADD TO SELECT
            console.log('ADD TO SELECTED')
            setSelectedClientsUserData(previous => [...previous, user]) 
        }
    }


    const saveAddedUsers = (e) => {
        e.preventDefault();

        console.log('Save added users...');
        const usersToAdd = [];
        let userType = '';
        for (let i = 0; i < selectedClientsUserData.length; i++) {
            
                usersToAdd.push({_id:selectedClientsUserData[i]._id, accountType:selectedClientsUserData[i].acType});
            
        }

        console.log('Users to add: ', usersToAdd);
        setCampaignContext((oldValues) => {
            return { ...oldValues, initialising: true}
        })

        axios
        .post(addCampaignUsersRoute, {campaignID: props.campaignId, userIDs:usersToAdd, withCredentials: true})
        .then((response) => {
            console.log('SUCCESS', response.data.campaignData)
            setCampaignContext((oldValues) => {
                return { ...oldValues, initialising: false, campaignData: response.data.campaignData }
            })
               props.closePanelFunc();
        })


        //---- axios call to endpoint goes here...
    }



    return (
        <div className="blackout popup-panel-holder">
            <div className="tabbed-invite-panel-holder">

                <TabbedPanel closePanelFunc={props.closePanelFunc} tabs={!props.hideExisting? [{ label: 'Invite by email' }, { label: 'Invite existing users' }]:[{ label: 'Invite by email' }]} tabWidth='43%' switchTabCallback={(tabId, tabData) => setCurrentTab(tabId)} initialTab={currentTab}>
                    <>

                        {currentTab === 0 &&
                            <div className="form-holder tabbed-invite-user-content">
                                <form id="inviteform" className="form tabbed-invite-form">
                                    {props.title &&
                                        <h2>{props.title}</h2>
                                    }
                                    {props.subTitle &&
                                        <p className="light-text">{props.subTitle}</p>
                                    }
                                    <FormField
                                        type="email"
                                        id="email"
                                        placeholder="Enter Email"
                                        setFunc={setEmail}
                                        label="Email"
                                        labelNote="Note: seperate multiple email addresses using a comma"
                                        error={emailError}
                                    />
                                    {props.showAcTypeSelector &&
                                        <FormDropDown 
                                            id="usertype"
                                            selectFunc={selectTypeHandler}
                                            currentIndex={selectedType ? selectedType.index : 0}
                                            label="User Type"
                                            items={listData}
                                            enabled={true}
                                        />
                                    }
                                    {!props.showAcTypeSelector && props.acType !== null &&
                                        <>
                                            <div className="label">
                                                User Type
                                            </div>
                                            <p className="light-text indented">{props.acType ? props.acType.displayName : 'Not Set'}</p>
                                        </>
                                    }

                                    <div className="grow"></div>

                                    <button className={'submit-btn' + (isSubmitting ? ' button-inactive' : ' button-active')} onClick={isSubmitting ? null : sendInvite}>
                                        {isSubmitting ? "Sending Invites" : "Send Invite"}
                                    </button>
                                </form>
                            </div>
                        }

                        {currentTab === 1 &&
                            <div className="form-holder tabbed-invite-user-content">
                                <SearchBox searchFunc={searchUserList} />
                                <form id="inviteform" className="form tabbed-invite-form">

                                    <div className="scroll-container">
                                        {availableUsers.map(
                                            (val, i) => {
                                                return (
                                                    <ListedCampaignUser user={val} selected={val.selected} toggleSelectedFunc={toggleUserSelected} showCheck={true} key={'tiu_' + i} />
                                                )
                                            }
                                        )}
                                    </div>
                                    <ReactPaginate
                                                activeClassName={'pagination-item item-active '}
                                                breakClassName={'pagination-item break-me '}
                                                breakLabel={'...'}
                                                containerClassName={'pagination'}
                                                disabledClassName={'disabled-page'}
                                                marginPagesDisplayed={2}
                                                nextClassName={"pagination-item  "}
                                                nextLabel={IconJsxer.GetIcon(IconJsxer.ICONS.chevronRight, IconJsxer.ICON_STYLES.settingsEdit)}
                                                onPageChange={handlePageClick}
                                                pageCount={numberOfPages}
                                                forcePage={currentPage}
                                                pageClassName={'pagination-item pagination-page '}
                                                pageRangeDisplayed={2}
                                                previousClassName={"pagination-item  "}
                                                previousLabel={IconJsxer.GetIcon(IconJsxer.ICONS.chevronLeft, IconJsxer.ICON_STYLES.settingsEdit)}
                                                renderOnZeroPageCount={false}
                                            />

                                    <div className="grow"></div>

                                    <button className={'submit-btn' + (isSubmitting ? ' button-inactive' : ' button-active')} onClick={isSubmitting ? null : saveAddedUsers}>
                                        {isSubmitting ? "Saving" : "Save"}
                                    </button>
                                </form>
                            </div>
                        }

                    </>
                </TabbedPanel>

                {props.showCloseBtn && <ClosePanelBtn clickFunc={props.closePanelFunc} />}
            </div>
        </div>
    )
}

TabbedInviteUser.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    closePanelFunc: PropTypes.func.isRequired,
    campaignId: PropTypes.string,
    showAcTypeSelector: PropTypes.bool,
    acType: PropTypes.object,
    showCloseBtn: PropTypes.bool.isRequired,
    defaultType: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    acTypeFilter: PropTypes.array,
}

TabbedInviteUser.defaultProps = {
    title: '',
    subTitle: '',
    closePanelFunc: () => DebugLogger.error('You need to provide a closePanelFunc to InviteUser component.'),
    campaignId: '',
    showAcTypeSelector: true,
    acType: null,
    showCloseBtn: true,
    defaultType: null,
    acTypeFilter: [],
}

export default TabbedInviteUser;
