import React, { useContext, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import DebugLogger from "../../../helpers/DebugLogger";
import FormField from "../FormField/FormField";
import "./HMSTimeField.scss";
import { convertSecondsToHMS, getHoursDropDownListData, getMinsSecsDropDownListData, getRelativeHoursDropDownListData } from "../../../helpers/Dates";
import FormDropDown from "../DropDownList/FormDropDown";

const HMSTimeField = (props) => {
    const { label, showLabel, inlineLabel, labelNote, id, maxHours, setFunc, showError, error, value, relativeTime, showHours, showMinutes, showSeconds, narrow, centered, className } = props;

    if (showLabel && label === 'Field Label') {
        DebugLogger.warn('No label provided to HMSTimeField, using default...');
    }
    if (id === '') {
        DebugLogger.warn('No id provided to HMSTimeField, using a random id...');
    }
    if (setFunc === null) {
        DebugLogger.warn('You should provide a function called `setFunc(fieldVal)` to HMSTimeField.');
    }

    const [timeH, setTimeH] = useState(0);
    const [timeM, setTimeM] = useState(0);
    const [timeS, setTimeS] = useState(0);

    // Hms timefields don't care about timezones - timezone adjustments should be done before submitting to the server and after retrival if needed
    const updateTimeInSecs = (h, m, s) => {
        let timeInSecs = parseInt(s || 0) + parseInt((m || 0) * 60) + parseInt((h || 0) * 3600);
        if (setFunc) {
            setFunc(timeInSecs);
        }
        return timeInSecs;
    }

    const updateTimeH = (val) => {
        val = val >= 0 ? (val <= maxHours ? val : maxHours) : 0;
        updateTimeInSecs(val, timeM, timeS);
        setTimeH(val);
    }

    const updateTimeM = (val) => {
        val = val >= 0 ? (val <= 59 ? val : 59) : 0;
        updateTimeInSecs(timeH, val, timeS);
        setTimeM(val);
    }

    const updateTimeS = (val) => {
        val = val >= 0 ? (val <= 59 ? val : 59) : 0;
        updateTimeInSecs(timeH, timeM, val);
        setTimeS(val);
    }

    useEffect(
        () => {
            const setInitialHMS = (timeInSecs) => {
                // No timezone stuff here! But we do want to clamp times that are not relative
                let hmsTime = convertSecondsToHMS(timeInSecs, relativeTime);
                console.log('SET TIME', hmsTime)
                if (hmsTime.h !== timeH) {
                    setTimeH(hmsTime.h);
                }
                if (hmsTime.m !== timeM) {
                    setTimeM(hmsTime.m);
                }
                if (hmsTime.s !== timeS) {
             
                    setTimeS(hmsTime.s);
                }
            }

            setInitialHMS(value);
        },
        [value]
    );

    return (
        <div className={`grow${className ? ' ' + className : ''}`}>

            {showLabel && !inlineLabel && label != null &&
                <div className="form-field-content label">
                    {label}
                </div>
            }
            {labelNote != null && labelNote !== '' &&
                <div className="form-field-content label-note" dangerouslySetInnerHTML={{__html: labelNote}}>
                    {/*labelNote*/}
                </div>
            }

            <div className="hms-time-fields-holder">
                {showLabel && inlineLabel && label != null &&
                    <div className="form-field-content label">
                        {label}
                    </div>
                }
                {centered &&
                    <div className="grow"></div>
                }
                {showHours &&
                    <>
                        <FormDropDown
                            id={id + '_h'}
                            showLabel={false}
                            showErrors={false}
                            className={`hms-time-field${narrow ? '-narrow' : ''}`}
                            items={relativeTime ? getRelativeHoursDropDownListData(narrow ? '' : ' hrs') : getHoursDropDownListData(narrow ? '' : ' hrs')}
                            currentIndex={timeH}
                            selectFunc={(e, id, val) => updateTimeH(val.value)}
                        />
                    </>
                }
                {showMinutes &&
                    <>
                        {/* If centered this isn't used, if NOT centered it adds a massive gap between controls{showHours &&
                            <div className={`label${!centered ? '' : ' grow j-center'}`}>:</div>
                        } */}
                        <FormDropDown
                            id={id + '_m'}
                            showLabel={false}
                            showErrors={false}
                            className={`hms-time-field${narrow ? '-narrow' : ''}`}
                            items={getMinsSecsDropDownListData(narrow ? '' : ' mins')}
                            currentIndex={timeM}
                            selectFunc={(e, id, val) => updateTimeM(val.value)}
                        />
                    </>
                }
                {showSeconds &&
                    <>
                        {/* {showMinutes &&
                            <div className={`label${centered ? '' : ' grow j-center'}`}>:</div>
                        } */}
                        <FormDropDown
                            id={id + '_s'}
                            showLabel={false}
                            showErrors={false}
                            className={`hms-time-field${narrow ? '-narrow' : ''}`}
                            items={getMinsSecsDropDownListData(narrow ? '' : ' secs')}
                            currentIndex={timeS}
                            selectFunc={(e, id, val) => updateTimeS(val.value)}
                        />
                    </>
                }
                {centered &&
                    <div className="grow"></div>
                }
            </div>

            {showError &&
                <div className="error">
                    {error}&nbsp;
                </div>
            }

        </div>
    )
}

HMSTimeField.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    showLabel: PropTypes.bool,
    inlineLabel: PropTypes.bool,
    labelNote: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    id: PropTypes.string.isRequired,
    maxHours: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    showHours: PropTypes.bool,
    showMinutes: PropTypes.bool,
    showSeconds: PropTypes.bool,
    relativeTime: PropTypes.bool,
    narrow: PropTypes.bool,
    centered: PropTypes.bool,
    showError: PropTypes.bool,
    error: PropTypes.string.isRequired,
    className: PropTypes.string,
    setFunc: PropTypes.func,
};

HMSTimeField.defaultProps = {
    label: 'Field Label',
    showLabel: true,
    inlineLabel: false,
    labelNote: '',
    id: '',
    maxHours: 9999,
    value: 0,
    showSeconds: true,
    showHours: true,
    showMinutes: true,
    relativeTime: true,
    narrow: false,
    centered: true,
    showError: true,
    error: '',
    className: '',
    setFunc: null,
}

export default HMSTimeField;
