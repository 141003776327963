import axiosDefault from "axios";

const isProduction = process.env.NODE_ENV === "production";

// Exported the baseURL so we can reference it elsewhere, but we might want to think about moving this...

const defaultOptions = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials:true,
  headers: {
    "Content-Type": "application/json",
    'Access-Control-Allow-Origin': process.env.REACT_APP_AXIOS_ORIGIN,
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
  },
};

const axios = axiosDefault.create(defaultOptions);
axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  (response) => {
    // console.log('Interceptor response: ', response);
    return response;
  },
  (error) => {
    // Reload on 401 errors, as long as it's not refreshToken that failed!
    // Should cause us to get new tokens...
    try {
      if (error.response.request.responseURL.indexOf('refreshToken') === -1 && error.response.status === 401) {
        // console.log('401! Reload...');
        window.location.reload();
      }
    } catch (e) {
      console.warn('Axios Interceptor: ', e);
    }
    // console.log('Interceptor error: ', error);
    return Promise.reject(error);
  }
)

export default axios;
