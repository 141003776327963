import "./BugReports.scss";
import { useContext, useEffect, useState, } from "react";
import DebugLogger from "../../helpers/DebugLogger";
import ListedBugReport from "./ListedBugReport";
import EditBugReport from "./EditBugReport";
import { IconJsxer } from "../../helpers/IconHelper";
import axios from "../../api/axios";
import { bugReportListRoute, bugReportPrioritiesAndStatusesRoute, getDeveloperListRoute, bugReportUpdateAssignedToRoute, bugReportUpdatePriorityRoute, bugReportUpdateStatusRoute } from "../../api/routes";
import { toast } from "react-toastify";
import DropDownList from "../../components/forms/DropDownList/DropDownList";
import { ACCOUNT_TYPES, getAcDropListData, getAcTypeData } from "../../helpers/AccountTypes";
import SearchBox from "../../components/forms/SearchBox/SearchBox";
// import ChangeUserType from "./ChangeUserType"; //probably leave all edits in view imported above EditBugReport
import { AuthContext } from "../../contexts/AuthContext";
import { alphaTypePrimer, nullOrAlphaTypePrimer, filterList, customListPrimer, searchList, sortListOn, toStringPrimer, numberTypePrimer } from "../../helpers/ListFunctions";
import Toggle from "../../components/forms/Toggle/Toggle";
import { BUG_REPORT } from "../../components/SettingsPop/SettingsPop";

const BugReports = () => {
    const [authContext, setAuthContext] = useContext(AuthContext);
    const acSettings = getAcTypeData(authContext.userData.accountType);

    const SORT_TYPES = {
        PRIORITY: "priority",
        TYPE: "type",
        TITLE: "title",
        ASSIGNED_TO: "assignedTo",
        REPORTER: "reporter",
        STATUS: "status",
        CREATED: "dateCreated",
    }
    const [priorities, setPriorities] = useState([]);
    const [developers, setDevelopers] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [allBugReportData, setAllBugReportData] =  useState([]);
    const [displayBugReportData, setDisplayBugReportData] =  useState([]);
    const [filteredDisplayList, setFilteredDisplayList] =  useState([]);
    const [priorityFilter, setPriorityFilter] = useState(null);
    const [showCompletedStatusFilter, setShowCompletedStatusFilter] = useState(false);
    const [assignedToFilter, setAssignedToFilter] = useState(null);
    const [sortListReverse, setSortListReverse] = useState(false);
    const [currentSort, setCurrentSort] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [shouldUpdateList, setShouldUpdateList] = useState(false);
    const [hasPerformedDefaultSort, setHasPerformedDefaultSort] = useState(false);
    const [editData, setEditData] = useState(null);

    const [bugReportToEdit, setBugReportToEdit] = useState(null);

    const listTracker = [];

    const prepareEditData = (data) => {
        setEditData(data);
        if(data == null) {
            setShouldUpdateList(true);
        }
    }

    const openEditBugReport = (bugReport) => {
        setBugReportToEdit(bugReport);

        //EditBugReport being closed
        if(bugReport === null) {
            setShouldUpdateList(true);
        }
    }

    const changePriorityHandler = (e, id, priority) => {
        axios
            .post(bugReportUpdatePriorityRoute, { bugReportID:id, priority:priority, withCredentials: true})
            .then(response => {
                if(response.data.success) {
                    setShouldUpdateList(true);
                    toast.success("Priority changed to " + response.updatedBugReport.priority);
                } else {
                    toast.error("Failes to change the priority");
                }
            })
            .catch(err => {
                DebugLogger.log("changePriorityHandler error",err)
            })
    }
    const changeAssignedToHandler = (e, id, assignedTo) => {
        axios
            .post(bugReportUpdateAssignedToRoute, { bugReportID:id, assignedTo:assignedTo, withCredentials: true})
            .then(response => {
                if(response.data.success) { 
                    setShouldUpdateList(true);
                    toast.success("Bug now assigned to " + response.data.updatedBugReport.assignedTo.name);
                } else {
                    toast.error("Failed to change assigned developer");
                }
            })
            .catch(err => {
                DebugLogger.log("changePriorityHandler error",err)
            })
    }
    const changeStatusHandler = (e, id, status) => {
        axios
            .post(bugReportUpdateStatusRoute, { bugReportID:id, status:status, withCredentials: true})
            .then(response => {
                if(response.data.success) {
                    setShouldUpdateList(true);
                    toast.success("Bug status changed to " + response.data.updatedBugReport.status);
                } else {
                    toast.error("Failed to change the status");
                }
            })
            .catch(err => {
                DebugLogger.log("changePriorityHandler error",err)
            })
    }

    const fetchPrioritiesAndStatuses = () => {
        axios
            .get(bugReportPrioritiesAndStatusesRoute, {withCredentials: true})
            .then(response => {
                setPriorities(response.data.priorities);
                setStatuses(response.data.statuses);

                setShowCompletedStatusFilter(false);
                setShouldUpdateList(true);
            })
            .catch(err => {
                DebugLogger.log("fetchPriorities err = ",err);
            })
    }
    const fetchDevelopersList = () => {
        axios
            .get(getDeveloperListRoute, {withCredentials: true})
            .then(response => {
                setDevelopers(response.data);
            })
            .catch(err => {
                DebugLogger.log("fetch developers error",err);
            })
    }

    const fetchBugReportList = () => {
        axios
        .get(bugReportListRoute, {withCredentials: true})
        .then((response) => {

            let sortedBugReportList = response.data.sort((a, b) => {
                let priorityA, priorityB;
                for(var key in priorities) {
                    let priority = priorities[key];
                    if(priority.id == a.priority) {
                        priorityA = priority.index;
                    }
                    if(priority.id == b.priority) {
                        priorityB = priority.index;
                    }

                }
                return priorityA - priorityB;
            })

            setAllBugReportData(sortedBugReportList);
            setDisplayBugReportData(sortedBugReportList);
        })
        .catch((error) => {
          //
          //toast.error(error.response.data.msg);
        });
    }

    const sortList = (event, type) => {

        event.preventDefault();

        let reverse = !sortListReverse;
        setSortListReverse(reverse);

        let sortedBugReportArray = displayBugReportData.concat();

        switch (type){
            case SORT_TYPES.PRIORITY:
                sortListOn(sortedBugReportArray, 'priority', reverse, (val) => { return customListPrimer(val, priorities) });
            break;
            case SORT_TYPES.TYPE:
                sortListOn(sortedBugReportArray, 'type', reverse, alphaTypePrimer);
            break;
            case SORT_TYPES.TITLE:
                sortListOn(sortedBugReportArray, 'title', reverse, alphaTypePrimer);
            break;
            case SORT_TYPES.ASSIGNED_TO:
                sortListOn(sortedBugReportArray, ['assignedTo','name'], reverse, nullOrAlphaTypePrimer);
            break;
            case SORT_TYPES.REPORTER:
                sortListOn(sortedBugReportArray, ['reporter','name'], reverse, nullOrAlphaTypePrimer);
            break;
            case SORT_TYPES.STATUS:
                sortListOn(sortedBugReportArray, 'status', reverse, (val) => { return customListPrimer(val, statuses) });
            break;
            case SORT_TYPES.CREATED:
                sortListOn(sortedBugReportArray, 'dateCreated', reverse, numberTypePrimer);
            break;
            default:
            break;
        }

        setCurrentSort(type)
        setDisplayBugReportData(sortedBugReportArray);
    }

    const filterDisplayList = () =>{
        let tempFilterArr = allBugReportData.concat();
        if(assignedToFilter && assignedToFilter.id != 'all')
        {
            tempFilterArr = filterList(tempFilterArr, ['assignedTo', '_id'], assignedToFilter.id);
        }
        if(priorityFilter && priorityFilter.id != 'all')
        {
            tempFilterArr = filterList(tempFilterArr, ['priority'], priorityFilter.id);
        }
        if(showCompletedStatusFilter === false) {
            tempFilterArr = tempFilterArr.filter(it=> it.status != "Complete");
        }

        setDisplayBugReportData(tempFilterArr);
        //setCurrentSort(null);
        setSearchTerm('');
        return tempFilterArr;
    }

    const searchBugReportList = (e, searchTerm) => {
        let filteredList = filterDisplayList();
        setSearchTerm(searchTerm);
        if (searchTerm !== '') {
            let searchedList = searchList(filteredList, ["title", "type", "bugReportCode"], searchTerm, alphaTypePrimer);
            setDisplayBugReportData(searchedList);
        }
    }

    const refreshBugReportList = () => {
        searchBugReportList(null, searchTerm);
    }

    useEffect(() => {
        //get data for dropdowns
        fetchPrioritiesAndStatuses();
        fetchDevelopersList();
        setCurrentSort(SORT_TYPES.PRIORITY);
    }, []);

    useEffect(() => {
        if(shouldUpdateList) {
            fetchBugReportList();
            setShouldUpdateList(false);
        }

    }, [shouldUpdateList])

    useEffect(() => {
        if(bugReportToEdit == null) {
            prepareEditData(null);
        }
    },[bugReportToEdit])

    useEffect(() => {
        filterDisplayList();
    }, [assignedToFilter, priorityFilter, showCompletedStatusFilter, allBugReportData])

    const updateFilterHandler = async (e, id, item) => {
        switch (id) {
            case 'priority':
                setPriorityFilter(item);
                break;
            case 'assignedTo':
                if(item.id == "only_you") {
                    let itemOnlyYou = item;
                    itemOnlyYou.id = authContext.userData._id;
                    setAssignedToFilter(itemOnlyYou);
                } else {
                    setAssignedToFilter(item);
                }
                break;
            case 'showCompleted':
                setShowCompletedStatusFilter(!showCompletedStatusFilter);
                break;
            default:
                break;
        }
    }


    return (
        <div className="content-page-container">
            <div className="content-page-top">
                <div className="fl-column grow">
                    <div className="fl-row grow">
                        <div>
                            <h1>Bug Reports</h1>
                        </div>
                        <div className="grow"></div>
                        <div className="bugreports-search">
                            <SearchBox searchFunc={searchBugReportList} />
                        </div>
                    </div>
                    <div className="fl-row grow">
                        <div className="filters-container filters-container-extra-margin form-holder fl-row grow">
                            <DropDownList 
                                id="priority"
                                selectFunc={updateFilterHandler}
                                currentIndex={priorityFilter ? priorityFilter.index : 0}
                                deselectedLabel="Priority"
                                icon="priority"
                                items={
                                      [
                                        {   
                                            id: 'all',
                                            label: 'All Priorities',
                                            deselect: true,
                                        },
                                        {
                                          id: 'High',
                                          label: "High"
                                        },
                                        {
                                          id: 'Medium',
                                          label: "Medium"
                                        },
                                        {
                                          id: 'Low',
                                          label: "Low"
                                        },
                                        {
                                          id: 'Wishlist',
                                          label: "Wishlist"
                                        }
                                      ]
                                }
                                className="filter"
                            />
                            <DropDownList 
                                id="assignedTo" 
                                selectFunc={updateFilterHandler}
                                currentIndex={assignedToFilter ? assignedToFilter.index : 0}
                                deselectedLabel="Assigned To"
                                icon="oneUser"
                                items={
                                    [
                                        {
                                            id: 'all',
                                            label: 'See All',
                                            deselect: true,
                                        },
                                        {
                                            id: 'only_you',
                                            label: 'Assigned to me'
                                        }
                                    ]
                                }
                                className="filter"
                            />
                            {searchTerm !== '' && <div className="light-text">Searched for: {searchTerm} ({displayBugReportData.length} result{displayBugReportData.length > 1 ? 's' : ''}) <span className="small-text">[<a href="#" onClick={(e) => {e.preventDefault(); searchBugReportList(e, '');}}>Clear Search</a>]</span></div>}
                            <div className="grow"></div>
                            <div className="fl-row shrink">
                                <label htmlFor="showCompleted">Show Completed:</label>
                                <Toggle
                                    id="showCompleted"
                                    currentState={showCompletedStatusFilter}
                                    label="Show Completed"
                                    toggleFunc={updateFilterHandler}
                                    enabled={true}
                                />
                            </div>
                            <div className="fl-row shrink">
                                <button class="standard-button" onClick={ () => {BUG_REPORT.sendNewBugReport && BUG_REPORT.sendNewBugReport()} }>Report a Bug</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
            <div className="content-page-content-container">
                <div className="content-page-main-content">

                    <div className="bugreports-content">

                        <div className="bugreports-panel">
                            <div className="scroll-container">
                                <div className="grid">
                                    <div className="header label" onClick={(event) => {sortList(event, SORT_TYPES.TITLE)}}>Title {currentSort === SORT_TYPES.TITLE ? (IconJsxer.GetIcon(sortListReverse ? IconJsxer.ICONS.chevronUp : IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.roundFormControl)) : ''}</div>
                                    <div className="header label" onClick={(event) => {sortList(event, SORT_TYPES.TYPE)}}>Type {currentSort === SORT_TYPES.TYPE ? (IconJsxer.GetIcon(sortListReverse ? IconJsxer.ICONS.chevronUp : IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.roundFormControl)) : ''}</div>
                                    <div className="header label">Reference Code</div>
                                    <div className="header label">Description</div>
                                    <div className="header label" onClick={(event) => {sortList(event, SORT_TYPES.PRIORITY)}}>Priority {currentSort === SORT_TYPES.PRIORITY ? (IconJsxer.GetIcon(sortListReverse ? IconJsxer.ICONS.chevronUp : IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.roundFormControl)) : ''}</div>
                                    <div className="header label" onClick={(event) => {sortList(event, SORT_TYPES.ASSIGNED_TO)}}>Assigned to {currentSort == SORT_TYPES.ASSIGNED_TO ? (IconJsxer.GetIcon(sortListReverse ? IconJsxer.ICONS.chevronUp: IconJsxer.ICONS.chevronDown, IconJsxer.ICONS.roundFormControl)) : ''}</div>
                                    <div className="header label" onClick={(event) => {sortList(event, SORT_TYPES.REPORTER)}}>Reported by {currentSort == SORT_TYPES.REPORTER ? (IconJsxer.GetIcon(sortListReverse ? IconJsxer.ICONS.chevronUp: IconJsxer.ICONS.chevronDown, IconJsxer.ICONS.roundFormControl)) : ''}</div>
                                    <div className="header label" onClick={(event) => {sortList(event, SORT_TYPES.CREATED)}}>Created {currentSort == SORT_TYPES.CREATED ? (IconJsxer.GetIcon(sortListReverse ? IconJsxer.ICONS.chevronUp: IconJsxer.ICONS.chevronDown, IconJsxer.ICONS.roundFormControl)) : ''}</div>
                                    <div className="header label" onClick={(event) => {sortList(event, SORT_TYPES.STATUS)}}>Status {currentSort === SORT_TYPES.STATUS ? (IconJsxer.GetIcon(sortListReverse ? IconJsxer.ICONS.chevronUp : IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.roundFormControl)) : ''}</div>
                                    <div className="header label"></div>
                                    <hr />
                                    {
                                        displayBugReportData.map(
                                            (val, i) => {
                                                let listItemKey = "";
                                                if(!listTracker[i]) {
                                                    listTracker[i] = {key: "", bugReport:{}};
                                                }
                                                if(val != listTracker[i].bugReport) {
                                                    listItemKey = `bug_report_${i}_${Math.floor(Math.random() * 10000)}`;
                                                    listTracker[i].key = listItemKey;
                                                    listTracker[i].bugReport = val;
                                                } else {
                                                    listItemKey = listTracker[i].key;
                                                }

                                                return (
                                                    <ListedBugReport bugReport={val} priorities={priorities} statuses={statuses} developers={developers} changeAssignedToFunc={changeAssignedToHandler} changePriorityFunc={changePriorityHandler} changeStatusFunc={changeStatusHandler} setBugReportToEdit={openEditBugReport} key={listItemKey} prepareEditData={prepareEditData} />
                                                );
                                            }
                                        )
                                    }

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            {editData && editData.isEditing && bugReportToEdit &&
                <EditBugReport bugReport={bugReportToEdit} priorities={priorities} currentPriorityIndex={editData.priorityIndex} currentPriorityColourClass={editData.priorityColourClass} statuses={statuses} currentStatusIndex={editData.statusIndex} currentStatusColourClass={editData.statusColourClass} developers={developers} currentAssignedToIndex={editData.assignedToIndex} closePanelFunc={() => setBugReportToEdit(null)} />
            }
        </div>
    );
}

export default BugReports;
