
const Blank = () => {
    return (
        <div className="content-page-container">
            <div className="content-page-content-container">
                <div className="content-page-main-content">
                    <div className="fl-column">


                    </div>

                </div>
            </div>
        </div>
    );
}

export default Blank;
