import "./ListedAddedStream.scss";

import PropTypes from 'prop-types';
import { convertSecondsToHMS, getLongDateString, longDateOptions } from "../../helpers/Dates";
import { IconJsxer } from "../../helpers/IconHelper";
import HeadedPanel from "../HeadedPanel/HeadedPanel"
import { TwitchIconSvg, assets } from "../../assets/assets";

const ListedAddedStream = (props) => {
    const stream = props.stream;
    // console.log('Stream data: ', stream);
    const startTimeHms = convertSecondsToHMS(stream.startTime);
    const endtimeHms = convertSecondsToHMS(stream.endTime);

    return (
        <div className='small-headed-panels no-panel-shadow'>
            <HeadedPanel
                color="primary"
                headingContent={
                    <div className="fl-row grow">
                        <div className="stream-twitch-logo">
                            {TwitchIconSvg}
                        </div>
                        <div className="grow">
                            {stream.streamerHandle}
                        </div>
                        <div className="edit-icon-wh" onClick={props.removeFunc ? (e) => props.removeFunc(stream) : null}>
                            {IconJsxer.GetIcon(
                                IconJsxer.ICONS.trash,
                                IconJsxer.ICON_STYLES.campaignPanelTop,
                            )}
                        </div>
                 
                    </div>
                }
            >
                <div className="stream-details">
                    <div className="fl-row">
                        <div>{IconJsxer.GetIcon(IconJsxer.ICONS.scheduleNav, IconJsxer.ICON_STYLES.standard)}</div>
                        <div className="grow">{getLongDateString(stream.streamDate.getTime())}</div>
                    </div>
                    <div className="fl-row">
                        <div>{IconJsxer.GetIcon(IconJsxer.ICONS.clock, IconJsxer.ICON_STYLES.standard)}</div>
                        <div className="grow time">{`${startTimeHms.h_str}:${startTimeHms.m_str} - ${endtimeHms.h_str}:${endtimeHms.m_str} ${stream.timezone.label}`}</div>
                    </div>
                </div>
            </HeadedPanel>
        </div>
    )
}

ListedAddedStream.propTypes = {
    stream: PropTypes.object,
    removeFunc: PropTypes.func,
};

ListedAddedStream.defaultProps = {
    stream: {},
    removeFunc: null,
}

export default ListedAddedStream;
