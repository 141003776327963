import PropTypes from 'prop-types';
import DebugLogger from "../../helpers/DebugLogger";
import ConfirmationCodeInputSingle from "./ConfirmationCodeInputSingle";

import "./ConfirmationCodeInput.scss";

const ConfirmationCodeInput = (props) => {
  const { id, error } = props;

  if (id === '') {
    DebugLogger.warn('No id provided to ConfirmationCodeInput, using a random id...');
  }


  
  return (
    <div className="confirmation-code-input-holder">

      <div className="confirmation-code-input-content-holder">
        <label className="confirmation-code-input-label label">Verification code:</label>
        <div className="confirmation-code-input-content">
          <ConfirmationCodeInputSingle id={id + "_#0"} placeholder="" submitConfirm={ props.submitConfirm } />
          <ConfirmationCodeInputSingle id={id + "_#1"} placeholder="" submitConfirm={ props.submitConfirm } />
          <ConfirmationCodeInputSingle id={id + "_#2"} placeholder="" submitConfirm={ props.submitConfirm } />
          <ConfirmationCodeInputSingle id={id + "_#3"} placeholder="" submitConfirm={ props.submitConfirm } />
          <ConfirmationCodeInputSingle id={id + "_#4"} placeholder="" submitConfirm={ props.submitConfirm } />
          <ConfirmationCodeInputSingle id={id + "_#5"} placeholder="" submitConfirm={ props.submitConfirm } />
        </div>

        <div className="error">
          {error}
        </div>
      </div>

    </div>
  );
};

ConfirmationCodeInput.propTypes = {
  id: PropTypes.string.isRequired,
  error: PropTypes.string,
};

ConfirmationCodeInput.defaultProps = {
  id: 'confirmation-code-input',
  error: ''
}

export default ConfirmationCodeInput;
