import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from "react";

import DebugLogger from "../../../helpers/DebugLogger";

import 'react-calendar/dist/Calendar.css';
import Calendar from "react-calendar";
import "./DateSelect.scss";

import FormField from '../FormField/FormField';
import { getLongDateString } from '../../../helpers/Dates';

const DateSelect = (props) => {
    const {initialDate, id, selectFunc, allowPast, label, showLabel, labelNote, calendarOffset} = props;

    const [showCalendar, setShowCalendar] = useState(false);
    const toggleCalendar = (e) => setShowCalendar(!showCalendar);

    const [selectedDate, setSelectedDate] = useState(typeof initialDate === 'number' ? new Date(initialDate) : initialDate || new Date());
    const dateSelected = (val, e) => {
        e.preventDefault();

        setSelectedDate(val);
        if (selectFunc) {
            selectFunc(id, val);
        }
    }

    const calendarOffsetStyle = calendarOffset ? {marginLeft: calendarOffset} : {};
    if (props.lowStyle) {
        calendarOffsetStyle.bottom = props.lowStyle;
    }

    return (
        <div className="date-select-holder">
            {showCalendar &&
            <>
                <div className="close-calendar" onClick={toggleCalendar}></div>
                <div className="calendar-holder" style={calendarOffsetStyle}>
                    <Calendar 
                        onChange={dateSelected} 
                        minDate={allowPast ? null : new Date()}
                        minDetail="year"
                        defaultValue={selectedDate}
                    />
                </div>
                </>
            }
            <FormField
                showLabel={showLabel}
                label={label}
                labelNote={labelNote}
                showError={false}
                value={getLongDateString(Math.floor(selectedDate.getTime()))}
                type="text"
                id={id || "date_selector"}
                placeholder="Date"
                readonly={true}
                onClick={toggleCalendar}
            />
        </div>
    )
}

const defaultSelectFunc = (id, val) => {
    DebugLogger.warn('You should provide a function called setFun(id, val) to DateSelect.');
    DebugLogger.attention('Selected Date: ', val);
}

DateSelect.propTypes = {
    id: PropTypes.string,
    initialDate: PropTypes.object,
    selectFunc: PropTypes.func,
    allowPast: PropTypes.bool,
    label: PropTypes.string,
    showLabel: PropTypes.bool,
    inlineLabel: PropTypes.bool,
    labelNote: PropTypes.string,
    calendarOffset: PropTypes.any,
}

DateSelect.defaultProps = {
    id: "date_selector",
    initialDate: null,
    selectFunc: defaultSelectFunc,
    allowPast: false,
    label: 'Date',
    showLabel: false,
    inlineLabel: false,
    labelNote: '',
    calendarOffset: null,
    lowStyle: '-10rem',
}

export default DateSelect;
