import FormField from "../../components/forms/FormField/FormField";
import Toggle from "../../components/forms/Toggle/Toggle";
import ToggleCheck from "../../components/forms/ToggleCheck/ToggleCheck";
import CheckBox from "../../components/forms/CheckBox/CheckBox";
import { FEATURE_CONTROLS_TYPES, FindParamArrayFromCustomisations, POST_MESSAGE_OUT_TYPES } from "../../helpers/FeatureControlsHelper";
import "./FeatureControls.scss";

import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import FormDropDown from "../../components/forms/DropDownList/FormDropDown";
import RadioButtonGroup from "../../components/forms/RadioButtonGroup/RadioButtonGroup";
import HMSTimeField from "../../components/forms/HMSTimeField/HMSTimeField";
import { VAR_TYPES, checkAndUpdateObjectVar, getValFromObject } from "../../config/FeatureDashboardVarMap";
import { IconJsxer } from "../../helpers/IconHelper";
import HeadedPanel from "../../components/HeadedPanel/HeadedPanel";
import RangeSlider from "../../components/forms/RangeSlider/RangeSlider";
import ColorPicker from "../../components/forms/ColorPicker/ColorPicker";
import ChainTimeline from "../ChainTimeline/ChainTimeline";
import { useNavigate } from "react-router-dom";
import { TOP_LEVEL_NAV_ROUTES } from "../../config/NavRoutes";
import CustomiseFeature from "../../containers/CustomiseFeature/CustomiseFeature";
import CollapsibleHeadedSection from "../CollapsibleHeadedSection/CollapsibleHeadedSection";
import { assets } from "../../assets/assets";
import ClawCameraFeeds from "./custom-control-components/claw-camera-feeds/ClawCameraFeeds";
import ThemeSelector from "./custom-control-components/theme-selector/ThemeSelector";
import LivePollSelector from "./custom-control-components/live-polls/LivePollSelector";
import LivePollAnswerSelector from "./custom-control-components/live-polls/AnswerSelector";

const FeatureControls = (props) => {
    const controlsData = props.feature.feature;
    const streamVars = props.streamVars;
    const featureId = props.featureId;
    const varTypes = useRef({theme: {id: VAR_TYPES.stringType}});
    const reportFeatureVarsChanged = props.reportFeatureVarsChangedFunc;
    const controlLabel = /* props.label || */ controlsData.featureName;
    const customisationVars = props.customisationVars;
    const reportCustomisationVarsChanged = props.reportCustomisationVarsChanged;
    const ignoreCustomisationDisplayConditions = props.ignoreCustomisationDisplayConditions ?? false;

    // console.log('Controls Data: ', controlsData);
    // console.log('Stream Vars: ', streamVars);

    // This state is really only used to trigger re-renders, we are actually pulling the values from props.streamVars
    const [featureState, setFeatureState] = useState({});

    const [selectedQuiz, setSelectedQuiz] = useState(0);
    const [customisationState, setCustomisationState]  = useState({...props.customisationVars });

    const buildStateObject = () => {
        const newFeatureState = {};
        newFeatureState[featureId] = {};
        controlsData.defaultVars.forEach(
            (groupVal, groupIndex, groupArr) => {
                newFeatureState[featureId][groupVal.varKey] = {};
                groupVal.variables.forEach(
                    (varVal, varIndex, varArr) => {
                        newFeatureState[featureId][groupVal.varKey][varVal.varName] = getValFromObject(streamVars, featureId, groupVal.varKey + '.' + varVal.varName);
                    }
                )
            }
        )
        // console.log('New feature controls state built: ', newFeatureState);
        setFeatureState(newFeatureState);
        
    }
    useEffect(
        () => {
        
            setCustomisationState({...props.customisationVars})
            buildStateObject();
        }, [streamVars, controlsData]
    )
    const setUpPostMessageListener = () => {
        console.log('QUIZ QUIZ QUIZ QUIZ')
        console.log(props.quizData)
       /* window.addEventListener(
            "message",
            processPostedMessages
        );*/
    }
    useEffect(
        () =>{
            setUpPostMessageListener();
        }, []
    )

    const processPostedMessages = (event) => {
        console.log('KTFC Koko Twitch Feature Controls received message: ', event.origin, event.data);
        const timestamp = Date.now();
        switch (event.data.type) {
            case POST_MESSAGE_OUT_TYPES.QUIZ_DATA:
                console.log('LOADED QUIZ DATA IN FEATURE CONTROLS',event.data.detail )
            break;
        }
    }

    const changeSelectedQuiz = (index) => {
        
        setSelectedQuiz(index)
    }
    const changeVal = (varKey, varName, newValue, report = true, updateDisruptorEvenWhenNotReporting = false, updateState = true) => {
        // console.log('Val changed: ', args)
        checkAndUpdateObjectVar(streamVars, featureId, varKey + '.' + varName, newValue, varTypes.current[varKey][varName]);
        // If we have some passed in object data with a value we need to update that as well
        if (props.passedInPreviewData && props.passedInPreviewData?.[varKey]?.[varName] !== undefined) {
            props.passedInPreviewData[varKey][varName] = newValue;
        }

        if (updateState) {
            buildStateObject();
        }
        if ((report || updateDisruptorEvenWhenNotReporting) && reportFeatureVarsChanged) {
            const onlyUpdateDisruptor = report ? false : updateDisruptorEvenWhenNotReporting;
            reportFeatureVarsChanged(onlyUpdateDisruptor, props.passedInPreviewData);
        }
    }

    const findOptionIndex = (options, val) => {
        console.log('FIND',typeof val, options)
        for (let i = 0; i < options.length; i++) {
            if (options[i].val === '') {
                options[i].val = options[i].value;
            }
            if (options[i].val === val) {
                console.log('FOUND')
                return i;
            }
        }
        
        return 0;
    }


    const changeSelectedChallengeSet = () =>{}
    const changeCustomisation = (val, target, targetVal, updateTarget) => {
        target[targetVal] = val
        updateTarget.updated = true;
        setCustomisationState({...customisationState})
        if(reportCustomisationVarsChanged)
        {
            console.log(reportCustomisationVarsChanged)
            console.log('CV TO SEND', customisationVars)
           setTimeout(() =>{ reportCustomisationVarsChanged(customisationVars, featureId)}, 200);
        }
    }

    const buildDropDownData = (dropDownConfig) => {
        if (Array.isArray(dropDownConfig)) {
            return dropDownConfig;
        }
        let dropDownRawData = null;
        // Standard name, val props in our raw data
        let nameVarkey = 'name';
        let valVarkey = 'val';
        let bracketedVarKey = null; // We can show some extra data in brackets after the name
        // If we have an array of objects with name and val properties, we can just use a string to reference the array in our customisation data
        if (typeof dropDownConfig === 'string') {
            return FindParamArrayFromCustomisations(props.customisationVars, featureId, dropDownConfig);
        }
        // Otherwise we need to be a bit smarter and pass in an object with some config:
        // {
        //      param: 'quizzes',
        //      nameVarkey: 'quizID.quiz.quizName',
        //      valVarkey: 'quizID.quiz._id',        Or we can use 'i' to just use the index of the array
        //      bracketedVarKey: 'quizID.quiz.quizType'
        // }
        // console.log('Drop down config object: ', dropDownConfig);
        if (typeof dropDownConfig === 'object' && !Array.isArray(dropDownConfig)) {
           
            if (typeof dropDownConfig.nameVarkey === 'string') {
                nameVarkey = dropDownConfig.nameVarkey;
            }
            if (typeof dropDownConfig.valVarkey === 'string') {
                valVarkey = dropDownConfig.valVarkey;
            }
            if (typeof dropDownConfig.bracketedVarKey === 'string') {
                bracketedVarKey = dropDownConfig.bracketedVarKey;
            }
            dropDownRawData = FindParamArrayFromCustomisations(props.customisationVars, featureId, dropDownConfig.param);


            if(dropDownConfig.param === 'quizzes')
            {
                console.log('FEATURE IS A QUIZ', dropDownRawData)
            }

            // console.log('Drop down raw data: ', dropDownRawData);
            // We should now have an array of objects to build our dropdown options from
            const dropDownOptions = [];
            if (!Array.isArray(dropDownRawData)) {
                console.log('Drop down raw data not found');
                return dropDownOptions;
            }
            dropDownRawData.forEach(
                (val, i, arr) => {
                    // console.log('Feature dropdown Val: ', val);
                    const drillDownToProp = (obj, propKey) => {
                        // console.log('Drill down to prop: ', obj, propKey);
                        const propKeyParts = propKey.split('.');
                        let drillDownObj = obj;
                        propKeyParts.forEach(
                            (propKeyPart, propKeyPartIndex, propKeyPartArr) => {
                                if (drillDownObj[propKeyPart] !== undefined) {
                                    drillDownObj = drillDownObj[propKeyPart];
                                    if (typeof drillDownObj === 'undefined' || drillDownObj === null) {
                                        console.log('Prop val not found');
                                        return drillDownObj;
                                    }
                                }
                            }
                        )
                        // console.log('Drill down obj: ', drillDownObj);
                        return drillDownObj;
                    }

                    let optionLabel = drillDownToProp(val, nameVarkey) || "No Options";
                    if (bracketedVarKey !== null) {
                        optionLabel += ' (' + (drillDownToProp(val, bracketedVarKey) || "No Data Found") + ')';
                    }
                    dropDownOptions.push({
                        label: optionLabel,
                        val: valVarkey === 'i' ? i : drillDownToProp(val, valVarkey) || i
                    })
                }
            )

            return dropDownOptions;
        }
    }

    const getControl = (varVal, groupVal) => {
        // console.log('FeatureControls props: ', props);
        // console.log('Control: ', varVal, getValFromObject(streamVars, featureId, groupVal.varKey + '.' + varVal.varName));

        // Remember our types!
        if (typeof varTypes.current[groupVal.varKey] === 'undefined') {
            varTypes.current[groupVal.varKey] = {};
        }
        varTypes.current[groupVal.varKey][varVal.varName] = varVal.varType;

        const showChatNotConnectedMessage = !props.previewControls && (!props.chatConnected || !props.featureReady);
        const challengeState = 
            props.previewControls ? (props.passedInPreviewData?.challengeData?.[streamVars[featureId].featureData.selectedChallengeSet - 1] ?? {notReady: true})
            : (streamVars[featureId]?.challengeData?.[streamVars[featureId].featureData.selectedChallengeSet - 1] ?? {notReady: true});
        const countTotalCompChallenges = () => {
            let totalCompChallenges = 0;;
            for (var i = 0; i < 10; i++) {
                let compChallengeData = customisationVars.current[featureId] ? customisationVars.current[featureId].leaderboard['compData' + (i + 1)] : null
                if (compChallengeData && compChallengeData.challengeData.length > 0) {
                    totalCompChallenges += compChallengeData.challengeData.length;
                }
            }
            return totalCompChallenges;
        }

        let enableControl = true;
        if (varVal.disabledCondition) {
            let ignoreMoreConditions = false;
            varVal.disabledCondition.forEach(
                (condVal, condI, condArr) => {
                    if (ignoreMoreConditions) {
                        return;
                    }
                    if (!enableControl && condVal.evalCondition === 'OR') {
                        ignoreMoreConditions = true;
                    } else
                    if (enableControl && condVal.evalCondition === 'OR') {
                        if (condI < condArr.length - 1) {
                            enableControl = true;
                        }
                    } else
                    if (enableControl) {
                        // We can now check for conditions in customisationVars or streamVars
                        if (!ignoreCustomisationDisplayConditions || !condVal.customisationCondition) {
                            let displayConditionVal = 
                                condVal.customisationCondition ?
                                getValFromObject(customisationVars?.current ?? customisationVars, featureId, condVal.var) :
                                getValFromObject(streamVars, featureId, groupVal.varKey + '.' + condVal.var);
                            if (props.passedInPreviewData /* && (displayConditionVal === null || typeof displayConditionVal === 'undefined') */) {
                                // console.log('CONTROL DISPLAY COND, looking in previewData: ', props.passedInPreviewData);
                                // Try to get the value from the passed in preview data
                                const dataStruct = {};
                                dataStruct[featureId] = props.passedInPreviewData;
                                const displayConditionVal_passed = getValFromObject(dataStruct, featureId, groupVal.varKey + '.' + condVal.var);
                                if (displayConditionVal_passed !== null && typeof displayConditionVal_passed !== 'undefined') {
                                    displayConditionVal = displayConditionVal_passed;
                                }
                            }
                            // Anything that is not a number needs to be enclosed in quotes in our eval string (so we can make > and < work and also arbitrary strings like "none")
                            const evalStr = typeof condVal.val=== 'number' && typeof displayConditionVal === 'number' ?
                                String(displayConditionVal) + condVal.evalCondition + String(condVal.val) :
                                "'" + String(displayConditionVal) + "'" + condVal.evalCondition + "'" + String(condVal.val) + "'";
                                enableControl = !(eval?.(evalStr));
                        }
                    }
                }
            )
        }

        switch (varVal.dashboardDisplayType) {
            case FEATURE_CONTROLS_TYPES.SubTitle.type:
                return (
                    <div className="var-label">
                        {varVal.label}
                    </div>
                )
            case FEATURE_CONTROLS_TYPES.DescriptionString.type:
                return (
                    <div className="info-text" dangerouslySetInnerHTML={{__html: varVal.description}}>
                    </div>
                )
            case FEATURE_CONTROLS_TYPES.LightDescriptionString.type:
                return (
                    <div className="info-text light-text" dangerouslySetInnerHTML={{__html: varVal.description}}>
                    </div>
                )
            case FEATURE_CONTROLS_TYPES.Toggle.type:
                // console.log('TOGGLE: ', varVal.varName, varVal);
                const value = getValFromObject(streamVars, featureId, groupVal.varKey + '.' + varVal.varName);
                let highLightClass = '';
                if (varVal.fullHighlight) {
                    if (value === true) {
                        highLightClass = ' full-control-highlight';
                    } else {
                        highLightClass = ' full-control-highlight-off';
                    }
                }
                return (
                    <>
                        <div className={`fl-row${highLightClass}`}>
                            <div className="form-field-content label">{varVal.label || varVal.varName}</div>
                            <div className="grow-100"></div>
                            <Toggle
                                id={varVal.varName}
                                currentState={getValFromObject(streamVars, featureId, groupVal.varKey + '.' + varVal.varName)}
                                toggleFunc={(e, id) => { changeVal(groupVal.varKey, varVal.varName, !getValFromObject(streamVars, featureId, groupVal.varKey + '.' + varVal.varName)) }}
                                enabled={enableControl}
                            />
                            {varVal.description && varVal.description !== '' &&
                                <div className="more-info end" onMouseEnter={(e) => props.showTooltipFunc(varVal.description, e)} onMouseLeave={props.hideTooltipFunc}>
                                    {IconJsxer.GetIcon(IconJsxer.ICONS.moreInfo, IconJsxer.ICON_STYLES.controlMoreInfo)}
                                </div>
                            }
                        </div>
                        {/*varVal.description && varVal.description !== '' &&
                            <div className="fl-row form-field-content label-note">
                                {varVal.description}
                            </div>
                        */}
                    </>
                )
            case FEATURE_CONTROLS_TYPES.ToggleCheck.type:
                return (
                    <>
                        <div className="fl-row">
                            <div>{varVal.label || varVal.varName}</div>
                            <div className="grow-100"></div>
                            <ToggleCheck
                                id={varVal.varName}
                                currentState={getValFromObject(streamVars, featureId, groupVal.varKey + '.' + varVal.varName)}
                                toggleFunc={(e, id) => { changeVal(groupVal.varKey, varVal.varName, !getValFromObject(streamVars, featureId, groupVal.varKey + '.' + varVal.varName)) }}
                                enabled={enableControl}
                            />
                            {varVal.description && varVal.description !== '' &&
                                <div className="more-info end" onMouseEnter={(e) => props.showTooltipFunc(varVal.description, e)} onMouseLeave={props.hideTooltipFunc}>
                                    {IconJsxer.GetIcon(IconJsxer.ICONS.moreInfo, IconJsxer.ICON_STYLES.controlMoreInfo)}
                                </div>
                            }
                        </div>
                        {/*varVal.description && varVal.description !== '' &&
                            <div className="fl-row form-field-content label">
                                {varVal.description}
                            </div>
                        */}
                    </>
                )
            case FEATURE_CONTROLS_TYPES.CheckBox.type:
                return (
                    <>
                        <div className="fl-row">
                            <div>{varVal.label || varVal.varName}</div>
                            <div className="grow-100"></div>
                            <CheckBox
                              
                                id={varVal.varName}
                                currentState={getValFromObject(streamVars, featureId, groupVal.varKey + '.' + varVal.varName)}
                                toggleFunc={(e, id) => { changeVal(groupVal.varKey, varVal.varName, !getValFromObject(streamVars, featureId, groupVal.varKey + '.' + varVal.varName)) }}
                                unClickable={!enableControl}
                            />
                            {varVal.description && varVal.description !== '' &&
                                <div className="more-info end" onMouseEnter={(e) => props.showTooltipFunc(varVal.description, e)} onMouseLeave={props.hideTooltipFunc}>
                                    {IconJsxer.GetIcon(IconJsxer.ICONS.moreInfo, IconJsxer.ICON_STYLES.controlMoreInfo)}
                                </div>
                            }
                        </div>
                        {/*varVal.description && varVal.description !== '' &&
                            <div className="fl-row form-field-content label">
                                {varVal.description}
                            </div>
                        */}
                    </>
                )
            case FEATURE_CONTROLS_TYPES.String.type:
                return (
                    <div className="fl-row">
                        <FormField
                            label={varVal.label || varVal.varName}
                            // labelNote={varVal.description || ''}
                            id={varVal.varName}
                            setFunc={(newVal) => { changeVal(groupVal.varKey, varVal.varName, newVal, false) }}
                            confirmChangeFunc={(newVal) => { changeVal(groupVal.varKey, varVal.varName, newVal) }}
                            type='text'
                            maxLength={varVal.maxLength}
                            value={getValFromObject(streamVars, featureId, groupVal.varKey + '.' + varVal.varName)}
                            showError={false}
                            placeholder={String(varVal.defaultVal)}
                            readonly={!enableControl}
                        />
                         {varVal.description && varVal.description !== '' &&
                            <div className="more-info" onMouseEnter={(e) => props.showTooltipFunc(varVal.description, e)} onMouseLeave={props.hideTooltipFunc}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.moreInfo, IconJsxer.ICON_STYLES.controlMoreInfo)}
                            </div>
                        }
                    </div>
                )
            case FEATURE_CONTROLS_TYPES.Number.type:
                return (
                    <div className="fl-row">
                        <FormField
                            label={varVal.label || varVal.varName}
                            // labelNote={varVal.description || ''}
                            id={varVal.varName}
                            setFunc={(newVal) => { changeVal(groupVal.varKey, varVal.varName, newVal, false) }}
                            confirmChangeFunc={(newVal) => { changeVal(groupVal.varKey, varVal.varName, newVal) }}
                            type='number'
                            min={varVal.minVal || 0}
                            max={varVal.maxVal || 9999999}
                            value={getValFromObject(streamVars, featureId, groupVal.varKey + '.' + varVal.varName)}
                            showError={false}
                            placeholder={String(varVal.defaultVal)}
                            readonly={!enableControl}
                        />
                        {varVal.description && varVal.description !== '' &&
                            <div className="more-info" onMouseEnter={(e) => props.showTooltipFunc(varVal.description, e)} onMouseLeave={props.hideTooltipFunc}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.moreInfo, IconJsxer.ICON_STYLES.controlMoreInfo)}
                            </div>
                        }
                    </div>
                )
            case FEATURE_CONTROLS_TYPES.Input.type:
                return (
                    <div className="fl-row">
                        <FormField
                            label={varVal.label || varVal.varName}
                            // labelNote={varVal.description || ''}
                            id={varVal.varName}
                            setFunc={(newVal) => { changeVal(groupVal.varKey, varVal.varName, newVal, false) }}
                            confirmChangeFunc={(newVal) => { changeVal(groupVal.varKey, varVal.varName, newVal) }}
                            type={varVal.varType === VAR_TYPES.numberType ? 'number' : 'text'}
                            min={varVal.minVal || 0}
                            max={varVal.maxVal || 9999999}
                            maxLength={varVal.maxLength}
                            value={getValFromObject(streamVars, featureId, groupVal.varKey + '.' + varVal.varName)}
                            showError={false}
                            placeholder={String(varVal.defaultVal)}
                            readonly={!enableControl}
                        />
                        {varVal.description && varVal.description !== '' &&
                            <div className="more-info" onMouseEnter={(e) => props.showTooltipFunc(varVal.description, e)} onMouseLeave={props.hideTooltipFunc}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.moreInfo, IconJsxer.ICON_STYLES.controlMoreInfo)}
                            </div>
                        }
                    </div>
                )
            case FEATURE_CONTROLS_TYPES.Range.type:
                return (
                    <div className="fl-row">
                        <RangeSlider
                            label={varVal.label || varVal.varName}
                            // labelNote={varVal.description || ''}
                            id={varVal.varName}
                            setFunc={(newVal) => { changeVal(groupVal.varKey, varVal.varName, newVal, false/* , true */) }}
                            confirmChangeFunc={(newVal) => { changeVal(groupVal.varKey, varVal.varName, newVal) }}
                            min={varVal.minVal || 0}
                            max={varVal.maxVal || 9999999}
                            value={getValFromObject(streamVars, featureId, groupVal.varKey + '.' + varVal.varName)}
                            useTextLabels={varVal.useTextLabels}
                            minLabel={String(varVal.minLabel ?? 'min') || ''}
                            midLabel={String(varVal.midLabel ?? '') || ''}
                            maxLabel={String(varVal.maxLabel ?? 'max') || ''}
                        />
                        {varVal.description && varVal.description !== '' &&
                            <div className="more-info-2" onMouseEnter={(e) => props.showTooltipFunc(varVal.description, e)} onMouseLeave={props.hideTooltipFunc}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.moreInfo, IconJsxer.ICON_STYLES.controlMoreInfo)}
                            </div>
                        }
                    </div>
                )
            case FEATURE_CONTROLS_TYPES.DropDown.type:
                const dropDownOptions = buildDropDownData(varVal.valOptions);
                console.log('Drop down options: ', dropDownOptions);
                if (dropDownOptions.length === 0) {
                    return (
                        <div className="var-label warning-text">
                            [DropDown: No options available]
                        </div>
                    )
                }

                let currentIndex = null;
                if (props.passedInPreviewData) {
                    const dataStruct = {};
                    dataStruct[featureId] = props.passedInPreviewData;
                    const passedInVal = getValFromObject(dataStruct, featureId, groupVal.varKey + '.' + varVal.varName);
                    if (passedInVal) {
                        currentIndex = findOptionIndex(dropDownOptions, passedInVal);
                    }
                }
                if (currentIndex === null) {
                    currentIndex = findOptionIndex(dropDownOptions, getValFromObject(streamVars, featureId, groupVal.varKey + '.' + varVal.varName))
                }
                if (currentIndex === null) {
                    currentIndex = 0;
                }

                return (
                    <>
                    <div className="fl-row">
                        <FormDropDown
                            className={'wide-select'}
                            label={varVal.label || varVal.varName}
                            // labelNote={varVal.description || ''}
                            id={varVal.varName}
                            selectFunc={(e, id, item) => {changeVal(groupVal.varKey, varVal.varName, item.val); changeSelectedQuiz(item.val); }}
                            items={dropDownOptions}
                            currentIndex={currentIndex/*findOptionIndex(dropDownOptions, getValFromObject(streamVars, featureId, groupVal.varKey + '.' + varVal.varName))*/}
                            showErrors={false}
                            enabled={dropDownOptions.length > 1 && enableControl}
                        />
                        {varVal.description && varVal.description !== '' &&
                            <div className="more-info" onMouseEnter={(e) => props.showTooltipFunc(varVal.description, e)} onMouseLeave={props.hideTooltipFunc}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.moreInfo, IconJsxer.ICON_STYLES.controlMoreInfo)}
                            </div>
                        }
                        {console.log('FEATURESTATE', featureState)}
                      
                       



                    </div>
                    <>
                         {varVal.valOptions.param == 'quizzes' && props.quizData && props.quizData.quizzes &&
                            <>
                            {props.quizData.quizzes.map((quizData,index) => {
                                console.log('QUIZ DATA', quizData);
                                if (typeof featureState[featureId]?.quizControl?.selectedQuiz === 'number' && currentIndex != featureState[featureId].quizControl.selectedQuiz) {
                                    changeVal(groupVal.varKey, varVal.varName, currentIndex); 
                                    changeSelectedQuiz(currentIndex);
                                }
                                return (
                                    <div key={'q_round_' + index}>
                                        {featureState[featureId] && featureState[featureId].quizControl && index == currentIndex/*featureState[featureId].quizControl.selectedQuiz*/ &&
                                            <>
                                                {quizData.rounds.map((roundData, j) =>{
                                                    return (
                                                        <div className={'round-info'} key={'r_btn_' + j}>
                                                            <div> {(j+1)+': '+roundData.name}</div>
                                                            <button className="standard-button" style={{maxWidth:'100px'}} onClick={(e) => {props.sendFeatureCommandFunc(e, 'START_QUIZ', featureId, j)}}>{'Start Round'}</button>
                                                        </div>
                                                    )
                                                })}
                                            </>
                                        }
                                    </div>
                                )
                            })}
                            
                            </>
                    }
                    </>
                    </>
                )
            case FEATURE_CONTROLS_TYPES.Radio.type:
                return (
                    <div className="fl-row">
                        <RadioButtonGroup
                            label={varVal.label || varVal.varName}
                            // labelNote={varVal.description || ''}
                            id={varVal.varName}
                            selectFunc={(e, id, newValue) => { changeVal(groupVal.varKey, varVal.varName, newValue) }}
                            options={varVal.valOptions}
                            horizontal={true}
                            selectedOption={getValFromObject(streamVars, featureId, groupVal.varKey + '.' + varVal.varName)}
                            fillWidth={true}
                        />
                        {varVal.description && varVal.description !== '' &&
                            <div className="more-info-2" onMouseEnter={(e) => props.showTooltipFunc(varVal.description, e)} onMouseLeave={props.hideTooltipFunc}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.moreInfo, IconJsxer.ICON_STYLES.controlMoreInfo)}
                            </div>
                        }
                    </div>
                )
            case FEATURE_CONTROLS_TYPES.RadioVertical.type:
                return (
                    <div className="fl-row">
                        <RadioButtonGroup
                            label={varVal.label || varVal.varName}
                            // labelNote={varVal.description || ''}
                            id={varVal.varName}
                            selectFunc={(e, id, newValue) => { changeVal(groupVal.varKey, varVal.varName, newValue) }}
                            options={varVal.valOptions}
                            horizontal={false}
                            selectedOption={getValFromObject(streamVars, featureId, groupVal.varKey + '.' + varVal.varName)}
                            fillWidth={true}
                        />
                        {varVal.description && varVal.description !== '' &&
                            <div className="more-info start" onMouseEnter={(e) => props.showTooltipFunc(varVal.description, e)} onMouseLeave={props.hideTooltipFunc}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.moreInfo, IconJsxer.ICON_STYLES.controlMoreInfo)}
                            </div>
                        }
                    </div>
                )
            case FEATURE_CONTROLS_TYPES.HmsTime.type:
                return (
                    <div className="fl-row">
                        <HMSTimeField
                            label={varVal.label || varVal.varName}
                            // labelNote={varVal.description || ''}
                            id={varVal.varName}
                            setFunc={(newValue) => { changeVal(groupVal.varKey, varVal.varName, newValue) }}
                            showError={false}
                            value={getValFromObject(streamVars, featureId, groupVal.varKey + '.' + varVal.varName)}
                            centered={false}
                        />
                        {varVal.description && varVal.description !== '' &&
                            <div className="more-info" onMouseEnter={(e) => props.showTooltipFunc(varVal.description, e)} onMouseLeave={props.hideTooltipFunc}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.moreInfo, IconJsxer.ICON_STYLES.controlMoreInfo)}
                            </div>
                        }
                    </div>
                )
            case FEATURE_CONTROLS_TYPES.MsTime.type:
                return (
                    <div className="fl-row">
                        <HMSTimeField
                            label={varVal.label || varVal.varName}
                            // labelNote={varVal.description || ''}
                            id={varVal.varName}
                            setFunc={(newValue) => { changeVal(groupVal.varKey, varVal.varName, newValue) }}
                            showHours={false}
                            showError={false}
                            value={getValFromObject(streamVars, featureId, groupVal.varKey + '.' + varVal.varName)}
                            centered={false}
                        />
                        <div className="grow-100"></div>
                        {varVal.description && varVal.description !== '' &&
                            <div className="more-info" onMouseEnter={(e) => props.showTooltipFunc(varVal.description, e)} onMouseLeave={props.hideTooltipFunc}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.moreInfo, IconJsxer.ICON_STYLES.controlMoreInfo)}
                            </div>
                        }
                    </div>
                )
            case FEATURE_CONTROLS_TYPES.RelHmsTime.type:
                return (
                    <div className="fl-row">
                        <HMSTimeField
                            label={varVal.label || varVal.varName}
                            // labelNote={varVal.description || ''}
                            id={varVal.varName}
                            setFunc={(newValue) => { changeVal(groupVal.varKey, varVal.varName, newValue) }}
                            relativeTime={true}
                            showError={false}
                            value={getValFromObject(streamVars, featureId, groupVal.varKey + '.' + varVal.varName)}
                            centered={false}
                        />
                        {varVal.description && varVal.description !== '' &&
                            <div className="more-info" onMouseEnter={(e) => props.showTooltipFunc(varVal.description, e)} onMouseLeave={props.hideTooltipFunc}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.moreInfo, IconJsxer.ICON_STYLES.controlMoreInfo)}
                            </div>
                        }
                    </div>
                )
            case FEATURE_CONTROLS_TYPES.ColourPicker.type:
                return (
                    <div className="fl-row">
                        <ColorPicker
                            label={varVal.label || varVal.varName}
                            // labelNote={varVal.description || ''}
                            id={varVal.varName}
                            setFunc={(newValue) => { changeVal(groupVal.varKey, varVal.varName, newValue) }}
                            value={getValFromObject(streamVars, featureId, groupVal.varKey + '.' + varVal.varName)}
                        />
                        {varVal.description && varVal.description !== '' &&
                            <div className="more-info" onMouseEnter={(e) => props.showTooltipFunc(varVal.description, e)} onMouseLeave={props.hideTooltipFunc}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.moreInfo, IconJsxer.ICON_STYLES.controlMoreInfo)}
                            </div>
                        }
                    </div>
                )
            case FEATURE_CONTROLS_TYPES.LineSpacer.type:
                return (
                    <div className="var-spacer-line"></div>
                )
                
            case FEATURE_CONTROLS_TYPES.CompChallengeList.type:

                if (showChatNotConnectedMessage) {
                    return (
                        <div className="var-label warning-text">
                            Please open the extension page and click Connect to Channels to enable this feature
                        </div>
                    )
                }

                if (countTotalCompChallenges() === 0) {
                    return (
                        <div className="var-label warning-text">
                            No challenges have been set up
                        </div>
                    )
                }

                    let challengeSetListData = [];
                    for(var i=0; i<10; i++)
                    {
                       let compChallengeData =  customisationVars.current[featureId] ? customisationVars.current[featureId].leaderboard['compData'+(i+1)] : null
                        if(compChallengeData && compChallengeData.challengeData.length > 0)
                        {
                            challengeSetListData.push({label: compChallengeData.settings[0].listName,  val: (i+1).toString()})
                        }
                    }
                    const challengeDropDownOptions = buildDropDownData(challengeSetListData);

                    console.log('CSET', groupVal.varKey, varVal.varName)

                    return (
                        <>
                            
                        {featureId !== 'preview' && challengeDropDownOptions.length && customisationVars.current[featureId].leaderboard && customisationVars.current[featureId].leaderboard.settings.setup[0].type == 'compChallenge' &&
                          <div className="fl-row">
                          <FormDropDown
                              className={'wide-select'}
                              label={'Select Challenge Set'}
                              // labelNote={varVal.description || ''}
                             selectFunc={(e, id, item) => { changeVal(groupVal.varKey, varVal.varName, item.val) }}
                              items={challengeDropDownOptions}
                              currentIndex={findOptionIndex(challengeDropDownOptions, getValFromObject(streamVars, featureId, groupVal.varKey + '.' + varVal.varName))}
                              showErrors={false}
                              enabled={challengeDropDownOptions.length > 1 && !challengeState.challengeListStarted}
                          />
                          {varVal.description && varVal.description !== '' &&
                              <div className="more-info" onMouseEnter={(e) => props.showTooltipFunc(varVal.description, e)} onMouseLeave={props.hideTooltipFunc}>
                                  {IconJsxer.GetIcon(IconJsxer.ICONS.moreInfo, IconJsxer.ICON_STYLES.controlMoreInfo)}
                              </div>
                          }
                          {console.log('FEATURESTATE', featureState)}
                      </div>
                        }
                       </>
                    )
            case FEATURE_CONTROLS_TYPES.LeaderboardData.type:
                const refreshList = () => {
                    setTimeout(() => {
                        props.sendFeatureCommandFunc(null, 'REFRESH_DATA', featureId, 0);
                    }, 50);
                }

                /* const challengeState = 
                    props.previewControls ? (props.passedInPreviewData?.challengeData?.[streamVars[featureId].featureData.selectedChallengeSet - 1] ?? {notReady: true})
                    : (streamVars[featureId]?.challengeData?.[streamVars[featureId].featureData.selectedChallengeSet - 1] ?? {notReady: true}); */

                if (customisationVars.current[featureId]?.leaderboard.settings.setup[0].type == 'compChallenge' && countTotalCompChallenges > 0 && challengeState.notReady) {
                    refreshList();
                }

                const countSelectedChallenges = () => {
                    let count = 0;
                    if (challengeState.challenges) {
                        challengeState.challenges.forEach((challenge, i) => {
                            if (challenge.selected) {
                                count++;
                            }
                        });
                    }
                    // streamVars[featureId].featureData.selectedCount = count;
                    return count;
                }

                const getStreakTotal = (ach) => {
                    let total = 0;
                    for (let i = 0; i< ach.pointClimberStages; i++) {
                        total += ach['stagePoints' + (i + 1)];
                    }
                    return total;
                }

                const getNextStreakPoints = (ach, progress) => {
                    let p = progress < 0 ? 0 : progress > ach.pointClimberStages - 1 ? ach.pointClimberStages - 1 : progress;
                    return ach['stagePoints' + (p + 1)];
                }

                const allSelected = countSelectedChallenges() === (customisationVars.current[featureId]?.leaderboard?.['compData'+streamVars[featureId].featureData.selectedChallengeSet]?.settings[0]?.numberToSelect ?? 0);
                console.log('---LDB Data:', customisationVars.current[featureId]);
                    
                return (
                    <div className="fl-column">
                        {featureId !== 'preview' && customisationVars.current[featureId].leaderboard && customisationVars.current[featureId].leaderboard.settings.setup[0].type == 'leaderboard' &&
                            <>
                                <div className='fl-column no-gap'>
                                    {customisationVars.current[featureId].leaderboard.leaderboardData.entries.map(
                                        (val, i, arr) => {
                                            const score = val.score
                                            console.log(score)
                                            /* if(!score || score == undefined) 
                                             {
                                                 return(<></>)
                                             }*/
                                            console.log(props.customisationVars.current[featureId].leaderboard.leaderboardData.entries);
                                            return (
                                                <div className={`leaderboardEntry achievementEntry ${i % 2 === 0 ? 'even-row' : 'odd-row'}`} key={"lbEntry_" + i}>
                                                    <div className="fl-row grow">
                                                        <div className="grow">
                                                            <p>{val.name}</p>
                                                        </div>
                                                        <div className="shrink">
                                                            {props.customisationVars.current[featureId].leaderboard.settings.setup[0].scoreType == 'time' && <HMSTimeField
                                                                id={'score' + i}
                                                                label={'Time'}
                                                                value={val.time}
                                                                setFunc={(newVal) => {
                                                                    console.log(newVal);
                                                                    changeCustomisation(Number(newVal), customisationVars.current[featureId].leaderboard.leaderboardData.entries[i], 'time', customisationVars.current[featureId].leaderboard.leaderboardData)
                                                                }}
                                                                maxHours={23}
                                                                showSeconds={true}
                                                                relativeTime={false}
                                                                narrow={true}
                                                                centered={false}
                                                                showError={false}
                                                            />}

                                                            {props.customisationVars.current[featureId].leaderboard.settings.setup[0].scoreType != 'time' &&
                                                            <div className="score-box">
                                                                <FormField
                                                                    label={'Score'}
                                                                    showLabel={false}
                                                                    // labelNote={varVal.description || ''}
                                                                    id={'score' + i}
                                                                    setFunc={(newVal) => { changeCustomisation(Number(newVal), customisationVars.current[featureId].leaderboard.leaderboardData.entries[i], 'score', customisationVars.current[featureId].leaderboard.leaderboardData) }}
                                                                    confirmChangeFunc={(newVal) => { }}
                                                                    type={'number'}
                                                                    min={varVal.minVal || 0}
                                                                    max={varVal.maxVal || 9999999}
                                                                    maxLength={varVal.maxLength}
                                                                    value={score}
                                                                    showError={false}
                                                                    placeholder={String(varVal.defaultVal)}
                                                                />
                                                                
                                                            </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                </div>
                                <div className="grow">
                                    <CustomiseFeature saveButtonOnly={true} customisationData={customisationVars}></CustomiseFeature>
                                </div>
                            </>
                        }
                        {/*showChatNotConnectedMessage && 
                            <div className="var-label warning-text">
                                Please click Connect to Channels to enable this feature
                            </div>
                        */}
                       {featureId !== 'preview' && customisationVars.current[featureId].leaderboard && customisationVars.current[featureId].leaderboard.settings.setup[0].type == 'achievement' &&
                       <>
                            <div className='fl-column no-gap'>
                                {customisationVars.current[featureId].leaderboard.achievementData.entries.map(
                                (val, i, arr) => { 
                                    const achievement = val.achievementCopy
                                
                                    if(!achievement || achievement == undefined) 
                                    {
                                        return(<></>)
                                    }
                                    return (
                                        <div className={`achievementEntry ${i % 2 === 0 ? 'even-row' : 'odd-row'}`} key={"achEntry_" + i}>
                                            <div className="fl-row">
                                                <div className="grow">
                                                    <p>{achievement}</p>
                                                </div>
                                                <div className="shrink">
                                                    <CheckBox
                                                        label=''
                                                        id={'toggle' + i}
                                                        initialState={val.isAchieved}
                                                        currentState={val.isAchieved}
                                                        setFunc={(newVal) => { changeCustomisation(Boolean(newVal), customisationVars.current[featureId].leaderboard.achievementData.entries[i], 'isAchieved', customisationVars.current[featureId].leaderboard.achievementData) }}
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                
                            </div>
                            <div className="grow">
                                <CustomiseFeature saveButtonOnly={true} forcedFeatureID={featureId} customisationData={customisationVars}></CustomiseFeature>
                            </div>
                        </>
                       }

                        {!showChatNotConnectedMessage && featureId !== 'preview' && customisationVars.current[featureId].leaderboard && customisationVars.current[featureId].leaderboard.settings.setup[0].type == 'compChallenge' && countTotalCompChallenges() > 0 &&
                        <>
                            <div className="button-grid">
                                <button className="standard-button" style={{minWidth: '100%'}} onClick={(e) => {props.sendFeatureCommandFunc(e, 'REFRESH_DATA', featureId, 0)}}>Refresh</button>
                                
                            </div>

                            <div className='fl-column no-gap'>

                                {challengeState.selectMode &&
                                    <div className='fl-row label'>
                                        {`Selected ${countSelectedChallenges()} of ${customisationVars.current[featureId].leaderboard['compData'+streamVars[featureId].featureData.selectedChallengeSet].settings[0].numberToSelect}`}
                                    </div>
                                }

                                <div className={`challengeHolder ${!challengeState.selectMode && challengeState.challengeListStarted ? 'live' : ''} 
                                ${!challengeState.selectMode && !challengeState.challengeListStarted ? 'ready' : ''}
                                ${challengeState.selectMode ? 'select' : ''}` } 
                                
                                
                                
                                >

                                    { !challengeState.selectMode && countSelectedChallenges() == 0 && <div> <strong>No Challenges Selected</strong><br />Please enter select challenge mode to select the streamers challenges! </div>}
                                {!challengeState.notReady && customisationVars.current[featureId].leaderboard['compData'+streamVars[featureId].featureData.selectedChallengeSet].challengeData.map(
                                (val, i, arr) => { 
                                    const achName = val.title;
                                    const achievement = val.description;
                                    const achieved = challengeState?.challenges?.[i]?.completed ?? false;
                                    console.log('ACH', i, achieved)
                                    // const challengeSelectModeEnabled = customisationVars.current[featureId].leaderboard['compData'+streamVars[featureId].featureData.selectedChallengeSet].settings[0].enableChallengeSelectMode;
                                   // const challengeState = streamVars[featureId]?.challengeData?.[streamVars[featureId].featureData.selectedChallengeSet - 1] ?? {selectMode: challengeSelectModeEnabled};
                                  

                                    console.log('--- Challenge state ---', challengeState)

                                  /*  if(!achievement || achievement == undefined || (!props.previewControls && !challengeState.selectMode && (challengeState?.challengeData?.[i]?.selected ?? false) === false)) 
                                    {
                                        return(<></>)
                                    }*/

                                    // Inline component for high progressive form field so we can have a controlled input
                                    const HighProgressFormField = ({value, confirmChangeFunc, index, readonly}) => {
                                        const [currentValue, setCurrentValue] = useState(value);
                                        useEffect(() => {
                                            if (currentValue !== value) {
                                                setCurrentValue(value);
                                            }
                                        }, [value]);

                                        return (
                                            <FormField
                                                label={'Progress'}
                                                showLabel={false}
                                                id={'progress' + index}
                                                setFunc={(newVal) => { setCurrentValue(!isNaN(Number(newVal)) ? Number(newVal) : value) }}
                                                confirmChangeFunc={(newVal) => {if (confirmChangeFunc) confirmChangeFunc(!isNaN(Number(newVal)) ? Number(newVal) : value)}}
                                                type={'number'}
                                                min={0}
                                                max={9999999}
                                                value={currentValue}
                                                showError={false}
                                                placeholder={String(0)}
                                                readonly={readonly}
                                            />
                                        )
                                    }

                                    return (
                                        <div className={`achievementEntry ${i % 2 === 0 ? 'even-row' : 'odd-row'} 
                                        ${!challengeState.selectMode && (!challengeState?.challenges?.[i]?.selected ?? false) ? 'hidden' : ''} 
                                        ${challengeState.selectMode && (!challengeState?.challenges?.[i]?.selected ?? false) ? 'unselected' : ''}
                                         ${challengeState.selectMode && challengeState?.challenges?.[i]?.selected ? 'selected' : ''}` } 
                                        key={"achEntry_" + i}>

                                            <div className='fl-row'>
                                                <div className="shrink">
                                                    <h4 className="light-text">{(i + 1)}</h4>
                                                </div>
                                                <div className="grow">
                                                    <p><span className="bold-text">{achName}</span><br />{achievement}</p>
                                                </div>
                                                <div className="fl-row shrink">
                                                    {!challengeState?.challenges?.[i]?.completed &&
                                                    <div className="fl-column no-gap j-right">
                                                        <p>{val.type === 'streak' ? getNextStreakPoints(val, challengeState?.challenges?.[i].progress) : val.type === 'single' ? val.pointsSimple : val.completedPoints}</p>
                                                        {val.type === 'high-progressive' &&
                                                            <p className="light-text no-wrap">{challengeState?.challenges?.[i].progress} / {val.highProgressAmount}</p>
                                                        }
                                                        {val.type === 'progressive' &&
                                                            <p className="light-text no-wrap">{challengeState?.challenges?.[i].progress} / {val.progressAmount}</p>
                                                        }
                                                        {val.type === 'streak' &&
                                                            <p className="light-text no-wrap">{challengeState?.challenges?.[i].progress} / {val.pointClimberStages}</p>
                                                        }
                                                    
                                                    </div>
                                                    }
                                                    {val.type !== 'streak' && challengeState?.challenges?.[i]?.completed &&
                                                        <CheckBox
                                                            label=''
                                                            id={'toggle' + i}
                                                            initialState={achieved}
                                                            currentState={achieved}
                                                            setFunc={() =>{}}
                                                            unClickable={true}
                                                            //setFunc={(newVal) => { changeCustomisation(Boolean(newVal), customisationVars.current[featureId].leaderboard.achievementData.entries[i], 'isAchieved', customisationVars.current[featureId].leaderboard.achievementData) }}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                          
                                            <div className="fl-row">
                                                {val.type == 'single' &&
                                                <>
                                                    {(!challengeState.selectMode /* || props.previewControls */) && (challengeState?.challenges?.[i].selected ?? true) &&
                                                    <>
                                                        <button className={`standard-button ${(!challengeState.challengeListStarted || challengeState?.challenges?.[i].completed) ? 'not-allowed' : 'live-but-green'}`} onClick={(!challengeState.challengeListStarted || challengeState?.challenges?.[i].completed) ? null : (e) => {props.sendFeatureCommandFunc(e, 'AWARD_CHALLENGE', featureId, i+1)}}>{'Complete'}</button>
                                                        
                                                    </>
                                                    }
                                                    
                                                    {(challengeState.selectMode /* || props.previewControls */) && !achieved && (!allSelected || challengeState?.challenges?.[i].selected) &&
                                                        <button className="standard-button select-button" onClick={(e) => {props.sendFeatureCommandFunc(e, 'SELECT_CHALLENGE', featureId, i+1)}}>{challengeState?.challenges?.[i].selected ? 'Deselect' : 'Select'}</button>
                                                    }
                                                    {(challengeState.selectMode || (challengeState?.challenges?.[i].selected ?? true)) &&
                                                        <button className="standard-button live-but-red" onClick={(e) => {props.sendFeatureCommandFunc(e, 'RESET_CHALLENGE', featureId, i+1)}}>{'Reset'}</button>
                                                    }
                                                </>
                                                }
                                                {val.type == 'progressive' &&
                                                  <>
                                                    {(!challengeState.selectMode /* || props.previewControls */) && (challengeState?.challenges?.[i].selected ?? true) &&
                                                    <>
                                                    
                                                    <button className={`standard-button ${(!challengeState.challengeListStarted || challengeState?.challenges?.[i].completed) ? 'not-allowed' : 'live-but-orange'}`} onClick={(!challengeState.challengeListStarted || challengeState?.challenges?.[i].completed) ? null : (e) => {props.sendFeatureCommandFunc(e, 'RETREAT_CHALLENGE', featureId, i+1)}}>{' <<< -1'}</button>
                                                    <button className={`standard-button ${(!challengeState.challengeListStarted || challengeState?.challenges?.[i].completed) ? 'not-allowed' : 'live-but-green'}`} onClick={(!challengeState.challengeListStarted || challengeState?.challenges?.[i].completed) ? null : (e) => {props.sendFeatureCommandFunc(e, 'PROGRESS_CHALLENGE', featureId, i+1)}}>{'+1 >>>'}</button>   
                                                        
                                                        {/* <button className="standard-button" onClick={(e) => {props.sendFeatureCommandFunc(e, 'RESET_CHALLENGE_PROGRESS', featureId, i+1)}}>{'Reset Progress'}</button> */}
                                                    </>
                                                    }
                                                    
                                                    {(challengeState.selectMode /* || props.previewControls */) && !achieved && (!allSelected || challengeState?.challenges?.[i].selected) &&
                                                        <button className="standard-button select-button" onClick={(e) => {props.sendFeatureCommandFunc(e, 'SELECT_CHALLENGE', featureId, i+1)}}>{challengeState?.challenges?.[i].selected ? 'Deselect' : 'Select'}</button>
                                                    }
                                                    {(challengeState.selectMode || (challengeState?.challenges?.[i].selected ?? true)) &&
                                                        <button className="standard-button live-but-red" onClick={(e) => {props.sendFeatureCommandFunc(e, 'RESET_CHALLENGE', featureId, i+1)}}>{'Reset'}</button>
                                                    }
                                                </>
                                                }
                                                {val.type == 'high-progressive' && (challengeState.selectMode || (challengeState?.challenges?.[i].selected ?? true)) &&
                                                    <div className="fl-column compact grow">
                                                        <div className="fl-row grow">
                                                            {!challengeState.selectMode && (challengeState?.challenges?.[i].selected ?? true) &&
                                                                <div className="grow">
                                                                    <HighProgressFormField
                                                                        value={challengeState?.challenges?.[i]?.progress ?? 0}
                                                                        confirmChangeFunc={(!challengeState.challengeListStarted || challengeState?.challenges?.[i].completed) ? null : (newVal) => { props.sendFeatureCommandFunc(null, 'SET_HIGH_PROGRESSIVE_CHALLENGE_PROGRESS', featureId, {challengeId: i+1, amount: newVal}) }}
                                                                        index={i}
                                                                        readonly={(!challengeState.challengeListStarted || challengeState?.challenges?.[i].completed)}
                                                                    />
                                                                </div>
                                                            }
                                                            {(challengeState.selectMode /* || props.previewControls */) && !achieved && (!allSelected || challengeState?.challenges?.[i].selected) &&
                                                                <button className="standard-button select-button" onClick={(e) => {props.sendFeatureCommandFunc(e, 'SELECT_CHALLENGE', featureId, i+1)}}>{challengeState?.challenges?.[i].selected ? 'Deselect' : 'Select'}</button>
                                                            }
                                                            {(challengeState.selectMode || (challengeState?.challenges?.[i].selected ?? true)) &&
                                                                <button className="standard-button live-but-red" onClick={(e) => {props.sendFeatureCommandFunc(e, 'RESET_CHALLENGE', featureId, i+1)}}>{'Reset'}</button>
                                                            }
                                                        </div>
                                                        {!challengeState.selectMode && (challengeState?.challenges?.[i].selected ?? true) &&
                                                            <div className="fl-row grow">
                                                                <button className={`standard-button ${(!challengeState.challengeListStarted || challengeState?.challenges?.[i].completed) ? 'not-allowed' : 'live-but-orange'}`} onClick={(!challengeState.challengeListStarted || challengeState?.challenges?.[i].completed) ? null : (e) => {props.sendFeatureCommandFunc(e, 'ADJUST_HIGH_PROGRESSIVE_CHALLENGE_PROGRESS', featureId, {challengeId: i+1, amount: -val.largeControlButtonInc})}}>{`-${val.largeControlButtonInc}`}</button>
                                                                <button className={`standard-button ${(!challengeState.challengeListStarted || challengeState?.challenges?.[i].completed) ? 'not-allowed' : 'live-but-orange'}`} onClick={(!challengeState.challengeListStarted || challengeState?.challenges?.[i].completed) ? null : (e) => {props.sendFeatureCommandFunc(e, 'ADJUST_HIGH_PROGRESSIVE_CHALLENGE_PROGRESS', featureId, {challengeId: i+1, amount: -val.medControlButtonInc})}}>{`-${val.medControlButtonInc}`}</button>
                                                                <button className={`standard-button ${(!challengeState.challengeListStarted || challengeState?.challenges?.[i].completed) ? 'not-allowed' : 'live-but-orange'}`} onClick={(!challengeState.challengeListStarted || challengeState?.challenges?.[i].completed) ? null : (e) => {props.sendFeatureCommandFunc(e, 'ADJUST_HIGH_PROGRESSIVE_CHALLENGE_PROGRESS', featureId, {challengeId: i+1, amount: -val.smallControlButtonInc})}}>{`-${val.smallControlButtonInc}`}</button>
                                                                <button className={`standard-button ${(!challengeState.challengeListStarted || challengeState?.challenges?.[i].completed) ? 'not-allowed' : 'live-but-green'}`} onClick={(!challengeState.challengeListStarted || challengeState?.challenges?.[i].completed) ? null : (e) => {props.sendFeatureCommandFunc(e, 'ADJUST_HIGH_PROGRESSIVE_CHALLENGE_PROGRESS', featureId, {challengeId: i+1, amount: val.smallControlButtonInc})}}>{`+${val.smallControlButtonInc}`}</button>
                                                                <button className={`standard-button ${(!challengeState.challengeListStarted || challengeState?.challenges?.[i].completed) ? 'not-allowed' : 'live-but-green'}`} onClick={(!challengeState.challengeListStarted || challengeState?.challenges?.[i].completed) ? null : (e) => {props.sendFeatureCommandFunc(e, 'ADJUST_HIGH_PROGRESSIVE_CHALLENGE_PROGRESS', featureId, {challengeId: i+1, amount: val.medControlButtonInc})}}>{`+${val.medControlButtonInc}`}</button>
                                                                <button className={`standard-button ${(!challengeState.challengeListStarted || challengeState?.challenges?.[i].completed) ? 'not-allowed' : 'live-but-green'}`} onClick={(!challengeState.challengeListStarted || challengeState?.challenges?.[i].completed) ? null : (e) => {props.sendFeatureCommandFunc(e, 'ADJUST_HIGH_PROGRESSIVE_CHALLENGE_PROGRESS', featureId, {challengeId: i+1, amount: val.largeControlButtonInc})}}>{`+${val.largeControlButtonInc}`}</button>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                {val.type == 'streak' &&
                                                <>
                                                    {(!challengeState.selectMode /* || props.previewControls */) && (challengeState?.challenges?.[i].selected ?? true) &&
                                                    <>
                                                     <button className={`standard-button ${(!challengeState.challengeListStarted) ? 'not-allowed' : 'live-but-orange'}`} onClick={(!challengeState.challengeListStarted) ? null : (e) => {props.sendFeatureCommandFunc(e, 'RETREAT_CHALLENGE', featureId, i+1)}}>{'<<< -1'}</button>
                                                        <button className={`standard-button ${(!challengeState.challengeListStarted) ? 'not-allowed' : 'live-but-green'}`} onClick={(!challengeState.challengeListStarted) ? null : (e) => {props.sendFeatureCommandFunc(e, 'PROGRESS_CHALLENGE', featureId, i+1)}}>{'+1 >>>'}</button>
                                                       
                                                        {/* <button className="standard-button" onClick={(e) => {props.sendFeatureCommandFunc(e, 'RESET_CHALLENGE_PROGRESS', featureId, i+1)}}>{'Reset Progress'}</button> */}
                                                        
                                                    </>
                                                    }
                                                    
                                                    {(challengeState.selectMode /* || props.previewControls */) && !achieved && (!allSelected || challengeState?.challenges?.[i].selected) &&
                                                        <button className="standard-button select-button" onClick={(e) => {props.sendFeatureCommandFunc(e, 'SELECT_CHALLENGE', featureId, i+1)}}>{challengeState?.challenges?.[i].selected ? 'Deselect' : 'Select'}</button>
                                                    }
                                                    {(challengeState.selectMode || (challengeState?.challenges?.[i].selected ?? true)) &&
                                                        <button className="standard-button live-but-red" onClick={(e) => {props.sendFeatureCommandFunc(e, 'RESET_CHALLENGE', featureId, i+1)}}>{'Reset'}</button>
                                                    }
                                                </>
                                                }
                                                {val.type == 'timed' &&
                                                <>
                                                    {(!challengeState.selectMode /* || props.previewControls */) && (challengeState?.challenges?.[i].selected ?? true) &&
                                                    <>
                                                       
                                                       {challengeState?.challenges?.[i].timeStarted == 0  && <button className={`standard-button${(!challengeState.challengeListStarted || challengeState?.challenges?.[i].completed) ? ' not-allowed' : ''}`} onClick={(!challengeState.challengeListStarted || challengeState?.challenges?.[i].completed) ? null : (e) => {props.sendFeatureCommandFunc(e, 'START_MANUAL_TIMER', featureId, i+1)}}>{'Start'}</button>}
                                                       {challengeState?.challenges?.[i].timeStarted != 0  && <button className={`standard-button ${(!challengeState.challengeListStarted || challengeState?.challenges?.[i].completed) ? 'not-allowed' : 'live-but-red'}`} onClick={(!challengeState.challengeListStarted || challengeState?.challenges?.[i].completed) ? null : (e) => {props.sendFeatureCommandFunc(e, 'STOP_MANUAL_TIMER', featureId, i+1)}}>{'Stop'}</button>}
                                                        <button className={`standard-button ${(!challengeState.challengeListStarted || challengeState?.challenges?.[i].completed) ? 'not-allowed' : 'live-but-green'}`} onClick={(e) => {props.sendFeatureCommandFunc(e, 'AWARD_CHALLENGE', featureId, i+1)}}>{'Complete'}</button>
                                                  
                                                        
                                                         </>
                                                    }
                                                    {(challengeState.selectMode /* || props.previewControls */) && !achieved && (!allSelected || challengeState?.challenges?.[i].selected) &&
                                                        <button className="standard-button select-button" onClick={(e) => {props.sendFeatureCommandFunc(e, 'SELECT_CHALLENGE', featureId, i+1)}}>{challengeState?.challenges?.[i].selected ? 'Deselect' : 'Select'}</button>
                                                    }
                                                    {(challengeState.selectMode || (challengeState?.challenges?.[i].selected ?? true)) &&
                                                        <button className="standard-button live-but-red" onClick={(e) => {props.sendFeatureCommandFunc(e, 'RESET_CHALLENGE', featureId, i+1)}}>{'Reset'}</button>
                                                    }
                                                </>
                                                }
                                            </div>
                                        </div>
                                    )
                                    
                                })}
                                </div>
                                
                            </div>
                            <div className="grow">
                            {(challengeState.selectMode /* || props.previewControls */) && 
                            <div className="button-grid">
                                <button className="standard-button" onClick={(e) => {props.sendFeatureCommandFunc(e, 'RANDOMISE_SELECTED_CHALLENGES', featureId, i+1)}}>{'Randomise Selections'}</button>
                                <button className="standard-button live-but-red" onClick={(e) => {props.sendFeatureCommandFunc(e, 'RESET_SELECTED_CHALLENGES', featureId, i+1)}}>{'Reset ALL Selections'}</button>     
                            </div>
                            }
                            </div>
                            <div className="button-grid">
                            {challengeState.selectMode &&
                                    <button className={`standard-button${countSelectedChallenges() === 0 ? ' not-allowed' : ' live-but-orange'}`} style={{minWidth: '100%'}} onClick={countSelectedChallenges() === 0 ? null : (e) => {props.sendFeatureCommandFunc(e, 'READY_CHALLENGE_LIST', featureId, 0); refreshList()}}>Ready Up Challenges</button>
                                }
                                {!challengeState.challengeListStarted &&
                                    <button className={`standard-button${countSelectedChallenges() === 0 ? ' not-allowed' : ' live-but-green'}`} style={{minWidth: '100%'}} onClick={countSelectedChallenges() === 0 ? null : (e) => {props.sendFeatureCommandFunc(e, 'START_CHALLENGE_LIST', featureId, 0); refreshList()}}>Start Challenges</button>
                                }
                                {challengeState.challengeListStarted &&
                                    <button className="standard-button live-but-red" style={{minWidth: '100%'}} onClick={(e) => {props.sendFeatureCommandFunc(e, 'STOP_CHALLENGE_LIST', featureId, 0); refreshList();}}>End Challenges</button>
                                }
                             
                                {!challengeState.selectMode && !challengeState.challengeListStarted &&
                                    <button className="standard-button" style={{minWidth: '100%'}} onClick={(e) => {props.sendFeatureCommandFunc(e, 'RESET_SELECTED_CHALLENGES', featureId, 0); refreshList();}}>Select Challenge Mode</button>
                                }
                                   </div>
                            
                            <div className="fc-column">
                                <div className="feature-control-holder fc-expanded">

                                {challengeState.selectMode &&<div className="info-text"><strong>Select Mode</strong><br /> Choose the challenges the streamer wants to take on. You can select fewer than the required number, but no more.</div>}
                          
                                {!challengeState.selectMode && !challengeState.challengeListStarted && <div className="info-text"><strong>Ready Up Mode</strong><br />Displays the selected challenges, but timers, progress, and completions are locked until you start the challenge list.</div>}
                          
                                {!challengeState.selectMode && challengeState.challengeListStarted &&   <div className="info-text"><strong>Live Mode</strong><br />Challenges can be updated in real-time, timers started, and challenges completed.</div>}
                                </div>
                            </div>
                            <div className="button-grid">
                            {!challengeState.challengeListStarted &&
                                    <>
                                        <button className="standard-button live-but-red" style={{minWidth: '100%'}} onClick={(e) => {props.sendFeatureCommandFunc(e, 'HARD_RESET_CHALLENGES', featureId, 0)}}>RESET ALL CHALLENGES</button>
                                        <button className="standard-button live-but-red" style={{minWidth: '100%'}} onClick={(e) => {props.sendFeatureCommandFunc(e, 'HARD_RESET_SCORE', featureId, 0)}}>RESET SCORE</button>
                                    </>
                                }
                            </div>
                        </>
                       }        
                    </div>
                )
            case FEATURE_CONTROLS_TYPES.ClawCameraFeed.type:
                return (
                    <ClawCameraFeeds />
                )

            case FEATURE_CONTROLS_TYPES.LivePollSelector.type:
                return (
                    <LivePollSelector 
                        label={varVal.label || varVal.varName}
                        id={varVal.varName}
                        setFunc={(newVal) => { changeVal(groupVal.varKey, varVal.varName, newVal) }}
                        changeValFunc={changeVal}
                        customisationData={customisationVars.current[featureId]}
                        featureVars={streamVars[featureId]}
                        passedInPreviewData={props.passedInPreviewData}
                        featureId={featureId}
                        value={getValFromObject(streamVars, featureId, groupVal.varKey + '.' + varVal.varName)}
                    />
                )
            case FEATURE_CONTROLS_TYPES.LivePollAnswerSelector.type:
                return (
                    <div className="fl-column">
                        {showChatNotConnectedMessage &&
                            <div className="warning-text bold-text">
                                Some aspects of live polls (eg. reporting back of results) require 2-way communication between the dashboard and the running feature. To fully enable live polls, please click Connect to Channels and make sure the Extension Page is open.
                            </div>
                        }
                        <LivePollAnswerSelector
                            label={varVal.label || varVal.varName}
                            id={varVal.varName}
                            setFunc={(newVal) => { changeVal(groupVal.varKey, varVal.varName, newVal) }}
                            customisationData={customisationVars.current[featureId]}
                            customisationVars={customisationVars}
                            featureVars={streamVars[featureId]}
                            featureId={featureId}
                            value={getValFromObject(streamVars, featureId, groupVal.varKey + '.' + varVal.varName)}
                            previewControls={props.previewControls}
                            passedInPreviewData={props.passedInPreviewData}
                            changeValFunc={changeVal}
                            reportCustomisationVarsChanged={props.saveCustomisationVarsFunc}
                            isPreview={props.previewControls}
                            channels={props.channels}
                        />
                    </div>
                )
            default:
                return (null);
        }
    }

    const [highlightedButtons, setHighlightedButtons] = useState([]);
    const highlightedButtons_ref = useRef([]);
    const highlightButton = (groupVal, command, buttonIndex) => {
        // console.log('Hilight button: ', groupVal, command, buttonIndex);
        const newHighlightedButton = {group: groupVal.varKey, command: command, buttonIndex: buttonIndex};

        for (let i = 0; i < highlightedButtons_ref.current.length; i++) {
            if (highlightedButtons_ref.current[i].group === newHighlightedButton.group && highlightedButtons_ref.current[i].command === newHighlightedButton.command && highlightedButtons_ref.current[i].buttonIndex === newHighlightedButton.buttonIndex) {
                return;
            }
        }
        // We only get here if we didn't find a match
        const newHighlightedButtonsData = [...highlightedButtons_ref.current];
        newHighlightedButtonsData.push(newHighlightedButton);
        setHighlightedButtons(newHighlightedButtonsData);
        highlightedButtons_ref.current = newHighlightedButtonsData;
        setTimeout(() => {
            removeButtonHighlight(groupVal, command, buttonIndex);
        }, 300);
    }
    const removeButtonHighlight = (groupVal, command, buttonIndex) => {
        if (highlightedButtons_ref.current && highlightedButtons_ref.current.length > 0) {
            const newHighlightedButtonsData = highlightedButtons_ref.current.filter(
                (val, i, arr) => {
                    return !(val.group === groupVal.varKey && val.command === command && val.buttonIndex === buttonIndex);
                }
            )
            setHighlightedButtons(newHighlightedButtonsData);
            highlightedButtons_ref.current = newHighlightedButtonsData;
        }
    }
    const shouldHighlightButton = (groupVal, command, buttonIndex) => {
        for (let i = 0; i < highlightedButtons.length; i++) {
            if (highlightedButtons[i].group === groupVal.varKey && highlightedButtons[i].command === command && highlightedButtons[i].buttonIndex === buttonIndex) {
                return true;
            }
        }
        return false;
    }

    const renderCommandButtons = (groupVal, varIndex = 0, varArr = []) => {
        return (
            <div className="button-grid">
                {groupVal.commandButtons.map(
                    (bVal, bI, bArr) => {
                        // console.log('Feature control button: ', bVal);
                        if ((bVal.index === varIndex && bVal.index < varArr.length) || (varIndex >= varArr.length - 1 && bVal.index >= varIndex) || varArr.length === 0) {

                            /* BUTTONS NOW HAVE DISPLAY CONDITIONS */
                            let displayButton = true;
                            if (bVal.displayCondition) {
                                let ignoreMoreConditions = false;
                                bVal.displayCondition.forEach(
                                    (condVal, condI, condArr) => {
                                        if (ignoreMoreConditions) {
                                            return;
                                        }
                                        if (displayButton && condVal.evalCondition === 'OR') {
                                            ignoreMoreConditions = true;
                                        } else
                                        if (!displayButton && condVal.evalCondition === 'OR') {
                                            if (condI < condArr.length - 1) {
                                                displayButton = true;
                                            }
                                        } else
                                        if (displayButton) {
                                            // We can now check for conditions in customisationVars or streamVars
                                            if (!ignoreCustomisationDisplayConditions || !condVal.customisationCondition) {
                                                let displayConditionVal =
                                                    condVal.customisationCondition ?
                                                        getValFromObject(customisationVars?.current ?? customisationVars, featureId, condVal.var) :
                                                        getValFromObject(streamVars, featureId, groupVal.varKey + '.' + condVal.var);
                                                    if (props.passedInPreviewData /* && (displayConditionVal === null || typeof displayConditionVal === 'undefined') */) {
                                                        // console.log('BUTTON DISPLAY COND, looking in previewData: ', props.passedInPreviewData);
                                                        // Try to get the value from the passed in preview data
                                                        const dataStruct = {};
                                                        dataStruct[featureId] = props.passedInPreviewData;
                                                        // displayConditionVal = getValFromObject(dataStruct, featureId, groupVal.varKey + '.' + condVal.var);
                                                        const displayConditionVal_passed = getValFromObject(dataStruct, featureId, groupVal.varKey + '.' + condVal.var);
                                                        if (displayConditionVal_passed !== null && typeof displayConditionVal_passed !== 'undefined') {
                                                            displayConditionVal = displayConditionVal_passed;
                                                        }
                                                    }
                                                if (!condVal.customisationCondition && (typeof displayConditionVal === 'undefined' || displayConditionVal === null)) {
                                                    // Try a more general search of streamVars, so we can hide panels based on variables in other groups / panels
                                                    displayConditionVal = getValFromObject(streamVars, featureId, condVal.var);
                                                }
                                                /* if (condVal.customisationCondition) {
                                                    console.log('Customisation condition: ', customisationVars, condVal, displayConditionVal, condVal.evalCondition, condVal.val);
                                                } */
                                                // Anything that is not a number needs to be enclosed in quotes in our eval string (so we can make > and < work and also arbitrary strings like "none")
                                                const evalStr = typeof condVal.val === 'number' && typeof displayConditionVal === 'number' ?
                                                    String(displayConditionVal) + condVal.evalCondition + String(condVal.val) :
                                                    "'" + String(displayConditionVal) + "'" + condVal.evalCondition + "'" + String(condVal.val) + "'";
                                                displayButton = eval?.(evalStr);
                                            }
                                        }
                                    }
                                )
                            }
                            if (!displayButton) {
                                return null;
                            }

                            let enableButton = true;
                            if (bVal.disabledCondition) {
                                let ignoreMoreConditions = false;
                                bVal.disabledCondition.forEach(
                                    (condVal, condI, condArr) => {
                                        if (ignoreMoreConditions) {
                                            return;
                                        }
                                        if (!enableButton && condVal.evalCondition === 'OR') {
                                            ignoreMoreConditions = true;
                                        } else
                                        if (enableButton && condVal.evalCondition === 'OR') {
                                            if (condI < condArr.length - 1) {
                                                enableButton = true;
                                            }
                                        } else
                                        if (enableButton) {
                                            // We can now check for conditions in customisationVars or streamVars
                                            if (!ignoreCustomisationDisplayConditions || !condVal.customisationCondition) {
                                                let displayConditionVal =
                                                    condVal.customisationCondition ?
                                                        getValFromObject(customisationVars?.current ?? customisationVars, featureId, condVal.var) :
                                                        getValFromObject(streamVars, featureId, groupVal.varKey + '.' + condVal.var);
                                                    if (props.passedInPreviewData /* && (displayConditionVal === null || typeof displayConditionVal === 'undefined') */) {
                                                        // console.log('BUTTON DISPLAY COND, looking in previewData: ', props.passedInPreviewData);
                                                        // Try to get the value from the passed in preview data
                                                        const dataStruct = {};
                                                        dataStruct[featureId] = props.passedInPreviewData;
                                                        // displayConditionVal = getValFromObject(dataStruct, featureId, groupVal.varKey + '.' + condVal.var);
                                                        const displayConditionVal_passed = getValFromObject(dataStruct, featureId, groupVal.varKey + '.' + condVal.var);
                                                        if (displayConditionVal_passed !== null && typeof displayConditionVal_passed !== 'undefined') {
                                                            displayConditionVal = displayConditionVal_passed;
                                                        }
                                                    }
                                                if (!condVal.customisationCondition && (typeof displayConditionVal === 'undefined' || displayConditionVal === null)) {
                                                    // Try a more general search of streamVars, so we can hide panels based on variables in other groups / panels
                                                    displayConditionVal = getValFromObject(streamVars, featureId, condVal.var);
                                                }
                                                /* if (condVal.customisationCondition) {
                                                    console.log('Customisation condition: ', customisationVars, condVal, displayConditionVal, condVal.evalCondition, condVal.val);
                                                } */
                                                // Anything that is not a number needs to be enclosed in quotes in our eval string (so we can make > and < work and also arbitrary strings like "none")
                                                const evalStr = typeof condVal.val === 'number' && typeof displayConditionVal === 'number' ?
                                                    String(displayConditionVal) + condVal.evalCondition + String(condVal.val) :
                                                    "'" + String(displayConditionVal) + "'" + condVal.evalCondition + "'" + String(condVal.val) + "'";
                                                    enableButton = !(eval?.(evalStr));
                                            }
                                        }
                                    }
                                )
                            }

                            let buttonLabel = bVal.label;
                            let buttonCommand = bVal.command;
                            let param = null;
                            let paramArray = null;
                            if (typeof buttonCommand === 'number') {
                                // We have a number as a command, so we need to look this one up!
                                if (buttonCommand >= 0 && controlsData.commands.length > buttonCommand) {
                                    const command = controlsData.commands[buttonCommand]
                                    buttonCommand = command.command;
                                    if (typeof command.param === 'string' && Array.isArray(controlsData[command.param])) {
                                        // Kind of a legacy type so we can keep the multiple param buttons working while getting customisation up and running.
                                        // We have a param that references an array property in our feature, our label should show something relevant to the param
                                        buttonLabel += ' ';
                                        paramArray = controlsData[command.param];
                                    } else {
                                        if (typeof command.param === 'string') {
                                            paramArray = FindParamArrayFromCustomisations(props.customisationVars, featureId, command.param);
                                        }
                                        if (paramArray === null) {
                                            param = command.param;
                                        }
                                    }
                                }
                            }

                            let addSecondaryButton = bVal.addRelatedButton;
                            let secondButtonLabel = bVal.relatedButton ? bVal.relatedButton.label : '';
                            let secondButtonCommand = bVal.relatedButton ? bVal.relatedButton.command : '';
                            if (typeof secondButtonCommand === 'number') {
                                // We have a number as a command, so we need to look this one up!
                                if (secondButtonCommand >= 0 && controlsData.commands.length > secondButtonCommand) {
                                    const command = controlsData.commands[secondButtonCommand]
                                    secondButtonCommand = command.command;
                                    if (typeof command.param === 'string' && Array.isArray(controlsData[command.param])) {
                                        // Kind of a legacy type so we can keep the multiple param buttons working while getting customisation up and running.
                                        // We have a param that references an array property in our feature, our label should show something relevant to the param
                                        secondButtonLabel += ' ';
                                    }
                                }
                            }

                            const buttonStyle = {};
                            if (bVal.width) {
                                buttonStyle.minWidth = bVal.width + '%';
                            }

                            let buttonColour = enableButton ? '' : 'disabled';
                            let secondaryButtonColour = enableButton ? '' : 'disabled';
                            if (buttonColour === '' && bVal.colour) {
                                buttonColour = bVal.colour;
                            }
                            if (secondaryButtonColour === '' && bVal.relatedButton && bVal.relatedButton.colour) {
                                secondaryButtonColour = bVal.relatedButton.colour;
                            }
                            if (buttonColour === '') {
                                // Based on the command, we can set a default colour. isPrimaryTrigger = green and isEndTrigger = red
                                for (let i = 0; i < controlsData.commands.length; i++) {
                                    if (controlsData.commands[i].command === buttonCommand) {
                                        if (controlsData.commands[i].isPrimaryTrigger) {
                                            buttonColour = 'green';
                                        }
                                        if (controlsData.commands[i].isEndTrigger) {
                                            buttonColour = 'red';
                                        }
                                    }
                                    if (buttonColour !== '') {
                                        break;
                                    }
                                }
                            }
                            if (secondaryButtonColour === '') {
                                // Based on the command, we can set a default colour. isPrimaryTrigger = green and isEndTrigger = red
                                for (let i = 0; i < controlsData.commands.length; i++) {
                                    if (controlsData.commands[i].command === secondButtonCommand) {
                                        if (controlsData.commands[i].isPrimaryTrigger) {
                                            secondaryButtonColour = 'green';
                                        }
                                        if (controlsData.commands[i].isEndTrigger) {
                                            secondaryButtonColour = 'red';
                                        }
                                    }
                                    if (secondaryButtonColour !== '') {
                                        break;
                                    }
                                }
                            }
                            if (paramArray === null) {
                                return (
                                    <Fragment key={'bgrid_' + bI}>
                                        <button key={'btn_' + bI} className={`standard-button live-but-${buttonColour}`} onClick={enableButton ? (e) => {highlightButton(groupVal, buttonCommand, bI); props.sendFeatureCommandFunc(e, buttonCommand, featureId, param)} : null} style={buttonStyle}>
                                            {buttonLabel}
                                            {shouldHighlightButton(groupVal, buttonCommand, bI) &&
                                                <>
                                                    <div className="live-but-highlighted-overlay"></div>
                                                    <img className="live-but-spinner" src={assets.ButtonSpinner} alt="" />
                                                </>
                                            }
                                        </button>
                                        {addSecondaryButton &&
                                            <button key={'btn_' + bI + '_2'} className={`standard-button live-but-${secondaryButtonColour}`} onClick={enableButton ? (e) => {highlightButton(groupVal, secondButtonCommand, bI); props.sendFeatureCommandFunc(e, secondButtonCommand, featureId, param)} : null} style={buttonStyle}>
                                                {secondButtonLabel}
                                                {shouldHighlightButton(groupVal, secondButtonCommand, bI) &&
                                                    <>
                                                        <div className="live-but-highlighted-overlay"></div>
                                                        <img className="live-but-spinner" src={assets.ButtonSpinner} alt="" />
                                                    </>
                                                }
                                            </button>
                                        }
                                    </Fragment>
                                )
                            }
                            return (
                                <div className="button-grid" style={{ marginBottom: 0 }} key={'bg_' + bI}>
                                    {paramArray.map(
                                        (pVal, pI, pArr) => {
                                            return (
                                                <Fragment key={'bgrid_' + bI + '_' + pI}>
                                                    <button key={'btn_' + pI} className={`standard-button live-but-${buttonColour}`} onClick={enableButton ? (e) => {highlightButton(groupVal, buttonCommand, pI); props.sendFeatureCommandFunc(e, buttonCommand, featureId, pI)} : null} style={buttonStyle}>
                                                        {buttonLabel + ' ' + (pVal.label || pVal.name || 'Item ' + (pI + 1))}
                                                        {shouldHighlightButton(groupVal, buttonCommand, pI) &&
                                                            <>
                                                                <div className="live-but-highlighted-overlay"></div>
                                                                <img className="live-but-spinner" src={assets.ButtonSpinner} alt="" />
                                                            </>
                                                        }
                                                    </button>
                                                    {addSecondaryButton &&
                                                        <button key={'btn_' + pI + '_2'} className={`standard-button live-but-${secondaryButtonColour}`} onClick={(e) => {highlightButton(groupVal, secondButtonCommand, pI); props.sendFeatureCommandFunc(e, secondButtonCommand, featureId, pI)}} style={buttonStyle}>
                                                            {secondButtonLabel + ' ' + (pVal.label || pVal.name || 'Item ' + (pI + 1))}
                                                            {shouldHighlightButton(groupVal, secondButtonCommand, pI) &&
                                                                <>
                                                                    <div className="live-but-highlighted-overlay"></div>
                                                                    <img className="live-but-spinner" src={assets.ButtonSpinner} alt="" />
                                                                </>
                                                            }
                                                        </button>
                                                    }
                                                </Fragment>
                                            )
                                        }
                                    )}
                                </div>
                            )
                        }
                        return null;
                    }
                )}
            </div>
        )
    }

    const navigate = useNavigate();
    const customiseFeature = () => {
        navigate(TOP_LEVEL_NAV_ROUTES.CUSTOMISE_FEATURE_STREAM.replace(':campaignId', props.campaignId).replace(':featureId', props.featureId).replace(':streamId', props.streamId));
    }

    return (
        <>
            <div className="fl-row">
                <h3>{props.feature.contentLabel} ({controlLabel}) Controls</h3>
                {!props.previewControls &&
                <>
                    <div className={`edit-icon${props.chatConnected ? ' disabled' : ''}`} onClick={props.chatConnected ? null : (e) => customiseFeature()}>
                        {IconJsxer.GetIcon(IconJsxer.ICONS.edit, IconJsxer.ICON_STYLES.campaignPanelTop)}
                    </div>
                    <div className="grow"></div>
                </>
                }
            </div>
            {props.featureChain &&
                <ChainTimeline
                    featureChain={props.featureChain}
                    featureId={props.featureId}
                    setChainItemVal={props.setChainItemVal}
                    startChain={props.startChain}
                    stopChain={props.stopChain}
                    endFeatureFunc={props.endFeatureFunc}
                    skipChainItemFunc={props.skipChainItemFunc}
                    chainSkipAllowed={props.chainSkipAllowed}
                    togglePreviewMode={props.togglePreviewMode}
                    chatConnected={props.chatConnected}
                />
            }
            <div className="feature-controls-holder no-panel-shadow">
                {controlsData.defaultVars.map(
                    (groupVal, i, groupArr) => {
                        if ((!groupVal.variables || groupVal.variables.length === 0) && (!groupVal.commandButtons || groupVal.commandButtons.length === 0)) {
                            return null;
                        }
                        if (groupVal.previewModeOnly === true && !props.previewControls) {
                            return null;
                        }

                        // We can now hide whole groups based on conditions
                        let displayControlGroup = true;
                        if (groupVal.displayCondition) {
                            let ignoreMoreConditions = false;
                            groupVal.displayCondition.forEach(
                                (condVal, condI, condArr) => {
                                    if (ignoreMoreConditions) {
                                        return;
                                    }
                                    if (displayControlGroup && condVal.evalCondition === 'OR') {
                                        ignoreMoreConditions = true;
                                    } else
                                    if (!displayControlGroup && condVal.evalCondition === 'OR') {
                                        if (condI < condArr.length - 1) {
                                            displayControlGroup = true;
                                        }
                                    } else
                                    if (displayControlGroup) {
                                        // We can now check for conditions in customisationVars or streamVars
                                        if (!ignoreCustomisationDisplayConditions || !condVal.customisationCondition) {
                                            let displayConditionVal =
                                                condVal.customisationCondition ?
                                                    getValFromObject(customisationVars?.current ?? customisationVars, featureId, condVal.var) :
                                                    getValFromObject(streamVars, featureId, groupVal.varKey + '.' + condVal.var);
                                            if (props.passedInPreviewData /* && (displayConditionVal === null || typeof displayConditionVal === 'undefined') */) {
                                                // console.log('GROUP DISPLAY COND, looking in previewData: ', props.passedInPreviewData);
                                                // Try to get the value from the passed in preview data
                                                const dataStruct = {};
                                                dataStruct[featureId] = props.passedInPreviewData;
                                                // displayConditionVal = getValFromObject(dataStruct, featureId, groupVal.varKey + '.' + condVal.var);
                                                const displayConditionVal_passed = getValFromObject(dataStruct, featureId, groupVal.varKey + '.' + condVal.var);
                                                if (displayConditionVal_passed !== null && typeof displayConditionVal_passed !== 'undefined') {
                                                    displayConditionVal = displayConditionVal_passed;
                                                }
                                            }
                                            if (!condVal.customisationCondition && (typeof displayConditionVal === 'undefined' || displayConditionVal === null)) {
                                                // Try a more general search of streamVars, so we can hide panels based on variables in other groups / panels
                                                displayConditionVal = getValFromObject(streamVars, featureId, condVal.var);
                                            }
                                            /* if (condVal.customisationCondition) {
                                                console.log('Customisation condition: ', customisationVars, condVal, displayConditionVal, condVal.evalCondition, condVal.val);
                                            } */
                                            // Anything that is not a number needs to be enclosed in quotes in our eval string (so we can make > and < work and also arbitrary strings like "none")
                                            const evalStr = typeof condVal.val === 'number' && typeof displayConditionVal === 'number' ?
                                                String(displayConditionVal) + condVal.evalCondition + String(condVal.val) :
                                                "'" + String(displayConditionVal) + "'" + condVal.evalCondition + "'" + String(condVal.val) + "'";
                                            displayControlGroup = eval?.(evalStr);
                                        }
                                    }
                                }
                            )
                        }
                        if (!displayControlGroup) {
                            return null;
                        }

                        return (
                            <div className="fl-column" key={'c_' + i}>
                                {/* <HeadedPanel color={'primary-light'}
                                    headingContent={<div className="feature-control-header"><h4>{groupVal.groupTitle}</h4></div>}
                                    expandable={true}
                                    startCollapsed={props.startCollapsed !== undefined ? ((groupVal.startExpanded && !props.forceCollapsed) ? false : props.startCollapsed) : (groupVal.startExpanded ? false : true)}
                                > */}
                                <div className="live-control-group-holder">
                                <CollapsibleHeadedSection preHeaderContent={
                                                                <div className="group-icon">
                                                                        {IconJsxer.GetIcon(IconJsxer.ICONS.customisationGroup, IconJsxer.ICON_STYLES.standard)}
                                                                    </div>
                                                            } title={groupVal.groupTitle} open={!(props.startCollapsed !== undefined ? ((groupVal.startExpanded && !props.forceCollapsed) ? false : props.startCollapsed) : (groupVal.startExpanded ? false : true))}>
                                    <div className="control-group">
                                        <div className="inner-box">
                                        {
                                            groupVal.variables.map(
                                                (varVal, j, varArr) => {
                                                    let displayControl = true;
                                                    if (varVal.displayCondition) {
                                                        let ignoreMoreConditions = false;
                                                        varVal.displayCondition.forEach(
                                                            (condVal, condI, condArr) => {
                                                                if (ignoreMoreConditions) {
                                                                    return;
                                                                }
                                                                if (displayControl && condVal.evalCondition === 'OR') {
                                                                    ignoreMoreConditions = true;
                                                                } else
                                                                if (!displayControl && condVal.evalCondition === 'OR') {
                                                                    if (condI < condArr.length - 1) {
                                                                        displayControl = true;
                                                                    }
                                                                } else
                                                                if (displayControl) {
                                                                    // We can now check for conditions in customisationVars or streamVars
                                                                    if (!ignoreCustomisationDisplayConditions || !condVal.customisationCondition) {
                                                                        let displayConditionVal = 
                                                                            condVal.customisationCondition ?
                                                                            getValFromObject(customisationVars?.current ?? customisationVars, featureId, condVal.var) :
                                                                            getValFromObject(streamVars, featureId, groupVal.varKey + '.' + condVal.var);
                                                                        if (props.passedInPreviewData /* && (displayConditionVal === null || typeof displayConditionVal === 'undefined') */) {
                                                                            // console.log('CONTROL DISPLAY COND, looking in previewData: ', props.passedInPreviewData);
                                                                            // Try to get the value from the passed in preview data
                                                                            const dataStruct = {};
                                                                            dataStruct[featureId] = props.passedInPreviewData;
                                                                            // displayConditionVal = getValFromObject(dataStruct, featureId, groupVal.varKey + '.' + condVal.var);
                                                                            const displayConditionVal_passed = getValFromObject(dataStruct, featureId, groupVal.varKey + '.' + condVal.var);
                                                                            if (displayConditionVal_passed !== null && typeof displayConditionVal_passed !== 'undefined') {
                                                                                displayConditionVal = displayConditionVal_passed;
                                                                            }
                                                                        }
                                                                        // Anything that is not a number needs to be enclosed in quotes in our eval string (so we can make > and < work and also arbitrary strings like "none")
                                                                        const evalStr = typeof condVal.val=== 'number' && typeof displayConditionVal === 'number' ?
                                                                            String(displayConditionVal) + condVal.evalCondition + String(condVal.val) :
                                                                            "'" + String(displayConditionVal) + "'" + condVal.evalCondition + "'" + String(condVal.val) + "'";
                                                                        displayControl = eval?.(evalStr);
                                                                    }
                                                                }
                                                            }
                                                        )
                                                    }
                                                    let hasButtons = false;
                                                    if (groupVal?.commandButtons?.length) {
                                                        groupVal.commandButtons.forEach(
                                                            (bVal, bI, bArr) => {
                                                                if ((bVal.index === j && bVal.index < varArr.length) || (j >= varArr.length - 1 && bVal.index >= j)) {
                                                                    hasButtons = true;
                                                                }
                                                            }
                                                        )
                                                    }
                                                    // if (displayControl || hasButtons) {
                                                    return (
                                                        <div className="fc-column" key={'var_' + j + '_' + varVal.varName}>
                                                            <div className={`feature-control-holder${displayControl ? ' fc-expanded' : ''}`}>
                                                                {// displayControl &&
                                                                    getControl(varVal, groupVal)
                                                                }
                                                            </div>
                                                            {hasButtons &&
                                                                renderCommandButtons(groupVal, j, varArr)
                                                            }
                                                        </div>
                                                    )
                                                }
                                            )
                                        }
                                        {groupVal.variables.length === 0 && 
                                            renderCommandButtons(groupVal)
                                        }
                                        </div>
                                    </div>
                                </CollapsibleHeadedSection>
                                </div>
                                {/* </HeadedPanel> */}
                                <div className="grow"></div>
                            </div>
                        )
                    }
                )}

{props.isThemeable && props.themeData &&
                    <div className="fl-column">
                        <div className="live-control-group-holder">
                            <CollapsibleHeadedSection 
                                preHeaderContent={
                                    <div className="group-icon">
                                        {IconJsxer.GetIcon(IconJsxer.ICONS.customisationGroup, IconJsxer.ICON_STYLES.standard)}
                                    </div>
                                } 
                                title={'Theme Control'} 
                                open={true}
                            >
                                <div className="control-group">
                                    <div className="inner-box">

                                        <ThemeSelector
                                            themeData={props.themeData}
                                            changeVal={changeVal}
                                            initialTheme={streamVars[featureId]?.theme?.id ?? ''}
                                            featureId={featureId}
                                            applyThemeFunc={props.applyThemeFunc}
                                        />

                                    </div>
                                </div>
                            </CollapsibleHeadedSection>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default FeatureControls;
