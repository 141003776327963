import "./ChainTimeline.scss";

import React, { useContext, useEffect, useRef, useState } from "react";
import { CampaignContext } from "../../contexts/CampaignContext";

import { IconJsxer } from "../../helpers/IconHelper";
import Toggle from "../forms/Toggle/Toggle";
import HMSTimeField from "../forms/HMSTimeField/HMSTimeField";

const ChainTimeline = (props) => {
    const {featureChain, featureId, setChainItemVal, togglePreviewMode, chatConnected} = props;

    const [campaignContext, setCampaignContext] = useContext(CampaignContext);
    const campaignData = campaignContext.campaignData;

    const findFeatureData = (featureId) => {
        for (let i = 0; i < campaignData.features.length; i++) {
            if (campaignData.features[i]._id === featureId) {
                return campaignData.features[i];
            }
        }
        return null;
    }

    console.log('ChainTimeline: ', featureChain, featureId);

    return (
        <div className="chain-timeline-holder">
            {!featureChain.active &&
                <div className={`start-button${chatConnected ? '' : ' disabled'}`} onClick={chatConnected ? (e) => {e.preventDefault(); props.startChain(featureId);} : null}>
                    {IconJsxer.GetIcon(IconJsxer.ICONS.play, IconJsxer.ICON_STYLES.chatToggleControlButton)}
                </div>
            }
            {featureChain.active &&
                <div className={`start-button${chatConnected ? '' : ' disabled'}`} onClick={chatConnected ? (e) => {e.preventDefault(); props.stopChain(featureId);} : null}>
                    {IconJsxer.GetIcon(IconJsxer.ICONS.stop, IconJsxer.ICON_STYLES.chatToggleControlButton)}
                </div>
            }

            {/* <div className="divider"></div>

            <div className={`cell${chatConnected && featureChain.chainItems[0].enabled ? '' : ' disabled'}${featureChain.active && featureChain.index === 0 ? ' active' : ''}`}>
                <Toggle
                    id="count_in_toggle"
                    currentState={featureChain.chainItems[0].enabled}
                    toggleFunc={(e) => {setChainItemVal(props.featureId, 0, 'enabled', !featureChain.chainItems[0].enabled)}}
                />
                <div className="content">
                    <div className={`bold-text${featureChain.chainItems[0].enabled ? '' : ' light-text'}`}>Count In</div>
                </div>
                <HMSTimeField
                    id="count_in_time"
                    value={featureChain.chainItems[0].countInTime}
                    showLabel={false}
                    showHours={false}
                    showMinutes={false}
                    showError={false}
                    setFunc={(val) => {setChainItemVal(props.featureId, 0, 'countInTime', val)}}
                />
            </div> */}

            {featureChain.chainItems && featureChain.chainItems.map(
                (val, index, arr) => {
                    // if (index > 0) {
                        const featureData = findFeatureData(val.featureId);
                        if (featureData) {
                            // console.log('Feature data: ', featureData);

                            return (
                                <ChainItem
                                    key={'ci_' + index}
                                    index={index}
                                    val={val}
                                    togglePreviewMode={togglePreviewMode}
                                    endFeatureFunc={props.endFeatureFunc}
                                    skipChainItemFunc={props.skipChainItemFunc}
                                    chainSkipAllowed={props.chainSkipAllowed}
                                    featureData={featureData}
                                    featureId={featureId}
                                    featureChain={featureChain}
                                    setChainItemVal={props.setChainItemVal}
                                    chatConnected={chatConnected}
                                />
                            )
                        }
                    // }
                    return null;
                }
            )}
            
        </div>
    )
}

const ChainItem = (props) => {
    return (
        <>
            <div className="divider"></div>

            <div className={`cell${props.chatConnected && props.val.enabled ? '' : ' disabled'}${props.chatConnected && props.val.enabled && props.featureChain.active && props.featureChain.index === props.index ? ' active' : ''}`}>
                <Toggle
                    id={'fc_tog_' + props.index}
                    currentState={props.val.enabled}
                    toggleFunc={(e) => {props.setChainItemVal(props.featureId, props.index, 'enabled', !props.val.enabled)}}
                    /* enabled={props.chatConnected} */
                />
                <div className="content">
                    <div className={`bold-text${props.val.enabled ? '' : ' light-text'}`}>{props.featureData.contentLabel}</div>
                    <div className={`${props.val.enabled ? '' : 'light-text'}`}>{props.featureData.feature.featureName}</div>
                </div>
                {(!props.chatConnected || !props.featureChain.active || !props.val.enabled || props.featureChain.index !== props.index) &&
                    <div className={`edit-icon`} onClick={(e) => props.togglePreviewMode(true, props.featureData, `${props.val.featureId === props.featureId ? '(Main Feature)' : '(Chained Disruptor)'}`)}>
                        {IconJsxer.GetIcon(IconJsxer.ICONS.preview, IconJsxer.ICON_STYLES.campaignPanelTop)}
                    </div>
                }
                {props.chainSkipAllowed && props.chatConnected && props.val.enabled && props.featureChain.active && props.featureChain.index === props.index &&
                    <div className={`edit-icon`} onClick={(e) => props.skipChainItemFunc(props.featureId, props.index, props.featureData._id)}>
                        {IconJsxer.GetIcon(IconJsxer.ICONS.skip, IconJsxer.ICON_STYLES.campaignPanelTop)}
                    </div>
                }
            </div>
        </>
    )
}

export default ChainTimeline;
