import { Link, useNavigate,  } from "react-router-dom";
import { seedFeatureDBRoute } from "../../api/routes";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import VideoUploader from "../../components/forms/VideoUploader/VideoUploader";
import AudioUploader from "../../components/forms/AudioUploader/AudioUploader";
import ImageUploader2 from "../../components/forms/ImageUpload/ImageUploader2";

import FontSelector from "../../components/forms/FontSelector/FontSelector";
import { useState, useEffect } from "react";
import FontSelectorPopup from "../../components/forms/FontSelector/FontSelectorPopup";
import { TOP_LEVEL_NAV_ROUTES } from "../../config/NavRoutes";
import RangeSlider from "../../components/forms/RangeSlider/RangeSlider";
import RadioButtonGroup from "../../components/forms/RadioButtonGroup/RadioButtonGroup";
import ClawCameraFeeds from "../../components/FeatureControls/custom-control-components/claw-camera-feeds/ClawCameraFeeds";

function Home() {
    const navigate = useNavigate();
    const [xPerc, setXPerc] = useState(50);
    const [yPerc, setYPerc] = useState(50);
    const [machineID, setMachineID] = useState('1');
    const [voices, setVoices] = useState(speechSynthesis.getVoices());
    
    useEffect(() => {
        setVoices(speechSynthesis.getVoices());
        speechSynthesis.onvoiceschanged = () => {
            setVoices(speechSynthesis.getVoices());
        };
    }, []);

    console.log(speechSynthesis.getVoices());

    const gotoSeederEditor = (e) => {
        console.log("Open Seeder Editor");
        navigate(TOP_LEVEL_NAV_ROUTES.FEATURE_EDITOR);
    }

    const seedFeatures = (e) =>
    {
        e.preventDefault();
        axios
        .post(seedFeatureDBRoute, {withCredentials:true})
        .then(function (response) {
            toast.success("Features Seeded!");
            // navigate("/");
        })
        .catch(function (error) {
            // setIsSubmitting(false);
            toast.error('Error - ' + error.response.data.message);
            // console.log(error.response)
        });
    }

    const getFeatureList = (e) =>
    {
        e.preventDefault();
        axios
        .get('features/get-feature-list', {withCredentials:true})
        .then(function (response) {
            console.log(response);
            toast.success("Features Seeded!");
            // navigate("/");
        })
        .catch(function (error) {
            // setIsSubmitting(false);
            toast.error('Error - ' + error.response.data.message);
            // console.log(error.response)
        });
    }

const connectToClawMachine = (e) => {
    e.preventDefault();
    axios
    .post('claw/connect', {withCredentials:true})
    .then(function (response) {
        console.log(response);
        // navigate("/");
    })
}
const disconnectClawMachine = (e) => {
    e.preventDefault();
    axios
    .post('claw/disconnect', {withCredentials:true})
    .then(function (response) {
        console.log(response);
        // navigate("/");
    })
}
const clawGetVersions = (e) => {
    e.preventDefault();
    axios
    .post('claw/get-versions', {withCredentials:true})
    .then(function (response) {
        console.log(response);
        // navigate("/");
    })
}
const clawAutomatePlayTest = (e) => {
    e.preventDefault();
    axios
    .post('claw/automate_play_action', {xPerc:xPerc, yPerc:yPerc, machineID:parseInt(machineID), withCredentials:true})
    .then(function (response) {
        console.log(response);
        // navigate("/");
    })
}
const clawResetMachine = (e) => {
    e.preventDefault();
    axios
    .post('claw/reboot_machine', {machineID:parseInt(machineID), withCredentials:true})
    .then(function (response) {
        console.log(response);
        toast.success('Machine '+ machineID+' Soft Reset - Success');
        // navigate("/");
    })
}
const clawRebootMachine = (e) => {
    e.preventDefault();
    axios
    .post('claw/reboot_machine', {machineID:parseInt(machineID), withCredentials:true})
    .then(function (response) {
        console.log(response);
        toast.success('Machine '+ machineID+' Rebooting - Please Wait');
        // navigate("/");
    })
}

    const [showFontSelector, setShowFontSelector] = useState(false);
    const openFontSelector = () => {
        setShowFontSelector(true);
    }
    const closeFontSelector = () => {
        setShowFontSelector(false);
    }

  return (
      <div>
          <main  style={{'padding-left':'20px'}}>
          <h1 className="mt-4 mb-2">CLAW MACHINE DEBUG TOOLS</h1>
            <div className="flex" style={{'padding':'10px'}}>
                <div className="fl-column text-center form-holder" >


                    
                    <RadioButtonGroup
                            label={'Select Machine'}
                            labelNote={''}
                            id={'machineSelect'}
                            selectFunc={(e, id, newVal) => {e.preventDefault(); console.log('SELECT', id, newVal); setTimeout(() => {setMachineID(newVal)}, 10) }}
                            options={[{label:"Left", value:'1'}, {label: "Right", value:'2'}]}
                            horizontal={true}
                            selectedOption={machineID}
                            fillWidth={true}
                        />
                    <RangeSlider
                            label={'X Percentage'}
                            labelNote={''}
                            setFunc={(newVal) => { setXPerc(newVal) }}
                        // confirmChangeFunc={(newVal) => { updateFunction ? updateFunction(newVal, valsArray, varSchema, index, valKey) : changeVal(valsArray, varSchema, index, valKey, Number(newVal)) }}
                            min={0}
                            max={100}
                            value={xPerc}
                            showCurrentValue={ true}
                        />
                        <RangeSlider
                            label={'Y Percentage'}
                            labelNote={''}
                            setFunc={(newVal) => { setYPerc(newVal) }}
                        // confirmChangeFunc={(newVal) => { updateFunction ? updateFunction(newVal, valsArray, varSchema, index, valKey) : changeVal(valsArray, varSchema, index, valKey, Number(newVal)) }}
                            min={0}
                            max={100}
                            value={yPerc}
                            showCurrentValue={ true}
                        />
                        <button className="standard-button" onClick={clawAutomatePlayTest}>Play Test Claw Move</button>
                        <button className="standard-button" onClick={clawResetMachine}>SOFT RESET MACHINE</button>
                        <button className="standard-button" onClick={clawRebootMachine}>HARD REBOOT MACHINE</button>
            
                </div>
                <div>
                    <ClawCameraFeeds />
                    
         
                    <a href='#' onClick={(e) => { connectToClawMachine(e) }} >CONNECT TO CLAW MACHINE</a>
                    <a href='#' onClick={(e) => { disconnectClawMachine(e) }} >DISCONNECT CLAW MACHINE</a>
                    <a href='#' onClick={(e) => { clawGetVersions(e) }} >GET VERSIONS</a>
                </div>
            </div>
        
        </main>   
    </div>
  );
}

export default Home;
