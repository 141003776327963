import { useState, useContext } from "react";
import { assets } from "../../assets/assets";

import { resetPasswordRoute, checkResetTokenRoute, changeTwoFactor } from "../../api/routes";
import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import ProfilePic from "../../components/ProfilePic/ProfilePic";
import { getAcTypeData, getAcTypeDisplayName } from "../../helpers/AccountTypes";
import { getShortDateString, getShortDateTimeString } from "../../helpers/Dates";
import { ProfilePicURL } from "../../helpers/ProfilePicURL";
import Toggle from "../../components/forms/Toggle/Toggle";
import "./EditUser.scss";
import { AuthContext } from "../../contexts/AuthContext";

import { toast } from "react-toastify";
import axios from "../../api/axios";
const EditUser = (props) => {

    const [authContext, setAuthContext] = useContext(AuthContext);
    const { user, closePanelFunc } = props;
    const acTypeData = getAcTypeData(user.acType);
    const [isSubmitting, setIsSubmitting] = useState(false)
    const resetPasswordFormSubmitHandler = (e) => {
        e.preventDefault();
     
        if(!isSubmitting)
        {
            setIsSubmitting(true);
            console.log("pre resetPassword");
            axios
                .post(resetPasswordRoute, {
                    email: user.email,
                    resetToken: props.resetToken,
                    password: 'FORCERESET',
                    confirmPassword: 'FORCERESET'
                })
                .then(function (response) {
                    setIsSubmitting(false);
                    navigator.clipboard.writeText(response.data.newPassword);
                    toast.success('User\'s password reset! Copied to clipboard');
                   // setWasSentSuccessfully(true);
                })
                .catch(function (error) {
                    console.log("frontend reset password catch")
                    console.table(error)
                    setIsSubmitting(false);
                    toast.error('Error - ' + error.response.data.message);
                });
        }
    };
    const setUserTwoFactor = () =>{
        setIsSubmitting(true);
        user.ignoreTwoFactor = !user.ignoreTwoFactor;
        axios.post(changeTwoFactor,  {userID:user._id, ignoreTwoFactor:user.ignoreTwoFactor, withCredentials:true})
        .then(function (response) {
            setIsSubmitting(false);
            const data = response.data
            console.log(data.globalUserData)
            //setAuthContext((oldValues) => {
             //   return { ...oldValues, userData: data.globalUserData }
            //})
            toast.success("Two factor successfully changed!");
        })
        .catch(function (error) {
            setIsSubmitting(false);
            toast.error('Error - ' + error.response.data.message);
            // console.log(error.response)
        });
    }
    
    return (
        <PopUpPanel closePanelFunc={closePanelFunc}>
            <div className="edit-user-container">
            <div className="s-row">
                                <div className="s-profile-pic">
                                    <ProfilePic size="extralarge" className="pp-style" user={user} />
                                    {user.isTwitchLogin && 
                                        <div className="icon-overlay"><img src={assets.TwitchPPicIcon} alt="" className="tw-ppic-icon" /></div>
                                    }
                                </div>
                                <div className="column-cell">
                                    <div className="s-row small-gap">
                                        <div className="column-cell">
                                            <h2>{user.firstName} {user.lastName}</h2>
                                            <h4>{user.name}</h4>
                                        </div>
                                    </div>
                                    <div className="s-row">
                                        <p className="small-text light-text">Member since {getShortDateString(user.dateCreated)}</p>
                                    </div>
                                    <div className="s-row">
                                        <p className="small-text light-text">Last Active {getShortDateTimeString(user.lastActive)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="s-column no-gap">
                                <div className="label">Email</div>
                                <p className="indented tight">{user.email}</p>
                            </div>
                            {user.isTwitchLogin && 
                                <div className="s-column no-gap">
                                    <div className="label">Twitch Username</div>
                                    <p className="indented tight">{user.name}</p>
                                </div>
                            }
                            <div className="s-column no-gap">
                                <div className="label">Account ID</div>
                                <div className="s-row small-gap">
                                    <p className="indented tight">{user._id}</p>
                                 
                                </div>
                            </div>
                            <div className="s-column no-gap">
                                <div className="label">Account Type</div>
                                <p className="indented tight">{getAcTypeDisplayName(user.acType)}</p>
                            </div>
                            {acTypeData.level >= 50 &&
                                <>
                                <h4>Advanced User Settings</h4>
                                    <div className="s-column no-gap">
                                        <div className="label">Ignore Two Factor Auth</div>
                                        <Toggle id={'2FA'+user._id} currentState={user.ignoreTwoFactor} toggleFunc={setUserTwoFactor} enabled={true} />
                                    </div>
                                    <button className="manage-btn grow" onClick={(e) => { resetPasswordFormSubmitHandler(e); }}>GENERATE NEW PASSWORD</button>
                                </> 
                            }

                {/* <h2>User Details</h2>
                <div className="label">{user.name}</div>
                <div className={`ac-pill ${acTypeData.colorCss}`}>{acTypeData.displayName}</div>
                <div>
                    <p>{user.email}<br />
                    <span className="small-text light-text">{user._id}</span></p>
                </div>

                <div>
                    <span>Account Created:</span><br />
                    <span className="light-text">[02.12.23 *missing*]</span>
                </div>

                <div>
                    <span>Last Active:</span><br />
                    <span className="light-text">[02.12.23 *missing*]</span>
                </div> */}
            </div>
        </PopUpPanel>
    );
}

export default EditUser;
