import { useCallback, useContext, useEffect, useState } from "react"
import { ToastContainer } from "react-toastify";
import axios from "./api/axios";
import { useLocation } from 'react-router-dom';
import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "./contexts/AuthContext"
import { refreshTokenRoute } from "./api/routes";
import MainSiteContainer from "./containers/MainSiteContainer/MainSiteContainer";
import { pullInAccountSettings } from "./helpers/AccountTypes";
import { toast } from "react-toastify";
import { TOP_LEVEL_NAV_ROUTES } from "./config/NavRoutes";


function App() {
  const [authContext, setAuthContext] = useContext(AuthContext)
  const location = useLocation();
  const [pulledInConfigs, setPulledInConfigs] = useState(false);

  //Handle refreshing of page to see if the token is still fresh and the user is still authenticated. This is designed to handle when the user refreshes the page
  const verifyUser = useCallback(() => {
    axios
    .post(refreshTokenRoute, {
    },{  withCredentials:true})
    .then(function (response) {
      //console.log('FAILED', response.failed)
      const data = response.data
      setAuthContext((oldValues) => {
        console.log('AUTH TOKEN', data.globalUserData.isConfirmed)
        axios.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;
        return { ...oldValues,initialising: false, token:data.token, isConfirmed:data.globalUserData.isConfirmed, isLoggedIn:true, userData:data.globalUserData}
      })
    })
    .catch(function (error) {
      // Check if we are on a feature url!
      const isFeature = location.pathname.split('/')[1] === TOP_LEVEL_NAV_ROUTES.FEATURE.split('/')[1];

      // We only need to respond to the auth error if we are not on the feature extension page
      if (!isFeature) {
        const params = new URLSearchParams(window.location.search) // id=123
        let twitchFail = params.get('failedTwitchLogin') 
        params.delete('failedTwitchLogin')
        window.history.replaceState(null, '', '?' + params + window.location.hash)

        if(twitchFail)
        {
          delete axios.defaults.headers.common["Authorization"];
          toast.error("Error: " + error);
        }

        console.log('FAILED',error)
        console.log('[KokoAPI] Refresh token error...');
      }

      setAuthContext(oldValues => {
        return { ...oldValues, initialising: false, token: null, isConfirmed:false, isLoggedIn:false, userData:{} }
      })
    });
    setTimeout(verifyUser, 5 * 60 * 1000)
  }, [setAuthContext])

  useEffect(() => {
    setTimeout(verifyUser, 20);
  }, [verifyUser])

  useEffect(() => {
    if (pulledInConfigs === false) {
      pullInAccountSettings(1000, true);
      setPulledInConfigs(true);
    }
  }, [pulledInConfigs]);

  return (
      <div className="App">
        <MainSiteContainer></MainSiteContainer>
        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={true}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
  );
}

export default App;
