import PropTypes from 'prop-types';
import DebugLogger from "../../../helpers/DebugLogger";

import "./Toggle.scss";

import { IconJsxer } from "../../../helpers/IconHelper";

/**
 * ToggleCheck is a version of CheckBox with no label and two states, a green checked state and a red X state.
 * It doesn't automatically toggle, you must provide a setFunc function and update it's currentState prop.
 * @param {*} props 
 * @returns 
 */
const Toggle = (props) => {
    const { id, currentState, toggleFunc, enabled} = props;
    const checked = currentState === 'checked' || currentState === true;

    if (toggleFunc === null) {
        DebugLogger.warn('You should provide a function called `toggleFunc(fieldVal[, id])` to Toggle.');
    }

    const toggle = (e) => {
        if (toggleFunc) {
            toggleFunc(e, id);
        }
    }

    return (
        <div className="toggle-content">
            <div onClick={enabled ? toggle : null} className={`toggle-holder${enabled ? ' button-active' : ' disabled'}`}>
                <div className="toggle-outer">
                    <div className={`toggle-track ${checked ? 't-on' : 't-off'}`} style={{backgroundColor: props.overrideBg ? props.overrideBg[checked ? 'on' : 'off'] : null}}>
                        <div className={`toggle-switch ${checked ? 's-on' : 's-off'}`}>
                            {IconJsxer.GetIcon(checked ? IconJsxer.ICONS.check : IconJsxer.ICONS.checkX, IconJsxer.ICON_STYLES.roundFormControl)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

Toggle.propTypes = {
    id: PropTypes.string,
    currentState: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    toggleFunc: PropTypes.func,
    enabled: PropTypes.bool,
};

Toggle.defaultProps = {
    id: '',
    currentState: false,
    toggleFunc: null,
    enabled: true,
}

export default Toggle;
