import { useState, useEffect } from "react";
import PropTypes from 'prop-types'; 
import DebugLogger from "../../helpers/DebugLogger";

import "./TabbedPanel.scss";

/**
 * Twitch-styled Tabbed Panel component
 * @author Carl Trelfa
 * @param {*} props Provide `tabs` tab data [{label: 'Tab 1 Label'}, {label: 'Tab 2 Label'}], `switchTabCallback(tabId, tabData)` and content (child elements).
 * @returns A tabbed panel
 */
const TabbedPanel = (props) => {
    const [currentTab, setTab] = useState(props.initialTab);
    const [updateRan, setUpdateRan] = useState(false);


    useEffect(() => {
        if(props.forceUpdate)
        {
            switchTab(0);
        }
      });

    const switchTab = (tabId) => {
        if (props.switchTabCallback) {
            props.switchTabCallback(tabId, props.tabs[tabId]);
        } 
        if (props.switchTabCallback === defaultSwitchTabCallback) {
            DebugLogger.warn('You should provide a callback function called `switchTabCallback(tabId, tabData)` to TabbedPanel.');
        }
        setTab(tabId);
    }

    const widthStyle = {};
    let addTopRightCurve = false;
    if (props.tabWidth !== '' && props.tabWidth !== 0 && props.tabWidth !== 100 && props.tabWidth !== '100%' && props.tabWidth !== '0%') {
        widthStyle.maxWidth = props.tabWidth;
        widthStyle.minWidth = props.tabWidth;
        widthStyle.width = props.width;
        addTopRightCurve = true;
    }

    return (
        <div className="tabbed-panel">
            <div className="tabbed-panel-tabs-row">
                {
                    props.tabs.map((v, i) => {
                        if (v.hidden) return null; // Skip hidden tabs (for conditional rendering
                        if (!v.label) {
                            v.label = 'Tab ' + (i + 1);
                            DebugLogger.warn('TabbedPanel expects tab data to contain at least a `label` property.');
                        }
                        return (
                            <div className={'tabbed-panel-tab' + (currentTab === i ? '' : ' tabbed-panel-tab-inactive')} style={widthStyle} key={'tab_' + i} onClick={() => switchTab(i)}>{v.label}</div>
                        )
                    })
                }
            </div>
            <div className={'tabbed-panel-content' + (props.extraPanelClass ? (' ' + props.extraPanelClass) : '') + (addTopRightCurve ? ' top-right-curve' : '')}>
                {props.children}
            </div>
        </div>
    );
}

const defaultSwitchTabCallback = (tabId, tabData) => console.log('Switched to tab: ', tabId, tabData);

TabbedPanel.propTypes = {
    tabs: PropTypes.array.isRequired,
    forceUpdate: PropTypes.bool,
    initialTab: PropTypes.number,
    tabWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    switchTabCallback: PropTypes.func,
    children: PropTypes.element.isRequired,
};

TabbedPanel.defaultProps = {
    tabs: [{label: 'Tab 1'}, {label: 'Tab 2'}, {label: 'Tab 3'}],
    initialTab: 0,
    tabWidth: '',
    switchTabCallback: defaultSwitchTabCallback,
    children: <p>Panel content goes here</p>,
}

export default TabbedPanel;
