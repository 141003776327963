import React, { useState, useEffect, useContext } from "react";

import axios from "../../api/axios";

import { toast } from "react-toastify";
import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import FormField from "../../components/forms/FormField/FormField";
import { CampaignContext } from "../../contexts/CampaignContext";
import HMSTimeField from "../../components/forms/HMSTimeField/HMSTimeField";
import FormDropDown from "../../components/forms/DropDownList/FormDropDown";

import { getDefaultTimeZoneListData, convertFormDateTimeTimezoneToUnixTimestamp, getTzAdjustedDateObject } from "../../helpers/Dates";

import DateSelect from "../../components/forms/DateSelect/DateSelect";
import SelectUser from "../../components/SelectUser/SelectUser";
import { updateStreamRoute } from "../../api/routes";
import { getTimezoneOptions, parseTimezone } from "../../helpers/TimeZones";

const EditStream = (props) => {
    const [campaignContext, setCampaignContext] = useContext(CampaignContext);

    const [showSelectUser, setShowSelectUser] = useState(false);
    const toggleShowSelectUser = (e) => {
        if (e) {
            e.preventDefault();
        }
        setShowSelectUser(!showSelectUser);
    }
    const userSelected = (user) => {
        console.log(user);
        setStreamerHandle(user.name);
        if (user.discord) {
            setStreamDiscord(user.discord);
        } else {
            setStreamDiscord('');
        }
    }

    const streamData = props.stream;
    const [streamerHandle, setStreamerHandle] = useState(streamData.twitchHandle);
    const [streamerHandleError, setStreamerHandleError] = useState('');
    const [streamDiscord, setStreamDiscord] = useState(streamData.discord);
    const [streamDiscordError, setStreamDiscordError] = useState('');

    // Turn our startTime / endTime into editable data...
    // Getting timezone caches the timezone data, so we do that first so we know parseTimezone is working with the correct data
    const [tzOptions, setTzOptions] = useState(getTimezoneOptions(streamData.startTime));

    // First make sure we have our timezone data sorted!
    const timezoneData = parseTimezone(streamData.timeZone);
    const timezoneDiffSecs = timezoneData.offset * 3600;

    const [streamDate, setStreamDate] = useState(null);
    if (streamDate === null) {
        // We need to transform the startTime into date that is 00:00 our time...
        let startDate = getTzAdjustedDateObject(streamData.startTime, timezoneData.offset);
        startDate.setHours(0, 0, 0, 0);
        setStreamDate(startDate);
    }
    const dateSelected = (id, val) => setStreamDate(val);

    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    if (startTime === null) {
        // We need to reduce startTime / date down to 24 hours in seconds, currently it is milliseconds from Jan 1st 1970
        let startTimeOfDay = streamData.startTime / 1000 + timezoneDiffSecs;
        while (startTimeOfDay > 3600 * 24) {
            startTimeOfDay -= 3600 * 24;
        }
        setStartTime(startTimeOfDay);
    }
    if (endTime === null) {
        // We need to reduce endTime / date down to 24 hours in seconds, currently it is milliseconds from Jan 1st 1970
        let endTimeOfDay = streamData.endTime / 1000 + timezoneDiffSecs;
        while (endTimeOfDay > 3600 * 24) {
            endTimeOfDay -= 3600 * 24;
        }
        setEndTime(endTimeOfDay);
    }

    const reevaluateTimezoneOptions = (selectedTimeZone = null) => {
        const utcUnix_startTime = convertFormDateTimeTimezoneToUnixTimestamp(streamDate, startTime, selectedTimeZone || timezone);
        const evaluatedOptions = getTimezoneOptions(utcUnix_startTime);
        if (tzOptions !== evaluatedOptions) {
            const currentTimezone = selectedTimeZone || timezone;
            setTzOptions(evaluatedOptions);
            // Settings TzOptions can re-order the list!
            setTimezone(parseTimezone(currentTimezone.value));
        }
    }

    const [timezone, setTimezone] = useState(getDefaultTimeZoneListData(tzOptions, streamData.timeZone));
    const selectTimeZone = (e, id, item) => {
        reevaluateTimezoneOptions(item);
    }

    useEffect(() => {
        reevaluateTimezoneOptions();
    }, [streamDate, startTime]);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const saveStream = (e) => {
        e.preventDefault();

        if(!isSubmitting)
        {
            let formValid = true;
            if (streamerHandle === '') {
                setStreamerHandleError('You need to enter your streamer\'s Twitch Handle');
                formValid = false;
            } else {
                setStreamerHandleError('');
            }

            if (formValid) {
                let utcUnix_startTime = convertFormDateTimeTimezoneToUnixTimestamp(streamDate, startTime, timezone);
                let utcUnix_endTime = convertFormDateTimeTimezoneToUnixTimestamp(streamDate, endTime, timezone);

                if(!isSubmitting)
                {
                    // do axios post here
                    setIsSubmitting(true);
                    axios
                    .post(updateStreamRoute, { 
                        campaignID:props.campaignId,
                        _id:streamData._id,
                        twitchHandle:streamerHandle,
                        discord:streamDiscord,
                        startTime:utcUnix_startTime,
                        endTime:utcUnix_endTime,
                        timeZone:timezone.value, 
                        withCredentials:true
                    })
                    .then((response) =>{
                        console.log(response)

                        let oldCampaignData = campaignContext.campaignData
                        oldCampaignData.streams = response.data.campaignData.streams;
                        setIsSubmitting(false);
                        setCampaignContext((oldValues) => {
                            return { ...oldValues, initialising: false, campaignData: oldCampaignData }
                        })

                        toast.success("Stream ("+streamerHandle+") successfully added to campaign");
                        props.closePanelFunc();
                    })
                }
                
            }
        }
    }

    return (
        <>
        {!showSelectUser &&
        <PopUpPanel closePanelFunc={props.closePanelFunc}>
            <div className="form-holder co-popup-content">
                <form id="addstream" className="form">
                    <h2>Edit Stream</h2>
                    <div className="fl-row">
                        <div className="grow">
                        <FormField
                            type="text"
                            id="str_handle"
                            placeholder="Enter Twitch Handle"
                            setFunc={setStreamerHandle}
                            label="Streamer Twitch Handle"
                            error={streamerHandleError}
                            value={streamerHandle}
                        />
                        </div>
                        <div>
                        <button className="submit-btn" onClick={toggleShowSelectUser}>Select</button>
                        </div>
                    </div>
                    <FormField
                        type="text"
                        id="str_discord"
                        placeholder="Enter Discord"
                        setFunc={setStreamDiscord}
                        label="Stream Discord"
                        error={streamDiscordError}
                        value={streamDiscord}
                    />

                    <div className="label">Schedule Stream</div>
                    <div className="fl-row drop-gap grow">
                        <DateSelect 
                            id="stream_date" 
                            allowPast={true}
                            initialDate={streamDate}
                            selectFunc={dateSelected}
                        />
                    </div>
                    
                    <div className="fl-row drop-gap shift-up-1-5">
                    <HMSTimeField
                        label="Start"
                        inlineLabel={true}
                        showLabel={false}
                        id="stream_start"
                        value={startTime}
                        setFunc={setStartTime}
                        maxHours={23}
                        showSeconds={false}
                        relativeTime={false}
                        narrow={true}
                        centered={false}
                        showError={false}
                    />
                    <div className="grow-100 j-center">until</div>
                    <HMSTimeField
                        label="Until"
                        inlineLabel={true}
                        showLabel={false}
                        id="stream_end"
                        value={endTime}
                        setFunc={setEndTime}
                        maxHours={23}
                        showSeconds={false}
                        relativeTime={false}
                        narrow={true}
                        centered={false}
                        showError={false}
                    />
                    </div>
                    <FormDropDown
                        id="timezone"
                        label="Timezone"
                        showLabel={false}
                        items={tzOptions}
                        currentIndex={timezone.index}
                        selectFunc={selectTimeZone}
                        className="shift-up-1-5"
                    />
                    <button className={'submit-btn' + (isSubmitting ? ' button-inactive' : ' button-active')} onClick={isSubmitting ? null : saveStream}>
                        {isSubmitting ? "Updating Stream" : "Update Stream"}
                    </button>
                </form>
            </div>
        </PopUpPanel>
        }
        {showSelectUser &&
            <SelectUser closePanelFunc={toggleShowSelectUser} selectUserFunc={userSelected} twitchOnly={true} />
        }
        </>
    )
}

export default EditStream;
