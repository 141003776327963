import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { filesRoute } from "../api/routes";
import { assets } from "../assets/assets";

const ProfilePicURL = (userData = null) => {
    const [authContext, setAuthContext] = useContext(AuthContext);
    if (userData === null) {
        userData = authContext.userData;
    }
    if(userData.isTwitchLogin || userData.isTwitch || userData.authStrategy == 'twitch' || userData.profilePic.indexOf('https://') !=-1) return  userData.profilePic;
    if(userData.profilePic === '') return assets.BlankUser;
    
    const localProfileImgURL =  filesRoute + '/profile-pics/' + userData.profilePic;
    return localProfileImgURL;

}
export { ProfilePicURL }