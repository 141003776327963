import React, { useState, useEffect, useContext } from "react";
import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import ToggleCheck from "../../components/forms/ToggleCheck/ToggleCheck";

import "./CsvPreview.scss";
import Toggle from "../../components/forms/Toggle/Toggle";
import { IconJsxer } from "../../helpers/IconHelper";
import { getFeatureAssetBaseUrl } from "../../helpers/FeatureAssets";

const CsvPreview = (props) => {
    const getBaseURL = () => {
        return getFeatureAssetBaseUrl()+'campaigns/'+ props.campaignId+'/quizzes/'+ props.quiz._id+'/image/'
    }

    const toggleInclude = (questionIndex) => {
        let newQuestions = [...props.processedCsvData];
        newQuestions[questionIndex].included = !newQuestions[questionIndex].included;
        props.setProcessedCsvData(newQuestions);
    }

    let includedRowsCount = 0;
    props.processedCsvData.forEach((question) => {
        if (question.included) {
            includedRowsCount++;
        }
    })

    return (
        <PopUpPanel
            title="CSV Preview"
            closePanelFunc={props.closePanelFunc}
            className={"csv-preview-panel"}
        >
            <div className="csv-preview">
                {props.allowUpdateFromPreview &&
                    <>
                        <h2>Imported Question Data Preview</h2>
                        <p className="light-text">Select which answers you want to include - note the first row is usually a header row that can be ignored. The answers have automatically been shuffled and the correct answer highlighted.</p>
                    </>
                }
                {!props.allowUpdateFromPreview &&
                    <h2>Question List</h2>
                }
                
                <div className="questions-holder">
                    {props.processedCsvData.map((question, questionIndex) => {
                        return (
                            <div className={`question-row${questionIndex % 2 === 1 ? ' odd-row' : ''}`} key={"question_" + questionIndex}>
                                {props.allowUpdateFromPreview &&
                                    <div className="include-question">
                                        <ToggleCheck id={"inc_toggle_" + questionIndex} label="Include" currentState={question.included} toggleFunc={(e) => toggleInclude(questionIndex)}></ToggleCheck>
                                    </div>
                                }
                                {question.type === "Image Question with Text Answers" &&
                                    <div className={`question${(question.included || !props.allowUpdateFromPreview) ? '' : ' light-text'}`}>
                                        <div className="image-holder">
                                            <img src={getBaseURL() + question.questionImage} alt="Question" />
                                        </div>
                                    </div>
                                }
                                {question.type !== "Image Question with Text Answers" &&
                                    <div className={`question${(question.included || !props.allowUpdateFromPreview) ? '' : ' light-text'}`}>
                                        {question.questionText}
                                    </div>
                                }
                                {question.answers.map((answer, answerIndex) => {
                                    if (props.quiz.type === "Tug of War" && !props.allowUpdateFromPreview && answerIndex >= 2) {
                                        return null;
                                    }
                                    if (answer.usePreviousWinner) {
                                        return (
                                            <div key={"answer_" + answerIndex} className={`answer${answer.correctAnswer && (props.quiz.type !== "Tug of War" || props.allowUpdateFromPreview) ? " correct-answer" : ""}${answer.correctAnswer && !question.included && props.allowUpdateFromPreview ? '-light' : ''}${(question.included || !props.allowUpdateFromPreview) ? '' : ' light-text'}`}>
                                                Using Previous Winner
                                            </div>
                                        )
                                    }
                                    if (question.type === "Text Question with Image Answers") {
                                        return (
                                            <div key={"answer_" + answerIndex} className={`answer${answer.correctAnswer && (props.quiz.type !== "Tug of War" || props.allowUpdateFromPreview) ? " correct-answer" : ""}${answer.correctAnswer && !question.included && props.allowUpdateFromPreview ? '-light' : ''}${(question.included || !props.allowUpdateFromPreview) ? '' : ' light-text'}`}>
                                                <div className="image-holder">
                                                    <img src={getBaseURL() + answer.answerImage} alt="Answer" />
                                                </div>
                                            </div>
                                        );
                                    }
                                
                                    if (answer.answerText === "" || answer.answerText === null || answer.answerText === undefined) {
                                        return null;
                                    }
                                    return (
                                        <div key={"answer_" + answerIndex} className={`answer${answer.correctAnswer && (props.quiz.type !== "Tug of War" || props.allowUpdateFromPreview) ? " correct-answer" : ""}${answer.correctAnswer && !question.included && props.allowUpdateFromPreview ? '-light' : ''}${(question.included || !props.allowUpdateFromPreview) ? '' : ' light-text'}`}>
                                            {answer.answerText}
                                        </div>
                                    )
                                })}
                                {!props.allowUpdateFromPreview &&
                                <>
                                    <div className="grow"></div>
                                    <div className="controls-column">
                                        <div className="edit-icon-wh edit-icon-primary-colour" onClick={props.editQuestionFunc ? (e) => {props.closePanelFunc(); props.editQuestionFunc(e, props.roundIndex, questionIndex)} : null}>
                                            {IconJsxer.GetIcon(
                                                IconJsxer.ICONS.edit,
                                                IconJsxer.ICON_STYLES.campaignPanelTop,
                                            )}
                                        </div>
                                        <div className="edit-icon-wh edit-icon-primary-colour" onClick={props.removeQuestionFunc ? (e) => {props.closePanelFunc(); props.removeQuestionFunc(e, props.roundIndex, questionIndex) }: null}>
                                            {IconJsxer.GetIcon(
                                                IconJsxer.ICONS.trash,
                                                IconJsxer.ICON_STYLES.campaignPanelTop,
                                            )}
                                        </div>
                                    </div>
                                </>
                                }
                            </div>
                        )
                    })}
                </div>

                {props.allowUpdateFromPreview &&
                    <div className="fl-row centered">
                        <div className="shrink">
                            <button className="standard-button" onClick={(e) => {e.preventDefault(); props.randomiseAnswerOrder()}}>Shuffle Answers</button>
                        </div>
                        <div className="grow"></div>
                        <div className="fl-row shrink">
                            <div className={`bold-text${props.replaceCurrentQuestions ? '' : ' light-text'}`}>Replace Existing Questions</div>
                            <Toggle currentState={props.replaceCurrentQuestions} toggleFunc={(e) => {e.preventDefault(); props.setReplaceCurrentQuestions((oldVal) => !oldVal)}}></Toggle>
                        </div>
                        <div className="shrink">
                            <button className="standard-button" onClick={props.addNewQuestionsFromCsv}>Add {includedRowsCount}/{props.processedCsvData.length} Rows</button>
                        </div>
                    </div>
                }

            </div>
        </PopUpPanel>
    )
}

export default CsvPreview;
