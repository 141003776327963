import React, { useState, useEffect, useContext } from "react";
import validator from "validator";

import axios from "../../api/axios";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../../contexts/AuthContext";

import "./AccountUnauthorised.scss";
import ClosePanelBtn from "../../components/ClosePanelBtn/ClosePanelBtn";
import { LogoutHandler } from "../../helpers/AuthFunctions";
import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";


const AccountUnauthorised = (props) => {
    const [authContext, setAuthContext] = useContext(AuthContext);
    return (
        <PopUpPanel showCloseBtn={false}>
            <div className="form-holder unauth-content">
                <p>Your account is currently disabled, please contact your Twitch representative to re-enable your account and remove this message.</p>
                <button className="submit-btn button-active" onClick={() => { LogoutHandler(setAuthContext); }}>Log Out</button>
            </div>
        </PopUpPanel>
    )
}

export default AccountUnauthorised;
