export const TOP_LEVEL_NAV_ROUTES = {
    LOGIN: '/',
    REGISTER: '/register',
    CAMPAIGNS: '/campaigns',
    CREATE_CAMPAIGN: '/campaigns/create',
    CAMPAIGN_OVERVIEW: '/campaigns/:id',
    CAMPAIGN_OVERVIEW_TAB: '/campaigns/:id/:tab',
    CUSTOMISE_FEATURE: '/campaigns/:campaignId/customise/:featureId',
    CUSTOMISE_FEATURE_STREAM: '/campaigns/:campaignId/manage-stream/:streamId/customise/:featureId',
    MANAGE_CAMPAIGN_STREAM: '/campaigns/:campaignId/manage-stream/:streamId',
    USERS: '/users',
    ANALYTICS: '/analytics',
    SCHEDULE: '/schedule',
    SETTINGS: '/settings',
    BUG_REPORTS: '/bug-reports',
    NOT_AUTHORSIED: '/not-authorised',
    EDIT_DISPLAY_NAME: '/edit-display-name',
    QUIZZES: '/quizzes',
    CREATE_QUIZ: '/quizzes/create',
    EDIT_QUIZ: '/quizzes/:id',
    EDIT_CAMPAIGN_FEATURE_QUIZ: '/campaigns/:campaignId/customise/:featureId/quizzes/:id',
    EDIT_CAMPAIGN_STREAM_FEATURE_QUIZ: '/campaigns/:campaignId/manage-stream/:streamId/customise/:featureId/quizzes/:id',
    FEATURE: '/feature/:campaignId/:streamId/:gamePIN',
    FEATURE_EXPIRED: '/feature/expired',
    KOKO_DEBUG_TOOLS: '/koko-debug',
    CLAW_DEBUG_TOOLS: '/claw-debug',
    FEATURE_EDITOR: '/feature-editor',
    STREAMFOG_TEST: '/streamfog',
}

export const NAV_AREAS = {
    CAMPAIGNS: 'campaigns',
    USERS: 'users',
    ANALYTICS: 'analytics',
    SCHEDULE: 'schedule',
    BUG_REPORTS: 'bug-reports',
    QUIZZES: 'quizzes',
    DEBUG: 'debug',
}
