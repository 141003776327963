import React, { useContext, useState } from "react";
import { assets } from "../../assets/assets";
import { ProfilePicURL } from "../../helpers/ProfilePicURL";
import SettingsPop from "../SettingsPop/SettingsPop";
import "./TopBar.scss";
import ProfilePic, { PROFILE_PIC_SIZES } from "../ProfilePic/ProfilePic";
import { IconJsxer } from "../../helpers/IconHelper";
import ScreenRecorderPop from "../ScreenRecorderPop/ScreenRecorderPop";
import UserLevelRestrict from "../UserLevelRestrict/UserLevelRestrict";
import { ACCOUNT_TYPES } from "../../helpers/AccountTypes";

const TopBar = (props) => {
    const [showSettingsPop, setShowSettingsPop] = useState(false);
    const toggleSettingsPop = () => setShowSettingsPop(!showSettingsPop);
    const hideSettingsPop = () => setShowSettingsPop(false);

    const [showScreenRecorderPop, setShowScreenRecorderPop] = useState(false);
    const toggleScreenRecorderPop = () => setShowScreenRecorderPop(!showScreenRecorderPop);
    const hideScreenRecorderPop = () => setShowScreenRecorderPop(false);
    const [recordingActive, setRecordingActive] = useState(false);

    return(
        <div className="top-bar">
            <div className="left">
                {/* <img className="logo" alt="Twitch Logo" src={assets.TwitchLogoSmall} />
                campaign studio */}
                <img className="icast-logo" alt="ICAST Logo" src={assets.icastTopLogo} />
            </div>
            <div className="right fl-row">
                <UserLevelRestrict accountType={ACCOUNT_TYPES.producer}>
                    <div className={`screen-recorder-top-bar-icon${recordingActive ? ' active' : ''}`}>
                        {IconJsxer.GetIcon(IconJsxer.ICONS.videoCamera, IconJsxer.ICON_STYLES.headerBarButtonIcon, '', toggleScreenRecorderPop)}
                    </div>
                </UserLevelRestrict>
                <ProfilePic size={PROFILE_PIC_SIZES.small} className="pp-style" onClick={toggleSettingsPop} />
                <UserLevelRestrict accountType={ACCOUNT_TYPES.producer}>
                    <ScreenRecorderPop shown={showScreenRecorderPop} hideFunc={hideScreenRecorderPop} reportRecordingFunc={setRecordingActive}></ScreenRecorderPop>
                </UserLevelRestrict>
                <SettingsPop shown={showSettingsPop} hideFunc={hideSettingsPop}></SettingsPop>
            </div>
        </div>
    );
}

export default TopBar;
