import React, { useState, useEffect, useRef } from "react";

import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import FormDropDown from "../../components/forms/DropDownList/FormDropDown";
import FormField from "../../components/forms/FormField/FormField";
import { FindParamArrayFromCustomisations } from "../../helpers/FeatureControlsHelper";
import HMSTimeField from "../../components/forms/HMSTimeField/HMSTimeField";
import Toggle from "../../components/forms/Toggle/Toggle";

const AddHashtag = (props) => {
    const [hashtag, setHashtag] = useState('');
    const [hashtagError, setHashtagError] = useState('');

    const [selectedCommand, setSelectedCommand] = useState(null);
    const updateCommandHandler = (e, dropDownId, item) => {
        console.log('Command selected: ', item);
        if (item !== selectedCommand) {
            setSelectedParam(null);
        }
        setSelectedCommand(item);
    }
    const [selectedCommandError, setSelectedCommandError] = useState('');

    const [selectedParam, setSelectedParam] = useState(null);
    const updateParamHandler = (e, dropDownId, item) => {
        console.log('Param selected: ', item);
        setSelectedParam(item);
    }
    const [selectedParamError, setSelectedParamError] = useState('');

    const [requiresParam, setRequiresParam] = useState(false);
    const fixedParam = useRef(null);
    const fixedParamLabel = useRef(null);

    const [cooldown, setCooldown] = useState(0);

    const [restricted, setRestricted] = useState(false);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const saveHashtag = (e) => {
        e.preventDefault();

        if (!isSubmitting) {
            let formValid = true;
            if (selectedCommand === null || selectedCommand.index === 0) {
                setSelectedCommandError('Please select a command');
                formValid = false;
            } else {
                setSelectedCommandError('');
            }
            if (hashtag === '') {
                setHashtagError('You need to enter your hashtag');
                formValid = false;
            } else {
                setHashtagError('');
            }
            if (!requiresParam || (requiresParam && selectedParam.value >= 0)) {
                setSelectedParamError('');
            } else {
                setSelectedParamError('This command requires a parameter');
                formValid = false;
            }

            if (formValid) {
                const newHashtagData = { campaignID: props.campaignId, featureID: props.feature._id, hashtag: { hashtag: hashtag, cooldown: cooldown, restricted: restricted }, command: selectedCommand.value, commandParam: selectedCommand.commandParam };
                if (fixedParam.current !== null) {
                    newHashtagData.param = fixedParam.current;
                } else
                    if (requiresParam) {
                        newHashtagData.param = selectedParam.value;
                    }
                setIsSubmitting(true);
                props.saveHashtag(newHashtagData, hashtagSaved);
            }
        }
    }

    const hashtagSaved = (success) => {
        setIsSubmitting(false);
        if (success) {
            props.closePanelFunc();
        }
    }

    const getCommandListDropDownData = () => {
        const commandListDropDownData = [];
        commandListDropDownData.push({ id: 0, value: '', label: 'None' });
        for (let i = 0; i < props.feature.feature.commands.length; i++) {
            if (props.feature.feature.commands[i].isHashtagEnabled) {
                commandListDropDownData.push({ id: props.feature.feature.commands[i]._id, value: props.feature.feature.commands[i].command, commandParam: props.feature.feature.commands[i].param, label: props.feature.feature.commands[i].title || props.feature.feature.commands[i].command, param: props.feature.feature.commands[i].param });
            }
        }
        console.log('Command drop down list: ', commandListDropDownData, props.feature.feature);
        return commandListDropDownData;
    }

    const getParamListDropDownData = (_selectedCommand = selectedCommand) => {
        if (_selectedCommand && (typeof _selectedCommand.param === 'number' || typeof _selectedCommand.param === 'string' || typeof _selectedCommand.param === 'boolean')) {
            if (typeof _selectedCommand.param === 'string' && _selectedCommand.param.split('-').length === 2 && !isNaN(parseInt(_selectedCommand.param.split('-')[0])) && !isNaN(parseInt(_selectedCommand.param.split('-')[1]))) {
                // We are dealing with a range!
                // Create a drop down with the range of numbers
                const range = _selectedCommand.param.split('-');
                const start = parseInt(range[0]);
                const end = parseInt(range[1]);
                const paramsListDropDownData = [];
                for (let i = start; i <= end; i++) {
                    paramsListDropDownData.push({ id: i, value: i, label: i });
                }
                if (requiresParam === false) {
                    setRequiresParam(true);
                }
                fixedParam.current = null;
                fixedParamLabel.current = null;
                return { requiresParam: true, hasAvailableParams: true, paramsListDropDownData: paramsListDropDownData };
            } else
            if ((typeof _selectedCommand.param === 'string' && props.feature.feature[_selectedCommand.param] && Array.isArray(props.feature.feature[_selectedCommand.param])) || (typeof _selectedCommand.param === 'string' && FindParamArrayFromCustomisations(props.customisationVars.current, props.feature._id, _selectedCommand.param))) {
                const paramsArray = FindParamArrayFromCustomisations(props.customisationVars.current, props.feature._id, _selectedCommand.param) || props.feature.feature[_selectedCommand.param];
                fixedParam.current = null;
                const returnData = { requiresParam: true };
                if (paramsArray.length > 0) {
                    if (paramsArray.length === 1) {
                        // auto fill if we need a param, but there is only 1 option!
                        if (requiresParam === true) {
                            setRequiresParam(false);
                        }
                        fixedParam.current = 0;
                        fixedParamLabel.current = paramsArray[0].label || paramsArray[0].name;
                        return null;
                    }

                    if (requiresParam === false) {
                        setRequiresParam(true);
                    }
                    returnData.hasAvailableParams = true;
                    const paramsListDropDownData = [];
                    paramsListDropDownData.push({ id: 0, value: -1, label: 'None' });
                    for (let i = 0; i < paramsArray.length; i++) {
                        paramsListDropDownData.push({ id: i + 1, value: i, label: paramsArray[i].label || paramsArray[i].name || 'Item ' + (i + 1)});
                    }
                    returnData.paramsListDropDownData = paramsListDropDownData;
                } else {
                    returnData.hasAvailableParams = false;
                }
                return returnData;
            } else {
                fixedParam.current = _selectedCommand.param;
                fixedParamLabel.current = _selectedCommand.param;
                return null
            }
        } else {
            if (requiresParam) {
                setRequiresParam(false);
            }
        }
        return null;
    }

    const paramListData = getParamListDropDownData();
    console.log('Params data: ', paramListData);

    return (
        <>
            <PopUpPanel closePanelFunc={props.closePanelFunc}>
                <div className="form-holder co-popup-content">
                    <form id="addstream" className="form">
                        <h2>Add Chat Command</h2>
                        <FormDropDown
                            label="Select Command"
                            id="select_command"
                            selectFunc={updateCommandHandler}
                            currentIndex={selectedCommand ? selectedCommand.index : 0}
                            items={
                                getCommandListDropDownData()
                            }
                            error={selectedCommandError}
                        />
                        <FormField
                            type="text"
                            id="hashtag"
                            placeholder="Enter Chat Command"
                            setFunc={setHashtag}
                            label="Chat Command"
                            error={hashtagError}
                            value={hashtag}
                        />
                        {requiresParam && paramListData.hasAvailableParams &&
                            <>
                                <FormDropDown
                                    label="Select Param"
                                    id="select_param"
                                    selectFunc={updateParamHandler}
                                    currentIndex={selectedParam ? selectedParam.index : 0}
                                    items={
                                        paramListData.paramsListDropDownData
                                    }
                                    error={selectedParamError}
                                />
                            </>
                        }
                        {requiresParam && !paramListData.hasAvailableParams &&
                            <>
                                <p>This command requires a parameter, but no options are available.</p>
                                <p>You may need to customise this feature to add some options.</p>
                            </>
                        }
                        {fixedParam.current !== null && !requiresParam &&
                            <>
                                <p>Param: {fixedParamLabel.current}</p>
                            </>
                        }
                        <div className="fl-row">
                            <HMSTimeField 
                                label="Cooldown"
                                labelNote="Minimum time between commands"
                                id="cooldown"
                                showHours={false}
                                value={cooldown}
                                centered={false}
                                setFunc={setCooldown}
                            />
                            <div className="grow-100"></div>
                        </div>

                        <div className="fl-row">
                            <div className="fl-column no-gap grow">
                                <div className="form-field-content label">
                                    Restricted Command?
                                </div>
                                <div className="form-field-content label-note">
                                    Only the channel owner and twitch accounts added to the campaign can run restricted commands.
                                </div>
                            </div>
                            <div className="shrink">
                                <Toggle id="r_toggle" currentState={restricted} toggleFunc={() => {setRestricted(!restricted)}} />
                            </div>
                        </div>

                        <button className={'submit-btn' + (isSubmitting ? ' button-inactive' : ' button-active')} onClick={isSubmitting ? null : saveHashtag}>
                            {isSubmitting ? "Adding Chat Command" : "Add Chat Command"}
                        </button>
                    </form>
                </div>
            </PopUpPanel>
        </>
    )
}

export default AddHashtag;
