import PropTypes from 'prop-types';
import DebugLogger from "../../../helpers/DebugLogger";

import "./FormField.scss";

const FormField = (props) => {
  const { showLabel, inlineLabel, label, labelNote, type, id, placeholder, setFunc, confirmChangeFunc, onClick, showError, error, value, min, max, maxLength, width, className, readonly } = props;

  if (showLabel && label === 'Field Label') {
    DebugLogger.warn('No label provided to FormField, using default...');
  }
  if (type === '') {
    DebugLogger.warn('No type provided to FormField, using default `text`...');
  }
  if (id === '') {
    DebugLogger.warn('No id provided to FormField, using a random id...');
  }
  if (placeholder === 'Placeholder text') {
    DebugLogger.warn('No placeholder provided to FormField, using default `Placeholder text`...');
  }
  if (!readonly && setFunc === null) {
    DebugLogger.warn('You should provide a function called `setFunc(fieldVal)` to FormField.');
  }

  return (
    <div className={`form-field-holder${className ? ` ${className}` : ''}`}>

      <div className="form-field-content-holder">

        {showLabel && !inlineLabel && label != null &&
          <div className="form-field-content label">
            {label}
          </div>
        }
        {showLabel && !inlineLabel && labelNote != null && labelNote !== '' &&
          <div className="form-field-content label-note" dangerouslySetInnerHTML={{__html: labelNote}}>
            {/*labelNote*/}
          </div>
        }

        <div className="form-field-content">
          {showLabel && inlineLabel && label != null &&
            <div className="label">
              {label}
            </div>
          }
          <div className='grow'>
            <input
              type={type !== '' ? type : 'text'}
              min={min}
              max={max}
              width={width}
              maxLength={maxLength}
              id={id !== '' ? id : Math.floor(Math.random() * 100000).toString()}
              name={id !== '' ? id : Math.floor(Math.random() * 100000).toString()}
              placeholder={placeholder}
              value={value}
              readOnly={readonly}
              onChange={
                (event) => {
                  if (setFunc) {
                    setFunc(event.target.value);
                  }
                }
              }
              onKeyUp={
                (event) => {
                  if (confirmChangeFunc && event.key === 'Enter') {
                    confirmChangeFunc(event.target.value);
                  }
                }
              }
              onBlur={
                (event) => {
                  if (confirmChangeFunc) {
                    confirmChangeFunc(event.target.value);
                  }
                }
              }
              onClick={onClick}
            />
          </div>
        </div>

        {showError &&
          <div className="error">
            {error}&nbsp;
          </div>
        }
      </div>

    </div>
  );
};

FormField.propTypes = {
  showLabel: PropTypes.bool,
  inlineLabel: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  labelNote: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.string.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  width: PropTypes.number,
  maxLength: PropTypes.number,
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showError: PropTypes.bool,
  error: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  setFunc: PropTypes.func,
  confirmChangeFunc: PropTypes.func,
  onClick: PropTypes.func,
  readonly: PropTypes.bool,
};

FormField.defaultProps = {
  showLabel: true,
  inlineLabel: false,
  label: 'Field Label',
  labelNote: '',
  type: 'text',
  id: '',
  value: undefined,
  showError: true,
  error: '',
  placeholder: 'Placeholder text',
  className: '',
  setFunc: null,
  confirmChangeFunc: null,
  onClick: null,
  readonly: false,
}

export default FormField;
