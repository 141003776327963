import React, { useState, useEffect, useContext, useRef } from "react";
import PropTypes from 'prop-types';

import HMSTimeField from "../../forms/HMSTimeField/HMSTimeField";
import FormDropDown from "../../forms/DropDownList/FormDropDown";

import { getDefaultTimeZoneListData, convertFormDateTimeTimezoneToUnixTimestamp, getShortDateTimeString } from "../../../helpers/Dates";

import DateSelect from "../../forms/DateSelect/DateSelect";

import { getTimezoneOptions, parseTimezone } from "../../../helpers/TimeZones";
import { getTzAdjustedDateObject } from "../../../helpers/Dates";

const DateTimeTimezoneSelect = (props) => {
    const {id, selectFunc, allowPast, label, showLabel, labelNote, showError, error, className} = props;
    const [initing, setIniting] = useState(true);

    let initialDateTime = typeof props.value !== 'string' ? props.value : Number(props.value.split(':')[0]);
    if (!initialDateTime) {
        initialDateTime = Date.now();
    }
    const savedTz = typeof props.value === 'string' ? props.value.split(':')[1] : null;

    const [tzOptions, setTzOptions] = useState(getTimezoneOptions());
    const [timezone, setTimezone] = useState(getDefaultTimeZoneListData(tzOptions, savedTz));
    const selectTimeZone = (e, id, item) => {
        reevaluateTimezoneOptions(item);
    }
    // console.log('Default timezone: ', getDefaultTimeZoneListData(tzOptions));

    const timezoneData = parseTimezone(timezone);
    const timezoneDiffSecs = timezoneData.offset * 3600;

    const [selectedDate, setSelectedDate] = useState(null);
    if (selectedDate === null) {
        // We need to set a default date that is 00:00 our time...
        if (initialDateTime) {
            let startDate = getTzAdjustedDateObject(initialDateTime, timezoneData.offset);
            startDate.setHours(0, 0, 0, 0);
            setSelectedDate(startDate);
        } else {
            let defaultDate = new Date();
            defaultDate.setHours(0, 0, 0, 0);
            setSelectedDate(defaultDate);
        }
    }
    const dateSelected = (id, val) => {
        setSelectedDate(val);
    }

    let startSelectedTime = null
    if (startSelectedTime === null) {
        if (initialDateTime) {
            // We need to reduce startTime / date down to 24 hours in seconds, currently it is milliseconds from Jan 1st 1970
            let startTimeOfDay = initialDateTime / 1000 + timezoneDiffSecs;
            while (startTimeOfDay > 3600 * 24) {
                startTimeOfDay -= 3600 * 24;
            }
            startSelectedTime = startTimeOfDay;
            // setSelectedTime(startTimeOfDay);
        } else {
            startSelectedTime = 0;
            // setSelectedTime(0);
        }
    }
    const [selectedTime, setSelectedTime] = useState(startSelectedTime);

    const reevaluateTimezoneOptions = (selectedTimeZone = null) => {
        const utcUnix_startTime = convertFormDateTimeTimezoneToUnixTimestamp(selectedDate, selectedTime, selectedTimeZone || timezone);
        const evaluatedOptions = getTimezoneOptions(utcUnix_startTime);
        if (tzOptions !== evaluatedOptions) {
            const currentTimezone = selectedTimeZone || timezone;
            setTzOptions(evaluatedOptions);
            // Settings TzOptions can re-order the list!
            setTimezone(parseTimezone(currentTimezone.value));
        }
    }

    const calculateResultingUtcUnixTimestamp = () => {
        return convertFormDateTimeTimezoneToUnixTimestamp(selectedDate || 0, selectedTime || 0, timezone || null);
    }

    const lastResultingUnixTimestamp = useRef(0);

    useEffect(() => {
        reevaluateTimezoneOptions();
    }, [selectedDate, selectedTime]);

    useEffect(() => {
        if (initing === false) {
            if (selectFunc && calculateResultingUtcUnixTimestamp() !== lastResultingUnixTimestamp.current) {
                selectFunc(id, calculateResultingUtcUnixTimestamp() + ':' + timezone.value);
                lastResultingUnixTimestamp.current = calculateResultingUtcUnixTimestamp();
                // console.log('TZ: ', timezone);
            }
        } else {
            if (lastResultingUnixTimestamp.current !== 0) {
                setIniting(false);
            } else {
                lastResultingUnixTimestamp.current = calculateResultingUtcUnixTimestamp();
            }
        }
    }, [selectedDate, selectedTime, timezone]);

    const localTimezone = getDefaultTimeZoneListData(tzOptions);

    return (
        <div className={`form-field-holder${className ? ` ${className}` : ''}`}>

            <div className="form-field-content-holder">

                {showLabel && label != null &&
                    <div className="form-field-content label">
                        {label}
                    </div>
                }
                {showLabel && labelNote != null && labelNote !== '' &&
                    <div className="form-field-content label-note" dangerouslySetInnerHTML={{ __html: labelNote }}>
                        {/*labelNote*/}
                    </div>
                }
                <FormDropDown
                    id="timezone"
                    label="Timezone"
                    showLabel={false}
                    items={tzOptions}
                    currentIndex={timezone.index}
                    selectFunc={selectTimeZone}
                    showErrors={false}
                />
                <div className="fl-row grow force-small-gap"></div>
                <div className="fl-row grow">
                    <DateSelect
                        id="select_date"
                        allowPast={allowPast}
                        initialDate={selectedDate}
                        selectFunc={dateSelected}
                        calendarOffset="7.5rem"
                        lowStyle={false}
                    />
                    <div className="grow"></div>
                    <div className="shrink">
                        <HMSTimeField
                            label="Start"
                            inlineLabel={true}
                            showLabel={false}
                            id="stream_start"
                            value={selectedTime}
                            setFunc={setSelectedTime}
                            maxHours={23}
                            showSeconds={false}
                            relativeTime={false}
                            narrow={true}
                            centered={false}
                            showError={false}
                        />
                    </div>
                </div>
                <div className="fl-row grow force-small-gap"></div>
                {props.value !== 0 && props.value !== '0' && props.value !== '' &&
                    <div className="fl-row grow light-text">
                        Local Time ({localTimezone.abbrev}, UTC{`${localTimezone.offset >= 0 ? '+' : ''}`}{localTimezone.offset.toFixed(0)}:{localTimezone.offset%1 * 60 < 10 ? '0' : ''}{localTimezone.offset%1 * 60}): {selectedDate !== null && selectedTime !== null && getShortDateTimeString(calculateResultingUtcUnixTimestamp(), false, ', ')}
                    </div>
                }
                {(props.value === 0 || props.value === '0' || props.value === '' || !props.value) &&
                    <div className="error">
                        No time or date set, defaulting to current time and date.
                    </div>
                }
                {showError &&
                    <div className="error">
                        {error}&nbsp;
                    </div>
                }
            </div>
        </div>
    )
}

DateTimeTimezoneSelect.propTypes = {
    id: PropTypes.string,
    initialDateTime: PropTypes.string,
    selectFunc: PropTypes.func,
    allowPast: PropTypes.bool,
    label: PropTypes.string,
    showLabel: PropTypes.bool,
    labelNote: PropTypes.string,
    showError: PropTypes.bool,
    error: PropTypes.string,
}

DateTimeTimezoneSelect.defaultProps = {
    id: "date_selector",
    initialDateTime: null,
    selectFunc: null,
    allowPast: false,
    label: 'Date and Time',
    showLabel: false,
    labelNote: '',
    showError: false,
    error: '',
}

export default DateTimeTimezoneSelect;
