import PropTypes from 'prop-types';
import "./CampaignAnalytics.scss";
import { convertMSToHMS, getLongDateString } from "../../helpers/Dates";
import { IconJsxer } from '../../helpers/IconHelper';
import HeadedPanel from '../HeadedPanel/HeadedPanel';
import LeftColorBlockBox from '../LeftColorBlockBox/LeftColorBlockBox';

/*
    If you want to restyle this, override the css!
*/
const CampaignAnalytics = (props) => {

    let dataObj = {
        numberOfStreams:0,
        totalInteractions:0,
        uniqueUsers:0,
        peakViewers: 0,
        engagementTime:0,
        totalRunTime:0,
        firstStreamTime:0,
        firstStreamEndTime:0,
        featuresRun:0,
        disruptorsRun:0,
        botsPosted:0,
    }
    props._campaignAnalyticsSummary.forEach((campaignSummary) =>{
        let includedStreamInOverall = false;
        campaignSummary.recordedAnalytics.forEach((individualStreamData) => {
            if(!individualStreamData.disabled)
            {
                if(!includedStreamInOverall) 
                {
                    dataObj.numberOfStreams ++;
                    includedStreamInOverall = true;
                }

                if(dataObj.firstStreamTime == 0 || dataObj.firstStreamTime > individualStreamData.featureStartTime )  
                {
                    dataObj.firstStreamTime = individualStreamData.featureStartTime      
                    dataObj.firstStreamEndTime = individualStreamData.featureStartTime +  individualStreamData.featureRunTime 
                } 
                dataObj.uniqueUsers += individualStreamData.uniqueUsers;
                dataObj.totalInteractions += individualStreamData.interactions;
                dataObj.engagementTime += individualStreamData.engagementTime;
                dataObj.totalRunTime += individualStreamData.featureRunTime;
                if (individualStreamData.peakViewers > dataObj.peakViewers) {
                    dataObj.peakViewers = individualStreamData.peakViewers;
                }
                dataObj.disruptorsPlayed += individualStreamData.disruptorsFired;
                dataObj.botsPosted += individualStreamData.botsPosted;
            }
            //datatObj.campaignData.push(individualStreamData);
        })
        
    })

    const startTimeStr = convertMSToHMS(dataObj.firstStreamTime);
    const endTimeStr = convertMSToHMS(dataObj.firstStreamTime + dataObj.totalRunTime);
    const firstStreamTotalTimeStr = convertMSToHMS(dataObj.firstStreamEndTime - dataObj.firstStreamTime);
    const engagementTimeStr = convertMSToHMS(dataObj.engagementTime);
    const totalRunTimeStr = convertMSToHMS(dataObj.totalRunTime);
    
    return (
        <>
            <div className={`analytics-summary-panel no-panel-shadow${props._clickable ? ' button-active' : ''}`} onClick={props._clickable ? (e) => {e.preventDefault(); props._openAnalyticsOverview(e, props._campaignId);} : null}>
                <div className="fl-row grow">
                    <div className="fl-column shrink fl-start">
                        <div>
                            <h3>{props._campaignName}</h3>
                            <h4 className='light-text'>{props._client}</h4>
                        </div>

                        {/* <div className='grow'></div> */}

                        <div className='fl-column compact'>
                            <div className='light-text'>First Streamed:</div>
                            <div className='fl-row compact'>
                                <div>{IconJsxer.GetIcon(IconJsxer.ICONS.scheduleNav, IconJsxer.ICON_STYLES.standardW)}</div>
                                <div className="time">{getLongDateString(dataObj.firstStreamTime)}</div>
                            </div>
                        </div>

                        {!props._clickable &&
                            <div className='grow'></div>
                        }

                        <div className='fl-column compact'>
                            <div className='fl-row compact'>
                                <div>{IconJsxer.GetIcon(IconJsxer.ICONS.liveStream, IconJsxer.ICON_STYLES.standardW)}</div>
                                <div>Streams: {dataObj.numberOfStreams}/{props._numberOfStreams}</div>
                            </div>
                            <div className='fl-row compact'>
                                <div>{IconJsxer.GetIcon(IconJsxer.ICONS.eye, IconJsxer.ICON_STYLES.standardW)}</div>
                                <div>Peak Viewers: {dataObj.peakViewers.toLocaleString()}</div>
                            </div>
                            <div className='fl-row compact'>
                                <div>{IconJsxer.GetIcon(IconJsxer.ICONS.clock, IconJsxer.ICON_STYLES.standardW)}</div>
                                <div>Total Runtime: {totalRunTimeStr.h+':'+totalRunTimeStr.m_str+':'+totalRunTimeStr.s_str}</div>
                            </div>
                        </div>

                        {props._clickable &&
                            <button className='standard-button tight' onClick={(e) => {e.preventDefault(); props._openAnalyticsOverview(e, props._campaignId);}}>More Details</button>
                        }

                    </div>

                    <div className='grow'></div>

                    <div className='fl-column'>
                        <div className='fl-row'>

                            <div className='fl-column'>
                                <HeadedPanel
                                    color="an-brand-engagement"
                                    headingContent={
                                        <>
                                            <div className="grow"></div>
                                            {IconJsxer.GetIcon(IconJsxer.ICONS.bullhorn, IconJsxer.ICON_STYLES.streamStatHeader)}
                                            <div className="grow"></div>
                                        </>
                                    }
                                    className="small-analytics-stat-panel tight"
                                >
                                    <div className="j-center analytics-summary-content">
                                        <h3>{engagementTimeStr.h+':'+engagementTimeStr.m_str+':'+engagementTimeStr.s_str}</h3>
                                        <p>Feature Engagement</p>
                                    </div>
                                </HeadedPanel>

                                <LeftColorBlockBox style="feature_default">
                                        <div className='analytics-summary-content'>
                                            <h4>{dataObj.featuresRun}</h4>
                                            <div>Features Ran</div>
                                        </div>
                                </LeftColorBlockBox>
                            </div>

                            <div className='fl-column'>
                                <HeadedPanel
                                    color="an-interactions"
                                    headingContent={
                                        <>
                                            <div className="grow"></div>
                                            {IconJsxer.GetIcon(IconJsxer.ICONS.hashtag, IconJsxer.ICON_STYLES.streamStatHeader)}
                                            <div className="grow"></div>
                                        </>
                                    }
                                    className="small-analytics-stat-panel tight"
                                >
                                    <div className="j-center analytics-summary-content">
                                        <h3>{dataObj.totalInteractions.toLocaleString()}</h3>
                                        <p>Total Interactions</p>
                                    </div>
                                </HeadedPanel>

                                    <LeftColorBlockBox style="disruptor_default">
                                        <div className='analytics-summary-content'>
                                            <h4>{dataObj.disruptorsRun}</h4>
                                            <div>Disruptors Started</div>
                                        </div>
                                    </LeftColorBlockBox>
                            </div>


                            <div className='fl-column'>
                                <HeadedPanel
                                    color="an-u-users"
                                    headingContent={
                                        <>
                                            <div className="grow"></div>
                                            {IconJsxer.GetIcon(IconJsxer.ICONS.usersNav, IconJsxer.ICON_STYLES.streamStatHeader)}
                                            <div className="grow"></div>
                                        </>
                                    }
                                    className="small-analytics-stat-panel tight"
                                >
                                    <div className="j-center analytics-summary-content">
                                        <h3>{dataObj.uniqueUsers.toLocaleString()}</h3>
                                        <p>Unique Users</p>
                                    </div>
                                </HeadedPanel>

                                <LeftColorBlockBox style="bot_default">
                                        <div className='analytics-summary-content'>
                                            <h4>{dataObj.botsPosted}</h4>
                                            <div>Bot Posts</div>
                                        </div>
                                </LeftColorBlockBox>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default CampaignAnalytics;