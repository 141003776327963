import "./CreateCampaignProgressBar.scss";

const chevronEndR = () => {
    return (
        <svg width="30" height="60">
            <path d="M0 0 L10 0 L30 30 L10 60 L0 60" strokeLinecap="round" strokeWidth="2"/>
            <path d="M0 0 L10 0 M10 60 L0 60" strokeLinecap="round" strokeWidth="4" fill="none"/>
        </svg>
    )
}

const chevronEndL = () => {
    return (
        <svg width="30" height="60">
            <path d="M30 0 L0 0 L20 30 L0 60 L30 60" strokeLinecap="round" strokeWidth="2"/>
            <path d="M30 0 L0 0 M30 60 L0 60" strokeLinecap="round" strokeWidth="4" fill="none"/>
        </svg>
    )
}

export const CreateCampaignProgressBar = (props) => {
    const stages = [];
    for (let i = 1; i <= 5; i++) {
        if (i === props.stage) {
            stages.push('in-progress');
        } else
        if (i < props.stage || props.startedStages.indexOf(i) >= 0) {
            stages.push('completed');
        } else {
            stages.push('not-started');
        }
    }

    return (
        <div className="create-campaign-progress-bar">

            <div className={`progress-block${stages[0] === 'completed' ? ' button-active' : ''}`} onClick={stages[0] === 'completed' ? () => props.setStageFunc(1) : null}>
                <div className={`left-curve ${stages[0]}`}></div>
                <div className={`block-mid ${stages[0]}`}>1. General Details</div>
                <div className={`${stages[0]} end`}>{chevronEndR()}</div>
            </div>
            
            <div className={`progress-block${stages[1] === 'completed' ? ' button-active' : ''}`} onClick={stages[1] === 'completed' ? () => props.setStageFunc(2) : null}>
                <div className={`between ${stages[1]} end`}>{chevronEndL()}</div>
                <div className={`block-mid ${stages[1]}`}>2. Contacts</div>
                <div className={`${stages[1]} end`}>{chevronEndR()}</div>
            </div>

            <div className={`progress-block${stages[2] === 'completed' ? ' button-active' : ''}`} onClick={stages[2] === 'completed' ? () => props.setStageFunc(3) : null}>
                <div className={`between ${stages[2]} end`}>{chevronEndL()}</div>
                <div className={`block-mid ${stages[2]}`}>3. Producers</div>
                <div className={`${stages[2]} end`}>{chevronEndR()}</div>
            </div>

            <div className={`progress-block${stages[3] === 'completed' ? ' button-active' : ''}`} onClick={stages[3] === 'completed' ? () => props.setStageFunc(4) : null}>
                <div className={`between ${stages[3]} end`}>{chevronEndL()}</div>
                <div className={`block-mid ${stages[3]}`}>4. Streams</div>
                <div className={`${stages[3]} end`}>{chevronEndR()}</div>
            </div>

            <div className={`progress-block${stages[4] === 'completed' ? ' button-active' : ''}`} onClick={stages[4] === 'completed' ? () => props.setStageFunc(5) : null}>
                <div className={`between ${stages[4]} end`}>{chevronEndL()}</div>
                <div className={`block-mid ${stages[4]}`}>5. Content</div>
                <div className={`right-curve ${stages[4]}`}></div>
            </div>
        </div>
    )
}
