import { useState } from "react";
import { assets } from "../../assets/assets";
import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import DropDownList from "../../components/forms/DropDownList/DropDownList";
import { ACCOUNT_TYPES, getAcDropListData, getAcTypeData, getUserLevel } from "../../helpers/AccountTypes";
import { IconJsxer } from "../../helpers/IconHelper";
import DebugLogger from "../../helpers/DebugLogger";
import axios from "../../api/axios";
import { changeUserTypeRoute } from "../../api/routes";
import { toast } from "react-toastify";
import FormDropDown from "../../components/forms/DropDownList/FormDropDown";

const ChangeUserType = (props) => {
    const { user, loggedInUser, maxChangeLevel, closePanelFunc, refreshUserListFunc } = props;
    const acTypeData = getAcTypeData(user.acType);

    const userTypesListData = getAcDropListData([{ id: 'nochange', label: 'No Change', deselect: true, }], maxChangeLevel, [ACCOUNT_TYPES.notset]);
    const [userType, setUserType] = useState(null);
    const updateUserTypeHandler = async (e, dropDownId, item) => {
        setUserType(item);
    }

    const [isSubmitting, setIsSubmitting] = useState(false);
    const submitChangeUserType = (event) => {
        event.preventDefault();
        if (!isSubmitting) {
            setIsSubmitting(true);

            axios
                .post(changeUserTypeRoute, { id: user._id, newType: userType.id, withCrednetials: true })
                .then((response) => {
                    user.acType = userType.id;
                    setIsSubmitting(false);
                    toast.success("User Type has been successfuly changed");
                    if (refreshUserListFunc) {
                        refreshUserListFunc();
                    }
                })
                .catch((err) => {
                    setIsSubmitting(false);
                    toast.error("Oops something went wrong, try again");
                })
        }
    }

    return (
        <PopUpPanel closePanelFunc={closePanelFunc}>
            <div className="change-user-panel-content">
                <h2>Change User Type</h2>
                <div className="label">{user.name}</div>
                <div className="light-text">{user.email}</div>
                <div className="small-text light-text">{user._id}</div>
                <div className="fl-row">
                <div className={`ac-pill ${acTypeData.colorCss}`}>
                    <p>{acTypeData.shortName}</p>
                    {acTypeData.icon &&
                        <>
                            {IconJsxer.GetIcon(acTypeData.icon, IconJsxer.ICON_STYLES.acPillIcon, 'icon')}
                        </>
                    }
                    {!acTypeData.icon && acTypeData.useTwitchIcon &&
                        <img src={assets.TwitchLogoSmall} className="tw-icon" alt="" />
                    }
                </div>
                    <div className="grow"></div>
                </div>
                {acTypeData.level <= maxChangeLevel && loggedInUser._id !== user._id &&
                    <div className="form-holder">
                        <form className="form">
                            <div className="label">Change To:</div>
                            <FormDropDown
                                id="changeusertype"
                                selectFunc={updateUserTypeHandler}
                                currentIndex={userType ? userType.index : 0}
                                deselectedLabel="No Change"
                                icon="oneUser"
                                items={userTypesListData}
                                enabled={!isSubmitting}
                                showLabel={false}
                                showErrors={false}
                            />
                            <button className={'submit-btn' + ((isSubmitting || userType === null || userType.index === 0 || userType.id === user.acType || acTypeData.level > maxChangeLevel) ? ' button-inactive' : ' button-active')} onClick={isSubmitting ? null : submitChangeUserType}>
                                {isSubmitting ? "Submitting" : "Submit Change"}
                            </button>
                        </form>
                    </div>
                }
                {(loggedInUser._id === user._id) &&
                    <div>You can't change your own user level.</div>
                }
                {(loggedInUser._id !== user._id && acTypeData.level > maxChangeLevel) &&
                    <div>You can't change this user's level.</div>
                }
            </div>
        </PopUpPanel>
    );
}

export default ChangeUserType;
