import { IconJsxer } from "./IconHelper"

export const BOT_TYPES = {
    manual: {
        displayName: 'Manual',
        dbString: 'manual',
        icon: IconJsxer.ICONS.manual,
    },
    auto: {
        displayName: 'Auto',
        dbString: 'auto',
        icon: IconJsxer.ICONS.automatic,
    },
    interval: {
        displayName: 'Interval',
        dbString: 'interval',
        icon: IconJsxer.ICONS.interval,
    },
    response: {
        displayName: 'Response',
        dbString: 'response',
        icon: IconJsxer.ICONS.response,
    }
}

export const BOT_TYPE_LIST_ORDER = [
    BOT_TYPES.manual,
    BOT_TYPES.auto,
    BOT_TYPES.interval,
    BOT_TYPES.response,
]

export const getBotTypeDataFromDbString = (dbString) => {
    for (let i = 0; i < BOT_TYPE_LIST_ORDER.length; i++) {
        if (BOT_TYPE_LIST_ORDER[i].dbString === dbString) {
            return BOT_TYPE_LIST_ORDER[i];
        }
    }
    return BOT_TYPES.manual;
}

export const getBotTypesListData = () => {
    const listData = [];
    for (let i = 0; i < BOT_TYPE_LIST_ORDER.length; i++) {
        listData.push({
            id: BOT_TYPE_LIST_ORDER[i].dbString, 
            value: BOT_TYPE_LIST_ORDER[i].dbString, 
            label: BOT_TYPE_LIST_ORDER[i].displayName,
        })
    }
    return listData;
}
