/**
 * Prepare an alphaNumeric string for case insensitive comparison, basically perform toLowerCase() on it...
 * @param {string} a 
 * @returns A string that is now all lower case
 */
export const alphaTypePrimer = (a) => a.toLowerCase();
/**
 * We use parseInt to prepare numbers for comparison (in case numbers come out as string from the db for example)
 */
export const numberTypePrimer = parseInt;
/**
 * We use toString for Booleans
 */
export const boolTypePrimer = (a) => a.toString();
/**
 * A generic toString one...
 */
export const toStringPrimer = (a) => a.toString();

/**
 * A generic primer, similar to alphaTypePrimer BUT handles nulls too
 */
export const nullOrAlphaTypePrimer = (a) => {
    return (a == null) ? "" : a.toLowerCase();
}

/**
 * A specialised primer for custom lists from a dropdown list (expects a list with items containing an id), requires you to pass the list as well as the value
 */
export const customListPrimer = (a, list) => {

    let score = list.length*50;
    for(var listItemKey in list) {
        let listItem = list[listItemKey];
        if(a === listItem.id) {
            return score;
        }
        score -= 50;
    }
}

/**
//  * A specialised primer for bugReport statuses
//  */
// export const bugReportStatusPrimer = (a, statuses) => {
//     let score = statuses.length*50;
//     for(var statusKey in statuses) {
//         let status = statuses[statusKey];
//         if(a === status.id) {
//             return score;
//         }
//         score -= 50;
//     }
// }

/**
 * An easy to remember store of the above primer functions
 */
export const PRIMERS = {
    alphaTypePrimer: alphaTypePrimer,
    nullOrAlphaTypePrimer: nullOrAlphaTypePrimer,
    numberTypePrimer: numberTypePrimer,
    boolTypePrimer: boolTypePrimer,
    toStringPrimer: toStringPrimer,
    customListPrimer: customListPrimer
}

/**
 * Sort a list on a specific field. Only supports single fields.
 * @param {array} list Your list should be an array of objects
 * @param {string} sortField The field to sort on
 * @param {(1|-1)} reverse Forward or reverse?
 * @param {function} primer A function that prepares data in some way. Accepts data and returns processed data (see PRIMERS above)
 * @returns A sorted list
 */
export const sortListOn = (list, sortField, reverse, primer = null) => {
    const sort_by = (field, reverse, primer) => {

        const key = primer ?
            function (x) {
                return primer(x[field])
            } :
            function (x) {
                return x[field]
            };

        reverse = reverse ? 1 : -1;

        return function (a, b) {
            a = key(a);
            b = key(b);
            return reverse * ((a > b) - (b > a));
        }
    }

    return list.sort(sort_by(sortField, reverse, primer));
}

/* Could we use object.filter and object.find for these? */


const recursiveFieldFilterCheck = (fields, object, values, key) => {
    for (let j = 0; j < fields.length; j++) {
        if (object.hasOwnProperty(fields[j])) {
            if (values.indexOf(key(object[fields[j]])) >= 0) {
                return true;
            } else if(j < fields.length-1) {
                let newFields = fields.slice();
                newFields.splice(j, 1);
                if(object[fields[j]] && object[fields[j]].hasOwnProperty) {
                    return recursiveFieldFilterCheck(newFields, object[fields[j]], values, key);
                } else {
                    return false;
                }
            }
        }
    }
    return false;
}

/**
 * Filter a list, remove items that don't match our filter criteria. Supports multiple fields and multiple values.
 * @param {array} list Your list to be filtered
 * @param {(array|string)} fields An array of field names to search, or a string for a single field name
 * @param {(array|string|number|any)} values An array of values to search for or a single value
 * @param {function} primer A function that prepares data in some way. Accepts data and returns processed data (see PRIMERS above)
 * @returns A filtered list
 */
export const filterList = (list, fields, values, primer = null) => {
    let filteredList = list.concat();
    if (!Array.isArray(fields)) {
        fields = [fields];
    }
    if (!Array.isArray(values)) {
        values = [values];
    }

    const key = primer ?
            function (x) {
                return primer(x);
            } :
            function (x) {
                return x;
            };

    for (let i = filteredList.length - 1; i >= 0; i--) {
        let found = false;
        //let fieldFound = null;
        
        found = recursiveFieldFilterCheck(fields, filteredList[i], values, key);

        if (!found) {
            filteredList.splice(i, 1);
        }
    }

    return filteredList;
}

/**
 * Search a list. A bit like filterList above, but only supports a single value and matches string as part of longer strings (doesn't need to be an exact match).
 * @param {array} list A list to be searched
 * @param {(array|string)} fields An array of field names to search, or a string for a single field name
 * @param {any} value Techincally we can pass in any type for value and search for it, but usually we would use string or number
 * @param {function} primer A function that prepares data in some way. Accepts data and returns processed data (see PRIMERS above)
 * @returns A new list of matched items
 */
export const searchList = (list, fields, value, primer = null) => {
    let searchedList = list.concat();
    if (!Array.isArray(fields)) {
        fields = [fields];
    }

    const key = primer ?
            function (x) {
                return primer(x);
            } :
            function (x) {
                return x;
            };

    for (let i = searchedList.length - 1; i >= 0; i--) {
        let found = false;
        for (let j = 0; j < fields.length; j++) {
            if (searchedList[i].hasOwnProperty(fields[j])) {
                // If the field is an array (eg. a list of producer accounts, we need to search within that array)
                if (Array.isArray(searchedList[i][fields[j]])) {
                    for (let k = 0; k < searchedList[i][fields[j]].length; k++) {
                        // Apply the same field list!
                        for (let l = 0; l < fields.length; l++) {
                            if (searchedList[i][fields[j]][k].hasOwnProperty(fields[l])) {
                                if (key(searchedList[i][fields[j]][k][fields[l]]) === key(value) || key(searchedList[i][fields[j]][k][fields[l]]).indexOf(key(value)) >= 0) {
                                    found = true;
                                    break;
                                }
                            }
                        }
                    }
                } else {
                    //  Search is almost the same as filter, but we accept part strings so we use indexOf, also the primer is applied to both search term and what we are searching in
                    if (key(searchedList[i][fields[j]]) === key(value) || key(searchedList[i][fields[j]]).indexOf(key(value)) >= 0) {
                        found = true;
                        break;
                    }
                }
            }
            if (found) {
                break;
            }
        }
        if (!found) {
            searchedList.splice(i, 1);
        }
    }

    return searchedList;
}
