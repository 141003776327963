import "./EditStackingOrder.scss";

import React, { useState, useEffect, useContext, useRef } from "react";

import PopUpPanel from "../../../components/PopUpPanel/PopUpPanel";
import { IconJsxer } from "../../../helpers/IconHelper";
import { toast } from "react-toastify";

const EditStackingOrder = (props) => {
    const features = props.features;
    const stackData_ref = useRef([]);

    const [currentStack, setCurrentStack] = useState(null);
    const initState = () => {
        const stackState = [];
        for (let i = 0; i < stackData_ref.current.length; i++) {
            stackState.push(stackData_ref.current[i]);
        }
        setCurrentStack(stackState);
    }
    useEffect(() => {
        for (let i = 0; i < features.length; i++) {
            stackData_ref.current.push({
                stackingOrder: features[i].stackingOrder,
                feature: features[i],
            });
        }
        stackData_ref.current.sort((a, b) => b.stackingOrder - a.stackingOrder);

        initState();
    }, []);

    const changeOrder = (stackItem, dir = 1) => {
        if ( 
            (dir === 1 && stackData_ref.current.indexOf(stackItem) < stackData_ref.current.length - 1)
            || (dir === -1 && stackData_ref.current.indexOf(stackItem) > 0)
        ) {
            const index = stackData_ref.current.indexOf(stackItem);
            const indexSwap = index + dir;
            const newStackOrder = stackData_ref.current[indexSwap].stackingOrder;
            stackData_ref.current[indexSwap].stackingOrder = stackItem.stackingOrder;
            stackItem.stackingOrder = newStackOrder;
            stackData_ref.current.sort((a, b) => b.stackingOrder - a.stackingOrder);
            initState();
        }
    }

    const saveChanges = () => {
        for (let i = 0; i < stackData_ref.current.length; i++) {
            stackData_ref.current[i].feature.stackingOrder = stackData_ref.current[i].stackingOrder;
        }
        props.saveFeatureUpdates();
        props.closePanelFunc();
    }

    return (
        <PopUpPanel closePanelFunc={props.closePanelFunc} className="stacking-panel">
            <div className="stack">
                <div className="fl-column">
                    <h2>Edit Stacking Order</h2>

                    <div>Features and Disruptors higher up the list will appear over the top of those further down.</div>

                    <div className="stack-scroll">

                        {/* <div className="list-item li-dark">
                            <div>Content Scene Library</div>
                            <div className="light-text">Always On Top</div>
                            <div className="grow"></div>
                        </div> */}

                        {stackData_ref.current.map(
                            (val, index, arr) => {
                                return (
                                    <div className={`list-item ${index % 2 === 0 ? 'li-light' : 'li-dark'}`} key={'st' + index}>
                                        <div>{val.feature.contentLabel}</div>
                                        <div className="light-text">{val.feature.feature.featureName}</div>
                                        <div className="grow"></div>
                                        <div className={`edit-order-button${index > 0 ? '' : ' edit-but-off'}`} onClick={() => changeOrder(val, -1)}>{IconJsxer.GetIcon(IconJsxer.ICONS.chevronUp, IconJsxer.ICON_STYLES.controlMoreInfo)}</div>
                                        <div className={`edit-order-button${index < arr.length - 1 ? '' : ' edit-but-off'}`} onClick={() => changeOrder(val, 1)}>{IconJsxer.GetIcon(IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.controlMoreInfo)}</div>
                                    </div>
                                )
                            }
                        )}

                    </div>

                    <div className="fl-row">
                        <button className="standard-button" onClick={saveChanges}>Save</button>
                    </div>
                </div>
            </div>
        </PopUpPanel>
    )
}

export default EditStackingOrder;
