import React, { useState, useEffect, useContext } from "react";
import PropTypes from 'prop-types';
import validator from "validator";

import axios from "../../api/axios";

import DebugLogger from "../../helpers/DebugLogger";
import { toast } from "react-toastify";
import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import FormField from "../../components/forms/FormField/FormField";
import RadioButtonGroup from "../../components/forms/RadioButtonGroup/RadioButtonGroup";
import TextArea from "../../components/forms/TextArea/TextArea";
import { CampaignContext } from "../../contexts/CampaignContext";
import { addCampaignBotRoute, archiveCampaignBotRoute, updateCampaignBotRoute } from "../../api/routes";
import HMSTimeField from "../../components/forms/HMSTimeField/HMSTimeField";
import { BOT_TYPES, getBotTypesListData } from "../../helpers/Bots";
import OneBotOverview from "../../components/Bots/OneBotOverview";

const DuplicateStreamBot = (props) => {
    const [campaignContext, setCampaignContext] = useContext(CampaignContext);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const duplicateBot = (e,) =>
    {
        e.preventDefault();
        if(!isSubmitting)
        {
            const botData = props.bot;
            setIsSubmitting(true);
            axios
            .post(addCampaignBotRoute, { 
                campaignID:props.campaignId,
                botName:botData.name + ' (COPY)', 
                botType:botData.botType, 
                autoPostTime:botData.autoPostTime, 
                botCopy:botData.botCopy, 
                withCredentials:true
                
            })
            .then((response) =>{
                let oldCampaignData = campaignContext.campaignData
                oldCampaignData.chatbots = response.data.campaignData.chatbots;
                setIsSubmitting(false);
                setCampaignContext((oldValues) => {
                    return { ...oldValues, initialising: false, campaignData: oldCampaignData }
                })

                toast.success("Bot ("+botData.botName+") successfully duplicated");
                props.closePanelFunc();
            })
        }
    }

    return (
        <PopUpPanel closePanelFunc={props.closePanelFunc}>
            <div className="form-holder co-popup-content">
                <form id="addstreambot" className="form">
                    <h2>Duplicate This Stream Bot?</h2>

                    <div className="drop-gap">
                        <OneBotOverview bot={props.bot} />
                    </div>

                    <button className={'submit-btn' + (isSubmitting ? ' button-inactive' : ' button-active')} onClick={isSubmitting ? null : duplicateBot}>
                        {isSubmitting ? "Duplicating Bot" : "Duplicate Bot"}
                    </button>
                </form>
            </div>
        </PopUpPanel>
    )
}

export default DuplicateStreamBot;
