import "./Themes.scss";

import React, { useState, useEffect, useContext, useRef } from "react";

import PopUpPanel from "../../../components/PopUpPanel/PopUpPanel";
import { IconJsxer } from "../../../helpers/IconHelper";
import { toast } from "react-toastify";
import FormField from "../../../components/forms/FormField/FormField";

const CreateThemePopup = (props) => {
    const [themeName, setThemeName] = useState('');
    const [themeNameError, setThemeNameError] = useState('');
    const [savingTheme, setSavingTheme] = useState(false);

    const saveTheme = () => {
        if (themeName === '') {
            setThemeNameError('Please enter a name for the theme.');
            return;
        } else {
            setThemeNameError('');
        }

        setSavingTheme(true);
        if (props.addTheme) {
            props.addTheme(themeName);
        }
    }

    return (
        <PopUpPanel closePanelFunc={props.closePanelFunc} className="themes-narrow-panel">
            <div className="theme-list">
                <div className="fl-column">
                    <h2>Create New Theme</h2>

                    <div className="fl-row form-holder">

                        <FormField
                            id="theme_name"
                            label="Theme Name"
                            type="text"
                            value={themeName}
                            placeholder={'Enter Theme Name'}
                            setFunc={setThemeName}
                            showErrors={true}
                            error={themeNameError}
                        />

                    </div>
                    
                </div>

            </div>

            <div className="fl-row">
                <button className={`standard-button${savingTheme ? ' button-inactive' : ' button-active'}`} onClick={savingTheme ? null : saveTheme}>{savingTheme ? 'Saving Theme' : 'Save Theme'}</button>
            </div>
        </PopUpPanel>
    )
}

export default CreateThemePopup;
