import "./RateStreamPopup.scss";

import React, { useState, useRef } from "react";

import PopUpPanel from "../../../components/PopUpPanel/PopUpPanel";
import { IconJsxer } from "../../../helpers/IconHelper";
import { toast } from "react-toastify";
import { assets } from "../../../assets/assets";
import axios from "../../../api/axios";
import { rateStreamRoute } from "../../../api/routes";

const RateStreamPopup = (props) => {
    const currentRating = useRef(props.rating);
    const [displayRating, setDisplayRating] = useState(props.rating);
    const [submittingRatingAs, setSubmittingRatingAs] = useState(0);
    const [submittingRating, setSubmittingRating] = useState(false);
    const [hasRated, setHasRated] = useState(false);

    const rateStream = (rating) => {
        if (!hasRated) {
            setSubmittingRatingAs(rating);
            setSubmittingRating(true);
            axios.post(rateStreamRoute, {
                streamID: props.streamId,
                campaignID: props.campaignId,
                rating: rating,
            })
            .then(function(response) {
                currentRating.current = rating;
                setDisplayRating(rating);
                setSubmittingRating(false);
                setHasRated(true);
                // closePanelFunc();
                toast.success("Stream rated successfully, thank you!");
            })
            .catch(function(error) {
                setDisplayRating(currentRating.current);
                setSubmittingRating(false);
                toast.error("Error rating stream");
            });
        }
    }

    const hoverOverStar = (rating) => {
        if (!hasRated) {
            setDisplayRating(rating);
        }
    }

    const rollOutStar = () => {
        if (!hasRated) {
            setDisplayRating(currentRating.current);
        }
    }

    return (
        <PopUpPanel closePanelFunc={props.closePanelFunc}>

            <div className="rate-stream-popup-content">

                <div className="grow"></div>

                <h1>Please Rate Your Stream</h1>

                <div className="fl-row">
                    <div className="grow"></div>

                    <div className={`rating-stars-container${hasRated ? ' has-rated' : ''}`}>
                        <div className={`rating-star ${(!submittingRating && displayRating >= 1) || (submittingRating && submittingRatingAs >= 1) ? 'active-star' : 'inactive-star'}`} onMouseOver={() => hoverOverStar(1)} onMouseOut={rollOutStar} onClick={() => rateStream(1)}>
                            {IconJsxer.GetIcon(IconJsxer.ICONS.star, IconJsxer.ICON_STYLES.ratingStar)}
                        </div>
                        <div className={`rating-star ${(!submittingRating && displayRating >= 2) || (submittingRating && submittingRatingAs >= 2) ? 'active-star' : 'inactive-star'}`} onMouseOver={() => hoverOverStar(2)} onMouseOut={rollOutStar} onClick={() => rateStream(2)}>
                            {IconJsxer.GetIcon(IconJsxer.ICONS.star, IconJsxer.ICON_STYLES.ratingStar)}
                        </div>
                        <div className={`rating-star ${(!submittingRating && displayRating >= 3) || (submittingRating && submittingRatingAs >= 3) ? 'active-star' : 'inactive-star'}`} onMouseOver={() => hoverOverStar(3)} onMouseOut={rollOutStar} onClick={() => rateStream(3)}>
                            {IconJsxer.GetIcon(IconJsxer.ICONS.star, IconJsxer.ICON_STYLES.ratingStar)}
                        </div>
                        <div className={`rating-star ${(!submittingRating && displayRating >= 4) || (submittingRating && submittingRatingAs >= 4) ? 'active-star' : 'inactive-star'}`} onMouseOver={() => hoverOverStar(4)} onMouseOut={rollOutStar} onClick={() => rateStream(4)}>
                            {IconJsxer.GetIcon(IconJsxer.ICONS.star, IconJsxer.ICON_STYLES.ratingStar)}
                        </div>
                        <div className={`rating-star ${(!submittingRating && displayRating >= 5) || (submittingRating && submittingRatingAs >= 5) ? 'active-star' : 'inactive-star'}`} onMouseOver={() => hoverOverStar(5)} onMouseOut={rollOutStar} onClick={() => rateStream(5)}>
                            {IconJsxer.GetIcon(IconJsxer.ICONS.star, IconJsxer.ICON_STYLES.ratingStar)}
                        </div>
                    </div>

                    <div className="grow"></div>
                </div>

                <div className="fl-row">
                    <div className="grow"></div>
                    <p>{hasRated && <>Thank you for rating this stream!</>}{!hasRated && <>Click a star to submit your rating</>}</p>
                    <div className="grow"></div>
                </div>

                <div className="grow"></div>

            </div>

        </PopUpPanel>
    )
}

export default RateStreamPopup;
