import TwitchLogo from "./twitch-logo.svg";
import TwitchLogoSmall from "./twitch-small-logo.svg";
import TwitchLogoSmallWhite from "./twitch-small-logo-white.svg";
import TwitchPPicIcon from "./twitch-ppic-icon.svg";
import BlankUser from "./blank-user.jpg";
import TwitchGlitchGIF from "./twitch_glitch.gif";
import Lock from "./lock.svg";
import Tick from "./tick.svg";

import CDGoGo from "./go-go.svg";
import CDPfGo from "./pf-go.svg";
import CDPoPf from "./po-pf.svg";
import CDPoPo from "./po-po.svg";

import StreamerPreviewBg from "./_streamerExampleBk.jpg";

import an_PlayOrderTopItem from './po-item-top.svg';
import an_PlayOrderItem from './po-items.svg';
import an_PlayOrderLine from './po-line.svg';

import wizDivider from './wiz-divider.svg';
import wizAlert from './wiz-alert.png';

import uplImgIcon from './upl-image.svg';
import uplVidIcon from './upl-video.svg';
import loadingGif from './loading-gif.gif'
import buttonSpinner from './loading_2.gif';
import loadingFeature from './loading_2.gif';

import icastTopLogo from './ICAST_linear_gradient_med.png';
import icastFrontPageLogo from './ICAST_stacked_white_med.png';

export const assets = {
    icastTopLogo: icastTopLogo,
    icastFrontPageLogo: icastFrontPageLogo,

    TwitchLogo: TwitchLogo,
    TwitchLogoSmall: TwitchLogoSmall,
    TwitchLogoSmallWhite: TwitchLogoSmallWhite,
    TwitchPPicIcon: TwitchPPicIcon,
    BlankUser: BlankUser,
    TwitchGlitchGIF:TwitchGlitchGIF,
    LoadingFeature:loadingFeature,
    Lock: Lock,
    Tick: Tick,

    CampaignProgressDividerGoGo: CDGoGo,
    CampaignProgressDividerPfGo: CDPfGo,
    CampaignProgressDividerPoPf: CDPoPf,
    CampaignProgressDividerPoPo: CDPoPo,

    AnPlayOrderTopItem: an_PlayOrderTopItem,
    AnPlayOrderItem: an_PlayOrderItem,
    AnPlayOrderLine: an_PlayOrderLine,

    StreamerPreviewBg: StreamerPreviewBg,

    WizDivider: wizDivider,
    WizAlert: wizAlert,

    UplImgIcon: uplImgIcon,
    UplVidIcon: uplVidIcon,
    LoadingGif: loadingGif,
    ButtonSpinner: buttonSpinner,
}

// An inline svg version of the Twitch Icon so we can change it's colour with css...
export const TwitchIconSvg = (
    <svg width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.865 0L0 5V23H5.838V28L10.703 23H14.595L23.352 14V0H4.865ZM21.406 13L17.514 17H13.622L10.2165 20.5V17H5.838V2H21.406V13ZM18.487 5.5H16.541V11.5H18.487V5.5ZM13.1355 5.5H11.1895V11.5H13.1355V5.5Z" fill="white"/>
    </svg>
);

export const TwitchIconSvgPurple = (
    <svg width="24" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.865 0L0 5V23H5.838V28L10.703 23H14.595L23.352 14V0H4.865ZM21.406 13L17.514 17H13.622L10.2165 20.5V17H5.838V2H21.406V13ZM18.487 5.5H16.541V11.5H18.487V5.5ZM13.1355 5.5H11.1895V11.5H13.1355V5.5Z" fill="#9146FF"/>
    </svg>
);

export const TwitchIconSvgPurpleSmall = (
    <svg width="14" height="17" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.865 0L0 5V23H5.838V28L10.703 23H14.595L23.352 14V0H4.865ZM21.406 13L17.514 17H13.622L10.2165 20.5V17H5.838V2H21.406V13ZM18.487 5.5H16.541V11.5H18.487V5.5ZM13.1355 5.5H11.1895V11.5H13.1355V5.5Z" fill="#9146FF"/>
    </svg>
);
