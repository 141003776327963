import PropTypes from 'prop-types';
import OneStreamOverview from '../../components/Streams/OneStreamOverview';
import { getTimezoneOptions, parseTimezone } from "../../helpers/TimeZones";
import { ACCOUNT_TYPES } from "../../helpers/AccountTypes";

const StreamsOverview = (props) => {
    props.streams.sort((a, b) => a.startTime - b.startTime);

    return (
        <>
        {props.streams.map(
            (val, i) => {
                // Calling getTimezoneOptions has the effect of caching the timezone data with dst so we can parse it correctly
                const tzOptions = getTimezoneOptions(val.startTime);
                const timezoneData = parseTimezone(val.timeZone);
                return (
                    <div key={'stream_' + i}>
                        {(!val.archived || props.editMode) && ((val.twitchHandle.toLowerCase() == props.auth.userData.name.toLowerCase() && props.auth.userData.userLevel == ACCOUNT_TYPES.streamer.level) || props.auth.userData.userLevel != ACCOUNT_TYPES.streamer.level)  &&
                            <OneStreamOverview stream={val} getProfilePicFunc={props.getProfilePicFunc} addStreamChannelFunc={props.addChannelFunc} removeStreamChannelFunc={props.removeChannelFunc} isStreamerOverview={props.auth.userData.userLevel < ACCOUNT_TYPES.creative.level} editMode={props.editMode} editFunc={props.editFunc} removeFunc={props.removeFunc} duplicateFunc={props.duplicateFunc} archived={props.archived} timezoneData={timezoneData} />
                        }
                    </div>
                )
            }
        )}
        </>
    )
}

StreamsOverview.propTypes = {
    bots: PropTypes.array,
    editMode: PropTypes.bool,
    editFunc: PropTypes.func,
    removeFunc: PropTypes.func,
    duplicateFunc: PropTypes.func,
};

StreamsOverview.defaultProps = {
    bots: [],
    editMode: false,
    editFunc: null,
    removeFunc: null,
    duplicateFunc: null,
}

export default StreamsOverview;
