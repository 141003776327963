import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { ACCOUNT_TYPES } from "../../helpers/AccountTypes";
import "./UserLevelRestrict.scss";

/**
 * props:
 * accountType - ACCOUNT_TYPE minimum level requirement, any below this level will be restricted
 * element - the React element to test userLevel for and potentially restrict, if not provided, children will be used
 */
const UserLevelRestrict = (props) => {
  const [authContext, setAuthContext] = useContext(AuthContext);

  return(
    <>
      { authContext.userData.userLevel >= props.accountType.level &&
        <>
          {props.element ? props.element : props.children}
        </>
      }
    </>
  )
}

export default UserLevelRestrict;