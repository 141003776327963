import React, { useState, useEffect, useContext } from "react";
import PropTypes from 'prop-types';
import validator from "validator";

import axios from "../../api/axios";

import DebugLogger from "../../helpers/DebugLogger";
import { toast } from "react-toastify";
import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import FormField from "../../components/forms/FormField/FormField";
import RadioButtonGroup from "../../components/forms/RadioButtonGroup/RadioButtonGroup";
import TextArea from "../../components/forms/TextArea/TextArea";
import { CampaignContext } from "../../contexts/CampaignContext";
import { archiveCampaignBotRoute, archiveStreamRoute, updateCampaignBotRoute } from "../../api/routes";
import HMSTimeField from "../../components/forms/HMSTimeField/HMSTimeField";
import { BOT_TYPES, getBotTypesListData } from "../../helpers/Bots";
import OneBotOverview from "../../components/Bots/OneBotOverview";
import OneStreamOverview from "../../components/Streams/OneStreamOverview";
import { parseTimezone } from "../../helpers/TimeZones";

const ConfirmRemoveStream = (props) => {
    const [campaignContext, setCampaignContext] = useContext(CampaignContext);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const tzOptions = props.tzOptions;
    const timezoneData = parseTimezone(props.stream.timeZone);

    const archiveStream = (e) => {
        e.preventDefault();
        const wasArchived = props.stream.archived;
        axios
            .post(archiveStreamRoute, {
                _id: props.stream._id,
                campaignID: props.campaignId,
                archived: props.stream.archived,
                withCredentials: true
            })
            .then((response) => {
                let updatedCampaignData = campaignContext.campaignData
                updatedCampaignData.streams = response.data.campaignData.streams;
                setCampaignContext((oldValues) => {
                    return { ...oldValues, initialising: false, campaignData: updatedCampaignData }
                })
                console.log(props.stream._id)
                toast.success("Stream successfully " + (wasArchived ? 'restored' : 'archived'));
                props.closePanelFunc();
            })

    }

    return (
        <PopUpPanel closePanelFunc={props.closePanelFunc} className={'confirm-remove-stream-pop'}>
            <div className="form-holder co-popup-content">
                <form id="removestream" className="form">
                    <h2>{props.stream.archived ? 'Restore' : 'Archive'} This Stream?</h2>

                    <div className="drop-gap">
                        <OneStreamOverview stream={props.stream} archived={props.stream.archived} timezoneData={timezoneData} condensed={true} />
                    </div>

                    <div className="fl-row">
                        <button className={'submit-btn' + (isSubmitting ? ' button-inactive' : ' button-active')} onClick={isSubmitting ? null : archiveStream}>
                            {isSubmitting ? (props.stream.archived ? "Restoring Stream" : "Archiving Stream") : (props.stream.archived ? "Restore Stream" : "Archive Stream")}
                        </button>
                    </div>
                </form>
            </div>
        </PopUpPanel>
    )
}

export default ConfirmRemoveStream;
