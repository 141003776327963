import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, } from "react-router-dom";
import axios from "../../api/axios";
import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import {getUserCampaigns } from "../../api/routes";
import { TOP_LEVEL_NAV_ROUTES } from "../../config/NavRoutes";
import CampaignSummaryPanel from "../../components/Campaigns/CampaignSummaryPanel";
import { orderCampaignsAccordingToStatus } from "../../helpers/CampaignsHelper";

const UserCampaignList = (props) => {
    const { user, closePanelFunc } = props;
    const [campaignsData, setCampaignsData] =  useState([]);
    const [showAllCampaigns, setShowAllCampaigns] =  useState(true);
    const navigate = useNavigate();
    const fetchCampaignList = () => {
        axios
        .get(getUserCampaigns, {params: { forcedUserID:user._id}, withCredentials: true})
        .then((response) => {
            console.log(response.data.campaigns)
            //campaigns = response.data.campaigns;
            orderCampaignsAccordingToStatus(response.data.campaigns);
            setCampaignsData( response.data.campaigns)
        })
        .catch((error) => {
          //
          //toast.error(error.response.data.msg);
        });
    }
    const openCampaignOverview = (e, campaignID) => {
        navigate(TOP_LEVEL_NAV_ROUTES.CAMPAIGN_OVERVIEW.replace(':id', campaignID));
    }


    useEffect(() => {
        fetchCampaignList();   
      }, [])
    return (
        <PopUpPanel closePanelFunc={closePanelFunc} className="user-campaigns-popup">
            <div>
                <h2>Active User Campaigns</h2>
                <div className="label">{user.name}</div>
                <div className="scroll-area no-panel-shadow small-headed-panels">
                {
                    campaignsData.map((val, i) => {
                        return (
                            <CampaignSummaryPanel campaign={val} key={'c_' + i} listView={true} />
                        );
                    })
                }
                </div>
            </div>
        </PopUpPanel>
    );
}

export default UserCampaignList;
