import React, { useContext, useEffect, useState } from "react";
import { CampaignContext } from "../../contexts/CampaignContext";
import {getAnalyticCampaignListRoute} from "../../api/routes";
import { toast } from "react-toastify";
import axios from "../../api/axios";
import "./Analytics.scss";
import { AuthContext } from "../../contexts/AuthContext";
import CampaignAnalytics from '../../components/Analytics/CampaignAnalytics';
import { TOP_LEVEL_NAV_ROUTES } from "../../config/NavRoutes";
import { useNavigate } from "react-router-dom";

const Analytics = () => {
    const navigate = useNavigate();

    const openCampaignAnalytics = (e, campaignID) => {
        navigate(TOP_LEVEL_NAV_ROUTES.CAMPAIGN_OVERVIEW_TAB.replace(':id', campaignID).replace(':tab', 'analytics'));
    }


    const [authContext, setAuthContext] = useContext(AuthContext);
    const [campaignList, setCampaignList] = useState([])
    const getCampaignAnalyticData = () => {
        console.log(authContext.userData)
        axios
        .get(getAnalyticCampaignListRoute, {
            params:{ userData:authContext.userData},
            withCredentials:true
        })
        .then(function (response) {
            console.log(response.data.campaignList)
            setCampaignList(response.data.campaignList);
            //setCampaignSummaryAnalytics(response.data.analyticsData.streams);
        })
        .catch(function (error) {
            toast.error('Error - ' + error);
        });
    }

    useEffect(
        () => {
            getCampaignAnalyticData();
        }, []
    )


    return (
        <div className="content-page-container">
            <div className="content-page-top">
                <div className="fl-column grow">
                    <div className="fl-row grow">
                        <div>
                            <h1>Analytics Overview</h1>
                        </div>
                    </div>
                </div>
            </div>


            <div className="content-page-content-container">
                <div className="content-page-main-content">

                    <div className="analytics-page-panel">

                    <div className="underlined-panel-heading">
                        <h4>Campaigns</h4>
                        <div className="grow"></div>
                    </div>

                        <div className="an-fullcol-scroll-area">
                            <div className="fl-column">
                            {campaignList.map(
                                (val, i) => {
                                    return (
                                        <CampaignAnalytics
                                            _campaignAnalyticsSummary={val.analyticsObj.streams}
                                            _campaignName={val.campaignName}
                                            _client={val.client}
                                            _numberOfStreams={val.numberOfStreams}
                                            _campaignId={val.analyticsObj.campaignID}
                                            _openAnalyticsOverview={openCampaignAnalytics}
                                            _clickable={true}
                                            key={"ana_" + i}
                                        />
                                    )
                                }
                            )}
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default Analytics;
