import PropTypes from 'prop-types';
import DebugLogger from "../../../helpers/DebugLogger";

import "./TextArea.scss";

const TextArea = (props) => {
  const { label, labelNote, type, id, rows, placeholder, setFunc, error, value, className } = props;

  if (label === 'Field Label') {
    DebugLogger.warn('No label provided to TextArea, using default...');
  }
  if (id === '') {
    DebugLogger.warn('No id provided to TextArea, using a random id...');
  }
  if (setFunc === null) {
    DebugLogger.warn('You should provide a function called `setFunc(fieldVal)` to TextArea.');
  }

  return (
    <div className={`text-area-holder${className ? ` ${className}` : ''}`}>

      <div className="text-area-content-holder">

        { label != null && 
          <div className="text-area-content label">
            {label}
          </div>
        }
        { labelNote != null && labelNote !== '' &&
          <div className="text-area-content label-note" dangerouslySetInnerHTML={{__html: labelNote}}>
            {/*labelNote*/}
          </div>
        }

        <div className="text-area-content">
          <textarea
            id={id !== '' ? id : Math.floor(Math.random() * 100000).toString()}
            name={id !== '' ? id : Math.floor(Math.random() * 100000).toString()}
            value={value}
            rows={rows}
            onChange={
              (event) => { 
                if (setFunc) { 
                  setFunc(event.target.value);
                }
              }
            }
          />
        </div>

        {props.showError && error !== '' &&
          <div className="error">
            {error}&nbsp;
          </div>
        }
      </div>

    </div>
  );
};

TextArea.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  labelNote: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  id: PropTypes.string.isRequired,
  rows: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string.isRequired,
  className: PropTypes.string,
  setFunc: PropTypes.func,
};

TextArea.defaultProps = {
  label: 'Field Label',
  labelNote: '',
  id: '',
  rows: 5,
  value: undefined,
  error: '',
  className: '',
  setFunc: null,
}

export default TextArea;
