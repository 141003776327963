import PropTypes from 'prop-types';
import DebugLogger from "../../../helpers/DebugLogger";

import "./RangeSlider.scss";

const RangeSlider = (props) => {
    const { showLabel, inlineLabel, label, labelNote, id, setFunc, confirmChangeFunc, value, min, max, showValues, showMidValue, showCurrentValue, useTextLabels, minLabel, midLabel, maxLabel, className } = props;
  
    if (showLabel && label === 'Field Label') {
      DebugLogger.warn('No label provided to FormField, using default...');
    }
    if (id === '') {
      DebugLogger.warn('No id provided to FormField, using a random id...');
    }
  
    return (
      <div className={`form-field-holder${className ? ` ${className}` : ''}`}>
  
        <div className="form-field-content-holder">
  
          {showLabel && !inlineLabel && label != null &&
            <div className="form-field-content label">
              {label}
            </div>
          }
          {showLabel && !inlineLabel && labelNote != null && labelNote !== '' &&
            <div className="form-field-content label-note" dangerouslySetInnerHTML={{__html: labelNote}}>
              {/*labelNote*/}
            </div>
          }
  
          {showCurrentValue &&
            <div className="form-field-content">
                <div className='grow'></div>
                <div>{value}</div>
                <div className='grow'></div>
            </div>
          }
          <div className="form-field-content">
            {showLabel && inlineLabel && label != null &&
              <div className="label">
                {label}
              </div>
            }
            <div className='grow'>
              <input
                type="range"
                min={min}
                max={max}
                id={id !== '' ? id : Math.floor(Math.random() * 100000).toString()}
                name={id !== '' ? id : Math.floor(Math.random() * 100000).toString()}
                value={value}
                onChange={
                  (event) => {
                    if (setFunc) {
                      setFunc(event.target.value);
                    }
                  }
                }
                onMouseUp={
                  (event) => {
                    if (confirmChangeFunc) {
                      confirmChangeFunc(event.target.value);
                    }
                  }
                }
                className='slider'
              />
            </div>
          </div>
          {(showValues || useTextLabels) &&
            <div className="form-field-content s-min-max-display">
                <div className='s-min-display'>{useTextLabels ? minLabel || '' : min}</div>
                {showMidValue &&
                    <div className='s-mid-display'>{useTextLabels ? midLabel || '' : Math.round((max - min) / 2) + min}</div>
                }
                <div className='s-max-display'>{useTextLabels ? maxLabel || '' : max}</div>
            </div>
          }
  
        </div>
  
      </div>
    );
  };
  
  RangeSlider.propTypes = {
    showLabel: PropTypes.bool,
    inlineLabel: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    labelNote: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    min: PropTypes.number,
    max: PropTypes.number,
    showValues: PropTypes.bool,
    showMidValue: PropTypes.bool,
    showCurrentValue: PropTypes.bool,
    useTextLabels: PropTypes.bool,
    minLabel: PropTypes.string,
    midLabel: PropTypes.string,
    maxLabel: PropTypes.string,
    id: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    setFunc: PropTypes.func,
    confirmChangeFunc: PropTypes.func,
  };
  
  RangeSlider.defaultProps = {
    showLabel: true,
    inlineLabel: false,
    label: 'Field Label',
    labelNote: '',
    min: 0,
    max: 100,
    showValues: true,
    showMidValue: true,
    showCurrentValue: false,
    useTextLabels: false,
    minLabel: 'Less',
    midLabel: 'Middle',
    maxLabel: 'More',
    id: '',
    value: undefined,
    className: '',
    setFunc: null,
    confirmChangeFunc: null,
  }
  
  export default RangeSlider;