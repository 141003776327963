import React, { useContext, useState } from "react";
import { CampaignContext } from "../../../contexts/CampaignContext";
import { IconJsxer } from "../../../helpers/IconHelper";
import BotsOverview from "../BotsOverview";
import FeaturePanel, { OneListedCommand } from "../FeaturePanel/FeaturePanel";
import ListedQuiz from "../../Quizzes/ListedQuiz";
import { useNavigate } from "react-router-dom";
import { TOP_LEVEL_NAV_ROUTES } from "../../../config/NavRoutes";
import { AuthContext } from "../../../contexts/AuthContext";
import { ACCOUNT_TYPES } from "../../../helpers/AccountTypes";
import { findLinkedFeature, findLinkedFeatureTriggers, isFeatureLinked } from "../../../helpers/CampaignsHelper";
import LeftColorBlockBox, { LEFT_COLOR_BLOCK_STYLES } from "../../../components/LeftColorBlockBox/LeftColorBlockBox";
import LeftColorBlockHeadedPanel from "../../../components/LeftColorBlockBox/LeftColorBlockHeadedPanel";

const CampaignContentTab = (props) => {
    const [campaignContext, setCampaignContext] = useContext(CampaignContext);
    const [authContext, setAuthContext] = useContext(AuthContext);
    const navigate = useNavigate();

    const [showHashtagsList, setShowHashtagsList] = useState([]);
    const toggleShowHashtagsList = (featureId) => {
        if (showHashtagsList.indexOf(featureId) >= 0) {
            setShowHashtagsList(showHashtagsList.filter((val) => val !== featureId));
        } else {
            setShowHashtagsList([...showHashtagsList, featureId]);
        }
    }

    let gotFeatures = false;
    const featureList = (props._editMode ? props._editedCampaignData : campaignContext.campaignData).features;
    for (let i = 0; i < featureList.length; i++) {
        if (featureList[i].feature.featureType === 'feature') {
            gotFeatures = true;
            break;
        }
    }

    let gotQuizzes = props._editedQuizzesData && props._editedQuizzesData.length>0;
    

    const customiseFeature = (featureId) => {
        navigate(TOP_LEVEL_NAV_ROUTES.CUSTOMISE_FEATURE.replace(':campaignId', props._campaignId).replace(':featureId', featureId));
    }

    // I feel like this function is a bit kludgey and maybe could do with re-working
    const getDisruptorPanel = (val_f, i_f, arr_f, triggered = false, triggeredByTrigger = false, triggerDepth = 0, inFeatureChain = false, featureChain = []) => {
        const featureLinked = isFeatureLinked(val_f._id, campaignContext.campaignData, props._customisationData.current.current);
        if ((val_f.feature.featureType === 'disruptor' || inFeatureChain) && ((!val_f.isChained || triggered || inFeatureChain) || (authContext.userData.userLevel >= ACCOUNT_TYPES.koko.level && props._editMode)) ) {
            // console.log('Disruptor panel: ', val_f);
            let hasChatCommands = false;
            for (let i = 0; i < campaignContext.campaignData.hashtagCommands.length; i++) {
                if (campaignContext.campaignData.hashtagCommands[i].hashtags.length > 0) {
                    for (let j = 0; j < val_f.feature.commands.length; j++) {
                        if (val_f._id === campaignContext.campaignData.hashtagCommands[i].featureID && val_f.feature.commands[j].isHashtagEnabled && campaignContext.campaignData.hashtagCommands[i].command === val_f.feature.commands[j].command) {
                            hasChatCommands = true;
                            break;
                        }
                    }
                }
                if (hasChatCommands) {
                    break;
                }
            }

            let canHaveHashtags = false;
            let validCommandStrings = [];
            for (let j = 0; j < val_f.feature.commands.length; j++) {
                if (val_f.feature.commands[j].isHashtagEnabled) {
                    canHaveHashtags = true;
                    validCommandStrings.push(val_f.feature.commands[j].command)
                }
            }

            let boxStyle = val_f.feature.featureType;
            if (LEFT_COLOR_BLOCK_STYLES[val_f.feature.featureType + '_' + val_f.feature.varKey]) {
                boxStyle = val_f.feature.featureType + '_' + val_f.feature.varKey;
            }
            if (LEFT_COLOR_BLOCK_STYLES['disruptor_' + val_f.feature.colourCategory]) {
                boxStyle = 'disruptor_' + val_f.feature.colourCategory;
            }
            if (LEFT_COLOR_BLOCK_STYLES['category_' + val_f.feature.colourCategory]) {
                boxStyle = 'category_' + val_f.feature.colourCategory;
            }
            const linkedFeatureTriggers = findLinkedFeatureTriggers(val_f._id, campaignContext.campaignData, props._customisationData.current.current);
            if ((inFeatureChain && val_f.feature.featureType === 'disruptor' && (!props._editMode || linkedFeatureTriggers.length === 0)) || (inFeatureChain && val_f.feature.featureType === 'feature' && linkedFeatureTriggers.length === 0) || (!props._editMode && !showHashtagsList.includes(val_f._id)) || triggered || (!canHaveHashtags && authContext.userData.userLevel < ACCOUNT_TYPES.koko.level && !featureLinked)) {
                // Compact version - no hashtags or any of that stuff
                return (
                    <div className="fl-row grow" key={'dpanel_' + i_f}>
                        <div className="fl-row grow">
                            {((!props._editMode && triggered) || triggeredByTrigger) &&
                                <div className="light-text triggered-feature-indent">
                                    {/*IconJsxer.GetIcon(IconJsxer.ICONS.arrowCircleRight, IconJsxer.ICON_STYLES.analyticsColourBlockIcon)*/}
                                </div>
                            }
                            <div className="fl-column grow compact">
                                <LeftColorBlockBox style={boxStyle}>
                                    <div className='fl-row list-view-content'>

                                        <div>
                                            {val_f.contentLabel}

                                            <span className={`bot-time-dark`}>
                                                &nbsp;({val_f.feature.featureName})
                                            </span>
                                        </div>

                                        <div className="grow"></div>

                                        {!(inFeatureChain && val_f.feature.featureType === 'feature') &&
                                            <>

                                            {authContext.userData.userLevel >= ACCOUNT_TYPES.creative.level && canHaveHashtags && !triggered && !inFeatureChain &&
                                                <div className={`edit-icon${props._gotLiveStream ? ' not-allowed' : ''}`} onClick={props._gotLiveStream ? null : (e) => toggleShowHashtagsList(val_f._id)}>
                                                    {IconJsxer.GetIcon(IconJsxer.ICONS.hashtag, IconJsxer.ICON_STYLES.campaignPanelTop)}
                                                </div>
                                            }

                                            {authContext.userData.userLevel >= ACCOUNT_TYPES.creative.level &&
                                                <div className={`edit-icon${props._gotLiveStream ? ' not-allowed' : ''}`} onClick={props._gotLiveStream ? null : (e) => props._editFeatureSettings(val_f)}>
                                                    {IconJsxer.GetIcon(IconJsxer.ICONS.preview, IconJsxer.ICON_STYLES.campaignPanelTop)}
                                                </div>
                                            }
                                            {authContext.userData.userLevel >= ACCOUNT_TYPES.creative.level &&
                                                <div className={`edit-icon${props._gotLiveStream ? ' not-allowed' : ''}`} onClick={props._gotLiveStream ? null : (e) => customiseFeature(val_f._id)}>
                                                    {IconJsxer.GetIcon(IconJsxer.ICONS.edit, IconJsxer.ICON_STYLES.campaignPanelTop)}
                                                </div>
                                            }

                                                {inFeatureChain && !triggered &&
                                                    <div className={`edit-icon${props._gotLiveStream ? ' not-allowed' : ''}`} onClick={props._gotLiveStream ? null : (e) => props._duplicatedChainedFeature(val_f._id, featureChain)}>
                                                        {IconJsxer.GetIcon(
                                                            IconJsxer.ICONS.copy,
                                                            IconJsxer.ICON_STYLES.campaignPanelTop,
                                                        )}
                                                    </div>
                                                }

                                                {props._editMode && (!featureLinked || (val_f.isChained && !triggered && authContext.userData.userLevel >= ACCOUNT_TYPES.koko.level) || (inFeatureChain && !triggered)) &&
                                                    <>
                                                        <div className="edit-icon" onClick={inFeatureChain ? (e) => props._removeFeatureFromChain(val_f._id, val_f, i_f, featureChain) : (e) => props._removeFeature(val_f)}>
                                                            {IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.campaignPanelTop)}
                                                        </div>
                                                    </>
                                                }
                                            </>
                                        }
                                    </div>
                                </LeftColorBlockBox>

                                { // Only show triggered disruptors in edit mode, keep it simple otherwise!
                                    props._editMode &&
                                    <>
                                        {triggerDepth <= 2 && (!inFeatureChain || props._editMode) &&
                                            <>
                                            {linkedFeatureTriggers.length > 0 &&
                                                <div className="triggered-feature-holder fl-column compact">
                                                    {linkedFeatureTriggers.map(
                                                        (val_tf, i_tf, arr_tf) => getDisruptorPanel(val_tf, i_tf, arr_tf, true, triggered, triggerDepth + 1, inFeatureChain, featureChain)
                                                    )}
                                                </div>
                                            }
                                            </>
                                        }
                                        {(!inFeatureChain || props._editMode) && linkedFeatureTriggers.length > 0 && triggerDepth > 2 &&
                                            <div className="fl-row">
                                                <div className="content-column">
                                                    <div>Maximum displayed link depth reached.</div>
                                                    <div>Note: Excessive linked triggers can cause infinte loops and other undesired effects!</div>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        </div>

                    </div>
                )
            }

            // Expanded version - for adding hastags, only for top level and non-feature chained disruptors / features
            return (
                <div className="fl-row grow" key={'dpanel_' + i_f}>
                        <LeftColorBlockHeadedPanel 
                            style={boxStyle}
                            overrideColor={val_f.isChained && !triggered && !inFeatureChain ? 'admin_default' : null}
                            headingContent={
                                <div className='fl-row list-view-content'>

                                    <div>
                                        {val_f.contentLabel}

                                        <span className={`bot-time-dark`}>
                                            &nbsp;({val_f.feature.featureName})
                                        </span>

                                        {val_f.isChained && !triggered && !inFeatureChain &&
                                            <span className="bold-text alert">
                                                [KOKO ADMIN]
                                            </span>
                                        }
                                    </div>

                                    <div className="grow"></div>

                                    {!(inFeatureChain && val_f.feature.featureType === 'feature') &&
                                        <>

                                            {authContext.userData.userLevel >= ACCOUNT_TYPES.creative.level && canHaveHashtags && !triggered && !props._editMode &&
                                                <div className={`edit-icon${props._gotLiveStream ? ' not-allowed' : ''}`} onClick={props._gotLiveStream ? null : (e) => toggleShowHashtagsList(val_f._id)}>
                                                    {IconJsxer.GetIcon(IconJsxer.ICONS.hashtag, IconJsxer.ICON_STYLES.campaignPanelTop)}
                                                </div>
                                            }

                                            <div className="edit-icon" onClick={(e) => props._editFeatureSettings(val_f)}>
                                                {IconJsxer.GetIcon(IconJsxer.ICONS.preview, IconJsxer.ICON_STYLES.campaignPanelTop)}
                                            </div>

                                            <div className="edit-icon" onClick={(e) => customiseFeature(val_f._id)}>
                                                {IconJsxer.GetIcon(IconJsxer.ICONS.edit, IconJsxer.ICON_STYLES.campaignPanelTop)}
                                            </div>

                                            {inFeatureChain && !triggered &&
                                                <div className="edit-icon" onClick={(e) => props._duplicatedChainedFeature(val_f._id, featureChain)}>
                                                    {IconJsxer.GetIcon(
                                                        IconJsxer.ICONS.copy,
                                                        IconJsxer.ICON_STYLES.campaignPanelTop,
                                                    )}
                                                </div>
                                            }

                                            {(!featureLinked || (val_f.isChained && !triggered && authContext.userData.userLevel >= ACCOUNT_TYPES.koko.level) || (inFeatureChain && !triggered)) && props._editMode &&
                                                <>
                                                    <div className="edit-icon" onClick={inFeatureChain ? (e) => props._removeFeatureFromChain(val_f._id, val_f, i_f, featureChain) : (e) => props._removeFeature(val_f)}>
                                                        {IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.campaignPanelTop)}
                                                    </div>
                                                </>
                                            }
                                        </>
                                    }
                                </div>
                            }
                        >
                            <div className="feature-panel">
                            {!inFeatureChain &&
                            <>
                                {authContext.userData.userLevel >= ACCOUNT_TYPES.koko.level &&
                                    <div className="bold-text alert"> ID: {val_f._id} [KOKO ADMIN]</div>
                                }
                                {val_f.isChained && !triggered && authContext.userData.userLevel >= ACCOUNT_TYPES.koko.level && props._editMode &&
                                    <div className="fl-row bold-text alert">
                                        [KOKO ADMIN]<br />
                                        THIS IS A CHAINED DISRUPTOR! EXERCISE CAUTION WHEN DELETING!<br />
                                        LINKED IN: {findLinkedFeature(val_f._id, campaignContext.campaignData, props._customisationData.current.current)}
                                    </div>
                                }
                                {(featureLinked || triggered) && !(val_f.isChained && !triggered && authContext.userData.userLevel >= ACCOUNT_TYPES.koko.level) && // ((!val_f.isChained && featureLinked && !triggered) || (props._editMode && featureLinked && !triggered)) && !(val_f.isChained && !triggered && authContext.userData.userLevel >= ACCOUNT_TYPES.koko.level) &&
                                    <div className="fl-row">
                                        <div className="content-column">
                                            {!triggered &&
                                                <div>
                                                    Linked in: <span className="bold-text">{findLinkedFeature(val_f._id, campaignContext.campaignData, props._customisationData.current.current, true)}</span>
                                                </div>
                                            }
                                            <div className="bold-text alert">Note: Linked disruptors can not be deleted without being unlinked</div>
                                        </div>
                                    </div>
                                }
                                {canHaveHashtags && !triggered &&
                                    <>
                                    <div className="fl-row">
                                        <h4>Commands</h4>
                                    </div>
                                    {hasChatCommands &&
                                    <div className="inner-panel">
                                        <div className="scroll-container">
                                            <div className="grid">
                                                <div className="header label fl-row">Disruptor Control</div>
                                                <div className="header label fl-row">Param</div>
                                                <div className="header label fl-row">Chat Command(s)</div>
                                                <div className="header label fl-row">Cooldown (secs)</div>
                                                <div className="header label fl-row">
                                                    <div className="grow"></div>
                                                    {// props._editMode &&
                                                        <div className="add-btn" onClick={(e) => props._addHashtag(val_f)}>
                                                            {IconJsxer.GetIcon(IconJsxer.ICONS.plus, IconJsxer.ICON_STYLES.roundPanelButtonSmall)}
                                                        </div>
                                                    }
                                                </div>
                                                <hr></hr>
                                                {hasChatCommands && campaignContext.campaignData.hashtagCommands.map(
                                                    (val_c, i_c, arr_c) => {
                                                        if (val_f._id === val_c.featureID && validCommandStrings.indexOf(val_c.command) >= 0 && val_c.hashtags.length > 0) {
                                                            return (
                                                                <OneListedCommand 
                                                                    key={'olc_' + i_c} 
                                                                    _val={val_c} 
                                                                    _featureData={val_f} 
                                                                    _editMode={props._editMode} 
                                                                    _removeHashtag={props._removeHashtag} 
                                                                    _campaignId={props._campaignId} 
                                                                    _customisationData={props._customisationData}
                                                                />
                                                            )
                                                        } else {
                                                            return null;
                                                        }
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    }
                                    {!hasChatCommands &&
                                    <div className="inner-panel">
                                        <div className="fl-row">
                                            <div>
                                                No chat commands set, click the Add Button to add some.
                                            </div>
                                            {// props._editMode &&
                                                <>
                                                    <div className="grow"></div>
                                                    <div className="add-btn" onClick={(e) => props._addHashtag(val_f)}>
                                                        {IconJsxer.GetIcon(IconJsxer.ICONS.plus, IconJsxer.ICON_STYLES.roundPanelButtonSmall)}
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                    }
                                    </>
                                }
                            </>
                            }

                                {linkedFeatureTriggers.length > 0 && (!inFeatureChain || props._editMode) && props._editMode &&
                                    <>
                                        <div></div>
                                        <div className="fl-row">
                                            <h4>Triggered Disruptors</h4>
                                        </div>
                                        <hr></hr>

                                        <div className="fl-column compact">
                                            {linkedFeatureTriggers.map(
                                                (val_tf, i_tf, arr_tf) => getDisruptorPanel(val_tf, i_tf, arr_tf, true, false, 0, inFeatureChain, featureChain)
                                            )}
                                        </div>
                                    </>
                                }
                            </div>
                        </LeftColorBlockHeadedPanel>

                </div>
            )
        } else {
            return null;
        }
    }

    return (
        <div className="co-tab-content">
            <div className={`co-tab-content-area fl-equal`}>
                <div className="underlined-panel-heading">
                    <h4>Main Feature</h4>
                   
                    <div className="grow"></div>
                    {campaignContext.campaignData.campaignTier != 'basic' && <div className="shrink">
                        <button className="standard-button stacking-button tight" onClick={props._editStacking}>Edit Stacking</button>
                    </div>}
                </div>
                <div className="co-fullcol-scroll-area">
                    {gotFeatures && (props._editMode ? props._editedCampaignData : campaignContext.campaignData).features.map(
                        (val, i, arr) => {
                            if (val.feature.featureType === 'feature') {
                                return (
                                    <FeaturePanel 
                                        key={'feature_' + i} 
                                        feature={val} 
                                        userLevel={authContext.userData.userLevel}
                                        _editMode={props._editMode} 
                                        _gotLiveStream={props._gotLiveStream}
                                        _addHashtag={props._addHashtag} 
                                        _removeHashtag={props._removeHashtag} 
                                        _campaignId={props._campaignId} 
                                        _editFeatureSettings={props._editFeatureSettings} 
                                        _removeFeature={props._removeFeature} 
                                        _showTooltipFunc={props._showTooltipFunc}
                                        _hideTooltipFunc={props._hideTooltipFunc}
                                        _featureChain={props._findFeatureChainFunc(val._id)}
                                        _setIntroAsOutro={props._setIntroAsOutro}
                                        _changeChainOrder={props._changeChainOrder}
                                        _removeFeatureFromChain={props._removeFeatureFromChain}
                                        _duplicatedChainedFeature={props._duplicatedChainedFeature}
                                        _saveFeatureUpdatesFunc={props._saveFeatureUpdatesFunc}
                                        _addDisruptor={props._addDisruptor}
                                        _customiseFeature={customiseFeature}
                                        _getDisruptorPanel={getDisruptorPanel}
                                        _openArcadeDataPopup={props._openArcadeDataPopup}
                                    />
                                )
                            } else {
                                return null;
                            }
                        }
                    )}
                    {!gotFeatures && campaignContext.campaignData.campaignTier != 'basic' &&
                        <div className="fl-row">
                            <div className="grow"></div>
                            <div className="grow">
                                {/*props._editMode &&*/ <button className={`standard-button${props._gotLiveStream ? ' not-allowed' : ''}`} onClick={props._gotLiveStream ? null : (e) => {e.preventDefault(); props._addFeature()}}>Add Feature</button>}
                                {/*!props._editMode && <h4>You don't have any features. Use Edit Mode to add one.</h4>*/}
                            </div>
                            <div className="grow"></div>
                        </div>
                    }
                    {!gotFeatures && campaignContext.campaignData.campaignTier == 'basic' && <div className="shrink"><p>Features cannot be added to a T3 Campaign</p></div>}
                </div>
            </div>

            <div className="co-tab-column fl-equal">

                <div className={`co-tab-content-area equal`}>
                    <div className="underlined-panel-heading">
                        <h4>Stream Disruptors</h4>
                        <div className="grow"></div>
                        {authContext.userData.userLevel >= ACCOUNT_TYPES.creative.level &&
                            <div className={`round-button small-add-button${props._gotLiveStream ? ' not-allowed' : ''}`} onClick={props._gotLiveStream ? null : props._addDisruptor}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.plus, IconJsxer.ICON_STYLES.roundPanelButtonSmall)}
                            </div>
                        }
                    </div>
                    <div className="co-halfcol-scroll-area">
                        <div className="fl-column compact">
                            {(props._editMode ? props._editedCampaignData : campaignContext.campaignData).features.map(
                                (val_f, i_f, arr_f) => {
                                    if (val_f.feature.featureType === 'disruptor') {
                                        if (val_f.feature.featureType === 'disruptor' && (!val_f.isChained || (authContext.userData.userLevel >= ACCOUNT_TYPES.koko.level && props._editMode)) ) {
                                            // const linkedFeatureTriggers = findLinkedFeatureTriggers(val_f._id, campaignContext.campaignData, props._customisationData.current.current);
                                            return (
                                                <div key={'d_' + i_f}>
                                                        {getDisruptorPanel(val_f, i_f, arr_f)}
                                                </div>
                                            )
                                        } else {
                                            return null;
                                        }
                                    } else {
                                        return null;
                                    }
                                }
                            )}
                        </div>
                    </div>
                </div>

                <div className={`co-tab-content-area equal`}>
                    <div className="underlined-panel-heading">
                        <h4>Stream Bots</h4>
                        <div className="grow"></div>
                        {authContext.userData.userLevel >= ACCOUNT_TYPES.creative.level &&
                            <div className={`round-button small-add-button${props._gotLiveStream ? ' not-allowed' : ''}`} onClick={props._gotLiveStream ? null : props._addStreamBot}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.plus, IconJsxer.ICON_STYLES.roundPanelButtonSmall)}
                            </div>
                        }
                    </div>

                    <div className="co-halfcol-scroll-area">

                        <BotsOverview bots={campaignContext.campaignData.chatbots} editMode={props._editMode} editFunc={props._editBot} removeFunc={props._removeBot} duplicateFunc={props._duplicateBot} _gotLiveStream={props._gotLiveStream} />

                    </div>
                </div>

            </div>

        </div>
    )
}

export default CampaignContentTab;
