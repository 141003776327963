import React, { useState, useEffect, useContext } from "react";
import validator from "validator";

import { toast } from "react-toastify";
import axios from "../../api/axios";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../../contexts/AuthContext";

import { changeDisplayNameRoute } from "../../api/routes";
import FormField from "../../components/forms/FormField/FormField";
import "./EditDisplayName.scss";

import CheckBox from "../../components/forms/CheckBox/CheckBox";
import ClosePanelBtn from "../../components/ClosePanelBtn/ClosePanelBtn";
import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import { TOP_LEVEL_NAV_ROUTES } from "../../config/NavRoutes";

const EditDisplayName = (props) => {

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [authContext, setAuthContext] = useContext(AuthContext);
    const [newFirstName, setNewFirstName] = useState("");
    const [newLastName, setNewLastName] = useState("");
    const [validName, setValidName] = useState(false);
    const navigate = useNavigate();
    const changeDisplayName = (e) => {
        e.preventDefault()
        axios
            .post(changeDisplayNameRoute, {
                firstName: newFirstName,
                lastName: newLastName
            })
            .then(function (response) {
                setIsSubmitting(false);
                const data = response.data
                setAuthContext((oldValues) => {
                    return { ...oldValues, userData: data.globalUserData }
                })
                toast.success("Display name successfully changed!");
                document.getElementById("changeDisplayName").reset();
                if(props.newUser) navigate(TOP_LEVEL_NAV_ROUTES.CAMPAIGNS)
            })
            .catch(function (error) {
                setIsSubmitting(false);
                toast.error('Error - ' + error.response.data.message);
                // console.log(error.response)
            });
    };

    useEffect(() => {
        if (newFirstName.length > 0 && newLastName.length > 0) {
            setValidName(true);
        } else {
            setValidName(false);
        }
    }, [newFirstName, newLastName]);

    return (
        <PopUpPanel closePanelFunc={props.closePanelFunc}>
            <div className="form-holder">
                <h2>Change Display Name</h2>
                <form id="changeDisplayName" className="form-holder">
                    <FormField
                        type="text"
                        id="firstName"
                        placeholder={authContext.userData.firstName || "Enter First Name"}
                        setFunc={setNewFirstName}
                        label="First Name"
                        error={''}
                    />
                      <FormField
                        type="text"
                        id="lastName"
                        placeholder={authContext.userData.lastName || "Enter Last Name"}
                        setFunc={setNewLastName}
                        label="Last Name"
                        error={''}
                    />
                    <button className={'submit-btn' + (isSubmitting || !validName ? ' button-inactive' : ' button-active')} onClick={isSubmitting || !validName ? (e) => e.preventDefault() : changeDisplayName}>
                        {isSubmitting ? "Updating" : "Update"}
                    </button>
                </form>
            </div>
        </PopUpPanel>
    )
}

export default EditDisplayName;
