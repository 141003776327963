import { IconJsxer } from "./IconHelper"

export const STREAM_TRANSCRIPT_SETTINGS = {
    default: {
        label: 'Unknown',
        dbString: 'unknown',
        colorCss: 'default-tr-color',
    },
    audioDisruptor: {
        label: 'Audio',
        dbString: 'disruptorAudio',
        colorCss: 'disrupter-audio-tr-color',
    },
    videoDisruptor: {
        label: 'Video',
        dbString: 'disruptorVideo',
        colorCss: 'disrupter-video-tr-color',
    },
    animatedOverlay: {
        label: 'Overlay',
        dbString: 'animatedOverlay',
        colorCss: 'disrupter-animated-overlay-tr-color',
    },
    imageOverlay: {
        label: 'Image',
        dbString: 'imageOverlay',
        colorCss: 'disrupter-image-overlay-tr-color',
    },
    disruptor: {
        label: 'Disruptor',
        dbDtring: 'disruptor',
        colorCss: 'disrupter-tr-color',
    },
    feature: {
        label: 'Feature',
        dbString: 'feature',
        colorCss: 'feature-tr-color',
    },
    bot: {
        label: 'Bot (Auto)',
        dbString: 'auto',
        colorCss: 'bot-tr-color',
    },
    botM: {
        label: 'Bot (Man)',
        dbString: 'manual',
        colorCss: 'bot-tr-color',
    },
    botI: {
        label: 'Bot (Int)',
        dbString: 'interval',
        colorCss: 'bot-tr-color',
    },
    botR: {
        label: 'Bot (Res)',
        dbString: 'response',
        colorCss: 'bot-tr-color',
    },
    overlay: {
        label: 'Overlay',
        dbString: 'overlay',
        colorCss: 'bot-tr-color',
    },
    analytics: {
        label: 'Analytics',
        dbString: 'analytics',
        colorCss: 'bot-tr-color',
    },
    peakViewers:{
        label: 'Viewers',
        dbString: 'peakViewers',
        colorCss: 'bot-tr-color',
        
    }
}


export const STREAM_STATUS_TYPES = {
    scheduled: {
        displayName: 'Scheduled',
        dbString: 'scheduled',
        headedPanelColorStr: 'scheduled',
        icon: IconJsxer.ICONS.clock,
        mapToForCount: 'scheduled',
    },
    live: {
        displayName: 'LIVE',
        dbString: 'live',
        headedPanelColorStr: 'live',
        icon: IconJsxer.ICONS.liveStream,
        mapToForCount: 'live',
    },
    ended: {
        displayName: 'Ended',
        dbString: 'ended',
        headedPanelColorStr: 'error',
        icon: IconJsxer.ICONS.powerOff,
        mapToForCount: 'ended',
    },
    notset: {
        displayName: 'Not Setup',
        dbString: 'notset',
        headedPanelColorStr: 'ended',
        icon: IconJsxer.ICONS.error,
        mapToForCount: 'ended',
    },
    archived: {
        displayName: 'Archived',
        dbString: 'archived',
        headedPanelColorStr: 'ended',
        icon: IconJsxer.ICONS.archived,
        mapToForCount: 'ended',
    }
}

const STREAM_STATUS_PRIORITY = [
    STREAM_STATUS_TYPES.live,
    STREAM_STATUS_TYPES.demo,
    STREAM_STATUS_TYPES.scheduled,
    STREAM_STATUS_TYPES.ended,
    STREAM_STATUS_TYPES.notset,
    STREAM_STATUS_TYPES.archived,
]

export const getStreamTypeDataFromDbString = (dbString) => {
    for (let p in (STREAM_STATUS_TYPES) ){
        if (STREAM_STATUS_TYPES[p].dbString === dbString) {
            return STREAM_STATUS_TYPES[p];
        }
    }
    return STREAM_STATUS_TYPES.scheduled;
}

export const getStatusForCampaign = (streamsArray, archived) => {
    if (archived) {
        return STREAM_STATUS_TYPES.archived;
    }

    let statusData = STREAM_STATUS_TYPES.notset;
    for (let i = 0; i < streamsArray.length; i++) {
        const streamStatusData = getStreamTypeDataFromDbString(streamsArray[i].status);
        if (STREAM_STATUS_PRIORITY.indexOf(streamStatusData) < STREAM_STATUS_PRIORITY.indexOf(statusData)) {
            statusData = streamStatusData;
        }
    }
    return statusData;
}

export const countStreamStatusTypesForCampaign = (streamsArray) => {
    const statusCounts = {
        live: 0,
        scheduled: 0,
        ended: 0,
    }
    for (let i = 0; i < streamsArray.length; i++) {
        const streamStatusData = getStreamTypeDataFromDbString(streamsArray[i].status);
        if (statusCounts.hasOwnProperty(streamStatusData.mapToForCount)) {
            statusCounts[streamStatusData.mapToForCount]++;
        }
    }
    return statusCounts;
}

const TWITCH_STREAM_URL = 'https://www.twitch.tv/[streamer_handle]';

export const getTwitchStreamUrl = (streamerHandle) => TWITCH_STREAM_URL.replace('[streamer_handle]', streamerHandle);
