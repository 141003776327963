import "./Themes.scss";

import React, { useState, useEffect, useContext, useRef } from "react";

import PopUpPanel from "../../../components/PopUpPanel/PopUpPanel";
import { IconJsxer } from "../../../helpers/IconHelper";
import { toast } from "react-toastify";

const DeletingThemePopup = (props) => {

    return (
        <PopUpPanel showCloseBtn={false} closePanelFunc={null} className="themes-narrow-panel">
            <div className="fl-column">
                <div className="fl-row grow"></div>
                <div className="fl-row shrink">
                    <div className="grow"></div>
                    <h2>Deleting Theme...</h2>
                    <div className="grow"></div>
                </div>
                <div className="fl-row grow"></div>
            </div>
        </PopUpPanel>
    )
}

export default DeletingThemePopup;
