import React, { useContext, useEffect, useState } from "react";
import { CampaignContext } from "../../../contexts/CampaignContext";
import {getCampaignAnalyticDataRoute} from "../../../api/routes";
import { toast } from "react-toastify";
import axios from "../../../api/axios";
import { convertMSToHMS } from "../../../helpers/Dates";
import { getLongDateString } from "../../../helpers/Dates";
import CampaignAnalytics from '../../../components/Analytics/CampaignAnalytics';
import StreamAnalyticsSummary from '../../../components/Analytics/StreamAnalyticsSummary';

const CampaignAnalyticsTab = (props) => {
    const [campaignContext, setCampaignContext] = useContext(CampaignContext);
    const [campaignSummaryAnalytics, setCampaignSummaryAnalytics] = useState([]);
    const [analyticsData, setAnalyticsData] = useState([]);

    const getCampaignAnalyticData = () => {
        console.log( campaignContext)
        axios
        .get(getCampaignAnalyticDataRoute, {
            params: { campaignID: campaignContext.campaignData._id },
            withCredentials:true
        })
        .then(function (response) {
            console.log(response.data.analyticsData.streams)
            setAnalyticsData(response.data.analyticsData);
            setCampaignSummaryAnalytics(response.data.analyticsData.streams);
        })
        .catch(function (error) {
            toast.error('Error - ' + error);
        });
    }

    useEffect(
        () => {
            getCampaignAnalyticData();
        }, []
    )

    return (
        <div className="co-tab-content">
            <div className={`co-tab-content-area fl-equal`}>
                <div className="underlined-panel-heading">
                    <h4>Analytics Overview</h4>
                    <div className="grow"></div>
                </div>
                <div className="co-fullcol-scroll-area">
                    <div className="fl-column">
                        <CampaignAnalytics
                            _campaignAnalyticsSummary={campaignSummaryAnalytics}
                            _campaignName={campaignContext.campaignData.campaignName}
                            _client={campaignContext.campaignData.client}v
                            _numberOfStreams={campaignContext.campaignData.streams.length}
                        />
                        {campaignContext.campaignData.streams.map(
                            (val, i) => {
                                return (
                                    <StreamAnalyticsSummary
                                        _setCampaignDataFunc={setCampaignSummaryAnalytics}
                                        _analyticsData={analyticsData}
                                        _campaignAnalyticsSummary={campaignSummaryAnalytics}
                                        _streamID={val._id}
                                        _twitchHandle={val.twitchHandle}
                                        _startTime={val.startTime}
                                        _campaignID={campaignContext.campaignData._id}
                                        _endTime={val.endTime}
                                        _timeZone = {val.timeZone}
                                        _openSessionAnalytics={props._openSessionAnalytics}
                                        key={'str_' + i}
                                    />
                                )
                            }
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CampaignAnalyticsTab;
