import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { TOP_LEVEL_NAV_ROUTES } from "../../config/NavRoutes";
import "./SettingsPop.scss";
import axios from "../../api/axios";
import { logoutRoute  } from "../../api/routes";
import { toast } from "react-toastify";
import { AuthContext } from "../../contexts/AuthContext";
import DebugLogger from "../../helpers/DebugLogger";
import { ProfilePicURL } from "../../helpers/ProfilePicURL";
import { getAcTypeDisplayName } from "../../helpers/AccountTypes";
import ProfilePic, { PROFILE_PIC_SIZES } from "../ProfilePic/ProfilePic";
import { LogoutHandler } from "../../helpers/AuthFunctions";

import SendBugReport from "../../containers/BugReport/SendBugReport";
import ClosePanelBtn from "../ClosePanelBtn/ClosePanelBtn";
import { IconJsxer } from "../../helpers/IconHelper";

export const BUG_REPORT = {
    sendNewBugReport: null,
};

const SettingsPop = (props) => {

    const [authContext, setAuthContext] = useContext(AuthContext);

    const [ showBugReportPopup, setShowBugReportPopup ] = useState(false);
    BUG_REPORT.sendNewBugReport = () => {setShowBugReportPopup(true)};

    const toggleBugReportPopup = (hasSaved) => {
        setShowBugReportPopup(!showBugReportPopup);
        console.log("window.location.pathname = ",window.location.pathname);
        console.log("showBugReportPopup = ",showBugReportPopup);
        console.log("hasSaved = ",hasSaved);
        if(hasSaved &&  window.location.pathname == "/bug-reports") {
            window.location.reload();
        }
    }

    return (
        <>
            {props.shown && <div className="settings-pop-canceller" onClick={props.hideFunc}></div>}
            <div className={'settings-pop-container' + (props.shown ? '' : ' settings-pop-hidden')} onClick={props.hideFunc}>
                <div className="fl-column">
                    <NavLink to={TOP_LEVEL_NAV_ROUTES.SETTINGS}>
                        <div className="profile">
                            <ProfilePic size={PROFILE_PIC_SIZES.medium} className="pp-style" />
                            <div className="text"><p>{authContext.userData.firstName} {authContext.userData.lastName}<br />{getAcTypeDisplayName(authContext.userData.accountType)}</p></div>
                        </div>
                    </NavLink>
                    <button className="small-button-with-icon" onClick={() => { setShowBugReportPopup(true) }}><>{IconJsxer.GetIcon(IconJsxer.ICONS.bug, IconJsxer.ICON_STYLES.roundPanelButtonSmallWithIcon)} Report a bug</></button>
                    <button onClick={() => {LogoutHandler(setAuthContext); props.hideFunc()}}>Log Out</button>
                </div>
            </div>
            { showBugReportPopup &&
                <SendBugReport closePanelFunc={toggleBugReportPopup} />
            }
        </>
    )
}

export default SettingsPop;
