import React, { useContext, useState } from "react";
import { NavLink, useLocation } from 'react-router-dom';
import DebugLogger from '../../helpers/DebugLogger';
import  { NAV_AREAS, TOP_LEVEL_NAV_ROUTES } from "../../config/NavRoutes";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/fontawesome-free-solid";
import { faChevronLeft } from "@fortawesome/fontawesome-free-solid";
import { faTh } from "@fortawesome/fontawesome-free-solid";
import { faUserFriends } from "@fortawesome/fontawesome-free-solid";
import { faChartLine } from "@fortawesome/fontawesome-free-solid";
import { faCalendar } from "@fortawesome/fontawesome-free-solid";
// import { faCog } from "@fontawesome/fontawesome-free-solid";

import "./Navbar.scss";
import { AuthContext } from "../../contexts/AuthContext";
import { ACCOUNT_TYPES } from "../../helpers/AccountTypes";
import { IconJsxer } from "../../helpers/IconHelper";

const NavBar = () => {
    const location = useLocation();
    // DebugLogger.log('Nav path: ' + location.pathname);
    const [authContext, setAuthContext] = useContext(AuthContext);

    const [expanded, setExpanded] = useState(false);
    const toggleExpanded = () => setExpanded(!expanded);

    return (
        <nav className={'nav-bar-container ' + (expanded ? 'nav-expanded' : 'nav-collapsed')}>
            <div className="nav-bar-section nav-expander-container">
                <div onClick={toggleExpanded} className="cursor-pointer">{IconJsxer.GetIcon(expanded ? IconJsxer.ICONS.chevronLeft : IconJsxer.ICONS.chevronRight, IconJsxer.ICON_STYLES.nav)}<span className="nav-bar-text">Collapse</span></div>
            </div>
            <div className="nav-bar-top-section-spacer"></div>
            <div className={'nav-bar-section nav-' + (location.pathname.split('/')[1] === NAV_AREAS.CAMPAIGNS ? '' : 'in') + 'active'}>
                <NavLink to={TOP_LEVEL_NAV_ROUTES.CAMPAIGNS}>
                    <div>{IconJsxer.GetIcon(IconJsxer.ICONS.campaignsNav, IconJsxer.ICON_STYLES.nav)}<span className="nav-bar-text">Campaigns</span></div>
                </NavLink>
            </div>
            {authContext.userData.userLevel >= ACCOUNT_TYPES.creative.level &&
                <div className={'nav-bar-section nav-' + (location.pathname.split('/')[1] === NAV_AREAS.QUIZZES ? '' : 'in') + 'active'}>
                    <NavLink to={TOP_LEVEL_NAV_ROUTES.QUIZZES}>
                        <div>{IconJsxer.GetIcon(IconJsxer.ICONS.quizNav, IconJsxer.ICON_STYLES.nav_quiz)}<span className="nav-bar-text">Quizzes</span></div>
                    </NavLink>
                </div>
            }
            {authContext.userData.userLevel >= ACCOUNT_TYPES.producer.level &&
                <div className={'nav-bar-section nav-' + (location.pathname.split('/')[1] === NAV_AREAS.USERS ? '' : 'in') + 'active'}>
                    <NavLink to={TOP_LEVEL_NAV_ROUTES.USERS}>
                        <div>{IconJsxer.GetIcon(IconJsxer.ICONS.usersNav, IconJsxer.ICON_STYLES.nav)}<span className="nav-bar-text">Users</span></div>
                    </NavLink>
                </div>
            }
            {authContext.userData.userLevel >= ACCOUNT_TYPES.producer.level &&
                <div className={'nav-bar-section nav-' + (location.pathname.split('/')[1] === NAV_AREAS.ANALYTICS ? '' : 'in') + 'active'}>
                    <NavLink to={TOP_LEVEL_NAV_ROUTES.ANALYTICS}>
                        <div>{IconJsxer.GetIcon(IconJsxer.ICONS.analyticsNav, IconJsxer.ICON_STYLES.nav)}<span className="nav-bar-text">Analytics</span></div>
                    </NavLink>
                </div>
            }   
            {authContext.userData.userLevel >= ACCOUNT_TYPES.producer.level &&    
                <div className={'nav-bar-section nav-' + (location.pathname.split('/')[1] === NAV_AREAS.SCHEDULE ? '' : 'in') + 'active'}>
                    <NavLink to={TOP_LEVEL_NAV_ROUTES.SCHEDULE}>
                        <div>{IconJsxer.GetIcon(IconJsxer.ICONS.scheduleNav, IconJsxer.ICON_STYLES.nav)}<span className="nav-bar-text">Schedule</span></div>
                    </NavLink>
                </div>
            }
            { authContext.userData.userLevel >= ACCOUNT_TYPES.twitchSuperUser.level &&
                <div className={'nav-bar-section nav-' + (location.pathname.split('/')[1] === NAV_AREAS.BUG_REPORTS ? '' : 'in') + 'active'}>
                    <NavLink to={TOP_LEVEL_NAV_ROUTES.BUG_REPORTS}>
                        <div><FontAwesomeIcon className="nav-bar-icon" icon={IconJsxer.ICONS.bug} /><span className="nav-bar-text">Bug Reports</span></div>
                    </NavLink>
                </div>
            }
            { authContext.userData.userLevel >= ACCOUNT_TYPES.koko.level &&
                <div className={'nav-bar-section nav-' + (location.pathname.split('/')[1].indexOf(NAV_AREAS.DEBUG) >= 0 ? '' : 'in') + 'active'}>
                    <NavLink to={TOP_LEVEL_NAV_ROUTES.KOKO_DEBUG_TOOLS}>
                        <div><FontAwesomeIcon className="nav-bar-icon" icon={IconJsxer.ICONS.kokoAcIcon} /><span className="nav-bar-text">Koko Debug</span></div>
                    </NavLink>
                </div>
            }
            <div className="nav-bar-bottom-section-spacer"></div>
        </nav>
    );
}

export default NavBar;
