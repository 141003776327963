import { assets } from "../../assets/assets";
import { useContext, useEffect, useRef, useState } from "react";
import { useParams, useNavigate, useLocation, useRoutes } from "react-router-dom";
import axios from "../../api/axios";
import DebugLogger from "../../helpers/DebugLogger";
import { getQuizRoute, createQuizRoute, quizUpdateRoute, getCampaignByIdRoute, getExtraQuizDataRoute, getAllCampaignsRoute } from "../../api/routes";
import { toast } from "react-toastify";
import { AuthContext } from "../../contexts/AuthContext";
import { getShortDateString, getShortDateTimeString } from "../../helpers/Dates";
import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import FormField from "../../components/forms/FormField/FormField";
import DropDownList from "../../components/forms/DropDownList/DropDownList";
import FormDropDown from "../../components/forms/DropDownList/FormDropDown";
import Toggle from "../../components/forms/Toggle/Toggle";
import HMSTimeField from "../../components/forms/HMSTimeField/HMSTimeField";
import RadioButtonGroup from "../../components/forms/RadioButtonGroup/RadioButtonGroup";
import RoundOverview from "../Campaigns/RoundOverview";
import ConfirmRemoveQuizRound from "../Campaigns/ConfirmRemoveQuizRound";
import ConfirmRemoveQuizQuestion from "../Campaigns/ConfirmRemoveQuizQuestion";
import EditQuizRound from "./EditQuizRound";
import EditQuizQuestion from "./EditQuizQuestion";
import { TOP_LEVEL_NAV_ROUTES } from "../../config/NavRoutes";
import Papa from "papaparse";

import "./EditQuiz.scss";
import CsvPreview from "./CsvPreview";

export const DEFAULT_HASHTAGS = ['#A', '#B', '#C', '#D', '#E', '#F', ];

const EditQuiz = () => {
    const navigate = useNavigate();
    const state = useLocation().state;
    // let campaignId = (state && state.campaignId) ? state.campaignId : null;
    let { campaignId, featureId, streamId, id } = useParams();
    if (!campaignId) {
        campaignId = (state && state.campaignId) ? state.campaignId : null;
    }

    // const { id } = useParams();

    //const { quiz, questionTypes, statuses, currentStatusIndex, currentStatusColourClass, closePanelFunc } = props;
    const [quizId, setQuizId] = useState(id);
    const [quiz, setQuiz] = useState(null);
    //const [status, setStatus] = useState(quiz.status);
    const [statusIndex, setStatusIndex] = useState(0);
    // const [campaignIndex, setCampaignIndex] = useState(0);
    const [streamIndex, setStreamIndex] = useState(0);
    const [statusColourClass, setStatusColourClass] = useState(null);

    const [campaign, setCampaign] = useState(null);
    const [campaigns, setCampaigns] = useState(null);
    const [campaignIndex, setCampaignIndex] = useState(0);
    const [quizStatuses, setQuizStatuses] = useState([]);
    const [quizTypes, setQuizTypes] = useState([]);
    const [questionTypes, setQuestionTypes] = useState([]);
    const [questionTypeIndexes, setQuestionTypeIndexes] = useState({});
    const [quizTypeIndex, setQuizTypeIndex] = useState(0);
    const [gradingSystems, setGradingSystems] = useState([]);
    const [gradingSystemIndex, setGradingSystemIndex] = useState(0);

    const [showEditQuestionPopup, setShowEditQuestionPopup] = useState(false);
    const [showEditRoundPopup, setShowEditRoundPopup] = useState(false);

    const getNavigationRoute = () => {
        if (streamId) {
            return TOP_LEVEL_NAV_ROUTES.CUSTOMISE_FEATURE_STREAM.replace(":campaignId", campaignId).replace(":featureId", featureId).replace(":streamId", streamId);
        } else
            if (featureId) {
                return TOP_LEVEL_NAV_ROUTES.CUSTOMISE_FEATURE.replace(":campaignId", campaignId).replace(":featureId", featureId);
            } else
                if (campaignId) {
                    return TOP_LEVEL_NAV_ROUTES.CAMPAIGN_OVERVIEW_TAB.replace(":id", campaignId).replace(":tab", 1);
                } else {
                    return TOP_LEVEL_NAV_ROUTES.QUIZZES;
                }
    }

    const getData = () => {
        if (!campaign && !campaigns) {
            getCampaign();
        }
        if (!quizStatuses || quizStatuses.length == 0 || !questionTypes || questionTypes.length == 0) {
            getExtraQuizData();
        }
        if (quizId && (campaign || campaigns) && quizStatuses && quizStatuses.length > 0 && questionTypes && questionTypes.length > 0) {
            getQuiz();
        } else if (!quizId && !quiz) {
            //ensure quiz exists
            setQuiz({
                name: "",
                type: "Quiz",
                gradingSystem: "teamMode",
            });
        }
    };

    const getQuiz = () => {
        axios
            .get(`${getQuizRoute}?quizID=${quizId}`, { withCredentials: true })
            .then((res) => {
                setQuiz(res.data.quiz);

                //set the index of the quizzes type
                if (res.data.quiz.type) {
                    for (var quizTypeKey in quizTypes) {
                        let quizType = quizTypes[quizTypeKey];
                        if (quizType.label == res.data.quiz.type) {
                            setQuizTypeIndex(quizType.index - 1);
                        }
                    }
                }

                console.log("quiz, campaigns", res.data.quiz, campaigns);
                //set campaignIndex
                if (res.data.quiz.campaign !== null) {
                    let campaignIndexSet = false;
                    for (var campaignKey in campaigns) {
                        // console.log("campaignKey", campaignKey);
                        let campaignIndex = parseInt(campaignKey);

                        if ((res.data.quiz.campaign._id) ? res.data.quiz.campaign._id == campaigns[campaignIndex].id : res.data.quiz.campaign == campaigns[campaignIndex.id]) {
                            setCampaignIndex(campaigns[campaignIndex].index);
                            campaignIndexSet = true;
                            break;
                        }
                    }
                    if (!campaignIndexSet) {
                        setCampaignIndex(-1);
                        // console.log("Quiz campaign doesn't exist!");
                    }
                } else {
                    setCampaignIndex(-1);
                    // console.log('Is generic quiz!');
                }
            })
            .catch(err => {
                DebugLogger.log("getQuiz error ", err);
                toast.error("Failed to get quiz for id ".id);
            });
    };
    const getExtraQuizData = () => {
        axios
            .get(getExtraQuizDataRoute, { withCredentials: true })
            .then((res) => {
                console.log('getExtraQuizData', res.data.extraQuizData);
                setQuizStatuses(res.data.extraQuizData.statuses);
                setQuizTypes(res.data.extraQuizData.quizTypes);
                setQuestionTypes(res.data.extraQuizData.questionTypes);
                setGradingSystems(res.data.extraQuizData.gradingSystems);
            })
            .catch(err => {
                DebugLogger.log("getQuestionTypesAndStatuses error ", err);
                toast.error("Failed to get question Types and statuses")
            })
    };

    const getCampaign = () => {
        if (campaignId) {
            axios
                .get(getCampaignByIdRoute + `?campaignId=${campaignId}`, { withCredentials: true })
                .then((res) => {
                    setCampaign(res.data.campaign);
                })
                .catch(err => {
                    DebugLogger.log("getCampaignByIdRoute error ", err);
                    toast.error("Failed to get campaign.")
                });
        } else {
            axios
                .get(getAllCampaignsRoute, { params: { page: 1, searchTerm: '' }, withCredentials: true })
                .then((res) => {
                    let allCampaigns = [{ _id: "", campaignName: "Select" }].concat(res.data.campaigns);

                    let campaignsCount = 0;
                    setCampaigns(allCampaigns.map(it => { return { id: it._id, label: it.campaignName, index: campaignsCount++ } }));
                })
                .catch(err => {
                    DebugLogger.log("get all campaigns error ", err);
                    toast.error("Failed to get campaigns");
                })
        }
    };


    const updateStatusIndex = () => {
        if (quiz && quiz.status && quizStatuses) {
            for (var i = 0; i < quizStatuses.length; ++i) {
                let quizStatus = quizStatuses[i];
                if (quizStatus.label == quiz.status) {
                    setStatusIndex(quizStatus.index || 0);
                }
            }
        }
    }

    const updateQuizTypeIndexes = () => {

    }
    const updateQuestionTypeIndexes = () => {

        if (quiz && quiz.rounds && quiz.rounds.length > 0 && questionTypes && questionTypes.length > 0) {
            let updatedQuestionTypeIndexes = questionTypeIndexes;
            for (var roundIndex = 0; roundIndex < quiz.rounds.length; ++roundIndex) {
                let round = quiz.rounds[roundIndex];

                for (var questionIndex = 0; questionIndex < round.questions.length; ++questionIndex) {
                    let question = round.questions[questionIndex];

                    for (var questionTypeKey in questionTypes) {
                        let questionType = questionTypes[questionTypeKey];
                        if (question.type == questionType.label) {
                            updatedQuestionTypeIndexes[roundIndex + "_" + questionIndex] = questionType.index;
                        }
                    }
                }
            }

            setQuestionTypeIndexes(updatedQuestionTypeIndexes);
        }
    }

    const updateColourClasses = (data) => {
        if (quiz) {
            let status = (data && data.status) ? data.status : quiz.status;

            switch (status) {
                case "Live":
                    setStatusColourClass("live-status-colour");
                    break;
                case "Draft":
                    setStatusColourClass("draft-status-colour");
                    break;
                case "Disabled":
                    setStatusColourClass("disabled-status-colour");
                    break;
            }
        }
    }

    const [roundsValid, setRoundsValid] = useState([]);
    const [roundQuestionsValid, setRoundQuestionsValid] = useState([]);
    const validateRoundsAndQuestions = () => {
        let roundsValid = [];
        let roundQuestionsValid = [];
        let hasErrors = false;
        if (quiz) {
            if (!quiz.rounds || quiz.rounds.length == 0) {
                hasErrors = true;
            } else
            for (let i = 0; i < quiz.rounds.length; ++i) {
                let round = quiz.rounds[i];
                let roundValid = true;
                let questionsValid = [];
                if (round.questions) {
                    for (let j = 0; j < round.questions.length; ++j) {
                        let question = round.questions[j];
                        let questionValid = true;
                        /* if ((!question.questionText || question.questionText.length == 0) && (!question.questionImage || question.questionImage.length == 0)) {
                            questionValid = false;
                            hasErrors = true;
                        } */
                        if (question.type === 'Image Question with Text Answers') {
                            if (!question.questionImage || question.questionImage.length == 0) {
                                questionValid = false;
                                hasErrors = true;
                            }
                        } else {
                            if (!question.questionText || question.questionText.length == 0) {
                                questionValid = false;
                                hasErrors = true;
                            }
                        }
                        let correctAnswerCount = 0;
                        for (let k = 0; k < question.answers.length; ++k) {
                            let answer = question.answers[k];
                            /* if ((!answer.answerText || answer.answerText.length == 0) && (!answer.answerImage || answer.answerImage.length == 0)) {
                                questionValid = false;
                                hasErrors = true;
                            } */
                            if (quiz.type !== "Tug of War" || !answer.usePreviousWinner) {
                                if (question.type === 'Text Question with Image Answers') {
                                    if (!answer.answerImage || answer.answerImage.length == 0) {
                                        questionValid = false;
                                        hasErrors = true;
                                    }
                                } else {
                                    if (!answer.answerText || answer.answerText.length == 0) {
                                        questionValid = false;
                                        hasErrors = true;
                                    }
                                }
                                if (answer.correctAnswer) {
                                    correctAnswerCount++;
                                }
                            }
                        }

                        // Polls don't need correct answers, so we'll just fake this bit of validation
                        if (quiz.type == "Poll" || quiz.type == "Tug of War") {
                            correctAnswerCount = 1;
                        }

                        if (correctAnswerCount != 1 || question.answers.length < 2) {
                            questionValid = false;
                            hasErrors = true;
                        }
                        questionsValid.push(questionValid);
                        if (questionValid == false) {
                            roundValid = false;
                            hasErrors = true;
                        }
                    }
                }
                if (!round.questions || round.questions.length === 0) {
                    roundValid = false;
                    hasErrors = true;
                }
                roundsValid.push(roundValid);
                roundQuestionsValid.push(questionsValid);
            }
        }

        setRoundsValid(roundsValid);
        setRoundQuestionsValid(roundQuestionsValid);
        console.log('roundsValid', roundsValid, roundQuestionsValid);
        return !hasErrors;
    }

    const [hasErrors, setHasErrors] = useState(false);
    const [saveError, setSaveError] = useState(false);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [quizNameError, setQuizNameError] = useState('');
    const quizNameHasError = useRef(false);
    const validateQuiz = (saving = false) => {
        let hasErrors = false;
        let quizNameError = "";

        // Only validate quiz name when saving or if we already have an error
        if (saving || quizNameError.current) {
            quizNameHasError.current = false;
            if (!quiz || !quiz.name || quiz.name.length == 0) {
                quizNameError = "Please enter a quiz name";
                quizNameHasError.current = true;
                hasErrors = true;
            }
        }

        const roundsValid = validateRoundsAndQuestions();
        if (!roundsValid) {
            hasErrors = true;
        }

        setHasErrors(hasErrors);
        setQuizNameError(quizNameError);
        if (!hasErrors) {
            setSaveError(false);
        }

        return !hasErrors;
    }

    useEffect(() => {
        if (quiz) {
            updateColourClasses();
            updateStatusIndex();
            //updateCampaignIndex();
            updateQuestionTypeIndexes();
            // Only validate quizzes that have rounds automatically, that way we don't get errors when creating a new quiz
            if (quiz.rounds && quiz.rounds.length > 0) {
                validateQuiz();
            }
        }
    }, [quiz])

    useEffect(() => {
        if (quizStatuses && (campaign || campaigns) && questionTypes) {
            getData();
        }
        //updateColourClasses();
    }, [quizId, quizStatuses, campaign, campaigns, questionTypes]);

    useEffect(() => {
        getData();
    }, []);

    const changeStatus = (e, id, item) => {
        e.preventDefault();

        let status = item.label;

        setQuiz(quiz => ({
            ...quiz,
            ...{ status: status }
        })
        );
        setStatusIndex(item.index || 0);

        updateColourClasses({ status: status });
    }

    const changeStream = (e, id, item) => {
        e.preventDefault();

        let streamId = item.id;

        setStreamIndex(item.index);

        setQuiz(quiz => ({
            ...quiz,
            ...{ stream: streamId }
        }));
    }

    const changeCampaign = (e, id, item) => {

        e.preventDefault();

        let campaignId = item.id;

        setCampaignIndex(item.index);

        setQuiz(quiz => ({
            ...quiz,
            ...{ campaign: campaignId }
        }));
    }

    const updateQuiz = (e) => {
        e.preventDefault();

        if (!validateQuiz(true)) {
            setSaveError(true);
            return;
        }

        //loop over answers, check for the isCorrectAnswer Boolean
        console.log('SAVING QUIZ: ', quiz, quizId, campaign, campaignId)

        const strippedQuiz = Object.assign({}, quiz);
        strippedQuiz.campaign = campaignId;
        console.log('SAVING STRIPPED QUIZ: ', strippedQuiz, quizId, campaign, campaignId)
        if (quizId) {

            axios
                .post(quizUpdateRoute, {
                    quizID: quizId,
                    quiz: strippedQuiz,
                    withCredentials: true
                })
                .then(response => {
                    if (response.data.updatedQuiz.hasUpdated) {
                        toast.success("Quiz updated successfully");
                        // setTimeout(() => { navigate(getNavigationRoute()) }, 600);
                        setHasUnsavedChanges(false);
                    } else {
                        toast.error("Failed to update the quiz, please ensure your quiz has a unique name");
                    }
                }).catch(err => {
                    toast.error("Failed to update the quiz, please ensure your quiz has a unique name");
                });
        } else {
            axios
                .post(createQuizRoute,
                    {
                        name: quiz.name,
                        campaignID: (quiz && quiz.campaign && quiz.campaign._id) ? quiz.campaign._id : (campaign && campaign._id) ? campaign._id : campaignId,
                        streamID: null,
                        disruptors: {
                            introDisruptor: quiz.introDisruptor,
                            correctDisruptor: quiz.correctDisruptor,
                            incorrectDisruptor: quiz.incorrectDisruptor
                        },
                        rounds: quiz.rounds,
                        status: quiz.status,
                        type: quiz.type,
                        gradingSystem: quiz.gradingSystem,
                        withCredentials: true
                    }
                )
                .then(response => {
                    if (response.data.success) {
                        toast.success("Quiz created successfully");
                        // setTimeout(() => { navigate(getNavigationRoute()) }, 600);
                        setHasUnsavedChanges(false);
                    } else {
                        toast.error(response.data.error);
                    }
                })
                .catch(function (error) {
                    // toast.error('Error - ' + error);
                });
        }
    }

    const addRound = (e) => {
        e.preventDefault();

        let rounds = quiz.rounds;
        if (!rounds) {
            rounds = [];
        }
        rounds.push({ name: "", questionTime: 0, questions: [] });
        setQuiz(quiz => ({
            ...quiz,
            ...{ rounds: rounds }
        }))

        setHasUnsavedChanges(true);
    }

    const removeUnnamedUntimedRounds = () => {
        if (quiz) {
            let rounds = quiz.rounds;
            let changed = false;
            if (rounds && rounds.length > 0) {
                for (var roundIndex = rounds.length - 1; roundIndex >= 0; --roundIndex) {
                    let round = rounds[roundIndex];
                    if (!round.name || round.name.length == 0 || round.time <= 0) {
                        rounds.splice(roundIndex, 1);
                        changed = true;
                    }
                }
            }
            if (changed) {
                setQuiz(quiz => ({
                    ...quiz,
                    ...{ rounds: rounds }
                }));
            }
        }
    }
    useEffect(() => {
        if (!showEditRoundPopup) {
            removeUnnamedUntimedRounds();
        }
        // console.log('Checked rounds: ', quiz);
    }, [showEditRoundPopup]);

    const removeBlankQuestions = () => {
        if (quiz) {
            let rounds = quiz.rounds;
            let changed = false;
            if (rounds && rounds.length > 0) {
                for (var roundIndex = rounds.length - 1; roundIndex >= 0; --roundIndex) {
                    let round = rounds[roundIndex];
                    if (round.questions && round.questions.length > 0) {
                        for (var questionIndex = round.questions.length - 1; questionIndex >= 0; --questionIndex) {
                            let question = round.questions[questionIndex];
                            if ((!question.questionText || question.questionText.length == 0) && (!question.questionImage || question.questionImage.length == 0)) {
                                round.questions.splice(questionIndex, 1);
                                changed = true;
                            }
                        }
                    }
                }
            }
            if (changed) {
                setQuiz(quiz => ({
                    ...quiz,
                    ...{ rounds: rounds }
                }));
            }
        }
    }
    useEffect(() => {
        if (!showEditQuestionPopup) {
            removeBlankQuestions();
        }
        // console.log('Checked questions: ', quiz);
    }, [showEditQuestionPopup]);

    const addQuestion = (e, roundIndex) => {
        e.preventDefault();

        let availableQuestionTypes = null;
        if (quizTypes && quizTypeIndex >= 0) {
            availableQuestionTypes = quizTypes[quizTypeIndex].availableQuestionTypes;
        }

        let rounds = quiz.rounds;
        if (!rounds || !rounds[roundIndex] || !rounds[roundIndex].questions) {
            rounds[roundIndex].questions = [];
        }
        rounds[roundIndex].questions.push({ questionText: "", questionImage: "", type: availableQuestionTypes[0].value, typeID: 0, answers: [], correctAnswer: {} });
        setQuiz(quiz => ({
            ...quiz,
            ...{ rounds: rounds }
        }));

        console.log('Added question: ', quiz, rounds, availableQuestionTypes);

        setHasUnsavedChanges(true);
    }

    const addAnswer = (e, roundIndex, questionIndex, hashtag, answerText, answerImage, answerExplanation, correctAnswer, usePreviousWinner) => {
        e.preventDefault();
        let rounds = quiz.rounds;
        rounds[roundIndex].questions[questionIndex].answers.push({ hashtag: hashtag, answerText: answerText, answerImage: answerImage, answerExplanation: answerExplanation, correctAnswer: correctAnswer, usePreviousWinner: usePreviousWinner }); //.splice(0, 0, {hashtag: "", answerText: "", answerImage:"" });
        setQuiz(quiz => ({
            ...quiz,
            ...{ rounds: rounds }
        })
        );

        verifyQuizDefaultHashtags();

        setHasUnsavedChanges(true);
    }


    const [showConfirmRemoveRound, setShowConfirmRemoveRound] = useState(false);
    const [roundIndexToEdit, setRoundIndexToEdit] = useState(null);
    const openConfirmRemoveRound = (e, roundIndex) => {
        setRoundIndexToEdit(roundIndex)
        setShowConfirmRemoveRound(true);
    }
    // const removeRound = (quiz, callback) => {
    //     axios
    //         .post(removeQuizRoute, { campaignID: id, quizID: editingQuiz._id, withCredentials: true })
    //         .then((response) => {
    //             if(response.data.removedQuiz) {
    //                 setQuizzes((oldQuizzes) => {
    //                     let newQuizzes = oldQuizzes.filter(it => it._id != response.data.removedQuiz.quiz._id);
    //                     return newQuizzes
    //                 })
    //                 callback();
    //             }
    //         })
    // }
    const removeRound = (roundIndex, callback) => {
        let updatedQuiz = quiz;
        updatedQuiz.rounds.splice(roundIndex, 1);

        setQuiz(quiz => ({
            ...updatedQuiz
        }));

        callback(true);

        setHasUnsavedChanges(true);
    }
    const closeConfirmRemoveRound = () => {
        //setRoundIndexToEdit(null);
        setShowConfirmRemoveRound(false);
    }

    const [showConfirmRemoveQuestion, setShowConfirmRemoveQuestion] = useState(false);
    const [questionIndexToEdit, setQuestionIndexToEdit] = useState(null);
    const openConfirmRemoveQuestion = (e, roundIndex, questionIndex) => {
        setRoundIndexToEdit(roundIndex)
        setQuestionIndexToEdit(questionIndex);
        setShowConfirmRemoveQuestion(true);
    }
    const removeQuestion = (roundIndex, questionIndex, callback) => {
        let updatedQuiz = quiz;
        updatedQuiz.rounds[roundIndex].questions.splice(questionIndex, 1);

        setQuiz(quiz => ({
            ...updatedQuiz
        }));

        callback(true);

        setHasUnsavedChanges(true);
    }
    const closeConfirmRemoveQuestion = () => {
        setQuestionIndexToEdit(null);
        setShowConfirmRemoveQuestion(false);
    }

    const [isNewRound, setIsNewRound] = useState(false);
    const openEditRoundPopup = (e, roundIndex, newRound = false) => {
        setIsNewRound(newRound);
        setRoundIndexToEdit(roundIndex);
        setShowEditRoundPopup(true);
    }
    const openEditQuestionPopup = (e, roundIndex, questionIndex) => {
        setRoundIndexToEdit(roundIndex);
        setQuestionIndexToEdit(questionIndex);
        setShowEditQuestionPopup(true);
    }

    const closeEditRoundPopup = () => {
        //setRoundIndexToEdit(null);
        setShowEditRoundPopup(false);
    }
    const closeEditQuestionPopup = () => {
        //setRoundIndexToEdit(null);
        //setQuestionIndexToEdit(null);
        setShowEditQuestionPopup(false);
    }

    const removeAnswer = (e, roundIndex, questionIndex, answerIndex) => {
        e.preventDefault();

        let updatedQuiz = quiz;
        updatedQuiz.rounds[roundIndex].questions[questionIndex].answers.splice(answerIndex, 1);

        verifyQuizDefaultHashtags();

        setQuiz(quiz => ({
            ...updatedQuiz
        }));

        setHasUnsavedChanges(true);
    }


    const changeQuizType = (e, id, item) => {
        //Changes the QuizType on the quiz level
        //The possible QuestionTypes will all be automatically set to the selected type
        setQuizTypeIndex(item.index);

        let updatedQuiz = quiz;
        updatedQuiz.type = item.label;

        //if a poll or tug of war, force questions counts to only 1 per round
        if (item.label == "Poll" || item.label == "Tug of War") {
            for (var roundIndex in updatedQuiz.rounds) {
                let round = updatedQuiz.rounds[roundIndex];
                if (round.questions.length > 1) {
                    updatedQuiz.rounds[roundIndex].questions = [updatedQuiz.rounds[roundIndex].questions[0]];
                }
            }
        }

        setQuiz(quiz => ({
            ...updatedQuiz
        }));

        setHasUnsavedChanges(true);
    }

    const changeQuestionType = (e, id, item, roundIndex, questionIndex) => {
        //prepare updated questions and questionTypeIndexes

        //Previous method, changed the type on a question by question basis.
        //Leaving here in case we want to be able to overwrite on a question by quesiton basis
        let rounds = quiz.rounds;
        rounds[roundIndex].questions[questionIndex].type = item;

        let updatedQuestionTypeIndexes = questionTypeIndexes;
        updatedQuestionTypeIndexes[roundIndex + "_" + questionIndex] = id;

        //set the updated fields in the state variables
        setQuiz(quiz => ({
            ...quiz,
            ...{ rounds: rounds }
        }));
        setQuestionTypeIndexes(updatedQuestionTypeIndexes);

        setHasUnsavedChanges(true);
    }

    const updateQuizState = (fieldVal, fieldNames) => {
        let fieldsArray = [];
        if (typeof fieldNames == "object"
            && fieldNames.length > 0) {

            fieldsArray = fieldNames;
        } else if (typeof fieldNames == "string") {
            if (fieldNames.indexOf(" ") > -1) {
                fieldsArray = fieldNames.split(" ");
            } else if (fieldNames.indexOf(",") > -1) {
                fieldsArray = fieldNames.split(",");
            } else {
                fieldsArray = [fieldNames];
            }
        }

        const findField = (object) => {

            for (var fieldNameKey in fieldsArray) {
                let fieldName = fieldsArray[fieldNameKey];
                let nextFieldName = fieldsArray[+fieldNameKey + 1];

                if (!nextFieldName) {
                    object[fieldName] = fieldVal;
                } else {
                    fieldsArray.splice(fieldNameKey, 1);
                    findField(object[fieldName])
                    return;
                }
            }
        }

        let updatedQuiz = quiz;
        findField(updatedQuiz);

        setQuiz(quiz => ({
            ...updatedQuiz
        }));

        console.log('Quiz updated: ', quiz);

        setHasUnsavedChanges(true);
    }

    const updateCorrectAnswer = (e, id, roundIndex, questionIndex, answerIndex) => {
        let updateQuiz = quiz;
        let hashtag = updateQuiz.rounds[roundIndex].questions[questionIndex].answers[answerIndex].hashtag;
        if (updateQuiz) {
            for (var key in updateQuiz.rounds[roundIndex].questions[questionIndex].answers) {
                if (key == answerIndex) {
                    if (updateQuiz.rounds[roundIndex].questions[questionIndex].correctAnswer) {
                        if (updateQuiz.rounds[roundIndex].questions[questionIndex].correctAnswer.hashtag == hashtag) {
                            updateQuiz.rounds[roundIndex].questions[questionIndex].correctAnswer.hashtag = null;
                        } else {
                            updateQuiz.rounds[roundIndex].questions[questionIndex].correctAnswer.hashtag = hashtag;
                        }
                    } else {
                        updateQuiz.rounds[roundIndex].questions[questionIndex].correctAnswer = {
                            hashtag: hashtag
                        }
                    }
                }
            }

            setQuiz(quiz => ({
                ...updateQuiz
            }));
        }

        setHasUnsavedChanges(true);
    }

    const verifyQuizDefaultHashtags = () => {
        if (quiz && quiz.rounds) {
            let updatedQuiz = quiz;
            let hasUpdated = false;
            for (var roundIndex in updatedQuiz.rounds) {
                let round = updatedQuiz.rounds[roundIndex];
                for (var questionIndex in round.questions) {
                    let question = round.questions[questionIndex];
                    let hasOnlyDefaultHashtags = true;
                    for (let answerIndex in question.answers) {
                        let answer = question.answers[answerIndex];
                        if (answer.hashtag !== '' && DEFAULT_HASHTAGS.indexOf(answer.hashtag) === -1) {
                            hasOnlyDefaultHashtags = false;
                        }
                    }
                    if (hasOnlyDefaultHashtags) {
                        for (let answerIndex in question.answers) {
                            let answer = question.answers[answerIndex];
                            if (answer.hashtag !== DEFAULT_HASHTAGS[answerIndex]) {
                                answer.hashtag = DEFAULT_HASHTAGS[answerIndex];
                                hasUpdated = true;
                            }
                        }
                    }
                }
            }
            if (hasUpdated) {
                /* setQuiz(quiz => ({
                    ...updatedQuiz
                })); */
                setHasUnsavedChanges(true);
            }
        }
    }

    /* Stuff for importing Csv files */
    const [roundIndexToAddCsvDataTo, setRoundIndexToAddCsvDataTo] = useState(null);
    const [processingCsvFile, setProcessingCsvFile] = useState(false);
    const [processedCsvData, setProcessedCsvData] = useState(null);
    const [allowUpdateFromPreview, setAllowUpdateFromPreview] = useState(false);
    const [showCsvDataPreview, setShowCsvDataPreview] = useState(false);
    const [replaceCurrentQuestions, setReplaceCurrentQuestions] = useState(false);

    const csvInputRef = useRef(null);
    const chooseCsvFile = (e, roundIndex) => {
        e.preventDefault();
        if (!processingCsvFile) {
            setRoundIndexToAddCsvDataTo(roundIndex);
            csvInputRef.current.click();
        }
    }
    const handleCsvFile = (e) => {
        setProcessingCsvFile(true);
        console.log('handleCsvFile', e);

        Papa.parse(e.target.files[0], {
            complete: function(results) {
                console.log(results);

                // Now we have the csv data, we can process it into an object we can use to preview the data
                let csvData = results.data;
                let processedCsvData = [];
                for (let i = 0; i < csvData.length; i++) {
                    let row = csvData[i];
                    const question = {
                        included: i > 0,
                        questionText: row[0],
                        answers: []
                    }
                    for (let j = 1; j < row.length; j++) {
                        question.answers.push({
                            answerText: row[j],
                            correctAnswer: j === 1
                        })
                    }
                    processedCsvData.push(question);
                }

                randomiseAnswerOrder(processedCsvData, false);
                setProcessedCsvData(processedCsvData);
                setReplaceCurrentQuestions(true);
                setAllowUpdateFromPreview(true);
                setShowCsvDataPreview(true);

                console.log('Processed csv data', processedCsvData);
            }
        });
    }

    const randomiseAnswerOrder = (questionData = null, setStateNow = true) => {
        const shuffle = (array) => {
            let currentIndex = array.length, randomIndex;
            while (currentIndex !== 0) {
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;
                [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
            }
            return array;
        }

        if (questionData === null) {
            questionData = processedCsvData;
        }
        for (let i = 0; i < questionData.length; i++) {
            let question = questionData[i];
            if (question.included) {
                question.answers = shuffle(question.answers);
            }
        }

        if (setStateNow) {
            setProcessedCsvData([...questionData]);
        }
    }
    
    const addNewQuestionsFromCsv = (e) => {
        e.preventDefault();

        let updatedQuiz = quiz;
        let roundIndex = roundIndexToAddCsvDataTo;
        let round = updatedQuiz.rounds[roundIndex];
        let questions = round.questions;
        console.log('Updating quiz with csv data', quiz, questions, processedCsvData);

        if (replaceCurrentQuestions) {
            questions = [];
        }
        for (let i = 0; i < processedCsvData.length; i++) {
            let question = processedCsvData[i];
            if (question.included) {
                questions.push({
                    questionText: question.questionText,
                    questionImage: "",
                    isPoll: false,
                    status: "Draft",
                    typeID: 1,
                    type: 'Multiple Choice',
                    answers: question.answers
                });
                for (let j = 0; j < question.answers.length; j++) {
                    let answer = question.answers[j];
                    answer.answerImage = null;
                    answer.answerExplanation = '';
                    answer.hashtag = DEFAULT_HASHTAGS[j];
                }
            }
        }
        round.questions = questions;

        setQuiz(quiz => ({...updatedQuiz}));
        setProcessingCsvFile(false);
        setShowCsvDataPreview(false);
        setHasUnsavedChanges(true);
    }

    const showCurrentQuestionsList = (e, round) => {
        e.preventDefault();
        setRoundIndexToAddCsvDataTo(round);
        setProcessedCsvData(quiz.rounds[round].questions);
        setAllowUpdateFromPreview(false);
        setShowCsvDataPreview(true);
    }

    return (
        <div className="content-page-container">
            <div className="fl-row">

                <div className="grow schedule-column">
                    <div className="content-page-top">
                        <div className='fl-row form-holder'>
                            <div><h1>  {(quiz && quiz._id) ? "Edit Quiz / Poll" : "Create Quiz / Poll"}</h1> </div>
                            <div className='grow'></div>
                            {saveError &&
                                <div className="error">Please fix errors before saving...</div>
                            }
                            {!saveError && hasUnsavedChanges &&
                                <div className="error">You have unsaved changes...</div>
                            }
                            <div><button type="submit" className={`standard-button${!hasUnsavedChanges ? ' button-inactive' : ''}`} onClick={hasUnsavedChanges ? (e) => { updateQuiz(e) } : null}>Save Quiz / Poll</button></div>
                        </div>
                    </div>
                    <div className="content-page-content-container">
                        <div className="content-page-main-content">

                            <div className="quiz-content">
                                <div className="form-holder quiz-panel">
                                    <form className="form" onSubmit={(e) => { updateQuiz(e) }} >
                                        <div className="fl-row  ">
                                            <div className="quiz-type-holder fl-start">
                                                <div className="">
                                                    <div className="column-cell">
                                                        <div className="underlined-panel-heading">
                                                            <h2>Settings</h2>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <div className="column-cell">
                                                        {/* <div className="label">Name</div> */}
                                                        <FormField id="name" type="text" showLabel={true} label="Name" placeholder={(quiz && quiz.name) ? quiz.name : "Enter a name"} error={quizNameError} value={(quiz && quiz.name) ? quiz.name : ""} setFunc={(fieldVal) => { return updateQuizState(fieldVal, "name") }} />

                                                        {quizTypes && quizTypes.length > 0 &&
                                                            <FormDropDown
                                                                id={"quiz_type"}
                                                                label="Type"
                                                                items={quizTypes}
                                                                selectFunc={(e, id, item) => changeQuizType(e, id, item)}
                                                                currentIndex={quizTypeIndex || 0}
                                                                enabled={quiz && quiz.rounds && quiz.rounds.length > 0 ? false : true}
                                                                icon="list"
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                                {/* <div className="s-row">
                                                    <div className="column-cell">
                                                        {gradingSystems &&
                                                            <RadioButtonGroup
                                                                id="gradingSystem"
                                                                label="Game Mode"
                                                                options={gradingSystems}
                                                                selectedOption={quiz && quiz.gradingSystem ? quiz.gradingSystem : "teamMode"}
                                                                selectFunc={(e, props, val) => { return updateQuizState(val, `gradingSystem`); }}
                                                            />
                                                        }
                                                    </div>
                                                </div> */}
                                                <div className="s-row">
                                                    <div className="column-cell">

                                                        {(campaign && campaign.streams && campaign.streams.length > 0) &&
                                                            <div className="s-row">
                                                                <div className="column-cell">
                                                                    <FormDropDown
                                                                        id="stream"
                                                                        label="Stream"
                                                                        items={[{ id: "_select", label: "All", index: 0 }].concat(campaign.streams.map(
                                                                            (it, index) => {
                                                                                return {
                                                                                    id: it._id,
                                                                                    label: it.twitchHandle + " " + getShortDateTimeString(it.startTime),
                                                                                    index: index + 1
                                                                                }
                                                                            }
                                                                        ))}
                                                                        currentIndex={streamIndex}
                                                                        selectFunc={(e, id, item) => changeStream(e, id, item)}
                                                                        icon="campaignsNav"
                                                                        enabled={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>

                                                {(quiz && quiz.dateCreated) &&
                                                <>
                                                    <div className="s-row">
                                                        <p className="small-text light-text">Created {(quiz && quiz.dateCreated) ? getShortDateTimeString(quiz.dateCreated) : ""}</p>
                                                    </div>
                                                    <div className="s-row">
                                                        <p className="small-text light-text">Last Updated {(quiz && quiz.dateUpdated) ? getShortDateTimeString(quiz.dateUpdated) : ""}</p>
                                                    </div>
                                                </>
                                                }


                                                </div>
                                            </div>
                                            <div className={`co-tab-column grow fl-start`}>
                                                <div className={`co-tab-content-area equal`}>
                                                    <div className="underlined-panel-heading quiz-heading">
                                                        <h2>Content</h2>
                                                        <div className="grow"></div>
                                                        <div className="shrink">
                                                            <button className={'standard-button heading-slim-button'} onClick={e => { addRound(e); openEditRoundPopup(e, (quiz.rounds && quiz.rounds.length) ? quiz.rounds.length - 1 : 0, true); }}>Add New Round</button>
                                                        </div>
                                                    </div>

                                                    {quiz && quiz.type &&
                                                        <>
                                                            <div className='round-box-overview'>
                                                                {(quiz && quiz.rounds && quiz.rounds.length > 0) &&
                                                                    quiz.rounds.map((round, roundIndex) => {

                                                                        let roundId = "round" + roundIndex;
                                                                        let roundNameId = "_name"
                                                                        let roundQuestionTimeId = "_questionTime";

                                                                        //From OneBotOverview
                                                                        return (<RoundOverview id={roundId} key={roundId} quiz={quiz} round={round} roundIndex={roundIndex} roundValid={roundsValid[roundIndex]} questionsValid={roundQuestionsValid[roundIndex]} editRoundFunc={openEditRoundPopup} removeRoundFunc={openConfirmRemoveRound} addQuestion={addQuestion} editQuestionFunc={openEditQuestionPopup} removeQuestionFunc={openConfirmRemoveQuestion} importCsvFile={chooseCsvFile} showCurrentQuestionsList={showCurrentQuestionsList} />);
                                                                    })
                                                                }
                                                                {hasErrors && quiz && (!quiz.rounds || quiz.rounds.length === 0) &&
                                                                    <div className="error">Please add at least one round to the quiz</div>
                                                                }

                                                            </div>


                                                        </>
                                                    }

                                                    {quiz && !quiz.type &&
                                                        <p>Please select a quiz type to continue</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showEditRoundPopup && !showEditQuestionPopup &&
                <EditQuizRound quiz={quiz} roundIndex={roundIndexToEdit} isNewRound={isNewRound} editQuestionFunc={openEditQuestionPopup} removeQuestionFunc={openConfirmRemoveQuestion} addQuestion={addQuestion} updateQuizState={updateQuizState} closePanelFunc={closeEditRoundPopup} />
            }
            {showEditQuestionPopup &&
                <EditQuizQuestion quiz={quiz} campaignId={(quiz.campaign ? quiz.campaign._id : 'generic')} removeAnswer={removeAnswer} roundIndex={roundIndexToEdit} questionIndex={questionIndexToEdit} questionTypes={questionTypes} questionTypeIndexes={questionTypeIndexes} quizTypes={quizTypes} quizTypeIndex={quizTypeIndex} changeQuizType={changeQuizType} changeQuestionType={changeQuestionType} addAnswer={addAnswer} updateCorrectAnswer={updateCorrectAnswer} addQuestion={addQuestion} updateQuizState={updateQuizState} closePanelFunc={closeEditQuestionPopup} />
            }
            {showConfirmRemoveRound &&
                <ConfirmRemoveQuizRound quiz={quiz} roundIndex={roundIndexToEdit} removeRound={removeRound} closePanelFunc={closeConfirmRemoveRound} />
            }
            {showConfirmRemoveQuestion &&
                <ConfirmRemoveQuizQuestion quiz={quiz} roundIndex={roundIndexToEdit} questionIndex={questionIndexToEdit} removeQuestion={removeQuestion} closePanelFunc={closeConfirmRemoveQuestion} />
            }
            {showCsvDataPreview &&
                <CsvPreview 
                    closePanelFunc={() => { setShowCsvDataPreview(false); setProcessingCsvFile(false); }} 
                    processedCsvData={processedCsvData}
                    setProcessedCsvData={setProcessedCsvData}
                    randomiseAnswerOrder={randomiseAnswerOrder}
                    replaceCurrentQuestions={replaceCurrentQuestions}
                    setReplaceCurrentQuestions={setReplaceCurrentQuestions}
                    addNewQuestionsFromCsv={addNewQuestionsFromCsv}
                    allowUpdateFromPreview={allowUpdateFromPreview}
                    roundIndex={roundIndexToAddCsvDataTo}
                    editQuestionFunc={openEditQuestionPopup} 
                    removeQuestionFunc={openConfirmRemoveQuestion}
                    campaignId={(quiz.campaign ? quiz.campaign._id : 'generic')}
                    quiz={quiz}
                />
            }

            {/* Hidden Csv Input */}
            {!showCsvDataPreview &&
                <input
                type="file"
                accept=".csv"
                name="image"
                onChange={handleCsvFile}
                hidden
                ref={csvInputRef}
                id="csvInput"
                />
            }
        </div>
        // </PopUpPanel>
    );
}

export default EditQuiz;
