import "./RoundOverview.scss";

import PropTypes from 'prop-types';
import { BOT_TYPES, getBotTypeDataFromDbString } from "../../helpers/Bots";
import { convertSecondsToHMS } from "../../helpers/Dates";
import { IconJsxer } from "../../helpers/IconHelper";
import HeadedPanel from "../../components/HeadedPanel/HeadedPanel";
import LeftColorBlockHeadedPanel from '../../components/LeftColorBlockBox/LeftColorBlockHeadedPanel';
const RoundOverview = (props) => {
    const round = props.round;
    console.log('Round overview props', props);

    if (!round.name) {
        return null;
    }

    return (
        <div className='no-panel-shadow'>
            < LeftColorBlockHeadedPanel 
                style={props.roundValid || typeof props.roundValid === 'undefined' ? "quiz" : "warning"}
                className={"quiz-round-panel"}
                headingContent={
                    <div className="fl-row fill-width ">
                        <div className="round-title-holder">
                            <div className="round-title">
                                {round.name}
                            </div>
                            <div className="small-text light-text">{round.questions.length} {round.questions.length === 1 ? 'Question' : 'Questions'}</div>
                        </div>
                        <div className="grow"></div>
                        
                            
                            <div className="edit-icon" onClick={props.editRoundFunc ? (e) => props.editRoundFunc(e, props.roundIndex) : null}>
                                {IconJsxer.GetIcon(
                                    IconJsxer.ICONS.edit,
                                    IconJsxer.ICON_STYLES.campaignPanelTop,
                                )}
                            </div>
                            <div className="edit-icon" onClick={props.removeRoundFunc ? (e) => props.removeRoundFunc(e, props.roundIndex) : null}>
                                {IconJsxer.GetIcon(
                                    IconJsxer.ICONS.trash,
                                    IconJsxer.ICON_STYLES.campaignPanelTop,
                                )}
                            
                            </div>
                               </div>
                            
                  
                }
            >
                <div className="quiz-questions-holder">
                {round.questions &&
                    <>
                            <div className="fl-column compact">
                                <div className="fl-row">
                                    <h5>{props.quiz.type !== 'Poll' ? 'Questions' : 'Question'}</h5>
                                    <div className="grow"></div>
                                    
                                    <div className="edit-icon" onClick={(e) => props.importCsvFile(e, props.roundIndex)}>
                                        {IconJsxer.GetIcon(IconJsxer.ICONS.importCsv, IconJsxer.ICON_STYLES.campaignPanelTop)}
                                    </div>
                                    <div className="edit-icon" onClick={(e) => props.showCurrentQuestionsList(e, props.roundIndex)}>
                                        {IconJsxer.GetIcon(IconJsxer.ICONS.list, IconJsxer.ICON_STYLES.campaignPanelTop)}
                                    </div>

                                    {(props.quiz.type !== 'Poll' || props.round.questions.length === 0) &&
                                        <div className="round-button small-add-button" onClick={e => { props.addQuestion(e, props.roundIndex); props.editQuestionFunc(e, props.roundIndex, round.questions.length-1); }}>
                                            {IconJsxer.GetIcon(IconJsxer.ICONS.plus, IconJsxer.ICON_STYLES.roundPanelButtonSmall)}
                                        </div>
                                    }
                                </div>
                                {props.quiz.type === 'Poll' &&
                                    <div className="fl-row small-text">
                                        Poll rounds are one question only.
                                    </div>
                                }
                            </div>
                           <div className="questions-list">
                        { round.questions.length > 0 &&
                            
                            round.questions.map((question, questionIndex) => {
                                if (props.quiz.type !== 'Poll' || questionIndex === 0) {
                                    return(
                                        <div key={"question_" + questionIndex} className="one-listed-q">
                                            <div className="question-text-column">
                                                <p>{question.questionText ? question.questionText : question.questionImage.split('.')[0].split('__')[0] + '.' + question.questionImage.split('.').pop()}
                                                    {props.questionsValid && props.questionsValid.length && props.questionsValid[questionIndex] ? "" : <><br /><span className="error">Invalid Question</span></>}
                                                </p>
                                            </div>
                                            <div className="grow"></div>
                                            <div className="controls-column">
                                                <div className="edit-icon-wh edit-icon-primary-colour" onClick={props.editQuestionFunc ? (e) => props.editQuestionFunc(e, props.roundIndex, questionIndex) : null}>
                                                    {IconJsxer.GetIcon(
                                                        IconJsxer.ICONS.edit,
                                                        IconJsxer.ICON_STYLES.campaignPanelTop,
                                                    )}
                                                </div>
                                                <div className="edit-icon-wh edit-icon-primary-colour" onClick={props.removeQuestionFunc ? (e) => props.removeQuestionFunc(e, props.roundIndex, questionIndex) : null}>
                                                    {IconJsxer.GetIcon(
                                                        IconJsxer.ICONS.trash,
                                                        IconJsxer.ICON_STYLES.campaignPanelTop,
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return null;
                                }
                            })
                        }
                        { round.questions.length === 0 &&
                        <>
                            <div className="one-listed-q">
                                <p><span className="error bold-text">{`No question${props.quiz.type === 'Quiz' ? 's' : ''} added.`}</span><br />{`Click the plus button to add ${props.quiz.type === 'Quiz' ? 'some' : 'one'}.`}</p>
                            </div>
                        </>
                        }
                        </div>
                    </>
                }
                    {/* <div className="grow"></div>
                    <div className="fl-row">
                        <button  className={'standard-button'} onClick={ e => { props.addQuestion(e, props.roundIndex); props.editQuestionFunc(e, props.roundIndex, round.questions.length-1); } }>Add Question</button>
                    </div> */}
                </div>
            </LeftColorBlockHeadedPanel>
        </div>
    )
}

RoundOverview.propTypes = {
    round: PropTypes.object,
    editRoundFunc: PropTypes.func,
    removeRoundFunc: PropTypes.func,
    editQuestionFunc: PropTypes.func,
    removeQuestionFunc: PropTypes.func,
};

RoundOverview.defaultProps = {
    round: {},
    editRoundFunc: null,
    removeRoundFunc: null,
    editQuestionFunc: null,
    removeQuestionFunc: null
}

export default RoundOverview;
