import BotsOverview from "../Campaigns/BotsOverview";
import { CampaignContext } from "../../contexts/CampaignContext";
import { useContext, useState, useEffect } from "react";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import HMSTimeField from "../../components/forms/HMSTimeField/HMSTimeField";
import CirclePerc from "../../components/CirclePerc/CirclePerc";
import FeatureControls from "../../components/FeatureControls/FeatureControls";
import { IconJsxer } from "../../helpers/IconHelper";
import StreamTranscriptItem from "../../components/Streams/StreamTranscriptItem";
import {CHANNEL_COLOURS, CalculateEngagementTime, GetPeakViewerTime, CalculateHashtagChartData, CalculateBotsTriggered, UserInteractionMessageChartData, ViewerCountChartData, ChattersChartData, CalculateAvgMsgPerUser, CalculateAvgInteractionPerUser, InteractionChartData, CalculateTopChatters, CalculateTopInteractionUsers, FEATURE_TIMELINE_DISPLAY, GetTopUsers, GetFeaturesRunCount, GetDisruptorsRunCount } from "../../helpers/AnalyticsFunctions";
import { assets } from "../../assets/assets";
import HeadedPanel from "../../components/HeadedPanel/HeadedPanel";
import Toggle from "../../components/forms/Toggle/Toggle";
import { LineChart, AreaChart, Area, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell, BarChart, Bar, ReferenceLine } from 'recharts';
import { convertMSToHMS, getShortDateTimeString, getShortTimeString } from "../../helpers/Dates";

import { getLiveStreamAnalyticData, getSessionAnaliticsData } from "../../api/routes";
import FormDropDown from "../../components/forms/DropDownList/FormDropDown";
import LeftColorBlockBox from "../../components/LeftColorBlockBox/LeftColorBlockBox";
import DropDownList from "../../components/forms/DropDownList/DropDownList";


const COLORS = ['#5DE8A0', '#ea80aa'];
const COLORS1 = ['#5DE8A0', '#FFD581'];
const MS_TIMES = {
    SECS_10: 10000,
    SECS_30: 30000,
    MINS_1: 60000,
    MINS_5: 300000,
    MINS_10: 600000,
    MINS_15: 900000,
    MINS_30: 1800000,
    HOURS_1: 3600000
}



const TabAnalytics = (props) => {
    const [campaignContext, setCampaignContext] = useContext(CampaignContext);
    const [chartUsername, setChartUsername] = useState('');

    const [prevChannel, setPrevChannel] = useState('')
    const [topUserChart, setTopUserChart] = useState('messages');
    const [messagePerTime, setMessagePerTime] = useState(MS_TIMES.MINS_1);
    const [selectedMessagePerTime, setSelectedMessagePerTime] = useState(null);
    const [selectedTopUsersType, setSelectedTopUsersType] = useState(null);
    const [selectedTopUsersTime, setSelectedTopUsersTime] = useState(null);
    const [topChattersStartTime, setTopChattersStartTime] = useState(props._analyticsData.featureStartTime)
    const [chattersBarTimeVariation, setChattersBarTimeVariation] = useState(MS_TIMES.MINS_5)
    const [chattersStartTimeOffset, setChattersStartTimeOffset] = useState(-1);
    const [pollingLiveAnlyticsData, setPollingLiveAnlyticsData] = useState(0);

    const [interactionUsername, setInteractionUsername] = useState('');
    const [topInteractionStartTime, setTopInteractionStartTime] = useState(props._analyticsData.featureStartTime)
    const [interactionBarTimeVariation, setInteractionBarTimeVariation] = useState(MS_TIMES.MINS_5)
    const [interactionStartTimeOffset, setInteractionStartTimeOffset] = useState(-1)
    // const [currentDisruptor, setCurrentDisruptor] = useState(null);
    // const [previewMode, setPreviewMode] = useState(false);
    const streamTargetTime = props._streamData.endTime - props._streamData.startTime;
    const targetTimeHms = convertMSToHMS(streamTargetTime, true);
    const liveTimeObj = props._streamLiveTime || { hoursStr: '00', minutesStr: '00', secondsStr: '00', rawHours: 0, rawMinutes: 0, rawSeconds: 0 };
    const liveTimeSecs = liveTimeObj.rawHours * 3600 + liveTimeObj.rawMinutes * 60 + liveTimeObj.rawSeconds;
    const liveTimePerc = liveTimeSecs / (streamTargetTime / 1000) * 100;

    const selectDisruptor = (d) => {
        console.log('Select distruptor: ', d);
        props._setPreviewMode(false);
        props._setCurrentDisruptor(d);
        //Test
    }

    
    const updateChannelFilterHandler = async (e, id, item) => {

        console.log(item, id)
        setChannelFilter(item);
    }
    const [channelFilter, setChannelFilter] = useState(null);
    const GetSessionDropdown = (props) => {
    
        let dropDownItems = 
            [
                {id:'all', label:'All'}
            ];
    
        if(props._analyticsData.channels &&  props._analyticsData.channels.length > 1)
        {
            for(let i=0; i<props._analyticsData.channels.length; i++)
            {
                let channelObj = props._analyticsData.channels[i];
                if(channelObj.channel)
                {
                    dropDownItems.push({id:channelObj.channel, label:channelObj.channel.toUpperCase() })
                }
            }
        }
        
    
    console.log(dropDownItems)
    
        return(
            <DropDownList 
            id="assignedTo" 
            selectFunc={updateChannelFilterHandler}
            currentIndex={channelFilter ? channelFilter.index : 0}
            icon="oneUser"
            items={
                dropDownItems
            }
            className="filter"
        >
    
        </DropDownList>
        )
    
    
    }

    const getFullAnalyticData = () => {
        if (props._sessionID) {
            // We need historical session analytics!
            console.log('GAD',  props._analyticsData )
            axios
                .get(getSessionAnaliticsData, {
                    params: { sessionID: props._analyticsData, analyticsObj:props._analyticsObj },
                    withCredentials: true
                })
                .then(function (response) {
                    console.log(response.data.analyticsData)
                    props._updateAnalyticsFunc(response.data.analyticsData);
                })
                .catch(function (error) {
                    toast.error('Error - ' + error);
                });
        } else {
            if (props._connected) {
                axios
                    .get(getLiveStreamAnalyticData, {
                        params: { streamID: props._streamID },
                        withCredentials: true
                    })
                    .then(function (response) {
                        console.log(response.data.analyticsData)
                        props._updateAnalyticsFunc(response.data.analyticsData);
                    })
                    .catch(function (error) {
                        toast.error('Error - ' + error);
                    });
            }
        }
        setPollingLiveAnlyticsData(0);
    }

    //Messages Chart Functions
    const changeTopChattersStartTime = (timeDiff, barTimeVariation) => {
        setChartUsername('');
        setChattersStartTimeOffset(timeDiff)
        setChattersBarTimeVariation(barTimeVariation);
    }

    const getFeatureEndTime = () => {
        if(!props._sessionID) return props._analyticsData.featureEndTime;
        return props._analyticsData.featureStartTime + props._analyticsData.featureRunTime;
    }
    const getLiveEndTime = () => {
        if(!props._sessionID) return Date.now();
        return props._analyticsData.featureStartTime + props._analyticsData.featureRunTime;
    }
    const updateTopChattersStartTime = () => {
        if (chattersStartTimeOffset == -1) setTopChattersStartTime(props._analyticsData.featureStartTime)
        else {
            var roundedStartTime = Math.floor((Date.now() - props._analyticsData.featureStartTime) / chattersBarTimeVariation) * chattersBarTimeVariation + props._analyticsData.featureStartTime;
            let newTime = roundedStartTime - chattersStartTimeOffset;
            if (newTime < props._analyticsData.featureStartTime) newTime = props._analyticsData.featureStartTime;
            setTopChattersStartTime(newTime);
        }
    }

    const historicalTimeBar =() =>{
        let timeObj = liveTimeObj
        if(props._sessionID)
        {
            timeObj = convertMSToHMS( props._analyticsData.featureRunTime, true);
            timeObj.rawSeconds = timeObj.s;
            timeObj.rawMinutes = timeObj.m;
            timeObj.rawHours = timeObj.h
            timeObj.minutesStr = timeObj.m_str;
            timeObj.secondsStr = timeObj.s_str

        }
        const timeSecs = timeObj.rawHours * 3600 + timeObj.rawMinutes * 60 + timeObj.rawSeconds;
        const timePerc = timeSecs / (streamTargetTime / 1000) * 100;
    
        console.log('TIME OBJ',timeObj)

        return (<>
            <CirclePerc perc={timePerc < 100 ? timePerc : 100}>
                {IconJsxer.GetIcon(IconJsxer.ICONS.clock, IconJsxer.ICON_STYLES.streamControlOptions)}
            </CirclePerc>
            <div className="fl-column compact">
                <div className="fl-row compact">
                    <h3 className="tight">{`${timeObj.rawHours}h ${timeObj.minutesStr}m ${timeObj.secondsStr}s`}</h3>
                    <p className="light-text small-text">(Target: {`${targetTimeHms.h}h ${targetTimeHms.m_str}m ${targetTimeHms.s_str}s`})</p>
                </div>
                <h4 className='light-text'>Stream Active Time</h4>
            </div>
        </>)
    }
    //Channel Specific functions
    const getTotalUniqueUsers = (props) =>{
        console.log('UNIQUES', props._analyticsData)
        if(channelFilter && channelFilter.id != 'all')
        {
            for(let i=0; i< props._analyticsData.channels.length; i++)
            {
                if( props._analyticsData.channels[i].channel == channelFilter.id)
                {
                    return  props._analyticsData.channels[i].uniqueUsers
                }
            }
        }
        else return props._analyticsData.userList.length
    }
    const getTotalMessages = (props) =>{

        if(channelFilter && channelFilter.id != 'all')
        {
            for(let i=0; i< props._analyticsData.channels.length; i++)
            {
                if( props._analyticsData.channels[i].channel == channelFilter.id)
                {
                    return  props._analyticsData.channels[i].messages;
                }
            }
        }
        else return props._analyticsData.messages
    }

    const getTotalUniqueInteractionUsers = (props) =>{

        if(channelFilter && channelFilter.id != 'all')
        {
            for(let i=0; i< props._analyticsData.channels.length; i++)
            {
                if( props._analyticsData.channels[i].channel == channelFilter.id)
                {
                    return  props._analyticsData.channels[i].uniqueInteractionUsers;
                }
            }
        }
        else return props._analyticsData.uniqueInteractionUsers
    }
    const getTotalInteractions = (props) =>{

        if(channelFilter && channelFilter.id != 'all')
        {
            for(let i=0; i< props._analyticsData.channels.length; i++)
            {
                if( props._analyticsData.channels[i].channel == channelFilter.id)
                {
                    return  props._analyticsData.channels[i].interactions;
                }
            }
        }
        else return props._analyticsData.interactions
    }

    const getHashtagData = (props) =>{
       
 
        let combinedHashtagData = [];
            for(let i=0; i< props._analyticsData.channels.length; i++)
                {
                    console.log( props._analyticsData.channels.length)
                    if(channelFilter && channelFilter.id != 'all' && props._analyticsData.channels[i].channel == channelFilter.id)
                    {
                        return  props._analyticsData.channels[i].interactionCount;
                    }
                    else
                    {
                        props._analyticsData.channels[i].interactionCount.forEach(interaction => {
                            let isNewHashtag = true;
                            combinedHashtagData.forEach(hashtagData => {
                                console.log(hashtagData)
                                if(hashtagData.hashtag == interaction.hashtag)
                                {
                                    isNewHashtag = false;
                                    hashtagData.amount += interaction.amount;
                                }
                               
                            })
                            if(isNewHashtag)
                                {
                                    combinedHashtagData.push({hashtag: interaction.hashtag, amount:interaction.amount })
                                }
                        })
                        
                    }
                }
                return combinedHashtagData
    }

    //Interaction CHart Functions
    const changeTopInteractionStartTime = (timeDiff, barTimeVariation) => {
        setInteractionUsername('');
        setInteractionStartTimeOffset(timeDiff)
        setInteractionBarTimeVariation(barTimeVariation);
    }

    const updateTopInteractionStartTime = () => {
        if (interactionStartTimeOffset == -1) setTopInteractionStartTime(props._analyticsData.featureStartTime)
        else {
            let newTime = Date.now() - interactionStartTimeOffset;
            if (newTime < props._analyticsData.featureStartTime) newTime = props._analyticsData.featureStartTime;
            setTopInteractionStartTime(newTime);
        }
    }

    const getTimeString = (time) => {
        let timeObj = convertMSToHMS(time, true);
        return timeObj.h + ':' + timeObj.m_str + ':' + timeObj.s_str;
    }
    const getDurationString = (startTime, endTime) => {
        if (endTime == 0) endTime = getLiveEndTime() - props._analyticsData.featureStartTime;
        let time = endTime - startTime
        let timeObj = convertMSToHMS(time, true);
        return timeObj.h + 'h ' + timeObj.m_str + 'm ' + timeObj.s_str + 's';
    }

    useEffect(
        () => {
            updateTopInteractionStartTime();
            updateTopChattersStartTime();
        }, [props._streamLiveTime, chartUsername]
    )

    useEffect(
        () => {
            setChartUsername('');
            setInteractionUsername('');
        },[channelFilter]

    )

    useEffect(
        () => {
            getFullAnalyticData();
        }, []
    )
    useEffect(
        () => {
            setPollingLiveAnlyticsData(prevState => {
                return prevState + 1
            })
        }, [props._streamLiveTime]
    )
    useEffect(
        () => {
            if (pollingLiveAnlyticsData == 60) {
                getFullAnalyticData();
            }
        }, [pollingLiveAnlyticsData]
    )
    //console.log('Analytics Data: ', props._analyticsData);

    return (
        <div className="ms-an-full-scroll-area">
            <div className="underlined-panel-heading">
                <h4>Stream Analytics Overview</h4>
                <div className="grow"></div>
            </div>

            {(!props._analyticsData || !props._analyticsData.botsFired) &&
                <div>Collecting data...</div>
            }

            {props._analyticsData && props._analyticsData.botsFired &&
                <>
                    <div className="analytics-sub-section">
                        <div className="analytics-sub-section">
                            <div className="ana-data-holder viewer-counts">
                                {!props._sessionID && 
                                <div className="fl-column compact">
                                    <p className="light-text">Current Viewers</p>
                                    <h4>{props._analyticsData.viewers.toLocaleString()}</h4>
                                </div>
                                }
                                <div className="fl-column compact">
                                    <p className="light-text">Peak Viewers</p>
                                    <div className="fl-row compact">
                                        <h4>{props._analyticsData.peakViewers.toLocaleString()}</h4>
                                        <p className="light-text small-text align-bottom">({GetPeakViewerTime(props._analyticsData)})</p>
                                    </div>
                                </div>
                                <div className="grow"></div>
                            </div>
                        </div>

                        <ResponsiveContainer width="100%" height={300}>
                            <LineChart

                                data={ViewerCountChartData(props._analyticsData)}
                                isAnimationActive={false}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: -20,
                                    bottom: 5,
                                }}
                            >
                                <XAxis dataKey="time" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                
                                <Line type="monotone" dataKey="ALL" stroke="#9146FF" strokeWidth={3} activeDot={{ r: 8 }} dot={{fill:"#9146FF", stroke:"#FFFFFF", strokeWidth: 2, r: 6, strokeDasharray: '' }} />
                   
                                {props._analyticsData.transcript && props._analyticsData.channels.map(
                                            (val, i) => {
                                                if(val.channel !== undefined)
                                                {
                                                    return (
                                                        <Line type="monotone" dataKey={val.channel.toUpperCase()}  stroke={CHANNEL_COLOURS[i%8]} strokeWidth={1}  strokeDasharray='5 5' activeDot={{ r: 8 }} dot={{ strokeWidth: 1, r: 4, strokeDasharray: '' }} />
                                                    )
                                                }
                                            }
                                        )}
                                

         </LineChart>
                        </ResponsiveContainer>
                    </div>

                    <div className="analytics-section">
                        <div className="fl-row no-panel-shadow">
                            <div className="fl-column fl-equal fl-start">

                                <div className="analytics-sub-section">

                                    <div className="ana-data-holder">
                                    {historicalTimeBar()}
                                
                                    </div>

                                </div>
                                {!props._sessionID &&
                                <div className="analytics-sub-section fl-column">
                                    <div className="ana-data-holder">
                                        <div className="fl-column compact">
                                            <p className="light-text">Stream Title</p>
                                            <h5>{props._analyticsData.streamTitle}</h5>
                                        </div>
                                    </div>
                                    <div className="ana-data-holder">
                                        <div className="fl-column compact">
                                            <p className="light-text">Game Name</p>
                                            <h5>{props._analyticsData.gameName}</h5>
                                        </div>
                                    </div>
                                </div>
                                }

                                <div className="fl-column">

                                    <div className="fl-row compact">
                                        <LeftColorBlockBox style="an_brand_engagement">
                                            <div className='analytics-summary-content'>
                                                <h4>{CalculateEngagementTime(props._analyticsData)}</h4>
                                                <div>Feature Engagement</div>
                                            </div>
                                        </LeftColorBlockBox>

                                        <LeftColorBlockBox style="an_total_interactions">
                                            <div className='analytics-summary-content'>
                                                <h4>{props._analyticsData.interactions ? props._analyticsData.interactions.toLocaleString() : 0}</h4>
                                                <div>Total Interactions</div>
                                            </div>
                                        </LeftColorBlockBox>
                                    </div>

                                    <div className="fl-row compact">
                                        <LeftColorBlockBox style="an_u_users">
                                            <div className='analytics-summary-content'>
                                                <h4>{props._analyticsData.userList ? props._analyticsData.userList.length.toLocaleString() : 0}</h4>
                                                <div>Unique Users</div>
                                            </div>
                                        </LeftColorBlockBox>

                                        <LeftColorBlockBox style="feature_default">
                                            <div className='analytics-summary-content'>
                                                <h4>{GetFeaturesRunCount(props._analyticsData).toLocaleString()}</h4>
                                                <div>Features Ran</div>
                                            </div>
                                        </LeftColorBlockBox>
                                    </div>

                                    <div className="fl-row compact">
                                        <LeftColorBlockBox style="bot_default">
                                            <div className='analytics-summary-content'>
                                                <h4>{CalculateBotsTriggered(props._analyticsData).toLocaleString()}</h4>
                                                <div>Bot Posts</div>
                                            </div>
                                        </LeftColorBlockBox>

                                        <LeftColorBlockBox style="disruptor_default">
                                            <div className='analytics-summary-content'>
                                                <h4>{GetDisruptorsRunCount(props._analyticsData).toLocaleString()}</h4>
                                                <div>Disruptors Started</div>
                                            </div>
                                        </LeftColorBlockBox>
                                    </div>

                                    {/* <div className="left-color-block-box">
                                        <div className="content-block left an-light-green-bg">
                                            {IconJsxer.GetIcon(IconJsxer.ICONS.multiUsers, IconJsxer.ICON_STYLES.analyticsColourBlockIcon)}
                                        </div>
                                        <div className="content-block right an-light-green-bd">
                                            <div>Unique Users</div>
                                            <div className="grow"></div>
                                            <div className="bold-text">{props._analyticsData.userList ? <>{props._analyticsData.userList.length.toLocaleString()}</> : <>0</>}</div>
                                        </div>
                                    </div>

                                    <div className="left-color-block-box">
                                        <div className="content-block left an-light-yellow-bg">
                                            {IconJsxer.GetIcon(IconJsxer.ICONS.clock, IconJsxer.ICON_STYLES.analyticsColourBlockIcon)}
                                        </div>
                                        <div className="content-block right an-light-yellow-bd">
                                            <div>Brand Engagement</div>
                                            <div className="grow"></div>
                                            <div className="bold-text">{CalculateEngagementTime(props._analyticsData)}</div>
                                        </div>
                                    </div>

                                    <div className="left-color-block-box">
                                        <div className="content-block left an-light-purple-bg">
                                            {IconJsxer.GetIcon(IconJsxer.ICONS.manual, IconJsxer.ICON_STYLES.analyticsColourBlockIcon)}
                                        </div>
                                        <div className="content-block right an-light-purple-bd">
                                            <div>Total Interactions</div>
                                            <div className="grow"></div>
                                            <div className="bold-text">{props._analyticsData.interactionUsers ? <>{props._analyticsData.interactions.toLocaleString()}</> : <>0</>}</div>
                                        </div>
                                    </div>

                                    <div className="left-color-block-box">
                                        <div className="content-block left an-dark-red-bg">
                                            {IconJsxer.GetIcon(IconJsxer.ICONS.paperPlane, IconJsxer.ICON_STYLES.analyticsColourBlockIcon)}
                                        </div>
                                        <div className="content-block right an-dark-red-bd">
                                            <div>Bots Triggered</div>
                                            <div className="grow"></div>
                                            <div className="bold-text">{CalculateBotsTriggered(props._analyticsData).toLocaleString()}</div>
                                        </div>
                                    </div>

                                    <div className="left-color-block-box">
                                        <div className="content-block left an-dark-green-bg">
                                            {IconJsxer.GetIcon(IconJsxer.ICONS.star, IconJsxer.ICON_STYLES.analyticsColourBlockIcon)}
                                        </div>
                                        <div className="content-block right an-dark-green-bd">
                                            <div>Features Run</div>
                                            <div className="grow"></div>
                                            <div className="bold-text">{GetFeaturesRunCount(props._analyticsData).toLocaleString()}</div>
                                        </div>
                                    </div>

                                    <div className="left-color-block-box">
                                        <div className="content-block left an-light-blue-bg">
                                            {IconJsxer.GetIcon(IconJsxer.ICONS.circle, IconJsxer.ICON_STYLES.analyticsColourBlockIcon)}
                                        </div>
                                        <div className="content-block right an-light-blue-bd">
                                            <div>Disruptors Shown</div>
                                            <div className="grow"></div>
                                            <div className="bold-text">{GetDisruptorsRunCount(props._analyticsData).toLocaleString()}</div>
                                        </div>
                                    </div> */}

                                </div>


                            </div>
                            <div className="fl-column fl-equal fl-start">
                                <HeadedPanel
                                    color="primary-light"
                                    headingContent={
                                        <>
                                            <h5 className="white-text">General Stream Stats</h5>
                                            <div className="grow"></div>
                                        </>
                                    }
                                    className="fl-equal"
                                >

                                    <div className="ana-data-holder fl-column standard-text-color">
                                        <div className="fl-row fill-width">
                                            <p>Start Time</p>
                                            <div className="grow"></div>
                                            <p className="light-text">{props._analyticsData.userList ? getShortDateTimeString(props._analyticsData.featureStartTime) : '[Not Started]'}</p>
                                        </div>
                                        <div className="fl-row fill-width">
                                            <p>End Time</p>
                                            <div className="grow"></div>
                                            <p className="light-text">{props._analyticsData.userList && getFeatureEndTime() !== 0 ? getShortDateTimeString(getFeatureEndTime()) : '[Still Running]'}</p>
                                        </div>
                                        <div className="fl-row fill-width">
                                            <p>Unique Users</p>
                                            <div className="grow"></div>
                                            <p className="light-text">{props._analyticsData.userList ? <>{props._analyticsData.userList.length.toLocaleString()}</> : <>0</>}</p>
                                        </div>
                                        <div className="fl-row fill-width">
                                            <p>Total Run Time</p>
                                            <div className="grow"></div>
                                            <p className="light-text">{props._analyticsData.userList ? getDurationString(0, props._analyticsData.featureRunTime) : '[No data]'}</p>
                                        </div>
                                        <div className="fl-row fill-width">
                                            <p>Total Interactions Recorded</p>
                                            <div className="grow"></div>
                                            <p className="light-text">{props._analyticsData.interactions ? <>{props._analyticsData.interactions.toLocaleString()}</> : <>0</>}</p>
                                        </div>
                                        <div className="fl-row fill-width">
                                            <p>Avg Interactions Per 10s</p>
                                            <div className="grow"></div>
                                            <p className="light-text">{props._analyticsData.interactions ? <>{(props._analyticsData.interactions / (props._analyticsData.featureRunTime / 10000)).toLocaleString()}</> : <>0</>}</p>
                                        </div>
                                        <div className="fl-row fill-width">
                                            <p>Top Users</p>
                                            <div className="grow"></div>
                                            <p className="light-text">{GetTopUsers(topInteractionStartTime, props._analyticsData).join(', ')}</p>
                                        </div>
                                    </div>

                                </HeadedPanel>
                            </div>
                        </div>
                    </div>


                    <div className="analytics-section">
                        <div className="fl-row no-panel-shadow">

                            <div className="fl-column fl-equal fl-start">

                                <div className="underlined-panel-heading">
                                    <h4>Play Order - Timeline</h4>
                                    <div className="grow"></div>
                                </div>

                                <div className="an-tr-scroll-area">
                                    <div className="fl-column an-po-list">
                                        {props._analyticsData.featuresStarted.slice(0).reverse().map((entry, index) => {
                                            let displayData = FEATURE_TIMELINE_DISPLAY.feature;
                                            if (FEATURE_TIMELINE_DISPLAY[entry.featureType]) {
                                                displayData = FEATURE_TIMELINE_DISPLAY[entry.featureType];
                                            }
                                            if (FEATURE_TIMELINE_DISPLAY[entry.varKey]) {
                                                displayData = FEATURE_TIMELINE_DISPLAY[entry.varKey];
                                            }
                                            if (FEATURE_TIMELINE_DISPLAY[entry.colourCategory]) {
                                                displayData = FEATURE_TIMELINE_DISPLAY[entry.colourCategory];
                                            }
                                            return (
                                                <div key={'po_' + index}>
                                                    <div className="fl-row">

                                                        <div className={`an-po-timeline-left${props._analyticsData.featuresStarted.length <= 1 ? ' only-po-item' : index === 0 ? ' first-po-item' : index === props._analyticsData.featuresStarted.length - 1 ? ' last-po-item' : ''}`}>
                                                            {index === props._analyticsData.featuresStarted.length - 1 &&
                                                                <img src={assets.AnPlayOrderLine} className="last-po-item-line" alt="" />
                                                            }
                                                            {index === 0 &&
                                                                <img src={assets.AnPlayOrderTopItem} alt="" />
                                                            }
                                                            {index > 0 &&
                                                                <img src={assets.AnPlayOrderItem} alt="" />
                                                            }
                                                            {index === props._analyticsData.featuresStarted.length - 1 &&
                                                                <div className="grow"></div>
                                                            }
                                                        </div>

                                                        <div className="fl-column compact an-po">
                                                            <div className={`fl-row an-${displayData.colour}`}>
                                                                <div>{IconJsxer.GetIcon(IconJsxer.ICONS[displayData.icon], IconJsxer.ICON_STYLES.standard)}</div>
                                                                <div>{entry.name}</div>
                                                            </div>
                                                            <div className="fl-row an-po-time">
                                                                <div>
                                                                    <span className="light-text">Time:</span> {getTimeString(entry.startTime * 1000)} - {entry.endTime == 0 && props._analyticsData.isLive ? <>NOW</> : <>{entry.endTime == 0 ? <> {getTimeString(getLiveEndTime()-props._analyticsData.featureStartTime)} </> : <>{getTimeString(entry.endTime * 1000)} </>
                                                                }</>}
                                                                </div>
                                                                <div>
                                                                    <span className="light-text">Duration:</span> {getDurationString(entry.startTime * 1000, entry.endTime * 1000)}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    {index < props._analyticsData.featuresStarted.length - 1 &&
                                                        <div className="fl-row">

                                                            <div className="an-po-timeline-left-divider">
                                                            </div>

                                                            <div className="fl-column grow">
                                                            </div>

                                                        </div>
                                                    }
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>

                            </div>

                            <div className="fl-column fl-equal fl-start">

                                <div className="underlined-panel-heading">
                                    <h4>Stream Transcript</h4>
                                    <div className="grow"></div>
                                </div>

                                <div className="an-tr-scroll-area">
                                    <div className="fl-column">
                                        {(!props._analyticsData.transcript || props._analyticsData.transcript.length === 0) &&
                                            <div>No Activity Yet Reported</div>
                                        }
                                        {props._analyticsData.transcript && props._analyticsData.transcript.map(
                                            (val, i) => {
                                                return (
                                                    <StreamTranscriptItem
                                                        key={'transcript' + i}
                                                        itemData={{
                                                            type: val.transcriptType,
                                                            startTime: val.startTime,
                                                            endTime: val.endTime,
                                                            shortDescription: val.shortDescription,
                                                            longDescription: val.longDescription
                                                        }}
                                                        _showTooltip={props._showTooltip}
                                                        _hideTooltip={props._hideTooltip}
                                                    />
                                                )
                                            }
                                        )}
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>



                    <div className="analytics-section">
                    <div className="underlined-panel-heading">
                            <h4>Channel Analytics</h4>
                           {GetSessionDropdown(props)}
                            <div className="grow"></div>
                        </div>

                        <div className="analytics-sub-section">
                            <div className="fl-row">
                                <div className="grow"></div>
                                <div className="fl-row compact an-mxi-key light-text">
                                    <div className="circle-color-block an-light-blue-bg">
                                        {IconJsxer.GetIcon(IconJsxer.ICONS.comments, IconJsxer.ICON_STYLES.analyticsKey)}
                                    </div>
                                    <div>Messages</div>
                                </div>
                                <div className="fl-row compact an-mxi-key light-text">
                                    <div className="circle-color-block an-light-yellow-bg">
                                        {IconJsxer.GetIcon(IconJsxer.ICONS.hashtag, IconJsxer.ICON_STYLES.analyticsKey)}
                                    </div>
                                    <div>Interactions</div>
                                </div>
                                <div className="fl-row compact an-mxi-key light-text">
                                    <div className="circle-color-block an-light-green-bg">
                                        {IconJsxer.GetIcon(IconJsxer.ICONS.multiUsers, IconJsxer.ICON_STYLES.analyticsKey)}
                                    </div>
                                    <div>Users</div>
                                </div>
                                <div className="fl-row compact light-text">
                                    {IconJsxer.GetIcon(IconJsxer.ICONS.clock, IconJsxer.ICON_STYLES.analyticsClock)}
                                    <FormDropDown
                                        className="an-mxi-drop"
                                        id="mxi_time"
                                        showLabel={false}
                                        showErrors={false}
                                        items={[
                                                {
                                                    id: '10_sec',
                                                    label: '10 sec',
                                                    value: MS_TIMES.SECS_10,
                                                },
                                                {
                                                    id: '30_sec',
                                                    label: '30 sec',
                                                    value: MS_TIMES.SECS_30,
                                                },
                                                {
                                                    id: '1_min',
                                                    label: '1 min',
                                                    value: MS_TIMES.MINS_1,
                                                },
                                                {
                                                    id: '5_min',
                                                    label: '5 min',
                                                    value: MS_TIMES.MINS_5,
                                                },
                                                {
                                                    id: '15_min',
                                                    label: '15 min',
                                                    value: MS_TIMES.MINS_15,
                                                },
                                            ]}
                                            selectFunc={(e, id, item) => { setSelectedMessagePerTime(item); setMessagePerTime(item.value) }}
                                            currentIndex={selectedMessagePerTime !== null ? selectedMessagePerTime.index : 0}
                                    />
                                </div>
                            </div>

                            <ResponsiveContainer width="100%" height={300}>
                                <AreaChart
                                    data={UserInteractionMessageChartData(props._analyticsData.featureStartTime, getLiveEndTime(), messagePerTime, props._analyticsData, null, channelFilter)}
                                    isAnimationActive={true}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: -20,
                                        bottom: 5,
                                    }}
                                >
                                    <XAxis dataKey="time" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Area type="monotone" isAnimationActive={true}  fillOpacity={1} name="Messages" dataKey="messages" fill="#ea80aa" stroke="#ea80aa" />
                                    <Area type="monotone" isAnimationActive={true}  fillOpacity={1} name="Interactions" dataKey="interactions" fill="#FFD581" stroke="#FFD581" />
                                    <Area type="monotone" isAnimationActive={true}  fillOpacity={1} name="New Chat Users" dataKey="uniqueMessageUsers" fill="#5DE8A0" stroke="#5DE8A0" activeDot={{ r: 4 }} />
                                </AreaChart>
                            </ResponsiveContainer>

                        </div>


                        <div className="fl-row">

                            <div className="fl-column fl-equal fl-start">

                                <div className="fl-column analytics-sub-section">
                                    <h5 className="light-text">Messages:</h5>

                                    <div className="fl-row">

                                        <div>
                                            <PieChart width={200} height={100}>
                                                <Pie
                                                    dataKey="amt"
                                                    nameKey="labelName"
                                                    startAngle={180}
                                                    endAngle={0}
                                                    data={ChattersChartData(getTotalMessages(props), getTotalUniqueUsers(props))}
                                                    cx="50%"
                                                    cy="100%"
                                                    outerRadius={80}
                                                    innerRadius={55}
                                                    fill="#8884d8"
                                                    isAnimationActive={true}
                                                    

                                                >
                                                    {ChattersChartData(getTotalMessages(props), getTotalUniqueUsers(props)).map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                    ))}
                                                </Pie>
                                            </PieChart>
                                        </div>

                                        <div className="grow"></div>

                                        <div className="fl-column fl-equal an-pie-key">
                                            <div className="circle-color-block an-light-green-bg">
                                                {IconJsxer.GetIcon(IconJsxer.ICONS.multiUsers, IconJsxer.ICON_STYLES.analyticsKey)}
                                            </div>
                                            <div>
                                                <h5>{props._analyticsData.userList ? <>{getTotalUniqueUsers(props).toLocaleString()}</> : <>0</>}</h5>
                                                <div className="light-text">Unique<br />Users</div>
                                            </div>
                                        </div>

                                        <div className="fl-column fl-equal an-pie-key">
                                            <div className="circle-color-block an-light-blue-bg">
                                                {IconJsxer.GetIcon(IconJsxer.ICONS.comments, IconJsxer.ICON_STYLES.analyticsKey)}
                                            </div>
                                            <div>
                                                <h5>{props._analyticsData.userList ? <>{getTotalMessages(props).toLocaleString()}</> : <>0</>}</h5>
                                                <div className="light-text">Total<br />Messages</div>
                                            </div>
                                        </div>

                                        <div className="fl-column fl-equal an-pie-key">
                                            <div className="circle-color-block an-light-grey-bg">
                                                {IconJsxer.GetIcon(IconJsxer.ICONS.projectPlan, IconJsxer.ICON_STYLES.analyticsKey)}
                                            </div>
                                            <div>
                                                <h5>{props._analyticsData.userList ? <>{CalculateAvgMsgPerUser(getTotalMessages(props), getTotalUniqueUsers(props)).toLocaleString()}</> : <>0</>}</h5>
                                                <div className="light-text">Average<br />Messages</div>
                                            </div>
                                        </div>

                                        <div className="grow"></div>

                                    </div>
                                </div>

                                <h5 className="light-text">Interactions:</h5>

                                <div className="fl-row">

                                    <div>
                                        <PieChart width={200} height={100}>
                                            <Pie
                                                dataKey="amt"
                                                nameKey="labelName"
                                                startAngle={180}
                                                endAngle={0}
                                                data={InteractionChartData(getTotalInteractions(props), getTotalUniqueInteractionUsers(props))}
                                                cx="50%"
                                                cy="100%"
                                                outerRadius={80}
                                                innerRadius={55}
                                                fill="#8884d8"
                                                isAnimationActive={true}
                                                

                                            >
                                                {InteractionChartData(getTotalInteractions(props), getTotalUniqueInteractionUsers(props)).map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={COLORS1[index % COLORS1.length]} />
                                                ))}
                                            </Pie>
                                        </PieChart>
                                    </div>

                                    <div className="grow"></div>

                                    <div className="fl-column fl-equal an-pie-key">
                                        <div className="circle-color-block an-light-green-bg">
                                            {IconJsxer.GetIcon(IconJsxer.ICONS.multiUsers, IconJsxer.ICON_STYLES.analyticsKey)}
                                        </div>
                                        <div>
                                            <h5>{props._analyticsData.uniqueInteractionUsers ? <>{getTotalUniqueInteractionUsers(props).toLocaleString()}</> : <>0</>}</h5>
                                            <div className="light-text">Unique<br />Users</div>
                                        </div>
                                    </div>

                                    <div className="fl-column fl-equal an-pie-key">
                                        <div className="circle-color-block an-light-yellow-bg">
                                            {IconJsxer.GetIcon(IconJsxer.ICONS.hashtag, IconJsxer.ICON_STYLES.analyticsKey)}
                                        </div>
                                        <div>
                                            <h5>{props._analyticsData.interactions ? <>{getTotalInteractions(props).toLocaleString()}</> : <>0</>}</h5>
                                            <div className="light-text">Total<br />Interactions</div>
                                        </div>
                                    </div>

                                    <div className="fl-column fl-equal an-pie-key">
                                        <div className="circle-color-block an-light-grey-bg">
                                            {IconJsxer.GetIcon(IconJsxer.ICONS.projectPlan, IconJsxer.ICON_STYLES.analyticsKey)}
                                        </div>
                                        <div>
                                            <h5>{props._analyticsData.interactions ? <>{CalculateAvgInteractionPerUser(getTotalInteractions(props), getTotalUniqueInteractionUsers(props)).toLocaleString()}</> : <>0</>}</h5>
                                            <div className="light-text">Average<br />Interactions</div>
                                        </div>
                                    </div>

                                    <div className="grow"></div>


                                </div>


                            </div>

                            <div className="fl-column fl-equal fl-start">
                                
                                <h5 className="light-text">Hashtags:</h5>

                                <ResponsiveContainer width="100%" height={300}>
                                    <BarChart
                                        data={CalculateHashtagChartData(getHashtagData(props))}
                                        layout="vertical"
                                        isAnimationActive={true}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: -20,
                                            bottom: 5,
                                        }}
                                    >
                                        <XAxis type="number" dataKey="amount" tickCount={6} allowDecimals={false} />
                                        <YAxis type="category" dataKey="hashtag" tickCount={4} />
                                        <Tooltip />
                                        <Bar barSize={20} name="Interactions" dataKey="amount" fill="#FFD581" />

                                    </BarChart>
                                </ResponsiveContainer>

                            </div>

                        </div>

                    </div>


                    <div className="analytics-section">

                        <div className="underlined-panel-heading">


                            <div className="fl-row grow">


                                <div className="fl-row fl-equal">



                                    <h4>Top Users</h4>
                                    <div className="grow"></div>

                                    <FormDropDown
                                        className="an-tutype-drop"
                                        id="tu_type"
                                        showLabel={false}
                                        showErrors={false}
                                        items={[
                                            {
                                                id: 'messages',
                                                label: 'Messages',
                                                value: 'messages',
                                            },
                                            {
                                                id: 'interactions',
                                                label: 'Interactions',
                                                value: 'interactions',
                                            },

                                        ]}
                                        selectFunc={(e, id, item) => { setSelectedTopUsersType(item); setTopUserChart(item.value) }}
                                        currentIndex={selectedTopUsersType !== null ? selectedTopUsersType.index : 0}
                                    />



                                </div>


                                <div className="fl-row fl-equal">
                                    <div className="grow"></div>

                                    <div className="fl-row compact light-text">
                                        {IconJsxer.GetIcon(IconJsxer.ICONS.clock, IconJsxer.ICON_STYLES.analyticsClock)}
                                      
                                        {!props._sessionID && 
                                        
                                        <FormDropDown
                                            className="an-tutype-drop"
                                            id="tu_time"
                                            showLabel={false}
                                            showErrors={false}
                                            items={[
                                                {
                                                    id: 'stream_all',
                                                    label: 'Whole Stream',
                                                    value: -1,
                                                    barTimeVariation: MS_TIMES.MINS_5,
                                                },
                                                {
                                                    id: 'last_hour',
                                                    label: 'Last Hour',
                                                    value: MS_TIMES.MINS_5,
                                                    barTimeVariation: MS_TIMES.SECS_10,
                                                },
                                                {
                                                    id: 'last_30_mins',
                                                    label: 'Last 30 Mins',
                                                    value: MS_TIMES.MINS_15,
                                                    barTimeVariation: MS_TIMES.SECS_30,
                                                },
                                                {
                                                    id: 'last_15_mins',
                                                    label: 'Last 15 Mins',
                                                    value: MS_TIMES.MINS_30,
                                                    barTimeVariation: MS_TIMES.MINS_1,
                                                },
                                                {
                                                    id: 'last_5_mins',
                                                    label: 'Last 5 Mins',
                                                    value: MS_TIMES.HOURS_1,
                                                    barTimeVariation: MS_TIMES.MINS_1,
                                                },
                                            ]}
                                            selectFunc={(e, id, item) => { setSelectedTopUsersTime(item); changeTopChattersStartTime(item.value, item.barTimeVariation); changeTopInteractionStartTime(item.value, item.barTimeVariation) }}
                                            currentIndex={selectedTopUsersTime !== null ? selectedTopUsersTime.index : 0}
                                        />}
                                    </div>





                                </div>
                            </div>


                        </div>


                        <div className="fl-row">

                            <div className="fl-column fl-equal fl-start">
                                <div className="an-tu-scroll-area">
                                    {topUserChart == 'messages' && CalculateTopChatters(topChattersStartTime, props._analyticsData, channelFilter).map(function (data, index) {
                                        if (index == 0 && chartUsername == '') setChartUsername(data.username)
                                        return (
                                            <div className={`an-user-list-item ${index % 2 === 0 ? 'an-user-light' : 'an-user-dark'}`} onClick={(e) => { setChartUsername(data.username) }} key={'un' + index}>
                                                <div className="black-text">{index < 9 ? '0' : ''}{index + 1}.</div>
                                                <div  className="black-text">{'['+data.channelName.toUpperCase()+'] '} </div>
                                                <div>{data.username}</div>
                                                <div className="grow"></div>
                                                <div className="black-text">{data.timedAmount} Messages</div>
                                            </div>
                                        )
                                    })}

                                    {topUserChart == 'interactions' && CalculateTopInteractionUsers(topInteractionStartTime, props._analyticsData, channelFilter).map(function (data, index) {
                                        if (index == 0 && interactionUsername == '') setInteractionUsername(data.username)
                                        return (
                                            <div className={`an-user-list-item ${index % 2 === 0 ? 'an-user-light' : 'an-user-dark'}`} onClick={(e) => { setInteractionUsername(data.username) }} key={'un' + index}>
                                                <div className="black-text">{index < 9 ? '0' : ''}{index + 1}.</div>
                                                <div  className="black-text">{'['+data.channelName.toUpperCase()+'] '} </div>
                                                <div>{data.username}</div>
                                                <div className="grow"></div>
                                                <div className="black-text">{data.timedAmount} Interactions</div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>

                            <div className="fl-column fl-equal fl-start">

                                <h5 className="light-text">{topUserChart == 'messages' ? chartUsername : interactionUsername} Message History</h5>

                                    <ResponsiveContainer width="100%" height={300}>
                                        <BarChart
                                            data={UserInteractionMessageChartData(topChattersStartTime, getLiveEndTime(), chattersBarTimeVariation, props._analyticsData, topUserChart == 'messages' ? chartUsername : interactionUsername)}
                                            isAnimationActive={false}
                                            margin={{
                                                top: 5,
                                                right: 30,
                                                left: -20,
                                                bottom: 5,
                                            }}
                                        >
                                            <XAxis dataKey="time" />
                                            <YAxis tickCount={6} allowDecimals={false} />
                                            <Tooltip />
                                            <Legend />
                                            <Bar barSize={20} name="Interactions" dataKey="interactions" stackId="a" fill="#FFD581" />
                                            <Bar barSize={20} name="Messages" dataKey="messages" stackId="a" fill="#ea80aa" />
                                        </BarChart>
                                    </ResponsiveContainer>

                            </div>

                        </div>



                    </div>




                </>
            }
        </div>
    )
}

export default TabAnalytics
