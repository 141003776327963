import { countStreamStatusTypesForCampaign } from "./Streams";

export const CAMPAIGN_TIERS = {
    basic: {
        displayName: 'T3',
        dbString: 'basic',
        oldDBString: 'bronze',
        imgString: 't3',
    },
    custom: {
        displayName: 'Custom',
        dbString: 'custom',
        oldDBString: 'gold',
        imgString: 'customnew',
    },
}

export const CAMPAIGN_TIER_ORDER = [
    CAMPAIGN_TIERS.basic,
    CAMPAIGN_TIERS.custom
]

export const getCampaignTierDataFromDbString = (dbString) => {
    for (let i = 0; i < CAMPAIGN_TIER_ORDER.length; i++) {
        if (CAMPAIGN_TIER_ORDER[i].dbString === dbString || CAMPAIGN_TIER_ORDER[i].oldDBString === dbString ) {
            return CAMPAIGN_TIER_ORDER[i];
        }
    }
}

export const getCampaignTierFormData = (listArray = [], ignoreTiers = []) => {
    for (let i = 0; i < CAMPAIGN_TIER_ORDER.length; i++) {
        if (ignoreTiers.indexOf(CAMPAIGN_TIER_ORDER[i]) === -1 && ignoreTiers.indexOf(CAMPAIGN_TIER_ORDER[i].displayName) === -1 && ignoreTiers.indexOf(CAMPAIGN_TIER_ORDER[i].dbString) === -1) {
            listArray.push({id: CAMPAIGN_TIER_ORDER[i].dbString, value: CAMPAIGN_TIER_ORDER[i].dbString, label: CAMPAIGN_TIER_ORDER[i].displayName});
        }
    }
    return listArray;
}

export const orderCampaignsAccordingToStatus = (campaigns) => {
    // Order our campaigns, live is most important, then scheduled, then ended, probably needs some date ordering as well
    for (let i = 0; i < campaigns.length; i++) {
        const statusCounts = countStreamStatusTypesForCampaign(campaigns[i].streams);
        campaigns[i].statusCounts = statusCounts;
    }
    campaigns.sort((a, b) => {
        // Big numbers here! As long as we have less than 500 streams, the ended campaigns will always go at the end
        let aPriority = a.statusCounts.live * 1000000 + a.statusCounts.scheduled * 500 + a.statusCounts.ended;
        let bPriority = b.statusCounts.live * 1000000 + b.statusCounts.scheduled * 500 + b.statusCounts.ended;
        return bPriority - aPriority;
    });
    return campaigns;
}

export const findFeature = (featureId, campaignData) => {
    for (let i = 0; i < campaignData.features.length; i++) {
        if (campaignData.features[i]._id === featureId) {
            return campaignData.features[i];
        }
    }
    return null;
}

export const findLinkedFeature = (featureId, campaignData, customisationData, basicOutput = false) => {
    const linkedFeatureNames = [];

    for (let i = 0; i < campaignData.chains.length; i++) {
        if (campaignData.chains[i].indexOf(featureId) >= 0) {
            if (!basicOutput) {
                linkedFeatureNames.push('FEATURE CHAIN');
            } else {
                linkedFeatureNames.push('Feature Chain');
            }
        }
    }

    
    for (let cId in customisationData) {
        for (let tab in customisationData[cId]) {
            for (let tabGroup in customisationData[cId][tab]) {
                for (let varGroup in customisationData[cId][tab][tabGroup]) {
                    for (let i = 0; i < customisationData[cId][tab][tabGroup][varGroup].length; i++) {
                        for (let prop in customisationData[cId][tab][tabGroup][varGroup][i]) {
                            if (typeof customisationData[cId][tab][tabGroup][varGroup][i][prop] === 'string' && customisationData[cId][tab][tabGroup][varGroup][i][prop].split(':')[0] === featureId) {
                                let linkedFeatureName = '';
                                for (let j = 0; j < campaignData.features.length; j++) {
                                    if (campaignData.features[j]._id === cId) {
                                        linkedFeatureName = campaignData.features[j].contentLabel + ' (' + campaignData.features[j].feature.featureName +')';
                                        
                                    }
                                }
                                linkedFeatureNames.push(basicOutput ? linkedFeatureName : linkedFeatureName + ': ' + cId);
                            }
                        }
                    }
                }
            }
        }
    }
    if (linkedFeatureNames.length) {
        return linkedFeatureNames.join(', ');
    }

    return basicOutput ? 'Unknown' : 'NOT FOUND (APPEARS UN-USED, MAYBE DELETE?)';
}

export const isFeatureLinked = (featureId, campaignData, customisationData) => {
    for (let i = 0; i < campaignData.chains.length; i++) {
        if (campaignData.chains[i].indexOf(featureId) >= 0) {
            return true;
        }
    }

    for (let cId in customisationData) {
        for (let tab in customisationData[cId]) {
            for (let tabGroup in customisationData[cId][tab]) {
                for (let varGroup in customisationData[cId][tab][tabGroup]) {
                    for (let i = 0; i < customisationData[cId][tab][tabGroup][varGroup].length; i++) {
                        for (let prop in customisationData[cId][tab][tabGroup][varGroup][i]) {
                            if (typeof customisationData[cId][tab][tabGroup][varGroup][i][prop] === 'string' && customisationData[cId][tab][tabGroup][varGroup][i][prop].split(':')[0] === featureId) {
                                return true;
                            }
                        }
                    }
                }
            }
        }
    }

    return false;
}

export const findLinkedFeatureTriggers = (featureId, campaignData, customisationData) => {
    const triggeredFeatures = [];

    for (let f_i = 0; f_i < campaignData.features.length; f_i++) {
        if (campaignData.features[f_i]._id !== featureId) {
            const cId = featureId;
            for (let tab in customisationData[cId]) {
                for (let tabGroup in customisationData[cId][tab]) {
                    for (let varGroup in customisationData[cId][tab][tabGroup]) {
                        for (let i = 0; i < customisationData[cId][tab][tabGroup][varGroup].length; i++) {
                            for (let prop in customisationData[cId][tab][tabGroup][varGroup][i]) {
                                if (typeof customisationData[cId][tab][tabGroup][varGroup][i][prop] === 'string' && customisationData[cId][tab][tabGroup][varGroup][i][prop].split(':')[0] === campaignData.features[f_i]._id) {
                                    if (triggeredFeatures.indexOf(campaignData.features[f_i]) === -1) {
                                        triggeredFeatures.push(campaignData.features[f_i]);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    return triggeredFeatures;
}

export const findUnlinkedChainedFeatures = (campaignData, customisationData) => {
    const unlinkedChainedFeaturesList = [];
    for (let f_i = 0; f_i < campaignData.features.length; f_i++) {
        if (campaignData.features[f_i].isChained && !isFeatureLinked(campaignData.features[f_i]._id, campaignData, customisationData)) {
            unlinkedChainedFeaturesList.push(campaignData.features[f_i]._id);
        }
    }
    return unlinkedChainedFeaturesList;
}

export const findFeatureChatCommands = (featureId, campaignData) => {
    const chatCommands = [];
    for (let i = 0; i < campaignData.hashtagCommands.length; i++) {
        if (campaignData.hashtagCommands[i].featureID === featureId) {
            chatCommands.push(campaignData.hashtagCommands[i]);
        }
    }
    return chatCommands;
}
