import React, { useState } from "react";

import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";

const ConfirmRemoveQuizRound = (props) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [round, setRound] = useState(props.quiz.rounds[props.roundIndex]);

    const removeNow = (e) => {
        e.preventDefault();
        props.removeRound(props.roundIndex, removeCallback);
    }

    const removeCallback = (success) => {
        if (success) {
            props.closePanelFunc();
        } else {
            setIsSubmitting(false);
        }
    }

    return (
        <PopUpPanel closePanelFunc={props.closePanelFunc}>
            <div className="form-holder co-popup-content">
                <form id="confirmRemoveFeature" className="form">
                    <h2>Remove This Round?</h2>

                    <div className="drop-gap">
                        <h4>{round.name}</h4>
                    </div>

                    <button className={'submit-btn' + (isSubmitting ? ' button-inactive' : ' button-active')} onClick={isSubmitting ? null : removeNow}>
                        {isSubmitting ? "Removing" : "Remove"}
                    </button>
                </form>
            </div>
        </PopUpPanel>
    )
}

export default ConfirmRemoveQuizRound;
