import React, { useContext, useEffect, useState } from "react";
import { CampaignContext } from "../../../contexts/CampaignContext";
import { IconJsxer } from "../../../helpers/IconHelper";
import { getLongDateString } from "../../../helpers/Dates";
import { getRegionDataFromDbString } from "../../../helpers/Regions";
import FormField from "../../../components/forms/FormField/FormField";
import RadioButtonGroup from "../../../components/forms/RadioButtonGroup/RadioButtonGroup";
import FormDropDown from "../../../components/forms/DropDownList/FormDropDown";
import ListedCampaignUser from "../../CreateCampaign/ListedCampaignUser";
import { getCampaignTierDataFromDbString } from "../../../helpers/CampaignsHelper";
import Toggle from "../../../components/forms/Toggle/Toggle";
import { AuthContext } from "../../../contexts/AuthContext";
import { ACCOUNT_TYPES } from "../../../helpers/AccountTypes";
import OneStreamOverview from '../../../components/Streams/OneStreamOverview';
import { getTimezoneOptions, parseTimezone } from "../../../helpers/TimeZones";
const CampaignDetailsTab = (props) => {
    const [campaignContext, setCampaignContext] = useContext(CampaignContext);
    const [authContext, setAuthContext] = useContext(AuthContext);
    return (
        <>
        <div className="co-tab-content">
            <div className={`co-tab-content-area fl-equal`}>
                <div className="underlined-panel-heading">
                    <h4>Campaign Details</h4>
                    
                </div>
                
                <div className="co-fullcol-scroll-area">

                {(!props._editMode || authContext.userData.userLevel < ACCOUNT_TYPES.producer.level) &&
                    <>
                        <p className="label">Client</p>
                        <p className="bold-text indented">{campaignContext.campaignData.client}</p>
                        {authContext.userData.userLevel >= ACCOUNT_TYPES.producer.level && <p className="label">Campaign Level</p>}
                        {authContext.userData.userLevel >= ACCOUNT_TYPES.producer.level && <p className="bold-text indented capitalise">{getCampaignTierDataFromDbString(campaignContext.campaignData.campaignTier).displayName}</p>}
                        {authContext.userData.userLevel >= ACCOUNT_TYPES.producer.level && <p className="label">Draft Campaign?</p>}
                        {authContext.userData.userLevel >= ACCOUNT_TYPES.producer.level && <p className="bold-text indented">{campaignContext.campaignData.draft ? 'Yes' : 'No'}</p>}
                        <p className="label">Date Created</p>
                        <p className="bold-text indented">{getLongDateString(campaignContext.campaignData.dateCreated)}</p>
                        {authContext.userData.userLevel >= ACCOUNT_TYPES.producer.level && <p className="label">Ad Bug Copy</p>}
                        {authContext.userData.userLevel >= ACCOUNT_TYPES.producer.level && <p className="bold-text indented">{campaignContext.campaignData.adBugCopy}</p>}
                        <p className="label">Region</p>
                        <p className="bold-text indented">{getRegionDataFromDbString(campaignContext.campaignData.region).displayName}</p>
                        {authContext.userData.userLevel >= ACCOUNT_TYPES.producer.level && <p className="label">Salesforce#</p>}
                        {authContext.userData.userLevel >= ACCOUNT_TYPES.producer.level && <p className="bold-text indented"><a href={campaignContext.campaignData.sfLink} target='_blank'>{campaignContext.campaignData.sfNumber}</a></p>}
                    </>
                }
                {props._editMode && authContext.userData.userLevel >= ACCOUNT_TYPES.producer.level &&
                    <>
                        <div className="form-holder">
                            <FormField label="Client" type="text" id="editclient" placeholder="Client Name" value={props._editedCampaignData.client} setFunc={(val) => props._changeEditedCampaignData('client', val)} />
                            <RadioButtonGroup label="Campaign Level" selectFunc={(e, id, val) => props._changeEditedCampaignData('campaignTier', val)} options={props._campaignTierData} selectedOption={props._editedCampaignData.campaignTier} />
                            <p className="label">Draft Campaign?</p>
                            <div className="fl-row indented">
                                <div className="shrink"><Toggle id="draftcampaign" currentState={props._editedCampaignData.draft === true} toggleFunc={(e, id) => props._changeEditedCampaignData('draft', !props._editedCampaignData.draft === true)} /></div>
                                <div className="light-text small-text tight grow">Draft campaigns can easily be hidden from the main campaign list.<br />You should use this setting for test campaigns and experiments.</div>
                            </div>
                        </div>
                        <p className="label">Date Created</p>
                        <p className="bold-text light-text indented uneditable">{getLongDateString(campaignContext.campaignData.dateCreated)}</p>
                        <div className="form-holder">
                            <FormField label="Ad Bug Copy" type="text" id="editadbug" placeholder="Ad Bug Copy" value={props._editedCampaignData.adBugCopy} setFunc={(val) => props._changeEditedCampaignData('adBugCopy', val)} />
                            <FormDropDown
                                label="Region"
                                id="region"
                                selectFunc={(e, id, item) => props._setCurrentRegion(item)}
                                currentIndex={props._currentRegion ? props._currentRegion.index : 0}
                                items={props._regionListData}
                            />

                            <p className="label">Salesforce</p>
                            <div className="fl-row">
                                <FormField label="#" inlineLabel={true} type="number" id="salesforcenum" placeholder="00000" value={props._editedCampaignData.sfNumber} setFunc={(val) => props._changeEditedCampaignData('sfNumber', val)} className={'edit-sfn-field'} min={0} />
                                <FormField label="Link" inlineLabel={true} type="text" id="salesforceurl" placeholder="Salesforce Link Url" value={props._editedCampaignData.sfLink} setFunc={(val) => props._changeEditedCampaignData('sfLink', val)} className={'grow'} />
                            </div>

                        </div>
                    </>
                }

                <p className={`label${props._editMode ? ' uneditable' : ''}`}>Campaign Owner</p>
                <div className="fl-row">
                    <ListedCampaignUser user={campaignContext.campaignData.campaignOwnerAccount} showCheck={false} />
                    {props._editMode && campaignContext.campaignData.campaignOwnerAccount && campaignContext.campaignData.campaignOwnerAccount._id !== props._editedCampaignData.campaignOwnerAccount._id &&
                        <>
                            <div className="label">will change to</div>
                            <ListedCampaignUser user={props._editedCampaignData.campaignOwnerAccount} showCheck={false} />
                        </>
                    }
                    <div className="grow"></div>
                    {/* props._editMode &&
                        <div className="edit-icon" onClick={props._editCampaignOwner}>{IconJsxer.GetIcon(IconJsxer.ICONS.edit, IconJsxer.ICON_STYLES.settingsEdit)}</div>
                    */}
                </div>

                </div>

            </div>         
            
            
             <div className="co-tab-column fl-equal">
                <div className={`co-tab-content-area equal`}>
                    <div className="underlined-panel-heading">
                        <h4>Producers and Creatives</h4>
                        <div className="grow"></div>
                        {authContext.userData.userLevel >= ACCOUNT_TYPES.producer.level &&
                            <div className="round-button small-add-button" onClick={props._addProducers}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.plus, IconJsxer.ICON_STYLES.roundPanelButtonSmall)}
                            </div>
                        }
                        
                    </div>
                    <div className="co-halfcol-scroll-area">
                        {
                            campaignContext.campaignData.producerAccounts.map(
                                (val, i) => {
                                    return (
                                        <div className="fl-row" key={'co_prod_' + i}>
                                            <ListedCampaignUser user={val} selected={true} toggleSelectedFunc={null/*toggleUserSelected*/} showCheck={false} />
                                            <div className="grow"></div>
                                            {props._editMode && authContext.userData.userLevel >= ACCOUNT_TYPES.producer.level &&
                                                <div className="edit-icon" onClick={(e) => props._removeProducer(e, val, i)}>
                                                    {IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.campaignPanelTop)}
                                                </div>
                                            }
                                        </div>
                                    )
                                }
                            )
                        }
                    </div>
                </div>
                {authContext.userData.userLevel > ACCOUNT_TYPES.creative.level &&
                <div className={`co-tab-content-area equal`}>
                    <div className="underlined-panel-heading">
                        <h4>Agencies, Clients and Streamers</h4>
                        <div className="grow"></div>
                        {authContext.userData.userLevel >= ACCOUNT_TYPES.producer.level &&
                            <div className="round-button small-add-button" onClick={props._addClientContacts}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.plus, IconJsxer.ICON_STYLES.roundPanelButtonSmall)}
                            </div>
                        }
                        
                    </div>
                    <div className="co-halfcol-scroll-area">
                        {
                            campaignContext.campaignData.clientAccounts.map(
                                (val, i) => {
                                    return (
                                        <div className="fl-row" key={'co_client_' + i}>
                                            <ListedCampaignUser user={val} selected={true} toggleSelectedFunc={null/*toggleUserSelected*/} showCheck={false} />
                                            <div className="grow"></div>
                                            {props._editMode && authContext.userData.userLevel >= ACCOUNT_TYPES.producer.level &&
                                                <div className="edit-icon" onClick={(e) => props._removeClientContact(e, val, i)}>
                                                    {IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.campaignPanelTop)}
                                                </div>
                                            }
                                        </div>
                                    )
                                }
                            )
                        }
                    </div>
                </div>
                }
            </div>
        
        </div>

</> 

)
}

export default CampaignDetailsTab;
