import { Link, useNavigate,  } from "react-router-dom";
import { seedFeatureDBRoute } from "../../api/routes";
import axios from "../../api/axios";
import { toast } from "react-toastify";
import VideoUploader from "../../components/forms/VideoUploader/VideoUploader";
import AudioUploader from "../../components/forms/AudioUploader/AudioUploader";
import ImageUploader2 from "../../components/forms/ImageUpload/ImageUploader2";

import FontSelector from "../../components/forms/FontSelector/FontSelector";
import { useState, useEffect } from "react";
import FontSelectorPopup from "../../components/forms/FontSelector/FontSelectorPopup";
import { TOP_LEVEL_NAV_ROUTES } from "../../config/NavRoutes";
import RangeSlider from "../../components/forms/RangeSlider/RangeSlider";
import RadioButtonGroup from "../../components/forms/RadioButtonGroup/RadioButtonGroup";

function Home() {
    const navigate = useNavigate();
    const [xPerc, setXPerc] = useState(50);
    const [yPerc, setYPerc] = useState(50);
    const [machineID, setMachineID] = useState(2);
    const [voices, setVoices] = useState(speechSynthesis.getVoices());
    
    useEffect(() => {
        setVoices(speechSynthesis.getVoices());
        speechSynthesis.onvoiceschanged = () => {
            setVoices(speechSynthesis.getVoices());
        };
    }, []);

    console.log(speechSynthesis.getVoices());

    const gotoSeederEditor = (e) => {
        console.log("Open Seeder Editor");
        navigate(TOP_LEVEL_NAV_ROUTES.FEATURE_EDITOR);
    }

    const seedFeatures = (e) =>
    {
        e.preventDefault();
        axios
        .post(seedFeatureDBRoute, {withCredentials:true})
        .then(function (response) {
            toast.success("Features Seeded!");
            // navigate("/");
        })
        .catch(function (error) {
            // setIsSubmitting(false);
            toast.error('Error - ' + error.response.data.message);
            // console.log(error.response)
        });
    }

    const getFeatureList = (e) =>
    {
        e.preventDefault();
        axios
        .get('features/get-feature-list', {withCredentials:true})
        .then(function (response) {
            console.log(response);
            toast.success("Features Seeded!");
            // navigate("/");
        })
        .catch(function (error) {
            // setIsSubmitting(false);
            toast.error('Error - ' + error.response.data.message);
            // console.log(error.response)
        });
    }

const connectToClawMachine = (e) => {
    e.preventDefault();
    axios
    .post('claw/connect', {withCredentials:true})
    .then(function (response) {
        console.log(response);
        // navigate("/");
    })
}
const disconnectClawMachine = (e) => {
    e.preventDefault();
    axios
    .post('claw/disconnect', {withCredentials:true})
    .then(function (response) {
        console.log(response);
        // navigate("/");
    })
}
const clawGetVersions = (e) => {
    e.preventDefault();
    axios
    .post('claw/get-versions', {withCredentials:true})
    .then(function (response) {
        console.log(response);
        // navigate("/");
    })
}
const clawAutomatePlayTest = (e) => {
    e.preventDefault();
    axios
    .post('claw/automate_play_action', {xPerc:xPerc, yPerc:yPerc, machineID:machineID, withCredentials:true})
    .then(function (response) {
        console.log(response);
        // navigate("/");
    })
}




    const hardReset= (e) =>
    {
        e.preventDefault();
        axios
        .post('stream/hard-reset-backend-connections', {withCredentials:true})
        .then(function (response) {
            console.log(response);
            toast.success("Hard Reset Confirmed");
            // navigate("/");
        })
        .catch(function (error) {
            // setIsSubmitting(false);
            toast.error('Error - ' + error.response.data.message);
            // console.log(error.response)
        });
    }

    const fixCampaignFeatureLinkages = (e) =>
    {
        e.preventDefault();
        axios
        .get('features/fix-feature-linkages', {withCredentials:true})
        .then(function (response) {
            console.log(response);
            toast.success("Feature Linkages Fixed!");
            // navigate("/");
        })
        .catch(function (error) {
            // setIsSubmitting(false);
            toast.error('Error - ' + error.response.data.message);
            // console.log(error.response)
        });
    }

    const [showFontSelector, setShowFontSelector] = useState(false);
    const openFontSelector = () => {
        setShowFontSelector(true);
    }
    const closeFontSelector = () => {
        setShowFontSelector(false);
    }

    

  return (
      <div>
          <main>
            <div className="fl-column text-center form-holder">
                <h1 className="mt-4 mb-2">Koko Debug Tools</h1>
        
                <a href='#' onClick={(e) => { fixCampaignFeatureLinkages(e) }} >FIX FEATURE LINKAGES</a>
                <a href='#' onClick={(e) => { hardReset(e) }} >HARD RESET</a>
                <h2>CLAW MACHINE CONTROLS</h2>
                <a href='/claw-debug'>CLAW DEBUG</a>
                <a href='#' onClick={(e) => { connectToClawMachine(e) }} >CONNECT TO CLAW MACHINE</a>
                <a href='#' onClick={(e) => { disconnectClawMachine(e) }} >DISCONNECT CLAW MACHINE</a>
                <a href='#' onClick={(e) => { clawGetVersions(e) }} >GET VERSIONS</a>
                

                <RadioButtonGroup
                            label={'Select Machine'}
                            labelNote={''}
                            id={'machineSelect'}
                            selectFunc={(e, id, newVal) => {console.log('SELECT', newVal); setMachineID(newVal) }}
                            options={[{label:"Left", val:1}, {label: "Right", val:2}]}
                            horizontal={true}
                            selectedOption={machineID}
                            fillWidth={true}
                        />
                <RangeSlider
                            label={'X Percentage'}
                            labelNote={''}
                            setFunc={(newVal) => { setXPerc(newVal) }}
                           // confirmChangeFunc={(newVal) => { updateFunction ? updateFunction(newVal, valsArray, varSchema, index, valKey) : changeVal(valsArray, varSchema, index, valKey, Number(newVal)) }}
                            min={0}
                            max={100}
                            value={xPerc}
                            showCurrentValue={ true}
                        />
                    <RangeSlider
                            label={'Y Percentage'}
                            labelNote={''}
                            setFunc={(newVal) => { setYPerc(newVal) }}
                           // confirmChangeFunc={(newVal) => { updateFunction ? updateFunction(newVal, valsArray, varSchema, index, valKey) : changeVal(valsArray, varSchema, index, valKey, Number(newVal)) }}
                            min={0}
                            max={100}
                            value={yPerc}
                            showCurrentValue={ true}
                        />
                <button className="standard-button" onClick={clawAutomatePlayTest}>Play Claw Move</button>


                <h2>Feature Seed Tools</h2>
                <a href='#' onClick={(e) => { seedFeatures(e) }} >SEED FEATURES</a>
                <a href='#' onClick={(e) => { getFeatureList(e) }} >GET FEATURES</a>
                <button className="standard-button" onClick={gotoSeederEditor}>Open Seeder Editor</button>


                <div className="popup-panel">

                <h2>Component Testing Ground</h2>
                    {/* <FontSelector /> */}

                    <button className="standard-button" onClick={openFontSelector}>Test Font Selector Popup</button>

                    {/* <VideoUploader

                    />

                    <ImageUploader2

                    />

                    <AudioUploader

                    /> */}


                </div>
                <div>
                    
                    {voices.map(
                    (val, i) => {
                        return (
                            <div key={'bot_copy_' + i}>
                                <p>{val.lang +' | +'+ val.name}</p>
                            </div>
                        )
                    }
                )}




                </div>


            </div>

          </main>

        {showFontSelector &&
            <FontSelectorPopup
                closePanelFunc={closeFontSelector}
            />
        }

          
      </div>
  );
}

export default Home;
