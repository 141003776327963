import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DebugLogger from "../../../helpers/DebugLogger";
import axios from "../../../api/axios";

import DefaultPlaceholderImg from "./placeholder.png";
import "./ImageUploader.scss";

export const UPLOADER_IMAGE_SIDE = {left: 'left', top: 'top'};

/**
 * Image uploader component.
 * Select an image to upload and see an immediate preview.
 * Optionally upload the image immediately or return the selected file to a callback so it can be uploaded as part of a larger form.
 * 
 * PROPS (mostly self explnatory, but some may need a little extra explanation, all are optional and have defaults / warnings if recommended):
 * 
 * label = A label for your uploader
 * previewSide = 'left' or 'top'
 * previewWidth / previewHeight = The width and height of your preview
 * imgAlt = A alt tag for your preview
 * placeholderImg = A default image to show when no image has been selected. This can be a string or an imported image (as used above: DefaultPlaceholderImg)
 * uploadBtnLabel = Set the label of the button
 * showRemoveOption = Optionally show the remove image option, if you are using this as part of a larger form you might leave this off
 * removeBtnLabel = The label for the remove button
 * uploadImmediately = true / false. If true then the uploader will attempt to upload your image to a provided endPoint as soon as it is selected.
 * endPoint = This is your endpoint for immediate uploads, for profile pics this is uploadProfilePicRoute from api/routes.js
 * removeEndPoint = Only really needed for immediate uploads, an endpoint to call to remove images from the server / db, see removeProfilePicRoute in api/routes.js
 * id = An id for your image, when set to uploadImmediately the id will be passed to the endPoint otherwise it is passed to the callbacks detailed below
 * extraData = Optional extra data passed to your endpoint. If this is an object then each property is extracted and passed up alongside your id
 * 
 * -- Only used when uploadImmediately=false --
 * fileSelectedCallback = A callback that receives and object in this format: {id: id, image: image, originalEvent: e}, 
 *                        this is a different signature to the other callbacks simply because you will most likely need all the data that gets passed back
 *                        (you will probably need to store the selected image data somewhere ready for submitting to your endpoint),
 *                        it fires when a file is selected just like it would if you used <input type="file"> directly instead of this component but with
 *                        a little bit more data included.
 *                        When removing the image you will receive: {id: id, image: null, originalEvent: 'removed'}, allowing you to remove the image from your stored form data.
 * 
 * -- Only used when uploadImmediately=true --
 * uploadedCallback = A callback that receives (res, image, id), you can probably ignore most params, but When uploading profile pics, I use it to set the profile pic
 *                      in authcontext userData.profilePic = res.data.uploadedFilename; so we can immediately start showing the new profile pic without asking the api for it.
 * 
 * -- Only used when uploadImmediately=true --
 * uploadErrorCallback = A callback you can use to deal with upload errors, but you probably won't need it...
 * 
 * -- Only used when uploadImmediately=true, if false you should use a fileSelected callback instead --
 * removedImageCallback = A callback you can use to detect when an image is removed.
 *                      I use it when a user removes their profile image to set the profile image data on authcontext as with uploadedCallback
 * 
 * Hopefully that's all clear! :) - Carl
 * 
 * @param {*} props 
 * @returns The component
 */
const ImageUploader = (props) => {
  const { label, previewSide, previewWidth, previewHeight, imgAlt, placeholderImg, uploadBtnLabel, showRemoveOption, removeBtnLabel, uploadImmediately, endPoint, removeEndPoint, id, extraData, fileSelectedCallback, uploadedCallback, uploadErrorCallback, removedImageCallback } = props;
  let currentImg = props.currentImg;

  // Warn about missing props...
  if (uploadImmediately && endPoint === '') {
    DebugLogger.error('No endPoint provided to Image Uploader, you should provide one if uploadImmediately=true.');
  }
  if (!uploadImmediately && fileSelectedCallback === null) {
    DebugLogger.warn('No fileSelectedCallback provided to Image Uploader, you should provide one if uploadImmediately=false.');
  }
  if (showRemoveOption && uploadImmediately && removeEndPoint === '') {
    DebugLogger.error('No removeEndPoint provided to Image Uploader, you should provide one if uploadImmediately=true and showRemoveOption=true.');
  }
  if (showRemoveOption && uploadImmediately && removedImageCallback === null) {
    DebugLogger.warn('You should provide a removedImageCallback when using uploadImmediately=true and showRemoveOption=true.');
  }

  const [busy, setBusy] = useState(false);
  const [previewImg, setPreviewImg] = useState(null);
  const imageInputRef = useRef(null);

  const chooseFile = () => {if (!busy) imageInputRef.current.click();};
  
  const handlePhoto = (e) => {
    e.preventDefault();
    if (!busy) {
      if (e.target.files.length === 0) {
        return;
      }
      const image = e.target.files[0];
      setPreviewImg(URL.createObjectURL(image));
      if (uploadImmediately) {
        DebugLogger.log('Attempting to upload image: ', image);
        if (endPoint) {
          const formData = new FormData();
          formData.append('image', image);
          if (id) {
            formData.append('id', id);
          } else {
            DebugLogger.warn('No id provided for image upload.')
          }
          if (extraData) {
            if (typeof extraData === 'string' || typeof extraData === 'number' || typeof extraData === 'boolean') {
              formData.append('extraData', extraData);
            } else {
              for (let prop in extraData) {
                formData.append(prop, extraData[prop]);
              }
            }
          }
          setBusy(true);

          axios.post(endPoint, formData, { withCredentials: true })
            .then(res => {
              DebugLogger.log('Upload result: ', res);
              setBusy(false);
              if (uploadedCallback) {
                uploadedCallback(res, image, id);
              }
            })
            .catch(err => {
              DebugLogger.log('Upload error: ', err);
              setBusy(false);
              if (uploadErrorCallback) {
                uploadErrorCallback(err, id);
              }
            });
        } else {
          DebugLogger.error('Could not upload image, no endPoint provided.');
        }
      } else {
        if (fileSelectedCallback) {
          fileSelectedCallback({id: id, image: image, originalEvent: e});
        }
      }
    }
  }

  const handleRemove = (e) => {
    e.preventDefault();
    if (!busy) {
      if (uploadImmediately) {
        DebugLogger.log('Attempting to remove image...');
        if (removeEndPoint) {
          const formData = new FormData();
          if (id) {
            formData.append('id', id);
          } else {
            DebugLogger.warn('No id provided for image removal.')
          }
          setBusy(true);

          axios.post(removeEndPoint, formData)
            .then(res => {
              DebugLogger.log('Remove image result: ', res);
              setBusy(false);
              imageInputRef.current.value = '';
              setPreviewImg(null);
              currentImg = null;
              if (removedImageCallback) {
                removedImageCallback(id);
              }
            })
            .catch(err => {
              DebugLogger.log('Remove image error: ', err);
              setBusy(false);
            });
        } else {
          DebugLogger.error('Could not remove image, no removeEndPoint provided.');
        }
      } else {
        imageInputRef.current.value = '';
        setPreviewImg(null);
        currentImg = null;
        if (fileSelectedCallback) {
          fileSelectedCallback({id: id, image: null, originalEvent: 'removed'});
        }
      }
    }
  }

  return (
    <div className="image-upload-holder">
        <div className="label">
          {label}
        </div>
        <div className={'content' + (previewSide === UPLOADER_IMAGE_SIDE.top ? ' thumb-top' : ' thumb-left')}>
          <div className="thumb-holder" style={{minWidth: previewWidth, minHeight: previewHeight}}>
            <div className="thumb-img" alt={imgAlt} style={{minWidth: previewWidth, minHeight: previewHeight, backgroundImage: `url(${previewImg ? previewImg : (currentImg && currentImg !== '') ? currentImg : placeholderImg})`}} />
          </div>
          <div className="options-holder">
            <div className="option">
              <button className={'upload-button' + (busy ? ' button-inactive' : ' button-active')} onClick={busy ? null : chooseFile}>{busy ? 'Uploading...' : uploadBtnLabel}</button>
            </div>
            <div className="option">
              <span className={(busy ? '' : ' button-active')} onClick={busy ? null : handleRemove}>{removeBtnLabel}</span>
            </div>
            <input
              type="file"
              accept=".png, .jpg, .jpeg, .gif"
              name="image"
              onChange={handlePhoto}
              hidden
              ref={imageInputRef}
              id={id}
            />
          </div>
        </div>
    </div>
  );
}

ImageUploader.propTypes = {
  label: PropTypes.string,
  previewSide: PropTypes.string,
  previewWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  previewHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  imgAlt: PropTypes.string,
  placeholderImg: PropTypes.any,
  currentImg: PropTypes.any,
  uploadBtnLabel: PropTypes.string,
  showRemoveOption: PropTypes.bool,
  removeBtnLabel: PropTypes.string,
  endPoint: PropTypes.string,
  removeEndPoint: PropTypes.string,
  id: PropTypes.string,
  extraData: PropTypes.any,
  uploadImmediately: PropTypes.bool,
  fileSelectedCallback: PropTypes.func,
  uploadedCallback: PropTypes.func,
  uploadErrorCallback: PropTypes.func,
  removedImageCallback: PropTypes.func,
};

ImageUploader.defaultProps = {
  label: 'Upload Image',
  previewSide: UPLOADER_IMAGE_SIDE.left,
  previewWidth: 100,
  previewHeight: 100,
  imgAlt: '',
  placeholderImg: DefaultPlaceholderImg,
  currentImg: '',
  uploadBtnLabel: 'Upload',
  showRemoveOption: true,
  removeBtnLabel: 'Remove',
  endPoint: '',
  removeEndPoint: '',
  id: '',
  extraData: null,
  uploadImmediately: false,
  fileSelectedCallback: null,
  uploadedCallback: null,
  uploadErrorCallback: null,
  removedImageCallback: null,
}

export default ImageUploader; 
