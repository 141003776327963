import { assets } from "../../assets/assets";
import { useContext, useEffect, useState, } from "react";
import axios from "../../api/axios";
import { bugReportUpdateRoute } from "../../api/routes";
import { toast } from "react-toastify";
import { AuthContext } from "../../contexts/AuthContext";
// import { getAcTypeData, getAcTypeDisplayName } from "../../helpers/AccountTypes";
import { getShortDateString, getShortDateTimeString } from "../../helpers/Dates";
import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import DropDownList from "../../components/forms/DropDownList/DropDownList";

import "./EditBugReport.scss";

/**
 * 
 * @param {*} props.bugReport - the BugReport model
 * @param {*} props.priorities - list of available priorities
 * @param {*} props.currentPriorityIndex - currentIndex for the priority DropDownList 
 * @returns 
 */
const EditBugReport = (props) => {

    const { bugReport, priorities, currentPriorityIndex, currentPriorityColourClass, statuses, currentStatusIndex,  currentStatusColourClass, developers, currentAssignedToIndex,  closePanelFunc } = props;

    const [authContext, setAuthContext] = useContext(AuthContext);
    const [ priority, setPriority ] = useState(bugReport.priority);
    const [ status, setStatus ] = useState(bugReport.status);
    const [ assignedTo, setAssignedTo ] = useState(bugReport.assignedTo);
    const [priorityIndex, setPriorityIndex] = useState(currentPriorityIndex);
    const [statusIndex, setStatusIndex] = useState(currentStatusIndex);
    const [assignedToIndex, setAssignedToIndex] = useState(currentAssignedToIndex);
    const [priorityColourClass, setPriorityColourClass] = useState(currentPriorityColourClass);
    const [statusColourClass, setStatusColourClass] = useState(currentStatusColourClass);

    const updateColourClasses = (data) => {
        let priority = (data && data.priority) ? data.priority : bugReport.priority;
        let status = (data && data.status) ? data.status : bugReport.status;

        switch(priority) {
          case "High":
            setPriorityColourClass("high-priority-colour");
            break;
          case "Medium":
            setPriorityColourClass("medium-priority-colour");
            break;
          case "Low":
            setPriorityColourClass("low-priority-colour");
            break;
          case "Wishlist":
            setPriorityColourClass("wishlist-priority-colour");
            break;
        }
  
        switch(status) {
          case "New":
            setStatusColourClass("new-status-colour");
            break;
          case "In Progress":
            setStatusColourClass("inprogress-status-colour");
            break;
          case "On Hold":
            setStatusColourClass("onhold-status-colour");
            break;
          case "Ignored":
            setStatusColourClass("ignored-status-colour");
            break;
          case "Complete":
            setStatusColourClass("complete-status-colour");
            break;
        }
      }
  
      useEffect(() => { 
        updateColourClasses();      
      },[bugReport])
  
      useEffect(() => {
        updateColourClasses();
      },[]);

    const changePriority = (e, id, item) => {
        e.preventDefault();
        
        let priority = item.id;

        setPriority(priority);
        setPriorityIndex(item.index);

        updateColourClasses({priority: priority});
    }
    const changeStatus = (e, id, item) => {
        e.preventDefault();

        let status = item.id;
        
        setStatus(status);
        setStatusIndex(item.index);

        updateColourClasses({status: status});
    }
    const changeAssignedTo = (e, id, item) => {
        e.preventDefault();
        
        setAssignedTo(item.id);
        setAssignedToIndex(item.index);
    }

    const updateBugReport = (e) => {
        e.preventDefault();

        axios
            .post(bugReportUpdateRoute, {
                "bugReportID": bugReport._id,
                "priority": priority,
                "status": status,
                "assignedTo": assignedTo,
                "notes": document.getElementById('notes').value
            })
            .then(response => {
                if(response.data.success) {
                    toast.success("Bug report updated successfully");
                    closePanelFunc();
                } else {
                    toast.error("Failed to update the bug report");
                }
            })
    }
    
    return (
        <PopUpPanel closePanelFunc={closePanelFunc}>
            <div className="edit-bugReport-container">
              {/* <form> */}
            <div className="form-holder">
                <form className="form">
                        <div className="s-row">
                            <div className="column-cell">
                                <div className="s-row">
                                    <div className="column-cell">
                                        <h2>{bugReport.title}</h2>
                                    </div>
                                </div>
                                <div className="s-row">
                                    <p className="small-text light-text">Bug Reported {getShortDateString(bugReport.dateCreated)}</p>
                                </div>
                                <div className="s-row">
                                    <p className="small-text light-text">Last Updated {getShortDateTimeString(bugReport.dateUpdated)}</p>
                                </div>
                            </div>
                        </div>
                        <div className="s-column small-gap">
                            <div className="label">Type</div>
                            <p className="indented tight">{bugReport.type}</p>
                        </div>
                        <div className="s-column small-gap">
                            <div className="label">Reference Code</div>
                            <p className="indented tight">{bugReport.bugReportCode}</p>
                        </div>
                        <div className="s-column small-gap">
                            <div className="label">Priority</div>
                            <DropDownList
                                id="priority"
                                items={priorities}
                                selectFunc={changePriority}
                                currentIndex={priorityIndex}
                                icon="priority"
                                className={priorityColourClass}
                            />
                        </div>
                        <div className="s-column small-gap">
                            <div className="label">Assigned to</div>
                            <DropDownList
                                id="assignedTo"
                                items={developers}
                                selectFunc={changeAssignedTo}
                                currentIndex={assignedToIndex}
                                icon="oneUser"
                            />
                        </div>
                        <div className="s-column small-gap">
                            <div className="label">Reported by</div>
                            <p className="indented tight">{bugReport.reporter ? bugReport.reporter.name : 'Unknown'}<br /> {`(${bugReport.reporter ? bugReport.reporter._id : ''})`}</p>
                        </div>
                        <div className="s-column small-gap">
                            <div className="label">Status</div>
                            <DropDownList
                                id="status"
                                items={statuses}
                                selectFunc={changeStatus}
                                currentIndex={statusIndex}
                                icon="status"
                                className={statusColourClass}
                            />
                        </div>

                        <div className="s-column small-gap">
                            <div className="label">Description</div>
                            <textarea id="description" className="textarea" readOnly value={bugReport.description}></textarea>
                        </div>
                        <div className="s-column small-gap">
                            <div className="label">Notes</div>
                            <textarea id="notes" className="textarea" defaultValue={bugReport.notes}></textarea>
                        </div>

                
                        <button className={'submit-btn button-active'} onClick={updateBugReport}>Update</button>
                    </form>
                </div>
            </div>
        </PopUpPanel>
    );
}

export default EditBugReport;
