import { useEffect, useState, useContext } from "react";
import axios from "../../api/axios";
import { AuthContext } from "../../contexts/AuthContext";

import { getCampaignsByDateRoute } from "../../api/routes";
import "./Schedule.scss";
import Calendar from "react-calendar";
import { IconJsxer } from "../../helpers/IconHelper";
import { STREAM_STATUS_TYPES } from "../../helpers/Streams";
import CampaignSummaryPanel from "../../components/Campaigns/CampaignSummaryPanel";
import DropDownList from "../../components/forms/DropDownList/DropDownList";
import { getShortDateString } from "../../helpers/Dates";
import { toast } from "react-toastify";

import { getRegionsDropDownListData } from "../../helpers/Regions";
import { getCampaignTierFormData } from "../../helpers/CampaignsHelper";
import { filterList } from "../../helpers/ListFunctions";

const Schedule = () => {
    const [today, setToday] = useState(null);
    const oneDay = 24 * 60 * 60 * 1000;

    const [authContext, setAuthContext] = useContext(AuthContext);

    const [campaignsData, setCampaignsData] = useState([]);
    const [firstFetch, setFirstFetch] = useState(true);
    
    const [regionFilter, setRegionFilter] = useState(null);
    const [tierFilter, setTierFilter] = useState(null);
    const [myCampaignsOnlyFilter, setMyCampaignsOnlyFilter] = useState(false);
    const [filteredCampaignsData, setFilteredCampaignsData] = useState([]);

    const updateFilterHandler = (e, dropDownId, item) => {
        console.log("updateFilterHandler(e,dropDownId, item)",e,dropDownId,item);
        switch (dropDownId) {
            case 'region':
                console.log("dropDownId == region");
                setRegionFilter(item);
                break;
            case 'myCampaignsOnly':
                if(item.id == "only_you") {
                    let itemOnlyYou = item;
                    itemOnlyYou.id = authContext.userData._id;
                    setMyCampaignsOnlyFilter(true);
                } else {
                    setMyCampaignsOnlyFilter(false);
                }
                break;
            case 'campaignTier':
                setTierFilter(item);
                break;
            default:
                break;
        }
    }
    
    //WILL BE NEEDED TO GRAB USER ID FOR myCampaignsOnly filter
    // authContext.userData._id
    const fetchCampaignList = (startDate = null, endDate = null) => {
        const date = new Date();

        const todayObj = {
            start: new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime(),
            end: new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1).getTime(),
        }
        setToday(todayObj);
        // setSelectedDate(null);

        startDate = startDate || new Date(date.getFullYear(), date.getMonth(), -7);
        endDate = endDate || new Date(date.getFullYear(), date.getMonth() + 2, 8);

        let campaignRoute = getCampaignsByDateRoute;

        axios
            .get(campaignRoute, { params: { isUserSpecific:myCampaignsOnlyFilter, regionFilter:regionFilter, startDate: startDate.getTime(), endDate: endDate.getTime() }, withCredentials: true })
            .then((response) => {
                setCampaignsData(response.data.campaigns);
                console.log(response.data.campaigns)
                setFilteredCampaignsData(response.data.campaigns);
                if (firstFetch) {
                    setFirstFetch(false);
                    findUpcomingStreams(null, response.data.campaigns);
                }
                else
                {
                    findUpcomingStreams(selectedDate, response.data.campaigns);

                    if(regionFilter || tierFilter || myCampaignsOnlyFilter) {
                        filterDisplayList();
                    }
                }
            })
            .catch((error) => {
                toast.error(error.response.data.msg);
            });
    }


    const filterDisplayList = () =>{
        console.log("in filterDisplayList");
        let tempFilterArr = campaignsData.concat();
        if(regionFilter && regionFilter.id !== 'all')
        {
            console.log("has regionFilter");
            tempFilterArr = filterList(tempFilterArr, ['region'], regionFilter.id);
        }
        if(myCampaignsOnlyFilter && myCampaignsOnlyFilter.id !== 'all')
        {
            console.log("has myCampaignsOnlyFilter");
            tempFilterArr = filterList(tempFilterArr, ['campaignOwner', '_id'], myCampaignsOnlyFilter.id);
        }
        if(tierFilter && tierFilter.id !== "all") {
            tempFilterArr = filterList(tempFilterArr, ['campaignTier'], tierFilter.id);
        }

        console.log("tempFilterArr = ",tempFilterArr);
        setFilteredCampaignsData(tempFilterArr);
        //setSearchTerm('');
        return tempFilterArr;
    }

    useEffect(() => {
       fetchCampaignList();
    }, []);

    useEffect(() => {
        console.log("filtersChanged in useEffect");
        filterDisplayList();
    }, [regionFilter, myCampaignsOnlyFilter, tierFilter])

    const [selectedDate, setSelectedDate] = useState(null);
    const [upcomingStreams, setUpcomingStreams] = useState([]);
    const findUpcomingStreams = (date = null, campaigns = null) => {
        campaigns = campaigns || filteredCampaignsData;//campaignsData;
        if (date !== null) {
            setSelectedDate(date);
        }

        const dateNow = new Date();
        const todayStart = new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate()).getTime();

        const epochDate = date ? date.getTime() : selectedDate ? selectedDate.getTime() : todayStart;
        const orderedCampaignList = [];
        for (let i = 0; i < campaigns.length; i++) {
            for (let j = 0; j < campaigns[i].streams.length; j++) {
                const stream = campaigns[i].streams[j];
                if (stream.startTime >= epochDate && (!date || stream.startTime < epochDate + oneDay)) {
                    orderedCampaignList.push({ campaign: campaigns[i], nextStream: stream.startTime, streamData: stream });
                }
            }
        }

        orderedCampaignList.sort((a, b) => a.nextStream - b.nextStream);

        while (!date && orderedCampaignList.length > 5) {
            orderedCampaignList.pop();
        }

        console.log('SET UPCOMING STREAMS')
        setUpcomingStreams(orderedCampaignList);
    }

    const dateRangeChanged = ({ action, activeStartDate, value, view }) => {
        const startDate = new Date(activeStartDate.getFullYear(), activeStartDate.getMonth(), -7);
        const endDate = new Date(activeStartDate.getFullYear(), activeStartDate.getMonth() + 2, 8);
        fetchCampaignList(startDate, endDate);
    }

    const dateChosen = (value, event) => {
        // setSelectedDate(value);
        findUpcomingStreams(value);
    }

    const getCalendarTileContent = ({ date, view }) => {
        // console.log(filteredCampaignsData)
        // Add data to tiles in month view only
        if (view === 'month') {
            const startTime = date.getTime();
            const endTime = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1).getTime();

            let count = 0;
            let allEnded = true;
            for (let i = 0; i < filteredCampaignsData.length; i++) {
                for (let j = 0; j < filteredCampaignsData[i].streams.length; j++) {
                    if (filteredCampaignsData[i].streams[j].startTime >= startTime && filteredCampaignsData[i].streams[j].startTime < endTime) {
                        count++;
                        if (filteredCampaignsData[i].streams[j].status !== STREAM_STATUS_TYPES.ended.dbString && filteredCampaignsData[i].streams[j].status !== STREAM_STATUS_TYPES.notset.dbString) {
                            allEnded = false;
                        }
                    }
                }
            }
            return (
                <div className={`count-pill${today && date.getTime() === today.start ? ' count-today' : ''}${count === 0 ? ' count-zero' : ''}${allEnded ? ' count-ended' : ''}`}>
                    <div>{IconJsxer.GetIcon(IconJsxer.ICONS.liveStream, IconJsxer.ICON_STYLES.standard)}</div>
                    <div>{count}</div>
                </div>
            );
        }
    }

    return (
        <div className="content-page-container">
            <div className="fl-row">

                <div className="grow schedule-column">
                    <div className="content-page-top">
                        <h1>Schedule</h1> 
                        <div className="filters-container form-holder">
                            <DropDownList
                                id="region"
                                selectFunc={updateFilterHandler}
                                currentIndex={regionFilter ? regionFilter.index : 0}
                                deselectedLabel="Region"
                                icon="region"
                                items={
                                    getRegionsDropDownListData([
                                        {
                                            id: 'all',
                                            label: 'See All',
                                            deselect: true,
                                        },
                                    ])
                                }
                                className="filter"
                            />
                            <DropDownList 
                                id="myCampaignsOnly" 
                                selectFunc={updateFilterHandler}
                                currentIndex={myCampaignsOnlyFilter ? 1 : 0}
                                deselectedLabel="Campaigns"
                                icon="oneUser"
                                items={
                                    [
                                        {
                                            id: 'all',
                                            label: 'All Campaigns',
                                            deselect: true,
                                        },
                                        {
                                            id: 'only_you',
                                            label: 'My Campaigns Only'
                                        }
                                    ]
                                }
                                className="filter"
                            />
                            <DropDownList
                                id="campaignTier"
                                selectFunc={updateFilterHandler}
                                currentIndex={tierFilter ? tierFilter.index : 0}
                                deselectedLabel="Campaign Tier"
                                icon="tier"
                                items={[
                                    { 
                                        id:"all",
                                        label: 'All Tiers',
                                        deselect: true
                                    }
                                ].concat(getCampaignTierFormData())}
                                className="filter"
                            />
                        </div>
                    </div>
                    <div className="content-page-content-container">
                        <div className="content-page-main-content">

                            <div className="schedule-holder">
                                <div className="grow">
                                    <Calendar
                                        minDetail="month"
                                        tileContent={getCalendarTileContent}
                                        onActiveStartDateChange={dateRangeChanged}
                                        onChange={dateChosen}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="schedule-column sch-r">
                    <div className="content-page-top">
                        {!selectedDate &&
                            <h1>Upcoming</h1>
                        }
                        {selectedDate &&
                            <h1>{getShortDateString(selectedDate)}</h1>
                        }
                    </div>
                    <div className="content-page-content-container">
                        <div className="content-page-main-content">

                            <div className="schedule-holder">
                                <div className="fl-column small-headed-panels no-panel-shadow upcoming-streams">
                                    {upcomingStreams.map(
                                        (val, i) => {
                                            console.log('Upcoming Stream: ', val, regionFilter);
                                            if (!regionFilter || regionFilter.id === 'all' || val.campaign.region === regionFilter.id) {
                                                return (
                                                    <CampaignSummaryPanel
                                                        key={'upc_' + i}
                                                        campaign={val.campaign}
                                                        showStream={val.streamData}
                                                    />
                                                )
                                            } else {
                                                return null;
                                            }
                                        }
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Schedule;
