import React, { useState, useEffect } from "react";
import validator from "validator";

import { toast } from "react-toastify";
import axios from "../../api/axios";
import { useNavigate } from "react-router-dom";

import { forgottenPasswordRoute } from "../../api/routes";
import FormField from "../../components/forms/FormField/FormField";
import "./ForgottenPassword.scss";

import { assets } from "../../assets/assets"

import ClosePanelBtn from "../../components/ClosePanelBtn/ClosePanelBtn";
import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";

const ForgottenPassword = (props) => {
    const [email, setEmail] = useState("");
    const [emailError, setemailError] = useState("");

    const [formValid, setFormValid] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [wasSentSuccessfully, setWasSentSuccessfully] = useState(false);

    const navigate = useNavigate();

    const handleValidation = (event) => {
        let formIsValid = true;

        if (!validator.isEmail(email)) {
            formIsValid = false;
            setemailError("Email Not Valid");
        } else {
            setemailError("");
        }

        setFormValid(formIsValid);
        return formIsValid;
    };

    const forgottenPasswordFormSubmitHandler = (e) => {
        e.preventDefault();
        const formValid = handleValidation();

        if (formValid) {
            setIsSubmitting(true);

            axios
                .post(forgottenPasswordRoute, {
                    email: email
                })
                .then(function (response) {
                    setIsSubmitting(false);
                    
                    toast.success("Forgotten Password Email Sent Successfully!");
                    setWasSentSuccessfully(true);
                })
                .catch(function (error) {
                    setIsSubmitting(false);
                    console.log("frontend error = ",error);
                    toast.error(error.response.data);
                });
        }
    };

    return (
        <PopUpPanel showCloseBtn={false}>

            { !wasSentSuccessfully && 
            
                <div className="form-holder">
                    <ClosePanelBtn clickFunc={props.closePanelFunc} />
                    <form id="forgottenpasswordform" className="form">
                        <h2>Forgotten Password</h2>
                        <p className="p-grey">Enter your details below to request an account password reset.</p>
                        <div className="spacer-small"></div>

                        <FormField
                            type="email"
                            id="email"
                            placeholder="Enter Email"
                            setFunc={setEmail}
                            label="Email"
                            error={emailError}
                        />

                        <div className="spacer-large"></div>
                        <div className="submit-btn-holder">
                            <button className={'submit-btn' + (isSubmitting ? ' button-inactive' : ' button-active')} onClick={isSubmitting ? null : forgottenPasswordFormSubmitHandler}>
                                {isSubmitting ? "Sending" : "Reset password"}
                            </button>
                        </div>
                    </form>
                
                </div>
            }
            { wasSentSuccessfully &&
                <div className="form-holder">
                    <div className="tickholder">
                        <img className="tickimg" src={assets.Tick} />
                    </div>
                    <div className="contentholder">
                        <h2>Great!</h2>
                        <p className="p-grey">If the information entered is associated with a Twitch Campaign Studio account, we have sent you an email with password reset instructions.</p>
                    </div>
                    <div className="spacer-small"></div>
                    
                    <form className="form">
                        <div className="submit-btn-holder">
                            <button className={'submit-btn button-active'} onClick={props.closePanelFunc}>
                                {"Close"}
                            </button>
                        </div>
                    </form>
                </div>
            }
        </PopUpPanel>
    )
}

export default ForgottenPassword;
