import React, { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { logoutRoute  } from "../api/routes";
import axios from "../api/axios";
import { toast } from "react-toastify";
import { convertMSToHMS } from "./Dates";


export const CHANNEL_COLOURS = [ '#ff46d3' ,  '#4648ff', '#ff5346',  '#46e2ff' ,'#ff4686', '#de46ff',  '#4695ff', '#46ffcf']
export const FEATURE_TIMELINE_DISPLAY = {
    audio: {
        colour: 'audio-disruptor-color',
        icon: 'music',
    },
    disruptorAudio: {
        colour: 'audio-disruptor-color',
        icon: 'music',
    },
    image: {
        colour: 'image-disruptor-color',
        icon: 'image',
    },
    imageOverlay: {
        colour: 'image-disruptor-color',
        icon: 'image',
    },
    animated: {
        colour: 'image-disruptor-color',
        icon: 'image',
    },
    animatedOverlay: {
        colour: 'image-disruptor-color',
        icon: 'image',
    },
    video: {
        colour: 'video-disruptor-color',
        icon: 'video',
    },
    disruptorVideo: {
        colour: 'video-disruptor-color',
        icon: 'video',
    },
    twitchHeader: {
        colour: 'image-disruptor-color',
        icon: 'image',
    },
    statusScreens: {
        colour: 'image-disruptor-color',
        icon: 'image',
    },
    miniFeature: {
        colour: 'mini-feature-disruptor-color',
        icon: 'film',
    },
    subBomb: {
        colour: 'mini-feature-disruptor-color',
        icon: 'film',
    },
    Confetti: {
        colour: 'mini-feature-disruptor-color',
        icon: 'film',
    },
    countdown321: {
        colour: 'mini-feature-disruptor-color',
        icon: 'film',
    },
    feature: {
        colour: 'feature-color',
        icon: 'star',
    },
    disruptor: {
        colour: 'disruptor-color',
        icon: 'disruptorDefault',
    },
}

export const UserInteractionMessageChartData = (startTime, endTime, timeDiff, analyticsObj, matchedUsername, channel) => {  
    if (startTime === 0) {
        return [];
        
    }

    let prevTime = '0:00:00';
    let currentTime = startTime;
    const chartData = [];
    while(currentTime <= endTime)
    {
        const chartTime = convertMSToHMS(currentTime + timeDiff - analyticsObj.featureStartTime, true);
        let newChartPoint = {
            messages: 0,
            interactions:0,
            uniqueMessageUsers:0,
            uniqueInteractionUsers:0,
            time:chartTime.h+':'+chartTime.m_str+':'+chartTime.s_str
        }
        prevTime = chartTime.h_str+':'+chartTime.m_str+':'+chartTime.s_str;
 

        analyticsObj.userList.forEach(user => {
            if(!matchedUsername || matchedUsername == user.username)
            {
                let channelID = null;
                if(channel && channel.id != 'all')
                {
                    for(let i=0; i<   analyticsObj.channels.length; i++)
                    {
                        if( analyticsObj.channels[i].channel == channel.id) channelID = i;
                    }
                }

                //console.log(channelID, user.channel, channel)
                if(channelID == null || channelID == parseInt(user.channel))
                {
                    let isUniqueInteraction = true;
                    for(let i=0; i< user.timeStamps.length; i++)
                    {
                        let time = user.timeStamps[i]
                        time = time*1000+analyticsObj.featureStartTime;
                        if(time > currentTime && time <= currentTime+ timeDiff)
                        {
                            newChartPoint.messages ++;
                            if(user.isInteraction[i] == 1)
                            {
                                newChartPoint.interactions ++;
                                if(isUniqueInteraction)
                                {
                                    isUniqueInteraction = false;
                                    newChartPoint.uniqueInteractionUsers++;
                                }
                            }
                            if(i ==0)newChartPoint.uniqueMessageUsers++;
                        }
                    } 
                } 
            }

        });

        if(matchedUsername) newChartPoint.messages -= newChartPoint.interactions

        if(currentTime >= startTime && matchedUsername != '') chartData.push(newChartPoint);
        currentTime += timeDiff;
    }
    return chartData;

}

export const ViewerCountChartData = (analyticsObj) => {  
 
    if(!analyticsObj.viewerCount) return [];
    const chartData = [];
    analyticsObj.viewerCount.forEach(viewerRecord =>{
        const chartTime = convertMSToHMS( viewerRecord.timeStamp * 1000, true);
        let newChartPoint = {
            ALL:viewerRecord.viewers,
            rawTime:viewerRecord.timeStamp,
            time:chartTime.h_str+':'+chartTime.m_str+':'+chartTime.s_str
        }
        chartData.push(newChartPoint);

    })

   let sortedChannels = analyticsObj.channels
   .filter(channelObj => channelObj.channel !== undefined)  // Filter out undefined channels
   .toSorted(function(a, b) {
        var textA = a.channel.toUpperCase();
        var textB = b.channel.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });


    for(let i=0; i<sortedChannels.length; i++)
    {
        let channelData = sortedChannels[i];
        channelData.viewerCount.forEach(viewerRecord =>{

            chartData.forEach(newChartData => {
                console.log(newChartData.rawTime,  viewerRecord.timeStamp)
                if(newChartData.rawTime == viewerRecord.timeStamp)
                {
                    newChartData[channelData.channel.toUpperCase()] = viewerRecord.viewers
                }
            })




/*

            const chartTime = convertMSToHMS( viewerRecord.timeStamp * 1000, true);
            let newChartPoint = {
                channel:channelData.channel.toUpperCase(),
                viewers:viewerRecord.viewers,
                time:chartTime.h_str+':'+chartTime.m_str+':'+chartTime.s_str
            }
            chartData.push(newChartPoint);*/
        })
    
    }


    return chartData;

}


export const ChattersChartData = (messages, uniqueUsers) => {  
    const chartData = [];    
    chartData.push({labelName:'Users: '+ uniqueUsers, amt:uniqueUsers})
    chartData.push({labelName:'Messages: '+messages, amt:messages - uniqueUsers})
    return chartData;
}

export const InteractionChartData = (interactions, uniqueUsers) => {  
    const chartData = [];    
   // if(!analyticsObj.interactionUsers) analyticsObj.interactionUsers = [];
   // if(!analyticsObj.interactions) analyticsObj.interactions = 0;
    chartData.push({labelName:'Users: '+uniqueUsers, amt:uniqueUsers})
    chartData.push({labelName:'Interactions: '+interactions, amt:interactions})
    return chartData;
}

export const CalculateAvgMsgPerUser = (messages, uniqueUsers) => {

    const msgsPerUser = messages / uniqueUsers;
    return Math.round(msgsPerUser * 100) / 100
}

export const CalculateBotsTriggered = (analyticsObj) => {

    let totalBotsFired = 0;
    if(analyticsObj.botsFired)
    {
        analyticsObj.botsFired.forEach(bot =>{
            totalBotsFired += bot.amount;
        })
    }
    return totalBotsFired;
}

export const CalculateHashtagChartData= (interactionData) => {

    /*  {hashtag:'#TEST1', amount:10},
        {hashtag:'#TEST2', amount:25},
        {hashtag:'#TEST3', amount:12},
        {hashtag:'#TEST4', amount:17},
        {hashtag:'#TEST5', amount:10},
        {hashtag:'#TEST6', amount:75},
        {hashtag:'#TEST7', amount:12},
        {hashtag:'#TEST8', amount:107},*/
    const chartData = [
      
    ];
    console.log('Interaction Data:',interactionData)
    interactionData.forEach(interaction => {
        let newChartData = {
            hashtag: interaction.hashtag,
            amount:interaction.amount,
        }
        chartData.push(newChartData);
    })
    return chartData;
}

export const CalculateEngagementTime = (analyticsObj) => {
    let rawTime = 0;
    if( analyticsObj.featuresStarted)
    {
        analyticsObj.featuresStarted.forEach(feature => {

            let endTime = feature.endTime == 0 ? Date.now() - analyticsObj.featureStartTime : feature.endTime * 1000;

            if(feature.endTime ==0 && !analyticsObj.isLive)
                {
                    endTime = analyticsObj.featureRunTime 



                }


            let timeDiff = endTime - (feature.startTime*1000);

            console.log('RAW TIME', analyticsObj )

            rawTime += timeDiff
        })
    }
    console.log('RAW TIME', rawTime)
    let rawEngagementTimeString = convertMSToHMS(rawTime, true);

    return rawEngagementTimeString.h+'h '+rawEngagementTimeString.m_str+'m '+ rawEngagementTimeString.s_str+'s';
}

export const CalculateAvgInteractionPerUser = (interactions, uniqueUsers) => {
    const interactionsPerUser = interactions /uniqueUsers;
    let resultDec = Math.round(interactionsPerUser * 100) / 100;
    if(isNaN(resultDec)) resultDec = 0;
    return resultDec;
}

export const CalculateTopChatters = (startTime, analyticsObj, channel) => {
   
    let userList = []
    if(channel && channel.id != 'all')
    {
        let channelID = 0;
        for(let i=0; i<   analyticsObj.channels.length; i++)
        {
            if( analyticsObj.channels[i].channel == channel.id) channelID = i;
        }

        analyticsObj.userList.forEach(userData => {
            if(parseInt(userData.channel) == channelID)
            {
                
                userList.push(userData);
            }
        })   
    }
    else
    {
        analyticsObj.userList.forEach(userData => {
            let channelName = analyticsObj.channels[userData.channel].channel; 
            userData.channelName = channelName;
        })
        userList = [...analyticsObj.userList];
    }
    userList.forEach(user => {

        user.timedAmount = 0;
        user.timeStamps.forEach((timeStamp, index) => {
            if(timeStamp - 6000000 < 0)
            {
                timeStamp = timeStamp*1000+analyticsObj.featureStartTime;
                if(timeStamp > startTime && user.isInteraction[index] == 0) user.timedAmount ++
            }
        })
    })
    userList.sort((a, b) => {
        return b.timedAmount - a.timedAmount;
    });
    return  userList.slice(0, 10)
 }

 export const CalculateTopInteractionUsers = (startTime, analyticsObj, channel) => {

    let userList = []
    if(channel && channel.id != 'all')
    {
    
    let channelID = 0;
    for(let i=0; i<   analyticsObj.channels.length; i++)
    {
        if( analyticsObj.channels[i].channel == channel.id) channelID = i
    }

    analyticsObj.userList.forEach(userData => {
        if(parseInt(userData.channel) == channelID)
        {
            userList.push(userData)
        }
    })
        
        
    }
    else
    {
        userList = [...analyticsObj.userList];
    }



   // if(analyticsObj.interactionUsers)
   // {
        userList.forEach((user) => {
            user.timedAmount = 0;
            user.timeStamps.forEach((timeStamp, index) => {
                timeStamp = timeStamp*1000+analyticsObj.featureStartTime;
                if(timeStamp > startTime && user.isInteraction[index] == 1) user.timedAmount ++
            })
        })
        userList.sort((a, b) => {
            return b.timedAmount - a.timedAmount;
        });
        return  userList.slice(0, 10)
   // }
    //return []
 }

export const GetTopUsers = (startTime, analyticsObj, count = 3) => {
    if(analyticsObj.interactionUsers)
    {
        let userList = [...analyticsObj.userList]
        userList.forEach((user) => {
            user.timedAmount = 0;
            user.timeStamps.forEach((timeStamp, index) => {
                timeStamp = timeStamp*1000+analyticsObj.featureStartTime;
                if(timeStamp > startTime && user.isInteraction[index] == 1) user.timedAmount ++
            })
        })
        userList.sort((a, b) => {
            return b.timedAmount - a.timedAmount;
        });
        userList = userList.slice(0, count);
        
        const topUsers = [];
        for (let i = 0; i < userList.length; i++) {
            topUsers.push(userList[i].username);
        }
        return topUsers;
    }
    return []
}

 export const GetPeakViewerTime = (analyticsObj) => {  
   
    let peakViewTime = '0:00:00'
    analyticsObj.viewerCount.forEach(viewerObj => {
       
        if(viewerObj.viewers.toString() == analyticsObj.peakViewers.toString()) 
        {
            const chartTime = convertMSToHMS( viewerObj.timeStamp * 1000, true);
            peakViewTime = chartTime.h+':'+chartTime.m_str+':'+chartTime.s_str
        }
    })
    return peakViewTime
}

export const GetFeaturesRunCount = (analyticsObj) => {
    let count = 0;
    for (let i = 0; i < analyticsObj.featuresStarted.length; i++) {
        if (analyticsObj.featuresStarted[i].featureType === 'feature') {
            count++;
        }
    }
    return count;
}

export const GetDisruptorsRunCount = (analyticsObj) => {
    let count = 0;
    for (let i = 0; i < analyticsObj.featuresStarted.length; i++) {
        if (analyticsObj.featuresStarted[i].featureType === 'disruptor') {
            count++;
        }
    }
    return count;
}
