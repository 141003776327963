import { useEffect, useState } from "react";
import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import FormField from "../../components/forms/FormField/FormField";
import { IconJsxer } from "../../helpers/IconHelper";

const KillFeaturesPopup = (props) => {
    const [forceStop, setForceStop] = useState('');
    const [forceStopError, setForceStopError] = useState('');
    const forceStopNow = () => {
        if (forceStop === 'DEACTIVATE') {
            setForceStopError('');
            props.killOldFeaturePages();
            props.closePanelFunc();
        } else {
            setForceStopError('Type DEACTIVATE above to confirm.');
        }
    }

    useEffect(() => {
        if (props.activatedFeatures <= 1) {
            props.closePanelFunc();
        }
    }, [props.activatedFeatures, props.closePanelFunc]);

    return (
        <PopUpPanel closePanelFunc={props.closePanelFunc}>
            <div className="fl-column red-force-stop-text form-holder">
                <h2>Deactivate Feature Pages?</h2>
                <div className="fl-row">
                    <div className="grow"></div>
                    <div>{IconJsxer.GetIcon(IconJsxer.ICONS.error, IconJsxer.ICON_STYLES.forceStopPopIcon)}</div>
                    <div className="grow"></div>
                </div>
                <div className="warning-text">You currently have {props.activatedFeatures} active feature pages.</div>
                <div></div>
                <div>Multiple active feature pages can interfere with disruptors and is only necessary when running a campaign across multiple streams at the same time.</div>
                <div>If you're not running the campaign, please consult the owner or producer before continuing.</div>
                <div></div>
                <div className="warning-text">To deactivate all but one feature page, type "DEACTIVATE" in the box below and click the button to confirm.</div>
                <div></div>
                <FormField
                    type="text"
                    id="str_handle"
                    placeholder=""
                    setFunc={setForceStop}
                    label="Force Stop?"
                    value={forceStop}
                    showLabel={false}
                    error={forceStopError}
                />
                <div></div>
                <div>This will keep either the master feature page or the last one opened. If you need to save a different feature page, set it as the master before clicking the button.</div>
                <div></div>
                <div className="fl-row grow">
                    <div className="grow"></div>
                    <button className="standard-button" onClick={forceStopNow}>DEACTIVATE FEATURES</button>
                    <div className="grow"></div>
                </div>
            </div>
        </PopUpPanel>
    )
}

export default KillFeaturesPopup;