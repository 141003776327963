/**
 * @class DebugLogger singleton class. Direct your console logs through this to make them easily switch off and on-able.
 * 
 * Usage:
 * 
 * If your NODE_ENV is set to development, then all logging is enabled.
 * 
 * In production mode you can use the following options on the query string to enable debug logging.
 * 
 *      All logging enabled: ?debug=*process.env.DEBUG_SECRET*   Default secret = RXH3zjYc2aLX
 *      Semi logging enabled: ?degub=semi OR ?semidebug
 *      Twitch logging enabled: ?debug=twitch OR ?twitchdebug
 */
const TWITCH_CSS = 'background: #6441A5; color: #FFF; padding: 5px; font-weight:bold;';
const ATTENTION_CSS = 'background: #D99D25; color: #000; padding: 5px; font-weight:bold;';
const ERROR_CSS = 'background: #FF0000; color: #FFF; padding: 5px; font-weight:bold;';

class DebugLogger {
    fullDebugMode = false;
    semiDebugMode = false;
    twitchDebugMode = false;

    constructor() {
        this.fullDebugMode = process.env.NODE_ENV === "development" || document.location.search.indexOf('debug=' + process.env.DEBUG_SECRET) !== -1;
        this.semiDebugMode = document.location.search.indexOf('debug=semi') !== -1 || document.location.search.indexOf('semidebug') !== -1;
        this.twitchDebugMode = document.location.search.indexOf('debug=twitch') !== -1 || document.location.search.indexOf('twitchdebug') !== -1;
    }

    /**
     * Just a standard console log, only shows in full debug mode
     * @param {*} msg 
     * @param  {...any} vals 
     */
    log(msg, ...vals) {
        if (this.fullDebugMode) {
            console.log(msg, ...vals);
        }
    }

    /**
     * A wrapped version of console.warn, in semi-debug mode we have a basic version, in full debug we have the full warning so we can drill down.
     * @param {*} msg 
     * @param  {...any} vals 
     */
    warn(msg, ...vals) {
        if (this.fullDebugMode) {
            console.log('%c WARNING! ', ATTENTION_CSS);
            console.warn(msg, ...vals);
        } else
        if (this.semiDebugMode) {
            console.log('%c WARNING: ' + msg, ATTENTION_CSS);
        }
    }

    /**
     * A wrapped version of console.error, in semi-debug mode we have a basic version, in full debug we have the full error so we can drill down.
     * @param {*} msg 
     * @param  {...any} vals 
     */
    error(msg, ...vals) {
        if (this.fullDebugMode) {
            console.log('%c ERROR! ', ERROR_CSS);
            console.error(msg, ...vals);
        } else
        if (this.semiDebugMode) {
            console.log('%c ERROR: ' + msg, ERROR_CSS);
        }
    }

    /**
     * A wrapped version of console.group, only shows in full debug mode
     * @param  {...any} data 
     */
    group(...data) {
        if (this.fullDebugMode) {
            console.group(...data);
        }
    }

    /**
     * A wrapped version of console.table, only shows in full debug mode
     * @param  {...any} data 
     */
    table(...data) {
        if (this.fullDebugMode) {
            console.table(...data);
        }
    }

    /**
     * A special twitch console.log. Will be prefixed with "TWITCH: " with a purple bg.
     * In semi-debug mode we have a basic version, in full debug we have the full warning so we can drill down.
     * @param {*} msg 
     * @param  {...any} vals 
     */
    twitch(msg, ...vals) {
        if (this.twitchDebugMode || this.semiDebugMode || this.fullDebugMode) {
            console.log('%c TWITCH: ' + msg, TWITCH_CSS);
            if (this.fullDebugMode) {
                console.warn(msg);
                if (vals) {
                    console.log(...vals);
                }
            }
        }
    }

    /**
     * A special attention console.log. Will be prefixed with "ATTENTION: " with an orange bg
     * @param {*} msg 
     * @param  {...any} vals 
     */
    attention(msg, ...vals) {
        if (this.semiDebugMode || this.fullDebugMode) {
            console.log('%c ATTENTION: ' + msg, ATTENTION_CSS);
            if (this.fullDebugMode) {
                console.warn(msg);
                if (vals) {
                    console.log(...vals);
                }
            }
        }
    }

    /**
     * Run a quick test on the main log types (doesn't bother with group or table as we really need data for those)
     * @param {string} testmode Set to 'off', 'full', 'twitch' or 'semi' to test different modes. The proper settings will be restored afterwards.
     */
    test(testmode = 'full') {
        let _fullDebugMode = this.fullDebugMode;
        let _semiDebugMode = this.semiDebugMode;
        let _twitchDebugMode = this.twitchDebugMode;
        
        this.fullDebugMode = testmode === 'full';
        this.semiDebugMode = testmode === 'semi';
        this.twitchDebugMode = testmode === 'twitch';

        this.log('Testing DEBUG_LOGGER.log', this);
        this.warn('Testing DEBUG_LOGGER.warn', this);
        this.error('Testing DEBUG_LOGGER.error', this);
        this.twitch('Testing DEBUG_LOGGER.twitch', this);
        this.attention('Testing DEBUG_LOGGER.attention', this);

        this.fullDebugMode = _fullDebugMode;
        this.semiDebugMode = _semiDebugMode;
        this.twitchDebugMode = _twitchDebugMode;
    }
}

const DEBUG_LOGGER = new DebugLogger();
export {DEBUG_LOGGER as default};
