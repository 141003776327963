import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DebugLogger from "../../../helpers/DebugLogger";
import axios from "../../../api/axios";

import { assets } from "../../../assets/assets";

// import DefaultPlaceholderImg from "./placeholder.png";
import "./ImageUploader2.scss";
import { IconJsxer } from '../../../helpers/IconHelper';

export const UPLOADER_PREVIEW_SIDE = {left: 'left', top: 'top'};

/**
 * Video uploader component.
 * Select an video to upload and see an immediate preview.
 * Optionally upload the video immediately or return the selected file to a callback so it can be uploaded as part of a larger form.
 * 
 * PROPS (mostly self explnatory, but some may need a little extra explanation, all are optional and have defaults / warnings if recommended):
 * 
 * label = A label for your uploader
 * previewSide = 'left' or 'top'
 * previewWidth / previewHeight = The width and height of your preview
 * uploadBtnLabel = Set the label of the button
 * showRemoveOption = Optionally show the remove video option, if you are using this as part of a larger form you might leave this off
 * removeBtnLabel = The label for the remove button
 * uploadImmediately = true / false. If true then the uploader will attempt to upload your video to a provided endPoint as soon as it is selected.
 * endPoint = This is your endpoint for immediate uploads, for profile pics this is uploadProfilePicRoute from api/routes.js
 * removeEndPoint = Only really needed for immediate uploads, an endpoint to call to remove videos from the server / db, see removeProfilePicRoute in api/routes.js
 * id = An id for your video, when set to uploadImmediately the id will be passed to the endPoint otherwise it is passed to the callbacks detailed below
 * extraData = Optional extra data passed to your endpoint. If this is an object then each property is extracted and passed up alongside your id
 * 
 * -- Only used when uploadImmediately=false --
 * fileSelectedCallback = A callback that receives and object in this format: {id: id, file: file, originalEvent: e}, 
 *                        this is a different signature to the other callbacks simply because you will most likely need all the data that gets passed back
 *                        (you will probably need to store the selected video data somewhere ready for submitting to your endpoint),
 *                        it fires when a file is selected just like it would if you used <input type="file"> directly instead of this component but with
 *                        a little bit more data included.
 *                        When removing the video you will receive: {id: id, file: null, originalEvent: 'removed'}, allowing you to remove the video from your stored form data.
 * 
 * -- Only used when uploadImmediately=true --
 * uploadedCallback = A callback that receives (res, file, id), you can probably ignore most params, but When uploading profile pics, I use it to set the profile pic
 *                      in authcontext userData.profilePic = res.data.uploadedFilename; so we can immediately start showing the new profile pic without asking the api for it.
 * 
 * -- Only used when uploadImmediately=true --
 * uploadErrorCallback = A callback you can use to deal with upload errors, but you probably won't need it...
 * 
 * -- Only used when uploadImmediately=true, if false you should use a fileSelected callback instead --
 * removedCallback = A callback you can use to detect when an video is removed.
 *                      I use it when a user removes their profile video to set the profile video data on authcontext as with uploadedCallback
 * 
 * Hopefully that's all clear! :) - Carl
 * 
 * @param {*} props 
 * @returns The component
 */
const ImageUploader2 = (props) => {
  const { showLabel, label, labelNote, previewSide, previewWidth, previewHeight, previewOnly, previewBgColor, showZoomOption, zoomFunction, showRemoveOption, showUploadOption, uploadImmediately, endPoint, removeEndPoint, id, extraData, fileSelectedCallback, uploadedCallback, uploadErrorCallback, removedCallback } = props;
  let currentFile = props.currentFile;
console.log(currentFile)
  // Warn about missing props...
  if (uploadImmediately && endPoint === '') {
    DebugLogger.error('No endPoint provided to Video Uploader, you should provide one if uploadImmediately=true.');
  }
  if (!uploadImmediately && !previewOnly && fileSelectedCallback === null) {
    DebugLogger.warn('No fileSelectedCallback provided to Video Uploader, you should provide one if uploadImmediately=false.');
  }
  if (showRemoveOption && uploadImmediately && removeEndPoint === '') {
    DebugLogger.error('No removeEndPoint provided to Video Uploader, you should provide one if uploadImmediately=true and showRemoveOption=true.');
  }
  if (showRemoveOption && uploadImmediately && removedCallback === null) {
    DebugLogger.warn('You should provide a removedCallback when using uploadImmediately=true and showRemoveOption=true.');
  }
  if (!previewOnly && showZoomOption && zoomFunction === null) {
    DebugLogger.warn('You should provide a zoomFunction when using showZoomOption=true, it should accept am image url.');
  }

  const [busy, setBusy] = useState(false);
  const [previewSrc, setPreviewSrc] = useState(currentFile || null);
  const fileInputRef = useRef(null);

  useEffect(
    () => {
      setPreviewSrc(null);
      setTimeout(
        () => setPreviewSrc(currentFile),
        100
      )
    },
    [props.currentFile]
  )

  const chooseFile = () => {if (!busy) fileInputRef.current.click();};
  const [loaded, setLoaded] = React.useState(false)
  const [failed, setFailed] = React.useState(false)

  const handleFile = (e) => {
    e.preventDefault();
    console.log('handleFile', e.target.files);
    if (!busy) {
      if (e.target.files.length > 0) {
        const file = e.target.files[0];
        setPreviewSrc(null);
        setTimeout(
          () => setPreviewSrc(URL.createObjectURL(file)),
          100
        );
        if (uploadImmediately) {
          DebugLogger.log('Attempting to upload video: ', file);
          if (endPoint) {
            const formData = new FormData();
            formData.append('video', file);
            if (id) {
              formData.append('id', id);
            } else {
              DebugLogger.warn('No id provided for video upload.')
            }
            if (extraData) {
              if (typeof extraData === 'string' || typeof extraData === 'number' || typeof extraData === 'boolean') {
                formData.append('extraData', extraData);
              } else {
                for (let prop in extraData) {
                  formData.append(prop, extraData[prop]);
                }
              }
            }
            setBusy(true);

            axios.post(endPoint, formData, { withCredentials: true })
              .then(res => {
                DebugLogger.log('Upload result: ', res);
                setBusy(false);
                if (uploadedCallback) {
                  uploadedCallback(res, file, id);
                }
              })
              .catch(err => {
                DebugLogger.log('Upload error: ', err);
                setBusy(false);
                if (uploadErrorCallback) {
                  uploadErrorCallback(err, id);
                }
              });
          } else {
            DebugLogger.error('Could not upload video, no endPoint provided.');
          }
        } else {
          if (fileSelectedCallback) {
            fileSelectedCallback({id: id, file: file, originalEvent: e});
          }
        }
      }
    }
  }

  const handleRemove = (e) => {
    e.preventDefault();
    if (!busy) {
      if (uploadImmediately) {
        DebugLogger.log('Attempting to remove video...');
        if (removeEndPoint) {
          const formData = new FormData();
          if (id) {
            formData.append('id', id);
          } else {
            DebugLogger.warn('No id provided for video removal.')
          }
          if (extraData) {
            if (typeof extraData === 'string' || typeof extraData === 'number' || typeof extraData === 'boolean') {
              formData.append('extraData', extraData);
            } else {
              for (let prop in extraData) {
                formData.append(prop, extraData[prop]);
              }
            }
          }
          setBusy(true);

          axios.post(removeEndPoint, formData)
            .then(res => {
              DebugLogger.log('Remove video result: ', res);
              setBusy(false);
              fileInputRef.current.value = '';
              setPreviewSrc(null);
              currentFile = null;
              if (removedCallback) {
                removedCallback(id);
              }
            })
            .catch(err => {
              DebugLogger.log('Remove video error: ', err);
              setBusy(false);
            });
        } else {
          DebugLogger.error('Could not remove video, no removeEndPoint provided.');
        }
      } else {
        fileInputRef.current.value = '';
        setPreviewSrc(null);
        currentFile = null;
        if (fileSelectedCallback) {
          fileSelectedCallback({id: id, file: null, originalEvent: 'removed'});
        }
        if (removedCallback) {
          removedCallback(id);
        }
      }
    }
  }

  const handleZoom = () => {
    if (props.zoomFunction) {
      props.zoomFunction(previewSrc);
    }
  }

const load =(src)=>  {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', resolve);
        image.addEventListener('error', reject);
        image.src = src;
        image.crossOrigin = 'anonymous';
    });
}

const getCSSImage = (srcURL) =>{

  if(!srcURL) return;
  if(srcURL && srcURL.startsWith('blob:') && !loaded)
  {
    setLoaded(true)
    return;
  }

  load(srcURL).then(() =>{
    setLoaded(true);
    setFailed(false);
  }).catch(() => {
    setFailed(true);
  })
}

useEffect(() =>{console.log('SRC URL:', previewSrc); getCSSImage(previewSrc)}, [previewSrc])

  return (
    <div className="image-upload-holder-2">
        {showLabel && label &&
          <div className="label">
            {label}
          </div>
        }
        {showLabel && labelNote != null && labelNote !== '' &&
          <div className="form-field-content label-note" style={{maxWidth: previewWidth + 100}} dangerouslySetInnerHTML={{__html: labelNote}}>
            {/*labelNote*/}
          </div>
        }
        <div className={'content' + (previewSide === UPLOADER_PREVIEW_SIDE.top ? ' thumb-top' : ' thumb-left')}>
          <div className={`thumb-holder ${props.leftJustified ? 'leftAlign' : ''}`} style={{minWidth: previewWidth, minHeight: previewHeight}}>
            {previewSrc &&
              <div className='file-holder' style={{minWidth: previewWidth, minHeight: previewHeight, maxWidth: previewWidth, maxHeight: previewHeight, backgroundColor: previewOnly ? previewBgColor : ''}}>
                  {!loaded && !failed && <> <img src={assets.LoadingGif} crossOrigin="anonymous" alt="" /></>}
                  {loaded  &&  <><div className="thumb-img" alt="" style={{minWidth: previewWidth*.9, minHeight: previewHeight*.9, backgroundImage: `url(${previewSrc})`}} /></>}
                  {failed  && <>{IconJsxer.GetIcon(IconJsxer.ICONS.notSetAcIcon, IconJsxer.ICON_STYLES.campaignLargeBtn)}</>}
            
              </div>
            }
            {previewSrc === null &&
              <div className='file-holder' style={{minWidth: previewWidth, minHeight: previewHeight, maxWidth: previewWidth, maxHeight: previewHeight, backgroundColor: previewOnly ? previewBgColor : ''}}>
                <img src={assets.UplImgIcon} crossOrigin="anonymous" alt="" />
              </div>
            }
          </div>

          {!previewOnly &&
            <div className={`options-holder${previewSide === UPLOADER_PREVIEW_SIDE.left ? ' v-opt' : ''}`}>
              <div className='grow'></div>
              {showUploadOption && 
                <div className={`option${busy ? ' opt-off' : ' button-active'}`} onClick={busy ? null : chooseFile}>
                  {IconJsxer.GetIcon(IconJsxer.ICONS.upload, IconJsxer.ICON_STYLES.uploaderBtn)}
                </div>
              }
              {showZoomOption && zoomFunction &&
                <div className={`option${(busy || previewSrc === null) ? ' opt-off' : ' button-active'}`} onClick={busy ? null : handleZoom}>
                  {IconJsxer.GetIcon(IconJsxer.ICONS.magnify, IconJsxer.ICON_STYLES.uploaderBtn)}
                </div>
              }
              {showRemoveOption &&
                <div className={`option${(busy || previewSrc === null) ? ' opt-off' : ' button-active'}`} onClick={busy ? null : handleRemove}>
                  {IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.uploaderBtn)}
                </div>
              }
              <div className='grow'></div>
              <input
                type="file"
                accept=".png, .jpg, .jpeg, .gif"
                name="video"
                onChange={handleFile}
                hidden
                ref={fileInputRef}
                id={id}
              />
            </div>
          }

        </div>
    </div>
  );
}

ImageUploader2.propTypes = {
  showLabel: PropTypes.bool,
  label: PropTypes.string,
  showZoomOption: PropTypes.bool,
  zoomFunction: PropTypes.func,
  previewSide: PropTypes.string,
  previewWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  previewHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currentFile: PropTypes.any,
  previewOnly: PropTypes.bool,
  previewBgColor: PropTypes.string,
  uploadBtnLabel: PropTypes.string,
  showRemoveOption: PropTypes.bool,
  showUploadOption: PropTypes.bool,
  removeBtnLabel: PropTypes.string,
  endPoint: PropTypes.string,
  removeEndPoint: PropTypes.string,
  id: PropTypes.string,
  extraData: PropTypes.any,
  uploadImmediately: PropTypes.bool,
  fileSelectedCallback: PropTypes.func,
  uploadedCallback: PropTypes.func,
  uploadErrorCallback: PropTypes.func,
  removedCallback: PropTypes.func,
};

ImageUploader2.defaultProps = {
  showLabel: false,
  label: 'Upload Video',
  showZoomOption: false,
  zoomFunction: null,
  previewSide: UPLOADER_PREVIEW_SIDE.top,
  previewWidth: 160,
  previewHeight: 160,
  currentFile: '',
  previewOnly: false,
  previewBgColor: '#DEF9FF',
  uploadBtnLabel: 'Upload',
  showRemoveOption: true,
  showUploadOption: true,
  removeBtnLabel: 'Remove',
  endPoint: '',
  removeEndPoint: '',
  id: '',
  extraData: null,
  uploadImmediately: false,
  fileSelectedCallback: null,
  uploadedCallback: null,
  uploadErrorCallback: null,
  removedCallback: null,
}

export default ImageUploader2; 
