import "./OneBotOverview.scss";
import React, { useContext } from "react";
import PropTypes from 'prop-types';
import { BOT_TYPES, getBotTypeDataFromDbString } from "../../helpers/Bots";
import { convertSecondsToHMS } from "../../helpers/Dates";
import { IconJsxer } from "../../helpers/IconHelper";
import HeadedPanel from "../HeadedPanel/HeadedPanel"
import LeftColorBlockBox from "../LeftColorBlockBox/LeftColorBlockBox";
import LeftColorBlockHeadedPanel from "../LeftColorBlockBox/LeftColorBlockHeadedPanel";
import { AuthContext } from "../../contexts/AuthContext";
import { ACCOUNT_TYPES } from "../../helpers/AccountTypes";

const OneBotOverview = (props) => {
    const [authContext, setAuthContext] = useContext(AuthContext);
    const bot = props.bot;
    // Convert any stringed bot copy into and array. I believe this is the only place this will ever need doing!
    if (typeof bot.botCopy === 'string') {
        const botCopyArray = [bot.botCopy];
        bot.botCopy = botCopyArray;
    }

    const timeHms = convertSecondsToHMS(bot.autoPostTime, true);
    const timeoutHms = convertSecondsToHMS(bot.responseTimeout, true);

    const getBotPauseStatus = (botID) => {
        let isPaused = false;
        if(props.timedBots && Array.isArray(props.timedBots))
        {
            props.timedBots.forEach((timedBot) =>{
                if(timedBot.id == botID) {
                isPaused = timedBot.isPaused
                }
            })
        }
        return isPaused
    }

    const getBotStatusStr = (botID) =>{
        let botStatus = !props.botsAvailable ? false : getBotPauseStatus(botID);
        // One very complicated ternary statement to get the bot status string
        let botStr = !props.botsAvailable ? 'Unavailable' : (bot.botType === BOT_TYPES.manual.dbString || props.streamStarted) ? botStatus ? 'Paused' : bot.botType === BOT_TYPES.manual.dbString ? 'Available' : 'Active' : 'Inactive';
        return (<>{botStr}</>)
    }
    const getBotTime = (botID) =>{
        let botTime = ''
        if(props.timedBots && Array.isArray(props.timedBots))
        {
            props.timedBots.forEach((timedBot) =>{
                if(timedBot.id == botID)
                {
                    let timeLeft =  Math.round((timedBot.postTime - props.streamLiveTime.rawTime) /1000)
                    if(timedBot.isPaused) timeLeft +=  Math.round((Date.now() - timedBot.pauseTime)/1000);
                    let timeStr = convertSecondsToHMS(timeLeft)
                    botTime = timeStr.h +'h '+ timeStr.m_str +'m '+ timeStr.s_str +'s'  
                }
            })
        }
        return (<>{botTime}</>)
    }

    return (
        <div className='no-panel-shadow'>
            {(!props.editMode && !props.controlMode) &&
                <LeftColorBlockBox style={'bot_' + bot.botType} overrideColor={bot.archived ? 'bot_archived' : null}>
                    <div className='fl-row list-view-content bot-content'>
                        <div>
                            {bot.name}
                        
                            {(bot.botType === BOT_TYPES.auto.dbString) &&
                                <span className={`bot-time-dark`}>
                                    &nbsp;(Post at {timeHms.h}h {timeHms.m_str}m {timeHms.s_str}s)
                                </span>
                            }
                            {(bot.botType === BOT_TYPES.interval.dbString) &&
                                <span className={`bot-time-dark`}>
                                    &nbsp;(Every {timeHms.h}h {timeHms.m_str}m {timeHms.s_str}s)
                                </span>
                            }
                            {bot.botType === BOT_TYPES.response.dbString &&
                                <span className={`bot-time-dark`}>
                                    &nbsp;(Timeout {timeoutHms.m_str}m {timeoutHms.s_str}s)
                                </span>
                            }

                        </div>

                        <div className="grow"></div>

                        {props.showControls && authContext.userData.userLevel >= ACCOUNT_TYPES.producer.level &&
                            <>
                                <div className={`edit-icon${props._gotLiveStream ? ' not-allowed' : ''}`} onClick={props._gotLiveStream ? null : props.duplicateFunc ? (e) => props.duplicateFunc(bot) : null}>
                                    {IconJsxer.GetIcon(
                                        IconJsxer.ICONS.copy,
                                        IconJsxer.ICON_STYLES.campaignPanelTop,
                                    )}
                                </div>
                                <div className={`edit-icon${props._gotLiveStream ? ' not-allowed' : ''}`} onClick={props._gotLiveStream ? null : props.editFunc ? (e) => props.editFunc(bot) : null}>
                                    {IconJsxer.GetIcon(
                                        IconJsxer.ICONS.edit,
                                        IconJsxer.ICON_STYLES.campaignPanelTop,
                                    )}
                                </div>
                            </>
                        }
                    </div>
                </LeftColorBlockBox>
            }
            {(props.editMode || props.controlMode) &&
                <LeftColorBlockHeadedPanel
                    style={'bot_' + bot.botType}
                    overrideColor={bot.archived ? 'bot_archived' : null}
                    // color={bot.archived ? "archived" : "primary"}
                    headingContent={
                        <div className={`fl-row list-view-content${bot.archived ? '' : ' bot-content'}`}>
                            {/* <div>
                                {IconJsxer.GetIcon(
                                    getBotTypeDataFromDbString(bot.botType).icon,
                                    IconJsxer.ICON_STYLES.campaignPanelTop,
                                )}
                            </div> */}
                            <div>
                                {bot.name}
                            
                                {(bot.botType === BOT_TYPES.auto.dbString) &&
                                    <span className={`bot-time-dark`}>
                                        &nbsp;(Post at {timeHms.h}h {timeHms.m_str}m {timeHms.s_str}s)
                                    </span>
                                }
                                {(bot.botType === BOT_TYPES.interval.dbString) &&
                                    <span className={`bot-time-dark`}>
                                        &nbsp;(Every {timeHms.h}h {timeHms.m_str}m {timeHms.s_str}s)
                                    </span>
                                }
                                {bot.botType === BOT_TYPES.response.dbString &&
                                    <span className={`bot-time-dark`}>
                                        &nbsp;(Timeout {timeoutHms.m_str}m {timeoutHms.s_str}s)
                                    </span>
                                }
                            </div>

                            <div className="grow"></div>

                            {props.showControls &&
                                <>

                                    {!bot.archived && !props.controlMode &&
                                        <>
                                            <div className={`edit-icon${props._gotLiveStream ? ' not-allowed' : ''}`} onClick={props._gotLiveStream ? null : props.duplicateFunc ? (e) => props.duplicateFunc(bot) : null}>
                                                {IconJsxer.GetIcon(
                                                    IconJsxer.ICONS.copy,
                                                    IconJsxer.ICON_STYLES.campaignPanelTop,
                                                )}
                                            </div>
                                            <div className={`edit-icon${props._gotLiveStream ? ' not-allowed' : ''}`} onClick={props._gotLiveStream ? null : props.editFunc ? (e) => props.editFunc(bot) : null}>
                                                {IconJsxer.GetIcon(
                                                    IconJsxer.ICONS.edit,
                                                    IconJsxer.ICON_STYLES.campaignPanelTop,
                                                )}
                                            </div>
                                        </>
                                    }
                                    {props.editMode &&
                                        <>
                                            {!bot.archived &&
                                                <>
                                                    <div className={`edit-icon${props._gotLiveStream ? ' not-allowed' : ''}`} onClick={props._gotLiveStream ? null : props.removeFunc ? (e) => props.removeFunc(bot) : null}>
                                                        {IconJsxer.GetIcon(
                                                            IconJsxer.ICONS.trash,
                                                            IconJsxer.ICON_STYLES.campaignPanelTop,
                                                        )}
                                                    </div>
                                                </>
                                            }
                                            {bot.archived &&
                                                <>
                                                    <div>Archived</div>
                                                    <div className={`edit-icon-gr${props._gotLiveStream ? ' not-allowed' : ''}`} onClick={props._gotLiveStream ? null : props.removeFunc ? (e) => props.removeFunc(bot) : null}>
                                                        {IconJsxer.GetIcon(
                                                            IconJsxer.ICONS.undelete,
                                                            IconJsxer.ICON_STYLES.campaignPanelTop,
                                                        )}
                                                    </div>
                                                </>
                                            }
                                        </>
                                    }
                                    {props.controlMode &&
                                        <div className="control-area">
                                            <div className="control-status">
                                                <div className={`post-count ${getBotPauseStatus(bot.id) ? 'no-posts' : 'some-posts'}`}>
                                                    {props.firedAmount}
                                                </div>
                                                <div className="grow">{getBotStatusStr(bot.id)}</div>
                                                {bot.botType !== BOT_TYPES.manual.dbString &&
                                                    <div className={`control-btn${(!props.botsAvailable || !props.streamStarted) ? ' control-btn-inactive' : ''}`} onClick={props.pauseBotFunc && props.botsAvailable && props.streamStarted ? (e) => props.pauseBotFunc(e, bot) : null}>
                                                        {(getBotPauseStatus(bot.id) || !props.botsAvailable || !props.streamStarted) ? IconJsxer.GetIcon(IconJsxer.ICONS.play, IconJsxer.ICON_STYLES.roundPanelButtonSmall)
                                                            : IconJsxer.GetIcon(IconJsxer.ICONS.pause, IconJsxer.ICON_STYLES.roundPanelButtonSmall)}
                                                    </div>
                                                }
                                            </div>
                                            <div className={`edit-icon-wh activate-bot-btn${!props.botsAvailable ? ' button-inactive' : ''}`} onClick={props.triggerBotFunc && props.botsAvailable ? (e) => props.triggerBotFunc(e, bot) : null}>
                                                {IconJsxer.GetIcon(
                                                    IconJsxer.ICONS.paperPlane,
                                                    IconJsxer.ICON_STYLES.botControlButton,
                                                )}
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    }
                >
                    {bot.botType === BOT_TYPES.response.dbString && 
                        <p className="bold-text bot-copy-heading">Respond to <span className="bot-command">{bot.botCommand}</span></p>
                    }
                    <div>
                        {bot.botCopy.map(
                            (val, i) => {
                                return (
                                    <div className="fl-row bot-copy" key={'bot_copy_' + i}>
                                        <p>{bot.botCopy.length > 0 && <span className="bot-command">{IconJsxer.GetIcon(IconJsxer.ICONS.circle, IconJsxer.ICON_STYLES.bullet)}</span>}{val}</p>
                                        <div className="grow"></div>
                                        {props.controlMode &&
                                            <div className={`edit-icon-wh sm-activate-bot-btn${!props.botsAvailable ? ' button-inactive' : ''}`} onClick={props.triggerBotFunc && props.botsAvailable ? (e) => props.triggerBotFunc(e, bot, i) : null}>
                                                {IconJsxer.GetIcon(IconJsxer.ICONS.paperPlane, IconJsxer.ICON_STYLES.standard)}
                                            </div>
                                        }
                                    </div>
                                )
                            }
                        )}
                    </div>
                </LeftColorBlockHeadedPanel>
            }
        </div>
    )
}

OneBotOverview.propTypes = {
    bot: PropTypes.object,
    controlMode: PropTypes.bool,
    showControls: PropTypes.bool,
    editMode: PropTypes.bool,
    editFunc: PropTypes.func,
    removeFunc: PropTypes.func,
    duplicateFunc: PropTypes.func,
    archived: PropTypes.bool,
    triggerBotFunc: PropTypes.func,
    pauseBotFunc: PropTypes.func,
    unpauseBotFunc: PropTypes.func,
};

OneBotOverview.defaultProps = {
    bot: {},
    controlMode: false,
    showControls: true,
    editMode: false,
    editFunc: null,
    removeFunc: null,
    duplicateFunc: null,
    archived: false,
    triggerBotFunc: null,
    pauseBotFunc: null,
    unpauseBotFunc: null,
}

export default OneBotOverview;
