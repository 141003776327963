import "./Themes.scss";

import React, { useState, useEffect, useContext, useRef } from "react";

import PopUpPanel from "../../../components/PopUpPanel/PopUpPanel";
import { IconJsxer } from "../../../helpers/IconHelper";
import { toast } from "react-toastify";

const ThemeListPopup = (props) => {
    const createNewTheme = () => {
        if (props.createNewTheme) {
            props.createNewTheme();
        }
    }

    const deleteTheme = (theme) => {
        if (props.deleteTheme) {
            props.deleteTheme(theme.index);
        }
    }

    const editTheme = (theme) => {
        if (props.editTheme) {
            props.editTheme(theme);
        }
    }

    useEffect(() => {
        console.log('Theme list popup props: ', props);
    }, []);

    return (
        <PopUpPanel closePanelFunc={props.closePanelFunc} className="themes-narrow-panel">
            <div className="theme-list">
                <div className="fl-column">
                    <h2>Themes</h2>

                    {props.themes && props.themes.length > 0 &&
                        <div className="theme-list-scroll">

                            {props.themes.map((theme, index) => {
                                return (
                                    <div key={index} className={`list-item ${index % 2 === 0 ? 'li-light' : 'li-dark'}`}>
                                            <div className="grow"><h4>{theme.themeName}</h4></div>
                                            <div className="fl-row shrink">
                                                <div className="edit-icon" onClick={() => editTheme(theme)}>{IconJsxer.GetIcon(IconJsxer.ICONS.edit, IconJsxer.ICON_STYLES.campaignPanelTop)}</div>
                                                <div className="edit-icon" onClick={() => deleteTheme(theme)}>{IconJsxer.GetIcon(IconJsxer.ICONS.trash, IconJsxer.ICON_STYLES.campaignPanelTop)}</div>
                                            </div>
                                    </div>
                                )
                            })}

                        </div>
                    }
                    {(!props.themes || props.themes.length === 0) &&
                        <div className="no-themes">
                            <p>No themes found.</p>
                        </div>
}
                </div>

            </div>

            <div className="fl-row">
                <button className="standard-button" onClick={createNewTheme}>Create New Theme</button>
            </div>
        </PopUpPanel>
    )
}

export default ThemeListPopup;
