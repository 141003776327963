import React, { useState } from "react";

import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import { toast } from "react-toastify";

const ConfirmRemoveFeature = (props) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const removeNow = (e) => {
        e.preventDefault();
        props.removeFeature(props.feature, removeCallback);
    }

    const clearChain = () => {
        // Don't remove any features / disruptors from the campaign as they could be used elsewhere, cleanup happens somewhere else
        /* if (props.chain.length > 0) {
            const featureId = props.chain.pop();
            let featureData = null;
            for (let i = 0; i < props._editedCampaignData.features.length; i++) {
                if (props._editedCampaignData.features[i]._id === featureId) {
                    featureData = props._editedCampaignData.features[i];
                }
            }
            if (featureData) {
                props.removeFeature(featureData, clearChain);
            }
        } else { */
            // We have remove them all!
            let chains = props._editedCampaignData.chains;
            chains.splice(chains.indexOf(props.chain), 1);
            props._changeEditedCampaignData('chains', props._editedCampaignData.chains);
            props._saveEditedCampaignData(null);
            props.closePanelFunc();
        // }
    }

    const removeCallback = (success) => {
        if (success) {
            if (props.chain) {
                // we are going to update the chain, possibly even remove all the features in it
                if (props.feature.feature.featureType === 'feature') {
                    // remove everything else from the chain and remove the chain
                    props.chain.splice(props.chain.indexOf(props.feature._id), 1);
                    clearChain();
                } else {
                    // update the chain
                    console.log('Update the chain! ', props.chain, props.feature._id);
                    while (props.chain.indexOf(props.feature._id) >= 0) {
                        props.chain.splice(props.chain.indexOf(props.feature._id), 1);
                    }
                    props._changeEditedCampaignData('chains', props._editedCampaignData.chains);
                    props._saveEditedCampaignData(null);
                    props.closePanelFunc();
                }
            } else {
                toast.success("Campaign Updated!");
                props.closePanelFunc();
            }
        } else {
            setIsSubmitting(false);
        }
    }

    let typeString = props.feature.feature.featureType === 'feature' ? 'Feature' : 'Disruptor';
    if (props.chain && typeString === 'Disruptor') {
        if (props.chain.indexOf(props.feature._id) === 0) {
            typeString = 'Intro';
        } else {
            typeString = 'Outro';
        }
    }

    return (
        <PopUpPanel closePanelFunc={props.closePanelFunc}>
            <div className="form-holder co-popup-content">
                <form id="confirmRemoveFeature" className="form">
                    <h2>Remove This {typeString}?</h2>

                    <div className="drop-gap">
                        <h4>{props.feature.contentLabel} ({props.feature.feature.featureName})</h4>
                    </div>

                    {props.feature.feature.featureType === 'feature' &&
                        <div><p>This will also remove your Intro and Outro!</p></div>
                    }

                    <button className={'submit-btn' + (isSubmitting ? ' button-inactive' : ' button-active')} onClick={isSubmitting ? null : removeNow}>
                        {isSubmitting ? "Removing" : "Remove"}
                    </button>
                </form>
            </div>
        </PopUpPanel>
    )
}

export default ConfirmRemoveFeature;
