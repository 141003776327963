import "./Users.scss";
import { useContext, useEffect, useState, useReducer} from "react";
import DebugLogger from "../../helpers/DebugLogger";
import ListedUser from "./ListedUser";
import { IconJsxer } from "../../helpers/IconHelper";
import axios from "../../api/axios";
import { userListAllUsersRoute, changeUserAuthorisationRoute} from "../../api/routes";
import { toast } from "react-toastify";
import EditUser from "./EditUser";
import UserCampaignList from "./UserCampaignList";
import DropDownList from "../../components/forms/DropDownList/DropDownList";
import { ACCOUNT_TYPES, getAcDropListData, getAcTypeData } from "../../helpers/AccountTypes";
import SearchBox from "../../components/forms/SearchBox/SearchBox";
import ChangeUserType from "./ChangeUserType";
import { AuthContext } from "../../contexts/AuthContext";
import { alphaTypePrimer, filterList, numberTypePrimer, searchList, sortListOn, toStringPrimer } from "../../helpers/ListFunctions";
import InviteUser from "../../components/InviteUserPopup/InviteUser";
import ReactPaginate from 'react-paginate';
const Users = () => {
    const [authContext, setAuthContext] = useContext(AuthContext);
    const acSettings = getAcTypeData(authContext.userData.accountType);
    const maxDeauthLevel = acSettings.adminSettings.maxDeauthLevel;
    const maxChangeLevel = acSettings.adminSettings.maxChangeLevel;

    const SORT_TYPES = {
        NAME:'name',
        EMAIL:'email',
        CAMPAIGNS:'campaigns',
        ACCOUNT_TYPE:'acType',
        AUTHORISED:'authorised',
        CREATED:'dateCreated',
    }
    const [allUserData, setAllUserData] =  useState([]);
   
    const [displayUserData, setDisplayUserData] =  useState([]);
    const [filteredDisplayList, setFilteredDisplayList] =  useState([]);
    const [editingUser, setEditingUser] = useState(null);
    const [showUserCampaigns, setShowUserCampaigns] = useState(null);
    const [showChangeUserType, setShowChangeUserType] = useState(null);
    const [showInviteUserPanel, setShowInviteUserPanel] = useState(null);
    const [userTypeFilter, setUserTypeFilter] = useState(null);
    const [authorisationFilter, setAuthorisationFilter] = useState(null);
    const [sortListReverse, setSortListReverse] = useState(true);
    const [currentSort, setCurrentSort] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [pageUpdate, forcePageUpdate] = useReducer(x => x + 1, 0);
    const [fetchUpdate, forceFetchUpdate] = useReducer(x => x + 1, 0);
    const [currentPage, setCurrentPage] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const handlePageClick = (event) => {
        setCurrentPage(event.selected)
      };
    const fetchUserList = () => {
        axios
        .get(userListAllUsersRoute, {
            params:{
                page:currentPage, 
                searchTerm:searchTerm, 
                userType:userTypeFilter, 
                authType:authorisationFilter, 
                sort:currentSort,
                reverse:sortListReverse
            },
            withCredentials: true})
        .then((response) => {
            console.log('USERS LOADED', response.data.numberOfPages)
            setAllUserData(response.data.allusers);
            setDisplayUserData(response.data.allusers);
            setNumberOfPages(response.data.numberOfPages);
           
        })
        .catch((error) => {
          //
          //toast.error(error.response.data.msg);
        });
    }
    useEffect(() => {
        console.log('GET PAGE SORT')
 
        forcePageUpdate();
    }, [searchTerm, currentSort, sortListReverse, authorisationFilter, userTypeFilter])
    useEffect(() => {
        console.log('GET PAGE CP')
       fetchUserList();
    }, [ currentPage, fetchUpdate])
   
    const sortList = (event, type) => {

        event.preventDefault();
        let reverse = !sortListReverse;
        console.log(reverse)
        setSortListReverse(reverse);
        setCurrentSort(type)
    }

    const searchUserList = (e, searchTerm) => {
        setSearchTerm(searchTerm);
    }

    const refreshUserList = () => {
        searchUserList(null, searchTerm);
    }

    useEffect(() => {
        fetchUserList();

      }, [])

    const toggleAuthHandler = (event, id) => {
        //console.log(id, event)
        event.preventDefault()
        axios
        .post(changeUserAuthorisationRoute, {id:id, withCrednetials:true})
        .then((response) => {
            let modifiedUsersName = ''
            let deAuthorised = true;
            let tempUserArray = allUserData.map(user => {
                if(user._id === id)
                {
                    modifiedUsersName = user.name
                    deAuthorised = user.authorised
                    return {...user, authorised:!user.authorised}
                }else
                return user
            })
            setAllUserData(tempUserArray);
            
            let tempDisplayUserArray = displayUserData.map(user => {
                if(user._id === id)
                {
                    modifiedUsersName = user.name
                    deAuthorised = user.authorised
                    return {...user, authorised:!user.authorised}
                }else
                return user
            })
            setDisplayUserData(tempDisplayUserArray);


            deAuthorised ? toast.success(modifiedUsersName+" has been successfuly disabled") : toast.success(modifiedUsersName+" has been successfuly enabled") ;
        })
        .catch ((err) => {
            toast.error("Oops something went wrong, try again");
        })
    }

    const deleteUserHandler = (event, id) => {
        DebugLogger.attention('Delete user', id);
    }

    const editUserHandler = (event, id, userData) => {
        setEditingUser(userData);
    }

    const cancelEditingUser = (event) => {
        setEditingUser(null);
    }

    const openUserCampaigns = (event, id, userData) => {
        setShowUserCampaigns(userData);
    }

    const closeUserCampaigns = (event) => {
        setShowUserCampaigns(null);
    }

    const addUserHandler = (e) => {
        setShowInviteUserPanel(true);
    }

    const closeInviteUserPanel = (e) => {
        setShowInviteUserPanel(false);
    }

    const changeUserTypeHandler = (e, id, userData) => {
        setShowChangeUserType(userData);
    }

    const closeChangeUserType = (event) => {
        setShowChangeUserType(null);
    }

    const updateFilterHandler = (e, dropDownId, item) => {
        switch (dropDownId) {
            case 'usertype':
                setUserTypeFilter(item);
                break;
            case 'authorised':
                setAuthorisationFilter(item);
                break;
            default:
                break;
        }
    }



    useEffect(() => {
        console.log('PAGE DATA UPDATED')
        currentPage == 0 ? forceFetchUpdate() : setCurrentPage(0);
       
    }, [numberOfPages, pageUpdate])

    return (
        <div className="content-page-container">
            <div className="content-page-top">
                <div className="fl-column grow">
                    <div className="fl-row grow">
                        <div>
                            <h1>Users Overview</h1>
                        </div>
                        <div className="grow"></div>
                        <div className="top-search">
                            <SearchBox searchFunc={searchUserList} />
                        </div>
                    </div>
                
                <div className="filters-container form-holder">
                            <DropDownList 
                                id="usertype"
                                selectFunc={updateFilterHandler}
                                currentIndex={userTypeFilter ? userTypeFilter.index : 0}
                                deselectedLabel="User Type"
                                icon="oneUser"
                                items={
                                    getAcDropListData(
                                        [{   
                                            id: 'all',
                                            label: 'All User Types',
                                            deselect: true,
                                        }])
                                }
                                className="filter"
                            />
                            <DropDownList 
                                id="authorised" 
                                selectFunc={updateFilterHandler}
                                currentIndex={authorisationFilter ? authorisationFilter.index : 0}
                                deselectedLabel="Authorisation"
                                icon="userAuth"
                                items={
                                    [
                                        {
                                            id: 'all',
                                            label: 'See All',
                                            deselect: true,
                                        },
                                        {
                                            id: 'true',
                                            label: 'Authorised',
                                        },
                                        {
                                            id: 'false',
                                            label: 'Not Authorised',
                                        },
                                    ]
                                }
                                className="filter"
                            />
                            {searchTerm !== '' && <div className="light-text">Searched for: {searchTerm} ({displayUserData.length} result{displayUserData.length > 1 ? 's' : ''}) <span className="small-text">[<a href="#" onClick={(e) => {e.preventDefault(); searchUserList(e, '');}}>Clear Search</a>]</span></div>}
                        </div>
                        </div>
            </div>
            <div className="content-page-content-container">
                <div className="content-page-main-content">

                    <div className="users-content">
                        
                        

                        <div className="users-panel">
                            <div className="add-btn" onClick={addUserHandler}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.plus, IconJsxer.ICON_STYLES.roundPanelButtonSmall)}
                            </div>
                            <div className="scroll-container">
                            <div className="grid">
                                <div className="header label">&nbsp;</div>
                                <div className="header label" href='#' onClick={(event) => {sortList(event, SORT_TYPES.EMAIL)}}>Email {currentSort === SORT_TYPES.EMAIL ? (IconJsxer.GetIcon(sortListReverse ? IconJsxer.ICONS.chevronUp : IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.roundFormControl)) : ''}</div>
                                <div className="header label" href='#'onClick={(event) => {sortList(event, SORT_TYPES.NAME)}}>Display name {currentSort === SORT_TYPES.NAME ? (IconJsxer.GetIcon(sortListReverse ? IconJsxer.ICONS.chevronUp : IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.roundFormControl)) : ''}</div>
                                <div className="header centered label" href='#' onClick={(event) => {sortList(event, SORT_TYPES.ACCOUNT_TYPE)}}>User Type {currentSort === SORT_TYPES.ACCOUNT_TYPE ? (IconJsxer.GetIcon(sortListReverse ? IconJsxer.ICONS.chevronUp : IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.roundFormControl)) : ''}</div>
                                <div className="header centered label" href='#' onClick={null/*(event) => {sortList(event, SORT_TYPES.CAMPAIGNS)}*/}>Campaigns {currentSort === SORT_TYPES.CAMPAIGNS ? (IconJsxer.GetIcon(sortListReverse ? IconJsxer.ICONS.chevronUp : IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.roundFormControl)) : ''}</div>
                                <div className="header centered label" href='#' onClick={(event) => {sortList(event, SORT_TYPES.AUTHORISED)}}>Authorised? {currentSort === SORT_TYPES.AUTHORISED ? (IconJsxer.GetIcon(sortListReverse ? IconJsxer.ICONS.chevronUp : IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.roundFormControl)) : ''}</div>
                                <div className="header centered label" href='#' onClick={(event) => {sortList(event, SORT_TYPES.CREATED)}}>Member Since {currentSort === SORT_TYPES.CREATED ? (IconJsxer.GetIcon(sortListReverse ? IconJsxer.ICONS.chevronUp : IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.roundFormControl)) : ''}</div>
                                <div className="header centered label">More</div>
                                <hr />
                                    {
                                        displayUserData.map(
                                            (val, i) => {
                                                return (
                                                    <ListedUser user={val} maxChangeLevel={maxChangeLevel} maxDeauthLevel={maxDeauthLevel} toggleAuthorisedFunc={toggleAuthHandler} editUserFunc={editUserHandler} openUserCampaignsFunc={openUserCampaigns} changeUserTypeFunc={changeUserTypeHandler} key={`user_${i}`} />
                                                );
                                            }
                                        )
                                    }

                            </div>
                            </div>
                        </div>
                        <ReactPaginate
                        activeClassName={'pagination-item item-active '}
                        breakClassName={'pagination-item break-me '}
                        breakLabel={'...'}
                        containerClassName={'pagination'}
                        disabledClassName={'disabled-page'}
                        marginPagesDisplayed={2}
                        nextClassName={"pagination-item  "}
                        nextLabel={IconJsxer.GetIcon(IconJsxer.ICONS.chevronRight, IconJsxer.ICON_STYLES.settingsEdit)}
                        onPageChange={handlePageClick}
                        pageCount={numberOfPages}
                        forcePage={currentPage}
                        pageClassName={'pagination-item pagination-page '}
                        pageRangeDisplayed={2}
                        previousClassName={"pagination-item  "}
                        previousLabel={IconJsxer.GetIcon(IconJsxer.ICONS.chevronLeft, IconJsxer.ICON_STYLES.settingsEdit)}
                        renderOnZeroPageCount={false}
                    />

                    </div>

                </div>
            </div>
            {editingUser !== null &&
                <EditUser user={editingUser} closePanelFunc={cancelEditingUser} />
            }
            {showUserCampaigns &&
                <UserCampaignList user={showUserCampaigns} closePanelFunc={closeUserCampaigns} />
            }
            {showChangeUserType &&
                <ChangeUserType user={showChangeUserType} loggedInUser={authContext.userData} maxChangeLevel={maxChangeLevel} closePanelFunc={closeChangeUserType} refreshUserListFunc={refreshUserList} />
            }
            {showInviteUserPanel &&
                <InviteUser closePanelFunc={closeInviteUserPanel} />
            }
        </div>
    );
}

export default Users;
