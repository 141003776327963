import { useEffect, useRef, useState } from "react";
import FormDropDown from "../../../forms/DropDownList/FormDropDown";
import "./ThemeSelector.scss";

const ThemeSelector = (props) => {
    const [lastAppliedThemeId, setLastAppliedThemeId] = useState('');
    const [currentThemeId, setCurrentThemeId] = useState('');
    const [currentThemeIndex, setCurrentThemeIndex] = useState(0);
    const themeListData_ref = useRef(null);
    const [themeListData, setThemeListData] = useState([{id: 'default', label: 'Default Theme', value: ''}]);

    useEffect(() => {
        themeListData_ref.current = [{id: 'default', label: 'Default Theme', value: ''}];
        props.themeData.forEach(theme => {
            if (theme.featureId !== props.featureId) return;
            themeListData_ref.current.push(
                {
                    id: theme._id,
                    label: theme.themeName,
                    value: theme._id
                }
            );
            if (props.initialTheme === theme._id) {
                setCurrentThemeId(theme._id);
                setLastAppliedThemeId(theme._id);
                setCurrentThemeIndex(themeListData_ref.current.length - 1);
            }
        });
        setThemeListData(themeListData_ref.current);
    }, [props.themeData, props.featureId, props.initialTheme]);

    return (
        <div className="fl-column">
            <FormDropDown
                label='Select Theme'
                items={themeListData}
                value={currentThemeId}
                selectFunc={(e, id, item) => {setCurrentThemeId(item.value); setCurrentThemeIndex(item.index)}}
                currentIndex={currentThemeIndex}
                showErrors={false}
            />
            <div className="info-text">
                Applying a theme will cause the feature / disruptor to reload.
            </div>
            <div className="button-grid">
                <button 
                    className={`standard-button ${currentThemeId === lastAppliedThemeId ? ' not-allowed' : 'live-but-green'}`} 
                    onClick={
                        currentThemeId === lastAppliedThemeId ? null 
                        : () => {
                            console.log('Apply Theme');
                            setLastAppliedThemeId(currentThemeId);
                            if (props.changeVal) {
                                props.changeVal('theme', 'id', currentThemeId);
                            }
                            if (props.applyThemeFunc) {
                                props.applyThemeFunc(props.featureId, currentThemeId);
                            }
                        }
                    }
                >Apply Theme</button>
            </div>
        </div>
    )
}

export default ThemeSelector;
