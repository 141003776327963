import React, { useState, useEffect, useContext } from "react";
//import PropTypes from 'prop-types'; 
import validator from "validator";

import axios from "../../api/axios";

import DebugLogger from "../../helpers/DebugLogger";
import { toast } from "react-toastify";
import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import FormField from "../../components/forms/FormField/FormField";
//import RadioButtonGroup from "../../components/forms/RadioButtonGroup/RadioButtonGroup";
import TextArea from "../../components/forms/TextArea/TextArea";
import HMSTimeField from "../../components/forms/HMSTimeField/HMSTimeField";
import { IconJsxer } from "../../helpers/IconHelper";

import "./EditQuiz.scss";

const EditQuizRound = (props) => {
  
  let round = props.quiz.rounds[props.roundIndex];
  let roundId = "round" + props.roundIndex;
  let roundNameId = "_name"
  let roundQuestionTimeId = "_questionTime";

    const [roundTitle, setRoundTitle] = useState('')
    const [roundTime, setRoundTime] = useState(0)

    useEffect(() => {
        setRoundTitle(round.name);
        setRoundTime(round.questionTime)
    }, [])

    const [roundTitleError, setRoundTitleError] = useState('');
    const [roundTimeError, setRoundTimeError] = useState('');
    const validateRound = () => {
        let isValid = true;
        if (!roundTitle || roundTitle.length === 0) {
            setRoundTitleError("Round Name is required");
            isValid = false;
        } else {
            setRoundTitleError("");
        }
        if (roundTime <= 0) {
            setRoundTimeError("Question Time Limit is required");
            isValid = false;
        } else {
            setRoundTimeError("");
        }
        return isValid;
    }

    const save = () => {
        if (!validateRound()) {
            return;
        }

        props.updateQuizState(roundTime, `rounds ${props.roundIndex} questionTime`);
        props.updateQuizState(roundTitle, `rounds ${props.roundIndex} name`);
        props.closePanelFunc();
    }

    return (
        <PopUpPanel closePanelFunc={props.closePanelFunc}>
            <div className="scroll form-holder">
                <h2>{`${props.isNewRound ? 'Create New' : 'Edit'}`} Round</h2>
                <div className="s-row">
                    <div className="column-cell">
                        <FormField id="roundNameField" type="text" name={roundId + roundNameId} label="Round Name" error={roundTitleError} placeholder={"Enter Round Name"}  value={(roundTitle ? roundTitle : "")} setFunc={setRoundTitle}></FormField>
                    </div>
                </div>
                <div className="s-row">
                    <div className="column-cell">
                        <HMSTimeField label="Question Time Limit" error={roundTimeError} showHours={false} showMinutes={true} showSeconds={true} centered={false} value={roundTime || 0} id={roundId + roundQuestionTimeId} setFunc={setRoundTime} />
                        {/* <input name={roundId + roundNameId} defaultValue={(round.questionTime) ? round.questionTime : ""} onChange={(e) => { return updateQuizState(e.target.value, `rounds ${roundIndex} questionTime`)}}></input> */}
                    </div>
                </div>

        
                <div className="s-row">
                    <div className="column-cell">
                        <button  className={'standard-button'} onClick={save}>Update</button>
                    </div>
                </div>
            </div>
        </PopUpPanel>
    );
}

export default EditQuizRound;