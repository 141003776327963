import { useEffect, useState, } from "react";
import { assets } from "../../assets/assets";
import { Link, useLocation } from 'react-router-dom';
import DropDownList from "../../components/forms/DropDownList/DropDownList";
import Toggle from "../../components/forms/Toggle/Toggle";
import ToggleCheck from "../../components/forms/ToggleCheck/ToggleCheck";
import { ACCOUNT_TYPES, getAcTypeData } from "../../helpers/AccountTypes";
import { IconJsxer } from "../../helpers/IconHelper";
import { TOP_LEVEL_NAV_ROUTES } from "../../config/NavRoutes";
import { getQuizTypeDataFromDbString } from "../../helpers/QuizHelper";
import { getShortDateString } from "../../helpers/Dates";

const ListedQuiz = (props) => {
    const { quiz, campaignId, _editMode, _removeQuiz, _extraFirstRow } = props;

    let currentStatusIndex = 0;

    const [ statusColourClass, setStatusColourClass ] = useState("");
  

    const updateColourClasses = () => {

      switch(quiz.status) {
        case "Live":
          setStatusColourClass("live-status-colour");
          break;
        case "Draft":
          setStatusColourClass("draft-status-colour");
          break;
        case "Disabled":
          setStatusColourClass("disabled-status-colour");
          break;
      }
    }

    useEffect(() => { 
      updateColourClasses();  
    },[quiz])

    useEffect(() => {
      updateColourClasses();
    },[]);

    const gridItemClass = "grid-item"/*  + ((statusColourClass && statusColourClass != "") ? " row-" + statusColourClass : "") */;

    let questionCount = 0;
    for(var roundKey in quiz.rounds) {
      if(quiz.rounds[roundKey].questions && quiz.rounds[roundKey].questions.length > 0) {
        questionCount += quiz.rounds[roundKey].questions.length;
      }
    }

    const typeData = getQuizTypeDataFromDbString(quiz.type);

    // console.log('Listed quiz: ', quiz);

    return (
        <>
          {/* _extraFirstRow &&
            <div className={gridItemClass}>             
            </div>
          */}
          <div className={gridItemClass}>
              <div className="name bold-text">{quiz.name}</div>
          </div>
          <div className={gridItemClass}>
            <div className="grow"></div>
            <div className={"ac-pill qu-pill " + typeData.colorCss + "-type-pill-color"}>
              <p>{quiz.type.toUpperCase()}</p>
              {IconJsxer.GetIcon(typeData.icon, IconJsxer.ICON_STYLES.acPillIcon, 'icon')}
            </div>
            <div className="grow"></div>
              {/*<div className="name">
                {quiz.type}
              </div>*/}
          </div>
          
          <div className={gridItemClass}>
            <div className="rounds centered">{quiz.rounds.length}</div>
          </div>
          <div className={gridItemClass}>
            <div className="questions centered">{questionCount}</div>
          </div>

          <div className={gridItemClass}>
              <div className="name"></div>
          </div>

          <div className={gridItemClass}>
            {quiz.campaign === null &&
              <div className="name">None</div>
            }
            {quiz.campaign &&
              <div className="name">
                <Link 
                  to={TOP_LEVEL_NAV_ROUTES.CAMPAIGN_OVERVIEW.replace(":id", quiz.campaign._id)}>
                    {quiz.campaign.campaignName}
                </Link>
                &nbsp;
                <Link 
                  to={TOP_LEVEL_NAV_ROUTES.CAMPAIGN_OVERVIEW.replace(":id", quiz.campaign._id)}>
                    {IconJsxer.GetIcon(IconJsxer.ICONS.link, IconJsxer.ICON_STYLES.standard)}
                </Link> 
              </div>
            }
          </div>
          <div className={gridItemClass}>
            <div className="date centered">{getShortDateString(quiz.dateCreated)}</div>
          </div>
          {/* <div className={gridItemClass}>
            <div className={`status ${statusColourClass}`}>{quiz.status}</div>
          </div> */}
          <div className={gridItemClass}>
            <div className="fl-row">
              <div className="grow"></div>
              { _editMode &&        
                <Link 
                  to={TOP_LEVEL_NAV_ROUTES.EDIT_QUIZ.replace(":id", quiz._id)}
                  state={{ campaignId: campaignId }}>
                  {IconJsxer.GetIcon(
                    IconJsxer.ICONS.edit,
                    IconJsxer.ICON_STYLES.campaignPanelTop,
                  )}
                </Link>
              }
              {/* </div>
              <div className={gridItemClass}> */}
              { _editMode && 
                <div className="button edit-icon" onClick={(e) => { e.preventDefault(); _removeQuiz(quiz);}}>
                  {IconJsxer.GetIcon(
                    IconJsxer.ICONS.trash,
                    IconJsxer.ICON_STYLES.campaignPanelTop
                  )}
                </div>
              }   
            </div>
          </div>
        </>
    );
}

export default ListedQuiz;
