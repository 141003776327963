import PropTypes from 'prop-types';
import DebugLogger from "../../../helpers/DebugLogger";

import "./RadioButtonGroup.scss";
import { useEffect, useRef, useState } from 'react';
import { IconJsxer } from '../../../helpers/IconHelper';

const RadioButtonGroup = (props) => {
    if (props.selectedOption === null) {
        DebugLogger.warn('RadioButtonGroup needs to be controlled by passing in the current value as selectedOption.');
    }

    const optionSelected = (e) => {
        if (props.selectFunc) {
            props.selectFunc(e, props.id, e.target.value);
        }
        if (props.selectFunc === null) {
            DebugLogger.warn('You should provide a prop called selectFunc(e, id, item) to RadioButtonGroup. The component should be controlled by passing in the selectedOption value as a prop. RadioButtonGroup does NOT track it\'s own state!');
        }
    }

    const _expanded = useRef([]);
    const [expanded, setExpanded] = useState([]);
    useEffect(() => {
        while (_expanded.current.length < props.options.length) {
            _expanded.current.push(false);
        }
        setExpanded(_expanded.current);
    }, []);
    const toggleExpandSection = (index) => {
        _expanded.current[index] = !_expanded.current[index];
        const newExpandedArray = [];
        while (newExpandedArray.length < _expanded.current.length) {
            newExpandedArray.push(_expanded.current[newExpandedArray.length]);
        }
        setExpanded(newExpandedArray);
    }

    return (
        <div className={`${props.className}${props.fillWidth ? ' grow' : ''}`}>
            {props.label != null &&
                <div className="form-field-content label">
                    {props.label}
                </div>
            }
            {props.labelNote != null && props.labelNote !== '' &&
                <div className="form-field-content label-note" dangerouslySetInnerHTML={{__html: props.labelNote}}>
                    {/*props.labelNote*/}
                </div>
            }
            <div className={`${props.horizontal ? 'fl-row' : 'fl-column'} radio-group${props.fillWidth ? ' grow' : ''}`}>
                {props.options.map(
                    (val, i) => {
                        return (
                            <div className='rb-content grow' key={'r_' + i}>
                                <div className='rb-row grow'>
                                    <input type="radio" name={val.id || val._id} id={val.id || val._id} value={val.value ?? val.val} checked={props.selectedOption === (val.value ?? val.val)} onChange={optionSelected} disabled={props.disabled ?? false} />
                                    <label htmlFor={val.id || val._id} className={props.selectedOption === (val.value || val.val) ? 'checked' : ''} /* onClick={props.disabled ? null : () => {optionSelected({target:{value: val.value ?? val.val}})}} */>{val.label || val.val}</label>
                                    {(props.horizontal || val.expandedContent) && <div className="grow" />}
                                    {val.expandedContent &&
                                        <div className='fl-row button-active light-text' onClick={() => toggleExpandSection(i)}>
                                            {val.expandedContentLabel && <div>{val.expandedContentLabel}</div>}
                                            {IconJsxer.GetIcon(expanded.length > i && expanded[i] ? IconJsxer.ICONS.chevronUp : IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.standard)}
                                        </div>
                                    }
                                </div>
                                {val.expandedContent &&
                                    <div className={`expanded-content${(expanded.length > i && expanded[i]) ? ' expanded' : ''}`}>
                                        {val.expandedContent}
                                    </div>
                                }
                            </div>
                        )
                    }
                )}
            </div>
        </div>
    )
}

const defaultSelectFunc = () => { DebugLogger.warn('You should provide a function called `setFunc(event)` to RadioButtonGroup.'); }

RadioButtonGroup.propTypes = {
    label: PropTypes.string,
    labelNote: PropTypes.string,
    horizontal: PropTypes.bool,
    options: PropTypes.array,
    className: PropTypes.string,
    selectFunc: PropTypes.func,
    selectedOption: PropTypes.any,
    fillWidth: PropTypes.bool,
}

RadioButtonGroup.defaultProps = {
    label: null,
    labelNote: null,
    horizontal: true,
    options: [{ label: 'Option 1', value: 'opt1' }, { label: 'Option 2', value: 'opt2' }, { label: 'Option 3', value: 'opt3' }],
    className: '',
    selectFunc: defaultSelectFunc,
    selectedOption: null,
    fillWidth: false,
}

export default RadioButtonGroup;
