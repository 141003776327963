import React, { useState, useEffect, useContext } from "react";
import validator from "validator";

import { toast } from "react-toastify";
import axios from "../../api/axios";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../../contexts/AuthContext";
import { ProfilePicURL } from "../../helpers/ProfilePicURL";
import { removeProfilePicRoute, uploadProfilePicRoute, filesRoute } from "../../api/routes";
import ImageUploader, { UPLOADER_IMAGE_SIDE } from "../../components/forms/ImageUpload/ImageUploader";
import { registerRoute } from "../../api/routes";
import FormField from "../../components/forms/FormField/FormField";
import "./EditProfileImage.scss";

import CheckBox from "../../components/forms/CheckBox/CheckBox";
import ClosePanelBtn from "../../components/ClosePanelBtn/ClosePanelBtn";
import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";

const EditProfileImage = (props) => {

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [authContext, setAuthContext] = useContext(AuthContext);

    const navigate = useNavigate();

    const profileImageRemoved = () => {
        let userData = authContext.userData;
        userData.profilePic = '';
        setAuthContext(oldValues => {
            return { ...oldValues, userData: userData }
        });
        toast.success("Profile Image Removed!");
    }
    const profileImageUploaded = (res, imgData) => {
        let userData = authContext.userData;
        userData.profilePic = res.data.uploadedFilename;
        setAuthContext(oldValues => {
            return { ...oldValues, userData: userData }
        });
        toast.success("Profile Image Updated!");
    }
    const profileImageUploadError = (err, id) => {
        toast.error("Error uploading profile image");
    }

    return (
        <PopUpPanel closePanelFunc={props.closePanelFunc}>
            <div className="form-holder">
                <h2>Change Profile Image</h2>
                <ImageUploader
                    label="Current Profile Image"
                    endPoint={uploadProfilePicRoute}
                    removeEndPoint={removeProfilePicRoute}
                    imgSide={UPLOADER_IMAGE_SIDE.left}
                    imgWidth={100}
                    imgHeight={100}
                    currentImg={ProfilePicURL()}
                    uploadImmediately={true}
                    removedImageCallback={profileImageRemoved}
                    uploadedCallback={profileImageUploaded}
                    uploadErrorCallback={profileImageUploadError}
                />
            </div>
        </PopUpPanel>
    )
}

export default EditProfileImage;
