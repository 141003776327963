import "./Quizzes.scss";
import { useContext, useEffect, useState, } from "react";
import DebugLogger from "../../helpers/DebugLogger";
import ListedQuiz from "./ListedQuiz";
import EditQuiz from "./EditQuiz";
import { NavLink } from 'react-router-dom';
import { TOP_LEVEL_NAV_ROUTES } from "../../config/NavRoutes";
import { IconJsxer } from "../../helpers/IconHelper";
import axios from "../../api/axios";
import { getQuizListRoute, getExtraQuizDataRoute, quizUpdateStatusRoute, quizUpdateRoute, removeQuizRoute, listQuizzesRoute } from "../../api/routes";
import { toast } from "react-toastify";
import DropDownList from "../../components/forms/DropDownList/DropDownList";
import { ACCOUNT_TYPES, getAcDropListData, getAcTypeData } from "../../helpers/AccountTypes";
import SearchBox from "../../components/forms/SearchBox/SearchBox";
import { AuthContext } from "../../contexts/AuthContext";
import { alphaTypePrimer, nullOrAlphaTypePrimer, filterList, customListPrimer, searchList, sortListOn, toStringPrimer } from "../../helpers/ListFunctions";
import Toggle from "../../components/forms/Toggle/Toggle";
import ConfirmRemoveQuiz from "../Campaigns/ConfirmRemoveQuiz";
import ReactPaginate from "react-paginate";
import NewQuizPopup from "./NewQuizPopup";

const Quizzes = () => {
    const [authContext, setAuthContext] = useContext(AuthContext);
    //const acSettings = getAcTypeData(authContext.userData.accountType);

    const SORT_TYPES = {
        NAME: "name",
        TYPE: "type",
        ROUNDS: "rounds",
        QUESTIONS: "questions",
        CREATED: 'dateCreated',
    }   
    
    // const [questionTypes, setQuestionTypes] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [allQuizData, setAllQuizData] =  useState([]);
    const [displayQuizData, setDisplayQuizData] =  useState([]);
    //const [filteredDisplayList, setFilteredDisplayList] =  useState([]);
    const [statusFilter, setStatusFilter] = useState(null);
    const [sortListReverse, setSortListReverse] = useState(false);
    const [currentSort, setCurrentSort] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [shouldUpdateList, setShouldUpdateList] = useState(false);
    //const [hasPerformedDefaultSort, setHasPerformedDefaultSort] = useState(false);
    const [showCampaignQuizzesFilter, setShowCampaignQuizzesFilter] = useState(false);
    const [showTypesFilter, setShowTypesFilter] = useState(
        {
            id: 'all',
            label: 'All Types',
            value: 'all',
            deselect: true,
            index: 0
        }
    );

    const [currentPage, setCurrentPage] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const handlePageClick = (event) => {
        setCurrentPage(event.selected)
    };

    const [editData, setEditData] = useState(null);
    const [quizToEdit, setQuizToEdit] = useState(null);

    const listTracker = [];

    const prepareEditData = (data) => {
        setEditData(data);
        if(data == null) {
            setShouldUpdateList(true);
        }
    }

    const openEditQuiz = (quiz) => {
        setQuizToEdit(quiz);

        //EditQuiz being closed
        if(quiz === null) {
            setShouldUpdateList(true);
        }
    }

    const changeStatusHandler = (e, id, status) => {
        axios
            .post(quizUpdateStatusRoute, { quizID:id, status:status, withCredentials: true})
            .then(response => {
                if(response.data.updatedQuiz.hasUpdated) {
                    setShouldUpdateList(true);
                    toast.success("Status changed to " + response.data.updatedQuiz.quiz.status);
                } else {
                    toast.error("Failed to change the status");
                }
            })
            .catch(err => {
                DebugLogger.log("changePriorityHandler error",err)
            })
    }

    const fetchTypesAndStatuses = () => {
        axios
            .get(getExtraQuizDataRoute, {withCredentials: true})
            .then(response => {
                // setQuizTypes(response.data.typesAndStatuses.quizTypes);
                // setQuestionTypes(response.data.typesAndStatuses.questionTypes);
                setStatuses(response.data.typesAndStatuses.statuses);

                setShowCampaignQuizzesFilter(false);
                setShouldUpdateList(true);
            })
            .catch(err => {
                DebugLogger.log("fetchPriorities err = ",err);
            })
    }

    const fetchQuizList = () => {
        setAllQuizData(null);
        setDisplayQuizData(null);

        axios
        .get(listQuizzesRoute, {
            params: {
                page:currentPage, 
                searchTerm: searchTerm, 
                typeFilter:showTypesFilter.value, 
                showCampaignQuizzes:showCampaignQuizzesFilter,
                sortBy:currentSort,
                reverseSort:sortListReverse
            },
            withCredentials: true
        })
        .then((response) => {

            // Remove 'Disabled' quizzes (aka deleted ones)
            // This probably needs to be a bit more comprehensive, but for now we can remove quizzes!
            /* response.data.quizzes = response.data.quizzes.filter(it => it.status !== 'Disabled');

            let sortedQuizList = response.data.quizzes.sort((a, b) => {
                let statusA, statusB;
                for(var key in statuses) {
                    let status = statuses[key];
                    if(status.id == a.status) {
                        statusA = status.index;
                    }
                    if(status.id == b.status) {
                        statusB = status.index;
                    }

                }
                return statusA - statusB;
            })

            setAllQuizData(null);
            setDisplayQuizData(null);
            setAllQuizData(sortedQuizList);
            setDisplayQuizData(sortedQuizList);

            console.log('Fetched quizzes: ', sortedQuizList); */

            setAllQuizData(response.data.quizzes);
            setDisplayQuizData(response.data.quizzes);
            setNumberOfPages(response.data.numberOfPages);

            console.log('Fetched quizzes: ', response.data.quizzes);
        })
        .catch((error) => {
            DebugLogger.log("fetchQuizList error ",error);
        });
    }

    const sortList = (event, type) => {

        event.preventDefault();

        let reverse = !sortListReverse;
        setSortListReverse(reverse);

        /* let sortedQuizArray = displayQuizData.concat();

        switch (type){
            case SORT_TYPES.NAME:
                sortListOn(sortedQuizArray, 'name', reverse, alphaTypePrimer);
            break;
            case SORT_TYPES.CAMPAIGN:
                sortListOn(sortedQuizArray, 'campaign', reverse, alphaTypePrimer);
            break;
            case SORT_TYPES.STATUS:
                sortListOn(sortedQuizArray, 'status', reverse, (val) => { return customListPrimer(val, statuses) });
            break;
            default:
            break;
        }
        */
        setCurrentSort(type)
        setCurrentPage(0);
        // setDisplayQuizData(sortedQuizArray);
    }

    const filterDisplayList = () =>{
        let tempFilterArr = allQuizData.concat();
        if (showCampaignQuizzesFilter === false) {
            tempFilterArr = tempFilterArr.filter(it=> it.campaign == null);
        }
        if (showTypesFilter.value !== 'all') {
            tempFilterArr = tempFilterArr.filter(it=> it.type === showTypesFilter.value);
        }

        setDisplayQuizData(tempFilterArr);
        setSearchTerm('');
        return tempFilterArr;
    }

    const searchQuizList = (e, searchTerm) => {
        // let filteredList = filterDisplayList();
        setSearchTerm(searchTerm);
        setCurrentPage(0);
        /* if (searchTerm !== '') {
            let searchedList = searchList(filteredList, ["name"], searchTerm, alphaTypePrimer);
            setDisplayQuizData(searchedList);
        } */
        // fetchQuizList();
    }

    const refreshQuizList = () => {
        searchQuizList(null, searchTerm);
    }

    /* useEffect(() => {
        //get data for dropdowns
        fetchTypesAndStatuses();
        setCurrentSort(SORT_TYPES.STATUS);
    }, []); */

    useEffect(() => {
        if(shouldUpdateList) {
            fetchQuizList();
            setShouldUpdateList(false);
        }

    }, [shouldUpdateList])

    useEffect(() => {
        if(quizToEdit == null) {
            prepareEditData(null);
        }
    },[quizToEdit])

    useEffect(() => {
        // filterDisplayList();
        fetchQuizList();
    }, [showCampaignQuizzesFilter, showTypesFilter, currentPage, searchTerm, currentSort, sortListReverse])

    const updateFilterHandler = async (e, id, item) => {
        switch (id) {
            case 'showCampaignQuizzes':
                setShowCampaignQuizzesFilter(!showCampaignQuizzesFilter);
                setCurrentPage(0);
                break;
            default:
                break;
        }
    }

    const [quizToRemove, setQuizToRemove] = useState(null);
    const [showConfirmRemoveQuiz, setShowConfirmRemoveQuiz] = useState(false);
    const removeQuiz = (quiz) => {
        setQuizToRemove(quiz);
        setShowConfirmRemoveQuiz(true);
    }
    const removeQuizNow = (quiz, callback) => {
        axios
        .post(removeQuizRoute, { quizID: quizToRemove._id, withCredentials: true })
        .then((response) => {
            console.log("remove response = ",response);
            if(response.data.removedQuiz) {
                // setAllQuizData((oldQuizzes) => oldQuizzes.filter(it => it._id != response.data.removedQuiz.quiz._id))
                fetchQuizList();
                callback(true);
            }
        })
    }
    const closeConfirmRemoveQuiz = () => {
        setQuizToRemove(null);
        setShowConfirmRemoveQuiz(false);
    }

    const [showCreateQuiz, setShowCreateQuiz] = useState(false);
    const closeCreateQuiz = () => {
        setShowCreateQuiz(false);
    }

    let gridClass = "grid";

    return (
        <div className="content-page-container">
            <div className="content-page-top">
                <div className="fl-column grow">
                    <div className="fl-row grow">
                        <div>
                            <h1>Quizzes and Polls</h1>
                        </div>
                        <div className="grow"></div>
                        <div className="quizzes-search">
                            <SearchBox searchFunc={searchQuizList} />
                        </div>
                    </div>
                    <div className="fl-row grow">
                        <div className="filters-container filters-container-extra-margin form-holder fl-row grow">
                            <DropDownList
                                id="typeFilter"
                                selectFunc={(e, id, item) => {setShowTypesFilter(item); setCurrentPage(0);}}
                                currentIndex={showTypesFilter.index}
                                deselectedLabel="Type"
                                icon="quizNav"
                                items={
                                    [
                                        {
                                            id: 'all',
                                            label: 'All Types',
                                            deselect: true,
                                            value: 'all'
                                        },
                                        {
                                            id: 'quiz',
                                            label: "Quiz",
                                            value: "Quiz"
                                        },
                                        {
                                            id: 'poll',
                                            label: "Poll",
                                            value: "Poll"
                                        },
                                        {
                                            id: 'tugOfWar',
                                            label: "Tug of War",
                                            value: "Tug of War"
                                        },
                                    ]
                                }
                                className="filter"
                            />

                            {searchTerm !== '' && displayQuizData && <div className="light-text">Searched for: {searchTerm} ({displayQuizData.length} result{displayQuizData.length > 1 ? 's' : ''}) <span className="small-text">[<a href="#" onClick={(e) => {e.preventDefault(); searchQuizList(e, '');}}>Clear Search</a>]</span></div>}

                            <div className="grow"></div>

                            <div className="fl-row shrink">
                                <label htmlFor="showCampaignQuizzes">Show Campaign Quizzes and Polls:</label>
                                <Toggle
                                    id="showCampaignQuizzes"
                                    currentState={showCampaignQuizzesFilter}
                                    label="Show Campaign Quizzes and Polls"
                                    toggleFunc={updateFilterHandler}
                                    enabled={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
            <div className="content-page-content-container">
                <div className="content-page-main-content">

                    <div className="quizzes-content">
                        <div className="quizzes-panel">
                            {/* <NavLink className="add-btn" to={TOP_LEVEL_NAV_ROUTES.CREATE_QUIZ}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.plus, IconJsxer.ICON_STYLES.roundPanelButtonSmall)}
                            </NavLink> */}
                            <div className="add-btn" onClick={() => {setShowCreateQuiz(true)}}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.plus, IconJsxer.ICON_STYLES.roundPanelButtonSmall)}
                            </div>
                            <div className="scroll-container">
                                <div className="grid">
                                    {/* <div className="header label">&nbsp;</div> */}
                                    <div className="header label" onClick={(event) => {sortList(event, SORT_TYPES.NAME)}}>Name {currentSort === SORT_TYPES.NAME ? (IconJsxer.GetIcon(sortListReverse ? IconJsxer.ICONS.chevronUp : IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.roundFormControl)) : ''}</div>
                                    <div className="header label centered" onClick={(event) => {sortList(event, SORT_TYPES.TYPE)}}>Type {currentSort === SORT_TYPES.TYPE ? (IconJsxer.GetIcon(sortListReverse ? IconJsxer.ICONS.chevronUp : IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.roundFormControl)) : ''}</div>

                                    <div className="header label centered" onClick={null/* (event) => {sortList(event, SORT_TYPES.ROUNDS)} */}>Rounds {currentSort === SORT_TYPES.ROUNDS ? (IconJsxer.GetIcon(sortListReverse ? IconJsxer.ICONS.chevronUp : IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.roundFormControl)) : ''}</div>
                                    <div className="header label centered" onClick={null/* (event) => {sortList(event, SORT_TYPES.QUESTIONS)} */}>Questions {currentSort === SORT_TYPES.QUESTIONS ? (IconJsxer.GetIcon(sortListReverse ? IconJsxer.ICONS.chevronUp : IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.roundFormControl)) : ''}</div>
                                    {/* <div className="header label" onClick={(event) => {sortList(event, SORT_TYPES.STATUS)}}>Status {currentSort === SORT_TYPES.STATUS ? (IconJsxer.GetIcon(sortListReverse ? IconJsxer.ICONS.chevronUp : IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.roundFormControl)) : ''}</div> */}
                                    
                                    <div className="header label"></div>
                                    
                                    <div className="header label">Campaign</div>
                                    <div className="header label centered" onClick={(event) => {sortList(event, SORT_TYPES.CREATED)}}>Created {currentSort === SORT_TYPES.CREATED ? (IconJsxer.GetIcon(sortListReverse ? IconJsxer.ICONS.chevronUp : IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.roundFormControl)) : ''}</div>
                                    <div className="header label">&nbsp;</div>
                                    {/* <div className="header label"></div> */}
                                    <hr />
                                    {
                                        displayQuizData && displayQuizData.map(
                                            (val, i) => {
                                                let listItemKey = "";
                                                if(!listTracker[i]) {
                                                    listTracker[i] = {key: "", quiz:{}};
                                                }
                                                if(val != listTracker[i].quiz) {
                                                    listItemKey = `quiz_${i}_${Math.floor(Math.random() * 10000)}`;
                                                    listTracker[i].key = listItemKey;
                                                    listTracker[i].quiz = val;
                                                } else {
                                                    listItemKey = listTracker[i].key;
                                                }

                                                return (
                                                    <ListedQuiz key={listItemKey} quiz={val} statuses={statuses} changeStatusFunc={changeStatusHandler} setQuizToEdit={openEditQuiz} prepareEditData={prepareEditData} _removeQuiz={removeQuiz} _editMode={true} _extraFirstRow={true} />
                                                );
                                            }
                                        )
                                    }

                                </div>
                            </div>
                        </div>

                        <ReactPaginate
                                activeClassName={'pagination-item item-active '}
                                breakClassName={'pagination-item break-me '}
                                breakLabel={'...'}
                                containerClassName={'pagination'}
                                disabledClassName={'disabled-page'}
                                marginPagesDisplayed={2}
                                nextClassName={"pagination-item  "}
                                nextLabel={IconJsxer.GetIcon(IconJsxer.ICONS.chevronRight, IconJsxer.ICON_STYLES.settingsEdit)}
                                onPageChange={handlePageClick}
                                pageCount={numberOfPages}
                                forcePage={currentPage}
                                pageClassName={'pagination-item pagination-page '}
                                pageRangeDisplayed={2}
                                previousClassName={"pagination-item  "}
                                previousLabel={IconJsxer.GetIcon(IconJsxer.ICONS.chevronLeft, IconJsxer.ICON_STYLES.settingsEdit)}
                                renderOnZeroPageCount={false}
                            />

                    </div>

                </div>
            </div>
            { showConfirmRemoveQuiz && 
                <ConfirmRemoveQuiz quiz={quizToRemove} removeQuiz={removeQuizNow} closePanelFunc={closeConfirmRemoveQuiz} />
            }
            {showCreateQuiz && <NewQuizPopup closePanelFunc={closeCreateQuiz} />}
        </div>
    );
}

export default Quizzes;
