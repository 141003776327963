import axios from "../api/axios";
import { getAccountSettingsRoute } from "../api/routes";
import { IconJsxer } from "./IconHelper";

// Account levels and admin settings are now pulled down from the backend so we only actually need them in one place,
// it won't hurt to leave them here, although the backend is treated as the source of truth and the settings from there will over-write these settings.
export const ACCOUNT_TYPES = {
    koko: {
        displayName: 'Koko Digital (Dev Team)',
        shortName: 'KOKO DIGITAL',
        dbString: 'koko_super_user',
        colorCss: 'koko-ac-color',
        profilePicBorderCss: 'koko-pp-border',
        icon: IconJsxer.ICONS.kokoAcIcon,
        level: 100,
        adminSettings: {
            maxDeauthLevel: 100,
            maxChangeLevel: 100,
            maxInviteLevel: 100,
        }
    },
    twitchSuperUser: {
        displayName: 'Twitch Super User',
        shortName: 'TWITCH SUPER',
        dbString: 'twitch_super_user',
        colorCss: 'super-user-ac-color',
        profilePicBorderCss: 'super-user-pp-border',
        icon: IconJsxer.ICONS.lightingBolt,
        // useTwitchIcon: true,
        level: 50,
        adminSettings: {
            maxDeauthLevel: 50,
            maxChangeLevel: 50,
            maxInviteLevel: 50,
        }
    },
    producer: {
        displayName: 'Twitch Producer',
        shortName: 'PRODUCER',
        dbString: 'twitch_producer',
        colorCss: 'producer-ac-color',
        profilePicBorderCss: 'producer-pp-border',
        useTwitchIcon: false,
        icon: IconJsxer.ICONS.idCard,
        level: 40,
        adminSettings: {
            maxDeauthLevel: 35,
            maxChangeLevel: 35,
            maxInviteLevel: 35,
        }
    },
    creative: {
        displayName: 'Creative',
        shortName: 'CREATIVE',
        dbString: 'creative',
        colorCss: 'client-ac-color',
        profilePicBorderCss: 'client-pp-border',
        useTwitchIcon: false,
        icon: IconJsxer.ICONS.animated,
        level: 35,
        adminSettings: {
            maxDeauthLevel: -1,
            maxChangeLevel: -1,
            maxInviteLevel: -1,
        }
    },
    client: {
        displayName: 'Client',
        shortName: 'CLIENT',
        dbString: 'client',
        colorCss: 'client-ac-color',
        profilePicBorderCss: 'client-pp-border',
        icon: IconJsxer.ICONS.clientAcIcon,
        level: 30,
        adminSettings: {
            maxDeauthLevel: -1,
            maxChangeLevel: -1,
            maxInviteLevel: -1,
        }
    },
    agency: {
        displayName: 'Agency',
        shortName: 'AGENCY',
        dbString: 'agency',
        colorCss: 'agency-ac-color',
        profilePicBorderCss: 'agency-pp-border ',
        icon: IconJsxer.ICONS.clientAcIcon,
        level: 30,
        adminSettings: {
            maxDeauthLevel: -1,
            maxChangeLevel: -1,
            maxInviteLevel: -1,
        }
    },
    streamer: {
        displayName: 'Streamer',
        shortName: 'STREAMER',
        dbString: 'streamer',
        colorCss: 'streamer-ac-color',
        profilePicBorderCss: 'streamer-pp-border',
        useTwitchIcon: true,
        level: 10,
        adminSettings: {
            maxDeauthLevel: -1,
            maxChangeLevel: -1,
            maxInviteLevel: -1,
        }
    },
    notset: {
        displayName: 'Not Set',
        shortName: 'NOT SET',
        dbString: 'not_set',
        colorCss: 'not-set-ac-color',
        profilePicBorderCss: 'not-set-pp-border',
        icon: IconJsxer.ICONS.notSetAcIcon,
        level: 0,
        adminSettings: {
            maxDeauthLevel: -1,
            maxChangeLevel: -1,
            maxInviteLevel: -1,
        }
    },
}

// The order of our account types in lists
export const ACCOUNT_TYPES_LIST_ORDER = [
    ACCOUNT_TYPES.notset,
    ACCOUNT_TYPES.streamer,
    ACCOUNT_TYPES.agency,
    ACCOUNT_TYPES.client,
    ACCOUNT_TYPES.creative,
    ACCOUNT_TYPES.producer,
    ACCOUNT_TYPES.twitchSuperUser,
    ACCOUNT_TYPES.koko,
];

/**
 * Get the account list in a format useable by our DropDownList component.
 * @param {Array} listArray Optionally provide an array to add items to (typically used to add in a View All option to the top of the list for filtering)
 * @returns An updated array of items
 */
export const getAcDropListData = (listArray = [], maxLevel = 100, ignoreTypes = [], useShortName = true) => {
    for (let i = 0; i < ACCOUNT_TYPES_LIST_ORDER.length; i++) {
        if (ACCOUNT_TYPES_LIST_ORDER[i].level <= maxLevel && ignoreTypes.indexOf(ACCOUNT_TYPES_LIST_ORDER[i]) === -1 && ignoreTypes.indexOf(ACCOUNT_TYPES_LIST_ORDER[i].id) === -1) {
            listArray.push({id: ACCOUNT_TYPES_LIST_ORDER[i].dbString, label: useShortName ? ACCOUNT_TYPES_LIST_ORDER[i].shortName : ACCOUNT_TYPES_LIST_ORDER[i].displayName});
        }
    }
    return listArray;
}

export const getAcTypeData = (dbString) => {
    dbString = dbString.toLowerCase();
    for (let p in ACCOUNT_TYPES) {
        if (ACCOUNT_TYPES[p].dbString.toLowerCase() === dbString) {
            return ACCOUNT_TYPES[p];
        }
    }
    return null;
}

export const getAcTypeDisplayName = (dbString) => {
    const acData = getAcTypeData(dbString);
    if (acData) {
        return acData.displayName;
    }
    return '';
}

export const getAcTypeColorCss = (dbString) => {
    const acData = getAcTypeData(dbString);
    if (acData) {
        return acData.colorCss;
    }
    return '';
}

export const getProfilePicBorderColorCss = (dbString) => {
    const acData = getAcTypeData(dbString);
    if (acData) {
        return acData.profilePicBorderCss;
    }
    return '';
}

export const getUserLevel = (dbString) => {
    const acData = getAcTypeData(dbString);
    if (acData) {
        return acData.level;
    }
    return 0;
}

let pullSettingsTimeoutId = null;
export const pullInAccountSettings = (timeout = 1000, resetTimeout = false) => {
    if (resetTimeout) {
        clearTimeout(pullSettingsTimeoutId);
    }
    axios.get(getAccountSettingsRoute, {withCredentials:true})
    .then(
        res => {
            // Make sure our account levels and admin settings are up to date
            for (let p in res.data.accountSettings) {
                if (ACCOUNT_TYPES.hasOwnProperty(p)) {
                    for (let q in res.data.accountSettings[p]) {
                        if (q !== 'type') {
                            ACCOUNT_TYPES[p][q] = res.data.accountSettings[p][q];
                        }
                    }
                }
            }
        }
    ).catch(function (error) {
        // Give it a second and try again!
        pullSettingsTimeoutId = setTimeout(() => pullInAccountSettings(timeout * 2), timeout);
    });
}
