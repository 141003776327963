import "./CustomiseFeature.scss";
import { useContext, useEffect, useRef, useState, } from "react";
import DebugLogger from "../../helpers/DebugLogger";
import { IconJsxer } from "../../helpers/IconHelper";
import axios from "../../api/axios";
import {changeUserAuthorisationRoute, getCampaignOverviewRoute, getCampaignFeatureVarsRoute, getCampaignCustomisationVarsRoute, updateCampaignCustomisationVarsRoute, filesRoute, uploadCustomAssetRoute, getStreamCustomisationVarsRoute, updateStreamCustomisationVarsRoute, updatedFeatureListRoute, updateCampaignFeatureVarsRoute, getQuizzesByCampaignRoute, getGenericQuizzesRoute, getCampaignFeatureThemesRoute, addCampaignThemeRoute, deleteCampaignThemeRoute, updateMultipleCampaignThemesRoute} from "../../api/routes";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { CampaignContext } from "../../contexts/CampaignContext";
import { TOP_LEVEL_NAV_ROUTES } from "../../config/NavRoutes";
import { CAMPAIGN_TIERS, getCampaignTierDataFromDbString } from "../../helpers/CampaignsHelper";
import TabbedPanel from "../../components/TabbedPanel/TabbedPanel";
import ImageUploader2 from "../../components/forms/ImageUpload/ImageUploader2";
import ColorPicker from "../../components/forms/ColorPicker/ColorPicker";
import HeadedPanel from "../../components/HeadedPanel/HeadedPanel";
import DropDownList from "../../components/forms/DropDownList/DropDownList";
import FormDropDown from "../../components/forms/DropDownList/FormDropDown";
import AudioUploader from "../../components/forms/AudioUploader/AudioUploader";
import CustomisationControls, { validateLastVal, validateValAtIndex } from "../../components/CustomisationControls/CustomisationControls";
import { CUSTOMISED_ASSETS_BASE_URL, CUSTOMISED_URL_MAP, getValFromObject } from "../../config/FeatureDashboardVarMap";
import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import ResetPresets from "./ResetPresets";
import AddDisruptor from "../Campaigns/AddDisruptor";
import AddQuiz from "../Campaigns/AddQuiz";
import FeaturePreview, { postMessageToPreviewFrame } from "../../components/FeaturePreview/FeaturePreview";
import { FEATURE_CONTROLS_TYPES, POST_MESSAGE_OUT_TYPES } from "../../helpers/FeatureControlsHelper";

import { AuthContext } from "../../contexts/AuthContext";
import { ACCOUNT_TYPES } from "../../helpers/AccountTypes";
import FontSelectorPopup from "../../components/forms/FontSelector/FontSelectorPopup";
import { getFeatureAssetBaseUrl } from "../../helpers/FeatureAssets";
import Toggle from "../../components/forms/Toggle/Toggle";
import CommonFontPopup from "./CommonFontPopup";
import ThemeListPopup from "./themes/ThemeListPopup";
import CreateThemePopup from "./themes/CreateThemePopup";
import DeletingThemePopup from "./themes/DeletingThemePopup";
import EditThemePopup from "./themes/EditThemePopup";
import FunctionUnavailableInPreviewPopup from "./themes/FunctionUnavailableInPreviewPopup";

// The consts are probably going to go
const TAB_IDS = {
    visual: 'visual',
    text: 'text',
    audio: 'audio',
    featureframe: 'featureframe',
    leaderboard: 'leaderboard',
}

const POTENTIAL_TABS = [
    {
        label: 'Visual',
        id: TAB_IDS.visual,
    },
    {
        label: 'Text',
        id: TAB_IDS.text,
    },
    {
        label: 'Audio',
        id: TAB_IDS.audio,
    },
    {
        label: 'Feature Frame',
        id: TAB_IDS.featureframe,
    },
    {
        label: 'Leaderboard',
        id: TAB_IDS.leaderboard,
    },
]

const CustomiseFeature = (props) => {

    const saveButtonOnly = props.saveButtonOnly
    const customisationData = props.customisationData
    const forcedFeatureID = props.forcedFeatureID

    const { campaignId, featureId, streamId } = useParams();
    console.log('CampaignId, FeatureId, StreamId: ', campaignId, featureId, streamId);
    const [campaignContext, setCampaignContext] = useContext(CampaignContext);
    const [authContext, setAuthContext] = useContext(AuthContext);
    const navigate = useNavigate();

    // Quiz data qill be needed here, but we don't always need to pull it in - it depends on the disruptor type we are customising
    const [campaignQuizzes, setCampaignQuizzes] = useState([]);
    const [genericQuizzes, setGenericQuizzes] = useState([]);

    
    const getCampaignQuizzes = () => {
        axios
            .get(getQuizzesByCampaignRoute, {params:{campaignID: campaignId}, withCredentials: true})
            .then((res) => {
                setCampaignQuizzes(res.data.quizzes);

                console.log('Campaign quizzes: ', res.data.quizzes);
            })
            .catch(err => {
                toast.error("Failed to get campaign quizzes")
            })
    };
    const getGenericQuizzes = () => {
        axios
            .get(getGenericQuizzesRoute, {withCredentials: true})
            .then((res) => {
                setGenericQuizzes(res.data.quizzes);

                console.log('Generic quizzes: ', res.data.quizzes);
            })
            .catch(err => {
                toast.error("Failed to get generic quizzes")
            })
    };

    const [advancedMode, setAdvancedMode] = useState(false);
    const [forceUpdate, setForceUpdate] = useState(false);

    

    const tabData = useRef(null);
    const buildTabs = (campaignData) => {
        setForceUpdate(false);
        const featureData = findFeatureData(campaignData);
        console.log('Building tabs for feature: ', featureData);
        if (featureData) {
            const newTabData = [];
            for (let i = 0; i < featureData.feature.customisation.length; i++) {
                newTabData.push(
                    {
                        label: featureData.feature.customisation[i].tabTitle,
                        id: featureData.feature.customisation[i].tabKey,
                    }
                )
            }
            tabData.current = newTabData;
            setCurrentTab(newTabData[0].id);
        }
    }

    /** Make sure we have campiagn data - as this is it's own route we can't guarantee we do! */
    const pullInCampaignData = (pullInCustomisation = true, callback = null) => {
        if (campaignId) {
            axios
                .get(getCampaignOverviewRoute, { params: { campaignID: campaignId }, withCredentials: true })
                .then((response) => {
                    console.log('Got campaign data: ', response.data.campaignData);
                    if (pullInCustomisation) {
                        buildTabs(response.data.campaignData);
                        determineIfThemeable(response.data.campaignData);
                    }
                    setCampaignContext((oldValues) => {
                        return { ...oldValues, initialising: false, campaignData: response.data.campaignData }
                    })
                    if (pullInCustomisation) {
                        getCustomisationVars(response.data.campaignData);
                    }
                    if (callback) {
                        callback(true, response);
                    }
                    if (response.data.campaignData.features.length) {
                        let needQuizData = false;
                        for (let i = 0; i < response.data.campaignData.features.length; i++) {
                            // If we are deiting a quiz we need to have quiz data available
                            if (response.data.campaignData.features[i]._id === featureId && response.data.campaignData.features[i].varKey === 'quizPoll') {
                                needQuizData = true;
                                break;
                            }
                        }
                        if (needQuizData) {
                            getCampaignQuizzes();
                            getGenericQuizzes();
                        }
                    }
                })
                .catch((error) => {
                    toast.error("Error fetching campaign data");
                    if (!callback) {
                        navigate(TOP_LEVEL_NAV_ROUTES.CAMPAIGNS);
                    }
                });
        } else {
            if (!callback) {
                navigate(TOP_LEVEL_NAV_ROUTES.CAMPAIGNS);
            }
        }
    }

    useEffect(() => {
        if(!saveButtonOnly)
        {
            setCampaignContext((oldValues) => {
                return { ...oldValues, initialising: true }
            });
            pullInCampaignData();
            while (prevFeatures.current[prevFeatures.currentlength - 1] === featureId) {
                prevFeatures.current.pop();
            }
            setShowCleaningUp(false);
            setShowUploadProgress(false);
            setShowingAddTheme(false);
            setShowingEditTheme(false);
            setShowingDeletingTheme(false);
            setShowCommonFontPopup(false);
        }
    }, [campaignId, featureId, streamId]);

    const findFeatureData = (campaignData ) => {
        console.log('Finding feature data from campaign data: ', campaignData);
        if (campaignData && campaignData.features) {
            for (let i = 0; i < campaignData.features.length; i++) {
                if (campaignData.features[i]._id === featureId || (forcedFeatureID && campaignData.features[i]._id === forcedFeatureID)) {
                    return campaignData.features[i];
                }
            }
        }
        return null;
    }

    const fixSrcUrls = (featureData, customisationDataIn = null, dataToFix = null) => {
        const customisationData = customisationDataIn || featureData.feature.customisation;
        console.log('FIX URL', featureData, customisationData, dataToFix);
        for (let i = 0; i < customisationData.length; i++) {
            // console.log('i loop: ', i, customisationData[i], customisationVarsRef.current, featureId);
            const rootVal = dataToFix ?? customisationVarsRef.current.current[featureId];
            const tabKey = customisationData[i].tabKey;
            // console.log('rootVal, tabKey: ', rootVal, tabKey);
            // console.log('-----');
            for (let j = 0; j < customisationData[i].options.length; j++) {
                // console.log('j loop: ', j);
                const varKey = customisationData[i].options[j].varKey;
                // console.log('varKey: ', varKey);
                // console.log('-----');
                for (let k = 0; k < customisationData[i].options[j].variables.length; k++) {
                    // console.log('k loop: ', k);
                    const varName = customisationData[i].options[j].variables[k].varName;
                    // console.log('varName: ', varName);
                    // console.log('-----');
                    for (let prop in customisationData[i].options[j].variables[k].valSchema) {
                        // console.log('prop loop: ', prop);
                        const type = customisationData[i].options[j].variables[k].valSchema[prop].type.toLowerCase();
                        // console.log('type: ', type);
                        // console.log('-----');
                        if (CUSTOMISED_URL_MAP.hasOwnProperty(type)) {
                            // console.log('We need to generate a Url here!');
                            // We found one we need to map to a url!
                            const vals = getValFromObject(rootVal[tabKey], varKey, varName);
                            // console.log('vals: ', vals);
                            // console.log('-----');
                            if (vals && vals.length) {
                                for (let l = 0; l < vals.length; l++) {
                                    // console.log('vals loop: ', l);

                                        // Reformat legacy string based filenames to be objects...
                                        // SrcUrl has to be part of the object, meaning all feature customisations are going to break...
                                        let filename = vals[l][prop];
                                        if (typeof filename === 'string' || typeof filename === 'undefined' || filename === null) {
                                            filename = {filename: vals[l][prop]};
                                            vals[l][prop] = filename;
                                            filename.isDefault = vals[l].isDefault;
                                        }

                                    // console.log('Filename: ', filename);
                                    if (filename.srcUrl && typeof filename.srcUrl === 'string' && filename.srcUrl.startsWith('blob:')) {
                                        // Do nothing...
                                    } else {
                                        const srcUrl = (typeof filename.filename === 'undefined' || filename.filename === null || filename.filename === '') ? null :
                                                
                                                    (filename.isDefault ?  getFeatureAssetBaseUrl(true) + featureData.feature.defaultAssetFolder :   getFeatureAssetBaseUrl(false) 
                                                    + 'campaigns/'+(campaignId || campaignContext.campaignData._id)
                                                    + '/'+(featureId + (streamId && vals[l][prop].isStreamLevel ? ('/' + streamId) : ''))) 
                                                    + '/' + CUSTOMISED_URL_MAP[type].dir 
                                                    + '/' + (filename.isDefault ? filename.filename : filename.filename.replace(/(?:\.(?![^.]+$)|[^\w.])+/g, ""));

                                        // console.log('srcUrl: ', srcUrl);
                                        filename.srcUrl = srcUrl;
                                    }
                                    filename.originalFile = filename.originalFile || filename.filename;
                                    filename.type = CUSTOMISED_URL_MAP[type].dir;
                                    console.log( filename.srcUrl);
                                }
                            }

                            // console.log('Urls generated for: ', vals);
                        }
                    }
                }
            }
        }
    }

    const [customisationVars, setCustomisationVars] = useState({});
    const customisationVarsRef = useRef(null);
    const getCustomisationVars = (campaignData, updateThisIdOnly = null) => {
        console.log('Get Campaign / Stream Customisation Vars');
        axios
        .get(streamId ? getStreamCustomisationVarsRoute : getCampaignCustomisationVarsRoute, {
            params: { campaignID: campaignId, streamID: streamId },
            withCredentials:true
        })
        .then(function (response) {
           // console.log('CAMPAIGN CUSTOMISATION VARS',response.data);
            if (response.data.customisationVars) {
                if (updateThisIdOnly) {
                    // console.log('Got customisation vars to update Id: ', updateThisIdOnly, response.data.customisationVars);
                    customisationVarsRef.current.current[updateThisIdOnly] = response.data.customisationVars.current[updateThisIdOnly];
                    customisationVarsRef.current.default[updateThisIdOnly] = response.data.customisationVars.default[updateThisIdOnly];
                } else {
                    customisationVarsRef.current = response.data.customisationVars;

                    // convert any filenames into urls and remember the original filenames as well while we are at it (so we can delete old files afterwards)
                    // console.log('Generate urls ------>>> ', campaignData)
                    const featureData = findFeatureData(campaignData);
                    // console.log('Feature data: ', featureData);
                    // const customisationData = featureData.feature.customisation;
                    // console.log('Customisation data: ', customisationData);
                    // console.log('-----');
                
                    fixSrcUrls(featureData);

                    setCustomisationVars(customisationVarsRef.current);
                    console.log('Got customisation vars: ', customisationVarsRef.current);
                }
            }
        })
        .catch(function (error) {
            toast.error('Error - ' + error);
            // console.log(error.response)
        });
    }
    /* const updateFeatureVars = (updatedVars) => {
        axios
        .post(updateCampaignFeatureVarsRoute, {
            campaignID: id, 
            updatedVars: updatedVars,
            withCredentials:true
        })
        .then(function (response) {
            console.log(response.data);
        })
        .catch(function (error) {
            toast.error('Error - ' + error);
        });
    } */
    // useEffect(getCustomisationVars, []);

    const [showCleaningUp, setShowCleaningUp] = useState(false);

    const exitWithoutSaving = () => {
        if (addedDisruptorIds.current.length) {
            // we need to do some cleanup!
            setShowCleaningUp(true);
            cleanupFeatures(addedDisruptorIds.current, exitBackToCampaignOverview);
        } else {
            exitBackToCampaignOverview();
        }
    }

    const exitBackToCampaignOverview = () => {
        // console.log('Exit customise');
        if (prevFeatures.current.length) {

            let  backToFeature = prevFeatures.current.pop();

            while(backToFeature == featureId)
            {
                backToFeature = prevFeatures.current.pop();
            }

            if(backToFeature == undefined)
            {
                if (streamId) {
                    navigate(TOP_LEVEL_NAV_ROUTES.MANAGE_CAMPAIGN_STREAM.replace(':campaignId', campaignId).replace(':streamId', streamId));
                } else {
                    navigate(TOP_LEVEL_NAV_ROUTES.CAMPAIGN_OVERVIEW_TAB.replace(':id', campaignId).replace(':tab', 'content'));
                }
                return;
            }

            // console.log('Going back to feature: ', backToFeature);
            if (streamId) {
                navigate(TOP_LEVEL_NAV_ROUTES.CUSTOMISE_FEATURE_STREAM.replace(':campaignId', campaignId).replace(':streamId', streamId).replace(':featureId', backToFeature));
            } else {
                navigate(TOP_LEVEL_NAV_ROUTES.CUSTOMISE_FEATURE.replace(':campaignId', campaignId).replace(':featureId', backToFeature));
            }
        } else
        if (streamId) {
            navigate(TOP_LEVEL_NAV_ROUTES.MANAGE_CAMPAIGN_STREAM.replace(':campaignId', campaignId).replace(':streamId', streamId));
        } else {
            navigate(TOP_LEVEL_NAV_ROUTES.CAMPAIGN_OVERVIEW_TAB.replace(':id', campaignId).replace(':tab', 'content'));
        }
    }

    const removedFiles = useRef([]);
    const removedFileTypes = useRef([]);
    const handleRemovedFile = (removedData) => {
        // console.log('Removed: ', removedData);
        removedFiles.current.push(removedData.originalFile);
        removedFileTypes.current.push(removedData.type);
    }

    const [showUploadProgress, setShowUploadProgress] = useState(false);
    const [uploadedCount, setUploadedCount] = useState(0);
    const [totalUploads, setTotalUploads] = useState(0);
    const [currentUploadFilename, setCurrentUploadFilename] = useState('');
    // An iterative function that handles uploading files one at a time from an array, then calls a callback when done
    const uploadFiles = (filesArray, fileDirs, totalFiles, afterUploadCallback, filesUploaded = 0) => {
        setUploadedCount(filesUploaded);
        if (filesArray.length > 0) {
            const fileToUpload = filesArray.pop();
            const fileDir = fileDirs.pop();
            setCurrentUploadFilename(fileToUpload.name);
            console.log('Uploading file (' + filesUploaded + 1 +'/' + totalFiles +'): ', fileToUpload, fileDir);
            
            /* fileToUpload.customAssetData = {
                featureID: featureId,
                type: fileDir,
            }; */
            const formData = new FormData();
         
            formData.append('filename', fileToUpload.uniqueFilename);
            formData.append('campaignID', campaignId);
            formData.append('featureID', featureId);
            if (streamId) {
                formData.append('streamID', streamId);
            }
            formData.append('type', fileDir);
            formData.append('file', fileToUpload);
            axios.post(uploadCustomAssetRoute, formData, { withCredentials: true })
                .then(res => {
                    console.log('Upload result: ', res);
                    uploadFiles(filesArray, fileDirs, totalFiles, afterUploadCallback, filesUploaded + 1);
                })
                .catch(err => {
                    DebugLogger.log('Upload error: ', err);
                    toast.error('Error Uploading File - ' + err);
                    if (afterUploadCallback) {
                        afterUploadCallback(false);
                    }
                });
        } else {
            if (afterUploadCallback) {
               
               setTimeout(() => { afterUploadCallback(true)}, 500);
            }
        }
    }

    const saveChanges = (afterSaveCallback = null, ignoreUpload = false, forcedFeatureID = '') => {
        if(customisationData) customisationVarsRef.current = customisationData;
        console.log('Save changes: ', customisationVarsRef.current,ignoreUpload, forcedFeatureID);
        const dataSchema = findFeatureData(campaignContext.campaignData, forcedFeatureID).feature.customisation;

        const newFeatureID = forcedFeatureID ? forcedFeatureID : featureId

        // Store any files to upload in an array
        const filesToUpload = [];
        const fileDirs = [];
        const oldFilenames = [];
        const filesToRemove = [];
        // Rebuild the data object and send up to the server...
        const rebuiltDataStructure = {};
        for (let i = 0; i < dataSchema.length; i++) {
            // Tab groups
            const tabKey = dataSchema[i].tabKey;
            console.log('TAB: ', tabKey);
            for (let j = 0; j < dataSchema[i].options.length; j++) {
                // Tab group options
                const varKey = dataSchema[i].options[j].varKey;
                console.log('VARKEY: ', varKey);
                for (let k = 0; k < dataSchema[i].options[j].variables.length; k++) {
                    const varName = dataSchema[i].options[j].variables[k].varName;
                    console.log('VARNAME: ', varName);
                    // We now have all the keys to build our data structure
                    if (rebuiltDataStructure[tabKey] === undefined) {
                        rebuiltDataStructure[tabKey] = {};
                    }
                    if (rebuiltDataStructure[tabKey][varKey] === undefined) {
                        rebuiltDataStructure[tabKey][varKey] = {};
                    }
                    if (rebuiltDataStructure[tabKey][varKey][varName] === undefined) {
                        rebuiltDataStructure[tabKey][varKey][varName] = [];
                    }
                    // Drop any invalid data (maybe they forgot to select a file)...
                    /* while (customisationVarsRef.current.current[featureId][tabKey][varKey][varName].length > 0 && validateLastVal(customisationVarsRef.current.current[featureId][tabKey][varKey][varName], dataSchema[i].options[j].variables[k].valSchema) === false) {
                        customisationVarsRef.current.current[featureId][tabKey][varKey][varName].pop();
                    } */
                    // While loop and pop doesn't do the trick - what if I add 3 values and the middle one is valid, the last one will be removed, but the other invalid one won't
                    // Before we go splicing out data, this only applies to data where multiples are allowed...
                    if (dataSchema[i].options[j].variables[k].allowMultiple) {
                        for (let validate_i = customisationVarsRef.current.current[newFeatureID][tabKey][varKey][varName].length - 1; validate_i >= 0; validate_i--) {
                            if (validateValAtIndex(customisationVarsRef.current.current[newFeatureID][tabKey][varKey][varName], dataSchema[i].options[j].variables[k].valSchema, validate_i) === false) {
                                customisationVarsRef.current.current[newFeatureID][tabKey][varKey][varName].splice(validate_i, 1);
                            }
                        }
                    }
                    // Now add in our new data, being careful to only send updated vars and preserve isDefault
                    for (let l = 0; l < customisationVarsRef.current.current[newFeatureID][tabKey][varKey][varName].length; l++) {
                        // if (customisationVarsRef.current.current[featureId][tabKey][varKey][varName][l].updated) {
                            const newDataObj = {};
                            for (let prop in dataSchema[i].options[j].variables[k].valSchema) {
                                console.log('PROP: ', prop, customisationVarsRef.current.current[newFeatureID][tabKey][varKey][varName][l][prop]);
                                
                                if (CUSTOMISED_URL_MAP.hasOwnProperty(dataSchema[i].options[j].variables[k].valSchema[prop].type.toLowerCase())) {
                                    newDataObj[prop] = {...customisationVarsRef.current.current[newFeatureID][tabKey][varKey][varName][l][prop]};
                                    if (customisationVarsRef.current.current[newFeatureID][tabKey][varKey][varName][l][prop].newFile) {    
                                        fileDirs.push(CUSTOMISED_URL_MAP[dataSchema[i].options[j].variables[k].valSchema[prop].type.toLowerCase()].dir);
                                        filesToUpload.push(customisationVarsRef.current.current[newFeatureID][tabKey][varKey][varName][l][prop].newFile);
                                        if (customisationVarsRef.current.current[newFeatureID][tabKey][varKey][varName][l][prop].originalFile) {
                                            oldFilenames.push(customisationVarsRef.current.current[newFeatureID][tabKey][varKey][varName][l][prop].originalFile);
                                            filesToRemove.push(CUSTOMISED_URL_MAP[dataSchema[i].options[j].variables[k].valSchema[prop].type.toLowerCase()].dir + '/' + customisationVarsRef.current.current[newFeatureID][tabKey][varKey][varName][l][prop].originalFile);
                                        }
                                        newDataObj[prop].isStreamLevel = typeof streamId !== 'undefined';
                                    }
                                    // Get rid of any stuff we don't need in the db
                                    newDataObj[prop].originalFile = undefined;
                                    newDataObj[prop].srcUrl = undefined;
                                    newDataObj[prop].newFile = undefined;
                                } else {
                                    newDataObj[prop] = customisationVarsRef.current.current[newFeatureID][tabKey][varKey][varName][l][prop];
                                }
                            }

                            newDataObj.isDefault = customisationVarsRef.current.current[newFeatureID][tabKey][varKey][varName][l]["isDefault"];
                            rebuiltDataStructure[tabKey][varKey][varName].push(newDataObj);
                        // }
                    }
                }
            }
        }
        console.log('Built data to send: ', rebuiltDataStructure);

        const dataToSend = {};
        dataToSend[newFeatureID] = rebuiltDataStructure;

        // Now we need to iterate through our theme data and process any files that need uploading or removing
        if (themeDataRef.current) {
            for (let i = 0; i < themeDataRef.current.length; i++) {
                const oneThemeData = themeDataRef.current[i].themeData;
                for (let tab in oneThemeData) {
                    if (oneThemeData[tab])
                    for (let varKey in oneThemeData[tab]) {
                        if (oneThemeData[tab][varKey])
                        for (let varName in oneThemeData[tab][varKey]) {
                            if (oneThemeData[tab][varKey][varName] && oneThemeData[tab][varKey][varName].length)
                            for (let varIndex = 0; varIndex < oneThemeData[tab][varKey][varName].length; varIndex++) {
                                for (let prop in oneThemeData[tab][varKey][varName][varIndex]) {
                                    console.log('PROP: ', prop, oneThemeData[tab][varKey][varName][varIndex][prop]);
                                    if (oneThemeData?.[tab]?.[varKey]?.[varName]?.[varIndex]?.[prop]?.type && CUSTOMISED_URL_MAP.hasOwnProperty(oneThemeData[tab][varKey][varName][varIndex][prop].type?.toLowerCase())) {
                                        if (oneThemeData[tab][varKey][varName][varIndex][prop].newFile) {
                                            console.log('>>>> THEME FILE UPLOAD: ', CUSTOMISED_URL_MAP[oneThemeData[tab][varKey][varName][varIndex][prop].type.toLowerCase()].dir, oneThemeData[tab][varKey][varName][varIndex][prop].newFile)
                                            fileDirs.push(CUSTOMISED_URL_MAP[oneThemeData[tab][varKey][varName][varIndex][prop].type.toLowerCase()].dir);
                                            filesToUpload.push(oneThemeData[tab][varKey][varName][varIndex][prop].newFile);
                                            if (oneThemeData[tab][varKey][varName][varIndex][prop].originalFile) {
                                                console.log('>>>> THEME FILE REMOVE: ', oneThemeData[tab][varKey][varName][varIndex][prop].originalFile, CUSTOMISED_URL_MAP[oneThemeData[tab][varKey][varName][varIndex][prop].type.toLowerCase()].dir + '/' + oneThemeData[tab][varKey][varName][varIndex][prop].originalFile);
                                                oldFilenames.push(oneThemeData[tab][varKey][varName][varIndex][prop].originalFile);
                                                filesToRemove.push(CUSTOMISED_URL_MAP[oneThemeData[tab][varKey][varName][varIndex][prop].type.toLowerCase()].dir + '/' + oneThemeData[tab][varKey][varName][varIndex][prop].originalFile);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        // Add in any removed files to our files to remove list
        for (let i = 0; i < removedFiles.current.length; i++) {
            oldFilenames.push(removedFiles.current[i]);
            filesToRemove.push(removedFileTypes.current[i] + '/' + removedFiles.current[i]);
        }
        console.log('Files: ', filesToUpload, fileDirs, oldFilenames);
        // Create a list of old files to be removed by splicing out any original files that have the same name as a new file
        for (let i = 0; i < filesToUpload.length; i++) {
            while (oldFilenames.indexOf(filesToUpload[i].uniqueFilename) >= 0) {
                oldFilenames.splice(oldFilenames.indexOf(filesToUpload[i].uniqueFilename), 1);
                filesToRemove.splice(oldFilenames.indexOf(filesToUpload[i].uniqueFilename), 1);
            }
        }
        console.log('Files to remove: ', filesToRemove);

        // Idea now is to upload each file one at a time so we can show the progress and then update the db afterwards
        const afterUploadCallback = (success) => {
            if (success) {
                // After successfully uploading our files, send the data to the db...
                axios
                    .post(streamId ? updateStreamCustomisationVarsRoute : updateCampaignCustomisationVarsRoute, {
                        campaignID: campaignId,
                        streamID: streamId, 
                        featureID: newFeatureID,
                        updatedVars: dataToSend,
                        oldFiles: filesToRemove,
                        withCredentials:true
                    })
                    .then(function (response) {
                        toast.success('Feature Customisation Updated.', );
                        setShowUploadProgress(false);

                        const savingComplete = () => {
                            if (removedDisruptorIds.current.length && removedDisruptorIds.current.length > 0) {
                                // we need to do some cleanup!
           
                                setShowCleaningUp(true);
                                cleanupFeatures(removedDisruptorIds.current, afterSaveCallback ? afterSaveCallback : exitBackToCampaignOverview);
                            } else {
    
                                if (afterSaveCallback === null) {
                
                                    exitBackToCampaignOverview();
                                } else {
                                    afterSaveCallback();
                                }
                            }
                        }

                        // Save theme data if we have it, this might well move...
                        // Also, it's a bit dirty updating all themes, so this is really just a test cos we will lose themes for all other features.
                        if (themeData) {
                            // Process theme data into a new object, making sure any files are converted back to flat string (filename)
                            const themeDataToSave = [];
                            for (let i = 0; i < themeData.length; i++) {
                                const themeDataObj = {
                                    index: themeData[i].index,
                                    featureId: featureId,
                                    themeName: themeData[i].themeName,
                                    themeData: JSON.parse(JSON.stringify(themeData[i].themeData)),
                                }
                                for (let tab in themeDataObj.themeData) {
                                    for (let varKey in themeDataObj.themeData[tab]) {
                                        for (let varName in themeDataObj.themeData[tab][varKey]) {
                                            for (let varIndex = 0; varIndex < themeDataObj.themeData[tab][varKey][varName].length; varIndex++) {
                                                for (let prop in themeDataObj.themeData[tab][varKey][varName][varIndex]) {
                                                    if (CUSTOMISED_URL_MAP.hasOwnProperty(themeDataObj.themeData[tab][varKey][varName][varIndex][prop]?.type?.toLowerCase())) {
                                                        themeDataObj.themeData[tab][varKey][varName][varIndex][prop] = themeDataObj.themeData[tab][varKey][varName][varIndex][prop].filename;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                themeDataToSave.push(themeDataObj);
                            }
                            console.log('[THEMES] --- Saving theme data: ', themeData, themeDataToSave);

                            axios
                                .post(updateMultipleCampaignThemesRoute, {
                                    campaignID: campaignId,
                                    themes: themeDataToSave,
                                }, {withCredentials: true})
                                .then((res) => {
                                    console.log('[THEMES] --- Themes saved: ', res.data);
                                    savingComplete();
                                });
                        } else {
                            savingComplete();
                        }


                        
                    })
                    .catch(function (error) {
                        setShowUploadProgress(false);
                        toast.error('Error - ' + error);
                    });
            } else {
                setShowUploadProgress(false);
            }
        }

        // Start uploading!
        if(!ignoreUpload)
        {
            setUploadedCount(0);
            setTotalUploads(filesToUpload.length);
            setCurrentUploadFilename('');
            setShowUploadProgress(true);
            uploadFiles(
                filesToUpload, fileDirs, filesToUpload.length, afterUploadCallback
            )
            if(filesToUpload.length == 0)   setShowUploadProgress(false);
        }
        else{
            afterUploadCallback(true);
        }
    }

    /* Themes */
    const [isThemeable, setIsThemeable] = useState(false);
    const [themeData, setThemeData] = useState(null);
    const themeDataRef = useRef(null);

    const [showingThemeList, setShowingThemeList] = useState(false);
    const showThemeList = () => {
        setShowingThemeList(true);
        setShowingDeletingTheme(false);
    }
    const closeThemeList = () => {
        setShowingThemeList(false);
    }

    const [showingAddTheme, setShowingAddTheme] = useState(false);
    const showAddTheme = () => {
        setShowingAddTheme(true);
        closeThemeList();
    }
    const closeAddTheme = () => {
        setShowingAddTheme(false);
        showThemeList();
    }

    const [showingDeletingTheme, setShowingDeletingTheme] = useState(false);

    const determineIfThemeable = (campaignData = null) => {
        // Is this themeable? Tabs can have a themeable flag, we need to set our isThemeable state and them pull in the theme data
        const featureData = findFeatureData(campaignData || campaignContext.campaignData);
        if (featureData) {
            console.log('[THEMES] --- Checking if themeable... Feature data: ', featureData);
            let themeable = false;
            for (let i = 0; i < featureData.feature.customisation.length; i++) {
                if (featureData.feature.customisation[i].themeable) {
                    themeable = true;
                    break;
                }
            }
            setIsThemeable(themeable);
            if (themeable) {
                console.log('[THEMES] --- This feature is themeable!');
                getThemeData(false, featureData);
            }
        }
    }

    const getThemeData = (autoShowThemeList = false, featureDataIn = null) => {
        axios
            .get(getCampaignFeatureThemesRoute, {params:{campaignID: campaignId, featureID: featureId}, withCredentials: true})
            .then((res) => {
                console.log('[THEMES] --- Got theme data: ', res.data, campaignContext.campaignData);
                themeDataRef.current = res.data.themes;
                setThemeData(res.data.themes);

                const fixThemeSrcUrls = (themeData) => {
                    const featureData = featureDataIn || findFeatureData(campaignContext.campaignData);
                    if (featureData) {
                        console.log('Fix theme srcUrls: ', featureData, themeData);
                        // const customisationData = featureData.feature.customisation;
                        // console.log('Customisation data: ', customisationData);
                        // console.log('-----');
                        for (let i = 0; i < themeData.length; i++) {
                            if (themeData[i].featureId === featureId && themeData[i].themeData) {
                                fixSrcUrls(featureData, null, themeData[i].themeData);
                            }
                        }

                        if (autoShowThemeList) {
                            showThemeList();
                        }
                    } else {
                        console.log('No feature data to fix urls with');
                        setTimeout(() => fixThemeSrcUrls(themeData), 100);
                    }
                }
                fixThemeSrcUrls(res.data.themes);
            })
    }

    const addTheme = (themeName, themeData = {}) => {
        axios
            .post(addCampaignThemeRoute, {campaignID: campaignId, featureID: featureId, themeName: themeName, themeData: themeData}, {withCredentials: true})
            .then((res) => {
                // Cycle through res.data.campaignThemes, find the matching theme (should match FeatureID and themeName),
                // inject index into our themeData as index: i then push the new themeData to our themeDataRef.current
                let themeAdded = false;
                for (let i = 0; i < res.data.campaignThemes.length; i++) {
                    if (res.data.campaignThemes[i].themeName === themeName && res.data.campaignThemes[i].featureId === featureId) {
                        themeDataRef.current.push({...res.data.campaignThemes[i], index: i});
                        setThemeData([...themeDataRef.current]);
                        toast.success("Theme added successfully");
                        themeAdded = true;
                        break;
                    }
                }
                if (!themeAdded) {
                    toast.error("Failed to add theme");
                }
                closeAddTheme();
            })
    }


    const deleteTheme = (themeIndex) => {
        closeThemeList();
        setShowingDeletingTheme(true);

        axios
            .post(deleteCampaignThemeRoute, {campaignID: campaignId, themeIndex: themeIndex}, {withCredentials: true})
            .then((res) => {
                toast.success("Theme deleted successfully");
                // getThemeData(true);
                for (let i = 0; i < themeDataRef.current.length; i++) {
                    if (themeDataRef.current[i].index === themeIndex) {
                        themeDataRef.current.splice(i, 1);
                        break;
                    }
                }
                for (let i = 0; i < themeDataRef.current.length; i++) {
                    if (themeDataRef.current[i].index > themeIndex) {
                        themeDataRef.current[i].index--;
                    }
                }
                setThemeData([...themeDataRef.current]);
                showThemeList();
            })
    }

    const [selectedTheme, setSelectedTheme] = useState(null);
    const [showingEditTheme, setShowingEditTheme] = useState(false);
    const editTheme = (theme) => {
        setSelectedTheme(theme);
        setShowingEditTheme(true);
        closeThemeList();
    }
    const closeEditTheme = () => {
        setSelectedTheme(null);
        setShowingEditTheme(false);
        showThemeList();
    }

    const [currentTab, setCurrentTab] = useState('visual');

    const [showLightbox, setShowLightbox] = useState(false);
    const [lightboxImgSrc, setLightboxImgSrc] = useState(null);
    const [imgDimensions, setImgDimensions] = useState(null);

    const imgRef = useRef(null);
    const zoomImage = (src) => {
        setLightboxImgSrc(src);
        setImgDimensions(null);
        setShowLightbox(true);

        imgRef.current = new Image();
        imgRef.current.onload = function() {
            setImgDimensions({x: this.width, y: this.height});
        }
        imgRef.current.src = src;
    }
    const closeLightBox = () => {
        setShowLightbox(false);
        imgRef.current = null;
    }

    const testVidUpload = () => {
        const hardCodedVars = {};
        hardCodedVars[featureId] = {};
        hardCodedVars[featureId]['customVideo'] = {};
        hardCodedVars[featureId]['customVideo']['videoFiles'] = {};
        hardCodedVars[featureId]['customVideo']['videoFiles']['customVideoFile'] = [
            {
                filename: 'newvid.webm',
                name: 'Test upload',
            }
        ]
        axios
        .post(updateCampaignCustomisationVarsRoute, {
            campaignID: campaignId, 
            updatedVars: hardCodedVars,
            withCredentials:true
        })
        .then(function (response) {
            console.log(response.data);
        })
        .catch(function (error) {
            toast.error('Error - ' + error);
        });
    }

    const [showingTooltip, setShowingTooltip] = useState(false);
    const [tooltipText, setTooltipText] = useState('');
    const [tooltipPos, setTooltipPos] = useState({left: 0, bottom: 0});
    const tooltipTimeout = useRef(null);
    const showTooltip = (text, e) => {
        setTooltipPosToMouse(e, true);
        setTooltipText(text);
        if (text.length > 0 && !showingTooltip && tooltipTimeout.current === null) {
            tooltipTimeout.current = setTimeout(() => setShowingTooltip(true), 300);
        }
    }
    const hideTooltip = (e) => {
        clearTimeout(tooltipTimeout.current);
        tooltipTimeout.current = null;
        setShowingTooltip(false);
    }
    const setTooltipPosToMouse = (e, force = false) => {
        if (showingTooltip || force) {
            const toolTipPos = {
                left: e.pageX + 'px',
                bottom: (window.innerHeight - e.pageY + 10) + 'px'
            }
            setTooltipPos(toolTipPos);
        }
    }

    const [showResetPresetsPanel, setShowResetPresetsPanel] = useState(false);
    const presetsData_ref = useRef(null);
    const [presetsData, setPresetsData] = useState(null);
    const presetVarsParent = useRef(null);
    const presetUpdatedCallback = useRef(null);
    const openResetPresetsPanel = (presetsData, varsParent, valsUpdatedCallback) => {
        presetsData_ref.current = presetsData;
        setPresetsData(presetsData);
        presetVarsParent.current = varsParent;
        presetUpdatedCallback.current = valsUpdatedCallback;
        setShowResetPresetsPanel(true);
    }
    const closeResetPresetsPanel = () => {
        setShowResetPresetsPanel(false);
    }
    const resetPresetsNow = (presetIndex) => {
        let presetsArray = presetsData_ref.current.vals;
        if (presetIndex >= 0) {
            presetsArray = presetsData_ref.current.presets[presetIndex].vals;
        }

        // first clear out the current vals array, but store it in case any vals in our preset are missing!
        const oldVals = [];
        while (presetVarsParent.current.length > 0) {
            oldVals.push(presetVarsParent.current.shift());
        }
        // now insert new preset vals
        for (let i = 0; i < presetsArray.length; i++) {
            presetVarsParent.current.push({...presetsArray[i]});
            // If a val doesn't exist in our preset keep the old value.
            // We do this so we can have partial presets where not every value is replaced...
            let valFixed = false;
            if (oldVals.length > i) {
                for (let prop in oldVals[i]) {
                    if (typeof presetVarsParent.current[i][prop] === 'undefined') {
                        presetVarsParent.current[i][prop] = oldVals[i][prop];
                        valFixed = true;
                    }
                }
            }
            // No val set? Let's try the default vals.
            if (!valFixed) {
                if (presetsData_ref.current.vals.length > i) {
                    const defaultVals = presetsData_ref.current.vals;
                    for (let prop in defaultVals[i]) {
                        if (typeof presetVarsParent.current[i][prop] === 'undefined') {
                            presetVarsParent.current[i][prop] = defaultVals[i][prop];
                            valFixed = true;
                        }
                    }
                }
            }
            // Or from our valSchema default settings..
            if (!valFixed) {
                const valSchema = presetsData_ref.current.valSchema;
                for (let prop in valSchema) {
                    if (typeof presetVarsParent.current[i][prop] === 'undefined' && typeof valSchema[prop].default !== 'undefined' && valSchema[prop.default !== null]) {
                        presetVarsParent.current[i][prop] = valSchema[prop].default;
                        valFixed = true;
                    }
                }
            }
        }

        fixSrcUrls(featureData);

        if (presetUpdatedCallback.current) {
            presetUpdatedCallback.current();
        }
    }

    const campaignData = campaignContext.campaignData;
    const featureData = findFeatureData(campaignData);

    // Adding disruptors
    const [showAddDistruptor, setShowAddDistruptor] = useState(false);
    const disruptorsUpdatedCallback = useRef(null);
    const existingDisruptorAddedCallback = useRef(null);

    const lastDisruptorAdded = useRef(null);
    const lastFeatureListUpdate = useRef(null);
    const [buildLeaderboard, setBuildLeaderboard] = useState(false);;
    const [newDisruptorLabel, setNewDisruptorLabel] = useState(null);

    const addDisruptor = (completedCallback, addExistingCallback, isLeaderboard=false, disruptorLabel = null) => {
        disruptorsUpdatedCallback.current = completedCallback;
        existingDisruptorAddedCallback.current = addExistingCallback;

        lastDisruptorAdded.current = null;
        lastFeatureListUpdate.current = null;
        setBuildLeaderboard(isLeaderboard);
        setNewDisruptorLabel(disruptorLabel);
        setShowAddDistruptor(true);
    };
    const closeAddDisruptor = () => {setShowAddDistruptor(false)};

    const addSelectedFeatureToList = (newFeatureData) => {
        console.log('Add feature to list: ', newFeatureData, campaignData.features);
        lastDisruptorAdded.current = newFeatureData;

        newFeatureData.feature.commands = [];
        const featureList = campaignData.features;
        featureList.push(newFeatureData);
        // changeEditedCampaignData('features', featureList);
        // addDisruptorToStartOfChain.current = addToStartOfChain;

        const featureData = [];
        for (let i = 0; i < campaignData.features.length; i++) {
            const chained = (i === campaignData.features.length - 1);
            const oneFeature = {
                _id: campaignData.features[i]._id, 
                feature: campaignData.features[i].feature._id, 
                contentLabel: campaignData.features[i].contentLabel || campaignData.features[i].feature.featureName, 
                varKey:campaignData.features[i].varKey,
                countIn:campaignData.features[i].countIn || false,
                countInTime:campaignData.features[i].countInTime || 10,
                isChained:chained || campaignData.features[i].isChained || false,
            };
            featureData.push(oneFeature);
        }
        console.log('Sending update: ', featureData);
        lastFeatureListUpdate.current = featureData;
        
        axios
        .post(updatedFeatureListRoute, {
            campaignID: campaignId,
            features: featureData,
        })
        .then(function (response) {
            toast.success("Campaign Updated!");

            setCampaignContext((oldValues) => {
                return { ...oldValues, initialising: true }
            });
            pullInCampaignData(false, disruptorAddedCallback);
            closeAddDisruptor();
            // getFeatureVars();

            // if (disruptorsUpdatedCallback.current) {
                // disruptorsUpdatedCallback.current(true, response);
            // }
        })
        .catch(function (error) {
            featureList.pop();
            // changeEditedCampaignData('features', featureList);
            closeAddDisruptor();

            if (disruptorsUpdatedCallback.current) {
                disruptorsUpdatedCallback.current(false);
            }
            toast.error('Error - ' + error.response.data.message);
            // console.log(error.response)
        });
    }

    const disruptorAddedCallback = (success, response) => {
        disruptorsUpdatedCallback.current(success, response, lastDisruptorAdded.current, lastFeatureListUpdate.current);
    }

    // We need to keep a log of added and removed disruptor ids.
    // The only way to get the correct id for a disruptor is to add it to our campaign.
    // However, this can result in un-used disruptors hanging around.
    // So we log them here to be dealt with when we finish customising.
    // When we save our changes, any removed disruptor ids need to be removed from the campaign,
    // if we exit without saving then any added disruptor ids need to be removed as they will no longer be needed.
    const addedDisruptorIds = useRef([]);
    const removedDisruptorIds = useRef([]);
    const logAddedDisruptor = (id) => {
        addedDisruptorIds.current.push(id);
        // We need to make sure we also have the customisation data for the new disruptor available for previewing...
        getCustomisationVars(campaignData, id);
    }
    const logRemovedDisruptor = (id) => {
        // We are only interested in removed disruptors that are in our added ist...
        if (addedDisruptorIds.current.indexOf(id) >= 0) {
            removedDisruptorIds.current.push(id);
        }
    }

    const cleanupFeatures = (featureIdsToRemoveList, afterCompleteCallback) => {
        const featureData = [];
        for (let i = 0; i < campaignData.features.length; i++) {
            if (featureIdsToRemoveList.indexOf(campaignData.features[i]._id) === -1) {
                const oneFeature = {
                    _id: campaignData.features[i]._id, 
                    feature: campaignData.features[i].feature._id, 
                    contentLabel: campaignData.features[i].contentLabel || campaignData.features[i].feature.featureName, 
                    varKey:campaignData.features[i].varKey,
                    countIn: campaignData.features[i].countIn,
                    countInTime: campaignData.features[i].countInTime,
                    isChained: campaignData.features[i].isChained || false,
                };
                featureData.push(oneFeature);
            }
        }

        axios
        .post(updatedFeatureListRoute, {
            campaignID: campaignId,
            features: featureData,
        })
        .then(function (response) {
            setShowCleaningUp(false);
            afterCompleteCallback();
        })
        .catch(function (error) {
            toast.error('Error - ' + error.response.data.message);
        });
    }

    const [showSaveBeforeCustomise, setShowSaveBeforeCustomise] = useState(false);
    const nextFeatureToCustomise = useRef(null);
    const prevFeatures = useRef([]);
    const customiseFeature = (featureId) => {
        nextFeatureToCustomise.current = featureId;
        setShowSaveBeforeCustomise(true);
    }
    const customiseNextFeatureNow = () => {
        if (prevFeatures.current.length === 0 || prevFeatures.current[prevFeatures.currentlength - 1] !== featureId) {
            prevFeatures.current.push(featureId);
            // console.log('Prev features: ', prevFeatures);
        }
        addedDisruptorIds.current = [];
        if (streamId) {
            navigate(TOP_LEVEL_NAV_ROUTES.CUSTOMISE_FEATURE_STREAM.replace(':campaignId', campaignId).replace(':streamId', streamId).replace(':featureId', nextFeatureToCustomise.current));
        } else {
            navigate(TOP_LEVEL_NAV_ROUTES.CUSTOMISE_FEATURE.replace(':campaignId', campaignId).replace(':featureId', nextFeatureToCustomise.current));
        }
        setShowUploadProgress(false);
    }
    const saveAndContinue = () => {
        setForceUpdate(true);
        setShowSaveBeforeCustomise(false);
       
        saveChanges(customiseNextFeatureNow);
    }
    

    const [showSaveBeforeEditQuiz, setShowSaveBeforeEditQuiz] = useState(false);
    const quizIdToEdit = useRef(null);
    const customiseQuiz = (quizID) => {
        quizIdToEdit.current = quizID;
        setShowSaveBeforeEditQuiz(true);
    }
    const customiseQuizNow = () => {
        console.log('CUSTOMISE QUIZ');
        if (streamId) {
            navigate(TOP_LEVEL_NAV_ROUTES.EDIT_CAMPAIGN_STREAM_FEATURE_QUIZ.replace(':id', quizIdToEdit.current).replace(':featureId', featureId).replace(':campaignId', campaignId).replace(':streamId', streamId));
        } else {
            navigate(TOP_LEVEL_NAV_ROUTES.EDIT_CAMPAIGN_FEATURE_QUIZ.replace(':id', quizIdToEdit.current).replace(':featureId', featureId).replace(':campaignId', campaignId));
        }
    }
    const saveAndEditQuiz = () => {
        setShowSaveBeforeCustomise(false);

        saveChanges(customiseQuizNow);
    }

    const cancelCustomise = () => {
        setShowSaveBeforeCustomise(false);
        setShowSaveBeforeEditQuiz(false);
    }

    /********************************************************
     * ADD QUIZ FUNCTIONALITY
     *******************************************************/
    const [showAddQuiz, setShowAddQuiz] = useState(false);
    const closeAddQuiz = () => {setShowAddQuiz(false)};
    const quizUpdatedCallback = useRef(null);
    const existingQuizAddedCallback = useRef(null);
    const addQuiz = (completedCallback, addExistingCallback) => {
        //disruptorsUpdatedCallback.current = completedCallback;
        //existingDisruptorAddedCallback.current = addExistingCallback;

       // lastDisruptorAdded.current = null;
        //lastFeatureListUpdate.current = null;
        quizUpdatedCallback.current = completedCallback;
        existingQuizAddedCallback.current = addExistingCallback;
        setShowAddQuiz(true);
    };





    const [featureVars, setFeatureVars] = useState({});
    const featureVarsRef = useRef(null);
    const getFeatureVars = () => {
        console.log('Get Campaign Feature Vars');
        axios
        .get(getCampaignFeatureVarsRoute, {
            params: { campaignID: campaignId },
            withCredentials:true
        })
        .then(function (response) {
            console.log('CAMPAIGN STREAM VARS',response.data);
            if (response.data.streamVars) {
                featureVarsRef.current = response.data.streamVars;
                setFeatureVars(featureVarsRef.current);
            }
            // getCustomisationVars();
        })
        .catch(function (error) {
            toast.error('Error - ' + error);
            // console.log(error.response)
        });
     


    }
    const updateFeatureVars = (updatedVars) => {
        axios
        .post(updateCampaignFeatureVarsRoute, {
            campaignID: campaignId, 
            updatedVars: updatedVars,
            withCredentials:true
        })
        .then(function (response) {
            console.log(response.data);




        })
        .catch(function (error) {
            toast.error('Error - ' + error);
        });
    }
    useEffect(getFeatureVars, [campaignId, featureId, streamId]);

    const [previewMode, setPreviewMode] = useState(false);
    const [previewPostFix, setPreviewPostFix] = useState('');
    const [currentFeature, setCurrentFeature] = useState(null);
    const currentFeatureRef = useRef(null);
    const [previewUpdate, setPreviewUpdate] = useState(0);
    const previewThisCustomisation = () => {
        const featureData = findFeatureData(campaignData);
        editFeatureSettings(featureData, "(Customising)");
    }
    const editFeatureSettings = (f, postFix = "") => {
        currentFeatureRef.current = f;
        setPreviewPostFix(postFix);
        setCurrentFeature(f);
        setPreviewMode(true);
    }

    const previewUpdateTimeoutId = useRef(null);
    const refreshPreview = () => {
        if (previewMode) {
            clearTimeout((previewUpdateTimeoutId.current));
            previewUpdateTimeoutId.current = setTimeout(
                () => setPreviewUpdate((currentVal) => currentVal + 1),
                500
            );
        }
    }

    const reportFeatureVarsChanged = (onlyUpdateDisruptor = false) => {
        if (!onlyUpdateDisruptor) {
            updateFeatureVars(featureVarsRef.current.current);
        }
        console.log('Report vars to preview: ', featureVarsRef.current.current[currentFeatureRef.current._id]);
        postMessageToPreviewFrame(POST_MESSAGE_OUT_TYPES.SETTINGS_VARS_RECEIVED, featureVarsRef.current.current[currentFeatureRef.current._id]);
    }

    const reportCustomisationVarsChanged = (updatedVars, featureID) => {

        postMessageToPreviewFrame(POST_MESSAGE_OUT_TYPES.CUSTOMISATION_VARS_RECEIVED, updatedVars.current[featureID]);
         
     }

    const [showingFunctionUnavilablePopup, setShowingFunctionUnavilablePopup] = useState(false);
    const openFunctionUnavilablePopup = () => {
        setShowingFunctionUnavilablePopup(true);
    }
    const closeFunctionUnavilablePopup = () => {
        setShowingFunctionUnavilablePopup(false);
    }

     // Special functions that can be triggered by buttons in the feature controls in place of the normal feature commands...
    const BUTTON_FUNCTIONS = {
        reportFeatureVarsChanged: (featureId, param) => reportFeatureVarsChanged(),
        startLivePoll: (featureId, param) => {
            reportFeatureVarsChanged();
            setTimeout(() => {
                sendFeatureCommand(null, 'START_POLL', featureId, param);
                // postMessageToPreviewFrame(POST_MESSAGE_OUT_TYPES.COMMAND_RECEIVED, { command: 'START_POLL', extraData: {username:'CampignStudio', hashtag:'Manual Press', commandParam: param} })
            }, 100);
        },
        createNewLivePoll: (featureId, param) => {
            // openRestrictedCustomisationPopup(featureId);
            openFunctionUnavilablePopup();
        },
    }
    const sendFeatureCommand = (e, commandString, featureId, param = null) => {
        if (e) {
            e.preventDefault();
        }
        if (BUTTON_FUNCTIONS[commandString]) {
            console.log('Running button function: ', commandString, featureId, param);
            BUTTON_FUNCTIONS[commandString](featureId, param);
        } else {
            postMessageToPreviewFrame(POST_MESSAGE_OUT_TYPES.COMMAND_RECEIVED, { command: commandString, extraData: {username:'CampignStudio', hashtag:'Manual Press', commandParam: param} });
        }
    }

    const pCountRef = useRef(1);
    const exportPreset = () => {
        // Function to download data to a file
        const download = (data, filename, type) => {
            var file = new Blob([data], {type: type});
            if (window.navigator.msSaveOrOpenBlob) // IE10+
                window.navigator.msSaveOrOpenBlob(file, filename);
            else { // Others
                var a = document.createElement("a"),
                        url = URL.createObjectURL(file);
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                setTimeout(function() {
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);  
                }, 0); 
            }
        }

        const dataSchema = findFeatureData(campaignContext.campaignData).feature.customisation;
        console.log('Data schema: ', dataSchema);
        // Rebuild the data object save as a file...
        const rebuiltDataStructure = {};
        for (let i = 0; i < dataSchema.length; i++) {
            // Tab groups
            const tabKey = dataSchema[i].tabKey;
            for (let j = 0; j < dataSchema[i].options.length; j++) {
                // Tab group options
                const varKey = dataSchema[i].options[j].varKey;
                for (let k = 0; k < dataSchema[i].options[j].variables.length; k++) {
                    const varName = dataSchema[i].options[j].variables[k].varName;
                    // We now have all the keys to build our data structure
                    if (rebuiltDataStructure[tabKey] === undefined) {
                        rebuiltDataStructure[tabKey] = {};
                    }
                    if (rebuiltDataStructure[tabKey][varKey] === undefined) {
                        rebuiltDataStructure[tabKey][varKey] = {};
                    }
                    if (rebuiltDataStructure[tabKey][varKey][varName] === undefined) {
                        rebuiltDataStructure[tabKey][varKey][varName] = [];
                    }
                    // Drop any invalid data (maybe they forgot to select a file)...
                    // console.log('--- SAVE DATA VALIDATION ---');
                    /* while (customisationVarsRef.current.current[featureId][tabKey][varKey][varName].length > 0 && validateLastVal(customisationVarsRef.current.current[featureId][tabKey][varKey][varName]) === false) {
                        console.log('--- REMOVED!!! ---');
                        customisationVarsRef.current.current[featureId][tabKey][varKey][varName].pop();
                    } */
                    // While loop and pop doesn't do the trick - what if I add 3 values and the middle one is valid, the last one will be removed, but the other invalid one won't
                    
                    // Commented this out as it's stopping me exporting a full data set for developing the claw feature
                    /* for (let validate_i = customisationVarsRef.current.current[featureId][tabKey][varKey][varName].length - 1; validate_i >= 0; validate_i--) {
                        if (validateValAtIndex(customisationVarsRef.current.current[featureId][tabKey][varKey][varName], dataSchema[i].options[j].variables[k].valSchema, validate_i) === false) {
                            // console.log('--- Validation (removed): ', tabKey, varKey, varName, validate_i, JSON.parse(JSON.stringify(customisationVarsRef.current.current[featureId][tabKey][varKey][varName])), JSON.parse(JSON.stringify(dataSchema[i].options[j].variables[k].valSchema)));
                            customisationVarsRef.current.current[featureId][tabKey][varKey][varName].splice(validate_i, 1);
                        }
                    } */
                    
                    // Now add in our new data, being careful to only send updated vars and preserve isDefault
                    for (let l = 0; l < customisationVarsRef.current.current[featureId][tabKey][varKey][varName].length; l++) {
                        // if (customisationVarsRef.current.current[featureId][tabKey][varKey][varName][l].updated) {
                            const newDataObj = {};
                            for (let prop in dataSchema[i].options[j].variables[k].valSchema) {
                                // Don't add any data that for controls marked as isCosmetic (helps to cut down on data we don't need to save)
                                let includeData = true;
                                const dataType = dataSchema[i].options[j].variables[k].valSchema[prop].type;
                                for (let typeProp in FEATURE_CONTROLS_TYPES) {
                                    if (FEATURE_CONTROLS_TYPES[typeProp].type.toLowerCase() === dataType.toLowerCase()) {
                                        if (FEATURE_CONTROLS_TYPES[typeProp].isCosmetic) {
                                            includeData = false;
                                        }
                                        break;
                                    }
                                }
                                if (includeData) {
                                    newDataObj[prop] = customisationVarsRef.current.current[featureId][tabKey][varKey][varName][l][prop];
                                }
                            }
                            newDataObj.isDefault = customisationVarsRef.current.current[featureId][tabKey][varKey][varName][l]["isDefault"];
                            rebuiltDataStructure[tabKey][varKey][varName].push(newDataObj);
                        // }
                    }
                }
            }
        }
        console.log('Built data to send: ', rebuiltDataStructure);

        const dataToSend = {};
        dataToSend[featureId] = rebuiltDataStructure;

        const data = JSON.stringify(dataToSend[featureId], null, 2);
        download(data, featureData.feature.featureName + '_preset_' + pCountRef.current + '.json', 'text/plain');
        pCountRef.current++;
    }

    const [showFontSelector, setShowFontSelector] = useState(false);
    const fontSelectedFunc_ref = useRef(null);
    const currentFont_ref = useRef(null);
    const openFontSelector = (currentFont, selectedFunc) => {
        currentFont_ref.current = currentFont;
        fontSelectedFunc_ref.current = selectedFunc;
        setShowFontSelector(true);
    }
    const closeFontSelector = () => {
        setShowFontSelector(false);
    }

    const [showCommonFontPopup, setShowCommonFontPopup] = useState(false);
    const addValueToThemeFunc_ref = useRef(null);
    const openCommonFontPopup = (addValueToThemeFunc = null) => {
        setShowCommonFontPopup(true);
        addValueToThemeFunc_ref.current = addValueToThemeFunc;
    }
    const closeCommonFontPopup = () => {
        setShowCommonFontPopup(false);
    }
    const setCommonFont = (font) => {
        const featureData = findFeatureData(campaignData);
        const isTheme = showingEditTheme;
        const addToThemeFunc = addValueToThemeFunc_ref.current;
        var updated = false;
        // console.log('Set common font for feature to font: ', featureData, font);
        if (featureData && featureData.feature && featureData.feature.customisation) {
            // Tabs
            for (let i = 0; i < featureData.feature.customisation.length; i++) {
                // Tab Options
                for (let j = 0; j < featureData.feature.customisation[i].options.length; j++) {
                    // Variables
                    for (let k = 0; k < featureData.feature.customisation[i].options[j].variables.length; k++) {
                        for (let prop in featureData.feature.customisation[i].options[j].variables[k].valSchema) {
                            if (featureData.feature.customisation[i].options[j].variables[k].valSchema[prop].type === 'font') {
                                // This is a font we need to set
                                const tabKey = featureData.feature.customisation[i].tabKey;
                                const varKey = featureData.feature.customisation[i].options[j].varKey;
                                const varName = featureData.feature.customisation[i].options[j].variables[k].varName;
                                // console.log('Setting common font: ', font, tabKey, varKey, varName, prop, customisationVarsRef.current.current[featureId]);
                                for (let l = 0; l < customisationVarsRef.current.current[featureId][tabKey][varKey][varName].length; l++) {
                                    if (!isTheme) {
                                        customisationVarsRef.current.current[featureId][tabKey][varKey][varName][l][prop] = font;
                                    } else {
                                        if (addToThemeFunc) {
                                            // addValueToTheme = (tabKey, familyKey, varKey, varIndex, schemaKey, value, varSchema)

                                            addToThemeFunc(tabKey, varKey, varName, l, prop, font, featureData.feature.customisation[i].options[j].variables[k].valSchema[prop]);
                                        }
                                    }
                                    updated = true;
                                }
                            }
                        }
                    }
                }
            }
        }
        if (updated) {
            setCustomisationVars({...customisationVarsRef.current});
        }
    }

    if(saveButtonOnly)
    {
        return(<div><button className="standard-button tight" onClick={() => {saveChanges(null, true, forcedFeatureID)}}>Save</button></div>)
    }
    else{
    return (
        
        <>
        <div className="content-page-container customise-holder">
            {campaignData && featureData && tabData.current &&
            <>
                <div className="content-page-top">
                    <div className="fl-column grow">
                        <div className="fl-row grow">
                            <div>
                                <h1>Customise Feature</h1>
                            </div>
                            <div className='tier-badge'>
                                <img src={'/assets/tier-badges/badge-fill-' + getCampaignTierDataFromDbString(campaignData.campaignTier).imgString + '.svg'} alt="" />
                            </div>
                            <div className="grow"></div>
                            { authContext.userData.userLevel >= ACCOUNT_TYPES.koko.level &&
                                <div className="main-top-options" onClick={exportPreset}>Export Preset {IconJsxer.GetIcon(IconJsxer.ICONS.undelete, IconJsxer.ICON_STYLES.customiseTopOptions)}</div>
                            }
                            <div className="main-top-options" onClick={openCommonFontPopup}>Set Common Font {IconJsxer.GetIcon(IconJsxer.ICONS.font, IconJsxer.ICON_STYLES.customiseTopOptions)}</div>
                            {isThemeable &&
                                <div className="main-top-options" onClick={showThemeList}>Themes {IconJsxer.GetIcon(IconJsxer.ICONS.animated, IconJsxer.ICON_STYLES.customiseTopOptions)}</div>
                            }
                            <div className="main-top-options" onClick={previewThisCustomisation}>Preview {IconJsxer.GetIcon(IconJsxer.ICONS.preview, IconJsxer.ICON_STYLES.customiseTopOptions)}</div>
                            <div className="main-top-options" onClick={exitWithoutSaving}>Exit without saving {IconJsxer.GetIcon(IconJsxer.ICONS.checkX, IconJsxer.ICON_STYLES.customiseTopOptions)}</div>
                            <div className="main-top-options" onClick={() =>{saveChanges(null)}}>Save Changes {IconJsxer.GetIcon(IconJsxer.ICONS.save, IconJsxer.ICON_STYLES.customiseTopOptions)}</div>
                        </div>
                        <div className="fl-row grow">
                            <div className="bold-text">
                                {campaignData.campaignName} 
                                <span className="light-text"> ({campaignData.client})</span>
                            </div>
                            <div className="bold-text">
                                {featureData.contentLabel} 
                                <span className="light-text"> ({featureData.feature.featureName})</span>
                            </div>
                            <div className="grow"></div>
                            <div className="fl-row shrink">
                                <div className={`bold-text${advancedMode ? '' : ' light-text'}`}>Advanced Mode</div>
                                <Toggle id='advanced_mode' currentState={advancedMode} toggleFunc={(e, id) => {setAdvancedMode(!advancedMode)}} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content-page-content-container">
                    <div className="content-page-main-content">
                        <TabbedPanel
                            tabs={
                                /* POTENTIAL_TABS */
                                tabData.current
                            }
                            switchTabCallback={(tabId, tabData) => setCurrentTab(tabData.id)}
                            initialTab={0}
                            forceUpdate={forceUpdate}
                            tabWidth={tabData.current.length < 5 ? '20%' : 100}
                        >

                            <div className="tab-content">
                                <div className="co-tab-content no-panel-shadow">

                                    {customisationVars.current && customisationVars.current[featureId] &&
                                        <CustomisationControls
                                            tabId={currentTab}
                                            controlsData={featureData.feature.customisation}
                                            customisationVars={customisationVars}
                                            featureId={featureId}
                                            featureData={featureData}
                                            campaignData={campaignData}
                                            handleRemovedFile={handleRemovedFile}
                                            zoomFunction={zoomImage}
                                            showTooltipFunc={showTooltip}
                                            hideTooltipFunc={hideTooltip}
                                            openResetPresetsPanel={openResetPresetsPanel}
                                            addDisruptor={addDisruptor}
                                            addQuiz={addQuiz}
                                            customiseQuiz={customiseQuiz}
                                            logAddedDisruptor={logAddedDisruptor}
                                            logRemovedDisruptor={logRemovedDisruptor}
                                            showFeaturePreview={editFeatureSettings}
                                            customiseFeature={customiseFeature}
                                            refreshPreview={refreshPreview}
                                            openFontSelectorFunc={openFontSelector}
                                            advancedMode={advancedMode}
                                        />
                                    }

                                </div>
                            </div>

                        </TabbedPanel>
                    </div>
                </div>
            </>
            }
        </div>
        {showingThemeList &&
            <ThemeListPopup
                closePanelFunc={closeThemeList}
                createNewTheme={showAddTheme}
                deleteTheme={deleteTheme}
                editTheme={editTheme}
                themes = {themeData}
            />
        }
        {showingAddTheme &&
            <CreateThemePopup
                closePanelFunc={closeAddTheme}
                addTheme={addTheme}
            />
        }
        {showingDeletingTheme &&
            <DeletingThemePopup />
        }
        {showingEditTheme &&
                <EditThemePopup
                    closePanelFunc={closeEditTheme}
                    themeData={selectedTheme}
                    // updateTheme={updateTheme}

                    customisationVars={customisationVars}
                    featureId={featureId}
                    featureData={featureData}
                    campaignData={campaignData}

                    handleRemovedFile={handleRemovedFile}
                    zoomFunction={zoomImage}
                    showTooltipFunc={showTooltip}
                    hideTooltipFunc={hideTooltip}
                    openResetPresetsPanel={openResetPresetsPanel}
                    addDisruptor={addDisruptor}
                    addQuiz={addQuiz}
                    customiseQuiz={customiseQuiz}
                    logAddedDisruptor={logAddedDisruptor}
                    logRemovedDisruptor={logRemovedDisruptor}
                    showFeaturePreview={editFeatureSettings}
                    customiseFeature={customiseFeature}
                    refreshPreview={refreshPreview}
                    openFontSelectorFunc={openFontSelector}
                    openCommonFontPopup={openCommonFontPopup}
                />
        }
        {showLightbox &&
            <div className="blackout lightbox">
                <img src={lightboxImgSrc} alt="" />
                <div className="fl-row">
                    <div className="grow"></div>
                    {imgDimensions &&
                        <div>{imgDimensions.x} x {imgDimensions.y}</div>
                    }
                    <div className="grow"></div>
                </div>
                <div className="close-btn" onClick={closeLightBox}>
                    {IconJsxer.GetIcon(IconJsxer.ICONS.closeX, IconJsxer.ICON_STYLES.roundPanelButton)}
                </div>
            </div>
        }
        {showUploadProgress &&
            <PopUpPanel showCloseBtn={false} className="prog-panel">
                <div className="prog">
                        <div className="fl-column">
                            <h1>Uploading Files</h1>

                            <div className="grow"></div>

                            <div>{uploadedCount} out of {totalUploads}</div>
                            <div>Completed</div>

                            <div className="grow"></div>

                            {uploadedCount < totalUploads &&
                                <div className="bold-text">Uploading {currentUploadFilename}</div>
                            }
                            {uploadedCount >= totalUploads &&
                                <div className="bold-text">Finishing Up</div>
                            }

                            <div className="grow"></div>

                        </div>
                </div>
            </PopUpPanel>
        }
        {showResetPresetsPanel &&
            <ResetPresets
                closePanelFunc={closeResetPresetsPanel}
                label={presetsData.label}
                presets={presetsData.presets}
                resetPresetFunc={resetPresetsNow}
            />
        }
        {showAddDistruptor &&
            <AddDisruptor
                closePanelFunc={closeAddDisruptor} 
                campaignId={campaignId} 
                addSelectedFeatureToListFunc={addSelectedFeatureToList}
                addExistingDisruptorToListFunc={existingDisruptorAddedCallback.current}
                campaignTier={campaignData.campaignTier}
                isIntro={false}
                isOutro={false}
                chainableOnly={true}
                showSelectExistingOption={true}
                campaignFeatures={campaignData.features}
                buildLeaderboard={buildLeaderboard}
                disruptorContentLabel={newDisruptorLabel}
            />
        }
        {showAddQuiz &&
            <AddQuiz
                closePanelFunc={closeAddQuiz} 
                campaignId={campaignId} 
                showSelectExistingOption={true}

                genericQuizzes={genericQuizzes}
                campaignQuizzes={campaignQuizzes}
       
                addExistingQuizToList={existingQuizAddedCallback.current}
                />
        }
        {showFontSelector &&
            <FontSelectorPopup
                closePanelFunc={closeFontSelector}
                selectFunc={fontSelectedFunc_ref.current}
                font={currentFont_ref.current}
            />
        }
        {showCommonFontPopup &&
            <CommonFontPopup
                closePanelFunc={closeCommonFontPopup}
                setCommonFontFunc={setCommonFont}
                editingTheme={showingEditTheme}
            />
        }
        {showCleaningUp &&
            <PopUpPanel
                showCloseBtn={false}
                className="prog-panel"
            >
                <div className="prog">
                    <div className="fl-column">
                        <h1>Cleaning Up</h1>
                        <div className="grow"></div>
                        <div className="bold-text">One moment...</div>
                        <div className="grow"></div>
                    </div>
                </div>
            </PopUpPanel>
        }
        {showSaveBeforeCustomise &&
            <PopUpPanel
                showCloseBtn={false}
                className="prog-panel"
            >
                <div className="prog">
                    <div className="fl-column">
                        <h1>Save and Continue?</h1>
                        <div className="grow"></div>
                        <div className="bold-text">Before you can customise another feature you must save your changes, <br />or they will be lost.</div>
                        <div className="grow"></div>
                        <div className="fl-row">
                            <button className="standard-button" onClick={saveAndContinue}>Save and Continue</button>
                            <button className="standard-button" onClick={cancelCustomise}>Cancel</button>
                        </div>
                    </div>
                </div>
            </PopUpPanel>
        }
        {showSaveBeforeEditQuiz &&
            <PopUpPanel
                showCloseBtn={false}
                className="prog-panel"
            >
                <div className="prog">
                    <div className="fl-column">
                        <h1>Save and Continue?</h1>
                        <div className="grow"></div>
                        <div className="bold-text">Before you can edit a quiz you must save your changes, <br />or they will be lost.</div>
                        <div className="grow"></div>
                        <div className="fl-row">
                            <button className="standard-button" onClick={saveAndEditQuiz}>Save and Continue</button>
                            <button className="standard-button" onClick={cancelCustomise}>Cancel</button>
                        </div>
                    </div>
                </div>
            </PopUpPanel>
        }
            {previewMode &&
                <FeaturePreview
                    campaignId={campaignId}

                    currentFeature={currentFeature}
                    titlePostFix={previewPostFix}
                    featureVars={featureVars}
                    streamId={streamId}
                    customisationVars={customisationVars}
                    closeFunc={() => setPreviewMode(false)}

                    startControlsExpanded={currentFeature.feature.featureType === 'feature'}
                    startMaximized={false}
                    resizeable={true}
                    startPosX={'right'}
                    showQuickPlayButton={true}
                    autoStart={true}
                    previewUpdate={previewUpdate}

                    _reportFeatureVarsChanged={reportFeatureVarsChanged}
                    _reportCustomisationVarsChanged={reportCustomisationVarsChanged}
                    
                    _sendFeatureCommandFunc={sendFeatureCommand}
                    _showTooltip={showTooltip}
                    _hideTooltip={hideTooltip}
                />
            }

            {showingFunctionUnavilablePopup &&
                <FunctionUnavailableInPreviewPopup
                    closePanelFunc={closeFunctionUnavilablePopup}
                />
            }

            {showingTooltip &&
                <div className="tw-tooltip" style={tooltipPos} dangerouslySetInnerHTML={{__html: tooltipText}}>
                </div>
            }
        </>
    );}
}

export default CustomiseFeature;
