import PropTypes from 'prop-types';
import DebugLogger from "../../../helpers/DebugLogger";

import "./ToggleCheck.scss";

import { IconJsxer } from "../../../helpers/IconHelper";

/**
 * ToggleCheck is a version of CheckBox with no label and two states, a green checked state and a red X state.
 * It doesn't automatically toggle, you must provide a toggleFunc function and update it's currentState prop.
 * @param {*} props 
 * @returns 
 */
const ToggleCheck = (props) => {
    const { id, currentState, toggleFunc, uncheckedStyle} = props;
    const checked = currentState === 'checked' || currentState === true;

    if (toggleFunc === null) {
        DebugLogger.warn('You should provide a function called `toggleFunc(fieldVal[, id])` to ToggleCheck.');
    }

    const toggleCheck = (e) => {
        if (toggleFunc) {
            toggleFunc(e, id);
        }
    }

    return (
        <div className="toggle-check-content">
            <div onClick={props.enabled ? toggleCheck : null} className={`toggle-check-holder${props.enabled ? '' : ' disabled'}`}>
                <div className="toggle-check-outer">
                    <div className={`toggle-check ${checked ? 'checked' : `unchecked-${uncheckedStyle}`}`}>
                        {IconJsxer.GetIcon(checked ? IconJsxer.ICONS.check : (uncheckedStyle === 'x' ? IconJsxer.ICONS.checkX : IconJsxer.ICONS.plus), IconJsxer.ICON_STYLES.roundFormControl)}
                    </div>
                </div>
            </div>
        </div>
    )
}

ToggleCheck.propTypes = {
    id: PropTypes.string,
    currentState: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    toggleFunc: PropTypes.func,
    uncheckedStyle: PropTypes.string,
    enabled: PropTypes.bool,
};

ToggleCheck.defaultProps = {
    id: '',
    currentState: false,
    toggleFunc: null,
    uncheckedStyle: 'x',
    enabled: true,
}

export default ToggleCheck;
