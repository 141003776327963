import React, { useState, useEffect, useRef } from "react";

import PopUpPanel from "../../PopUpPanel/PopUpPanel";

import { HexColorPicker } from "react-colorful";

import "./ColorPicker.scss";
import FormField from "../FormField/FormField";

const colorPickerPopUpRefs = {
    // Just a store for references to our instance of the pop up component
}
export const ShowColorPicker = (color, setFunc, label = null) => {
    if (colorPickerPopUpRefs.showColorPicker) {
        colorPickerPopUpRefs.showColorPicker(color, setFunc, label);
    }
}

const ColorPickerPopUp = (props) => {
    const [currentVal, setCurrentVal] = useState('#000000');
    const [colorPickerVisible, setColorPickerVisible] = useState(false);
    const [varLabel, setVarLabel] = useState(null);

    const setFunc_ref = useRef(null);
    const showColorPicker = (color, setFunc, varLabel) => {
        setFunc_ref.current = setFunc;
        setCurrentVal(color);
        setColorPickerVisible(true);
        setVarLabel(varLabel);
    }
    colorPickerPopUpRefs.showColorPicker = showColorPicker;

    const confirmNewColor = (e = null) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        if (setFunc_ref.current) {
            setFunc_ref.current(currentVal);
        }

        closePanelFunc();
    }

    const closePanelFunc = () => {
        // Should send the update to a setFunc
        setColorPickerVisible(false);
    }

    return (
        <>
            {colorPickerVisible &&
                <PopUpPanel closePanelFunc={closePanelFunc} className="color-pop">
                    <div className="form-holder co-popup-content">
                        <div className="form-field-content-holder">
                            <div className="form-field-content">
                                <h2>Choose Colour</h2>
                                <div className="fl-row">
                                    <div className="grow"></div>
                                    <div className="fl-column">
                                        {varLabel &&
                                            <div className="var-label">{varLabel}</div>
                                        }

                                        <HexColorPicker color={currentVal} onChange={setCurrentVal} />
                                        <div className="fl-row">
                                            <div className="grow"></div>

                                            <div className="current-color" style={{ background: currentVal }}></div>

                                            <FormField
                                                id={"colourPicker"}
                                                placeholder="#000000"
                                                showLabel={false}
                                                type="text"
                                                maxLength={7}
                                                setFunc={setCurrentVal}
                                                confirmChangeFunc={setCurrentVal}
                                                value={currentVal}
                                                showError={false}
                                                className="c-hex-field"
                                            />

                                            <div className="grow"></div>
                                        </div>

                                        <button className="standard-button" onClick={confirmNewColor}>Submit</button>
                                    </div>
                                    <div className="grow"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </PopUpPanel>
            }
        </>
    )
}

export default ColorPickerPopUp;
