import PropTypes from 'prop-types';
import DebugLogger from "../../../helpers/DebugLogger";

import { HexColorPicker } from "react-colorful";

import "./ColorPicker.scss";
import FormField from '../FormField/FormField';
import { useEffect, useRef, useState } from 'react';
import { ShowColorPicker } from './ColorPickerPopUp';

const ColorPicker = (props) => {
    const firstValChange = useRef(true);
    const { showLabel, inlineLabel, label, labelNote, id, setFunc, value, className } = props;

    if (showLabel && label === 'Field Label') {
        DebugLogger.warn('No label provided to FormField, using default...');
    }

    const [currentVal, setCurrentVal] = useState('#000000');
    const setVal = (newVal) => {
        console.log('Confirm colour val: ', newVal);
        if (newVal.substring(0, 1) !== '#') {
            newVal = '#' + newVal;
        }
        if (/^#[0-9A-F]{6}$/i.test(newVal)) {
            setCurrentVal(newVal);
            if (!firstValChange.current || props.immediateChange) {
                if (setFunc) {
                    setFunc(newVal);
                }
            }
            firstValChange.current = false;
        } else {
            setCurrentVal(value);
        }
    }
    useEffect(
        () => {
            console.log('[useEffect] Setting colour val: ', id, value);
            setVal(value || '#000000');
        }
        , [value]
    );

    const showColorPickerPopUp = () => {
        ShowColorPicker(currentVal, setVal, null);
    }

    return (
        <div className={`form-field-holder${className ? ` ${className}` : ''}`}>

            <div className="form-field-content-holder">

                {showLabel && !inlineLabel && label != null &&
                    <div className="form-field-content label">
                        {label}
                    </div>
                }
                {showLabel && !inlineLabel && labelNote != null && labelNote !== '' &&
                    <div className="form-field-content label-note" dangerouslySetInnerHTML={{__html: labelNote}}>
                        {/*labelNote*/}
                    </div>
                }

                <div className="form-field-content">
                    {showLabel && inlineLabel && label != null &&
                        <div className="label">
                            {label}
                        </div>
                    }

                    <div className="current-color" style={{ background: currentVal }} onClick={showColorPickerPopUp}></div>

                    <FormField
                        id={id || "colourPicker"}
                        placeholder="#000000"
                        showLabel={false}
                        type="text"
                        maxLength={7}
                        setFunc={setCurrentVal}
                        confirmChangeFunc={setVal}
                        value={currentVal}
                        showError={false}
                        className="c-hex-field"
                    />

                    <div className='grow'></div>
                </div>

            </div>

        </div>
    );
};

ColorPicker.propTypes = {
    showLabel: PropTypes.bool,
    inlineLabel: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    labelNote: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    id: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    setFunc: PropTypes.func,
    confirmChangeFunc: PropTypes.func,
    immediateChange: PropTypes.bool,
};

ColorPicker.defaultProps = {
    showLabel: true,
    inlineLabel: false,
    label: 'Field Label',
    labelNote: '',
    id: '',
    value: '#000000',
    className: '',
    setFunc: null,
    confirmChangeFunc: null,
    immediateChange: false,
}

export default ColorPicker;
