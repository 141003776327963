import PropTypes from 'prop-types';
import DebugLogger from "../../helpers/DebugLogger";

import "./ClosePanelBtn.scss";

import { IconJsxer } from '../../helpers/IconHelper';

const ClosePanelBtn = (props) => {
    if (props.clickFunc === null || props.clickFunc === undefined) {
        DebugLogger.warn('No clickFunc provided to ClosePanelBtn...');
    }

    return (
        <div className="close-btn" onClick={props.clickFunc}>
            {IconJsxer.GetIcon(IconJsxer.ICONS.closeX, IconJsxer.ICON_STYLES.roundPanelButton)}
        </div>
    );
}

ClosePanelBtn.propTypes = {
    clickFunc: PropTypes.func,
};

ClosePanelBtn.defaultProps = {
    clickFunc: null,
}

export default ClosePanelBtn;
