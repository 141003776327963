import "./Error404.scss";

const Error404 = () => {
    return (
        <div className="content-page-container">
            <div className="content-page-content-container">
                <div className="content-page-main-content">
                    <div className="fl-column">

                        <div className="grow"></div>

                            <h1>Error 404</h1>

                        <div className="grow"></div>

                            <h2>Page Not Found</h2>

                        <div className="grow"></div>
                        <div className="grow"></div>

                            <p>Please check the url of the page you are trying to access, or <a href='/'>Click Here</a> to return to the home page.</p>
                    
                        <div className="grow"></div>
                        <div className="grow"></div>
                        <div className="grow"></div>

                    </div>

                </div>
            </div>
        </div>
    );
}

export default Error404;
