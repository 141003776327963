import PropTypes from 'prop-types';
import DebugLogger from "../../../helpers/DebugLogger";

import { useState } from "react";
import "./SearchBox.scss";
import { IconJsxer } from "../../../helpers/IconHelper";

const searchComponentSetRef = {current: null};
export const updateComponentSearchTerm = (_searchTerm) => {
    if (searchComponentSetRef.current) {
        searchComponentSetRef.current(_searchTerm);
    }
}

const SearchBox = (props) => {
    const [searchTerm, setSearchTerm] = useState('');
    searchComponentSetRef.current = setSearchTerm;

    const submitSearch = (e) => {
        e.preventDefault();
        if (props.searchFunc) {
            props.searchFunc(e, searchTerm);
        } else {
            DebugLogger.error('You should provide a function called `searchFunc(event, searchTerm)` to SearchBox.');
        }
    }

    return (
        <form className="search-box" onSubmit={submitSearch}>
            <input
                type="text"
                id="search"
                name="search"
                placeholder="Search"
                onChange={
                    (event) => { 
                        console.log('Search term changed: ', event);
                        setSearchTerm(event.target.value);
                    }
                }
                value={searchTerm}
            />
            <div className="icon">{IconJsxer.GetIcon(IconJsxer.ICONS.search, IconJsxer.ICON_STYLES.searchBox)}</div>
        </form>
    );
}

SearchBox.propTypes = {
    searchFunc: PropTypes.func,
    initialSearchTerm: PropTypes.string,
}

export default SearchBox;
