import PropTypes from 'prop-types';
import "./StreamAnalyticsSummary.scss";
import { toast } from "react-toastify";
import axios from "../../api/axios";
import { convertMSToHMS, getLongDateString } from "../../helpers/Dates";
import { disableStreamSessionRoute } from "../../api/routes";
import { TwitchIconSvg } from '../../assets/assets';
import HeadedPanel from '../HeadedPanel/HeadedPanel';
import { IconJsxer } from '../../helpers/IconHelper';
import Toggle from '../forms/Toggle/Toggle';
import { parseTimezone } from '../../helpers/TimeZones';
import CirclePerc from '../CirclePerc/CirclePerc';
import LeftColorBlockBox from '../LeftColorBlockBox/LeftColorBlockBox';
/*
    If you want to restyle this, override the css!
*/
const StreamAnalyticsSummary = (props) => {

    const disableSessionAnalytics = (e, featureStartTime) => {
        e.preventDefault();
        axios
            .post(disableStreamSessionRoute, {
                campaignID: props._campaignID,
                featureStartTime: featureStartTime,
                withCredentials: true
            })
            .then(function (response) {
                props._setCampaignDataFunc(response.data.analyticsData.streams);
                //setCampaignSummaryAnalytics(response.data.analyticsData.streams);
            })
            .catch(function (error) {
                toast.error('Error - ' + error);
            });
    }


    let dataObj = {
        peakViewers: 0,
        totalMessages: 0,
        totalInteractions: 0,
        uniqueInteractionUsers: 0,
        uniqueUsers: 0,
        engagementTime: 0,
        totalRunTime: 0,
        featuresRun: 0,
        botsPosted: 0,
        disruptorsPlayed: 0,
        totalTargetTime: 0,
        streamData: []
    }
    props._campaignAnalyticsSummary.forEach((campaignSummary) => {

        if (campaignSummary.streamID == props._streamID) {
            campaignSummary.recordedAnalytics.forEach((individualStreamData) => {
                if (!individualStreamData.disabled) {
                    if (individualStreamData.peakViewers > dataObj.peakViewers) dataObj.peakViewers = individualStreamData.peakViewers;
                    dataObj.totalMessages += individualStreamData.messages;
                    dataObj.totalRunTime += individualStreamData.featureRunTime;
                    dataObj.totalTargetTime += props._endTime - props._startTime;
                    dataObj.uniqueUsers += individualStreamData.uniqueUsers;
                    dataObj.totalInteractions += individualStreamData.interactions;
                    dataObj.uniqueInteractionUsers += individualStreamData.uniqueInteractionUsers;
                    dataObj.engagementTime += individualStreamData.engagementTime;
                    dataObj.featuresRun += individualStreamData.featuresRun;
                    dataObj.botsPosted += individualStreamData.botsPosted;
                    dataObj.disruptorsPlayed += individualStreamData.disruptorsFired;
                    //datatObj.totalRunTime += 
                }
                dataObj.streamData.push(individualStreamData);
            })
        }
    })
    const engagementTimeStr = convertMSToHMS(dataObj.engagementTime);
    const timezoneData = parseTimezone(props._timeZone);
    const startTimeStr = convertMSToHMS(props._startTime, false, timezoneData.offset);
    const endTimeStr = convertMSToHMS(props._endTime, false, timezoneData.offset);
    const totalRunTimeStr = convertMSToHMS(dataObj.totalRunTime);

    const targetTimePerc = dataObj.totalRunTime / dataObj.totalTargetTime * 100;

    const calculateEngagementTime = (analyticsObj) =>{
        

        console.log('AOBJ', analyticsObj)



    }


    return (
        <>
            {dataObj.streamData.length > 0 &&
                <div className='no-panel-shadow'>

                    <HeadedPanel
                        color="an-stream-summary"
                        className="tight"
                        headingContent={
                            <div className="fl-row grow">
                                <div className="stream-twitch-logo">
                                    {TwitchIconSvg}
                                </div>
                                <div className="grow">
                                    {props._twitchHandle}
                                </div>
                                <div>
                                    <div className='fl-row sa-time compact'>
                                        <div>{IconJsxer.GetIcon(IconJsxer.ICONS.scheduleNav, IconJsxer.ICON_STYLES.standardW)}</div>
                                        <div className="time">{getLongDateString(props._startTime)}</div>
                                    </div>
                                </div>
                                <div>
                                    <div className='fl-row sa-time compact'>
                                        <div>{IconJsxer.GetIcon(IconJsxer.ICONS.clock, IconJsxer.ICON_STYLES.standardW)}</div>
                                        <div className="time">
                                            {`${startTimeStr.h_str}:${startTimeStr.m_str} - ${startTimeStr.h_str}:${startTimeStr.m_str} (${timezoneData.label.replace('(', '').replace(')', '')})`}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    >
                    <div className='fl-column an-stream-summary-holder'>
                        <div className='fl-row'>
                            <div className='fl-column fl-start'>

                                <div className='fl-row'>
                                    <CirclePerc perc={targetTimePerc < 100 ? targetTimePerc : 100}>
                                        {IconJsxer.GetIcon(IconJsxer.ICONS.clock, IconJsxer.ICON_STYLES.streamControlOptions)}
                                    </CirclePerc>
                                    <div className="fl-column compact">
                                            <h3>{`${totalRunTimeStr.h}h ${totalRunTimeStr.m_str}m ${totalRunTimeStr.s_str}s`}</h3>
                                            <h4 className='light-text'>Total Run Time</h4>
                                    </div>
                                </div>

                                <div className='fl-row compact'>
                                    <div>{IconJsxer.GetIcon(IconJsxer.ICONS.eye, IconJsxer.ICON_STYLES.standardW)}</div>
                                    <div>Peak Viewers: {dataObj.peakViewers.toLocaleString()}</div>
                                </div>

                            </div>
                            <div className='grow'></div>
                            <div className='fl-row'>

                                <div className='fl-column'>
                                    <HeadedPanel
                                        color="an-brand-engagement"
                                        headingContent={
                                            <>
                                                <div className="grow"></div>
                                                {IconJsxer.GetIcon(IconJsxer.ICONS.bullhorn, IconJsxer.ICON_STYLES.streamStatHeader)}
                                                <div className="grow"></div>
                                            </>
                                        }
                                        className="small-analytics-stat-panel tight"
                                    >
                                        <div className="j-center analytics-summary-content">
                                            <h3>{engagementTimeStr.h+':'+engagementTimeStr.m_str+':'+engagementTimeStr.s_str}</h3>
                                            <p>Feature Engagement</p>
                                        </div>
                                    </HeadedPanel>

                                    <LeftColorBlockBox style="an_messages">
                                            <div className='analytics-summary-content'>
                                                <h4>{dataObj.totalMessages.toLocaleString()}</h4>
                                                <div>Messages</div>
                                            </div>
                                    </LeftColorBlockBox>
                                </div>

                                <div className='fl-column'>
                                    <HeadedPanel
                                        color="an-interactions"
                                        headingContent={
                                            <>
                                                <div className="grow"></div>
                                                {IconJsxer.GetIcon(IconJsxer.ICONS.hashtag, IconJsxer.ICON_STYLES.streamStatHeader)}
                                                <div className="grow"></div>
                                            </>
                                        }
                                        className="small-analytics-stat-panel tight"
                                    >
                                        <div className="j-center analytics-summary-content">
                                            <h3>{dataObj.totalInteractions.toLocaleString()}</h3>
                                            <p>Total Interactions</p>
                                        </div>
                                    </HeadedPanel>

                                    <LeftColorBlockBox style="feature_default">
                                            <div className='analytics-summary-content'>
                                                <h4>{dataObj.featuresRun}</h4>
                                                <div>Features Ran</div>
                                            </div>
                                    </LeftColorBlockBox>
                                </div>


                                <div className='fl-column'>
                                    <HeadedPanel
                                        color="an-u-users"
                                        headingContent={
                                            <>
                                                <div className="grow"></div>
                                                {IconJsxer.GetIcon(IconJsxer.ICONS.usersNav, IconJsxer.ICON_STYLES.streamStatHeader)}
                                                <div className="grow"></div>
                                            </>
                                        }
                                        className="small-analytics-stat-panel tight"
                                    >
                                        <div className="j-center analytics-summary-content">
                                            <h3>{dataObj.uniqueUsers.toLocaleString()}</h3>
                                            <p>Unique Users</p>
                                        </div>
                                    </HeadedPanel>

                                    <LeftColorBlockBox style="disruptor_default">
                                            <div className='analytics-summary-content'>
                                                <h4>{dataObj.disruptorsPlayed}</h4>
                                                <div>Disruptors Started</div>
                                            </div>
                                        </LeftColorBlockBox>
                                </div>

                                <div className='fl-column'>
                                    <HeadedPanel
                                        color="an-u-i-users"
                                        headingContent={
                                            <>
                                                <div className="grow"></div>
                                                {IconJsxer.GetIcon(IconJsxer.ICONS.comments, IconJsxer.ICON_STYLES.streamStatHeader)}
                                                <div className="grow"></div>
                                            </>
                                        }
                                        className="small-analytics-stat-panel tight"
                                    >
                                        <div className="j-center analytics-summary-content">
                                            <h3>{dataObj.uniqueInteractionUsers.toLocaleString()}</h3>
                                            <p>Unique Interacting Users</p>
                                        </div>
                                    </HeadedPanel>

                                    <LeftColorBlockBox style="bot_default">
                                            <div className='analytics-summary-content'>
                                                <h4>{dataObj.botsPosted}</h4>
                                                <div>Bot Posts</div>
                                            </div>
                                    </LeftColorBlockBox>
                                </div>


                            </div>
                        </div>

                        <hr></hr>

                        

                        <div className='fl-row grow'>
                            <div className='fl-column grow'>
                                <h4>Recorded Sessions</h4>
                                {dataObj.streamData.map((val, index) => {
                                    return (
                                        <div className={`an-session-list-item ${index % 2 === 1 ? 'an-session-dark' : 'an-session-light'}`} key={'sess_' + index}>
                                            <div className='fl-column compact grow'>
                                                <div className='fl-row'>
                                                    <div>
                                                        <h5> {index + 1}. {getLongDateString(val.featureStartTime)} {'(' + convertMSToHMS(val.featureRunTime).h + ':' + convertMSToHMS(val.featureRunTime).m_str + ':' + convertMSToHMS(val.featureRunTime).s_str + ')'} </h5>
                                                    </div>

                                                </div>

                                                <div className='fl-column grow'>
                                                    <div className='fl-row grow'>
                                                        <div className='fl-column compact fl-equal'>
                                                            <div className='fl-row compact'>
                                                                {IconJsxer.GetIcon(IconJsxer.ICONS.bullhorn, IconJsxer.ICON_STYLES.standardW)}
                                                                {console.log(calculateEngagementTime(val))}
                                                                <div className='stat-text'>
                                                                    Feature Engagement: {convertMSToHMS(val.engagementTime*1000).h + ':' + convertMSToHMS(val.engagementTime*1000).m_str + ':' + convertMSToHMS(val.engagementTime*1000).s_str}
                                                                </div>
                                                            </div>
                                                            <div className='fl-row compact'>
                                                                {IconJsxer.GetIcon(IconJsxer.ICONS.star, IconJsxer.ICON_STYLES.standardW)}
                                                                <div className='stat-text'>
                                                                    Features Ran: {val.featuresRun}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='fl-column compact fl-equal'>
                                                            <div className='fl-row compact'>
                                                                {IconJsxer.GetIcon(IconJsxer.ICONS.eye, IconJsxer.ICON_STYLES.standardW)}
                                                                <div className='stat-text'>
                                                                    Peak Viewers: {val.peakViewers.toLocaleString()}
                                                                </div>
                                                            </div>
                                                            <div className='fl-row compact'>
                                                                {IconJsxer.GetIcon(IconJsxer.ICONS.disruptorDefault, IconJsxer.ICON_STYLES.standardW)}
                                                                <div className='stat-text'>
                                                                    Disruptors Started: {val.disruptorsFired}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='fl-column compact fl-equal'>
                                                            <div className='fl-row compact'>
                                                                {IconJsxer.GetIcon(IconJsxer.ICONS.hashtag, IconJsxer.ICON_STYLES.standardW)}
                                                                <div className='stat-text'>
                                                                    Interactions: {val.interactions.toLocaleString()}
                                                                </div>
                                                            </div>
                                                            <div className='fl-row compact'>
                                                                {IconJsxer.GetIcon(IconJsxer.ICONS.bot, IconJsxer.ICON_STYLES.standardW)}
                                                                <div className='stat-text'>
                                                                    Bot Posts: {val.botsPosted}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='fl-column compact fl-equal'>
                                                            <div className='fl-row compact'>
                                                                {IconJsxer.GetIcon(IconJsxer.ICONS.commentDots, IconJsxer.ICON_STYLES.standard)}
                                                                <div className='stat-text'>
                                                                    Messages: {val.messages.toLocaleString()}
                                                                </div>
                                                            </div>
                                                            <div className='fl-row compact'>
                                                                <div>&nbsp;</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='grow'></div>
                                            <div className='fl-column shrink'>
                                                <div className='fl-row grow'>
                                                    <div className='grow'></div>
                                                    <div className='fl-row shrink'>
                                                        <Toggle
                                                            id={'sess_togg_' + index}
                                                            currentState={!val.disabled}
                                                            toggleFunc={(e) => { disableSessionAnalytics(e, val.featureStartTime) }}
                                                        />
                                                    </div>
                                                </div>
                                                <button className='standard-button' onClick={(e) => { e.preventDefault(); props._openSessionAnalytics(val, props._twitchHandle, props._streamID) }}>View Details</button>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    </HeadedPanel>

                </div>
            }
        </>
    )
}

export default StreamAnalyticsSummary;