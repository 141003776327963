import React, { useState, useEffect } from "react";

import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import FormDropDown from "../../components/forms/DropDownList/FormDropDown";
import FontPicker from "font-picker-react";

const CommonFontPopup = (props) => {
    const [selectedFont, setSelectedFont] = useState('Roboto');

    const selectFontHandler = (font) => {
        setSelectedFont(font);
    }

    const setToFontNow = (e) => {
        e.preventDefault();
        props.setCommonFontFunc(selectedFont);
        props.closePanelFunc();
    }

    return (
        <PopUpPanel closePanelFunc={props.closePanelFunc} className="font-popup">
            <div className="form-holder co-popup-content">
                <form id="setCommonFont" className="form">
                    <h2>Select {props.editingTheme ? 'Theme' : 'Common'} Font</h2>

                    <p className="light-text">This will set the font for all customisable elements to the selected font face.
                    You will still be able to set individual elements to different fonts afterwards.</p>
                       
                    <div className="fl-column font-selector-content">
                        <FontPicker
                            apiKey="AIzaSyCK-FT-TdFUvWTWBQ_hxsoU_HS6GHeytWg"
                            activeFontFamily={selectedFont}
                            limit={250}
                            onChange={(nextFont) =>
                                selectFontHandler(nextFont.family)
                            }
                        />
                        <div className="fl-column apply-font">
                            <h1>The quick brown fox jumps over the lazy dog.</h1>
                            <h2>The quick brown fox jumps over the lazy dog.</h2>
                            <h3>The quick brown fox jumps over the lazy dog.</h3>
                            <h4>The quick brown fox jumps over the lazy dog.</h4>
                            <h5>The quick brown fox jumps over the lazy dog.</h5>
                            <p>The quick brown fox jumps over the lazy dog.</p>
                        </div>
                    </div>

                    <button className={'submit-btn button-active'} onClick={setToFontNow}>
                        Set All Element Fonts
                    </button>
                </form>
            </div>
        </PopUpPanel>
    )
}

export default CommonFontPopup;
