import React, { useState } from "react";

import PopUpPanel from "../../PopUpPanel/PopUpPanel";
import FontPicker from "font-picker-react";

import "./FontSelector.scss";

const FontSelectorPopup = (props) => {
    const [activeFontFamily, setActiveFontFamily] = useState(props.font || 'Open Sans');

    return (
        <PopUpPanel closePanelFunc={props.closePanelFunc} className="font-popup">
            <div className="form-holder co-popup-content">

                <h2>Select Font</h2>

                <div className="fl-column font-selector-content">
                    <FontPicker
                        apiKey="AIzaSyCK-FT-TdFUvWTWBQ_hxsoU_HS6GHeytWg"
                        activeFontFamily={activeFontFamily}
                        limit={250}
                        onChange={(nextFont) =>
                            setActiveFontFamily(nextFont.family)
                        }
                    />
                    <div className="fl-column apply-font">
                        <h1>The quick brown fox jumps over the lazy dog.</h1>
                        <h2>The quick brown fox jumps over the lazy dog.</h2>
                        <h3>The quick brown fox jumps over the lazy dog.</h3>
                        <h4>The quick brown fox jumps over the lazy dog.</h4>
                        <h5>The quick brown fox jumps over the lazy dog.</h5>
                        <p>The quick brown fox jumps over the lazy dog.</p>
                    </div>
                </div>

                <button className="standard-button" onClick={props.selectFunc ? () => {props.selectFunc(activeFontFamily); props.closePanelFunc()} : null}>Confirm Selection</button>
            </div>
        </PopUpPanel>
    )
}

export default FontSelectorPopup;
