import { useState, useRef, useEffect } from "react";
import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import "./CustomisationPreview.scss";
import Toggle from "../../components/forms/Toggle/Toggle";
import TabbedPanel from "../../components/TabbedPanel/TabbedPanel";
import CustomisationControls from "../../components/CustomisationControls/CustomisationControls";

const CustomisationPreviewPopup = (props) => {
    const [advancedMode, setAdvancedMode] = useState(false);
    const [currentTab, setCurrentTab] = useState();

    const tabData = useRef(null);
    const buildTabs = () => {
            const newTabData = [];
            for (let i = 0; i < props.featureData.customisation.length; i++) {
                newTabData.push(
                    {
                        label: props.featureData.customisation[i].tabTitle,
                        id: props.featureData.customisation[i].tabKey,
                    }
                )
            }
            tabData.current = newTabData;
            setCurrentTab(newTabData[0].id);

            console.log('Tab data built: ', props, tabData.current);
            console.log('Props: ', props);
    }
    useEffect(() => {
        buildTabs();
    }, []);

    return (
        <PopUpPanel closePanelFunc={props.closePanelFunc} className="customisation-preview-holder">
            <div className="fl-column customisation-preview form-holder">
                <div className="fl-row preview-top">
                    <div className="grow">
                        <h2>Customisation Preview</h2>
                    </div>
                    <div className="fl-row shrink">
                        <div className="label">Advanced</div>
                        <div className="grow"></div>
                        <div className="shrink">
                            <Toggle id="advancedMode" currentState={advancedMode} toggleFunc={(e, id) => setAdvancedMode((oldVal) => !oldVal)} />
                        </div>
                    </div>
                </div>
                <div className="fl-row">
                    <div className="content-page-content-container">
                        <div className="content-page-main-content">
                            {tabData.current && tabData.current.length >= 1 &&
                                <TabbedPanel
                                    tabs={
                                        /* POTENTIAL_TABS */
                                        tabData.current
                                    }
                                    switchTabCallback={(tabId, tabData) => setCurrentTab(tabData.id)}
                                    initialTab={0}
                                    tabWidth={tabData.current.length < 5 ? '20%' : 100}
                                >

                                    <div className="tab-content">
                                        <div className="co-tab-content no-panel-shadow">

                                            {props.customisationVars.current && props.customisationVars.current['previewData'] &&
                                                <CustomisationControls
                                                    tabId={currentTab}
                                                    controlsData={props.featureData.customisation}
                                                    customisationVars={props.customisationVars}
                                                    featureId={"previewData"}
                                                    featureData={{feature: props.featureData}}
                                                    campaignData={null}
                                                    handleRemovedFile={() => {}}
                                                    zoomFunction={() => {}}
                                                    showTooltipFunc={() => {}}
                                                    hideTooltipFunc={() => {}}
                                                    openResetPresetsPanel={() => {}}
                                                    addDisruptor={() => {}}
                                                    addQuiz={() => {}}
                                                    customiseQuiz={() => {}}
                                                    logAddedDisruptor={() => {}}
                                                    logRemovedDisruptor={() => {}}
                                                    showFeaturePreview={() => {}}
                                                    customiseFeature={() => {}}
                                                    refreshPreview={() => {}}
                                                    openFontSelectorFunc={() => {}}
                                                    advancedMode={advancedMode}
                                                    isFullPreview={true}
                                                    getGroupLayoutSettings={props.getGroupLayoutSettings}
                                                    getVarFamilyLayoutSettings={props.getVarFamilyLayoutSettings}
                                                    addVarGroup={props.addVarGroup}
                                                    predefinedGroupDropDownOptions={props.predefinedGroupDropDownOptions}
                                                    selectPredefinedGroup={props.selectPredefinedGroup}
                                                    selectedPredefinedGroupIndex={props.selectedPredefinedGroupIndex}
                                                    addPredefinedCustomisationGroup={props.addPredefinedCustomisationGroup}
                                                    addVarFamily={props.addVarFamily}
                                                    pasteVarFamily={props.pasteVarFamily}
                                                />
                                            }

                                        </div>
                                    </div>

                                </TabbedPanel>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </PopUpPanel>
    );
}

export default CustomisationPreviewPopup;
