import "./ConnectingPopup.scss";
import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import { IconJsxer } from "../../helpers/IconHelper";

const ConnectingPopup = (props) => {
    // console.log(props.activationObj);
    return (
        <PopUpPanel showCloseBtn={false}>
            <div className="connecting-popup-content">
                <h2>Activating Stream</h2>

                <h4>Connecting to Twitch Channel</h4>
                {(!props.activationObj.stage || (props.activationObj.stage === 1 && !props.activationObj.twitchConnected)) &&
                    <div className="icon busy busy-anim">{IconJsxer.GetIcon(IconJsxer.ICONS.busy, IconJsxer.ICON_STYLES.streamControlConnecting)}</div>
                }
                {props.activationObj.stage && props.activationObj.twitchConnected &&
                    <div className="icon green">{IconJsxer.GetIcon(IconJsxer.ICONS.check, IconJsxer.ICON_STYLES.streamControlOptions)}</div>
                }
                {props.activationObj.stage && props.activationObj.stage !== 1 && !props.activationObj.twitchConnected &&
                    <div className="icon red">{IconJsxer.GetIcon(IconJsxer.ICONS.checkX, IconJsxer.ICON_STYLES.streamControlOptions)}</div>
                }
                

                <h4 className={(!props.activationObj.stage || props.activationObj.stage < 2) ? 'light-text' : ''}>Bot Authorised</h4>
                {(!props.activationObj.stage || props.activationObj.stage === 1) &&
                    <div className="icon inactive">{IconJsxer.GetIcon(IconJsxer.ICONS.busy, IconJsxer.ICON_STYLES.streamControlConnecting)}</div>
                }
                {props.activationObj.stage && props.activationObj.stage !== 1 && props.activationObj.botAuthorised &&
                    <div className="icon green">{IconJsxer.GetIcon(IconJsxer.ICONS.check, IconJsxer.ICON_STYLES.streamControlOptions)}</div>
                }
                {props.activationObj.stage && props.activationObj.stage !== 1 && !props.activationObj.botAuthorised &&
                    <div className="icon red">{IconJsxer.GetIcon(IconJsxer.ICONS.checkX, IconJsxer.ICON_STYLES.streamControlOptions)}</div>
                }

                <h4 className={(!props.activationObj.stage || props.activationObj.stage < 2) ? 'light-text' : ''}>Activating Stream Server</h4>
                {(!props.activationObj.stage || props.activationObj.stage < 2) &&
                    <div className="icon inactive">{IconJsxer.GetIcon(IconJsxer.ICONS.busy, IconJsxer.ICON_STYLES.streamControlConnecting)}</div>
                }
                {props.activationObj.stage && props.activationObj.stage === 2 &&
                    <div className="icon busy busy-anim">{IconJsxer.GetIcon(IconJsxer.ICONS.busy, IconJsxer.ICON_STYLES.streamControlConnecting)}</div>
                }
                {props.activationObj.stage && props.activationObj.stage >= 3 && props.activationObj.socketCreated && 
                    <div className="icon green">{IconJsxer.GetIcon(IconJsxer.ICONS.check, IconJsxer.ICON_STYLES.streamControlOptions)}</div>
                }
                {props.activationObj.stage && props.activationObj.stage >= 3 && !props.activationObj.socketCreated && 
                    <div className="icon red">{IconJsxer.GetIcon(IconJsxer.ICONS.checkX, IconJsxer.ICON_STYLES.streamControlOptions)}</div>
                }
            </div>
        </PopUpPanel>
    )
}

export default ConnectingPopup;
