import React, { useState, useEffect, useContext } from "react";
import PropTypes from 'prop-types';
import validator from "validator";

import axios from "../../api/axios";

import DebugLogger from "../../helpers/DebugLogger";
import { toast } from "react-toastify";
import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import FormField from "../../components/forms/FormField/FormField";
import RadioButtonGroup from "../../components/forms/RadioButtonGroup/RadioButtonGroup";
import TextArea from "../../components/forms/TextArea/TextArea";
import { CampaignContext } from "../../contexts/CampaignContext";
import { archiveCampaignBotRoute, updateCampaignBotRoute } from "../../api/routes";
import HMSTimeField from "../../components/forms/HMSTimeField/HMSTimeField";
import { BOT_TYPES, getBotTypesListData } from "../../helpers/Bots";
import OneBotOverview from "../../components/Bots/OneBotOverview";

const ConfirmRemoveBot = (props) => {
    const [campaignContext, setCampaignContext] = useContext(CampaignContext);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const archiveBot = (e) => {
        e.preventDefault();
        const wasArchived = props.bot.archived;
        axios
            .post(archiveCampaignBotRoute, {
                botID: props.bot.id,
                campaignID: props.campaignId,
                archived: props.bot.archived,
                withCredentials: true
            })
            .then((response) => {
                let oldCampaignData = campaignContext.campaignData
                oldCampaignData.chatbots = response.data.campaignData.chatbots;
                setCampaignContext((oldValues) => {
                    return { ...oldValues, initialising: false, campaignData: oldCampaignData }
                })
                console.log(props.bot.id)
                toast.success("Bot (" + props.bot.name + ") successfully " + (wasArchived ? 'restored' : 'archived'));
                props.closePanelFunc();
            })

    }

    return (
        <PopUpPanel closePanelFunc={props.closePanelFunc}>
            <div className="form-holder co-popup-content">
                <form id="addstreambot" className="form">
                    <h2>{props.bot.archived ? 'Restore' : 'Archive'} This Stream Bot?</h2>

                    <div className="drop-gap">
                        <OneBotOverview bot={props.bot} showControls={false} editMode={true} />
                    </div>

                    <button className={'submit-btn' + (isSubmitting ? ' button-inactive' : ' button-active')} onClick={isSubmitting ? null : archiveBot}>
                        {isSubmitting ? (props.bot.archived ? "Restoring Bot" : "Archiving Bot") : (props.bot.archived ? "Restore Bot" : "Archive Bot")}
                    </button>
                </form>
            </div>
        </PopUpPanel>
    )
}

export default ConfirmRemoveBot;
