import "./StartupWizard.scss";

import React, { useState, useEffect, useContext } from "react";

import PopUpPanel from "../../../components/PopUpPanel/PopUpPanel";
import { IconJsxer } from "../../../helpers/IconHelper";
import { toast } from "react-toastify";
import { assets } from "../../../assets/assets";

const WIZARD_STEPS = {
    USE_WIZARD_PROMPT: 0,
    EXTENSION_LINK: 1,
    ENABLE_AUDIO_VIDEO: 2,
    CONNECT_TO_TWITCH: 3,
    AUTHORISE_BOTS: 4,
    START_CAMPAIGN: 5,
}

const StartupWizard = (props) => {
    const [wizardStep, setWizardStep] = useState(WIZARD_STEPS.USE_WIZARD_PROMPT);
    const nextStep = () => {
        setWizardStep(wizardStep + 1);
    }
    const gotoStep = (step) => {
        setWizardStep(step);
    }

    const copyStreamLinkToClipboard = (e) =>{
        navigator.clipboard.writeText(props.streamLink);
        toast.success("Feature URL copied to clipboard!");
    }

 

    return (
        <>
        {!props.invitePanelOpen && !props.connectingPanelOpen &&
            <PopUpPanel showCloseBtn={false} closePanelFunc={props.closePanelFunc} className="wiz-panel">
                <div className="wiz">
                    {wizardStep === WIZARD_STEPS.USE_WIZARD_PROMPT &&
                        <div className="fl-column">
                            <h1>Startup Wizard</h1>

                            <div className="grow"></div>

                            <div>Would you like us to guide you through setting up and<br />starting your campaign?</div>

                            <div className="grow"></div>

                            <button className="standard-button" onClick={nextStep}>Let's go!</button>
                            <div className="fl-row">
                                <div className="grow"></div>
                                <div className="cancel-button" onClick={props.closePanelFunc}>No thanks, I'll wing it :)</div>
                                <div className="grow"></div>
                            </div>
                        </div>
                    }
                    {wizardStep === WIZARD_STEPS.EXTENSION_LINK &&
                        <div className="fl-column wiz-step">
                            <div className="steps">
                                <div className="grow"></div>
                                <div className="step current">1</div>
                                <div className="divider"></div>
                                <div className="step">2</div>
                                <div className="divider"></div>
                                <div className="step">3</div>
                                <div className="divider"></div>
                                <div className="step">4</div>
                                <div className="grow"></div>
                            </div>

                            <h1>OBS Extension Link</h1>
                            <div>The first thing you will need is your OBS Extension Link<br />(this is available from the Stream Manager tab at any time)</div>
                            <div className="fl-row">
                                <div className="grow"></div>
                                <div className="primary-color bold-text space big">
                                    <a href={props.streamLink} target='_blank'>Campaign Studio Extension Link</a>
                                </div>
                                <div className="button-active primary-color" onClick={copyStreamLinkToClipboard}>
                                    {IconJsxer.GetIcon(IconJsxer.ICONS.copy, IconJsxer.ICON_STYLES.standard)}
                                </div>
                                <div className="grow"></div>
                            </div>
                            <div>Pass this url to your Twitch Streamer. They will need to<br />set this up as a Browser Source Layer in OBS.</div>
                            <div className="light-text em">Alternatively, to run in 'Test Mode' to try out your Features<br />and Disruptors (without streaming through Twitch),<br />simply open the link in your browser.</div>
                            
                            <div className="fl-row space big">
                                <div className="grow"></div>
                                <div className={`status-lozenge ${props.featureReady ? 'on' : 'off'}`}>Feature {props.featureReady ? '' : 'Not '}Ready</div>
                                <div className="grow"></div>
                            </div>

                            <div>When the feature is loaded and ready, the status will<br />turn green at the top of the Stream Manager.</div>

                            <div className="grow"></div>

                            <button className="standard-button" onClick={nextStep}>Continue</button>
                            <div className="fl-row">
                                <div className="grow"></div>
                                <div className="cancel-button" onClick={props.closePanelFunc}>Exit Startup Wizard</div>
                                <div className="grow"></div>
                            </div>
                        </div>
                    }
                    {wizardStep === WIZARD_STEPS.ENABLE_AUDIO_VIDEO &&
                        <div className="fl-column wiz-step">
                            <div className="steps">
                                <div className="grow"></div>
                                <div className="step completed" onClick={() => gotoStep(1)}>{IconJsxer.GetIcon(IconJsxer.ICONS.check, IconJsxer.ICON_STYLES.wizardProgressIcon)}</div>
                                <div className="divider"></div>
                                <div className="step current">2</div>
                                <div className="divider"></div>
                                <div className="step">3</div>
                                <div className="divider"></div>
                                <div className="step">4</div>
                                <div className="grow"></div>
                            </div>

                            <h1>Enable Audio and Video</h1>

                            <div>Most browsers block audio and video until you interact<br />with them. To avoid this happening we have provided<br />a handy <span className="bold-text">Configuration Overlay</span> to make this part easy.</div>

                            <div className="light-text em">Once you start your campaign or activate Test Mode,<br />you can show this overlay at any time.</div>

                            <button className="standard-button wh-btn space" onClick={(e) => props.setOverlayState('configoverlay')}>Show Configuration Overlay</button>

                            <div>The Streamer will need to switch on <span className="bold-text">Interactive Mode in<br />OBS</span> and <span className="bold-text">click on the browser source</span>.</div>
                            <div className="light-text em">If you are running the extension in a normal browser<br />window, simply click on the Configuration Overlay to<br />enable Audio and Video.</div>

                            <div className="fl-row space big">
                                <div className="grow"></div>
                                <div className={`status-lozenge ${props.audioEnabled ? 'on' : 'off'}`}>Audio & Video {props.audioEnabled ? '' : 'Not '}Enabled</div>
                                <div className="grow"></div>
                            </div>

                            <div className="grow"></div>

                            <button className="standard-button" onClick={(e) => {props.setOverlayState('idle'); nextStep()}}>Continue</button>
                            <div className="fl-row">
                                <div className="grow"></div>
                                <div className="cancel-button" onClick={props.closePanelFunc}>Exit Startup Wizard</div>
                                <div className="grow"></div>
                            </div>
                        </div>
                    }
                    {wizardStep === WIZARD_STEPS.CONNECT_TO_TWITCH &&
                        <div className="fl-column wiz-step">

                            <div className="steps">
                                <div className="grow"></div>
                                <div className="step completed" onClick={() => gotoStep(1)}>{IconJsxer.GetIcon(IconJsxer.ICONS.check, IconJsxer.ICON_STYLES.wizardProgressIcon)}</div>
                                <div className="divider"></div>
                                <div className="step completed" onClick={() => gotoStep(2)}>{IconJsxer.GetIcon(IconJsxer.ICONS.check, IconJsxer.ICON_STYLES.wizardProgressIcon)}</div>
                                <div className="divider"></div>
                                <div className="step current">3</div>
                                <div className="divider"></div>
                                <div className="step">4</div>
                                <div className="grow"></div>
                            </div>

                            <h1>Connect to Channel</h1>

                            <div>Connecting to your Streamer's channel chat is<br />essential to allow their viewers to interact with your<br />campaign features.</div>

                            <div className="fl-row space big">
                                <div className="grow"></div>
                                <div className={`status-lozenge ${props.chatConnected ? 'on' : 'off'}`}>Channel {props.chatConnected ? '' : 'Not '}Connected</div>
                                <div className="grow"></div>
                            </div>

                            <button className="standard-button wh-btn space" onClick={props.activateChat}>Connect to Twitch Channel Chat</button>

                            <div className="light-text em">Some features will only activate once connected<br />eg. Timelines that trigger count ins, intros and outros.</div>

                            <div className="grow"></div>

                            <div className="light-text em">Connecting isn't essential if you are just running some<br />tests. Most features and disruptors can be triggered<br />manually from the dashboard.</div>

                            <div className="grow"></div>

                            <button className="standard-button" onClick={nextStep}>Continue</button>
                            <div className="fl-row">
                                <div className="grow"></div>
                                <div className="cancel-button" onClick={props.closePanelFunc}>Exit Startup Wizard</div>
                                <div className="grow"></div>
                            </div>

                        </div>
                    }
                    {wizardStep === WIZARD_STEPS.AUTHORISE_BOTS &&
                        <div className="fl-column wiz-step">

                            <div className="steps">
                                <div className="grow"></div>
                                <div className="step completed" onClick={() => gotoStep(1)}>{IconJsxer.GetIcon(IconJsxer.ICONS.check, IconJsxer.ICON_STYLES.wizardProgressIcon)}</div>
                                <div className="divider"></div>
                                <div className="step completed" onClick={() => gotoStep(2)}>{IconJsxer.GetIcon(IconJsxer.ICONS.check, IconJsxer.ICON_STYLES.wizardProgressIcon)}</div>
                                <div className="divider"></div>
                                <div className="step completed" onClick={() => gotoStep(3)}>{IconJsxer.GetIcon(IconJsxer.ICONS.check, IconJsxer.ICON_STYLES.wizardProgressIcon)}</div>
                                <div className="divider"></div>
                                <div className="step current">4</div>
                                <div className="grow"></div>
                            </div>

                            <h1>Channel Bots</h1>

                            <div>If you want to post to your Streamer's channel chat<br />using bots, they will need to authorise this.</div>

                            <div className="fl-row space big">
                                <div className="grow"></div>
                                <div className={`status-lozenge ${props.botAuthorised ? 'on' : 'off'}`}>Bot {props.botAuthorised ? '' : 'Not '}Authorised</div>
                                <div className="grow"></div>
                            </div>

                            {!props.botAuthorised ?
                            <>
                            {props.streamerExists ? 
                                <>
                                    <h3>** STREAMER ACCOUNT EXISTS **</h3>
                                    <div>The streamer has disabled bot permissions in their account.<br />Please request them to log in to Campaign Studio <br />and enable bots from the dashboard homepage.</div>
                                </>
                                : 
                                <>
                                    <div>You will need to invite them to Campaign Studio so they can<br />log in using their Twitch login and authorise bots.</div>

                                    <button className="standard-button wh-btn space" onClick={props.showInvitePanel}>Send Invite Now</button>

                                    <div className="light-text em">You can manage campaign users and invites<br />at any time from the Campaign Overview.</div>

                                    <div className="grow"></div>

                                    <div className="light-text em">This step is optional and only needed if you want<br />to make use of bots and only when you are getting ready to<br />run your campaign for real.</div>
                                </>
                            }
                                </>

                            : <div>The streamer has successfully authorised the bots. <br />You do not need to do anything!</div>
                             }
                            <div className="grow"></div>

                            <button className="standard-button" onClick={nextStep}>Continue</button>
                            <div className="fl-row">
                                <div className="grow"></div>
                                <div className="cancel-button" onClick={props.closePanelFunc}>Exit Startup Wizard</div>
                                <div className="grow"></div>
                            </div>

                        </div>
                    }
                    {wizardStep === WIZARD_STEPS.START_CAMPAIGN &&
                        <div className="fl-column wiz-step">

                            <div className="steps">
                                <div className="grow"></div>
                                <div className="step completed" onClick={() => gotoStep(1)}>{IconJsxer.GetIcon(IconJsxer.ICONS.check, IconJsxer.ICON_STYLES.wizardProgressIcon)}</div>
                                <div className="divider"></div>
                                <div className="step completed" onClick={() => gotoStep(2)}>{IconJsxer.GetIcon(IconJsxer.ICONS.check, IconJsxer.ICON_STYLES.wizardProgressIcon)}</div>
                                <div className="divider"></div>
                                <div className="step completed" onClick={() => gotoStep(3)}>{IconJsxer.GetIcon(IconJsxer.ICONS.check, IconJsxer.ICON_STYLES.wizardProgressIcon)}</div>
                                <div className="divider"></div>
                                <div className="step completed" onClick={() => gotoStep(4)}>{IconJsxer.GetIcon(IconJsxer.ICONS.check, IconJsxer.ICON_STYLES.wizardProgressIcon)}</div>
                                <div className="grow"></div>
                            </div>

                            <h1>Start Your Campaign</h1>

                            <div>Great, you're now all setup and ready to go.<br /><span className="bold-text">'Start Campaign'</span> button and get going!</div>

                            <div className="grow"></div>

                            <div className="bold-text">Starting your analytics will set your 'Automated Bots'<br />in motion and begin collecting 'Analytics' data. <br ></br>So, don't start your campaign until you are ready!</div>
                            <div className="light-text em">You can test your feature without collecting analytics.<br /> You'll also be able to trigger any bots manually but they <br />won't trigger automatically or respond to users in chat.</div>
                            <div className="grow"></div>

                            <div className="final-warning">
                                <img src={assets.WizAlert} alt="" /><br />
                                When you are done, please remember to hit<br /><span className="bold-text">Disconnect / End Campaign button</span> to close your<br />session and stop tracking analytics.
                            </div>

                            <div className="grow"></div>

                            <div className="fl-row">
                                <button className="standard-button" onClick={props.closePanelFunc}>Finish Startup Wizard</button>
                            </div>

                            <div className="grow"></div>
                        </div>
                    }
                </div>
            </PopUpPanel>
        }
        </>
    )
}

export default StartupWizard;
