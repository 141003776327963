import React, { useState, useEffect } from "react";

import PopUpPanel from "../../components/PopUpPanel/PopUpPanel";
import FormDropDown from "../../components/forms/DropDownList/FormDropDown";

const ResetPresets = (props) => {
    const [selectedPreset, setSelectedPreset] = useState(null);

    const selectPresetHandler = (e, id, item) => {
        setSelectedPreset(item);
    }

    const setToPresetNow = (e) => {
        e.preventDefault();
        props.resetPresetFunc(selectedPreset ? selectedPreset.value : -1);
        props.closePanelFunc();
    }

    const getPresetListDropDownData = () => {
        const dropDownData = [];
        dropDownData.push({id: '_default', label: 'Default / Reset', value: -1});
        if (props.presets) {
            for (let i = 0; i < props.presets.length; i++) {
                dropDownData.push({id: '_preset_' + (i + 1), label: props.presets[i].name, value: i});
            }
        }
        return dropDownData;
    }

    return (
        <PopUpPanel closePanelFunc={props.closePanelFunc}>
            <div className="form-holder co-popup-content">
                <form id="resetpresets" className="form">
                    <h2>Select Preset</h2>
                    <h4>{props.label}</h4>
                    <div className="fl-column">
                       
                        <FormDropDown
                            id="select_preset"
                            showLabel={false}
                            selectFunc={selectPresetHandler}
                            currentIndex={selectedPreset ? selectedPreset.index : 0}
                            items={
                                getPresetListDropDownData()
                            }
                            showErrors={false}
                        />
                    </div>

                    <button className={'submit-btn button-active'} onClick={setToPresetNow}>
                        Use Preset
                    </button>
                </form>
            </div>
        </PopUpPanel>
    )
}

export default ResetPresets;
