import './CollapsibleHeadedSection.scss';
import PropTypes from 'prop-types'; 

const { useState, useEffect } = require("react");
const { IconJsxer } = require("../../helpers/IconHelper");

export const COLLAPSIBLES_CONTROL = {
    activeCollapsibleResetData: [],
    addActiveCollapsible: (setExpandedFunction, defaultVal) => {
        COLLAPSIBLES_CONTROL.activeCollapsibleResetData.push({setter: setExpandedFunction, defaultVal: defaultVal});
    },
    removeActiveCollapsible: (setExpandedFunction) => {
        for (let i = 0; i < COLLAPSIBLES_CONTROL.activeCollapsibleResetData.length; i++) {
            if (COLLAPSIBLES_CONTROL.activeCollapsibleResetData[i].setter === setExpandedFunction) {
                COLLAPSIBLES_CONTROL.activeCollapsibleResetData.splice(i, 1);
                break;
            }
        }
    },
    resetToDefaults: () => {
        for (let i = 0; i < COLLAPSIBLES_CONTROL.activeCollapsibleResetData.length; i++) {
            COLLAPSIBLES_CONTROL.activeCollapsibleResetData[i].setter(COLLAPSIBLES_CONTROL.activeCollapsibleResetData[i].defaultVal);
        }
    }
}

const CollapsibleHeadedSection = ({ id, title, open, canCollapse, onCollapseToggle, children, extraHeaderContentLeft, extraHeaderContentRight, bgColour, preHeaderContent }) => {
    const [expanded, setExpanded] = useState(open);
    const [hovering, setHovering] = useState(false);

    useEffect(() => {
        if (id && onCollapseToggle) {
            onCollapseToggle(id, expanded);
        }
    }, [expanded]);
    
    useEffect(() => {
        COLLAPSIBLES_CONTROL.addActiveCollapsible(setExpanded, open);
        return () => {
            COLLAPSIBLES_CONTROL.removeActiveCollapsible(setExpanded, open);
        }
    }, []);

    const bgStyle = bgColour ? {backgroundColor: bgColour} : null;

    return (
        <>
            <div className={`underlined-panel-heading${canCollapse ? ' collapsible' : ''}`} onClick={canCollapse ? (e) => setExpanded((oldVal) => !oldVal) : null} onMouseEnter={canCollapse ? (e) => setHovering(true) : null} onMouseLeave={canCollapse ? (e) => setHovering(false) : null} style={bgStyle}>
                {preHeaderContent &&
                    <>{preHeaderContent}</>
                }
                <h4 className={!expanded && canCollapse && !hovering ? 'light-text' : ''}>{title}</h4>
                {extraHeaderContentLeft &&
                    <>{extraHeaderContentLeft}</>
                }
                <div className="grow"></div>
                {extraHeaderContentRight &&
                    <>{extraHeaderContentRight}</>
                }
                {!expanded && canCollapse && <div className="small-text light-text">(Click to expand)</div>}
                {canCollapse &&
                    <div>
                        {IconJsxer.GetIcon(!expanded ? IconJsxer.ICONS.chevronDown : IconJsxer.ICONS.chevronUp, IconJsxer.ICON_STYLES.settingsEdit, 'light-text')}
                    </div>
                }
            </div>
            {(expanded || !canCollapse) && children}
        </>
    );
}

CollapsibleHeadedSection.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string.isRequired,
    open: PropTypes.bool,
    onCollapseToggle: PropTypes.func,
    canCollapse: PropTypes.bool,
    children: PropTypes.element.isRequired,
    preHeaderContent: PropTypes.element,
    extraHeaderContentLeft: PropTypes.element,
    extraHeaderContentRight: PropTypes.element,
    bgColour: PropTypes.string,
};

CollapsibleHeadedSection.defaultProps = {
    id: null,
    title: 'Collapsible Section',
    open: false,
    canCollapse: true,
    onCollapseToggle: null,
    children: null,
    extraHeaderContentLeft: null,
    extraHeaderContentRight: null,
    bgColour: null,
}

export default CollapsibleHeadedSection;
