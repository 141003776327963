import PropTypes from 'prop-types';
import DebugLogger from "../../../helpers/DebugLogger";

import "./DropDownList.scss";
import { IconJsxer } from '../../../helpers/IconHelper';
import { useState } from 'react';

const DropDownList = (props) => {
    const { id, items, currentIndex, selectFunc, error, deselectedClass, selectedClass, listClass, deselectedLabel, icon, showErrors, enabled, className } = props;

    // console.log('Drop down: ', items, currentIndex)
    const selected = !items[currentIndex].deselect;

    const [open, setOpen] = useState(false);
    const toggleOpen = (e) => {
        if (enabled) {
            setOpen(!open);
        } else {
            closeList();
        }
    }
    const closeList = () => {
        setOpen(false);
    }
    const selectItem = (e, i) => {
        if (enabled && selectFunc) {
            items[i].index = i;
            // Just make these nice and robust!
            if (typeof items[i].value === 'undefined' && items[i].val !== undefined) {
                items[i].value = items[i].val;
            }
            selectFunc(e, id, items[i]);
        }
        if (selectFunc === null) {
            DebugLogger.warn('You should provide a prop called selectFunc(e, id, item) to DropDownList. The component should be controlled by passing in the currentIndex as a prop, the index can be found as part of the item data passed back to your selectFunc. DropDownList does NOT track it\'s own state!');
        }
    }

    return (
        <>
        {open && <div className="drop-down-canceller" onClick={closeList}></div>}
        <div className={`drop-list-holder${className ? ` ${className}` : ''}`} onClick={toggleOpen}>
            <div className={`drop-down-closed top-row ${selected ? selectedClass : deselectedClass} ${!enabled ? "drop-down-disabled" : ""}`} >
                {IconJsxer.GetIcon(typeof icon === 'string' ? IconJsxer.ICONS[icon] : icon, IconJsxer.ICON_STYLES.roundFormControl)}
                <div className='label-holder'>{selected ? items[currentIndex].label : (deselectedLabel || items[currentIndex].label)}</div>
                { enabled && IconJsxer.GetIcon(IconJsxer.ICONS.chevronDown, IconJsxer.ICON_STYLES.roundFormControl)}
            </div>
            {open &&

                <div className={`drop-down-list ${listClass}${(!enabled ? " drop-down-list-disabled" : "")}`} onClick={toggleOpen}>
                    <div className='top-row item'>
                        {IconJsxer.GetIcon(typeof icon === 'string' ? IconJsxer.ICONS[icon] : icon, IconJsxer.ICON_STYLES.roundFormControl)}
                        <div className='label-holder'>{selected ? items[currentIndex].label : (deselectedLabel || items[currentIndex].label)}</div>
                        {IconJsxer.GetIcon(IconJsxer.ICONS.chevronUp, IconJsxer.ICON_STYLES.roundFormControl)}
                    </div>

                    {items.map(
                        (val, i) => {
                            return (
                                <div className={`item${i !== currentIndex ? ' option' : ''}`} onClick={(e) => selectItem(e, i)} key={id + '_' + i}>
                                    {val.label}
                                </div>
                            )
                        }
                    )}
                </div>
            }
            {showErrors &&
            <div className="error">
                {error}&nbsp;
            </div>
            }
        </div>
        </>
    )
}

DropDownList.propTypes = {
    id: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentIndex: PropTypes.number.isRequired,
    selectFunc: PropTypes.func,
    error: PropTypes.string.isRequired,
    deselectedClass: PropTypes.string.isRequired,
    selectedClass: PropTypes.string.isRequired,
    deselectedLabel: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.any]),
    showErrors: PropTypes.bool.isRequired,
    enabled: PropTypes.bool,
    className: PropTypes.string,
};

DropDownList.defaultProps = {
    id: 'drop_list',
    items: [
        {
            id: 'none',
            label: 'None',
            deselect: true,
        },
        {
            id: 'item1',
            label: 'Item 1',
        },
        {
            id: 'item2',
            label: 'Item 2',
        },
        {
            id: 'item3',
            label: 'Item 4',
        },
    ],
    currentIndex: 0,
    selectFunc: null,
    error: 'Some error',
    deselectedClass: '',
    selectedClass: 'default-selected',
    listClass: 'default-selected',
    deselectedLabel: 'Choose Item',
    icon: 'cog',
    showErrors: false,
    enabled: true,
    className: '',
}

export default DropDownList;
