import React, { useRef, useState } from "react";
import "./ScreenRecorderPop.scss";
import { IconJsxer } from "../../helpers/IconHelper";

const REMIND_EVERY_MILLISECONDS = 900000; // 15 minutes

const ScreenRecorderPop = (props) => {
    const stream = useRef(null);
    const recorder = useRef(null);
    const data = useRef(null);
    const reminderTimeout = useRef(null);

    const previewVideoRef = useRef(null);
    const recordingVideoRef = useRef(null);

    const [recordingActive, setRecordingActive] = useState(false);
    const [gotRecording, setGotRecording] = useState(false);

    const startRecording = () => {
        navigator.mediaDevices.getDisplayMedia({
            audio: {noiseSuppression: false, echoCancellation: false,},
            video: true
          }).then(newStream => {
            // console.log('Stream started: ', newStream);
            previewVideoRef.current.srcObject = newStream;
            setGotRecording(false);
            previewVideoRef.current.captureStream = previewVideoRef.current.captureStream || previewVideoRef.current.mozCaptureStream;
            return new Promise(resolve => previewVideoRef.current.onplaying = resolve);
          }).then(() => recordStream(previewVideoRef.current.captureStream()))
          .then (recordedChunks => {
            // console.log('Recording complete: ', recordedChunks);
            let recordedBlob = new Blob(recordedChunks.current, { type: "video/mp4" });
            recordingVideoRef.current.src = URL.createObjectURL(recordedBlob);
            setRecordingActive(false);
            props.reportRecordingFunc(false);
            setGotRecording(true);
            clearTimeout(reminderTimeout.current);
          }).catch((err) => {
            console.error(err);
            setRecordingActive(false);
            props.reportRecordingFunc(false);
            clearTimeout(reminderTimeout.current);
        });
    }

    const stopRecording = () => {
        if (!recordingActive) {
            return;
        }
        if (stream.current) {
            stream.current.getTracks().forEach(track => track.stop());
            recorder.current.stop();
            clearTimeout(reminderTimeout.current);
        }
    }

    const recordStream = (newStream) => {
        // console.log('Recording stream: ', newStream);
        stream.current = newStream;

        const kbps = 1024;
        const Mbps = kbps * kbps;
        
        // These options drastically affect the quality of the recording
        const options = {
          mimeType: 'video/mp4',
          bitsPerSecond: 800 * Mbps,
        };

        recorder.current = new MediaRecorder(stream.current, options);
        data.current = [];

        recorder.current.ondataavailable = event => data.current.push(event.data);
        recorder.current.start();

        setRecordingActive(true);
        props.reportRecordingFunc(true);
        setRecordingReminder();

        let stopped = new Promise((resolve, reject) => {
            recorder.current.onstop = resolve;
            recorder.current.onerror = event => reject(event.name);
        });

        return Promise.all([
            stopped
        ])
        .then(() => data);
    }

    const downloadVideo = () => {
        if (!gotRecording || recordingActive) {
            return;
        }
        let a = document.createElement('a');
        a.href = recordingVideoRef.current.src;
        a.download = 'ICAST_recorded.mp4';
        a.click();
    }

    const setRecordingReminder = () => {
        reminderTimeout.current = setTimeout(() => {
            let answer = window.confirm('You are currently recording. Do you want to continue recording?\n\nClick OK to continue recording or Cancel to stop recording.');
            if (!answer) {
                stopRecording();
            } else {
                setRecordingReminder();
            }
        }, REMIND_EVERY_MILLISECONDS);
    }

    return (
        <>
            {props.shown && <div className="settings-pop-canceller" onClick={props.hideFunc}></div>}
            <div className={'screen-recorder-pop-container' + (props.shown ? '' : ' hidden')}>
                <div className="fl-column compact">
                    <div className="title">Screen Recorder</div>
                    <div className="copy">You can close this panel and continue to record in the background. Recording will end when you click Stop Recording or close the source window.</div>
                    <div className="fl-row">
                        <button onClick={recordingActive ? stopRecording : startRecording}>
                            <div className={`icon${!recordingActive ? ' red' : ''}`}>
                                {IconJsxer.GetIcon(!recordingActive ? IconJsxer.ICONS.recordIcon : IconJsxer.ICONS.recordStopIcon, IconJsxer.ICON_STYLES.standard)}
                            </div>
                            {recordingActive ? `Stop Recording` : `Start New Recording`}
                        </button>
                        <button className={`${(recordingActive || !gotRecording) ? 'disabled' : ''}`} onClick={downloadVideo}>
                            <div className={`icon${(recordingActive || !gotRecording) ? ' dark' : ''}`}>
                                {IconJsxer.GetIcon(IconJsxer.ICONS.fileDownload, IconJsxer.ICON_STYLES.standard)}
                            </div>
                            Download
                        </button>
                    </div>
                    <div className={`recording-preview${(recordingActive || !gotRecording) ? '' : ' hidden'}`}>
                        <video ref={previewVideoRef} autoPlay muted></video>
                        {!gotRecording && !recordingActive && <p>Click Start New Recording to record something!</p>}
                    </div>
                    <div className={`recording-preview${(!recordingActive && gotRecording) ? '' : ' hidden'}`}>
                        <video ref={recordingVideoRef} controls></video>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ScreenRecorderPop;