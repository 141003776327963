import spacetime from 'spacetime'
import soft from 'timezone-soft'

export const TzOptions = {
    lastDate: null,
    options: null,
}

export const getTimezoneOptions = (date = new Date(), labelStyle = 'original', displayValue = 'UTC', maxAbbrLength = 4) => {
    const dateEpoch = typeof date === 'number' ? date : date.getTime()
    if (TzOptions.lastDate !== dateEpoch || TzOptions.options === null) {
        // let index = 0;
        const options = Object.entries(allTimezones)
            .map(zone => {
                try {
                    const now = spacetime(dateEpoch, zone[0])
                    const tz = now.timezone()
                    const tzStrings = soft(zone[0])

                    let label = ''

                    const standardAbbr = tzStrings?.[0]?.standard?.abbr ?? ''
                    const dstAbbr = tzStrings?.[0]?.daylight?.abbr ?? standardAbbr

                    let abbr = now.isDST() ? dstAbbr : standardAbbr

                    const standardAltName = tzStrings?.[0]?.standard?.name ?? ''
                    const dstAltName = tzStrings?.[0]?.daylight?.name ?? standardAltName

                    let altName = now.isDST() ? dstAltName : standardAltName

                    const min = tz.current.offset * 60
                    const hr =
                        `${(min / 60) ^ 0}:` + (min % 60 === 0 ? '00' : Math.abs(min % 60))
                    const prefix = `(${displayValue}${hr.includes('-') ? hr : `+${hr}`
                        }) ${zone[1]}`

                    switch (labelStyle) {
                        case 'original':
                            label = prefix
                            break
                        case 'altName':
                            label = `${prefix} ${altName ? `(${altName})` : ''}`
                            break
                        case 'abbrev':
                            label = `${prefix} (${abbr.substring(0, maxAbbrLength)})`
                            break
                        default:
                            label = `${prefix}`
                    }

                    return {
                        // index: index++,
                        value: tz.name,
                        label: label,
                        offset: tz.current.offset,
                        abbrev: abbr,
                        altName: altName,
                    }
                } catch {
                    return null
                }
            })
            .sort((a, b) => a.offset - b.offset)

        // console.log('Tz Date: ', dateEpoch);
        // console.log('Timezone options: ', options)
        for (let i = 0; i < options.length; i++) {
            options[i].index = i;
        }
        TzOptions.lastDate = dateEpoch;
        TzOptions.options = options;
    }
    return TzOptions.options;
}

const findFuzzyTz = (zone, options = null) => {
    options = options || TzOptions.options || getTimezoneOptions();
    let currentTime = spacetime.now('GMT')
    try {
        currentTime = spacetime.now(zone)
    } catch (err) {
        return
    }
    return options
        .filter(
            (tz) =>
                tz.offset === currentTime.timezone().current.offset
        )
        .map((tz) => {
            let score = 0
            if (
                currentTime.timezones[tz.value.toLowerCase()] &&
                !!currentTime.timezones[tz.value.toLowerCase()].dst ===
                currentTime.timezone().hasDst
            ) {
                if (
                    tz.value
                        .toLowerCase()
                        .indexOf(
                            currentTime.tz.substring(currentTime.tz.indexOf('/') + 1)
                        ) !== -1
                ) {
                    score += 8
                }
                if (
                    tz.label
                        .toLowerCase()
                        .indexOf(
                            currentTime.tz.substring(currentTime.tz.indexOf('/') + 1)
                        ) !== -1
                ) {
                    score += 4
                }
                if (
                    tz.value
                        .toLowerCase()
                        .indexOf(currentTime.tz.substring(0, currentTime.tz.indexOf('/')))
                ) {
                    score += 2
                }
                score += 1
            } else if (tz.value === 'GMT') {
                score += 1
            }
            return { tz, score }
        })
        .sort((a, b) => b.score - a.score)
        .map(({ tz }) => tz)[0]
}

export const parseTimezone = (zone, options) => {
    options = options || TzOptions.options || getTimezoneOptions();
    if (typeof zone === 'object' && zone.value && zone.label) return zone
    if (typeof zone === 'string') {
        return (
            options.find(tz => tz.value === zone) ||
            (zone.indexOf('/') !== -1 && findFuzzyTz(zone))
        )
    } else if (zone.value && !zone.label) {
        return options.find(tz => tz.value === zone.value)
    }
}

const allTimezones = {
    "Pacific/Midway": "Midway Island, Samoa",
    "Pacific/Honolulu": "Hawaii",
    "America/Juneau": "Alaska",
    "America/Boise": "Mountain Time",
    "America/Dawson": "Dawson, Yukon",
    "America/Chihuahua": "Chihuahua, La Paz, Mazatlan",
    "America/Phoenix": "Arizona",
    "America/Chicago": "Central Time",
    "America/Regina": "Saskatchewan",
    "America/Mexico_City": "Guadalajara, Mexico City, Monterrey",
    "America/Belize": "Central America",
    "America/Detroit": "Eastern Time",
    "America/Bogota": "Bogota, Lima, Quito",
    "America/Caracas": "Caracas, La Paz",
    "America/Santiago": "Santiago",
    "America/St_Johns": "Newfoundland and Labrador",
    "America/Sao_Paulo": "Brasilia",
    "America/Tijuana": "Tijuana",
    "America/Montevideo": "Montevideo",
    "America/Argentina/Buenos_Aires": "Buenos Aires, Georgetown",
    "America/Godthab": "Greenland",
    "America/Los_Angeles": "Pacific Time",
    "Atlantic/Azores": "Azores",
    "Atlantic/Cape_Verde": "Cape Verde Islands",
    GMT: "UTC",
    "Europe/London": "Edinburgh, London",
    "Europe/Dublin": "Dublin",
    "Europe/Lisbon": "Lisbon",
    "Africa/Casablanca": "Casablanca, Monrovia",
    "Atlantic/Canary": "Canary Islands",
    "Europe/Belgrade": "Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    "Europe/Sarajevo": "Sarajevo, Skopje, Warsaw, Zagreb",
    "Europe/Brussels": "Brussels, Copenhagen, Madrid, Paris",
    "Europe/Amsterdam": "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    "Africa/Algiers": "West Central Africa",
    "Europe/Bucharest": "Bucharest",
    "Africa/Cairo": "Cairo",
    "Europe/Helsinki": "Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
    "Europe/Athens": "Athens, Minsk",
    "Asia/Jerusalem": "Jerusalem",
    "Africa/Harare": "Harare, Pretoria",
    "Europe/Moscow": "Istanbul, Moscow, St. Petersburg, Volgograd",
    "Asia/Kuwait": "Kuwait, Riyadh",
    "Africa/Nairobi": "Nairobi",
    "Asia/Baghdad": "Baghdad",
    "Asia/Tehran": "Tehran",
    "Asia/Dubai": "Abu Dhabi, Muscat",
    "Asia/Baku": "Baku, Tbilisi, Yerevan",
    "Asia/Kabul": "Kabul",
    "Asia/Yekaterinburg": "Ekaterinburg",
    "Asia/Karachi": "Islamabad, Karachi, Tashkent",
    "Asia/Kolkata": "Chennai, Kolkata, Mumbai, New Delhi",
    "Asia/Kathmandu": "Kathmandu",
    "Asia/Dhaka": "Astana, Dhaka",
    "Asia/Colombo": "Sri Jayawardenepura",
    "Asia/Almaty": "Almaty, Novosibirsk",
    "Asia/Rangoon": "Yangon Rangoon",
    "Asia/Bangkok": "Bangkok, Hanoi, Jakarta",
    "Asia/Krasnoyarsk": "Krasnoyarsk",
    "Asia/Shanghai": "Beijing, Chongqing, Hong Kong SAR, Urumqi",
    "Asia/Kuala_Lumpur": "Kuala Lumpur, Singapore",
    "Asia/Taipei": "Taipei",
    "Australia/Perth": "Perth",
    "Asia/Irkutsk": "Irkutsk, Ulaanbaatar",
    "Asia/Seoul": "Seoul",
    "Asia/Tokyo": "Osaka, Sapporo, Tokyo",
    "Asia/Yakutsk": "Yakutsk",
    "Australia/Darwin": "Darwin",
    "Australia/Adelaide": "Adelaide",
    "Australia/Sydney": "Canberra, Melbourne, Sydney",
    "Australia/Brisbane": "Brisbane",
    "Australia/Hobart": "Hobart",
    "Asia/Vladivostok": "Vladivostok",
    "Pacific/Guam": "Guam, Port Moresby",
    "Asia/Magadan": "Magadan, Solomon Islands, New Caledonia",
    "Asia/Kamchatka": "Kamchatka, Marshall Islands",
    "Pacific/Fiji": "Fiji Islands",
    "Pacific/Auckland": "Auckland, Wellington",
    "Pacific/Tongatapu": "Nuku'alofa",
}
